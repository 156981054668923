import React, { useState, useEffect } from 'react';
import PainelFiltro from './filtro/painel.filtro'
import { Creators as ModulosCreators } from './store/painel.ducks'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Table from '../../components/Table/table.component'
import { CardStyle } from "../../components/CardStyle/cardstyle.component"
import HistogramaGrafico from './grafico/grafico'
import ToastHelper from './../../utils/toastHelper';

function PainelListagem(props) {
	const [controleEquipamentos, setControleEquipamentos] = useState(undefined);
	const [controleHoras, setControleHoras] = useState(undefined)
	const [filtro, setFiltro] = useState({})

	const columns = [
		{ id: 1, title: 'Máquina', property: 'maquina' },
		{ id: 2, title: 'Media por Horas', property: 'mediaHoras' },
		{ id: 3, title: 'Dias trabalhados', property: 'dias' },
		{ id: 4, title: 'Quantidade de horas trabalhadas', property: 'quantidade' },
	];

	const filtrar = (filtro) => {
		if (filtro.anosMeses.length === 0)
			return ToastHelper.error("Selecione um período para prosseguir.")

		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		filtro.controleEquipamentos = controleEquipamentos !== undefined ? controleEquipamentos : undefined;
		filtro.controleHoras = controleHoras !== undefined ? controleHoras : undefined;

		setFiltro(filtro)

		props.buscarPainel(filtro)
		props.buscarPainelMaquinas(filtro)
	}

	const controlar = async (controle) => {
		if (controle.controleEquipamentos)
			setControleEquipamentos(controle.controleEquipamentos)

		if (controle.controleHoras)
			setControleHoras(controle.controleHoras);
	}

	useEffect(() => {
		let filtroAux = filtro

		filtroAux.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		filtroAux.controleHoras = controleHoras
		filtroAux.controleEquipamentos = controleEquipamentos

		props.buscarPainel(filtroAux)
		props.buscarPainelMaquinas(filtroAux)
	}, [controleHoras, controleEquipamentos]);

	useEffect(() => {
		if (localStorage.getItem('fazendaId')) {
			props.getMaquinas()
			props.getGrupoMaquinas()
			props.getAnoMes()
			props.getSafra()
		}
	}, []);

	useEffect(() => {
		if (props.anoMes.result !== undefined) {
			var lastItem = props.anoMes.result.slice(-1)
			var param = {
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				anosMeses: [lastItem[0].id]
			}

			props.buscarPainel(param)
			props.buscarPainelMaquinas(param)
		}
	}, [props.anoMes.result])

	return (
		<>
			<PainelFiltro
				grupoMaquinas={props.grupoMaquinas.items}
				maquinas={props.maquinas.items}
				safras={props.safras}
				anoMes={props.anoMes.result}
				filtrar={filtrar}
				filtro={filtro}
			/>
			<CardStyle className="card">
				<hr></hr>
				<HistogramaGrafico
					data={props.painel}
					dataAnoMes={props.painelAnoMes}
					controlar={controlar}
				/>
				<div className="row">
					<div className="col-md-12">
						<hr></hr>
						<div label='Filtros' style={{ margin: 10, fontSize: 18 }}>
							Tabela de Máquinas
					</div>
						<Table
							align='left'
							columns={columns}
							data={props.painelMaquinas || []}
							emptyColSpan={columns ? columns.length + 1 : 0}
						/>
						<hr></hr>
					</div>
				</div>
			</CardStyle>
		</>
	)
}

const mapStateToProps = state => {
	return ({
		grupoMaquinas: state.painel.grupoMaquinas,
		maquinas: state.painel.maquinas,
		safras: state.painel.safra,
		anoMes: state.painel.anoMes,
		painel: state.painel.painel,
		painelAnoMes: state.painel.painelAnoMes,
		painelMaquinas: state.painel.painelMaquinas
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PainelListagem)
