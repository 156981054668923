import api from './api';

export default {
	buscar(afastamento){
		return api.post(`/api/PessoaAfastamento/BuscarTodos`, afastamento);
	},
	salvar(afastamento){
		return api.post(`/api/PessoaAfastamento`, afastamento);
	},
	deletar(id){
		return api.delete(`/api/PessoaAfastamento/${id}`);
	},
	buscarPorId(afastamentoId){
		return api.get(`/api/PessoaAfastamento/BuscarPorId/${afastamentoId}`);
	},
	editar(afastamento){
		return api.put('/api/PessoaAfastamento', afastamento);
	}
};


