import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import {bindActionCreators} from 'redux';
import { Creators as ModulosCreators } from './store/afastamento.ducks';
import {connect} from 'react-redux';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Modal from '../../utils/modalHelper';
import Utils from '../../utils/utils';

function AfastamentosListagem(props) {

    const columns = [
		{ id: 1, title: 'Colaborador', property: 'pessoa.nome'},
		{ id: 2, title: 'Período inicial', property: 'dataInicio'},
        { id: 3, title: 'Período final', property: 'dataFim'},
        { id: 4, title: 'Justificativa', property: 'afastamentoTipo.nome'},
    ];

    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_PESSOA_LEITURA,
            action: item => visualizar(item),
        },
        {
			id: 2,
			name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_PESSOA_ATUALIZAR,
			action: item => editar(item),
        },
        {
			id: 3,
			name: 'Apagar',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_PESSOA_EXCLUIR,
			action: item => handleModalExcluir(item),
		}
    ];

    const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [excluirItem, setExcluirItem] = useState({id:null, showModal:false})
    const [orderColumn, setOrderColumn] = useState()
    const [descending, setDescending] = useState(true)
    
    
    const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: currentPage,
        pageSize: 10,
        filtro:'',
        descedescending: true,
        order: 'DataInicio',
    }

    const filtrar = async (content) =>{
        let newPagination = pagination;
        newPagination.filtro = content.toLowerCase();
        newPagination.page = 1;
		setPagination(newPagination)
        props.getAfastamentos(newPagination)
    }

    const editar = (item) => {
        props.history.push({
            pathname: `/afastamentos/editar/${item.id}`
        });
    }

    const visualizar = (item) => {
        props.history.push({
            pathname: `/afastamentos/visualizar/${item.id}`
        });
    }
    
    const cadastrarRota = () => {
        props.history.push(`/afastamentos/cadastro`);
    }

    const handleModalExcluir = (item) => {
        if(item) {
            setExcluirItem({id:item.id, showModal: !excluirItem.showModal})
        } else {
            setExcluirItem({id: null, showModal: !excluirItem.showModal})
        }
	}
    
    const [pagination, setPagination] = useState(paginateParams)
    
    useEffect( () => {
        if(localStorage.getItem('fazendaId')){
            props.getAfastamentos(pagination)
        }
    }, []);
    
    useEffect(()=>{
		if(props.afastamentos) initPagination()
    })

	const initPagination = () => {
		setItems(props.afastamentos.items);
		setTotal(props.afastamentos.total);
		setPage(props.afastamentos.page);
    }
    
    const apagarAfastamento = async () => {
        await props.deleteAfastamento(excluirItem.id)
        await setTimeout(function(){
            props.getAfastamentos(pagination)
        }, 800)
        setExcluirItem({showModal: false})
    }

    

    const orderTable = (property, descending) => {
        setOrderColumn(Utils.parsePropertToFilter(property));
        setDescending(descending);
        let params = pagination;
        params.order = Utils.parsePropertToFilter(property);
        params.descedescending = descending;
        props.getAfastamentos(params)
    }

    const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
        props.getAfastamentos(pagination)
    }
    
    return (
        <>  
            <Title 
                acao="Novo afastamento"
                permissaoBotao={PERMISSAO.MODULO_AFASTAMENTOS_CRIAR}
                iconBotao="fas fa-plus"
                onClick={cadastrarRota}>
                    Afastamentos
            </Title>
            <CardStyle className="card">
                <SimpleSearch 
                    placeHolder="Pesquisar"
                    value={pagination.filtro}
                    submit={(e) => filtrar(e)}
                    
                />
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
					orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
                />
			</CardStyle>
            <Modal
                show={excluirItem.showModal}
                doubleButtons={true}
                title={"Excluir Afastamento"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => setExcluirItem({id: excluirItem.id, showModal: false})}
                submit={apagarAfastamento}
                close={() => setExcluirItem({id: excluirItem.id, showModal: false})}
                content={
                    <h6>{"Deseja realmente excluir esse afastamento?"}</h6>
                }
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    afastamentos: state.afastamentos.afastamentos
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AfastamentosListagem)