import React from 'react';
import { CardStyle } from "../../../components/CardStyle/cardstyle.component";
import Title from '../../../components/Title/title.component';
import DropzoneFile from '../../../components/DragAndDrop/dragAndDropFile'
import UploadService from '../../../services/uploadService'
import ModalRevisao from './importacaoRevisao.modal'
import localService from '../../../services/localService'
import servicoService from '../../../services/servicoService'
import ToastHelper from '../../../utils/toastHelper'
import planejamentoSafraService from '../../../services/planejamentoSafraService'
import Modal from './importacao.modal'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LimparPlanejamentosModal from './limparPlanejamentos.modal'

export default class Importacao extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tipo: '',
			servicos: [],
			locais: [],
			modal: false,
			modalRevisao: false,
			dados: [],
			atividades: [],
			setLimparModal: false,
			parametros: null

		};
	}

	componentDidMount() {
		this.getServicos();
		this.getlocal();
	}

	getServicos = async (str) => {
		var parametros = {
			page: 0,
			pageSize: 1000,
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value
		};
		if (str !== undefined) parametros.filtro = str;
		const { data } = await servicoService.buscarTodas(parametros);
		this.setState({ servicos: data.result.items });
	}

	getlocal = async (str) => {
		var parametros = {
			page: 0,
			pageSize: 1000,
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			safraId: JSON.parse(localStorage.getItem('safraId')).value,
		};
		if (str !== undefined) parametros.filtro = str;
		const { data } = await localService.buscarTodos(parametros);

		this.setState({ locais: data.result.items });
	}

	setImage = async (imagemNova) => {
		const formData = new FormData();
		formData.append('file', imagemNova[0]);
		const { data } = await UploadService.upload(formData);

		if (!data.isOk)
			return ToastHelper.error(data.mensagem)

		this.setState({ modal: true });
		this.setState({ dados: data.result });
	}

	downloadModelo = async () => {
		let response = await UploadService.downloadModelo()
		let tempLink = document.createElement('a');
		var data = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		var csvURL = window.URL.createObjectURL(data);
		tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', 'modelo.xlsx');
		tempLink.click();
	}


	atualizarlocal = (str) => {
		this.getlocal(str);
	}

	atualizarServicos = (str) => {
		this.getServicos(str);
	}

	salvarImportacao = async params => {
		this.setState({ setLimparModal: true, parametros: params });
	}

	limparPlanjemantos = async (params) => {
		if (params.toString() === "sim") {
			await planejamentoSafraService.limpar({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				safraId: JSON.parse(localStorage.getItem('safraId')).value
			});
		}

		var dados = this.state.dados;

		dados.itensPlanilha.forEach(item => {
			var servico = this.state.parametros.servicos.find(x => x.servicos === item.servico);
			var local = this.state.parametros.locais.find(x => x.locais === item.local);

			if (local !== undefined)
				item.localEquivalente = local.localId;

			if (servico !== undefined)
				item.servicoEquivalente = servico.servicoId;

			item.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
			item.safraId = JSON.parse(localStorage.getItem('safraId')).value;

			return item
		})

		let result = await planejamentoSafraService.salvarPlanilha(dados.itensPlanilha);

		if (result.data.isOk) {
			toast.success('✔ ' +
				"Operação realizada com sucesso!",
				{});
			window.location.href = '#/planejamento/safra';
		}
		else
			ToastHelper.error(result.data.mensagem);

		return
	}


	render() {

		return (
			<>

				<Title>Importação de Modelo de Planejamento</Title>
				<CardStyle className="card">
					<br></br>
					<div className="col-md-12 row">
						<div className="col-md-12 ">
							<div class="jumbotron">
								<p class="lead">Veja nosso modelo de planilha clicando no botão de Baixar Modelo.</p>
								<hr class="my-4" />
								<p style={{ fontSize: 16 }}> Os dados inseridos na planilha precisam estar em conformidade com os dados do sistema.
								Você pode revisar os dados já cadastrados no sistema clicando no botão Revisar</p>
								<p class="lead">
									<button
										type="button"
										className="btn btn-primary active text-center"
										onClick={this.downloadModelo}
										style={{ marginTop: 10 }}>
										<i class="fas fa-download"></i> Baixar Modelo
								</button>
									<button
										type="button"
										className="btn btn-revisar modal-2-btn mr-4 wd-30 text-center"
										onClick={() => this.setState({ modalRevisao: true })}
										style={{ marginTop: 10 }}>
										<i class="fas fa-search"></i> Revisar
								</button>
								</p>
								<p> Os arquivos aceitos são planilhas de Excel no formato .XLSX </p>

								<div className="text-center col-md-12 b-0 p-0">
									<br></br>
									<DropzoneFile
										setImage={this.setImage}
										text="Clique ou arraste sua planilha aqui!"
									/>
								</div>
							</div>
						</div>
					</div>
					<Modal
						show={this.state.modal}
						dados={this.state.dados}
						servicos={this.state.servicos}
						local={this.state.locais}
						close={() => this.setState({ modal: false })}
						salvar={this.salvarImportacao}
					>
					</Modal>
				</CardStyle>
				<ModalRevisao
					show={this.state.modalRevisao}
					close={() => this.setState({ modalRevisao: false })}
					title="Serviços e local cadastrados no sistema."
					servicos={this.state.servicos}
					local={this.state.locais}
					atualizarServicos={this.atualizarServicos}
					atualizarlocal={this.atualizarlocal}
				></ModalRevisao>
				<LimparPlanejamentosModal
					show={this.state.setLimparModal}
					close={() => this.setState({ setLimparModal: false })}
					limpar={(param) => this.limparPlanjemantos(param)} >

				</LimparPlanejamentosModal>
			</>
		)
	}
}
