import Utils from '../utils/utils';

export default class ObjectHelper {
	static multisafra() {
		let safrasLocalStorage = JSON.parse(localStorage.getItem("safraId"));
		if (safrasLocalStorage === null || safrasLocalStorage === undefined) {
			window.location.href = "/#/"
			return
		} else {
			if (Array.isArray(safrasLocalStorage)) {
				let safras = []
				safrasLocalStorage.forEach(item => {
					safras.push(item.value)
				});
				return safras;
			} else {
				return [safrasLocalStorage.value];
			}
		}
	}

	static primeiraSafra() {
		let safrasLocalStorage = JSON.parse(localStorage.getItem("safraId"));
		if (safrasLocalStorage === null || safrasLocalStorage === undefined) {
			return window.location.href = "/#/"
		} else {
			if (Array.isArray(safrasLocalStorage)) {
				return safrasLocalStorage[0].value
			} else {
				return [safrasLocalStorage.value];
			}
		}
	}

	static clone(object) {
		return JSON.parse(JSON.stringify(object));
	}

	static removeInativos(list) {
		let ativos = []
		list.forEach(item => {
			if (item.ativo !== undefined && item.ativo === true) {
				ativos.push(item);
			}
		})

		return ativos;
	}

	static getValuesCulturaParseToSelect(list, valueName, labelName, codigoName, registroSistema, sigla, cultivares, ativo) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		registroSistema = registroSistema || 'registroSistema';
		cultivares = cultivares || 'cultivares';
		codigoName = codigoName || 'codigo';
		ativo = ativo || 'ativo';
		sigla = sigla || 'sigla';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], codigo: item[codigoName], ativo: item.ativo ? item.ativo : undefined, cultivares: item[cultivares], registroSistema: item[registroSistema] })
		});

		return options;
	}

	static getValuesLocaisParseToSelect(list, valueName, labelName, areaHa) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		areaHa = areaHa || 'areaHa';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], areaHa: item[areaHa] })
		});
		return options;
	}

	static getRecursoTipoParseToSelect(list, valueName, labelName, codigoName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		codigoName = codigoName || 'codigo';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], codigo: item[codigoName], ativo: item.ativo ? item.ativo : undefined })
		});
		return options;
	}

	static getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], ativo: item.ativo ? item.ativo : undefined })
		});
		return options;
	}

	static getMembersTeamParseToSelect(list, valueName, labelName, horasApontadasName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		horasApontadasName = horasApontadasName || 'horasApontadas'
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], horasApontadas: item[horasApontadasName], labelComHoras: item[horasApontadasName] && item[horasApontadasName] !== 0 ? `${item[labelName]} (${Utils.formatHour(item[horasApontadasName])})` : item[labelName], ativo: item.ativo ? item.ativo : undefined })
		});
		return options;
	}

	static getValuesEquipeParseToSelect(list, valueName, labelName, sUpervisor, grupoLocal, grupoLocalId) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		sUpervisor = sUpervisor || 'sUpervisor';
		grupoLocalId = grupoLocalId || 'grupoLocalId';
		grupoLocal = grupoLocal || 'grupoLocal';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: `${item[labelName]} (${item[grupoLocal] ? item[grupoLocal] : "Todas as fazendas"})`, supervisor: item[sUpervisor], grupoLocalId: item[grupoLocalId], grupoLocal: item[grupoLocal], ativo: item.ativo ? item.ativo : false })
		});
		return options;
	}

	static getValuesUnitsParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'sigla';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], ativo: item.ativo })
		});
		return options;
	}


	static getValuesMachineParseToSelect(list, valueName, labelName, horimetroAtual, horimetroAtivo, grupoLocal, grupoLocalId) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		horimetroAtual = horimetroAtual || 'horimetroAtual';
		horimetroAtivo = horimetroAtivo || 'horimetroAtivo';
		grupoLocal = grupoLocal || 'grupoLocal';
		grupoLocalId = grupoLocalId || 'grupoLocalId';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], horimetroAtual: item[horimetroAtual], horimetroAtivo: item[horimetroAtivo], grupoLocal: item[grupoLocal], grupoLocalId: item[grupoLocalId], ativo: item.ativo })
		});
		return options;
	}

	static getValuesMaterialParseToSelect(list, valueName, labelName, unidade, composto, volumeMistura, materiaisAuxiliares) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		unidade = unidade || 'unidade';
		composto = composto || 'composto';
		volumeMistura = volumeMistura || 'volumeMistura';
		materiaisAuxiliares = materiaisAuxiliares || 'materiaisAuxiliares';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], unidade: item[unidade], composto: item[composto], ativo: item.ativo, volumeMistura: item[volumeMistura], materiaisAuxiliares: item[materiaisAuxiliares] })
		});
		return options;
	}

	static getValuesLocationParseToSelect(list, valueName, labelName, areaHa) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		areaHa = areaHa || 'areaHa';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], areaHa: item[areaHa], ativo: item.ativo })
		});
		return options;
	}

	static parseValuesUnitsToSelect(unity) {
		let options = [];
		options.push({ value: unity.id, label: unity.sigla })
		if (unity.unidadeConversao ? (unity.unidadeConversao.length > 0) : false) {
			unity.unidadeConversao.forEach(unidadeConversao => {
				options.push({
					value: unidadeConversao.unidadeConvertida.id,
					label: unidadeConversao.unidadeConvertida.sigla,
					valorConversao: unidadeConversao.valor
				})
			})
		}
		return options;
	}

	static getValueByPropertyName(propertyName, object) {
		if (propertyName === undefined) return ""
		const parts = propertyName.split('.');
		const length = parts.length;
		let i;
		let value = object || this;
		if (!value) return null;

		for (i = 0; i < length; i++) {
			value = value[parts[i]];
		}

		if (value === 0)
			return value

		return value || null;
	}

	static getObjectAndFieldName(string) {
		let splitName = string.split(".");
		if (splitName.length > 1) {
			return {
				object: splitName[0],
				field: splitName[1]
			};
		}

		return null;

	}
}
