import api from './api';

export default {
	getAll(fazendaId){
		return api.post(`/api/GrupoLocais/BuscarTudo`, fazendaId);
	},
	deletar(id){
		return api.delete(`/api/GrupoLocais/${id}`);
	},
	salvar(grupoLocais){
		return api.post(`/api/GrupoLocais`, grupoLocais)
	},
	buscarPorId(grupoLocaisId){
		return api.get(`/api/GrupoLocais/${grupoLocaisId}`)
	},
	editar(grupoLocais){
		return api.put(`/api/GrupoLocais`, grupoLocais)
	},
	ativaDesativa(id){
		return api.get(`/api/GrupoLocais/AtivarDesativar/${id}`);
	},
};
