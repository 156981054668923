import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/es/Modal';
import TabelaRecursos from '../../../components/TabelaRecursos/tabelaRecursos.component';

export default function PlanejamentoSafraRecursosModal(props) {

	const [recursos, setRecursos] = useState();

	useEffect(() => {
		if (props.recursos) {
			setRecursos(JSON.parse(JSON.stringify(props.recursos)));
		}
	}, [props.show]);

	const addRecurso = useCallback(async (item) => {
		setRecursos([...recursos, item]);
	});

	const editRecurso = useCallback(async (item) => {
		setRecursos([...item]);
	});

	const deleteRecurso = useCallback(async (item) => {
		setRecursos([...item]);
	});

	const cancelar = useCallback(async () => {
		props.close();
	});

	const salvar = useCallback(async () => {
		props.submit(props.planejamentoId, recursos);
		props.close();
	});

	return (
		<>
			<Modal
				show={props.show}
				dialogClassName="modal-dialog-lg"
				onHide={() => cancelar()}>
				<Modal.Body>
					<h3 className='mt-3' >{"Lista de recursos do serviço: " + (props.servicoSelecionado) + (props.localSelecionado ? " (Local: " + (props.localSelecionado) + ' - ' + (props.areaLocal) + " ha)" : "")}</h3>
					<div className='row col-md-12 flex mt-4'>
						<div className="card-header col-md-12">
							<TabelaRecursos
								recursos={recursos}
								hideColunaLocal={true}
								locais={props.locais}
								utilizaPorcentagem={props.utilizaPorcentagem}
								onAdd={addRecurso}
								onEdit={editRecurso}
								onDelete={deleteRecurso} />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="footer-modal">
					<div className="row col-md-12">
						<div className="col-md-6">
							<button className="btn btn-outline-secondary pull-left" type="button" onClick={cancelar}>
								<i className='fas fa-close' /> Cancelar
							</button>
						</div>
						<div className="col-md-6">
							<button className="btn btn-primary active pull-right" type="submit" onClick={salvar}>
								<i className='fas fa-check' /> Salvar
							</button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}
