import api from './api';

export default {
	getAll(fazendaId) {
		return api.get(`/api/GrupoSafra/${fazendaId}`);
	},
	buscarPorId(GrupoSafraId) {
		return api.get(`/api/GrupoSafra/BuscarPorId/${GrupoSafraId}`);
	},
	create(param) {
		return api.post('/api/GrupoSafra', param);
	}
};


