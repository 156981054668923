import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Title from "../../../components/Title/title.component";
import { CardStyle } from '../../../components/CardStyle/cardstyle.component';
import ToastHelper from "../../../utils/toastHelper";
import { Creators as ModulosCreators } from '../../redefinir_senha/store/redefinir.senha.ducks';
import UsuarioService from '../../../services/usuarioService'
import zxcvbn from 'zxcvbn'
import './redefinir.senha.scss'

export const TOKEN_KEY = '@projeto-token';

function RedefinirSenha(props) {

	const { match: { params } } = props;
	const { handleSubmit } = useForm({});
	const [type, setType] = useState('password');
	const [scoreNovaSenha, setScoreNovaSenha] = useState("null");
	const [scoreConfirmarNovaSenha, setScoreConfirmarNovaSenha] = useState("null");
	const [idUser, setIdUser] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [errorNewPassword, setErrorNewPassword] = useState('');
	const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState('');

	useEffect(() => {
	}, []);

	useEffect(() => {
		if (params.id !== undefined) {
			setIdUser(params.id)
		}
	}, []);

	const showHide = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setType(type === 'input' ? 'password' : 'input')
	}

	const newPasswordStrength = (e) => {
		setNewPassword(e.target.value)
		if (e.target.value === '') {
			setScoreNovaSenha('null')
		}
		else {
			var pw = zxcvbn(e.target.value).score;
			setScoreNovaSenha(pw)
		}
	}

	const currentPasswordChanged = (e) => {
		setCurrentPassword(e.target.value);
	}

	const confirmNewPasswordStrength = (e) => {
		setConfirmNewPassword(e.target.value)
		if (e.target.value === '') {
			setScoreConfirmarNovaSenha('null')
		}
		else {
			var pw = zxcvbn(e.target.value).score;
			setScoreConfirmarNovaSenha(pw)
		}
	}

	const validateConfirmNewPassword = (confirmNewPassword) => {
		if (confirmNewPassword) {
			if (confirmNewPassword.length < 6) {
				setErrorConfirmNewPassword("Sua nova senha deve ter um minimo de 6 digitos.")
				return false
			} else {
				if (confirmNewPassword === newPassword) {

					setErrorConfirmNewPassword("")
					return true
				} else {
					setErrorConfirmNewPassword("Confirmação de senha Inválida.")
					return false
				}
			}
		} else {
			setErrorConfirmNewPassword("Campo obrigatório.")
			return false
		}
	}

	const validateNewPassword = (newPassword) => {
		if (newPassword) {
			if (newPassword.length < 6) {
				setErrorNewPassword("Sua nova senha deve ter um minimo de 6 digitos.")
				return false
			} else {
				setErrorNewPassword("")
				return true
			}
		} else {
			setErrorNewPassword("Campo obrigatório.")
			return false
		}
	}

	const validateConf = (newPassword, confirmNewPassword) => {
		if (newPassword && confirmNewPassword) {
			if (newPassword.length < 6 && confirmNewPassword.length < 6) {
				setErrorNewPassword("Sua nova senha deve ter um minimo de 6 digitos.")
				return false
			} else {
				if (newPassword === confirmNewPassword) {
					setErrorConfirmNewPassword("")
					setErrorNewPassword("")
					return true
				} else {
					setErrorConfirmNewPassword("Confirmação de senha Inválida.")
					return false
				}
			}
		} else {
			setErrorConfirmNewPassword("Campo obrigatório.")
			setErrorNewPassword("Campo obrigatório.")
			return false
		}
	}

	const submitForm = useCallback(async (data) => {

		const isValid = await validateConf(newPassword, confirmNewPassword)

		if ((errorNewPassword && errorConfirmNewPassword) === "" && isValid) {

			let params = {
				usuarioId: idUser,
				senhaAtual: currentPassword,
				novaSenha: newPassword
			}

			const { data } = await UsuarioService.redefinirSenha(params);
			if (data.isOk) {
				ToastHelper.success(data.mensagem);
				props.history.goBack()
			} else {
				ToastHelper.error(data.mensagem);
			}
		}
	}, [newPassword, confirmNewPassword]);

	return (
		<>
			<div className="ml-2 text-left">
				<Title>Redefinir Senha</Title>
			</div>
			<form onSubmit={handleSubmit(submitForm)}>
				<CardStyle className='card mb-0 pb-0 text-left'>
					<div className='card-header row col-md-12 flex mb-3'>
						<div className="col-md-12 mt-3 ">
							<p className="text-left sub-title">
								Escolha uma nova senha e à confirme no próximo campo.
                            </p>
							<p className="text-left sub-title">
								Use senhas fortes para garantir sua segurança, de preferência a senhas com no minimo oito dígitos, alfanuméricas, que intercalam números e letras.
                            </p>
						</div>
						<div className="col-md-4 mt-4 ">
							<label className="label">
								Senha atual:*
                            </label>
							<input type={type} name="currentPassword" autoComplete="off" onChange={currentPasswordChanged} className="form-control" />
						</div>
						<div className="col-md-3 mt-4 ">
							<label className="label">
								Nova senha:*
                            </label>
							<input type={type} autoComplete="off" className="form-control" onChange={newPasswordStrength} onBlur={e => validateNewPassword(e.target.value)} />
							<div className="flex">
								<span className="password__strength ml-4" data-score={scoreNovaSenha} />
							</div>
							{errorNewPassword && <span className='span-errors'> {errorNewPassword}</span>}
						</div>
						<div className="col-md-3 mt-4 ml-3">
							<label className="label">
								Confirmar nova senha:*
                            </label>
							<input type={type} autoComplete="off" className="form-control" onChange={confirmNewPasswordStrength} onBlur={e => validateConfirmNewPassword(e.target.value)} />
							<div className="flex">
								<span className="password__strength ml-4" data-score={scoreConfirmarNovaSenha} />
							</div>
							{errorConfirmNewPassword && <span className='span-errors'> {errorConfirmNewPassword}</span>}
						</div>
						<span className="icon-show-password ml-3" onClick={showHide}>{type === 'input' ? <i className='fas fa-eye color-green' /> : <i className='fas fa-eye-slash color-secundary' />}</span>
					</div>
				</CardStyle>
				<div className="col-md-12 mt-3">
					<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => window.history.back()}>
						<i className='fas fa-close' /> Cancelar
                    </button>
					<button className="btn btn-primary active pull-right" type="submit">
						<i className='fas fa-check' /> Salvar
                    </button>
				</div>
			</form>
		</>
	);
}

const mapStateToProps = state => {
	return ({
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RedefinirSenha)

