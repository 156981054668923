import React, { useState, useEffect} from 'react';
import {
	BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';
import Select from 'react-select';
import ObjectHelper  from '../../../utils/objectHelper';


export default function HistogramaGrafico(props){

	const [array, setArray] =  useState([])
	const [unidade, setUnidade] =  useState([])
	const [selectedRecursos, setSelectedRecursos] = useState([])

	useEffect( () => {
		if(props.data !== undefined){
			if(props.data.length > 0){
				setArray(props.data[0].mesesAno)
				setSelectedRecursos({value: props.data[0].recursos, label: props.data[0].recursos})
				setUnidade(props.data[0].unidade)
			}else{
				setArray(props.data)
				setSelectedRecursos({value: props.data, label: props.data})
				setUnidade(props.data)
			}
		}
	}, [props.data]);


	const changeChartRecursos = (recurso) => {
		props.data.forEach(element => {
			if(element.recursos === recurso.value){
				setArray(element.mesesAno)
				setUnidade(element.unidade)

			}
		});
	}

	return (
			<>
			<div>
				<div className="card-header col-md-4">
					<label className="label" htmlFor="recursosTipoId">
							Recursos:
					</label>
					<Select
						placeholder='Selecione o Recurso'
						className="select-style ajust-label-padrao disabled-select"
						onChange={recurso => {setSelectedRecursos(recurso); changeChartRecursos(recurso)}}
						options={ObjectHelper.getValuesParseToSelect(props.data, 'recursos', 'recursos')}
						value={selectedRecursos}
						theme={theme => ({...theme, borderRadius: 0 })}
					/>
				</div>
			</div>

				<BarChart width={window.innerWidth - 130} height={300} data={array || []}
					margin={{top: 20, right: 30, left: 20, bottom: 5}}>
				<CartesianGrid strokeDasharray="3 3"/>
				<XAxis dataKey="mesAno"/>
				<YAxis yAxisId="left" orientation="left" stroke="#8884d8"/>
				<Tooltip content={<CustomTooltip nome={selectedRecursos} unidade={unidade}/>} />
				{
					array.length > 0 &&
					<Legend
						payload={
							[
								{ id: 'quantidade', value: `Quantidade de ${selectedRecursos.value}`, type: 'square', color: '#8884d8'},
							]
						}
					/>
				}
				<Bar maxBarSize={50} yAxisId="left" dataKey="quantidade" fill="#8884d8" />
				</BarChart>
			</>
		);
  	}


	const CustomTooltip = ({ nome, unidade, active, payload, label }) => {
		if (active && payload) {
			return (
				<div className="custom-tooltip-rechart" >
					<p className="">{`Mes/Ano:  ${label}`}</p>
					<p>{`Quantidade de ${nome.value}: ${payload[0].value} por ${unidade.toLowerCase()}`}</p>

				</div>
			);
		}

		return null;
	};

