import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import GrupServicesService from "../../../services/grupoServicoService";
import ServicesService from "../../../services/servicoService";
import { Types } from "./advancedSearch.ducks";
import executeAction from "../../../store/sagas/executeAction";
import LocationService from "../../../services/localService";
import SafraService from "../../../services/safraService";
import ObjectHelper from "utils/objectHelper";
const parametros = {
	fazendaId:
		JSON.parse(localStorage.getItem("fazendaId")) !== null
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: null,
	page: 1,
	pageSize: 99999
};

function* asyncListGrupServices(action) {
	yield executeAction(function* () {
		const { callback } = action;
		const { data } = yield call(
			async () => await GrupServicesService.buscarTodos(parametros)
		);
		yield put({ type: Types.SET_GROUP_SERVICES, data: data.result });
		callback();
	}, Types.GET_GROUP_SERVICES);
}

function* asyncListServices(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		if (params) {
			const { data } = yield call(
				async () =>
					await ServicesService.buscarPorGrupoId(
						parametros.fazendaId,
						params
					)
			);
			yield put({ type: Types.SET_SERVICES, data: data.result });
		} else {
			yield put({ type: Types.SET_SERVICES, data: [] });
		}
		callback();
	}, Types.GET_SERVICES);
}

function* asyncListLocations(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		if (params) {
			if (params.toFilter) {
				const { data } = yield call(
					async () => await LocationService.buscarTodos(parametros)
				);
				yield put({ type: Types.SET_LOCATIONS, data: data.result });
				callback();
			} else {
				let safraId = ObjectHelper.primeiraSafra();
				const { data } = yield call(
					async () =>
						await SafraService.locais(
							parametros.fazendaId,
							safraId,
							params.includeAdministrativo
						)
				);
				yield put({ type: Types.SET_LOCATIONS, data: data.result });
				callback();
			}
		} else {
			let safraId = ObjectHelper.primeiraSafra();
			const { data } = yield call(
				async () =>
					await SafraService.locais(parametros.fazendaId, safraId)
			);
			yield put({ type: Types.SET_LOCATIONS, data: data.result });
			callback();
		}
	}, Types.GET_LOCATIONS);
}

function* takeListGrupServices() {
	yield takeLatest(Types.GET_GROUP_SERVICES, asyncListGrupServices);
}

function* takeListServices() {
	yield takeLatest(Types.GET_SERVICES, asyncListServices);
}

function* takeListLocations() {
	yield takeLatest(Types.GET_LOCATIONS, asyncListLocations);
}

export default function* root() {
	yield all([fork(takeListGrupServices)]);
	yield all([fork(takeListServices)]);
	yield all([fork(takeListLocations)]);
}
