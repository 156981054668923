import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Title from '../../../components/Title/title.component';
import { CardStyle } from '../../../components/CardStyle/cardstyle.component';
import "react-datepicker/dist/react-datepicker.css";
import MaquinaService from '../../../services/maquinaService';
import GrupoMaquinaService from '../../../services/grupoMaquinaService'
import GrupoLocaisService from '../../../services/grupoLocaisService';
import pt from 'date-fns/locale/pt';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Toggle from 'react-toggle';
import { Formik, Field, ErrorMessage } from 'formik';
import ToastHelper from '../../../utils/toastHelper';
import NumberFormat from 'react-number-format';
import "../maquina.scss";
import ModalTipoMaquina from '../../../components/CommonModal/commonModal'
import TipoMaquinaService from "../../../services/tipoMaquinaService";
import Modal from '../../../components/CommonModal/commonModal'
import Utils from "../../../utils/utils"

class MaquinaVisualizarEditar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModalGrupoMaquina: false,
			showModalTipoMaquina: false,
			safraId: '',
			modo: '',
			fazendaId: '',
			data: '',
			tiposMaquinas: [],
			grupoLocais: [],
			maquina: {
				id: '',
				nome: '',
				marca: '',
				modelo: '',
				anoFabricacao: undefined,
				dataAquisicao: '',
				valor: '',
				horimetroAtivo: '',
				horimetroAtual: '',
				implemento: '',
				foto: '',
				observacao: '',
				grupoLocalId: '',
				maquinaTipoId: '',
				usuarioId: '',
				dataCriacao: '',
				dataAtualizacao: '',
				dataExclusao: '',
				ativo: ''
			}
		}
	}

	MODO_VISUALIZACAO = "VISUALIZAR"
	MODO_EDICAO = "EDITAR"

	async componentDidMount() {
		await this.setModoMaquinaFazendaSafraId();
		await this.buscarTiposMaquinas();
		await this.buscaMaquina();
		await this.buscarGrupoMaquinas();
		await this.buscarGrupoLocais();
	}

	buscarTiposMaquinas = async () => {
		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
		let param = {
			fazendaId: fazendaAtual.value,
			page: 1,
			pageSize: 1000
		}
		const { data } = await MaquinaService.buscarTiposDeMaquinas(param);
		await this.setState({
			...this.state,
			tiposMaquinas: this.getValuesParseToSelect(data.result.items),
		});
	}

	buscarGrupoLocais = async () => {
		let params = {
			fazendaId: (JSON.parse(localStorage.getItem('fazendaId')).value),
			parametro: '',
			page: 1,
			pageSize: 1000,
			order: "Nome",
			descedescending: false
		}
		let result = await GrupoLocaisService.getAll(params)
		await this.setState({
			...this.state,
			grupoLocais: Utils.removeInactiveToSelect(Utils.getValuesParseToSelect(result.data.result.items)),
		});
	}

	buscarGrupoMaquinas = async () => {
		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
		let param = {
			fazendaId: fazendaAtual.value,
			page: 1,
			pageSize: 1000
		}
		const { data } = await GrupoMaquinaService.getAll(param);
		await this.setState({
			...this.state,
			grupoMaquinas: this.getValuesParseToSelect(data.result.items),
		});
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];
		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	setModoMaquinaFazendaSafraId = async () => {
		let fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		let maquinaId = this.props.history.location.pathname.slice(57, 93);
		let modo = this.props.history.location.pathname.slice(94,);
		let safraId = JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : "";

		await this.setState({
			safraId: safraId,
			modo: modo,
			fazendaId: fazendaId,
			maquina: {
				id: maquinaId
			}
		})
	}


	async setImage(imagemNova) {

		let imagemN = imagemNova ? imagemNova : undefined

		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				imagem: imagemN
			}
		})
	}

	setModo = async () => {

		let modo = (this.state.modo === this.MODO_VISUALIZACAO ? !this.state.modo : this.MODO_VISUALIZACAO)
		await this.setState({
			...this.state,
			modo: modo
		})
		if (modo === this.MODO_VISUALIZACAO) {
			await this.buscarTiposMaquinas();
			await this.buscaMaquina();
		}
	}

	buscaMaquina = async () => {
		if (this.state.maquina.id !== '') {
			var query = {
				id: this.state.maquina.id
			}

			let dados = await MaquinaService.buscarPorId(query.id);
			dados.data.dataAquisicao = dados.data.dataAquisicao !== "" ? new Date(dados.data.dataAquisicao) : null
			await this.setState({
				...this.state,
				maquina: dados.data
			});
		}
	}

	handleTextForm = async (e, prop) => {
		let valor = ''
		if (prop === 'horimetroAtual') {
			valor = e.target.value ? e.target.value.replace(',', '.') : '';
		} else {

			valor = e.target.value ? e.target.value : '';
		}
		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				[prop]: valor
			}
		})
	}

	handleSelectForm = async (e, prop) => {
		let valor = e ? e.value : '';
		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				[prop]: valor
			}
		})
	}

	handleChangeHorimetro = async () => {
		let novoHorimetro = !this.state.maquina.horimetroAtivo
		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				horimetroAtivo: novoHorimetro
			}
		})
	}

	handleChangeImplemento = async () => {
		let novoImplemento = !this.state.maquina.implemento
		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				implemento: novoImplemento
			}
		})
	}

	async validateProp(prop) {
		let error;
		if (!this.state.maquina[prop] && this.state.modo !== this.MODO_VISUALIZACAO) {
			error = 'Campo obrigatório';
		}
		if (prop === 'horimetroAtual') {
			error = !this.state.maquina.horimetroAtivo ? null : error
		}
		return error;
	};

	onChangeDateAquisicao = (event) => {
		if (event) {
			this.setState({
				...this.state,
				maquina: {
					...this.state.maquina,
					dataAquisicao: event
				}
			})
		}
	}

	voltar = () => {
		this.props.history.push(`/maquinas/listagem`);
	}


	salvar = async (submit) => {
		let newValor = ''
		if (this.state.maquina.valor) {
			if (typeof this.state.maquina.valor === "string") {
				newValor = this.state.maquina.valor.replace('R$', '')
				newValor = newValor.replace(',', '.')
				newValor = newValor.replace('.', '')
			} else {
				newValor = this.state.maquina.valor
			}
		}
		let newMaquina = {
			id: this.state.maquina.id,
			anoFabricacao: this.state.maquina.anoFabricacao,
			dataAquisicao: this.state.maquina.dataAquisicao,
			fazendaId: this.state.fazendaId,
			foto: this.state.maquina.foto,
			horimetroAtivo: this.state.maquina.horimetroAtivo,
			horimetroAtual: this.state.maquina.horimetroAtual,
			implemento: this.state.maquina.implemento,
			maquinaTipoId: this.state.maquina.maquinaTipoId,
			grupoLocalId: this.state.maquina.grupoLocalId,
			marca: this.state.maquina.marca,
			modelo: this.state.maquina.modelo,
			nome: this.state.maquina.nome,
			observacao: this.state.maquina.observacao,
			valor: newValor,
			grupoMaquinasId: this.state.maquina.grupoMaquinasId
		}
		let dados = await MaquinaService.editar(newMaquina);
		if (dados.data.isOk) {
			ToastHelper.success("Máquina editada com sucesso!");
			this.voltar();
		} else {
			ToastHelper.error("Erro ao tentar editar máquina.");
		}
	}

	async modalClose(values) {
		if (values) {
			values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
			let result = await GrupoMaquinaService.salvar(values);
			if (result.data.isOk) {
				this.setState({ showModalGrupoMaquina: false })
				ToastHelper.success("Grupo de Máquina cadastrado com sucesso");
			}

			let grupo = await GrupoMaquinaService.getAll({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				page: 1,
				pageSize: 1000
			});

			if (grupo.data.isOk)
				await this.setState({
					...this.state,
					showModalGrupoMaquina: false,
					grupoMaquinas: this.getValuesParseToSelect(grupo.data.result.items),
				});
			else
				ToastHelper.error("Erro ao buscar Grupos de máquinas.");
		}
	}

	async modalTipoClose(values) {
		if (values) {
			values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
			let result = await TipoMaquinaService.salvar(values);

			if (result.data.isOk) {
				this.setState({ showModalTipoMaquina: false })
				ToastHelper.success("Tipo de Máquina cadastrado com sucesso");
			}

			let tipo = await TipoMaquinaService.buscarTodas({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				page: 1,
				pageSize: 1000
			});

			if (tipo.data.isOk)
				await this.setState({
					...this.state,
					showModalTipoMaquina: false,
					tiposMaquinas: this.getValuesParseToSelect(tipo.data.result.items),
				});
			else
				ToastHelper.error("Erro ao buscar tipos de máquinas.");
		}
	}

	render() {
		return (
			<Formik
				initialValues={{
					anoFabricacao: this.state.maquina.anoFabricacao,
					dataAquisicao: this.state.maquina.dataAquisicao,
					fazendaId: this.state.fazendaId,
					maquinaTipoId: this.state.maquina.maquinaTipoId,
					marca: this.state.maquina.marca,
					modelo: this.state.maquina.modelo,
					nome: this.state.maquina.nome,
					valor: this.state.maquina.valor
				}}
				onSubmit={this.salvar}
			>
				{({ touched, errors, status, setFieldValue, handleSubmit, validateField, validateForm, isValidating, resetForm, handleReset }) => (
					<form className="form" onSubmit={handleSubmit}>
						<div className="tab-pane fade show active" id="maquina" role="tabpanel" >

							<div className="w-100">
								<div className="row">
									<div className="col-md-8">
										<Title>{this.state.modo === this.MODO_VISUALIZACAO ? 'Visualizar' : 'Editar'} Máquina</Title>
									</div>
								</div>
								<CardStyle className="card">
									<div className="row col-md-12 mt-4">
										<div className="col-md-4">
											<label className="label" >Nome:</label>
											<Field htmlFor="nome" type="text" autocomplete="off"
												name="nome" className="form-control bmd-form-group" disabled={this.state.modo === this.MODO_VISUALIZACAO}
												onChange={(e) => { this.handleTextForm(e, 'nome') }}
												validate={() => this.validateProp('nome')} value={this.state.maquina.nome} />
											<ErrorMessage name="nome">{msg => <div className="error error-message error-validate">{msg}</div>}</ErrorMessage>
										</div>
										<div className="col-md-4">
											<label className="label" >Marca:</label>
											<Field htmlFor="marca" type="text" autocomplete="off"
												name="marca" className="form-control bmd-form-group" disabled={this.state.modo === this.MODO_VISUALIZACAO}
												onChange={(e) => { this.handleTextForm(e, 'marca') }}
												value={this.state.maquina.marca}
											/>
										</div>
										<div className="col-md-4">
											<label className="label" >Modelo:</label>
											<Field htmlFor="modelo" type="text" autocomplete="off"
												name="modelo" className="form-control bmd-form-group" disabled={this.state.modo === this.MODO_VISUALIZACAO}
												onChange={(e) => { this.handleTextForm(e, 'modelo') }}
												value={this.state.maquina.modelo}
											/>
										</div>
									</div>
									<div className="row col-md-12 mt-4">
										<div className="col-md-4">
											<label className="label" >Tipo de máquina:</label>
											<div className="input-group mb-3">
												<Select id="maquinaTipoId"
													name="maquinaTipoId"
													options={this.state.tiposMaquinas}
													className={"form-control select-ajust " + (this.state.modo === this.MODO_VISUALIZACAO ? "disabled-select" : "")}
													htmlFor="maquinaTipoId"
													value={this.state.tiposMaquinas ?
														this.state.tiposMaquinas.filter(option => option.value === this.state.maquina.maquinaTipoId) : ''}
													onChange={(e) => { this.handleSelectForm(e, 'maquinaTipoId') }}
													placeholder="Tipo de máquina."
													isDisabled={this.state.modo === this.MODO_VISUALIZACAO}
													hideSelectedOptions
													theme={theme => ({
														...theme,
														borderRadius: 0,
													})}
												/>
												{
													!(this.state.modo === this.MODO_VISUALIZACAO) &&
													<div className="input-group-prepend">
														<button className="btn-custom float-right " type='button' onClick={() => this.setState({ showModalTipoMaquina: true })} >
															<i className="fas fa-plus"></i>
														</button>
													</div>
												}
												<ErrorMessage name="maquinaTipoId">{msg => <div className="error error-message error-validate">{msg}</div>}</ErrorMessage>
											</div>
										</div>
										<div className="col-md-4">
											<label className="label" >Grupo de máquina:</label>
											<div className="input-group mb-3">
												<Select
													name="grupoMaquinasId"
													options={this.state.grupoMaquinas}
													className={"form-control select-ajust " + (this.state.modo === this.MODO_VISUALIZACAO ? "disabled-select" : "")}
													value={this.state.grupoMaquinas ?
														this.state.grupoMaquinas.filter(option => option.value === this.state.maquina.grupoMaquinasId) : ''}
													onChange={(e) => { this.handleSelectForm(e, 'grupoMaquinasId') }}
													placeholder="Tipo de máquina."
													isDisabled={this.state.modo === this.MODO_VISUALIZACAO}
													hideSelectedOptions
													theme={theme => ({
														...theme,
														borderRadius: 0,
													})}
												/>
												{
													!(this.state.modo === this.MODO_VISUALIZACAO) &&
													<div className="input-group-prepend">
														<button className="btn-custom float-right " type='button' onClick={() => this.setState({ showModalGrupoMaquina: true })} >
															<i className="fas fa-plus"></i>
														</button>
													</div>
												}
												<ErrorMessage name="grupoMaquinasId">{msg => <div className="error error-message error-validate">{msg}</div>}</ErrorMessage>
											</div>
										</div>
										<div className="col-md-4">
											<label className="label">Fazenda:</label>
											<div className="input-group mb-3">
												<Select
													name="grupoLocalId"
													options={this.state.grupoLocais}
													className={"form-control select-ajust " + (this.state.modo === this.MODO_VISUALIZACAO ? "disabled-select" : "")}
													value={this.state.grupoLocais ?
														this.state.grupoLocais.filter(option => option.value === this.state.maquina.grupoLocalId) : ''}
													onChange={(e) => { this.handleSelectForm(e, 'grupoLocalId') }}
													placeholder="Fazenda"
													isDisabled={this.state.modo === this.MODO_VISUALIZACAO}
													hideSelectedOptions
													theme={theme => ({
														...theme,
														borderRadius: 0,
													})}
												/>
											</div>
										</div>
									</div>
									<div className="row col-md-12 mt-4">
										<div className="col-md-4">
											<label className="label" >Ano de Fabricação:</label>
											<Field htmlFor="anoFabricacao" autocomplete="off" type="number" disabled={this.state.modo === this.MODO_VISUALIZACAO}
												name="anoFabricacao" className="form-control bmd-form-group"
												onChange={(e) => { this.handleTextForm(e, 'anoFabricacao') }}
												value={this.state.maquina.anoFabricacao} />
										</div>
										<div className="col-md-4">
											<label className="label" >Data de aquisição:</label>
											<DatePicker
												id="dataAquisicao"
												htmlFor="dataAquisicao"
												name="dataAquisicao"
												placeholderText="Data de aquisição"
												selected={this.state.maquina.dataAquisicao}
												disabled={this.state.modo === this.MODO_VISUALIZACAO}
												onChange={(event) => { this.onChangeDateAquisicao(event) }}
												className='form-control bmd-form-group margin-date'
												dateFormat="dd/MM/yyyy"
												autoComplete="off"
												locale={pt}
											/>
										</div>
										<div className="col-md-4">
											<label className="label">
												Valor:
											</label>
											<NumberFormat
												htmlFor="valor"
												autoComplete="off"
												name="valor"
												thousandSeparator={'.'}
												decimalSeparator={','}
												disabled={this.state.modo === this.MODO_VISUALIZACAO}
												prefix={'R$'}
												className='form-control bmd-form-group'
												placeholder="Insira o valor"
												onChange={(e) => { this.handleTextForm(e, 'valor') }}
												value={this.state.maquina.valor}
											/>
										</div>
									</div>
									<div className="row col-md-12 mt-4">
										<div data-tip={(!this.state.temHorimetro ? "Desabilitado, máquina com horimetro parado." : "Insira as horas do horimetro. Valor maximo permitido 99999,9")}
											className="col-md-4">
											<label className="label">Horímetro Atual:</label>
											<Field htmlFor="horimetroAtual"
												autocomplete="off"
												disabled={this.state.modo === this.MODO_VISUALIZACAO || !this.state.maquina.horimetroAtivo}
												name="horimetroAtual" className="form-control bmd-form-group"
												validate={() => this.validateProp('horimetroAtual')}
												onChange={(e) => { this.handleTextForm(e, 'horimetroAtual') }}
												value={this.state.maquina.horimetroAtual}
											/>
										</div>
										<div className="custom-control flex col-md-8">
											<div className="col-md-6 center-toggle">
												<div className="col-md-12 label-horas">
													<label>Horimetro Ativo ?</label>
												</div>
												<label className="col-md-12 custom-switch">
													<Toggle
														checked={this.state.maquina.horimetroAtivo}
														disabled={this.state.modo === this.MODO_VISUALIZACAO}
														icons={{
															checked: <i className="fas fa-check icon-toogle" />,
															unchecked: <i className="fas fa-times icon-toogle" />,
														}}
														onChange={this.handleChangeHorimetro} />
												</label>
												<ErrorMessage name="horimetroAtual">
													{msg => <div className="error error-message error-validate">{msg}</div>}
												</ErrorMessage>
											</div>
											<div className="col-md-6  center-toggle-implemento">
												<div className="col-md-12 label-horas">
													<label>É um implemento ?</label>
												</div>
												<label className="col-md-12 custom-switch">
													<Toggle
														checked={this.state.maquina.implemento}
														disabled={this.state.modo === this.MODO_VISUALIZACAO}
														icons={{
															checked: <i className="fas fa-check icon-toogle" />,
															unchecked: <i className="fas fa-times icon-toogle" />,
														}}
														onChange={this.handleChangeImplemento} />
												</label>
											</div>
										</div>
									</div>
									<div className="row col-md-12 mt-4 mb-4">
										<div className="col-md-12">
											<label className="label" >Observação:</label>
											<Field htmlFor="observacao" autocomplete="off" type="text" disabled={this.state.modo === this.MODO_VISUALIZACAO}
												name="observacao" className="form-control bmd-form-group"
												onChange={(e) => { this.handleTextForm(e, 'observacao') }}
												value={this.state.maquina.observacao} />
										</div>
									</div>
								</CardStyle>
								<div className="card-deck  mb-2">
									<div className="card-body">
										<button className="btn btn-outline-secondary pull-left" type="button" onClick={this.voltar}>
											<i className='fas fa-close' /> {(this.state.modo === this.MODO_VISUALIZACAO) ? 'Voltar' : 'Cancelar'}
										</button>
										{!(this.state.modo === this.MODO_VISUALIZACAO) &&
											<button className={"btn btn-primary active pull-right " + (this.state.modo === this.MODO_VISUALIZACAO ? "display-none" : "")} onClick={this.salvar} type="button">
												<i className='fas fa-check' /> Salvar
                                            </button>
										}
									</div>
								</div>

							</div>
						</div>
						<Modal
							title={"Cadastrar Grupo de Máquina"}
							show={this.state.showModalGrupoMaquina}
							close={() => this.setState({ showModalGrupoMaquina: false })}
							modalClose={(item) => this.modalClose(item)}
						/>
						<ModalTipoMaquina
							title={"Cadastrar Tipo de Máquina"}
							show={this.state.showModalTipoMaquina}
							close={() => this.setState({ showModalTipoMaquina: false })}
							modalClose={(item) => this.modalTipoClose(item)}
						/>
					</form>
				)}
			</Formik>
		);

	}
}

export default withRouter(MaquinaVisualizarEditar);
