import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	ThStyle, TrStyle,
} from './thead.style';

const SingleActionHeader = () => (
	<th className='font-075em text-right display-th'>#</th>
);

const MultiActionHeader = () => (
	<th className='font-075em text-right display-th'>#</th>
);

const onClickOrder = (columnItem, order) => {
	let propertOrder = ''
	if (columnItem.title === "Turno") {
		propertOrder = columnItem.title
	} else {
		propertOrder = columnItem.property
	}

	order.orderFunction(propertOrder, order.descending ? false : true)
};

function Thead({
	columns,
	acoesSingleButton,
	actions,
	order,
	ordenation,
	handleCheckbox,
	currentPage
}) {

	const [check, setCheck] = useState(false);
	const [page, setPage] = useState(0);

	const OnCheck = event => {
		handleCheckbox({ value: event.target.checked, selecionarTudo: true });
		setCheck(event.target.checked);
	}

	useEffect(() => {
		if (currentPage !== page && document.getElementById("checkbox") !== null) {
			document.getElementById("checkbox").checked = false;
			setCheck(false);
			setPage(currentPage);
		}
	}, [currentPage]);


	return (
		<thead>
			<TrStyle>
				{columns && columns.map((columnItem, index) => {

					/*
						Testa se o item de alguma coluna é número,
						se for alinha o mesmo a direita.

						Lembrando que deve ser colocado no array de
						objetos-coluna uma propriedade 'number', com o valor
						de false ou true
					*/


					const alignHeader = columnItem.number ? 'text-center' : '';
					return (
						<ThStyle onClick={ordenation && columnItem.checkbox === undefined ? () => onClickOrder(columnItem, order) : null}
							style={ordenation ? { cursor: 'pointer' } : {}} className={alignHeader} key={index.toString()}>
							<div className="flex">

								<span
									className="mr-2"
									style={columnItem.painel ? { textAlign: "left" } : {}} >
									{columnItem.title}
								</span>
								{columnItem.ordenation === undefined && ordenation && columnItem.checkbox === undefined && order.orderColumn === columnItem.property && order.descending &&
									<i className="fas fa-sort-down mt-1"></i>
								}
								{columnItem.ordenation === undefined && ordenation && columnItem.checkbox === undefined && order.orderColumn === columnItem.property && !order.descending &&
									<i className="fas fa-sort-up mt-1"></i>
								}
								{columnItem.ordenation === undefined && ordenation && columnItem.checkbox === undefined && order.orderColumn !== columnItem.property &&
									<i className="fas fa-sort mt-1"></i>
								}
								{
									columnItem.checkbox && <input value={check} id="checkbox" type="checkbox" onChange={(event) => OnCheck(event)}></input>
								}
							</div>

						</ThStyle>
					);
				})}

				{/* Header ação */}
				{(acoesSingleButton || (actions && actions.length <= 1)) && (
					<SingleActionHeader />
				)}

				{/* Header ações */}
				{actions && actions.length > 1 && (
					<MultiActionHeader />
				)}
			</TrStyle>
		</thead>
	);
}

Thead.propTypes = {
	/** true: botão único com ação | false: botão select com várias ações */
	acoesSingleButton: PropTypes.bool,
	/** Array de objetos das actions */
	actions: PropTypes.arrayOf(PropTypes.object),
	/** Array de objetos com as colunas: title, property e number */
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Thead.defaultProps = {
	acoesSingleButton: false,
	actions: null,
	order: null
};

export default Thead;
