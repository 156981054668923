import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/es/Modal';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';

export default function GeradorModal(props) {

	const [locais, setLocais] = useState([]);

	const columns = [
		{ id: 0, title: 'Selecionado', property: 'selecionadoIcon' },
		{ id: 1, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Cultura', property: 'cultura' },
		{ id: 2, title: 'Área em Hectares', property: 'areaHa' },
		{ id: 3, title: 'Parâmetro', property: 'parametro' }
	];

	const actions = [
		{
			id: 0,
			name: 'Selecionar',
			permissao: PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_LEITURA,
			icon: 'fas fa-low-vision circ-item-desativar',
			action: item => seleciona(item),
		}
	];

	useEffect(() => {
		var locais = [...props.locais];
		locais.forEach(item => {
			item.selecionado = true;
			item.selecionadoIcon = <i className="far fa-check-circle ativo-check"></i>
		});
		setLocais(locais);
	}, [props.locais]);

	const seleciona = (item) => {
		item.selecionado = !item.selecionado;
		if (item.selecionado) {
			item.selecionadoIcon = <i className="far fa-check-circle ativo-check"></i>
		} else {
			item.selecionadoIcon = <i className="far fa-times-circle inativo-check"></i>
		}
		setLocais([...locais]);
	}

	const handleSubmit = values => {
		const locaisSelecionados = [];
		locais.forEach(item => {
			if (item.selecionado) {
				locaisSelecionados.push(item.id);
			}
		});
		props.modalClose(values, locaisSelecionados);
		props.close()
	}

	const close = () => {
		props.close()
	}

	return (
		<>
			<Modal dialogClassName="modal-dialog-lg" show={props.show} onHide={() => props.close()}>
				<Modal.Header closeButton >
					<Modal.Title style={{ textAlign: "center" }}>
						Deseja apagar todos planejamentos da safra {props.safra !== undefined ? props.safra.label : ""} antes de prosseguir?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center">
						<label>Somente os locais abaixo selecionados serão alterados</label>
					</div>

					<Table
						align='left'
						acoesSingleButton={true}
						columns={columns}
						actions={actions}
						data={locais}
						emptyMessage="Nenhum item encontrado."
						emptyColSpan={columns ? columns.length + 1 : 1}
					/>

					<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => close()} style={{ width: 120, height: 40 }}>
						<i className='fas fa-close' /> Cancelar
					</button>
					<button className="btn btn-primary active pull-right" type="submit" onClick={() => handleSubmit(true)} style={{ width: 120, height: 40 }}>
						<i className='fas fa-check' /> Sim
					</button>
					<button className="btn btn-outline-secondary pull-right" type="button" onClick={() => handleSubmit(false)} style={{ width: 120, height: 40, marginRight: 10 }}>
						<i className='fas fa-close' /> Não
					</button>
				</Modal.Body>
				<Modal.Footer className="footer-modal">

				</Modal.Footer>
			</Modal>
		</>
	)
}
