import React, { useState, useEffect } from "react";
import logo from "../../assets/logo/logo.png";
import profileEmpty from "../../assets/logo/profileEmpty.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isAuthenticated, removeToken, getUsername, getUserId } from "../../services/auth";
import SideNav, { NavItem, NavIcon, NavText, Toggle } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./menu.scss";
import FazendaService from '../../services/fazendaService';
import SafraService from '../../services/safraService';
import 'react-flags-select/css/react-flags-select.css';
import { Creators as ModulosCreators } from '../../store/ducks/permissao';
import { getTelasDoMenuRapido, getTelasDoMenu } from './telas';
import { GlobalHotKeys } from "react-hotkeys";
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import DropdownCustom from "../DropdownButton/dropdown.component";


const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
		maxWidth: 150,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

const MenuProps = {
	PaperProps: {
		style: {
			width: 250,
			marginTop: 40
		},
	},
};

function Menu(props) {
	const hotKeysKeyMap = {
		MENU_RAPIDO: "ctrl+m"
	};

	const hotKeyshandlers = {
		MENU_RAPIDO: acessarMenuRapido.bind(this)
	};

	const [state, setState] = useState({
		drawer: false,
		userName: "",
		fazendas: [],
		telaAtual: null,
		grupoSafras: [],
		fazendaAtual: null,
		safraId: null,
		urlPowerBI: null,
		selectedSafra: [],
		selectedGrupoSafra: []
	});

	const menuRapidoRef = React.useRef()

	function acessarMenuRapido() {
		menuRapidoRef.current.focus();
	}

	function getValuesParseToSelect(list, valueName, labelName) {
		let options = [];
		valueName = valueName || 'value';
		labelName = labelName || 'label';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	const [fazendas, setfazendas] = useState([]);
	const getFazendas = async () => {
		const { data } = await FazendaService.getAll(localStorage.getItem('userEmail'));
		setfazendas(data.result)
	}

	useEffect(() => {
		getFazendas();
		getSafras();
		getSafraPorGruposSafra();
		if (!isAuthenticated()) props.history.push("/login");
		else {
			setState({ ...state, userName: getUsername() });
			setPermissao();
		}

		if (JSON.parse(localStorage.getItem('fazendaId'))) {
			FazendaService.buscarPorId(JSON.parse(localStorage.getItem('fazendaId')).value).then(response => {
				const fazenda = response.data.result.result;
				if (fazenda && fazenda.urlPowerBI) {
					setState({ ...state, urlPowerBI: fazenda.urlPowerBI });
				} else {
					setState({ ...state, urlPowerBI: null });
				}
			});
		}
		setState({
			...state,
			selectedSafra: JSON.parse(localStorage.getItem('safraId')),
			selectedGrupoSafra: JSON.parse(localStorage.getItem('grupoSafraId'))
		});
	}, []);

	function logout() {
		try {
			removeToken();
			localStorage.clear();
			sessionStorage.clear();
			props.history.push("/login");
		} catch (error) {
			setState({
				...state,
				error: "Houve um problema com o logout"
			});
		}
	}

	const onChangeFazenda = async param => {
		const { target } = param;
		let obj = {
			label: target.value.nomeRazao,
			value: target.value.id,
			atividadeAgricolaId: target.value.atividadeAgricolaId
		}
		localStorage.removeItem('fazendaId');
		localStorage.removeItem('safraId');
		localStorage.removeItem('grupoSafraId');
		localStorage.setItem('fazendaId', JSON.stringify(obj));
		props.history.push("/");

		setPermissao();
		await getSafras();

		// window.location.reload();
	}

	function setPermissao() {
		if (localStorage.getItem('fazendaId')) {
			var usuarioId = getUserId();
			props.permissaoSetar(usuarioId);
		}
	}

	const [grupoSafras, setgrupoSafras] = useState([])
	const getSafras = async () => {
		if (JSON.parse(localStorage.getItem('fazendaId'))) {
			const { data } = await SafraService.getGrupoSafraPorFazendaId(JSON.parse(localStorage.getItem('fazendaId')).value);
			setgrupoSafras(data);
		}
	}

	const [safras, setsafras] = useState([])
	const getSafraPorGruposSafra = async () => {
		let grupoSafraId = JSON.parse(localStorage.getItem('grupoSafraId'));
		if (grupoSafraId === null) return;
		var ids = [];
		grupoSafraId.forEach(safra => {
			ids.push(safra.value);
		});
		const { data } = await SafraService.getSafraPorGruposSafra(ids);
		let arraysafras = data.result
		if (grupoSafraId.length > 1) {
			arraysafras.forEach(item => {
				item.label = `${item.label} - ${item.grupoSafra.nome}`;
			});
		}
		setsafras(getValuesParseToSelect(arraysafras, "value", "label"));
	}



	function verificarArray(value, localStorageItem) {
		const { props } = value;
		var safra = props.value;
		var array = localStorageItem;
		if (array) {
			var find = array.find(x => x.value === safra.value);
			if (find) {
				array = array.filter(x => x.value !== find.value);
				return array;
			}
			else {
				array.push(safra);
				return array
			}
		} else {
			array = [];
			array.push(safra);
			return array;
		}
	}

	const onChangeSafra = (event, value) => {
		var array = verificarArray(value, JSON.parse(localStorage.getItem('safraId')));
		if (array.length > 0)
			localStorage.setItem('safraId', JSON.stringify(array));
		else
			localStorage.removeItem('safraId');
		props.history.push("/");
	}

	const onChangeGrupoSafra = (event, value) => {
		let array = verificarArray(value, JSON.parse(localStorage.getItem('grupoSafraId')));
		if (array.length > 0)
			localStorage.setItem('grupoSafraId', JSON.stringify(array));
		else
			localStorage.removeItem('grupoSafraId');
		localStorage.removeItem('safraId');
		props.history.push("/");

		getSafraPorGruposSafra();
	}

	const [telaAtual, settelaAtual] = useState()
	const onChangeMenu = async item => {
		window.location.href = `/#${item.pathname}`
		settelaAtual(item);
	}

	const isActive = () => {
		if (state.drawer === true) {
			setState({ ...state, drawer: false });
		} else {
			setState({ ...state, drawer: true });
		}
	}


	const telasDoMenuRapido = getTelasDoMenuRapido(props.permissoes, state.urlPowerBI);
	let telasDoMenu = getTelasDoMenu(props.permissoes, state.urlPowerBI);

	const classes = useStyles();


	return (
		<>
			<GlobalHotKeys keyMap={hotKeysKeyMap} handlers={hotKeyshandlers}>
				<SideNav
					expanded={state.drawer}
					onToggle={() => {
						isActive();
					}}
				>
					<Toggle>
						{!state.drawer ? (
							<i className="fas fa-bars menu-icon"></i>
						) : (
							<div style={{ width: 110 }}>
								<span className="toggle-icon-font">
									<i className="fas fa-arrow-left menu-icon"></i>{" "}
										&nbsp; {'Menu'}
								</span>
							</div>
						)}
					</Toggle>
					<SideNav.Nav defaultSelected="home" className={((JSON.parse(localStorage.getItem('fazendaId')) !== null &&
						JSON.parse(localStorage.getItem('safraId')) !== null &&
						JSON.parse(localStorage.getItem('grupoSafraId')) !== null) ? "" : "display-none22")}>
						<hr className="hr-white" />
						<NavItem eventKey="home" onClick={() => props.history.push({ pathname: "/" })}>
							<NavIcon>
								<i
									className="fa fa-fw fa-home"
									style={{ fontSize: "1.65em", color: "#fff !important" }}
								/>
							</NavIcon>
							<NavText>{'Home'}</NavText>
						</NavItem>
						{
							telasDoMenu.map(elemento => {
								return (
									<NavItem eventKey={elemento.grupo.id}>
										<NavIcon>
											<i className={elemento.grupo.icone} style={{ fontSize: "1.65em", color: "#fff !important" }} />
										</NavIcon>
										<NavText>{elemento.grupo.nome}</NavText>
										{
											elemento.itens.map(item => {
												return (<NavItem
													onClick={() => onChangeMenu(item)}
													eventKey={item.value}>
													<NavText>
														<i
															className={`${item.icone} mr-3`}
															style={{ fontSize: "1.25em", color: "#fff !important" }}
														/>
														{item.label}
													</NavText>
												</NavItem>)
											})
										}
									</NavItem>);
							})
						}
					</SideNav.Nav>
				</SideNav>
				<nav className="navbar navbar-expand-md col-sm-12 navbar-custom">
					<div className="navbar-border">
						<a className="navbar-brand" href="#/">
							<img
								src={logo}
								alt="Logo"
								className="logo"
								width={100}
							/>
						</a>
					</div>
					<ul className="navbar-nav">
						<li className="nav-item dropdown">
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-mutiple-checkbox-label">Clientes</InputLabel>
								<Select
									labelId="demo-mutiple-checkbox-label"
									id="demo-mutiple-checkbox"
									value={JSON.parse(localStorage.getItem('fazendaId'))}
									onChange={item => onChangeFazenda(item)}
									input={<Input />}
									renderValue={(selected) => selected.label}
									MenuProps={MenuProps}
								>
									{fazendas.map((obj) => (
										<MenuItem key={obj} value={obj}>
											{obj.nomeRazao}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-mutiple-checkbox-label">Safras</InputLabel>
								<Select
									labelId="demo-mutiple-checkbox-label"
									id="demo-mutiple-checkbox"
									multiple
									value={JSON.parse(localStorage.getItem('grupoSafraId')) || []}
									onChange={onChangeGrupoSafra}
									input={<Input />}
									renderValue={(selected) => selected && selected.map(function (elem) {
										return elem.label;
									}).join(",")}
									MenuProps={MenuProps}
								>
									{grupoSafras.map((obj) => (
										<MenuItem key={obj} value={obj}>
											<Checkbox checked={JSON.parse(localStorage.getItem('grupoSafraId')) &&
												JSON.parse(localStorage.getItem('grupoSafraId')).findIndex(x => x.value === obj.value) > -1} />
											<ListItemText primary={obj.label} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl className={classes.formControl}>
								<InputLabel id="demo-mutiple-checkbox-label">Atividades</InputLabel>
								<Select
									labelId="demo-mutiple-checkbox-label"
									id="demo-mutiple-checkbox"
									multiple
									value={JSON.parse(localStorage.getItem('safraId')) || []}
									onChange={onChangeSafra}
									input={<Input />}
									renderValue={(selected) => selected && selected.map(function (elem) {
										return elem.label;
									}).join(",")}
									MenuProps={MenuProps}
								>
									{safras.map((obj) => (
										<MenuItem key={obj} value={obj}>
											<Checkbox checked={JSON.parse(localStorage.getItem('safraId')) &&
												JSON.parse(localStorage.getItem('safraId')).findIndex(x => x.value === obj.value) > -1} />
											<ListItemText primary={obj.label} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</li>
					</ul>
					<ul className="navbar-nav">
						<li className="nav-item dropdown">
							<div>
								<DropdownCustom
									onChange={(item) => onChangeMenu(item)}
									array={telasDoMenuRapido}
									value={telaAtual}
									label="Menu rápido (Ctrl + M)"
									resetOnChange={true}
									hidden={true}
									ref={menuRapidoRef}
								></DropdownCustom>
							</div>
						</li>
					</ul>
					<ul className="navbar-nav ml-auto">
						<li className="collapsed nav-item dropdown dropdown-menu-right">
							<span
								className="nav-link dropdown-toggle"
								id="navbardrop"
								data-toggle="dropdown"
								style={{ textDecoration: "none" }}
								href="#/"
							>
								<a
									className="navbar-brand"
									href="#/"
									style={{ textDecoration: "none" }}
								>
									<img
										src={profileEmpty}
										alt="Logo"
										width={25}
										height={25}
									/>
									<span className="user-info">
										{state.userName}
									</span>
								</a>
							</span>
							<div className="dropdown-menu dropdown-menu-right">
								{
									JSON.parse(localStorage.getItem('fazendaId')) &&
									<a
										className="dropdown-item dropdown-custom"
										href={`/#/editarUsuario/${getUserId()}`}
										style={{ fontSize: 12 }}
									>
										<i
											className="fas fa-user-edit mr-3"
											style={{ fontSize: "1.25em", color: "#fff !important" }}
										/>
										Editar usuário
									</a>
								}
								<a
									className="dropdown-item dropdown-custom"
									href="#/login"
									onClick={logout}
									style={{ fontSize: 12 }}
								>
									<i
										className="fas fa-sign-out-alt mr-3"
										style={{ fontSize: "1.25em", color: "#fff !important" }}
									/>
									Sair
								</a>
							</div>

						</li>
					</ul>

				</nav>
			</GlobalHotKeys>
		</>
	);
}

const mapStateToProps = state => ({
	loading: state.loading,
	permissoes: state.permissao.permissoes
});


const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Menu);
