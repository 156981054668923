import * as yup from "yup";

export const Schema = yup.object().shape({
	inicio: yup.string().required('Campo obrigatório'),
	fim: yup.string().required('Campo obrigatório'),
	localId: yup.string().required('Campo obrigatório'),
	servicoTipoId: yup.string().required('Campo obrigatório'),
	servicoId: yup.string().required('Campo obrigatório'),

});
