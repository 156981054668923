import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Title from '../../components/Title/title.component';
import Table from '../../components/Table/table.component';
import { NUMERO_ITENS_POR_PAGINA } from '../../constants/index';
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import MaquinaService from '../../services/maquinaService';
import Modal from "../../utils/modalHelper";
import "react-datepicker/dist/react-datepicker.css";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Traducoes from '../../utils/traducoes'
import ToastHelper from "../../utils/toastHelper"
import { PERMISSAO } from '../../permissoes/permissoes';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Utils from '../../utils/utils'

class MaquinaListagem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			showModalExcluir: false,
			idMaquinaExcluir: '',
			showModalAtivaDesativa: false,
			idMaquinaAtivaDesativa: '',
			filtro: '',
			orderColumn: '',
			orderTable: '',
			descending: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
		}
	}

	paginate = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1, pageSize: 10, filtro: '', maquinaTipo: '', order: 'Nome',
		periodoInicial: '', periodoFinal: '', descedescending: false,
		safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
	}


	linguagem = JSON.parse(localStorage.getItem('linguagem'));

	MODO_VISUALIZACAO = "VISUALIZAR"
	MODO_EDICAO = "EDITAR"

	columns = [
		{ id: 1, title: Traducoes.nome(this.linguagem), property: 'nome' },
		{ id: 2, title: Traducoes.tipo(this.linguagem), property: 'maquinaTipo.nome' },
		{ id: 3, title: "Grupo", property: 'grupoMaquinas.nome' },
		{ id: 4, title: "Fazenda", property: 'grupoLocal' },
		{ id: 5, title: Traducoes.status(this.linguagem), property: 'isAtivo' },
	];

	actions = [
		{
			id: 0,
			name: Traducoes.visualizar(this.linguagem),
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_MAQUINA_ATUALIZAR,
			action: item => this.visualizar(item),
		},
		{
			id: 1,
			name: Traducoes.editar(this.linguagem),
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_MAQUINA_ATUALIZAR,
			action: item => this.editar(item),
		},
		{
			id: 2,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
			permissao: PERMISSAO.MODULO_MAQUINA_ATUALIZAR,
			action: item => this.handleModal(item, 'SETAR_STATUS'),
		},
		{
			id: 3,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_MAQUINA_EXCLUIR,
			action: item => this.handleModal(item, 'EXCLUIR'),
		}
	];


	async handleModal(item, modal) {

		if (modal === 'EXCLUIR') {

			if (item) {
				await this.setState({
					...this.state,
					idMaquinaExcluir: item.id,
					showModalExcluir: !this.state.showModalExcluir
				})
			} else {

				await this.setState({
					...this.state,
					showModalExcluir: !this.state.showModalExcluir
				})
			}
		} else if (modal === 'SETAR_STATUS') {

			if (item) {

				await this.setState({
					...this.state,
					idMaquinaAtivaDesativa: item.id,
					showModalAtivaDesativa: !this.state.showModalAtivaDesativa
				})
			} else {

				await this.setState({
					...this.state,
					showModalAtivaDesativa: !this.state.showModalAtivaDesativa
				})
			}
		}
	}

	async handlePaginacao(page) {
		await this.setState({ currentPage: page });
		this.paginate.page = page;
		await this.buscar(this.paginate);
	}

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		let params = this.paginate
		params.filtro = filtro
		params.page = 1
		await this.buscar(params);
	}

	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.buscar(this.paginate);
		}
	}

	async componentDidMount() {

		this.visualizar = this.visualizar.bind(this);
		this.editar = this.editar.bind(this);
		await this.buscar(this.paginate);
	};

	buscar = async (filtro) => {
		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		const { data } = await MaquinaService.buscarTodas(filtro);

		if (data.result) {
			await data.result.items.forEach(item => {
				if (item.ativo) {
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});
			await this.setState({
				...this.state,
				data: {
					...this.state.data,
					items: data.result.items,
					page: data.result.page,
					total: data.result.total
				}
			});
		}
	};

	orderTable = (property, descending) => {
		this.setOrderColumn(Utils.parsePropertToFilter(property));
		this.setDescending(descending);
		let params = this.paginate;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		this.buscar(params)
	}

	setOrderColumn = (property) => {
		this.setState({
			...this.state,
			orderColumn: property
		})
	}

	setDescending = (descending) => {
		this.setState({
			...this.state,
			descending: descending
		})
	}

	tableContent() {
		let linguagem = JSON.parse(localStorage.getItem('linguagem'));
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					acoesSingleButton={false}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					totalItemPages={this.state.data.pageSize}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage={Traducoes.nenhumItemEncontrado(linguagem)}
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
				/>
			</>
		)
	};

	visualizar(item) {
		let modo = this.MODO_VISUALIZACAO
		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));

		this.props.history.push({
			pathname: `/maquina/visualizar/${fazendaAtual.value}/${item.id}/${modo}`
		});
	};

	editar(item) {
		let modo = this.MODO_EDICAO
		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));

		this.props.history.push({
			pathname: `/maquina/visualizar/${fazendaAtual.value}/${item.id}/${modo}`
		});
	};

	async apagar(item) {
		let dados = await MaquinaService.deletar(item);

		if (dados.data.isOk) {
			ToastHelper.success("Máquina excluída com sucesso!");
			await this.buscar(this.paginate);
		} else {
			ToastHelper.error(dados.data.mensagem);
		}
		await this.handleModal(null, 'EXCLUIR');
	};

	async ativaDesativa(item) {
		let dados = await MaquinaService.ativaDesativa(item);

		if (dados.status === 200) {

			ToastHelper.success("Status da máquina alterado com sucesso!");
			await this.buscar(this.paginate);
			await this.handleModal(null, 'SETAR_STATUS');
		} else {

			ToastHelper.error("Erro ao tentar alterar o status da máquina!");
		}
	};

	novaMaquina = () => {
		this.props.history.push({
			pathname: `/maquinas/cadastro`
		});
	}

	render() {
		return (
			<div className="w-100">
				<Title
					acao="Nova máquina"
					permissaoBotao={PERMISSAO.MODULO_MAQUINA_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.novaMaquina}>
					Máquinas
				</Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div>
						{this.tableContent()}
					</div>
				</CardStyle>
				<Modal
					show={this.state.showModalExcluir}
					title={Traducoes.excluirMaquina()}
					buttonTitle={Traducoes.confirmar()}
					button2Title={Traducoes.cancelar()}
					buttom2Action={() => this.handleModal(null, 'EXCLUIR')}
					submit={() => this.apagar(this.state.idMaquinaExcluir)}
					close={() => this.handleModal(null, 'EXCLUIR')}
					content={
						<h6>{Traducoes.mensagemExclusaoMaquina()}</h6>
					}
				/>
				<Modal
					show={this.state.showModalAtivaDesativa}
					title={'Ativar / Desativar máquina'}
					buttonTitle={Traducoes.confirmar()}
					button2Title={Traducoes.cancelar()}
					buttom2Action={() => this.handleModal(null, 'SETAR_STATUS')}
					submit={() => this.ativaDesativa(this.state.idMaquinaAtivaDesativa)}
					close={() => this.handleModal(null, 'SETAR_STATUS')}
					content={
						<h6>{'Deseja realmente alterar o status desta máquina?'}</h6>
					}
				/>
			</div>
		);
	}
}

export default withRouter(MaquinaListagem);
