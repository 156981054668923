export function validateStartHourMeter(horimetroAtivo, error, setError, form) {

	if (horimetroAtivo) {
		if (form.startHourMeter && form.finalHourMeter) {

			if (parseFloat(form.startHourMeter) > parseFloat(form.finalHourMeter)) {

				setError({ ...error, startHourMeter: 'O Horímetro inicial não pode ser maior que o horímetro final.' })
				return false
			} else {
				setError({ ...error, startHourMeter: '' })
				return true
			}
		} else if (!form.startHourMeter) {
			setError({ ...error, startHourMeter: 'O Horímetro inicial é obrigatório.' })
			return false
		} else {
			setError({ ...error, startHourMeter: '' })
			return true
		}
	}
	return true
}

export function validateFinalHourMeter(horimetroAtivo, error, setError, form) {

	if (horimetroAtivo) {
		if (form.startHourMeter && form.finalHourMeter) {

			if (parseFloat(form.startHourMeter) > parseFloat(form.finalHourMeter)) {

				setError({ ...error, finalHourMeter: 'O Horímetro inicial não pode ser maior que o horímetro final.' })
				return false
			} else {
				setError({ ...error, finalHourMeter: '' })
				return true
			}
		} else if (!form.finalHourMeter) {
			setError({ ...error, finalHourMeter: 'O Horímetro final é obrigatório.' })
			return false
		} else {
			setError({ ...error, finalHourMeter: '' })
			return true
		}
	}
	return true
}

export function validateTotalHourMeter(horimetroAtivo, error, setError, form) {

	if (!horimetroAtivo) {
		if (!form.totalHourMeter) {
			setError({ ...error, totalHourMeter: 'Campo obrigatório' })
			return false
		} else {
			setError({ ...error, totalHourMeter: '' })
			return true
		}
	}
	return true
}
