import React from "react";

import { TitleStyle } from "./title.style";
import Can from "../../permissoes/Can"

const Title = props =>
	<TitleStyle>
		<div className='row pb-0 mb'>
			<div className='col-9  pb-0 mb-0'>
				{props.children}
			</div>
			<div className='col-3  pb-0 mb'>
				<Can
					perform={props.permissaoBotao}
					yes={() =>
						<>
							{props.onClick &&
								<button
									className="btn btn-primary active pull-right mb-2"
									onClick={props.onClick}>
									{props.iconBotao &&
										<i className={props.iconBotao}></i>
									}
									<strong>
										{props.acao}
									</strong>
								</button>
							}
						</>
					}
				/>
			</div>
		</div>
	</TitleStyle>;

export default Title;
