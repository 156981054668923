import React from "react";
import clsx from "clsx";
import {
	TextField,
	InputAdornment,
	SvgIcon,
	makeStyles
} from "@material-ui/core";
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Search as SearchIcon } from "react-feather";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});

const useStyles = makeStyles(theme => ({
	root: {},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	linkChangeFilter: {
		marginTop: "50px"
	},
	po: {
		marginBottom: 0,
		paddingBottom: 0
	}
}));

function stopPropagation(event) {
	event.stopPropagation();
}

export default function FiltroSimples(props) {
	const classes = useStyles();

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				xs={12}
				item
				display="flex"
				onClick={
					props.temFiltroAvancado ? () => { } : e => stopPropagation(e)
				}
				className={classes.po}
			>
				<Grid
					xs={10}
					item
					className={classes.po}
					onClick={
						props.temFiltroAvancado
							? () => { }
							: e => stopPropagation(e)
					}
				>
					<TextField
						className={classes.po}
						fullWidth
						onClick={e => stopPropagation(e)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SvgIcon fontSize="small" color="action">
										<SearchIcon
											className={clsx(
												classes.linkChangeFilter,
												props.className
											)}
										/>
									</SvgIcon>
								</InputAdornment>
							)
						}}
						value={props.filtro.filtro}
						placeholder="Pesquisar"
						variant="outlined"
						onChange={e => props.changeFiltro("filtro", e)}
						onKeyDown={e => props.submit(e)}
					/>
				</Grid>
				<Grid
					xs={2}
					item
					container
					direction="row"
					justify="flex-end"
					alignItems="center"
					onClick={e => stopPropagation(e)}
				>
					<Grid
						xs={12}
						item
						container
						direction="row"
						justify="flex-end"
						alignItems="center"
					>
						<Fab
							color="primary"
							size="small"
							aria-label="add"
							onClick={props.onClickAdd}
						>
							<AddIcon />
						</Fab>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
