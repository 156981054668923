import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const DragAndDropFile = (props) => {
  const maxSize = 5242880;
  const onDrop = useCallback(acceptedFiles => {
      props.setImage(acceptedFiles);
  }, []);

  const { isDragActive, getRootProps, getInputProps, isDragReject,  rejectedFiles } = useDropzone({
    onDrop
  });

  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <div className="container text-center drag-and-drop" style={{backgroundColor: 'white'}}>

        <div {...getRootProps()} style={{marginBottom: 20, color: '#555'}}>
            <input {...getInputProps()} />
            {!isDragActive && props.text}
			<br></br>
			<div style={{fontSize: 13}}>{!isDragActive && props.text2}</div>
			<i class="fas fa-file-upload fa-3x" style={{marginTop: 10}}></i>
            {isDragActive && !isDragReject && "Solte o arquivo para carregar!"}
            {isDragReject && "Tipo de arquivo invalido, por favor use arquivos .png,.gif,.jpg!"}
            {isFileTooLarge && (
                <div className="text-danger mt-2">
                Arquivo muito grande, tamanho maximo de 5mb.
                </div>
            )}
        </div>

    </div>
  );
};

export default DragAndDropFile;
