import React from "react";
import Grid from "@material-ui/core/Grid";
import {
	Button,
	TextField,
	makeStyles
} from "@material-ui/core";
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import pt from "date-fns/locale/pt-BR";
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';

const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});

const useStyles = makeStyles(theme => ({
	root: {},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	linkChangeFilter: {
		marginTop: "50px"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: "80%"
	},
	initialGrid: {
		marginBottom: 15
	},
	margin: {
		marginTop: "15px"
	},
	textField: {
		'& input': {
			color: '#000000',
		},
		'& input:disabled': {
			color: '#000000 !important',
		},
	},
}));

const opcoesStatus = [
	{ value: "CONCLUIDO", label: "Concluído" },
	{ value: "CONCLUIDO_COM_ATRASO", label: "Concluído com atraso" },
	{ value: "ATRASADO", label: "Atrasado" },
	{ value: "EM_ANDAMENTO", label: "Em andamento" },
	{ value: "CANCELADO", label: "Cancelado (Inativo)" },
	{ value: "SOLICITADO", label: "Solicitado" },
	{ value: "FUTURO", label: "Futuro" },
]


export default function FiltroSimples(props) {
	const classes = useStyles();

	const keyPress = (e) => {
		if (e.keyCode === 13) {
			props.submit(e);
		}
	};



	return (
		<ThemeProvider theme={theme}>
			<Grid container xs={12} item display="flex">
				<Grid
					container
					xs={12}
					item
					display="flex"
					className={classes.initialGrid}
				>
					<h6>Filtro avançado</h6>
				</Grid>
				{(props.filtroNome || props.filtroOperador) && (
					<Grid container xs={3} item display="flex">
						<FormControl className={classes.formControl}>
							<TextField
								id="nome"
								label={
									props.filtroOperador ? "Operador" : "Nome"
								}
								name="nome"
								type="text"
								autoComplete='off'
								className={classes.textField}
								onChange={e => props.changeFiltro("nome", e)}
								value={props.filtro.nome}
								onKeyDown={e => keyPress(e)}
								InputLabelProps={{
									shrink: true
								}}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroInsumos.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="size-small-standard"
								size="small"
								options={props.filtroInsumos}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("insumosId", event, newValue)
								}
								value={props.filtroInsumos.filter(x => x.value === props.filtro.insumosId)[0]}
								defaultValue={props.filtroInsumos.filter(x => x.value === props.filtro.insumosId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Insumos" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroGrupoMaquina.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="size-small-standard"
								size="small"
								options={props.filtroGrupoMaquina}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("grupoMaquinaId", event, newValue)
								}
								value={props.filtroGrupoMaquina.filter(x => x.value === props.filtro.grupoMaquinaId)[0]}
								defaultValue={props.filtroGrupoMaquina.filter(x => x.value === props.filtro.grupoMaquinaId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Grupo de Máquinas" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroMaquinas.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="maquinaId"
								size="small"
								options={props.filtroMaquinas}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("maquinaId", event, newValue)
								}
								value={props.filtroMaquinas.filter(x => x.value === props.filtro.maquinaId)[0]}
								defaultValue={props.filtroMaquinas.filter(x => x.value === props.filtro.maquinaId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Máquinas" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroPeriodo && (
					<Grid container xs={3} item display="flex">
						<MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
							<TextField
								id="date"
								label="Período inicial"
								type="date"
								className={classes.formControl}
								value={props.filtro.periodoInicial || ''}
								onChange={e => props.changeFiltro("periodoInicial", e, 'date')}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment onClick={(e) => props.changeFiltro("periodoInicial", e, 'date')} position="end">
											<CloseIcon style={{ cursor: 'pointer' }} />
										</InputAdornment>
									)
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				)}
				{props.filtroPeriodo && (
					<Grid container xs={3} item display="flex">
						<MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>

							<TextField
								id="date"
								label="Período final"
								type="date"
								className={classes.formControl}
								value={props.filtro.periodoFinal || ''}
								onChange={e => props.changeFiltro("periodoFinal", e, 'date')}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment onClick={(e) => props.changeFiltro("periodoFinal", e, 'date')} position="end">
											<CloseIcon style={{ cursor: 'pointer' }} />
										</InputAdornment>
									)
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				)}
				{props.filtroGrupoLocais.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="grupoLocalId"
								size="small"
								options={props.filtroGrupoLocais}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("grupoLocalId", event, newValue)
								}
								value={props.filtroGrupoLocais.filter(x => x.value === props.filtro.grupoLocalId)[0]}
								defaultValue={props.filtroGrupoLocais.filter(x => x.value === props.filtro.grupoLocalId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Fazenda" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroLocais.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="localId"
								size="small"
								options={props.filtroLocais}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("localId", event, newValue)
								}
								value={props.filtroLocais.filter(x => x.value === props.filtro.localId)[0]}
								defaultValue={props.filtroLocais.filter(x => x.value === props.filtro.localId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Local" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroGruposServico.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="servicoTipoId"
								size="small"
								options={props.filtroGruposServico}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) => {
									props.changeFiltroAutoComplete("servicoId", null, '');
									props.changeFiltroAutoComplete("servicoTipoId", event, newValue);
								}}
								value={props.filtroGruposServico.filter(x => x.value === props.filtro.servicoTipoId)[0]}
								defaultValue={props.filtroGruposServico.filter(x => x.value === props.filtro.servicoTipoId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Grupo de serviço" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroServicos.length > 0 && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="size-small-standard"
								size="small"
								options={props.filtroServicos}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("servicoId", event, newValue)
								}
								value={props.filtroServicos.filter(x => x.value === props.filtro.servicoId)[0] || ''}
								defaultValue={props.filtroServicos.filter(x => x.value === props.filtro.servicoId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Serviço" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.filtroStatus !== undefined && (
					<Grid container item xs={(props.filtro.status !== undefined && props.filtro.length !== undefined && props.filtro.status.length > 1) ? 7 : 3} display="flex"
						style={(props.filtro.status !== undefined && props.filtro.status.length !== undefined && props.filtro.status.length > 1) ? { paddingRight: '20px' } : {}}>
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="size-small-standard"
								size="small"
								disableCloseOnSelect
								multiple={true}
								options={opcoesStatus}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("status", event, newValue)
								}
								value={props.filtro.status}
								defaultValue={props.filtro.status}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Status" />
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{props.atividades !== undefined && props.atividades && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="size-small-standard"
								size="small"
								options={JSON.parse(localStorage.getItem("safraId"))}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("atividades", event, newValue)
								}
								value={props.filtro.atividades !== undefined ? JSON.parse(localStorage.getItem("safraId")).filter(x => x.value === props.filtro.atividades)[0] : ''}
								defaultValue={props.filtro.atividades !== undefined ? JSON.parse(localStorage.getItem("safraId")).filter(x => x.value === props.filtro.atividades)[0] : ''}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Atividades" />
								)}
							/>
						</FormControl>
					</Grid>
				)}

				{props.safras !== undefined && props.safras && (
					<Grid container item xs={3} display="flex">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="size-small-standard"
								size="small"
								options={JSON.parse(localStorage.getItem("grupoSafraId"))}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									props.changeFiltroAutoComplete("grupoSafraId", event, newValue)
								}
								value={JSON.parse(localStorage.getItem("grupoSafraId")).filter(x => x.value === props.filtro.grupoSafraId)[0] || ''}
								defaultValue={JSON.parse(localStorage.getItem("grupoSafraId")).filter(x => x.value === props.filtro.grupoSafraId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Safras" />
								)}
							/>
						</FormControl>
					</Grid>
				)}


				<Grid
					xs={12}
					container
					item
					direction="row"
					justify="flex-end"
					alignItems="center"
					className={classes.margin}
				>
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={e => props.submit(e)}
					>
						Buscar
					</Button>
				</Grid>
			</Grid>
		</ThemeProvider >
	);
}
