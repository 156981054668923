import api from './api';

export default {

	buscarPorId(localId){
		return api.get(`/api/Local/${localId}`);
	},

	buscarTodos(param){
		return api.post(`/api/Local/BuscarTodas`, param);
	},

	editar(local){
		return api.put(`/api/Local`, local);
	},

	cadastrar(local){
		return api.post(`/api/Local`, local)
	},

	deletar(localId){
		return api.delete(`/api/Local/${localId}`);
	},

	getLocaisSafra(fazendaId, safraId){
		return api.get(`/api/Safra/GetLocaisSafra/${fazendaId}/${safraId}`);
	}
};
