import React, {useState, useEffect} from 'react'
import './simpleSearch.css'

export default function SimpleSearch(props) {

    const [search, setSearch] = useState()

    useEffect(() => {
		if(props.value){
            setSearch(props.value)
        }
    }, [props.value]);
    
    const submit = (event) => {
        if ((event ? event.keyCode : event.which) === 13) {
            props.submit(search)
        }
    }

    const enterSubmit = () => {
        props.submit(search)
    }

    return (
        <>
            {
                props.show !== false &&
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <br></br>
                            <div className="input-group md-form form-sm form-2 pl-0">
                                <input
                                    className="form-control my-0 py-1"
                                    placeholder={props.placeHolder}
                                    value={search}
                                    autoComplete="off"
                                    onChange={(e) => setSearch(e.target.value)}

                                    onKeyDown={(e)=>submit(e)}
                                />
                                <div className="input-group-append">
                                    <button className="input-group-text red lighten-3" id="basic-text1" onClick={enterSubmit} >
                                        <i className="fas fa-search icon-color-green"
                                        aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
