import { all, fork, put, takeLatest } from "redux-saga/effects";
import localService from "../../../services/localService";
import servicoService from "../../../services/servicoService";
import grupoServicoService from "../../../services/grupoServicoService";
import recursosService from "../../../services/recursosService";
import planejamentoSafraService from "../../../services/planejamentoSafraService";
import ToastHelper from "../../../utils/toastHelper";
import { Types } from "./planejamento_safra.ducks";
import executeAction from "../../../store/sagas/executeAction";
import "react-sweet-progress/lib/style.css";
import ObjectHelper from "utils/objectHelper";
const parametros = {
	fazendaId:
		JSON.parse(localStorage.getItem("fazendaId")) !== null
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: null,
	page: 1,
	pageSize: 1000,
	order: "Nome"
};

function* asyncListaPlanejamentos(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		params.safraId = ObjectHelper.primeiraSafra();

		const { data } = yield planejamentoSafraService.buscarTodas(
			params || parametros
		);

		yield put({ type: Types.READ_PLANEJAMENTOS, data: data.result });
		callback();
	}, Types.BUSCAR_PLANEJAMENTOS);
}

function* asyncListaLocais(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield localService.buscarTodos(parametros);

		yield put({ type: Types.SET_LOCAIS, data: data.result });
		callback();
	}, Types.GET_LOCAIS);
}

function* asyncListaGrupoServicos(action) {
	yield executeAction(function* () {
		const { callback } = action;
		const { data } = yield grupoServicoService.buscarTodos(parametros);
		if (data.result) {
			data.result.items.sort(function (a, b) {
				if (a.nome.toLowerCase() < b.nome.toLowerCase()) {
					return -1;
				}
				if (a.nome.toLowerCase() > b.nome.toLowerCase()) {
					return 1;
				}
				return 0;
			});
		}
		yield put({ type: Types.SET_GRUPO_SERVICOS, data: data.result });
		callback();
	}, Types.GET_GRUPO_SERVICOS);
}

function* asyncListaServicos(action) {
	yield executeAction(function* () {
		const { params, callback } = action;

		const { data } = yield servicoService.buscarPorGrupoId(
			parametros.fazendaId,
			params
		);

		yield put({ type: Types.SET_SERVICOS, data: data.result });
		callback();
	}, Types.GET_SERVICOS);
}

function* asyncCreatePlanejamento(action) {
	yield executeAction(function* () {
		const { params, history, callback } = action;

		const { data } = yield planejamentoSafraService.salvar(params);

		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!");
			history.goBack();
		} else ToastHelper.error(data.mensagem);

		yield put({ type: Types.POST_PLANEJAMENTO, data: data });
		callback();
	}, Types.CREATE_PLANEJAMENTO);
}

function* asyncUpdatePlanejamento(action) {
	yield executeAction(function* () {
		const { params, history, callback } = action;

		const { data } = yield planejamentoSafraService.editar(params);

		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!");
			history.goBack();
		} else ToastHelper.error(data.mensagem);

		yield put({ type: Types.PUT_PLANEJAMENTO, data: data });
		callback();
	}, Types.UPDATE_PLANEJAMENTO);
}

function* asyncListaRecursos(action) {
	yield executeAction(function* () {
		const { params, callback } = action;

		const { data } = yield recursosService.buscarTodas(params);

		yield put({ type: Types.SET_RECURSOS, data: data.result });
		callback();
	}, Types.GET_RECURSOS);
}

function* getPlanejamentoById(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield planejamentoSafraService.get(params);
		yield put({
			type: Types.SET_PLANEJAMENTO_BY_ID,
			data: data.result
		});
		callback();
	}, Types.GET_PLANEJAMENTO_BY_ID);
}

function* deletePlanejamentoSafra(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield planejamentoSafraService.deletar(params);
		if (data.isOk) ToastHelper.success("Atividade excluída com sucesso!");
		else ToastHelper.error("Ocorreu um erro.");
		callback();
	}, Types.DELETE_PLANEJAMENTO_SAFRA);
}

function* takeListaGrupoServicos() {
	yield takeLatest(Types.GET_GRUPO_SERVICOS, asyncListaGrupoServicos);
}

function* takeListaLocais() {
	yield takeLatest(Types.GET_LOCAIS, asyncListaLocais);
}

function* takeListaServicos() {
	yield takeLatest(Types.GET_SERVICOS, asyncListaServicos);
}

function* takeListaRecursos() {
	yield takeLatest(Types.GET_RECURSOS, asyncListaRecursos);
}

function* takeCreatePlanejamento() {
	yield takeLatest(Types.CREATE_PLANEJAMENTO, asyncCreatePlanejamento);
}

function* takeUpdatePlanejamento() {
	yield takeLatest(Types.UPDATE_PLANEJAMENTO, asyncUpdatePlanejamento);
}

function* takeReadPlanejamento() {
	yield takeLatest(Types.BUSCAR_PLANEJAMENTOS, asyncListaPlanejamentos);
}

function* takeGetPlanejamentoById() {
	yield takeLatest(Types.GET_PLANEJAMENTO_BY_ID, getPlanejamentoById);
}

function* takeDeletePlanejamentoSafra() {
	yield takeLatest(
		[Types.DELETE_PLANEJAMENTO_SAFRA],
		deletePlanejamentoSafra
	);
}

export default function* root() {
	yield all([fork(takeListaLocais)]);
	yield all([fork(takeListaGrupoServicos)]);
	yield all([fork(takeListaServicos)]);
	yield all([fork(takeListaRecursos)]);
	yield all([fork(takeCreatePlanejamento)]);
	yield all([fork(takeReadPlanejamento)]);
	yield all([fork(takeUpdatePlanejamento)]);
	yield all([fork(takeGetPlanejamentoById)]);
	yield all([fork(takeDeletePlanejamentoSafra)]);
}
