import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getAppointmentManpower: ['params', 'callback'],
    setAppointmentManpower: ['appointmentManpower'],

    deleteAppointmentManpower: ['params', 'callback'],

    getTeams: ['params', 'callback'],
    setTeams: ['params'],

    getMembersTeam: ['params', 'data', 'callback'],
    setMembersTeam: ['params'],

    getPessoas: ['params', 'callback'],
    setPessoas: ['params'],

    getServiceGroups: ['params', 'callback'],
    setServiceGroups: ['serviceGroup'],

    getServicesByGroupId: ['params', 'callback'],
    setServicesByGroupId: ['services'],

    getWorkShifts: ['params', 'callback'],
    setWorkShifts: ['workShifts'],

    getWorkPlaces: ['params', 'callback'],
    setWorkPlaces: ['workPlaces'],

    getTypesStatus: ['params', 'callback'],
    setTypesStatus: ['typesStatus'],

    createManpowerAppointments: ['appointment', 'history', 'callback'],
    postManpowerAppointments: ['params'],

    getManpowerAppointmentById: ['params','setForm', 'callback'],
    setManpowerAppointmentById: ['params'],

    updateManpowerAppointment: ['appointment', 'history', 'callback'],
})

const INITIAL_STATE_VALUES = {
    appointmentManpower: [],
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getAppointmentManpower = (state = INITIAL_STATE_VALUES, action) => ({...state, appointmentManpower: action.data})
const deleteAppointmentManpower = (state = INITIAL_STATE, action) => ( {idDeletedItem: action.params })
const getTeams = (state = INITIAL_STATE, action) => ({ teams: action.data })
const getMembersTeam = (state = INITIAL_STATE, action) => ({...state, membersOfSelectedTeam: action.data })
const getPessoas = (state = INITIAL_STATE, action) => ({...state, supervisors: action.data })
const getTypesStatus = (state = INITIAL_STATE, action) => ({...state, typesStatus: action.data })
const getServiceGroups = (state = INITIAL_STATE, action) =>  ({...state, serviceGroup: action.data})
const getServicesByGroupId = (state = INITIAL_STATE, action) =>  ({...state, services: action.data})
const getWorkShifts = (state = INITIAL_STATE, action) =>  ({...state, workShifts: action.data})
const getWorkPlaces = (state = INITIAL_STATE, action) =>  ({...state, workPlaces: action.data})
const createManpowerAppointments = (state = INITIAL_STATE, action) => ({...state, appointmentManpower: action.data})
const getManpowerAppointmentById = (state = INITIAL_STATE, action) => ({...state, appointment: action.data.data.result})
const updateManpowerAppointment = (state = INITIAL_STATE, action) => ({})

export default createReducer(INITIAL_STATE, {
    [Types.SET_APPOINTMENT_MANPOWER]: getAppointmentManpower,
    [Types.DELETE_APPOINTMENT_MANPOWER]: deleteAppointmentManpower,
    [Types.SET_TEAMS]: getTeams,
    [Types.SET_MEMBERS_TEAM]: getMembersTeam,
    [Types.SET_PESSOAS]: getPessoas,
    [Types.SET_SERVICE_GROUPS]: getServiceGroups,
    [Types.SET_SERVICES_BY_GROUP_ID]: getServicesByGroupId,
    [Types.SET_WORK_SHIFTS]: getWorkShifts,
    [Types.SET_WORK_PLACES]: getWorkPlaces,
    [Types.SET_TYPES_STATUS]: getTypesStatus,
    [Types.POST_MANPOWER_APPOINTMENTS]: createManpowerAppointments,
    [Types.SET_MANPOWER_APPOINTMENT_BY_ID]: getManpowerAppointmentById,
    [Types.UPDATE_MANPOWER_APPOINTMENT]: updateManpowerAppointment,
})
