import { SET_FILTRO_AJUSTE_APONTAMENTO_MA_LISTAGEM } from "../actions/ajusteApontamentoMaquina";
import ObjectHelper from "utils/objectHelper";
export const storageAjusteApontamentoMa = "ajusteApontamentoMa";

const initialState = {
	filtroListagem: {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId")) ? JSON.parse(localStorage.getItem("fazendaId")).value : '',
		atividadesId: JSON.parse(localStorage.getItem("safraId")) ? ObjectHelper.multisafra() : '',
		page: 1,
		pageSize: 10,
		countItems: 0,
		filtro: "",
		servicoTipoId: "",
		servicoId: "",
		maquinaId: "",
		localId: "",
		periodoInicio: "",
		periodoFinal: "",
		descedescending: true,
		order: "Data"
	}
};

export const ajusteApontamentoMa = (state = initialState, action) => {
	switch (action.type) {
		case SET_FILTRO_AJUSTE_APONTAMENTO_MA_LISTAGEM:
			return {
				...state,
				filtroListagem: action.filtroListagem
			};
		default:
			return state;
	}
};
