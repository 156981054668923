import React from "react";
import Title from "../../components/Title/title.component";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import { Formik, Field, ErrorMessage } from "formik";
import { LocalSchema } from "./local.cadastro.validate";
import LocalService from "../../services/localService";
import ToastHelper from "../../utils/toastHelper";
import SubmitFormButtons from "../../components/submitFormButtons/submitFormButtons";
import GrupoLocaisService from "../../services/grupoLocaisService";
import Select from "react-select";
import Utils from "../../utils/utils";
import Toggle from "react-toggle";

export default class PessoaCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: "",
			gruposLocais: [],
			dados: {
				id: undefined,
				nome: undefined,
				descricao: undefined,
				areaHa: undefined,
				grupoLocais: undefined,
				administrativo: false
			},
			areaValida: true,
			visualizar: false,
			editar: false
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		this.getGruposLocais = this.getGruposLocais.bind(this);
		this.getValuesParseToSelect = this.getValuesParseToSelect.bind(this);
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId"));
		await this.setOperacao();
		await this.getGruposLocais();

		await this.setState({
			fazendaId
		});

		const {
			match: { params }
		} = this.props;
		if (params.id) {
			await this.buscar(params.id);
		}
	}

	async getGruposLocais() {
		const parametros = {
			fazendaId:
				JSON.parse(localStorage.getItem("fazendaId")) !== null
					? JSON.parse(localStorage.getItem("fazendaId")).value
					: null,
			page: 1,
			pageSize: 99999,
			filtro: ""
		};

		let { data } = await GrupoLocaisService.getAll(parametros);

		await this.setState({
			gruposLocais: Utils.removeInactiveToSelect(
				Utils.getValuesParseToSelect(data.result.items)
			)
		});
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar");
		var isEditar = url_atual.indexOf("editar");

		isVisualizar > 0
			? this.setState({ visualizar: true })
			: this.setState({ visualizar: false });
		isEditar > 0
			? this.setState({ editar: true })
			: this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar
			? "Editar"
			: this.state.visualizar
			? "Visualizar"
			: "Cadastrar";
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || "id";
		labelName = labelName || "nome";
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] });
		});
		return options;
	}

	async buscar(localId) {
		let { data } = await LocalService.buscarPorId(localId);

		let grupoDeLocalSelecionado = this.state.gruposLocais.filter(
			item => item.value === data.result.grupoLocaisId
		)[0];

		await this.setState({
			dados: {
				...data.result,
				grupoLocais: grupoDeLocalSelecionado
					? grupoDeLocalSelecionado
					: null
			},
			grupoLocaisSelecionado: grupoDeLocalSelecionado
				? grupoDeLocalSelecionado
				: null
		});
	}

	voltar = () => {
		this.history.goBack();
	};

	onSubmit = async values => {
		await this.validateArea(values.areaHa);
		if (this.state.areaValida) {
			let metodo = this.state.editar ? "editar" : "cadastrar";
			values.fazendaId = this.state.fazendaId.value;

			if (values.grupoLocais) {
				values.grupoLocaisId = values.grupoLocais.value;
			}

			const { data } = await LocalService[metodo](values);

			if (data.isOk) {
				ToastHelper.success("Operação concluída com sucesso!");
				this.voltar();
			} else
				ToastHelper.error(
					"Ocorreu um erro. " +
						(data.exception
							? data.exception.Message
							: data.mensagem)
				);
		}
	};

	validateArea = area => {
		if (area < 0) {
			this.setState({
				...this.state,
				areaValida: false,
				errorArea: "Área não pode ser menor que 0"
			});
		} else {
			this.setState({
				...this.state,
				areaValida: true,
				errorArea: ""
			});
		}
	};

	handleAdicionarGrupoLocais = grupo => {
		if (grupo.length > 1) {
			grupo.forEach(item => {
				item.fazendaId = this.state.fazendaId.value;
			});
		} else {
			grupo.fazendaId = this.state.fazendaId.value;
		}
		this.setState({
			...this.state,
			grupoLocaisSelecionado: grupo
		});
	};

	render() {
		const {
			state: { dados }
		} = this;
		return (
			<>
				<Formik
					initialValues={dados}
					enableReinitialize
					validationSchema={LocalSchema || null}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({
						values,
						setFieldValue,
						handleSubmit,
						handleChange,
						handleBlur
					}) => (
						<form className="form " onSubmit={handleSubmit}>
							<div>
								<div className="row">
									<div className="col">
										<Title>
											{this.titulo() + " Local"}
										</Title>
									</div>
								</div>
								<CardStyle className="card">
									<div className="card-header row justify-content-start col-md-12">
										<div className="card-header col-md-12">
											<label className="label">
												Nome:*
											</label>
											<Field
												className="form-control bmd-form-group"
												placeholder="Informe o nome"
												name="nome"
												disabled={this.state.visualizar}
												onChange={handleChange}
												value={values.nome}
												htmlFor="nome"
												maxLength="40"
												autoComplete="off"
												type="text"
											/>
											<ErrorMessage name="nome">
												{msg => (
													<div className="error error-message error-validate  ajust-div-validate">
														{msg}{" "}
													</div>
												)}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-12">
											<label className="label">
												Descrição:
											</label>
											<input
												className="form-control bmd-form-group"
												placeholder="Descreva o local"
												name="descricao"
												disabled={this.state.visualizar}
												onChange={handleChange}
												value={values.descricao}
												htmlFor="descricao"
												type="text"
											/>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Área (ha):*
											</label>
											<Field
												className="form-control bmd-form-group"
												placeholder="Informe a Área (ha):"
												name="areaHa"
												disabled={this.state.visualizar}
												onChange={handleChange}
												onBlur={() =>
													this.validateArea(
														values.areaHa
													)
												}
												value={values.areaHa}
												autoComplete="off"
												htmlFor="areaHa"
												type="number"
											/>
											<ErrorMessage name="areaHa">
												{msg => (
													<div className="error error-message error-validate  ajust-div-validate">
														{msg}{" "}
													</div>
												)}
											</ErrorMessage>
											{!this.state.areaValida && (
												<span className="span-errors">
													{this.state.errorArea}
												</span>
											)}
										</div>

										<div className="card-header col-md-6">
											<label className="label">
												Fazenda:*
											</label>
											<Select
												isDisabled={
													this.state.visualizar
												}
												isClearable={false}
												isMulti={false}
												name={"grupoLocais"}
												value={
													this.state
														.grupoLocaisSelecionado
												}
												placeholder={
													"Selecione a fazenda"
												}
												className="select-style ajust-label-padrao disabled-select"
												onChange={item => {
													if (item) {
														this.handleAdicionarGrupoLocais(
															item
														);
														setFieldValue(
															"grupoLocais",
															item
														);
													}
												}}
												noOptionsMessage={() =>
													"Lista vazia"
												}
												options={
													this.state.gruposLocais
												}
												theme={theme => ({
													...theme,
													borderRadius: 0
												})}
											/>
											<ErrorMessage name="grupoLocais">
												{msg => (
													<div className="error error-message error-validate  ajust-div-validate">
														{msg}{" "}
													</div>
												)}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-12">
											<label className="label">
												Administrativo
											</label>
											<div className="col-md-12">
												<Toggle
													name="administrativo"
													disabled={
														this.state.visualizar
													}
													className="custom-classname"
													value={
														values.administrativo
													}
													checked={
														values.administrativo
													}
													icons={{
														checked: (
															<i className="fas fa-long-arrow-alt-left white-arrow" />
														),
														unchecked: (
															<i className="fas fa-long-arrow-alt-right white-arrow" />
														)
													}}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
								</CardStyle>
								<SubmitFormButtons
									flagVisualizar={this.state.visualizar}
									flagEditar={
										this.state.dados.id !== undefined
									}
									actionGoBack={() => this.voltar()}
								/>
							</div>
						</form>
					)}
				</Formik>
			</>
		);
	}
}
