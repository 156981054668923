import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getGrupoLocais: ['params',  'callback'],
	readGrupoLocais: ['params'],

	createGrupoLocais: ['params', 'history', 'callback'],
	postGrupoLocais: ['params'],

	getByIdGrupoLocais: ['params', 'setUnitsConversions','callback'],
	setGetByIdGrupoLocais: ['params'],

	updateGrupoLocais: ['params', 'history'],

	deletarGrupoLocais: ['params', 'props', 'callback'],
})

const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getGrupoLocais = (state = INITIAL_STATE, action) => state.merge({itens: action.data})
const createGrupoLocais = (state = INITIAL_STATE, action) => state.merge({item: action.data})
const getByIdGrupoLocais = (state = INITIAL_STATE, action) => state.merge( action.data )
const updateGrupoLocais = (state = INITIAL_STATE, action) => state.merge( action.params )
const deletarGrupoLocais = (state = INITIAL_STATE, action) => state.merge({id: action.params })

export default createReducer(INITIAL_STATE, {
	[Types.SET_GET_BY_ID_GRUPO_LOCAIS]: getByIdGrupoLocais,
	[Types.READ_GRUPO_LOCAIS]: getGrupoLocais,
	[Types.POST_GRUPO_LOCAIS]: createGrupoLocais,
	[Types.UPDATE_GRUPO_LOCAIS]: updateGrupoLocais,
	[Types.DELETAR_GRUPO_LOCAIS]: deletarGrupoLocais
})
