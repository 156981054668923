import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getInsumoAnaliseSolo: ['params',  'callback'],
	readInsumoAnaliseSolo: ['params'],

	createInsumoAnaliseSolo: ['params', 'history', 'callback'],
	postInsumoAnaliseSolo: ['params'],

	getByIdInsumoAnaliseSolo: ['params', 'setUnitsConversions','callback'],
	setGetByIdInsumoAnaliseSolo: ['params'],

	updateInsumoAnaliseSolo: ['params', 'history'],

	deleteInsumoAnaliseSolo: ['params', 'props', 'callback'],

	getLocations: ['params', 'callback', 'toFilter'],
    setLocations: ['location'],
    
    getServiceGroups: ['params', 'callback'],
    setServiceGroups: ['serviceGroup'],
    
    getServicesByGroupId: ['params', 'callback'],
    setServicesByGroupId: ['services'],
    
    getFamilyMaterials: ['params', 'callback'],
    setFamilyMaterials: ['familyMaterials'],
    
    getMaterialsByFamily: ['params', 'callback'],
    setMaterialsByFamily: ['params'],
})

const INITIAL_STATE_VALUES = {
	insumosAnaliseSolo: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getInsumoAnaliseSolo = (state = INITIAL_STATE, action) => state.merge({...state, insumosAnaliseSolo: action.data})
const createInsumoAnaliseSolo = (state = INITIAL_STATE, action) => state.merge({item: action.data})
const getByIdInsumoAnaliseSolo = (state = INITIAL_STATE, action) => state.merge({...state, insumoAnaliseSolo: action.data})
const updateInsumoAnaliseSolo = (state = INITIAL_STATE, action) => state.merge( action.params )
const deleteInsumoAnaliseSolo = (state = INITIAL_STATE, action) => state.merge({id: action.params })
const getLocations = (state = INITIAL_STATE, action) => state.merge({...state, locais: action.data })
const getServiceGroups = (state = INITIAL_STATE, action) => state.merge({...state, servicoTipos: action.data })
const getServicesByGroupId = (state = INITIAL_STATE, action) => state.merge({...state, servicos: action.data })
const getFamilyMaterials = (state = INITIAL_STATE, action) => state.merge({...state, familiasMateriais: action.data })
const getMaterialsByFamily = (state = INITIAL_STATE, action) => state.merge({...state, materiais: action.data })


export default createReducer(INITIAL_STATE, {
	[Types.SET_GET_BY_ID_INSUMO_ANALISE_SOLO]: getByIdInsumoAnaliseSolo,
	[Types.READ_INSUMO_ANALISE_SOLO]: getInsumoAnaliseSolo,
	[Types.POST_INSUMO_ANALISE_SOLO]: createInsumoAnaliseSolo,
	[Types.UPDATE_INSUMO_ANALISE_SOLO]: updateInsumoAnaliseSolo,
	[Types.DELETE_INSUMO_ANALISE_SOLO]: deleteInsumoAnaliseSolo,
    [Types.SET_LOCATIONS]: getLocations,
    [Types.SET_SERVICE_GROUPS]: getServiceGroups,
    [Types.SET_SERVICES_BY_GROUP_ID]: getServicesByGroupId,
    [Types.SET_FAMILY_MATERIALS]: getFamilyMaterials,
    [Types.SET_MATERIALS_BY_FAMILY]: getMaterialsByFamily,
})
