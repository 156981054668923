import React, { useEffect, useState, useRef } from 'react';
import MaterialTable from 'material-table';
import Select, { components } from 'react-select';
import { TextField, List, ListItem, ListItemText, ListItemIcon, Grid } from '@material-ui/core';
import RecursosService from '../../services/recursosService';
import "./tabelaApontamentoInsumos.scss";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ObjectHelper from "../../utils/objectHelper";
import EditarReceitaModal from './modalEditarReceita.component';

const TabelaRecursos = (props) => {
	const {
		titulo = "Insumos",
		recursos = [],
		onAdd = () => { },
		onEdit = () => { },
		onDelete = () => { },
		opcoesRecursosComCodigo = [],
		removerClasses = [],
		recursosMenuPlacement = null
	} = props;

	const [recursosTabela, setRecursosTabela] = useState([]);
	const [opcoesRecursos, setOpcoesRecursos] = useState([]);
	const [listaRecursos, setListaRecursos] = useState([]);
	const [showModalEditarReceita, setShowModalEditarReceita] = useState(false);
	const [itensReceitaParaEditar, setItensReceitaParaEditar] = useState(null);
	const [volumeMistura, setVolumeMistura] = useState(null);
	const [quantidadeReceita, setQuantidadeReceita] = useState(null);
	const [quantidadeError, setQuantidadeError] = useState({
		error: false,
		helperText: '',
	});
	const [recursoError, setRecursoError] = useState({
		error: false,
		helperText: '',
	});
	const [unidadeError, setUnidadeError] = useState({
		error: false,
		helperText: '',
	});
	const tableRef = useRef(null);

	const getRecursos = async () => {
		const resposta = await RecursosService.getPorCodigoTipo({
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			codigosTipo: opcoesRecursosComCodigo
		});
		let recursosFiltrados = resposta.data.result.filter(
			item => !removerClasses.includes(item.categoria)
		);
		setListaRecursos(recursosFiltrados);

		const itensAgrupados = getItensAgrupados(recursosFiltrados);
		setOpcoesRecursos(itensAgrupados);

		return recursosFiltrados;
	}

	useEffect(() => {
		getRecursos();
	}, []);

	useEffect(() => {
		if (recursos) {
			// É necessário fazer essa cópia devido a esse bug da Material-Table
			// https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
			setRecursosTabela(JSON.parse(JSON.stringify(recursos)));
		}
	}, [recursos]);

	const getItensAgrupados = (itens) => {
		let itensAgrupados = [];
		const tipos = itens.map(item => item.tipo.nome)
			.filter((value, index, self) => self.indexOf(value) === index);
		tipos.forEach(element => {
			itensAgrupados.push({
				label: element,
				options: itens.filter(item => item.tipo.nome === element).map(item => {
					return {
						label: item.nome,
						value: item.id
					};
				})
			})
		});
		return itensAgrupados;
	}

	// handle options group header click event
	// hide and show the options under clicked group
	const handleHeaderClick = id => {
		const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
		const classes = node.classList;
		if (classes.contains("grupoRecursosCollapsed")) {
			node.classList.remove("grupoRecursosCollapsed");
		} else {
			node.classList.add("grupoRecursosCollapsed");
		}
	};

	// Create custom GroupHeading component, which will wrap
	// react-select GroupHeading component inside a div and
	// register onClick event on that div
	const CustomGroupHeading = props => {
		return (
			<div
				className="group-heading-wrapper"
				onClick={() => handleHeaderClick(props.id)}>
				<components.GroupHeading {...props} />
			</div>
		);
	};

	const formatGroupLabel = data => (
		<div className='groupStyles'>
			<span>{data.label}</span>
			<span className='groupBadgeStyles'>{data.options.length}</span>
		</div>
	);

	const groupedStyles = {
		menuPortal: base => ({ ...base, zIndex: 9999, width: '250px' }),
		groupHeading: (provided) => ({
			...provided,
			textTransform: 'none',
			fontSize: 16,
			color: 'black'
		}),
		option: (provided) => ({
			...provided,
		}),
	}

	const validaItem = item => {
		let valido = true;

		// Recurso
		if (!item.recurso) {
			setRecursoError({
				error: true,
				helperText: 'Obrigatório',
			});
			valido = false;
		} else {
			setRecursoError({
				error: false,
				helperText: '',
			});
		}

		// Unidade
		if (!item.unidade) {
			setUnidadeError({
				error: true,
				helperText: 'Obrigatório',
			});
			valido = false;
		} else {
			setUnidadeError({
				error: false,
				helperText: '',
			});
		}

		// Quantidade Total
		if (!item.quantidade) {
			setQuantidadeError({
				error: true,
				helperText: 'Obrigatório',
			});
			valido = false;
		}

		if (item.quantidade <= 0) {
			setQuantidadeError({
				error: true,
				helperText: 'Quantidade menor ou igual a zero',
			});
			valido = false;
		}
		return valido;
	}

	const cancelar = () => {
		setRecursoError({
			error: false,
			helperText: ''
		});

		setQuantidadeError({
			error: false,
			helperText: ''
		});
	}

	const colunasRecursos = [
		{ title: 'Tipo', field: 'recurso.tipo.nome', editable: 'never' },
		{ title: 'Categoria', field: 'recurso.categoria', editable: 'never' },
		{
			title: 'Recurso', field: 'recurso.nome', editComponent: props => (
				<div>
					<Select
						id="recursos"
						name="recursos"
						menuPlacement={recursosMenuPlacement ? recursosMenuPlacement : 'top'}
						value={props.rowData.recurso ? { key: props.rowData.recurso.id, label: props.value } : {}}
						styles={groupedStyles}
						menuPortalTarget={document.body}
						components={{ GroupHeading: CustomGroupHeading }}
						formatGroupLabel={formatGroupLabel}
						placeholder='Selecionar'
						className="select-style ajust-label-padrao disabled-select"
						options={opcoesRecursos}
						onChange={item => {
							const recurso = listaRecursos.filter(element => element.id === item.value)[0];
							var data = { ...props.rowData };
							data.recurso = recurso;

							if (recurso !== undefined) {
								let units = ObjectHelper.parseValuesUnitsToSelect(
									recurso.unidade
								);

								data.unidades = units;
								data.unidade = recurso.unidade;
							} else {
								data.unidade = null;
								data.unidades = null;
							}

							props.onRowDataChange(data);
						}}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{recursoError.error && <span className="span-errors">{recursoError.helperText}</span>}
				</div>
			)
		},
		{
			title: 'Unidade', field: 'unidade.sigla',
			editComponent: props => (
				<div>
					<Select
						id="unidade"
						name="unidade"
						menuPlacement={recursosMenuPlacement ? recursosMenuPlacement : 'top'}
						value={
							props.rowData.unidade
								? {
									key: props.rowData.unidade.id,
									label: props.value
								}
								: {}

						}
						styles={groupedStyles}
						menuPortalTarget={document.body}
						components={{ GroupHeading: CustomGroupHeading }}
						formatGroupLabel={formatGroupLabel}
						placeholder='Selecionar'
						className="select-style ajust-label-padrao disabled-select"
						options={props.rowData.unidades}
						onChange={item => {
							const unidade = item;
							var data = { ...props.rowData };
							data.unidade = unidade;
							props.onRowDataChange(data);
						}}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{unidadeError.error && <span className="span-errors">{unidadeError.helperText}</span>}
				</div>
			)
		},
		{
			title: 'Quantidade', field: 'quantidade', type: 'numeric', editComponent: props => {
				return (
					<TextField
						type='number'
						step='any'
						value={props.value}
						error={quantidadeError.error}
						helperText={quantidadeError.helperText}
						onChange={e => {
							var data = { ...props.rowData };
							data.quantidade = e.target.value;
							props.onRowDataChange(data);
						}}
					/>
				)
			}
		}
	];

	const formatItensReceita = (rowData) => {
		let itensReceita = JSON.parse(JSON.stringify(rowData.recurso.receita));
		itensReceita.forEach(item => {
			item.recurso = JSON.parse(JSON.stringify(item));
			item.unidade = {
				value: item.unidade.id,
				label: item.unidade.sigla,
				unidadeConversao: item.unidade.unidadeConversao
			};
			item.unidades = ObjectHelper.parseValuesUnitsToSelect(
				item.unidade
			);
		});
		return itensReceita;
	}

	const renderDetailPanel = (rowData) => {
		return (<div className="col-md-12 mt-3">
			<div className="col-md-12 mt-3">
				<h6 style={{ fontWeight: "bold" }}>{`Detalhes da Receita:`}</h6>
			</div>
			<div className="col-md-12 mt-3">
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<div className="col-md-12 mt-3">
							<h6 style={{ fontWeight: "bold" }}>{`Total para ${rowData.recurso.volumeMistura} litros de mistura`}</h6>
						</div>
						<List dense={true}>
							{rowData.recurso.receita.map(item =>
								<ListItem>
									<ListItemIcon>
										<FiberManualRecordIcon fontSize='small' />
									</ListItemIcon>
									<ListItemText
										primary={`${item.quantidade} ${item.unidade.sigla} de ${item.nome}`}
									/>
								</ListItem>
							)}
						</List>
					</Grid>
					<Grid item xs={4}>
						<div className="col-md-12 mt-3">
							<h6 style={{ fontWeight: "bold" }}>{`Total para ${rowData.quantidade} litros de mistura`}</h6>
						</div>
						<List dense={true}>
							{rowData.recurso.receita.map(item =>
								<ListItem>
									<ListItemIcon>
										<FiberManualRecordIcon fontSize='small' />
									</ListItemIcon>
									<ListItemText
										primary={`${Math.round(((item.quantidade * (rowData.quantidade / rowData.recurso.volumeMistura)) + Number.EPSILON) * 100) / 100} ${item.unidade.sigla} de ${item.nome}`}
									/>
								</ListItem>
							)}
						</List>
					</Grid>
				</Grid>
			</div>
			<div className="col-md-12 mt-3">
				<button
					className="btn btn-primary pull-left"
					type="button"
					onClick={() => {
						setVolumeMistura(rowData.recurso.volumeMistura);
						setQuantidadeReceita(rowData.quantidade);
						setItensReceitaParaEditar(formatItensReceita(rowData));
						setShowModalEditarReceita(true);
					}}
				>
					<i className="fas fa-edit" /> Editar
			  </button>
				<button
					className="btn btn-primary pull-left"
					type="button"
					onClick={() => {
						const win = window.open(`#/materiais/duplicar/${rowData.recurso.insumosId}/source=recursos`, "_blank");
						win.addEventListener('beforeunload', () => {
							getRecursos().then((lista) => {
								const recurso = lista.filter(
									element => element.id === rowData.recurso.id
								)[0];
								rowData.recurso = recurso;
							});
						}, false);
					}}
				>
					<i className="fas fa-clone" /> Duplicar
			  </button>
			</div>
		</div>);
	}

	const detailPanel = [
		rowData => ({
			disabled: !(rowData.recurso && rowData.recurso.tipo.codigo === "RE" && rowData.recurso.receita),
			render: () => renderDetailPanel(rowData)
		})
	];

	const editarReceita = (insumos) => {
		const dados = JSON.parse(JSON.stringify(insumos));
		setRecursosTabela(dados);
		onEdit(dados);
	}

	return (
		<div>
			<MaterialTable
				tableRef={tableRef}
				title={titulo}
				columns={colunasRecursos}
				data={recursosTabela}
				options={{
					paging: false,
					actionsColumnIndex: -1
				}}
				editable={{
					onRowAdd: newData =>
						new Promise((resolve, reject) => {
							const valido = validaItem(newData);
							if (valido) {
								onAdd(newData);
								resolve();
							} else {
								reject();
							}
						}
						),
					onRowUpdate: (newData, oldData) =>
						new Promise((resolve, reject) => {
							const valido = validaItem(newData);
							if (valido) {
								const dataUpdate = [...recursosTabela];
								const index = oldData.tableData.id;
								dataUpdate[index] = newData;
								onEdit(dataUpdate);
								resolve();
							} else {
								reject();
							}
						}
						),
					onRowDelete: oldData =>
						new Promise((resolve, reject) => {
							const dataDelete = [...recursosTabela];
							const index = oldData.tableData.id;
							dataDelete.splice(index, 1);
							onDelete(dataDelete);
							resolve();
						}
						),
					onRowAddCancelled: () => {
						cancelar();
					},
					onRowUpdateCancelled: () => {
						cancelar();
					},
				}}
				localization={{
					header: {
						actions: 'Ações'
					},
					body: {
						emptyDataSourceMessage: 'Nenhum registro para exibir',
						addTooltip: 'Adicionar',
						deleteTooltip: 'Excluir',
						editTooltip: 'Editar',
						editRow: {
							deleteText: 'Deseja excluir essa linha?',
							cancelTooltip: 'Cancelar',
							saveTooltip: 'Confirmar'
						}
					},
					toolbar: {
						searchTooltip: 'Pesquisar',
						searchPlaceholder: 'Pesquisar'
					}
				}}
				detailPanel={detailPanel}
			/>
			<EditarReceitaModal
				show={showModalEditarReceita}
				submit={editarReceita}
				close={() => setShowModalEditarReceita(false)}
				volumeMistura={volumeMistura}
				quantidadeReceita={quantidadeReceita}
				insumos={itensReceitaParaEditar}
			/>
		</div>
	);
};

export default TabelaRecursos;
