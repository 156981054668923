import React from 'react';
import { useForm } from "react-hook-form";
import Select from "react-select";

export default function RevisaoRecursosFiltros(props) {

	const { register, handleSubmit, setValue } = useForm({});
	const onSubmit = data => {
		props.filtrar(data);
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-2">
						<strong style={{ fontSize: 15 }}>Grupo de Serviços:</strong>
						<Select
							id="servicoTipoId"
							name="servicoTipoId"
							placeholder='Selecionar'
							className=""
							options={props.grupoServicos}
							isClearable={true}
							onChange={element => {
								setValue('servicoTipoId', element ? element.value : undefined);
								// changeRecursoTipo(recursoTipo.label)
							}}
							theme={theme => ({
								...theme,
								borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
							})}
							ref={() => register({ name: "servicoTipoId", required: true })}
						/>
					</div>
					<div className="col-md-2">
						<strong style={{ fontSize: 15 }}>Serviços:</strong>
						<Select
							id="servicoId"
							name="servicoId"
							placeholder='Selecionar'
							isClearable={true}
							onChange={element => {
								setValue('servicoId', element ? element.value : undefined);
								// changeRecursoTipo(recursoTipo.label)
							}}
							options={props.servicos}
							theme={theme => ({
								...theme,
								borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
							})}
							ref={() => register({ name: "servicoId", required: true })}
						/>
					</div>
					<div className="col-md-2">
						<strong style={{ fontSize: 15 }}>Locais:</strong>
						<Select
							id="localId"
							name="localId"
							placeholder='Selecionar'
							isClearable={true}
							onChange={element => {
								setValue('localId', element ? element.value : undefined);
								// changeRecursoTipo(recursoTipo.label)
							}}
							options={props.local}
							theme={theme => ({
								...theme,
								borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
							})}
							ref={() => register({ name: "localId", required: true })}
						/>
					</div>
					<div className="col-md-2">
						<strong style={{ fontSize: 15 }}>Tipos de Recursos:</strong>
						<Select
							id="recursosTipoId"
							name="recursosTipoId"
							placeholder='Selecionar'
							isClearable={true}
							onChange={element => {
								setValue('recursosTipoId', element ? element.value : undefined);
								props.changeRecursoTipo(element.label)
							}}
							options={props.recursosTipo}
							theme={theme => ({
								...theme,
								borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
							})}
							ref={() => register({ name: "recursosTipoId", required: true })}
						/>
					</div>
					<div className="col-md-2">
						<strong style={{ fontSize: 15 }}>Calsses de Recursos:</strong>
						<Select
							id="recursosClasseId"
							name="recursosClasseId"
							placeholder='Selecionar'
							isClearable={true}
							onChange={element => {
								setValue('recursosClasseId', element ? element.value : undefined);
								// changeRecursoTipo(recursoTipo.label)
							}}
							options={props.recursosClasse}
							theme={theme => ({
								...theme,
								borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
							})}
							ref={() => register({ name: "recursosClasseId", required: true })}
						/>
					</div>
					<div className="col-md-2">
						<strong style={{ fontSize: 15 }}>Recursos:</strong>
						<Select
							id="recursosId"
							name="recursosId"
							placeholder='Selecionar'
							isClearable={true}
							onChange={element => {
								setValue('recursosId', element ? element.value : undefined);
								// changeRecursoTipo(recursoTipo.label)
							}}
							options={props.recursos}
							theme={theme => ({
								...theme,
								borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
							})}
							ref={() => register({ name: "recursosId", required: true })}
						/>
					</div>
				</div>
				<br></br>
				<div className="row">
					<div className="col-md-9">

					</div>
					<div className="col-md-3 pull-right">
						<button
							className="btn btn-primary active pull-right"
							onClick={handleSubmit}>
							<i className='fas fa-search' /> Buscar
						</button>

					</div>
				</div>

			</form>
		</>
	)
}
