import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Creators as ModulosCreators } from './store/units.ducks';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';
import Utils from '../../utils/utils';

function ConversionUnits(props) {
	const params = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1,
		pageSize: 99999,
		filtro: '',
	};

	const [unitConversion, setUnitConversion] = useState();
	const [valueConversion, setValueConversion] = useState();
	const [optionsUnits, setOptionsUnits] = useState([]);

	const columns = [
		{ id: 0, title: 'Unidade', property: 'sigla' },
		{ id: 1, title: 'Conversão', property: 'valor' },
	];

	const actions = [
		{
			id: 0,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_UNIDADE_CRIAR,
			action: item => deleteUnitConversion(item),
		},
		{
			id: 1,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_UNIDADE_CRIAR,
			action: item => EditUnitConversion(item),
		},
	];

	useEffect(() => {
		let newOptions = [...optionsUnits]
		newOptions.forEach(unity => {
			props.unitsConversion.forEach(addUnity => {
				if (unity.value === addUnity.value) {
					newOptions.splice(newOptions.indexOf(unity), 1)
					setOptionsUnits(newOptions)
				}
			})

		});
	}, [props.unitsConversion, props.showComponent])

	useEffect(() => {
		let newOptions = [...optionsUnits]
		newOptions.forEach(unity => {
			if (props.params.id ? unity.value === props.params.id : false) {
				newOptions.splice(newOptions.indexOf(unity), 1)
				setOptionsUnits(newOptions)
			}
		})
	}, [optionsUnits])

	const EditUnitConversion = (item) => {
		if (item) {
			const newList = [...props.unitsConversion];
			newList.splice(newList.indexOf(item), 1);
			props.setUnitsConversion(newList);
			props.getUnits(params, props);
			setUnitConversion({ value: item.unidadeConvertidaId, label: item.sigla });
			setValueConversion(item.valor);
		}
	};

	const deleteUnitConversion = (item) => {
		if (item) {
			const newList = [...props.unitsConversion];
			newList.splice(newList.indexOf(item), 1);
			props.setUnitsConversion(newList);
			props.getUnits(params, props);
		}
	};

	useEffect(() => {
		if (localStorage.getItem('fazendaId')) {
			props.getUnits(params, props);
		}
	}, []);

	useEffect(() => {
		setOptionsUnits(props.unitsToSelect ? Utils.getValuesParseToSelect(props.unitsToSelect.items, 'id', 'sigla') : []);
	}, [props.unitsToSelect]);

	useEffect(() => {
		if (props.unitsConversion && optionsUnits) {
			props.updateOptionsUnits(optionsUnits, setOptionsUnits, props.unitsConversion);
		}
	}, [props.unitsConversion, optionsUnits]);

	const tableUnitsConversions = () => (
		<Table
			className='mt-3'
			align='left'
			columns={columns}
			actions={!props.visualize ? actions : null}
			acoesSingleButton={false}
			data={props.unitsConversion}
			emptyMessage='Nenhum item adicionado.'
			emptyColSpan={columns ? columns.length + 1 : 0}
		/>
	);

	const addUnitCOnversion = useCallback(() => {
		if (unitConversion && valueConversion) {
			const newUnits = [...props.unitsConversion];
			newUnits.push({ unidadeConvertidaId: unitConversion.value, sigla: unitConversion.label, valor: parseFloat(valueConversion) });
			props.setUnitsConversion(newUnits);
			setUnitConversion(null);
			setValueConversion('');
		}
	}, [unitConversion, valueConversion, setUnitConversion, setValueConversion,]);

	return (
		<>
			{ !props.visualize && props.showComponent && (
				<div className='col-md-12 flex card-externo-horas mt-3'>
					<div className='col-md-12 flex card-border pt-3'>
						<div className='col-md-5'>
							<label className='label mt-1'>Unidade:</label>
							<div className='col-md-12 card-interno-border '>
								<Select
									id='unitConversion'
									name='unitConversion'
									htmlFor='unitConversion'
									className='select-style ajust-label-padrao disabled-select'
									options={optionsUnits}
									isDisabled={false}
									onChange={e => setUnitConversion(e)}
									placeholder='Selecione a unidade'
									value={unitConversion}
									hideSelectedOptions
									isClearable
									theme={theme => ({
										...theme,
										borderRadius: 0,
									})}
								/>
							</div>
						</div>
						<div className='col-md-5'>
							<label className='label mt-1'>Valor de conversão:</label>
							<div className='col-md-12 card-interno-border '>
								<input
									className='form-control select-style ajust-label-padrao'
									name='valueConversion'
									id='valueConversion'
									autoComplete="off"
									disabled={false}
									onChange={e => setValueConversion(e.target.value)}
									value={valueConversion}
									htmlFor='valueConversion'
									step='any'
									type='number'
								/>
							</div>
						</div>
						<div data-tip='col-md-2'>
							<label className='label mt-1 ml-5'>Adicionar</label>
							<div className='col-md-12 label-horas mt-3 ml-5'>
								<div data-tip='Adicionar unidade de conversão' className='col-md-12 flex padding-input-modal'>
									<button
										className='btn btn-primary btn-style active'
										type='button'
										disabled={!unitConversion || !valueConversion}
										onClick={() => addUnitCOnversion()}
									>
										<i className='fas fa-plus' />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
			}
			{
				props.showComponent &&
				tableUnitsConversions()
			}
		</>
	);
}

const mapStateToProps = state => ({
	unitsToSelect: state.units.itens,
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConversionUnits);
