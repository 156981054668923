import React from 'react';
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import Title from "../../components/Title/title.component"
import Table from '../../components/Table/table.component';
import TemplatePlanejamentoService from '../../services/templatePlanejamentoService'
import ToastHelper from "../../utils/toastHelper";
import Can from '../../permissoes/Can';
import { PERMISSAO } from '../../permissoes/permissoes';
import Modal from "../../utils/modalHelper";
import ModalDuplicacao from './template.duplicacao-modal';
import Utils from '../../utils/utils';

const NUMERO_ITENS_POR_PAGINA = 10;

export default class TemplateListagem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			filtro: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
			modalDuplicar: false,
			templateDuplicado: {}
		}
		this.history = this.props.history
	}

	async componentDidMount() {
		let fazenda = JSON.parse(localStorage.getItem('fazendaId'));
		this.setState({
			fazendaId: fazenda.value,
		});
		await this.buscar(this.paginate);

		this.duplicar.bind(this)
	}



	paginate = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1, pageSize: 10, filtro: '', order: 'Nome', descedescending: false,
		safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
	}

	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Descrição', property: 'descricao' },
		{ id: 2, title: 'Data', property: 'data' },
		{ id: 3, title: 'Cultura', property: 'cultura' },
	];

	actions = [
		{
			id: 1,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_TURNO_LEITURA,
			action: item => this.editar(item),
		},

		{
			id: 2,
			name: 'Duplicar',
			icon: 'fas fa-copy',
			permissao: PERMISSAO.MODULO_TURNO_EXCLUIR,
			action: item => this.modalDuplicar(item),
		},
		{
			id: 3,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_TURNO_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];


	visualizar(item) {
		this.MODO = 'visualizar'
		this.history.push(`/planejamento/template/visualizar/${item.id}`);
	}

	cadastrar = () => {
		this.MODO = 'cadastrar';
		this.history.push(`/planejamento/template/${this.MODO}`);
	}

	editar(item) {
		this.MODO = 'editar'
		this.history.push({
			pathname: `/planejamento/template/editar/${item.id}`
		});

	}

	async apagar(item) {
		let dados = await TemplatePlanejamentoService.deletar(item);
		if (dados.data.isOk) {
			ToastHelper.success("Modelo de Manejo excluído com sucesso!");
			await this.buscar(this.paginate);
		} else {
			ToastHelper.error(dados.data.mensagem)
		}
		await this.handleModalExcluir();
	}

	async handlePaginacao(page) {
		await this.setState({ currentPage: page });
		let newPaginate = this.paginate
		newPaginate.page = page
		await this.buscar(newPaginate);
	}


	setOrderColumn = (property) => {
		this.setState({
			...this.state,
			orderColumn: property
		})
	}

	setDescending = (descending) => {
		this.setState({
			...this.state,
			descending: descending
		})
	}

	async buscar(param) {
		const { data } = await TemplatePlanejamentoService.buscar(param);

		let dados = this.state.data
		dados.items = data.result.items;
		dados.total = data.result.total;
		dados.page = this.state.currentPage;
		dados.totalPages = data.result.page;
		await this.setState({
			data: dados
		});
	}

	duplicar = async (values) => {
		let dados = await TemplatePlanejamentoService.duplicar(values);
		if (dados.data.isOk) {
			ToastHelper.success("Modelo de Manejo duplicado com sucesso!");
		} else {
			return ToastHelper.error(dados.data.mensagem);
		}

		TemplatePlanejamentoService.buscar(this.paginate).then(dados => {
			if (dados.data.isOk)
				this.setState({ data: dados.data.result });

		})
	}

	table() {
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.totalPages}
					columns={this.columns}
					acoesSingleButton={false}
					onChangePage={page => this.handlePaginacao(page)}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
				/>
			</>
		)
	};

	modalDuplicar(template) {
		this.setState({ modalDuplicar: !this.state.modalDuplicar, templateDuplicado: template })
	}

	orderTable = (property, descending) => {
		this.setOrderColumn(Utils.parsePropertToFilter(property));
		this.setDescending(descending);
		let params = this.paginate;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		this.buscar(params)
	}

	async handleModalExcluir(item) {
		if (item) {
			await this.setState({
				...this.state,
				idItem: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	modelExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				doubleButtons={true}
				title="Excluir"
				buttonTitle='Confirmar'
				button2Title='Cancelar'
				buttom2Action={() => this.handleModalExcluir()}
				submit={() => this.apagar(this.state.idItem)}
				close={() => this.handleModalExcluir()}
				content={
					<h4>Deseja realmente excluir este modelo ?</h4>
				}
			/>);
	};

	botaoCadastrar() {
		return <Can
			perform={PERMISSAO.MODULO_TURNO_CRIAR}
			yes={() =>
				<button className="btn btn-primary btn-style active pull-right" onClick={this.cadastrar} >
					<i className='fas fa-plus' /> Novo Modelo de Manejo
                </button>
			}
		/>
	}


	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		let newPaginate = this.paginate
		newPaginate.filtro = filtro
		newPaginate.page = 1
		await this.buscar(newPaginate);
	}

	render() {
		return (
			<div>
				<div className='row'>
					<div className='col'>
						<Title>Modelos de Manejo</Title>
					</div>
					<div className="col-md-4" style={{ marginTop: 2 }}>
						{
							this.botaoCadastrar()
						}
					</div>
				</div>
				<CardStyle className="card">
					<div className="col-md-12">
						<div className="input-group md-form form-sm form-2 pl-0">
							<input
								placeholder="Pesquisar"
								className="form-control my-0 py-1"
								value={this.state.filtro}
								autoComplete="off"
								onChange={(e) => this.setState({ filtro: e.target.value })}

								onKeyDown={async (event) => {
									if ((event ? event.keyCode : event.which) === 13) {
										await this.setState({ currentPage: 1 })
										let newPaginate = this.paginate
										newPaginate.filtro = this.state.filtro
										newPaginate.page = 1
										await this.buscar(newPaginate);
									}
								}}
							/>
							<div className="input-group-append">
								<button className="input-group-text red lighten-3" id="basic-text1"
									onClick={async () => {

										await this.setState({ currentPage: 1 })
										let newPaginate = this.paginate
										newPaginate.filtro = this.state.filtro
										newPaginate.page = 1
										await this.buscar(newPaginate)
									}}
								>

									<i className="fas fa-search icon-color-green"
										aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
					<div className="mt-3">
						{this.table()}
					</div>
				</CardStyle>
				{this.modelExcluir()}
				<ModalDuplicacao
					show={this.state.modalDuplicar}
					submit={this.duplicar}
					close={() => this.setState({ modalDuplicar: false })}
					template={this.state.templateDuplicado}
				></ModalDuplicacao>
			</div>
		)
	}
}
