import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';

const TabelaHorasPorDia = props => {

    const [columns] = useState([
      { title: 'Segunda', field: 'horasSegunda', type: 'numeric' },
      { title: 'Terça', field: 'horasTerca', type: 'numeric' },
      { title: 'Quarta', field: 'horasQuarta', type: 'numeric' },
      { title: 'Quinta', field: 'horasQuinta', type: 'numeric' },
      { title: 'Sexta', field: 'horasSexta', type: 'numeric' },
      { title: 'Sábado', field: 'horasSabado', type: 'numeric' },
      { title: 'Domingo', field: 'horasDomingo', type: 'numeric' },
    ]);
  
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.dados) {
            setData([props.dados]);
        }
    }, [props.dados]);
  
    return (
      <MaterialTable
        title="Horas trabalhadas por dia da semana"
        options={{
            search: false,
            sorting: false,
            paging: false,
            draggable: false,
            showTitle: true,
        }}
        localization={{
            header: {
                actions: ''
            }
        }}
        columns={columns}
        data={data}
        editable={{
            isEditable: () => !props.disabled,
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    if (props.onDadosChange) props.onDadosChange(newData);
                    resolve();
                }),
            }
        }
      />
    )
  }

  export default TabelaHorasPorDia;