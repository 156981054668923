import api from './api';

export default {

	buscarPorId(culturaId) {
		return api.get(`/api/Cultura/BuscarPorId/${culturaId}`);
	},
	GetBySafras(param) {
		return api.post(`/api/Cultura/GetBySafras`, param);
	},
	buscarTodas(param) {
		return api.post(`/api/Cultura/BuscarTodas`, param);
	},

	editar(cultura) {
		return api.put(`/api/Cultura`, cultura);
	},

	cadastrar(cultura) {
		return api.post(`/api/Cultura`, cultura)
	},

	deletar(culturaId) {
		return api.delete(`/api/Cultura/${culturaId}`);
	},
};
