import api from './api';

export default {
	buscar(read){
		return api.post(`/api/Usuario/filtro`, read);
	},
	salvar(data){
		return api.post(`/api/Usuario`, data);
	},
	deletar(id, fazenda){
		return api.delete(`/api/Usuario/${id}/${fazenda}`);
	},
	buscarPorId(usuarioId, fazendaId){
		return api.get(`/api/Usuario/${usuarioId}/${fazendaId}`);
	},
	editar(data){
		return api.put('/api/Usuario', data);
	},
	redefinirSenha(data){
		return api.post('/api/Login/RedefinirSenha', data);
	},
	buscarPorEmail(email){
		return api.get(`/api/Usuario/BuscarPorEmail/${email}`);
	},
	novaFazenda(data){
		return api.post(`/api/Usuario/NovaFazenda/`, data);
	}
};
