import React, { useState, useCallback } from 'react';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';
import CultivarService from "../../services/cultivarService";
import ToastHelper from '../../utils/toastHelper';

export default function Cultivar(props) {

	const [nome, setNome] = useState();
	const [descricao, setDescricao] = useState();
	const [id, setId] = useState();

	const columns = [
		{ id: 0, title: 'Cultivar', property: 'nome' },
		{ id: 1, title: 'Descrição', property: 'descricao' },
	];

	const actions = [
		{
			id: 0,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_CULTURA_ATUALIZAR,
			action: item => deleteCultivar(item),
		},
		{
			id: 1,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_CULTURA_ATUALIZAR,
			action: item => EditarCultivar(item),
		},
	];

	const EditarCultivar = (item) => {
		if(!nome){

			if (item) {
				const newList = [...props.cultivares];
				newList.splice(newList.indexOf(item), 1);
				props.setCultivares(newList);
				setNome(item.nome)
				setDescricao(item.descricao)
				setId(item.id)
			}
		}else {

			ToastHelper.warn("Termine a atual edição para iniciar a proxima!")
		}
	};

	const deleteCultivar = async (item) => {
		if (item) {
			if(props.id){
				let newList = [...props.cultivares];
				newList = await CultivarService.deletarDaCultura(item.id, props.id)
				if(newList.data){
					props.setCultivares(newList.data);
					ToastHelper.success("Cultivar excluído da cultura com sucesso!")
				}
			}else {
				if (item) {
					const newList = [...props.cultivares];
					newList.splice(newList.indexOf(item), 1);
					props.setCultivares(newList);
				}
			}
		}
	};

	const tableCultivares = () => (
		<Table
			className='mt-3'
			align='left'
			columns={columns}
			actions={!props.visualizar ? actions : null}
			acoesSingleButton={false}
			data={props.cultivares}
			emptyMessage='Nenhum item adicionado.'
			emptyColSpan={columns ? columns.length + 1 : 0}
		/>
	);

	const addCultivar = useCallback(async () => {
		if (nome) {
			if(props.id){
				let newList = [...props.cultivares];
				const existe = newList.filter(adicionado => adicionado.nome === nome)
				if(existe.length === 0){
					let cultivar = {
						nome,
						descricao,
						id,
						culturaId: props.id,
						fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value
					};
					newList = await CultivarService.adicionaCultivarComId(cultivar)
					if(newList){
						props.setCultivares(newList.data);
						setNome("");
						setDescricao("");
						setId("");
						ToastHelper.success("Cultivar adicionado a cultura com sucesso!")
					}
				}else {
					ToastHelper.error("Já existe um cultivar com este nome!")
				}
			}else {
				const listaAtual = [...props.cultivares];
				const existe = listaAtual.filter(adicionado => adicionado.nome === nome)
				if(existe.length === 0){
					if (nome) {
						const newList = [...props.cultivares];
						newList.push({ nome, descricao, id});
						props.setCultivares(newList);
						setNome("");
						setDescricao("");
						setId("");
					}
				}else {
					ToastHelper.error("Já existe um cultivar com este nome!")
				}

			}
		}
	}, [nome, descricao, id, setId, setNome, setDescricao]);

	return (
		<>
			{ !props.visualizar && (
                <div className='col-md-12 flex card-externo-horas mt-3'>
					<div className='col-md-12 flex card-border pt-3'>
                        <div className="card-header col-md-5">
                            <label className="label">
                                Cultivar:
                            </label>
                            <input
                                className='form-control bmd-form-group'
                                placeholder="Informe o nome do cultivar"
                                name="cultivar"
                                disabled={props.visualizar}
                                onChange={(e) => setNome(e.target.value || "")}
                                value={nome}
                                htmlFor="cultivar"
                                type="text"
                                maxLength="40"
                            />
                        </div>
                        <div className="card-header col-md-5">
                            <label className="label">
                                Descrição:
                            </label>
                            <input
                                className='form-control bmd-form-group'
                                placeholder="Informe a descrição do cultivar"
                                name="descricao"
                                disabled={props.visualizar}
                                onChange={(e) => setDescricao(e.target.value || "")}
                                value={descricao}
                                htmlFor="descricao"
                                type="text"
                                maxLength="40"
                            />
                        </div>
					<div data-tip='col-md-2'>
						<label className='label mt-1 ml-5'>Adicionar</label>
						<div className='col-md-12 label-horas mt-3 ml-5'>
							<div data-tip='Adicionar cultivar' className='col-md-12 flex padding-input-modal'>
								<button
									className='btn btn-primary btn-style active'
									type='button'
									disabled={!nome}
									onClick={() => addCultivar()}
                                >
									<i className='fas fa-plus' />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
			}
			{
				tableCultivares()
			}
		</>
	);
}
