import React from 'react';
import "./categoriaPessoa.listagem.page.scss"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import Title from "../../components/Title/title.component"
import Table from '../../components/Table/table.component';
import CategoriaService from '../../services/categoriaService'
import ToastHelper from "../../utils/toastHelper"
import { PERMISSAO } from '../../permissoes/permissoes';
import Modal from "../../utils/modalHelper";
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Utils from '../../utils/utils'
import ModalAtivaDesativa from "../../utils/modalHelper";

const NUMERO_ITENS_POR_PAGINA = 10;

export default class CategoriaPessoaListagem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			showModalExcluir: false,
			showModalAtivaDesativa: false,
			idGrupoAtivaDesativa: '',
			idApontamentoExcluir: '',
			filtro: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
		}
		this.history = this.props.history
	}

	paginate = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1, pageSize: 10, filtro: '', order: 'Nome', descedescending: false,
		safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
	}

	columns = [
		{ id: 0, title: 'Grupo', property: 'nome' },
		{ id: 1, title: 'Decrição', property: 'descricao' },
		{ id: 2, title: 'Ativo', property: 'isAtivo' },
	];

	actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_CATEGORIA_PESSOA_LEITURA,
			action: item => this.visualizar(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_CATEGORIA_PESSOA_ATUALIZAR,
			action: item => this.editar(item)
		},
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
			permissao: PERMISSAO.MODULO_MAQUINA_ATUALIZAR,
			action: item => this.handleModalAtivaDesativa(item),
		},
		{
			id: 4,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_CATEGORIA_PESSOA_EXCLUIR,
			action: item => this.handleModal(item),
		}
	];

	TIPO_CADASTRO = 'categoriaPessoa';

	cadastrar = () => {
		this.history.push(`/${this.TIPO_CADASTRO}/cadastro`);
	}

	editar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/editar/${item.id}`
		});
	}

	visualizar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/visualizar/${item.id}`
		});
	}

	async apagar(item) {
		let result = await CategoriaService.deletar(item);
		if (result.data.isOk) {
			ToastHelper.success("Grupo excluído com sucesso!");
			this.buscar(this.paginate);
			this.handleModal();
		} else {
			ToastHelper.error(result.data.mensagem);
		}
	}

	async componentDidMount() {
		await this.buscar(this.paginate);
	}

	async handlePaginacao(page) {
		await this.setState({ currentPage: page });
		let newPaginate = this.paginate
		newPaginate.page = page
		await this.buscar(newPaginate);
	}

	async buscar(param) {
		let result = await CategoriaService.buscarTodas(param)
		if (result.data.result) {
			await result.data.result.items.forEach(item => {
				if (item.ativo) {
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});
			await this.setState({
				data: { ...result.data.result }
			});
		}
	}

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		let newPaginate = this.paginate
		newPaginate.filtro = filtro
		newPaginate.page = 1
		await this.buscar(newPaginate);
	}

	async handleModal(item) {
		if (item) {
			await this.setState({
				...this.state,
				idCategoriaExcluir: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {

			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	tableCategoria() {
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					acoesSingleButton={false}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
				/>
			</>
		)
	};

	orderTable = (property, descending) => {
		this.setOrderColumn(Utils.parsePropertToFilter(property));
		this.setDescending(descending);
		let params = this.paginate;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		this.buscar(params)
	}

	setOrderColumn = (property) => {
		this.setState({
			...this.state,
			orderColumn: property
		})
	}

	setDescending = (descending) => {
		this.setState({
			...this.state,
			descending: descending
		})
	}

	async handleModalAtivaDesativa(item) {
		if (item) {

			await this.setState({
				...this.state,
				idGrupoAtivaDesativa: item.id,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			})
		} else {

			await this.setState({
				...this.state,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			})
		}
	}

	async ativaDesativa(item) {
		let dados = await CategoriaService.ativaDesativa(item);

		if (dados.status === 200) {

			ToastHelper.success("Operação realizada com sucesso!");
			await this.buscar(this.paginate);
			await this.handleModalAtivaDesativa(null);
		} else {

			ToastHelper.error("Ocorreu um erro!");
		}
	};

	render() {
		return (
			<div>
				<Title
					acao="Novo Grupo"
					permissaoBotao={PERMISSAO.MODULO_CATEGORIA_PESSOA_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.cadastrar}>
					Grupos de Colaboradores
                </Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div>
						{this.tableCategoria()}
					</div>
				</CardStyle>
				<Modal
					show={this.state.showModalExcluir}
					doubleButtons={true}
					title="Excluir Categoria"
					buttonTitle='Confirmar'
					button2Title='Cancelar'
					buttom2Action={() => this.handleModal()}
					submit={() => this.apagar(this.state.idCategoriaExcluir)}
					close={() => this.handleModal()}
					content={
						<h6>Deseja realmente excluir este grupo ?</h6>
					}
				/>

				<ModalAtivaDesativa
					show={this.state.showModalAtivaDesativa}
					title={'Ativar / Desativar Grupo'}
					buttonTitle={"Confirmar"}
					button2Title={"Cancelar"}
					buttom2Action={() => this.handleModalAtivaDesativa(null)}
					submit={() => this.ativaDesativa(this.state.idGrupoAtivaDesativa)}
					close={() => this.handleModalAtivaDesativa(null)}
					content={
						<h6>Deseja realmente alterar o status deste grupo?</h6>
					}
				/>
			</div>
		)
	}
}
