import api from './api';

export default {
	buscarTudo(fazendaId){
		return api.get(`/api/AtividadeAgricola/BuscarTudo/${fazendaId}`);
	},
	buscarPorId(atividadeagricolaId){
		return api.get(`/api/AtividadeAgricola/BuscarPorId/${atividadeagricolaId}`);
	}
};


