import React from 'react';
import Modal from 'react-bootstrap/es/Modal'
import Select from 'react-select';
import objectHelper from '../../../utils/objectHelper'
import { Table } from 'reactstrap';
import ToastHelper from '../../../utils/toastHelper'

export default class RevisaoModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tipo: '',
			servicosSelecionados: [],
			locaisSelecionados: [],
			atividades: {
				servicos: [],
				locais: []
			},
			dados: {
				servicos: [],
				locais: []
			},
		};
	}

	async componentWillReceiveProps(nextProps) {
		await this.setState({
			dados: nextProps.dados
		})
		this.setState({
			tipo: "Serviço"
		})

	}

	async changeServicos(data, item, index) {
		item.id = index;
		item.servicoEquivalente = data.label
		item.servicoId = data.value
		var atividadesAux = this.state.atividades;
		atividadesAux.servicos.forEach(atividade => {
			if (atividade.id === index) {
				atividadesAux.servicos.splice(index, 1);
			}
		})

		atividadesAux.servicos.push(item)
		await this.setState({ atividades: atividadesAux });

		var selecionados = this.state.servicosSelecionados
		selecionados.forEach((atividade, i) => {
			if (atividade.id === index) {
				selecionados.splice(i, 1);
				return
			}
		})
		data.id = index
		selecionados.push(data)
		this.setState({ servicosSelecionados: selecionados })

		var cols = document.getElementsByClassName(`col${index}`);
		cols[0].style.boxShadow = 'none';
	}

	changeLocais = async (data, item, index) => {
		item.id = index;
		item.localEquivalente = data.label
		item.localId = data.value
		var atividadesAux = this.state.atividades;
		atividadesAux.locais.forEach(atividade => {
			if (atividade.id === index) {
				atividadesAux.locais.splice(index, 1);
			}
		})

		atividadesAux.locais.push(item)
		await this.setState({ atividades: atividadesAux });

		var selecionados = this.state.locaisSelecionados
		selecionados.forEach((atividade, i) => {
			if (atividade.id === index) {
				selecionados.splice(i, 1);
				return
			}
		})
		data.id = index
		selecionados.push(data)
		this.setState({ locaisSelecionados: selecionados });
		var cols = document.getElementsByClassName(`col${index}`);
		cols[0].style.boxShadow = 'none';
	}

	async verificarLinhasIrregularesServico() {
		var tamanho = this.state.dados.servicos.length;
		for (var i = 0; i < tamanho; i++) {
			(function (i) {
				var servico = this.state.servicosSelecionados.find(item => item.id === i);
				if (servico === undefined) {
					var cols = document.getElementsByClassName(`col${i}`);
					cols[0].style.boxShadow = 'inset 0 1px 1px rgba(228, 24, 24, 0.075),0 0 8px rgba(241, 21, 21, 0.6)';
				}
			}(i));
		}
	}

	async verificarLinhasIrregularesLocais() {
		var tamanho = this.state.dados.locais.length;
		for (var i = 0; i < tamanho; i++) {
			(function (i) {
				var local = this.state.locaisSelecionados.find(item => item.id === i);
				if (local === undefined) {
					var cols = document.getElementsByClassName(`col${i}`);
					cols[0].style.boxShadow = 'inset 0 1px 1px rgba(228, 24, 24, 0.075),0 0 8px rgba(241, 21, 21, 0.6)';
				}
			}(i));
		}
	}

	atualizarTipo = (str) => {
		if (this.state.tipo === "Serviço")
			if (this.state.servicosSelecionados.length !== this.state.dados.servicos.length) {
				this.verificarLinhasIrregularesServico();
				return ToastHelper.error("Por favor confira todos os itens da tabela antes de prosseguir.");
			}


		if (this.state.tipo === "Local")
			if (this.state.locaisSelecionados.length !== this.state.dados.locais.length) {
				this.verificarLinhasIrregularesLocais();
				return ToastHelper.error("Por favor confira todos os itens da tabela antes de prosseguir.");
			}
		if (str === "salvar")
			if (this.state.locaisSelecionados.length !== this.state.dados.locais.length) {
				this.verificarLinhasIrregularesLocais();
				return ToastHelper.error("Por favor confira todos os itens da tabela antes de prosseguir.");
			}
			else
				return this.abrirModal(this.state.atividades)


		this.setState({ tipo: str });
	}

	cancelar = () => {
		this.setState({
			tipo: '',
			servicosSelecionados: [],
			locaisSelecionados: [],
			atividades: {
				servicos: [],
				locais: []
			},
			dados: {
				servicos: [],
				locais: []
			}
		})
		return this.props.close()
	}


	abrirModal = (atividades) => {
		this.props.close();
		return this.props.salvar(atividades)

	}

	render() {

		return (
			<>
				<Modal dialogClassName="modal-dialog-lg-template" show={this.props.show} onHide={this.cancelar.bind(this)}>
					<Modal.Header closeButton >
						<h3>Equivalências da Planilha</h3>
					</Modal.Header>
					<hr></hr>
					<Modal.Body className="" style={{ height: "80vh" }}>

						<Table striped bordered hover>
							<thead>
								<tr>
									{/* <th style={{ width: '30%' }}>Composições</th> */}
									<th style={{ width: '30%' }}>{this.state.tipo} Planilha</th>
									<th style={{ width: '30%' }}>{this.state.tipo} Cadastrados</th>
									<th>Data Inicial</th>
									<th>Data Final</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.dados.servicos !== undefined &&
									this.state.tipo === "Serviço" &&
									this.state.dados.servicos.map((item, index) => {
										return <>
											<tr key={index.toString()} className={`col${index}`}>
												<td>{item.servicos}</td>
												<td style={{ width: 160 }}>
													<Select
														placeholder='Selecionar serviço...'
														className="select-style ajust-label-padrao disabled-select"
														onChange={data => this.changeServicos(data, item, index)}
														options={
															objectHelper.getValuesParseToSelect(this.props.servicos)
														}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
														value={this.state.servicosSelecionados.find(x => x.id.toString() === index.toString())}
													/>
												</td>
												<td>{item.dataInicio}</td>
												<td>{item.dataFim}</td>
											</tr>
										</>
									})
								}
								{
									this.state.dados.locais !== undefined &&
									this.state.tipo === "Local" &&
									this.state.dados.locais.map((item, index) => {
										return <>
											<tr key={index.toString()} className={`col${index}`}>
												<td>{item.locais}</td>

												<td style={{ width: 160 }}>
													<Select
														placeholder='Selecionar local...'
														className="select-style ajust-label-padrao disabled-select"
														onChange={data => this.changeLocais(data, item, index)}
														options={
															objectHelper.getValuesParseToSelect(this.props.local)
														}
														value={this.state.locaisSelecionados.find(x => x.id.toString() === index.toString())}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
												</td>
												<td>{item.dataInicio}</td>
												<td>{item.dataFim}</td>
											</tr>
										</>
									})
								}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer className="footer-modal">
						<hr></hr>
						<div className="row col-md-12">
							<div className="col-md-6 pull-left">
								<button className="btn btn-outline-secondary pull-left" variant="success" onClick={this.cancelar.bind(this)} >
									<i className='fas fa-close' /> Cancelar
							</button>
							</div>
							<div className="col-md-6 pull-right">
								{
									this.state.tipo === "Serviço" &&
									<button className="btn btn-primary active pull-right" variant="success" onClick={() => this.atualizarTipo("Local")} >
										<i class="fas fa-long-arrow-alt-right"></i> Próximo
								</button>
								}
								{
									this.state.tipo === "Local" &&
									<div className="pull-right">
										<button className="btn btn-primary btn-outline-secondary" variant="success" onClick={() => this.atualizarTipo("Serviço")} >
											<i class="fas fa-long-arrow-alt-left"></i> Anterior
									</button>
										<button className="btn btn-primary active" variant="success" onClick={() => this.atualizarTipo("salvar")} >
											<i class="fas fa-check"></i> Salvar
									</button>
									</div>
								}

							</div>
						</div>
					</Modal.Footer>
				</Modal>

			</>
		)
	}
}
