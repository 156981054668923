import React, { useState} from 'react';
import { useForm } from 'react-hook-form';
import Title from "../../../components/Title/title.component"
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Accordion from '../../../components/AccordionSection/Accordion';
import ObjectHelper  from '../../../utils/objectHelper';
import RecursosService from '../../../services/recursosService';
import FamiliaMaterialService from '../../../services/familiaMaterialService';
import PessoaTipoService from '../../../services/categoriaService';
import MaquinaTipoService from '../../../services/tipoMaquinaService';

export default function HistogramaFiltro(props){
	const { register, handleSubmit, setValue} = useForm({});

	const [inicio, setInicio] = useState(null);
	const [fim, setFim] = useState(null);
	const [classes, setClasses] = useState([]);
	const [recursos, setRecursos] = useState([]);

	const submitForm = values => {
		values.anoMesInicio = inicio || undefined
		values.anoMesFim = fim || undefined
		var arraySafras = [];
		if(values.safras !== undefined){
			values.safras.foreach(safra => {
				var safraId = safra.value
				arraySafras.push({
					safraId
				})
			});
		}
		values.safraId = arraySafras.length > 0 ? arraySafras : undefined
		props.filtrar(values)
	};



	const changeRecursoTipo = async (e) => {
        let param = {
            fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
            page: 1,
            pageSize: 1000
        }

        let data = null;

        if (e.codigo === "MA") data = await MaquinaTipoService.buscarTodas(param);
        if (e.codigo === "IN" || e.codigo === "RE") data = await FamiliaMaterialService.buscarTodas(param);
		if (e.codigo === "MO") data = await PessoaTipoService.buscarTodas(param);

		if (data) {
			setClasses(ObjectHelper.getValuesParseToSelect(data.data.result.items));
		} else {
			setClasses(ObjectHelper.getValuesParseToSelect([]));
		}
	}

	const buscarRecursos = async (id) => {
		const { data }  = await RecursosService.getClasseId(id);
		setRecursos(data.result);
	}

	return (

		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>Histograma</Title>
					</div>
				</div>
				<div className="card">
				<Accordion >
					<div label='Filtros'>
						<div className='card-body row justify-content-start' style={{paddingTop: "inherit"}}>
							<div className="card-header col-md-4">
								<label className="label">
									Período Inicial:*
								</label>
								<InputMask
									className='form-control bmd-form-group'
									name="anoMesInicio"
									mask="99/9999"
									placeholder="Digite o período inicial"
									maskChar={null}
									onChange={e => setInicio(e.target.value)}
									ref={() =>register({ name: "anoMesInicio" })}
									autoComplete="off"
								/>
							</div>
							<div className="card-header col-md-4">
								<label className="label">
									Período Final:*
								</label>
								<InputMask
									onChange={e => setFim(e.target.value)}
									className='form-control bmd-form-group'
									name="anoMesFim"
									mask="99/9999"
									maskChar={null}
									ref={() =>register({ name: "anoMesFim" })}
									placeholder="Digite o período final"
									autoComplete="off"
								/>
							</div>
							<div className="card-header col-md-4">
								<label className="label" htmlFor="recursosTipoId">
										Recursos Tipo :*
								</label>
								<Select
									id="recursosTipoId"
									name="recursosTipoId"
									isClearable={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={recursoTipo => {
										setValue('recursosTipoId', recursoTipo ? recursoTipo.value : undefined);
										changeRecursoTipo(recursoTipo.label)
									}}
									ref={() =>register({ name: "recursosTipoId" })}
									options={ObjectHelper.getRecursoTipoParseToSelect(props.recursosTipo)}
									theme={theme => ({...theme, borderRadius: 0 })}
								/>
							</div>
							<div className="card-header col-md-4">
								<label className="label" htmlFor="localId">
										Recursos Classe :*
								</label>
								<Select
									id="recursosClasseId"
									name="recursosClasseId"
									isClearable={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={recursosClasse => {
										setValue('recursosClasseId', recursosClasse.value);
										buscarRecursos(recursosClasse.value)
									}}
									ref={() =>register({ name: "recursosClasseId" })}
									options={classes}
									theme={theme => ({...theme, borderRadius: 0 })}
								/>
							</div>
							<div className="card-header col-md-4">
								<label className="label" htmlFor="recursosId">
										Recursos :*
								</label>
								<Select
									id="recursosId"
									name="recursosId"
									isClearable={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={recursos => {setValue('recursosId', recursos ? recursos.value : undefined)}}
									ref={() =>register({ name: "recursosId" })}
									options={recursos}
									theme={theme => ({...theme, borderRadius: 0 })}
								/>

							</div>
							<div className="card-header col-md-4">
								<label className="label" htmlFor="safraId">
									Safras :*
								</label>
								<Select
									id="safraId"
									name="safraId"
									isClearable={true}
									isMulti={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={safra => {setValue('safras', safra ? safra : undefined)}}
									ref={() =>register({ name: "safras" })}
									options={ObjectHelper.getValuesParseToSelect(props.safras)}
									theme={theme => ({...theme, borderRadius: 0 })}
								/>

							</div>
						</div>

						<div className="card-header row justify-content-start" style={{marginTop: -35}}>

							<div className="card-header col-md-4">
								<label className="label" htmlFor="localId">
										Locais :*
									</label>
									<Select
										id="localId"
										name="localId"
										isClearable={true}
										placeholder='Selecionar'
										className="select-style ajust-label-padrao disabled-select"
										onChange={local => {setValue('localId', local ? local.value : undefined)}}
										ref={() =>register({ name: "localId" })}
										options={ObjectHelper.getValuesParseToSelect(props.locais)}
										theme={theme => ({...theme, borderRadius: 0 })}
									/>

								</div>
								<div className="card-header col-md-4">
										<label className="label" htmlFor="servicoTipoId">
											Grupo de Serviços :*
										</label>
										<Select
											id="servicoTipoId"
											name="servicoTipoId"
											isClearable={true}
											placeholder='Selecionar'
											className="select-style ajust-label-padrao disabled-select"
											onChange={local => { setValue('servicoTipoId', local ? local.value : undefined);
											local ? props.getServicos(local.value ) : void 0}}
											ref={() =>register({ name: "servicoTipoId" })}
											options={ObjectHelper.getValuesParseToSelect(props.grupoServicos)}
											theme={theme => ({...theme, borderRadius: 0 })}
										/>
								</div>
								<div className="card-header col-md-4">
									<label className="label">
										Serviços :*
									</label>
									<Select
										id="servicoId"
										name="servicoId"
										isClearable={true}
										placeholder='Selecionar'
										className="select-style ajust-label-padrao disabled-select"
										options={ObjectHelper.getValuesParseToSelect(props.servicos) || []}
										onChange={servico => {setValue('servicoId', servico ? servico.value : undefined);}}
										ref={() =>register({ name: "servicoId" })}
										theme={theme => ({...theme, borderRadius: 0 })}
									/>
								</div>
						</div>
						<div className="col-md-12 mt-3">
							<button className="btn btn-primary active pull-right" type="submit" style={{marginBottom: 10, marginTop: -30}}>
								<i className='fas fa-search'/> Buscar
							</button>
						</div>
					</div>
				</Accordion>
				</div>
			</form>
		</>
	)
}


