import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
	getAppointmentMachines: ['params', 'callback'],
	setAppointmentMachines: ['appointmentMachines'],

	getMachines: ['params', 'callback'],
	setMachines: ['machines'],

	getOperators: ['params', 'callback'],
	setOperators: ['operators'],

	getServiceGroups: ['params', 'callback'],
	setServiceGroups: ['serviceGroup'],

	getServicesByGroupId: ['params', 'callback'],
	setServicesByGroupId: ['services'],

	getWorkShifts: ['params', 'callback'],
	setWorkShifts: ['workShifts'],

	getWorkPlaces: ['params', 'callback'],
	setWorkPlaces: ['workPlaces'],

	getFamilyMaterials: ['params', 'callback'],
	SetFamilyMaterials: ['familyMaterials'],

	getMaterialsByFamily: ['params', 'callback'],
	setMaterialsByFamily: ['materials'],

	createAppointmentMachine: ['appointment', 'listEntrys', 'history', 'callback'],
	postAppointmentMachine: ['params'],

	getMachineAppointmentById: ['params', 'setForm', 'callback'],
	setMachineAppointmentById: ['params'],

	updateMachineAppointment: ['appointment', 'listEntrys', 'history', 'callback'],

	deleteAppointmentMachine: ['params', 'callback'],

	deleteAppointmentEntry: ['params', 'callback'],
})

const INITIAL_STATE_VALUES = {
	appointmentMachines: [],
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getAppointmentMachines = (state = INITIAL_STATE_VALUES, action) => ({ ...state, appointmentMachines: action.data })
const deleteAppointmentMachine = (state = INITIAL_STATE, action) => ({ idDeletedItem: action.params })
const getMachines = (state = INITIAL_STATE, action) => ({ ...state, machines: action.data })
const getOperators = (state = INITIAL_STATE, action) => ({ ...state, operators: action.data })
const getServiceGroups = (state = INITIAL_STATE, action) => ({ ...state, serviceGroup: action.data })
const getServiceByGroupId = (state = INITIAL_STATE, action) => ({ ...state, services: action.data })
const getWorkShifts = (state = INITIAL_STATE, action) => ({ ...state, workShifts: action.data })
const getWorkPlaces = (state = INITIAL_STATE, action) => ({ ...state, workPlaces: action.data })
const getFamilyMaterials = (state = INITIAL_STATE, action) => ({ ...state, familyMaterials: action.data })
const getMaterialsByFamily = (state = INITIAL_STATE, action) => ({ ...state, materials: action.data })
const createAppointmentMachine = (state = INITIAL_STATE, action) => ({ ...state, appointmentMachine: action.data })
const getMachineAppointmentById = (state = INITIAL_STATE, action) => ({ ...state, appointment: action.data.data.result, entrys: action.data.entrys })
const updateMachineAppointment = (state = INITIAL_STATE, action) => ({})
const deleteAppointmentEntry = (state = INITIAL_STATE, action) => ({ ...state, idDeletedItem: action.params })

export default createReducer(INITIAL_STATE, {
	[Types.SET_APPOINTMENT_MACHINES]: getAppointmentMachines,
	[Types.DELETE_APPOINTMENT_MACHINE]: deleteAppointmentMachine,
	[Types.SET_MACHINES]: getMachines,
	[Types.SET_OPERATORS]: getOperators,
	[Types.SET_SERVICE_GROUPS]: getServiceGroups,
	[Types.SET_SERVICES_BY_GROUP_ID]: getServiceByGroupId,
	[Types.SET_WORK_SHIFTS]: getWorkShifts,
	[Types.SET_WORK_PLACES]: getWorkPlaces,
	[Types.SET_FAMILY_MATERIALS]: getFamilyMaterials,
	[Types.POST_APPOINTMENT_MACHINE]: createAppointmentMachine,
	[Types.SET_MACHINE_APPOINTMENT_BY_ID]: getMachineAppointmentById,
	[Types.UPDATE_MACHINE_APPOINTMENT]: updateMachineAppointment,
	[Types.DELETE_APPOINTMENT_ENTRY]: deleteAppointmentEntry,
	[Types.SET_MATERIALS_BY_FAMILY]: getMaterialsByFamily
})
