import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import planejamentoSafraService from 'services/planejamentoSafraService'
import { Types } from "../ducks/recomendacoes_tecnicas.ducks";
import executeAction from "./executeAction";
import ToastHelper from "../../utils/toastHelper";

const setarDuasCasasDecimais = async (items) => {
	return await items.map(item => {
		item.quantidadeTotal = item.quantidadeTotal.toFixed(2)
		return item.quantidadeHa = item.quantidadeHa.toFixed(2)
	})
}

function apiGet(parametro) {
	let newParametro = parametro
	return new Promise(async (resolve, reject) => {
		let { data } = await planejamentoSafraService.getRecursosToUpdate(newParametro)
		if (data)
			if (data.result.items) {
				await setarDuasCasasDecimais(data.result.items)
				resolve(data.result)
			}
			else
				reject([])
	})
}

function deleteRecurso(recursoId, filtro) {
	return new Promise(async (resolve, reject) => {

		let deleteRecurso = { recursoId: recursoId, filtro: filtro }
		let { data } = await planejamentoSafraService.deleteRecurso(deleteRecurso)
		if (data)
			if (data.result.result.items) {
				await setarDuasCasasDecimais(data.result.result.items)
				resolve(data.result)
			}
			else
				resolve([])
	})
}

function updateRecursos(recursosNovos, filtro) {
	return new Promise(async (resolve, reject) => {
		let array = []

		for (let value of Object.entries(recursosNovos)) {
			array.push({
				id: value[1].newData.id,
				recursoId: value[1].newData.recursos.value,
				porcentagem: value[1].newData.porcentagem,
				quantidadeTotal: value[1].newData.quantidadeTotal
			});
		}

		let newUpdate = { novosRecursos: array, filtro: filtro }
		let { data } = await planejamentoSafraService.updateRecursosRecomendacoesTecnicas(newUpdate)
		if (data)
			if (data.result.result.items) {
				await setarDuasCasasDecimais(data.result.result.items)
				resolve(data.result)
			}
			else
				resolve([])
	})
}

function* asyncListRecursos(action) {
	yield executeAction(function* () {
		const { params, callback } = action;

		const data = yield call(apiGet, params);
		params.page = data.page;
		params.pageCount = data.pageCount;
		params.rowCount = data.rowCount;
		params.totalPageCount = data.totalPageCount;
		if (data) {
			yield put({ type: Types.SUCCESS_RECURSOS_TO_ADJUST, data: { dados: data.items, pagination: params } });
			callback();
		} else {
			yield put({ type: Types.SET_ERROR, data: { error: data, mensagem: "erro tal", pagination: params } });
		}
	}, Types.REQUEST_RECURSOS_TO_ADJUST);
}

function* asyncUpdateRecursos(action) {
	yield executeAction(function* () {
		const { items, filtro, callback } = action;

		const data = yield call(updateRecursos, items, filtro);
		filtro.page = data.result.page;
		filtro.pageCount = data.result.pageCount;
		filtro.rowCount = data.result.rowCount;
		filtro.totalPageCount = data.result.totalPageCount;
		if (data.result) {
			if (data.isOk)
				ToastHelper.success(`Recursos atualizados com sucesso!`)
			yield put({ type: Types.SUCCESS_RECURSOS_TO_ADJUST, data: { dados: data.result.items, pagination: filtro } });
			callback();
		} else {
			yield put({ type: Types.SET_ERROR, data: { error: data, mensagem: "erro tal", pagination: filtro } });
		}
	}, Types.UPDATE_RECURSOS);
}

function* asyncDeletarRecursos(action) {
	yield executeAction(function* () {
		const { recursoId, filtro, callback } = action;

		const data = yield call(deleteRecurso, recursoId, filtro);
		filtro.page = data.result.page;
		filtro.pageCount = data.result.pageCount;
		filtro.rowCount = data.result.rowCount;
		filtro.totalPageCount = data.result.totalPageCount;
		if (data.result) {
			data.result.items.foreach(item => {

			})
			if (data.isOk)
				ToastHelper.success(`Recurso deletado com sucesso!`)
			yield put({ type: Types.SUCCESS_RECURSOS_TO_ADJUST, data: { dados: data.result.items, pagination: filtro } });
			callback();
		} else {
			yield put({ type: Types.SET_ERROR, data: { error: data, mensagem: "erro tal", pagination: filtro } });
		}
	}, Types.UPDATE_RECURSOS);
}

function* takeListRecursos() {
	yield takeLatest(Types.REQUEST_RECURSOS_TO_ADJUST, asyncListRecursos);
}

function* takeUpdateRecursos() {
	yield takeLatest(Types.UPDATE_RECURSOS, asyncUpdateRecursos);
}

function* takeDeleteRecursos() {
	yield takeLatest(Types.DELETAR_RECURSO, asyncDeletarRecursos);
}

export default function* root() {
	yield all([fork(takeListRecursos)])
	yield all([fork(takeUpdateRecursos)])
	yield all([fork(takeDeleteRecursos)])
}
