import React, { Component } from "react";
import Select from "react-select";
import "./dropdown.component.scss";

const selectStyles = {
	control: provided => ({ ...provided, minWidth: 240, margin: 8 })
};

class DropdownCustom extends Component {
	constructor(props) {
		super(props);
		this.state = { isOpen: false, value: this.props.value };

		this.selectRef = React.createRef();

		this.focus = this.focus.bind(this);
	}

	focus = () => {
		this.toggleOpen();
	}

	toggleOpen = () => {
		this.setState(state => ({ isOpen: !state.isOpen }));
	};

	onSelectChange = value => {
		this.toggleOpen();
		this.props.onChange(value);

		if (this.props.resetOnChange)
			this.setState({ value: null });
		else
			this.setState({ value });
	};

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];
		valueName = valueName || 'value';
		labelName = labelName || 'label';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], pathname: item.pathname, powerBi: item.powerBi, culturaId: item.culturaId ? item.culturaId : undefined,atividadeAgricolaId: item.atividadeAgricolaId ? item.atividadeAgricolaId : ""})
		});
		return options;
	}

	render() {
		const stateOptions = this.props.array;
		const { isOpen, value } = this.state;

		return (
			<>
				<div className="border-dropdown">
					<Dropdown
						isOpen={isOpen}
						onClose={this.toggleOpen}
						target={
							<button
								onClick={this.toggleOpen}
								className="btn btn-default btn-dropdown"
								style={{ textTransform: "none", marginTop: 10 }}
							>
								{value
									? `${value.label}`
									: this.props.label}
								<i className="fas fa-angle-down icon-dropdown"></i>
							</button>
						}
					>
						{
							this.props.hidden &&
							<Select
								autoFocus
								backspaceRemovesValue={false}
								controlShouldRenderValue={false}
								hideSelectedOptions={false}
								isClearable={false}
								menuIsOpen
								onChange={this.onSelectChange}
								options={this.getValuesParseToSelect(stateOptions)}
								placeholder=""
								styles={selectStyles}
								tabSelectsValue={false}
							/>
						}
					</Dropdown>
				</div>

			</>
		);
	}
}

const Menu = props => {
	const shadow = "hsla(218, 50%, 10%, 0.1)";
	return (
		<div
			style={{
				backgroundColor: "white",
				borderRadius: 4,
				boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
				marginTop: 8,
				position: "absolute",
				zIndex: 2
			}}
			{...props}
		/>
	);
};
const Blanket = props => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 0,
			right: 0,
			position: "fixed",
			zIndex: 1,
			color: '#000'
		}}
		{...props}
	/>
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
	<div style={{ position: "relative", color: 'black' }}>
		{target}
		{isOpen ? <Menu>{children}</Menu> : null}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
);

export default (DropdownCustom);
