import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getMaquinas: ['params', 'callback'],
	setMaquinas: ['maquinas'],

	getAnoMes: ['params', 'callback'],
	setAnoMes: ['params'],

	getSafra: ['params', 'callback'],
	setSafra: ['safra'],

	getGrupoMaquinas: ['params', 'callback'],
	setGrupoMaquinas: ['grupoMaquinas'],

	buscarPainel: ['params', 'callback'],
	readPainel: ['params'],

	buscarPainelMaquinas: ['params', 'callback'],
	readPainelMaquinas: ['params']
})

const INITIAL_STATE_VALUES = {
	anoMes: [],
	grupoMaquinas: [],
	maquinas:[],
	safra:[],
	painel: [],
	painelMaquinas : []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getAnoMes = (state = INITIAL_STATE, action) => ({ ...state, anoMes: action.data })
const getMaquinas = (state = INITIAL_STATE, action) => ({ ...state, maquinas: action.data })
const getSafra = (state = INITIAL_STATE, action) => ({ ...state, safra: action.data })
const getGrupoMaquinas = (state = INITIAL_STATE, action) => ({...state, grupoMaquinas: action.data})
const buscarPainel = (state = INITIAL_STATE, action) => ({...state, painel: action.data})
const buscarPainelMaquinas = (state = INITIAL_STATE, action) => ({...state, painelMaquinas: action.data})

export default createReducer(INITIAL_STATE_VALUES, {
	[Types.SET_MAQUINAS]: getMaquinas,
	[Types.SET_SAFRA]: getSafra,
	[Types.SET_GRUPO_MAQUINAS]: getGrupoMaquinas,
	[Types.READ_PAINEL]: buscarPainel,
	[Types.SET_ANO_MES]: getAnoMes,
	[Types.READ_PAINEL_MAQUINAS]: buscarPainelMaquinas
})
