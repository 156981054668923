import api from './api';

export default {
	salvar(apontamento){
		return api.post(`/api/ApontamentoGrupo/Cadastrar`, apontamento);
	},
	findAll(apontamento){
		return api.post(`/api/ApontamentoGrupo/Buscar`, apontamento);
	},
	deletar(id){
		return api.delete(`/api/ApontamentoGrupo/${id}`);
	},
	buscarPorId(apontamentoId){
		return api.get(`/api/Apontamento/${apontamentoId}`);
	},
	editar(apontamento){
		return api.put('/api/Apontamento', apontamento);
	},
	somatorioPorPeriodo(parametro){
		return api.post('/api/Apontamento/SomatorioHorasPorPeriodo', parametro);
	},
	somatorioHorasPeloDiaAtual(fazenda, safra){
		return api.get(`/api/Apontamento/SomatorioHorasPeloDiaAtual/${fazenda}/${safra}`);
	},
	somatorioHorasPorSemana(fazenda, safra){
		return api.get(`/api/Apontamento/SomatorioHorasPorSemanaAtual/${fazenda}/${safra}`);
	},
	somatorioHorasPeloMes(fazenda, safra){
		return api.get(`/api/Apontamento/SomatorioHorasPorMesAtual/${fazenda}/${safra}`);
	},
	atualizarPainel(){
		return api.get(`/api/Apontamento/AtualizarPainel`);
	},
	popularGrafico(fazendaId,safraId, tipo){
		return api.get(`/api/Apontamento/GraficoServicosRealizados/${fazendaId}/${safraId}/${tipo}`);
	},
	buscarParaAjustes(params){
		return api.post(`/api/Apontamento/BuscarParaAjustes`, params);
	},
	atualizaListaApontamentos(params){
		return api.put(`/api/Apontamento/AtualizaApontamentos`, params);
	},
	excluirSelecionados(params){
		return api.put(`/api/Apontamento/ExcluirSelecionados`, params);
	},
	editarSelecionados(params){
		return api.put(`/api/Apontamento/EditarSelecionados`, params);
	}
};
