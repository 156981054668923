import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import OrdemServicoService from "../../../services/ordemServicoService";
import InsumosService from "../../../services/materiaisService";
import { Types } from "./ordem_servico.ducks";
import executeAction from "../../../store/sagas/executeAction";
import ToastHelper from "../../../utils/toastHelper";

function* asyncGetOrdemServicoById(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(
			async () => await OrdemServicoService.buscarPorId(params)
		);

		yield put({
			type: Types.SET_ORDEM_SERVICO_BY_ID,
			data: data.result
		});

		callback();
	}, Types.GET_ORDEM_SERVICO_BY_ID);
}

function* asyncGetEpis(action) {
	yield executeAction(function* () {
		const { callback } = action;
		var fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		const { data } = yield call(
			async () => await InsumosService.getByGrupoInsumos(fazendaId, "EPI")
		);

		yield put({
			type: Types.SET_EPIS,
			data: data.result
		});

		callback();
	}, Types.GET_EPIS);
}

function* asyncUpdateOrdemServico(action) {
	yield executeAction(function* () {
		const { params } = action;
		const { data } = yield call(
			async () => await OrdemServicoService.editar(params)
		);
		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!");
		} else ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.UPDATE_ORDEM_SERVICO,
			data
		});
	}, Types.UPDATE_ORDEM_SERVICO);
}

function* takeGetOrdemServicoById() {
	yield takeLatest(Types.GET_ORDEM_SERVICO_BY_ID, asyncGetOrdemServicoById);
}

function* takeGetEpis() {
	yield takeLatest(Types.GET_EPIS, asyncGetEpis);
}

function* takeUpdateOrdemServico() {
	yield takeLatest(Types.UPDATE_ORDEM_SERVICO, asyncUpdateOrdemServico);
}

export default function* root() {
	yield all([fork(takeGetOrdemServicoById)]);
	yield all([fork(takeGetEpis)]);
	yield all([fork(takeUpdateOrdemServico)]);
}
