import React from 'react';
import { CardStyle } from "components/CardStyle/cardstyle.component";
import Title from 'components/Title/title.component';
import DropzoneFile from 'components/DragAndDrop/dragAndDropFile'
import ToastHelper from 'utils/toastHelper'
import ImportacaoAnaliseSoloService from 'services/ImportacaoAnaliseSoloService'
import 'react-toastify/dist/ReactToastify.css';

export default class ImportacaoAnaliseSoloPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {


		};
	}

	componentDidMount() {
	}



	setImage = async (imagemNova) => {
		let formData = new FormData();
		formData.append('file', imagemNova[0]);
		const { data } = await ImportacaoAnaliseSoloService.upload(
			JSON.parse(localStorage.getItem('fazendaId')).label,
			JSON.parse(localStorage.getItem('safraId')).value,
			formData);

		if (data.isOk) {
			ToastHelper.success("Planilha importada com sucesso!");
			window.location.href = "/#/analiseSolo"
		} else {
			ToastHelper.error(data.mensagem)
		}
	}

	render() {
		return (
			<>
				<Title>Importação da Análise do Solo</Title>
				<CardStyle className="card">
					<div className="row">
						<div className="col-md-12 ">
							<div class="jumbotron">
								{/* <hr class="my-4" /> */}
								{/* <p style={{ fontSize: 16 }}> Os dados inseridos na planilha precisam estar em conformidade com os dados do sistema.
								Você pode revisar os dados já cadastrados no sistema clicando no botão Revisar</p> */}
								<p> Os arquivos aceitos são planilhas de Excel no formato .XLSX </p>

								<div className="text-center col-md-12 b-0 p-0">
									<br></br>
									<DropzoneFile
										setImage={this.setImage}
										text="Clique ou arraste sua planilha aqui!"
									/>
								</div>
							</div>
						</div>
					</div>

				</CardStyle>
				<br></br>
				<a className="btn btn-outline-secondary pull-left" type="button" href="/#/analiseSolo/configuracao">
					<i className='fas fa-arrow-left' />  Voltar
            	</a>

			</>
		)
	}
}
