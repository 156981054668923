import React, { useState, useEffect } from "react";
import { PERMISSAO } from "../../permissoes/permissoes";
import Title from "../../components/Title/title.component";
import TabelaAjusteApontamentoMaquina from "components/tabelasAjusteApontamentos/tabelaAjusteApMaquina.componet";
import TabelaAjusteApontamentoMaoDeObra from "components/tabelasAjusteApontamentos/tabelaAjusteApMaoDeObra.componet"
import TabelaAjusteApontamentoInsumos from "components/tabelasAjusteApontamentos/tabelaAjusteApInsumos.componet"
import AppBar from '@material-ui/core/AppBar';
import culturaService from "services/culturaService";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ColaboradorService from '../../services/pessoaService';
import SafraService from '../../services/safraService';
import ServicoService from '../../services/servicoService';
import MaquinaService from '../../services/maquinaService';
import GrupoServicoService from '../../services/grupoServicoService';
import InsumoService from '../../services/materiaisService';
import ToastHelper from "../../utils/toastHelper";
import ObjectHelper from "../../utils/objectHelper"
import { useSelector, useDispatch } from "react-redux";
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};


const theme = createMuiTheme({
	overrides: {
		MuiTab: {
			root: {
				"&.MuiTab-root": {
					border: 0,
					borderBottom: "2px solid grey",
					borderColor: "grey",
					color: "grey",
					"&:hover": {
						border: 0,
						borderBottom: "2px solid #009D90",
						borderColor: "#009D90",
						color: "#009D90",
					},
				},
				"&.Mui-selected": {
					backgroundColor: "#E7F2F2",
					borderBottom: "2px solid #00635A",
					borderColor: "#00635A",
					color: "#00635A"
				}
			}
		}
	}
});

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		flexGrow: 1,
	},
}));

export default function AjustesApontamentos(props) {

	const dispatch = useDispatch();
	const [value, setValue] = useState(useSelector(state => state.ajusteApontamento.tipoApontamento));
	const [colaboradores, setColaboradores] = useState([]);
	const [maquinas, setMaquinas] = useState([]);
	const [insumos, setInsumos] = useState([]);
	const [locais, setLocais] = useState([]);
	const [servicos, setServicos] = useState([]);
	const [gruposServicos, setGruposServicos] = useState([]);
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		dispatch({
			type: "SET_FILTRO_AJUSTE_APONTAMENTO",
			filtroListagem: index
		});

		setValue(index);
	};

	function a11yProps(index) {
		return {
			id: `scrollable-force-tab-${index}`,
			'aria-controls': `scrollable-force-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		async function Init() {
			let params = {
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				safraId: ObjectHelper.multisafra(),
				descedescending: false,
				page: 1,
				pageSize: 9999999,
				order: 'Nome',
				ativo: true
			}
			buscarColaboradores(params);
			buscarLocais(params);
			buscarServicos(params);
			buscarGrupoServicos(params);
			buscarMaquinas(params);
			buscarInsumos(params);
			getSafras();
			getAtividades();
		}
		Init()
	}, [])

	const buscarColaboradores = (params) => {
		ColaboradorService.buscar(params).then((response) => {
			if (response.status === 200)
				setColaboradores(ObjectHelper.getValuesParseToSelect(response.data.result.items))
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const buscarInsumos = (params) => {
		InsumoService.buscarTodas(params).then((response) => {
			if (response.status === 200)
				setInsumos(ObjectHelper.getValuesMaterialParseToSelect(response.data.result.items))
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const buscarLocais = async (params) => {
		await SafraService.locais(params.fazendaId, params.safraId, true).then((response) => {
			if (response.status === 200)
				setLocais(ObjectHelper.getValuesParseToSelect(response.data.result))
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const buscarGrupoServicos = (params) => {
		GrupoServicoService.buscarTodos(params).then((response) => {
			if (response.status === 200) {
				setGruposServicos(ObjectHelper.getValuesParseToSelect(response.data.result.items))
			}
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const buscarServicos = (params) => {
		ServicoService.getServicos(params.fazendaId).then((response) => {
			if (response.status === 200)
				setServicos(ObjectHelper.getValuesParseToSelect(response.data.result))
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const buscarMaquinas = (params) => {
		MaquinaService.buscarTodasPorId(params.fazendaId).then((response) => {
			if (response.status === 200) {
				setMaquinas(ObjectHelper.getValuesParseToSelect(response.data))
			}
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const [safras, setSafras] = useState([]);
	const getSafras = async () => {
		let result = null;
		if (JSON.parse(localStorage.getItem('fazendaId'))) {
			result = await SafraService.get(JSON.parse(localStorage.getItem('fazendaId')).value);
			let safras = []
			result.data.result.map((safra) => {
				if (ObjectHelper.multisafra().find(x => x === safra.id)) {
					var obj = {
						label: safra.nome,
						value: safra.id,
						culturaId: safra.cultura ? safra.cultura.id : ""
					}
					safras.push(obj);
				}
				return safras
			})
			setSafras(safras)
		} else {
			return;
		}
	}

	const [atividades, setAtividades] = useState([]);
	const getAtividades = async () => {
		const { data } = await culturaService.GetBySafras(ObjectHelper.multisafra());
		setAtividades(data);
	}


	return (
		<>
			<Title permissaoBotao={PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR}>
				Apontamentos
			</Title>
			<ThemeProvider theme={theme}>

				<AppBar position="static" color="default">
					<Tabs
						classes={classes}
						value={value}
						onChange={handleChange}
						indicatorColor="none"
						textColor="none"
						variant="fullWidth"

						aria-label="full width tabs example"
					>
						<Tab label="Mão de obra" {...a11yProps(0)} />
						<Tab label="Máquina" {...a11yProps(1)} />
						<Tab label="Insumos" {...a11yProps(2)} />
					</Tabs>
				</AppBar>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={value} index={0} >
						<TabelaAjusteApontamentoMaoDeObra
							history={props.history}
							colaboradores={colaboradores}
							locais={locais}
							servicos={servicos}
							gruposServicos={gruposServicos}
							safras={safras}
							atividades={atividades}
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<TabelaAjusteApontamentoMaquina
							history={props.history}
							maquinas={maquinas}
							locais={locais}
							servicos={servicos}
							gruposServicos={gruposServicos}
							safras={safras}
							atividades={atividades}
						/>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<TabelaAjusteApontamentoInsumos
							history={props.history}
							insumos={insumos}
							locais={locais}
							servicos={servicos}
							gruposServicos={gruposServicos}
							safras={safras}
							atividades={atividades}
						/>
					</TabPanel>
				</SwipeableViews>
			</ThemeProvider>

		</>
	);
}
