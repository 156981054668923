import React from 'react';
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import Title from '../../components/Title/title.component';
import Table from '../../components/Table/table.component';
import Modal from "../../utils/modalHelper";
import ToastHelper from '../../utils/toastHelper';
import ElementoAnaliseSoloService from 'services/ElementoAnaliseSoloService';
import { PERMISSAO } from '../../permissoes/permissoes';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';

const NUMERO_ITENS_POR_PAGINA = 10;

export default class ElementoAnaliseSoloListagem extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			currentPage: 1,
			filtro: "",
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
		}
		this.history = this.props.history
	}

	async componentDidMount() {
		await this.buscar();
	}

	IPO_CADASTRO = 'cultura';

	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Código', property: 'codigo' },
		{ id: 2, title: 'Sigla', property: 'sigla' },
		{ id: 3, title: 'Unidade', property: 'unidade' }
	];

	actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_CULTURA_LEITURA,
			action: item => this.visualizar(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			mostrarSeRegistroSistema: true,
			permissao: PERMISSAO.MODULO_CULTURA_ATUALIZAR,
			action: item => this.editar(item)
		},
		{
			id: 3,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_CULTURA_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];

	async handlePaginacao(page) {
		await this.setState({ currentPage: page });
		await this.buscar();
	}

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		await this.buscar();
	}

	async buscar() {
		let param = {
			fazendaid: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: this.state.currentPage,
			pageSize: 10,
			filtro: this.state.filtro
		};
		let result = await ElementoAnaliseSoloService.buscarTodas(param);
		await this.setState({
			data: { ...result.data.result }
		});
	}

	tableCultura() {
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					acoesSingleButton={false}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
				/>
			</>
		)
	};

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				title='Excluir elemento'
				buttonTitle='Confirmar'
				button2Title='Cancelar'
				buttom2Action={() => this.handleModalExcluir(null, 'EXCLUIR')}
				submit={() => this.excluir(this.state.idItem)}
				close={() => this.handleModalExcluir(null, 'EXCLUIR')}
				content={
					<h6>Quer realmente excluir esse elemento da análise do solo ?</h6>
				}
			/>
		)
	}

	async handleModalExcluir(item) {
		if (item) {
			await this.setState({
				...this.state,
				idItem: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}


	cadastrar = () => {
		this.history.push(`/analiseSolo/elementos/cadastro`);
	}

	editar(item) {
		this.history.push({
			pathname: `/analiseSolo/elementos/editar/${item.id}`
		});
	}

	visualizar(item) {
		this.history.push({
			pathname: `/analiseSolo/elementos/visualizar/${item.id}`
		});
	}

	async excluir(item) {
		let { data } = await ElementoAnaliseSoloService.deletar(item);
		if (data.isOk) {
			ToastHelper.success("Elemento excluído com sucesso!");
			await this.buscar();
		} else {
			ToastHelper.error(data.mensagem);
		}
		await this.handleModalExcluir();
	}

	render() {
		return (
			<div className="w-100">
				<Title
					acao="Novo Elemento"
					permissaoBotao={PERMISSAO.MODULO_CULTURA_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.cadastrar}>
					Lista de Elementos da Análise do Solo
                </Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div>
						{this.tableCultura()}
					</div>
				</CardStyle>
				{this.modalExcluir()}
			</div>
		)
	}
}
