import React from 'react';
import Modal from 'react-bootstrap/es/Modal'


export default function LimparPlanejamentosModal(props) {

	const handleSubmit = limpar => {
		return props.limpar(limpar)
	}

	return (
		<>
			<Modal show={props.show} onHide={() => props.close()}>
				<Modal.Header closeButton >
					<h3 style={{ textAlign: 'center' }}>Deseja limpar todos planejamentos antes de prosseguir?</h3>

					<hr></hr>
				</Modal.Header>
				<Modal.Footer className="" style={{ marginTop: '-5px' }}>
					<div className="col-md-12">
						<button
							className="btn btn-outline-secondary pull-left"
							variant="success" onClick={() => handleSubmit("nao")} >
							<i className='fas fa-close' /> Não
							</button>
						<button className="btn btn-primary active pull-right" variant="success" onClick={() => handleSubmit("sim")} >
							<i class="fas fa-check"></i> Sim
							</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}
