import React, { useState, useEffect, useCallback } from "react"; import ObjectHelper from "utils/objectHelper";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import Title from "../../components/Title/title.component";
import SubmitFormButtons from "../../components/submitFormButtons/submitFormButtons";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ToastHelper from "../../utils/toastHelper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ModulosCreators } from "./store/agendamento.ducks";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import Table from "../../components/Table/table.component";
import { Progress } from "react-sweet-progress";
import Modal from "../../utils/modalHelper";
import AgendamentoService from "services/agendamentoService";
import DateHelper from "../../utils/dateHelper";
import MaterialTable from "material-table";
import { MTableToolbar } from "material-table";
import ReactTooltip from "react-tooltip";
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Select from "react-select";
import Paper from "@material-ui/core/Paper";

function AgendamentoCadastro(props) {
	const {
		match: { params }
	} = props;
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [dataInicio, setDataInicio] = useState();
	const [dataFim, setDataFim] = useState();
	const [items, setItems] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [tiposRecursos, setTiposRecursos] = useState([]);
	const [insumos, setInsumos] = useState([]);
	const [quantidadeDeItems, setQuantidadeDeItems] = useState({
		label: "20",
		value: 20
	});
	const [cachSelecionados, setCachSelecionados] = useState([]);
	const [cachTiposRecursos, setCachTiposRecursos] = useState([]);

	const opcoesDeQuantidade = [
		{
			label: "20",
			value: 20
		},
		{
			label: "50",
			value: 50
		},
		{
			label: "100",
			value: 100
		}
	];

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: "#4caf50"
			},
			secondary: {
				main: "#009688"
			}
		}
	});

	useEffect(() => {
		async function fetchData() {

			await getOrdensServicos();
		}
		fetchData();
	}, []);

	const handleChangeQuantidadeDeItems = quantidade => {
		async function fetchData() {
			await setQuantidadeDeItems(quantidade);
			await getOrdensServicos(quantidade);
		}
		fetchData();
	};

	const getOrdensServicos = quantidade => {
		async function fetchData() {
			let { data } = await AgendamentoService.buscarOrdensServico({
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				safraId: ObjectHelper.primeiraSafra(),
				agendamentoId: params.id,
				quantidadeDeItems: quantidade ? quantidade.value : 20
			});
			if (data.result) {
				data.result.forEach(item => {
					item.progressoBar = (
						<div className="ml-1 mr-2">
							<Progress percent={item.progresso} />
						</div>
					);
					cachSelecionados.forEach(callBack => {
						if (callBack === item.id) {
							item.tableData = {
								...item.tableData,
								checked: true
							};
						}
					});
				});
				setItems(data.result);
			}
			if (params.id) await props.getAgendamentoById(params.id);
		}
		fetchData();
	};

	useEffect(() => {
		if (props.agendamento && params.modo === "editar" && items) {
			let dataInicial = new Date(
				DateHelper.createDate(
					props.agendamento.dataInicio.split(" ")[0]
				)
			);
			let dataFim = new Date(
				DateHelper.createDate(props.agendamento.dataFim.split(" ")[0])
			);
			setValue("agenda", props.agendamento.agenda);
			setValue("dataInicio", dataInicial);
			setValue("dataFim", dataFim);
			let newItems = [...items];
			newItems = adicionaSelecionados(newItems);
			newItems = adicionaConcluidos(newItems);
			setDataInicio(dataInicial);
			setDataFim(dataFim);
			setItems(newItems);
			montarListaTipoRecurso(newItems);
			montarListaInsumos(newItems);
		}
	}, [props.agendamento]);

	const adicionaSelecionados = newItems => {
		props.agendamento.selecionados.forEach(item => {
			let newItem = { ...item };
			newItem.tableData = { checked: true };
			newItem.progressoBar = (
				<div className="ml-1 mr-2">
					<Progress percent={newItem.progresso} />
				</div>
			);
			newItems.push(newItem);
		});
		return newItems;
	};

	const adicionaConcluidos = newItems => {
		props.agendamento.concluidos.forEach(item => {
			let newItem = { ...item };
			newItem.tableData = { checked: true };
			newItem.progressoBar = (
				<div className="ml-1 mr-2">
					<Progress percent={newItem.progresso} />
				</div>
			);
			newItems.push(newItem);
		});
		return newItems;
	};

	const locationTable = {
		header: {
			actions: "Ações"
		},
		toolbar: {
			nRowsSelected: "{0} OS('s) selecionada(s)"
		},
		body: {
			emptyDataSourceMessage: "Nenhum registro para exibir",
			addTooltip: "Adicionar",
			deleteTooltip: "Excluir",
			editTooltip: "Editar",
			editRow: {
				deleteText: "Deseja excluir essa linha?",
				cancelTooltip: "Cancelar",
				saveTooltip: "Confirmar"
			}
		}
	};

	const columns = [
		{
			title: "Código",
			field: "codigo",
			render: item => (
				<Link
					style={{ color: "#009688", cursor: "pointer" }}
					target="_blank"
					to={`/ordemservico/` + item.id}
				>
					{item.codigo}
				</Link>
			)
		},
		{ title: "Grupo de Serviços", field: "grupoServico.nome" },
		{ title: "Serviços", field: "servico.nome" },
		{
			title: "Local",
			field: "servico.nome",
			render: rowData => renderLocaisTable(rowData)
		},
		{ title: "Status", field: "status.label" },
		{
			title: <i className="fas fa-user-clock icon-in-head-table"> (h)</i>,
			field: "totalMO",
			type: "numeric",
			align: "right"
		},
		{
			title: <i className="fas fa-tractor icon-in-head-table"> (h)</i>,
			field: "totalMA",
			type: "numeric",
			align: "right"
		},
		{ title: "Data Inicial", field: "dataInicio" },
		{ title: "Data Final", field: "dataFim" },
		{ title: "Progresso", field: "progressoBar" }
	];

	const columnsTiposRecursos = [
		{ title: "Tipo de recurso", field: "tipo", editable: "never" },
		{
			title: "Horas Previstas",
			field: "horasPrevistas",
			type: "numeric",
			editable: "never",
			align: "right",
			render: rowData => <div>{rowData.horasPrevistas.toFixed(2)}</div>
		},
		{
			title: "FTE",
			field: "fte",
			type: "numeric",
			cellStyle: { textAlign: "-webkit-right" }
		},
		{
			title: "Dias",
			field: "dias",
			type: "numeric",
			cellStyle: { textAlign: "-webkit-right" }
		},
		{
			title: "Horas",
			field: "horas",
			type: "numeric",
			cellStyle: { textAlign: "-webkit-right" }
		},
		{
			title: "Total de horas",
			field: "totalHoras",
			type: "numeric",
			editable: "never",
			cellStyle: { textAlign: "-webkit-right" }
		},
		{
			title: "Delta",
			field: "delta",
			type: "numeric",
			editable: "never",
			cellStyle: { textAlign: "-webkit-right" }
		}
	];

	const columnsInsumos = [
		{ id: 18, title: "Nome", property: "nome" },
		{ id: 19, title: "Quantidade", property: "quantidade" },
		{ id: 20, title: "Unidade", property: "unidade" }
	];

	const renderLocaisTable = rowData => {
		if (rowData.locais.length > 1) {
			let lista = montarListaLocais(rowData.locais);
			return (
				<>
					<div data-tip={lista}>
						{rowData.locais[0].nome + "..."}
						<i
							className="fas fa-search-plus w-1"
							style={{ color: "#009688", cursor: "pointer" }}
						/>
					</div>
					<ReactTooltip
						place="right"
						type="dark"
						delayShow={200}
						effect="float"
					/>
				</>
			);
		} else {
			return <div>{rowData.locais[0].nome}</div>;
		}
	};

	const montarListaLocais = locais => {
		let newLista = "";
		locais.forEach(local => {
			newLista = newLista + (newLista.length > 0 ? "," : "") + local.nome;
		});
		return newLista;
	};

	const onChangeSelect = (rows, data) => {
		let newItems = [...items];
		if (data === undefined) {
			newItems.forEach(row => {
				if (row.status.codigo === "CONCLUIDO")
					row.tableData.checked = true;
			});
			setItems(newItems);
		}
		montarListaTipoRecurso(newItems);
		montarListaInsumos(newItems);
		guardaSelecionados();
	};

	const guardaSelecionados = () => {
		let newSelecionados = [];
		let newItems = [...items];
		newItems.forEach(row => {
			if (row.tableData.checked) newSelecionados.push(row.id);
		});
		setCachSelecionados(newSelecionados);
	};

	const montarListaTipoRecurso = async rows => {
		let newListTipoRecurso = [];
		await rows.forEach(ordenServico => {
			if (ordenServico.tableData.checked) {
				ordenServico.recursos.forEach(recurso => {
					var jaExisteEsteTipo = newListTipoRecurso.find(
						e => e.id === recurso.classe.id
					);
					if (jaExisteEsteTipo !== undefined) {
						newListTipoRecurso.forEach(e => {
							if (e.id === recurso.classe.id) {
								let newHorasPrevistas =
									e.horasPrevistas + recurso.quantidade;
								e.horasPrevistas = newHorasPrevistas;
							}
						});
					} else {
						let newItem = {
							id: recurso.classe.id,
							tipo: recurso.classe.nome,
							codigo: recurso.classe.codigo,
							horasPrevistas: recurso.quantidade,
							fte: " 0",
							dias: " 0",
							horas: " 0",
							totalHoras: " 0",
							delta: " 0"
						};
						cachTiposRecursos.forEach(item => {
							if (item.id === newItem.id) {
								newItem.fte = item.fte;
								newItem.dias = item.dias;
								newItem.horas = item.horas;
							}
						});
						newListTipoRecurso.push(newItem);
					}
				});
			}
		});
		await setTiposRecursos(newListTipoRecurso);
		await setCachTiposRecursos(newListTipoRecurso);
		await calculaDelta(newListTipoRecurso);
	};

	const montarListaInsumos = rows => {
		let newListInsumos = [];
		rows.forEach(ordenServico => {
			if (ordenServico.tableData.checked) {
				ordenServico.insumos.forEach(insumo => {
					var jaExisteEsteTipo = newListInsumos.find(
						e => e.id === insumo.id
					);
					if (jaExisteEsteTipo === undefined) {
						newListInsumos.push({
							id: insumo.id,
							nome: insumo.nome,
							quantidade: insumo.quantidade,
							unidade: insumo.unidade
						});
					} else {
						newListInsumos.forEach(e => {
							if (e.id === insumo.id) {
								e.quantidade = e.quantidade + insumo.quantidade;
							}
						});
					}
				});
			}
		});
		setInsumos(newListInsumos);
	};

	const validaData = values => {

		const diffTime = Math.abs(values.dataFim - values.dataInicio);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		if (diffDays > 31) {
			ToastHelper.error(
				"A agenda não pode ter um período maior que 31 dias corridos!"
			);
			return false;
		}

		if (values.dataInicio > values.dataFim) {
			ToastHelper.error(
				"Data início não pode ser maior que a data final!"
			);
			return false;
		} else return true;
	};

	const submitForm = useCallback(
		data => {
			if (validaData(data)) {
				let newAgendamento = {
					id: params.id,
					ordensServicos: [],
					dataInicio: data.dataInicio,
					dataFim: data.dataFim,
					agenda: data.agenda,
					horasMo: 0,
					horasMa: 0,
					retrospectiva: "retrospectiva",
					fazendaId: JSON.parse(localStorage.getItem("fazendaId"))
						? JSON.parse(localStorage.getItem("fazendaId")).value
						: "",
					safraId: JSON.parse(localStorage.getItem("safraId"))
						? ObjectHelper.primeiraSafra()
						: ""
				};
				items.forEach(item => {
					if (item.tableData.checked) {
						newAgendamento.ordensServicos.push(item.id);
					}
				});
				tiposRecursos.forEach(tipoRecurso => {
					if (tipoRecurso.codigo.includes("MA")) {
						newAgendamento.horasMa =
							newAgendamento.horasMa +
							parseInt(tipoRecurso.horasPrevistas, 10);
					}
					if (tipoRecurso.codigo.includes("MO")) {
						newAgendamento.horasMo =
							newAgendamento.horasMo +
							parseInt(tipoRecurso.horasPrevistas, 10);
					}
				});
				if (newAgendamento.ordensServicos.length > 0) {
					if (params.id !== undefined) {
						props.updateAgendamento(newAgendamento, props.history);
					} else {
						props.createAgendamento(newAgendamento, props.history);
					}
				} else ToastHelper.error("Selecione uma ordem de serviço!");
			}
		},
		[items, tiposRecursos]
	);

	const atualizaInput = async (newValue, rowData, columnDef) => {
		let newTiposRecursos = [...tiposRecursos];
		await newTiposRecursos.forEach(tipo => {
			if (tipo.id === rowData.id) {
				tipo[columnDef.field] = newValue;
				if (rowData.fte > 0 && rowData.dias > 0 && rowData.horas > 0) {
					tipo.totalHoras =
						rowData.fte * rowData.dias * rowData.horas;
					tipo.delta = tipo.totalHoras - tipo.horasPrevistas;
				} else {
					tipo.totalHoras = 0;
					tipo.delta = 0;
				}
			}
		});
		await setTiposRecursos(newTiposRecursos);
		await calculaDelta();
	};

	const calculaDelta = lista => {
		let newTiposRecursos = lista ? [...lista] : [...tiposRecursos];
		newTiposRecursos.forEach(tipo => {
			if (tipo.fte > 0 && tipo.dias > 0 && tipo.horas > 0) {
				tipo.totalHoras = tipo.fte * tipo.dias * tipo.horas;
				tipo.delta = tipo.totalHoras - tipo.horasPrevistas;
				tipo.totalHoras = tipo.totalHoras.toFixed(2);
				tipo.delta = tipo.delta.toFixed(2);
			}
		});
		setTiposRecursos(newTiposRecursos);
	};

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="row">
					<div className="col">
						<Title>
							{params.id ? "Editar " : "Cadastrar "} Agendamento
						</Title>
					</div>
				</div>
				<CardStyle className="card">
					<div className="card-header row justify-content-start col-md-12 ml-0">
						<div className="col-md-6">
							<label className="label">Agenda:*</label>
							<input
								type="text"
								name="agenda"
								placeholder="Digite um nome"
								className="form-control"
								autoComplete="off"
								ref={register({ required: true })}
							/>
							{errors.agenda && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="col-md-3">
							<label className="label">Data inicial:*</label>
							<DatePicker
								name="dataInicio"
								htmlFor="dataInicio"
								selected={dataInicio}
								isClearable={true}
								placeholderText="Informe a data inicial"
								className="form-control bmd-form-group"
								dateFormat="dd/MM/yyyy"
								autoComplete="off"
								ref={() =>
									register(
										{ name: "dataInicio" },
										{ required: true }
									)
								}
								onChange={date => {
									setValue("dataInicio", date);
									setDataInicio(date);
								}}
								value={dataInicio}
								locale={pt}
							/>
							{errors.dataInicio && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="col-md-3">
							<label className="label">Data Final:*</label>
							<DatePicker
								name="dataFim"
								htmlFor="dataFim"
								selected={dataFim}
								isClearable={true}
								placeholderText="Informe a data final"
								className="form-control bmd-form-group"
								dateFormat="dd/MM/yyyy"
								autoComplete="off"
								ref={() =>
									register(
										{ name: "dataFim" },
										{ required: true }
									)
								}
								onChange={date => {
									setValue("dataFim", date);
									setDataFim(date);
								}}
								value={dataFim}
								locale={pt}
							/>
							{errors.dataFim && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
					</div>
					<div className="col-md-12">
						<MuiThemeProvider theme={theme}>
							<Paper elevation={0} />

							<Paper />
							<MaterialTable
								title={"Lista de Atividades"}
								columns={columns}
								components={{
									Toolbar: props => (
										<div>
											<MTableToolbar {...props} />
											<div
												className={
													"col-md-12 flex custom-tollbar-table"
												}
											>
												<div
													className={"mt-2"}
													style={{
														padding: "0px 10px"
													}}
												>
													Quantidade:
												</div>
												<div
													className={"col-md-1"}
													style={{
														padding: "0px 10px",
														zIndex: 10
													}}
												>
													<Select
														id="numeroDeItems"
														name="numeroDeItems"
														placeholder="Selecionar"
														onChange={item =>
															handleChangeQuantidadeDeItems(
																item
															)
														}
														className="select-style ajust-label-padrao disabled-select"
														options={
															opcoesDeQuantidade
														}
														theme={theme => ({
															...theme,
															borderRadius: 0,
															zIndex: 10
														})}
														value={
															quantidadeDeItems
														}
													/>
												</div>
											</div>
										</div>
									)
								}}
								data={items}
								options={{
									paging: false,
									actionsColumnIndex: -1,
									selection: true,
									selectionProps: rowData => ({
										disabled:
											rowData.status.codigo ===
											"CONCLUIDO",
										color: "primary"
									}),
									search: false,
									headerStyle: {
										zIndex: 0
									}
								}}
								localization={locationTable}
								onSelectionChange={(data, rows) =>
									onChangeSelect(data, rows)
								}
							/>
						</MuiThemeProvider>
					</div>
					<div className=" linha-divisoria"></div>
					<div className="col-md-12 mb-3">
						<MaterialTable
							title={"Tipos de recursos"}
							columns={columnsTiposRecursos}
							data={tiposRecursos}
							options={{
								paging: false,
								actionsColumnIndex: -1,
								search: false,
								detailPanelColumnAlignment: "right"
							}}
							localization={locationTable}
							cellEditable={{
								onCellEditApproved: (
									newValue,
									oldValue,
									rowData,
									columnDef
								) => {
									if (!newValue) newValue = " 0";
									return new Promise((resolve, reject) => {
										atualizaInput(
											newValue,
											rowData,
											columnDef
										);
										setTimeout(resolve, 300);
									});
								}
							}}
						/>
					</div>
					{tiposRecursos.length > 0 && (
						<div className="col-md-2 ml-0 pull-left mt-3 mb-3 pt-0">
							<button
								className="btn btn-primary active btn-lista-insumos mt-0"
								type="button"
								onClick={() => setShowModal(true)}
								hidden={props.flagVisualizar}
							>
								Lista de insumos
							</button>
						</div>
					)}
				</CardStyle>
				<SubmitFormButtons
					flagEditar={params.id !== undefined}
					actionGoBack={() => props.history.goBack()}
					agendar={true}
				/>
			</form>
			<Modal
				show={showModal}
				doubleButtons={false}
				title={"Insumos necessários"}
				buttonTitle={"voltar"}
				buttom2Action={() => setShowModal(false)}
				submit={() => setShowModal(false)}
				close={() => setShowModal(false)}
				content={
					<Table
						align="right"
						totalElements={insumos.length}
						columns={columnsInsumos}
						data={insumos || []}
						emptyMessage="Nenhum item encontrado."
						emptyColSpan={columns ? columns.length + 1 : 1}
					/>
				}
			/>
		</>
	);
}

const mapStateToProps = state => ({
	agendamento: state.agendamento.agendamento
		? state.agendamento.agendamento.result
		: undefined
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AgendamentoCadastro);
