
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {Types} from "./agendamento.ducks";
import executeAction from "../../../store/sagas/executeAction";
import ToastHelper from "../../../utils/toastHelper";
import AgendamentoService from '../../../services/agendamentoService'

function* asyncCreateAgendamento(action){
	yield executeAction(function* () {

		const { params, history, callback } = action;
		const { data } = yield call( async () => await AgendamentoService.create(params));
		if(data.isOk){
			ToastHelper.success("Operação concluída com sucesso!")
			history.goBack()
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.POST_AGENDAMENTO, data: data });
		callback();
	}, Types.CREATE_AGENDAMENTO);
}

function* getAgendamentoById(action){
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call( async () => await AgendamentoService.buscarPorId(params));
		if(!data.isOk)
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.SET_AGENDAMENTO_BY_ID, data: data });
		callback();
	}, Types.GET_AGENDAMENTO_BY_ID);
}

function* updateAgendamento(action){
	yield executeAction(function* () {

		const { params, history} = action;
        
		const { data } = yield call( async () => await AgendamentoService.update(params));
		if(data.isOk){
			ToastHelper.success("Agendamento atualizado com sucesso!")
			history.goBack()
		} else 
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.SET_UPDATE_AGENDAMENTO,
			data
		});
	}, Types.UPDATE_AGENDAMENTO);
}

function* takeCreateAgendamento() {
	yield takeLatest(Types.CREATE_AGENDAMENTO, asyncCreateAgendamento);
}

function* takeGetAgendamentoById() {
	yield takeLatest(Types.GET_AGENDAMENTO_BY_ID, getAgendamentoById);
}

function* takeUpdateAgendamento() {
	yield takeLatest(Types.UPDATE_AGENDAMENTO, updateAgendamento);
}

export default function* root() {
	yield all([fork(takeCreateAgendamento)])
	yield all([fork(takeGetAgendamentoById)])
	yield all([fork(takeUpdateAgendamento)])
}

