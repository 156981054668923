import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

export const { Types, Creators } = createActions({
	//Actions Saga
	permissaoSetar: ['usuarioId', 'callback'],

	//Actions reducers
	permissaoSetarReducer: ["permissoes"],
});

const INITIAL_STATE_VALUES = {
	permissoes: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const permissaoSetar = (state = INITIAL_STATE, action) => state.merge({ permissoes: [...action.permissoes] });

export default createReducer(INITIAL_STATE, {
	[Types.PERMISSAO_SETAR_REDUCER]: permissaoSetar
});
