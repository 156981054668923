import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({


    getMaterialById: ['params', 'setAuxMaterials','callback'],
	setGetMaterialById: ['params'],
    
})

const INITIAL_STATE_VALUES = {
    material: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getMaterialById = (state = INITIAL_STATE, action) => ( {...state, material: action.data } )

export default createReducer(INITIAL_STATE, {
    
    [Types.SET_GET_MATERIAL_BY_ID]: getMaterialById,

})