import React from 'react';
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import Title from "../../components/Title/title.component";
import Table from '../../components/Table/table.component';
import TipoMaquinaService from "../../services/tipoMaquinaService";
import ToastHelper from "../../utils/toastHelper";
import Modal from '../../utils/modalHelper';
import { PERMISSAO } from '../../permissoes/permissoes';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Utils from '../../utils/utils';

const NUMERO_ITENS_POR_PAGINA = 10;

export default class TipoMaquinaListagem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			fazendaId: null,
			page: 0,
			pageSize: 10,
			currentPage: 1,
			erro: '',
			showModalExcluir: false,
			idTipoMaquinaExcluir: '',
			showModalAtivaDesativa: false,
			idTipoAtivaDesativa: '',
			filtro: '',
			orderColumn: '',
			orderTable: '',
			descending: '',
			tipoMaquina: {
				nome: '',
				descricao: ''
			},
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
		}
		this.history = this.props.history;
		this.handleModalExcluir = this.handleModalExcluir.bind(this);
	}

	paginate = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1, pageSize: 10, filtro: '', order: 'Nome', descedescending: false,
		safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
	}

	async componentDidMount() {
		await this.buscar(this.paginate);
	}

	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Descrição', property: 'descricao' },
		{ id: 2, title: 'Ativo', property: 'isAtivo' }
	];

	actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_TIPO_MAQUINA_LEITURA,
			action: item => this.visualizar(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_TIPO_MAQUINA_ATUALIZAR,
			action: item => this.editar(item),
		},
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
			permissao: PERMISSAO.MODULO_TIPO_MAQUINA_ATUALIZAR,
			action: item => this.handleModal(item, 'SETAR_STATUS'),
		},
		{
			id: 4,
			name: 'Apagar',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_TIPO_MAQUINA_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];

	TIPO_CADASTRO = 'tipo/maquina';

	async visualizar(item) {
		this.history.push(`/${this.TIPO_CADASTRO}/visualizar/${item.id}`);
	}

	cadastrar = () => {
		this.history.push(`/${this.TIPO_CADASTRO}/cadastro`);
	}

	async editar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/editar/${item.id}`
		});

	}

	async excluir(item) {

		let data = await TipoMaquinaService.deletar(item);
		if (data.data.isOk) {
			ToastHelper.success("Tipo de máquina excluído com sucesso!");
			await this.buscar(this.paginate);
		} else {
			ToastHelper.error(data.data.mensagem)
		}
		await this.handleModalExcluir();
	}

	async buscar(param) {

		let result = await TipoMaquinaService.buscarTodas(param);

		if (result.data.result) {
			await result.data.result.items.forEach(item => {
				if (item.ativo) {
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});

			await this.setState({
				data: {
					...result.data.result
				}
			});
		}
	}

	table() {

		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					actions={this.actions}
					data={this.state.data.items}
					onChangePage={page => this.handlePaginacao(page)}
					currentPage={this.state.currentPage}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 1}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
					totalItemPages={10}
				/>
			</>
		)
	};

	async handleModal(item) {
		if (item) {

			await this.setState({
				...this.state,
				idTipoAtivaDesativa: item.id,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			})
		} else {

			await this.setState({
				...this.state,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			})
		}
	}

	async handleModalExcluir(item) {
		if (item) {

			this.setState({
				...this.state,
				idTipoMaquinaExcluir: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {

			this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				doubleButtons={true}
				title={"Excluir Tipo de Máquina"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => this.handleModalExcluir()}
				submit={() => this.excluir(this.state.idTipoMaquinaExcluir)}
				close={() => this.handleModalExcluir()}
				content={
					<h6>{"Deseja realmente excluir esse tipo?"}</h6>
				}
			/>
		);
	};

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		let newPaginate = this.paginate
		newPaginate.filtro = filtro
		newPaginate.page = 1
		await this.buscar(newPaginate);
	}

	orderTable = (property, descending) => {
		this.setOrderColumn(Utils.parsePropertToFilter(property));
		this.setDescending(descending);
		let params = this.paginate;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		this.buscar(params)
	}

	setOrderColumn = (property) => {
		this.setState({
			...this.state,
			orderColumn: property
		})
	}

	setDescending = (descending) => {
		this.setState({
			...this.state,
			descending: descending
		})
	}

	async handlePaginacao(page) {
		await this.setState({ currentPage: page });
		let newPaginate = this.paginate
		newPaginate.page = page
		await this.buscar(newPaginate);
	}

	async ativaDesativa(item) {
		let dados = await TipoMaquinaService.ativaDesativa(item);

		if (dados.status === 200) {

			ToastHelper.success("Status do tipo de máquina alterado com sucesso!");
			await this.buscar(this.paginate);
			await this.handleModal(null);
		} else {

			ToastHelper.error("Erro ao tentar alterar o status do tipo de máquina!");
		}
	};

	render() {
		return (
			<div>

				<Title
					acao="Novo Tipo"
					permissaoBotao={PERMISSAO.MODULO_TIPO_MAQUINA_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.cadastrar}>
					Tipos de Máquinas
                </Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div className="col-md-12">
						{this.table()}
					</div>
				</CardStyle>
				{this.modalExcluir()}
				<Modal
					show={this.state.showModalAtivaDesativa}
					title={'Ativar / Desativar Tipo de Máquina'}
					buttonTitle={"Confirmar"}
					button2Title={"Cancelar"}
					buttom2Action={() => this.handleModal(null, 'SETAR_STATUS')}
					submit={() => this.ativaDesativa(this.state.idTipoAtivaDesativa)}
					close={() => this.handleModal(null, 'SETAR_STATUS')}
					content={
						<h6>{'Deseja realmente alterar o status deste tipo?'}</h6>
					}
				/>
			</div>
		)
	}
}
