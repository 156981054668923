import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/es/Modal';
import TabelaApontamentoInsumos from './tabelaApontamentoInsumos.component'

function EditarReceitaModal(props) {

    const [insumos, setInsumos] = useState([]);
    const [volumeMistura, setVolumeMistura] = useState('');
    const [quantidadeReceita, setQuantidadeReceita] = useState(0);

    useEffect(() => {
		if (props.insumos) {
			setInsumos(props.insumos);
		}
        if (props.volumeMistura) {
			setVolumeMistura(props.volumeMistura);
		}
        if (props.quantidadeReceita) {
			setQuantidadeReceita(props.quantidadeReceita);
		}
	}, [props.show]);

    const addInsumo = useCallback(async (item) => {
        setInsumos([...insumos, item]);
	});

	const editInsumo = useCallback(async (item) => {
        setInsumos([...item]);
	});

	const deleteInsumo = useCallback(async (item) => {
        setInsumos([...item]);
    });

    const cancelar = useCallback(async () => {
		props.close();
    });

    const salvar = useCallback(async () => {
        insumos.forEach(item => {
            item.quantidade = Math.round(
                (
                    (item.quantidade * (quantidadeReceita / volumeMistura)) 
                    + Number.EPSILON) * 100
                ) / 100;
        })
		props.submit(insumos);
		props.close();
	});

    return (
            <>
                <Modal
                    show={props.show}
                    dialogClassName="modal-dialog-lg"
                    onHide={() => cancelar()}>
                    <Modal.Body>
                        <h3 className='mt-3'>{"Editar os componentes da calda para o lançamento"}</h3>
                            <div className="row col-md-12 flex mt-4">
                                <div className="card-header col-md-4">
                                    <label className="label">
                                        Volume da Mistura:*
                                    </label>
                                    <input
                                        className='form-control bmd-form-group'
                                        placeholder="Informe o volume da mistura"
                                        maxLength="40"
                                        name="volumeMistura"
                                        onChange={(e) => setVolumeMistura(e.target.value)}
                                        value={volumeMistura}
                                        autoComplete="off"
                                        htmlFor="volumeMistura"
                                        type="text" />
                                </div>
                                <div className="card-header col-md-4">
                                    <label className="label">
                                        Quantidade Total Aplicada:*
                                    </label>
                                    <input
                                        className='form-control bmd-form-group'
                                        placeholder="Informe a quantidade total"
                                        maxLength="40"
                                        name="quantidadeReceita"
                                        onChange={(e) => setQuantidadeReceita(e.target.value)}
                                        value={quantidadeReceita}
                                        autoComplete="off"
                                        htmlFor="quantidadeReceita"
                                        type="text" />
                                </div>
                            </div>
                        <TabelaApontamentoInsumos
                            titulo={''}
                            recursos={insumos}
                            onAdd={addInsumo}
                            onEdit={editInsumo}
                            onDelete={deleteInsumo}
                            recursosMenuPlacement={'bottom'}
                            opcoesRecursosComCodigo={['IN']}
                            removerClasses={['EPI']} />
                   </Modal.Body>
                    <Modal.Footer className="footer-modal">
                        <div className="row col-md-12">
                            <div className="col-md-6">
                                <button className="btn btn-outline-secondary pull-left" type="button" onClick={cancelar}>
                                    <i className='fas fa-close' /> Cancelar
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-primary active pull-right" type="submit" disabled={!(volumeMistura > 0 && quantidadeReceita > 0)} onClick={salvar}>
                                    <i className='fas fa-check' /> Salvar
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
}

export default EditarReceitaModal;
