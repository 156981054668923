import React, { useState, useEffect } from "react";
import { CardStyle } from "../../../components/CardStyle/cardstyle.component";
import { bindActionCreators } from "redux";
import { Creators as SearchCreators } from "../../../components/AdvancedSearch/store/advancedSearch.ducks";
import { Creators as ListingMachinesCreators } from "./store/ma.ducks";
import { connect } from "react-redux";
import Utils from "../../../utils/utils";
import Toolbar from "../../../components/BasicPageTopBar/Toolbar";
import safraService from "../../../services/safraService";
import grupoServicoService from "../../../services/grupoServicoService";
import grupoLocaisService from "../../../services/grupoLocaisService";
import { MTablePagination } from 'material-table';
import Grid from '@material-ui/core/Grid';
import grupoMaquinaService from "services/grupoMaquinaService";
import maquinaService from "services/maquinaService";
import EditCompleteIcon from '@material-ui/icons/EditOutlined';
import servicoService from "../../../services/servicoService";
import ObjectHelper from "../../../utils/objectHelper";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

function ListingMachinePointing(props) {
	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState(useSelector(state => state.apontamentoMa.filtroListagem));
	const dispatch = useDispatch();
	const [locaisFiltro, setLocaisFiltro] = useState([]);
	const [grupoLocaisFiltro, setGrupoLocaisFiltro] = useState([]);
	const [gruposServicoFiltro, setGruposServicoFiltro] = useState([]);
	const [gruposMaquinaFiltro, setGruposMaquinaFiltro] = useState([]);
	const [maquinasFiltro, setMaquinasFiltro] = useState([]);
	const [servicosFiltro, setServicosFiltro] = useState([]);

	const getLocaisFiltro = async (fazendaId, safraId) => {
		let { data } = await safraService.locais(fazendaId, safraId, true);

		let newLocais = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setLocaisFiltro(newLocais);
	};

	const getGruposLocaisFiltro = async (fazendaId) => {
		let { data } = await grupoLocaisService.getAll({
			fazendaId: fazendaId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGrupoLocaisFiltro(newGrupos);
	};

	const getMaquinasFiltro = async (fazendaId, safraId) => {
		let { data } = await maquinaService.buscarTodas({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		var ativos = newGrupos.filter(x => x.ativo === true);
		setMaquinasFiltro(ativos);
	};

	const getGrupoMaquinasFiltro = async (fazendaId, safraId) => {
		let { data } = await grupoMaquinaService.getAll({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		var ativos = newGrupos.filter(x => x.ativo === true);
		setGruposMaquinaFiltro(ativos);
	};

	const getGruposServicoFiltro = async (fazendaId, safraId) => {
		let { data } = await grupoServicoService.buscarTodos({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGruposServicoFiltro(newGrupos);
	};

	const getServicosFiltro = async (fazendaId, safraId) => {
		let { data } = await servicoService.buscarTodas({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome",
			descedescending: true
		});

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	useEffect(() => {
		verificadorDeExistenciaDeSafra();
		setCurrentPage(filter.page);
		let safraId = ObjectHelper.primeiraSafra();
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		getGruposLocaisFiltro(fazendaId);
		getLocaisFiltro(fazendaId, safraId);
		getGruposServicoFiltro(fazendaId, safraId);
		getServicosFiltro(fazendaId, safraId);
		getMaquinasFiltro(fazendaId, safraId);
		getGrupoMaquinasFiltro(fazendaId, safraId);
		filter.descedescending = true;
		filter.order = "Data";
		props.getAppointmentMachines(filter);
	}, []);

	const [columns, setColumns] = useState([]);
	const verificadorDeExistenciaDeSafra = () => {
		let array = [
			{ id: 0, title: "Serviço realizado", field: "servico", editable: "never" },
			{ id: 1, title: "Máquina", field: "maquina", editable: "never" },
			{ id: 2, title: "Data", field: "data", editable: "never" },
			{ id: 3, title: "Local", field: "local", editable: "never" },
			{ id: 6, title: "Operador", field: "operador", editable: "never" },
			{
				id: 7, title: "Produtos", field: "produto", editable: "never",
				render: rowData => (
					<>
						<HtmlTooltip title={gerarTooltip(rowData)}>
							<div>
								{
									rowData.produtos.length === 1 &&
									<span>{rowData.produtos[0].recurso.nome}</span>
								}
								{
									rowData.produtos.length > 1 &&
									<span>{rowData.produtos[0].recurso.nome} (+)</span>
								}
							</div>
						</HtmlTooltip>
					</>
				)
			},
			{ id: 8, title: "Turno", field: "nome", editable: "never" },
			{ id: 9, title: "Horas máquina", field: "horimetroTotal", editable: "never" },
			{ id: 10, title: "Horas homem", field: "horaHomem", editable: "never" }
		]

		if (JSON.parse(localStorage.getItem('safraId')).length >= 2)
			array.splice(2, 0, { title: "Atividade", field: "atividade", editable: 'never' });

		if (JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2)
			array.splice(2, 0, { title: "Safra", field: "safra", editable: 'never' });

		setColumns(array);
	}



	const gerarTooltip = (item) => {
		return (
			<>
				<p>Produtos aplicados:</p>
				<ul>
					{item.produtos.map(p =>
						<li style={{ marginRight: '20px', marginBottom: '10px' }}>
							<span>
								{p.quantidade} {p.unidade.sigla} de {p.recurso.nome}
							</span>
							{
								p.recurso.tipo && p.recurso.tipo.codigo === "RE" &&
								<div style={{ marginTop: '5px' }}>
									<span style={{ fontWeight: "bold", }}>{`Total de produtos aplicados em ${p.quantidade} ${p.unidade.sigla} de receita:`}</span>
									<br></br>
									<ul>
										{p.recurso.receita.map(item =>
											<li>
												{Math.round(((item.quantidade * (p.quantidade / p.recurso.volumeMistura)) + Number.EPSILON) * 100) / 100} {item.unidade.sigla} de {item.nome}
											</li>
										)}
									</ul>
								</div>
							}
						</li>

					)}
				</ul>
			</>
		)

	}

	const actions = [
		{
			icon: EditCompleteIcon,
			tooltip: 'Edição',
			onClick: (event, rowData) => edit(rowData.id),
		},
	]

	const orderTable = (property, descending) => {
		var column = columns.find(x => x.id === property);
		var propertyParsed = Utils.parsePropertToFilter(column.field)


		let params = filter;
		params.order = propertyParsed;
		params.descedescending = !params.descedescending;
		props.getAppointmentMachines(params);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MO",
			filtroListagem: params
		});
	};

	const handleFilter = async newFilter => {
		newFilter.descedescending = true;
		newFilter.page = 1;

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MA",
			filtroListagem: newFilter
		});

		await setFilter(newFilter);
		props.getAppointmentMachines(newFilter);
	};

	const edit = item => {
		props.history.push({
			pathname: `/apontamento/maquina/${"edit"}/${item}`
		});
	};


	const newAppointment = () => {
		props.history.push({
			pathname: `/apontamento/maquina/cadastro`
		});
	};

	const changePage = params => {
		window.scrollTo(0, 0);
		setCurrentPage(params);
		let newFilter = filter;
		newFilter.page = params;
		setFilter(newFilter);
		props.getAppointmentMachines(newFilter);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MA",
			filtroListagem: newFilter
		});
	};

	const deleteAppointment = (deleteItem) => {
		props.deleteAppointmentMachine(deleteItem.id);
		setTimeout(function () {
			props.getAppointmentMachines(filter);
		}, 800);
	};

	const trocaGrupoServico = servicoTipoId => {
		let novoFiltro = filter;
		novoFiltro.servicoId = "";
		novoFiltro.servicoTipoId = servicoTipoId;
		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MA",
			filtroListagem: novoFiltro
		});
		if (servicoTipoId !== "") {
			getFiltroServicosByGrupoId(servicoTipoId);
		} else {
			let safraId = JSON.parse(localStorage.getItem("safraId"))
				? ObjectHelper.primeiraSafra()
				: "";
			let fazendaId = JSON.parse(localStorage.getItem("safraId"))
				? JSON.parse(localStorage.getItem("fazendaId")).value
				: "";
			getServicosFiltro(fazendaId, safraId);
		}
	};

	const getFiltroServicosByGrupoId = async grupoServico => {
		let { data } = await servicoService.buscarPorGrupoId(
			filter.fazendaId,
			grupoServico
		);

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	useEffect(() => {
		if (filter.servicoTipoId) {
			getFiltroServicosByGrupoId(filter.servicoTipoId);
		}
	}, []);

	return (
		<>
			<Toolbar
				style={{ marginTop: '-20px' }}
				safras={JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2}
				atividades={JSON.parse(localStorage.getItem('safraId')).length >= 2}
				handleFiltro={e => handleFilter(e)}
				filtroInicial={filter}
				onClickAdd={() => newAppointment()}
				filtroOperador
				filtroGrupoLocais={grupoLocaisFiltro}
				filtroLocais={locaisFiltro}
				filtroGruposServico={gruposServicoFiltro}
				filtroServicos={servicosFiltro}
				filtroMaquinas={maquinasFiltro}
				filtroGrupoMaquina={gruposMaquinaFiltro}
				getFiltroServicosByGrupoId={e => getFiltroServicosByGrupoId(e)}
				filtroPeriodo
				trocaGrupoServico={trocaGrupoServico}
				temFiltroAvancado
			/>
			<CardStyle className="card mt-4">
				<MaterialTable
					title="Apontamento de Máquinas"
					columns={columns}
					data={props.appointments ? props.appointments.items : []}
					onOrderChange={(numeroColuna, orientacao1) =>
						orderTable(numeroColuna, orientacao1)
					}
					options={{
						paging: true,
						pageSize: 10,
						search: false,
						actionsColumnIndex: -1,
					}}
					components={{
						Pagination: (paginat) => {
							const {
								classes,
								headerIsHidden,
								...other
							} = paginat;
							return (
								<>
									<Grid
										item
										container
										xs={12}
										justify="flex-end"
									>
										<MTablePagination
											{...other}
											onChangePage={(event, page) =>
												changePage(page + 1)
											}
											page={currentPage - 1}
											count={props.appointments ? props.appointments.total : 0}
											// pageSize={10}
											// rowsPerPage={10}
											localization={{
												labelRowsSelect: 'recursos',
												labelDisplayedRows:
													'{from}-{to} de {count}',
											}}
										/>
									</Grid>
								</>
							);
						},
					}}
					actions={actions}
					editable={{
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								deleteAppointment(oldData);
								resolve();
							})
					}}
					localization={{
						header: {
							actions: 'Ações'
						},
						body: {
							emptyDataSourceMessage:
								'Nenhum registro para exibir',
							addTooltip: 'Adicionar',
							deleteTooltip: 'Excluir',
							editTooltip: 'Edição rápida',
							editRow: {
								deleteText: 'Deseja excluir essa linha?',
								cancelTooltip: 'Cancelar',
								saveTooltip: 'Confirmar',
							},
						},
						toolbar: {
							searchTooltip: 'Pesquisar',
							searchPlaceholder: 'Pesquisar'
						}
					}}
				/>
			</CardStyle>
		</>
	);
}

const mapStateToProps = state => {
	return {
		appointments: state.appointmentMachines.appointmentMachines
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ ...SearchCreators, ...ListingMachinesCreators },
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListingMachinePointing);
