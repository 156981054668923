import React, { useState, useEffect } from "react";
import { CardStyle } from "components/CardStyle/cardstyle.component";
import { bindActionCreators } from "redux";
import { Creators as SearchCreators } from "components/AdvancedSearch/store/advancedSearch.ducks";
import { Creators as ListingManpowerCreators } from "./store/mo.ducks";
import { connect } from "react-redux";
import Utils from "utils/utils";
import Toolbar from "components/BasicPageTopBar/Toolbar";
import safraService from "services/safraService";
import grupoServicoService from "services/grupoServicoService";
import grupoLocaisService from "services/grupoLocaisService";
import servicoService from "services/servicoService";
import ObjectHelper from "utils/objectHelper";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from 'material-table';
import { MTablePagination } from 'material-table';
import Grid from '@material-ui/core/Grid';
import EditCompleteIcon from '@material-ui/icons/EditOutlined';
import SafraService from 'services/safraService';

function ListingManpowerAppointment(props) {
	const actions = [
		{
			icon: EditCompleteIcon,
			tooltip: 'Edição',
			onClick: (event, rowData) => edit(rowData.id),
		},
	];

	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useDispatch();
	const [locaisFiltro, setLocaisFiltro] = useState([]);

	const [gruposServicoFiltro, setGruposServicoFiltro] = useState([]);
	const [grupoLocaisFiltro, setGrupoLocaisFiltro] = useState([]);
	const [servicosFiltro, setServicosFiltro] = useState([]);
	const [filtro, setFiltro] = useState(useSelector(state => state.apontamentoMo.filtroListagem));

	const getLocaisFiltro = async (fazendaId, safraId) => {
		let { data } = await safraService.locais(fazendaId, safraId, true);

		let newLocais = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setLocaisFiltro(newLocais);
	};

	const getGruposServicoFiltro = async (fazendaId, safraId) => {
		let { data } = await grupoServicoService.buscarTodos({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGruposServicoFiltro(newGrupos);
	};

	const getGruposLocaisFiltro = async (fazendaId) => {
		let { data } = await grupoLocaisService.getAll({
			fazendaId: fazendaId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGrupoLocaisFiltro(newGrupos);
	};

	const getServicosFiltro = async (fazendaId, safraId) => {
		let { data } = await servicoService.buscarTodas({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	const orderTable = (property, descending) => {
		var column = columns.find(x => x.id === property);
		var propertyParsed = Utils.parsePropertToFilter(column.field)

		let params = filtro;
		params.order = propertyParsed;
		params.descedescending = !params.descedescending;
		props.getAppointmentManpower(params);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MO",
			filtroListagem: params
		});
	};

	const handleFilter = async newFilter => {
		newFilter.descedescending = true;
		newFilter.page = 1;

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MO",
			filtroListagem: newFilter
		});

		await setFiltro(newFilter);
		props.getAppointmentManpower(newFilter);
	};

	const [columns, setColumns] = useState([]);
	const verificadorDeExistenciaDeSafra = () => {
		let array = [
			{ title: "Nome", field: "pessoa", editable: 'never', tableData: {} },
			{ title: "Data", field: "data", editable: 'never' },
			{ title: "Local", field: "area", editable: 'never' },
			{ title: "Serviço realizado", field: "servico", editable: 'never' },
			{ title: "Status do Serviço", field: "status", editable: 'never' },
			{ title: "Horas Apontadas", field: "horas", editable: 'never' }
		];

		if (JSON.parse(localStorage.getItem('safraId')).length >= 2)
			array.splice(2, 0, { title: "Atividade", field: "atividade", editable: 'never' });

		if (JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2)
			array.splice(2, 0, { title: "Safra", field: "safra", editable: 'never' });

		setColumns(array);
	}

	useEffect(() => {
		verificadorDeExistenciaDeSafra();
		setCurrentPage(filtro.page);
		let safraId = ObjectHelper.primeiraSafra();
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		getGruposLocaisFiltro(fazendaId);
		getLocaisFiltro(fazendaId, safraId);
		getGruposServicoFiltro(fazendaId, safraId);
		getServicosFiltro(fazendaId, safraId);
		getSafras();
		filtro.order = "Data";
		filtro.descedescending = true;
		props.getAppointmentManpower(filtro);
	}, []);

	const edit = item => {
		props.history.push({
			pathname: `/apontamento/maoObra/editar/${item}`
		});
	};


	const newAppointment = () => {
		props.history.push({
			pathname: `/apontamento/maoDeObra/cadastro`
		});
	};

	const changePage = params => {
		window.scrollTo(0, 0);

		setCurrentPage(params);
		let newFilter = filtro;
		newFilter.page = params;
		setFiltro(newFilter);
		props.getAppointmentManpower(newFilter);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MO",
			filtroListagem: newFilter
		});
	};

	const deleteAppointment = (deleteItem) => {
		props.deleteAppointmentManpower(deleteItem.id);
		setTimeout(function () {
			props.getAppointmentManpower(filtro);
		}, 800);
	};

	const trocaGrupoServico = (servicoTipoId) => {

		let novoFiltro = filtro;
		novoFiltro.servicoId = "";
		novoFiltro.servicoTipoId = servicoTipoId;
		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_MO",
			filtroListagem: novoFiltro
		});
		if (servicoTipoId !== "") {
			getFiltroServicosByGrupoId(servicoTipoId);
		} else {

			let safraId = JSON.parse(localStorage.getItem("safraId")) ? ObjectHelper.primeiraSafra() : "";
			let fazendaId = JSON.parse(localStorage.getItem("safraId")) ? JSON.parse(localStorage.getItem("fazendaId")).value : "";
			getServicosFiltro(fazendaId, safraId);
		}
	};
	const getFiltroServicosByGrupoId = async grupoServico => {
		let { data } = await servicoService.buscarPorGrupoId(
			filtro.fazendaId,
			grupoServico
		);

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	useEffect(() => {
		if (filtro.servicoTipoId) {
			getFiltroServicosByGrupoId(filtro.servicoTipoId)
		}
	}, [])

	const getSafras = async () => {
		let result = null;
		if (JSON.parse(localStorage.getItem('fazendaId'))) {
			result = await SafraService.get(JSON.parse(localStorage.getItem('fazendaId')).value);
			let safras = []
			result.data.result.map((safra) => {
				if (ObjectHelper.multisafra().find(x => x === safra.id)) {
					var obj = {
						label: safra.nome,
						value: safra.id,
						culturaId: safra.cultura ? safra.cultura.id : ""
					}
					safras.push(obj);
				}
				return safras
			})
		} else {
			return;
		}
	}


	return (
		<>
			<Toolbar
				style={{ marginTop: '-20px' }}
				handleFiltro={e => handleFilter(e)}
				filtroInicial={filtro}
				onClickAdd={() => newAppointment()}
				filtroNome
				filtroGrupoLocais={grupoLocaisFiltro}
				filtroLocais={locaisFiltro}
				filtroGruposServico={gruposServicoFiltro}
				filtroServicos={servicosFiltro}
				getFiltroServicosByGrupoId={e => getFiltroServicosByGrupoId(e)}
				filtroPeriodo
				trocaGrupoServico={trocaGrupoServico}
				temFiltroAvancado
				safras={JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2}
				atividades={JSON.parse(localStorage.getItem('safraId')).length >= 2}

			/>
			<CardStyle className="card mt-3">
				<MaterialTable
					title="Apontamentos de Mão de Obra"
					columns={columns}
					data={props.appointments ? props.appointments.items : []}
					onOrderChange={(numeroColuna, orientacao1) =>
						orderTable(numeroColuna, orientacao1)
					}
					options={{
						paging: true,
						pageSize: 10,
						search: false,
						actionsColumnIndex: -1,

					}}
					components={{
						Pagination: (paginat) => {
							const {
								classes,
								headerIsHidden,
								...other
							} = paginat;
							return (
								<>
									<Grid
										item
										container
										xs={12}
										justify="flex-end"
									>
										<MTablePagination
											{...other}
											onChangePage={(event, page) =>
												changePage(page + 1)
											}
											page={currentPage - 1}
											count={props.appointments ? props.appointments.total : 0}

											localization={{
												labelRowsSelect: 'recursos',
												labelDisplayedRows:
													'{from}-{to} de {count}',
											}}
										/>
									</Grid>
								</>
							);
						},
					}}
					actions={actions}
					editable={{
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								deleteAppointment(oldData);
								resolve();
							})
					}}
					localization={{
						header: {
							actions: 'Ações'
						},
						body: {
							emptyDataSourceMessage:
								'Nenhum registro para exibir',
							addTooltip: 'Adicionar',
							deleteTooltip: 'Excluir',
							editTooltip: 'Edição rápida',
							editRow: {
								deleteText: 'Deseja excluir essa linha?',
								cancelTooltip: 'Cancelar',
								saveTooltip: 'Confirmar',
							},
						},

					}}
				/>
			</CardStyle>
		</>
	);
}

const mapStateToProps = state => {
	return {
		appointments: state.appointmentManpower.appointmentManpower
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ ...SearchCreators, ...ListingManpowerCreators },
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListingManpowerAppointment);
