import { createHashHistory } from 'history';
import React from 'react';
import {
	Router, Route, Switch, Redirect,
} from 'react-router-dom';

import Login from '../pages/default/login/login.page';
import RedefinirSenha from '../pages/seguranca/redefinir_senha/redefinir.senha'
import LayoutPerfilContainer from '../pages/default/layout/layoutPerfil.container'
import Dashboard from '../pages/dashboard/dashboard.page';
import ApontamentoMaoDeObraListagem from '../pages/apontamentos/mo/mo.listagem';
import ApontamentoMaoDeObraCadastro from '../pages/apontamentos/mo/mo.cadastro';
import { isAuthenticated } from '../services/auth';
import LayoutContainer from '../pages/default/layout/layout.container';
import LayoutLoginContainer from '../pages/default/layout/layoutLogin.container';
import CategoriaPessoaListagem from '../pages/categoriaPessoa/categoriaPessoa.listagem.page';
import CategoriaPessoaCadastro from '../pages/categoriaPessoa/categoriaPessoa.cadastro.page';
import PessoaListagem from '../pages/pessoa/pessoa.listagem.page';
import PessoaCadastro from '../pages/pessoa/pessoa.cadastro';
import ListMachineAppointment from '../pages/apontamentos/ma/ma.listagem';
import RegisterMachineAppointment from '../pages/apontamentos/ma/ma.cadastro';
import EquipeListagem from '../pages/equipes/equipe.listagem.page';
import EquipeCadastro from '../pages/equipes/equipe.cadastro';
import MaquinaListagem from '../pages/maquinas/maquina.page';
import MaquinaCadastro from '../pages/maquinas/cadastro/maquina.cadastro.page';
import MaquinaVisualizarEditar from '../pages/maquinas/visualizar/maquina.visualizar.editar';
import LocaisListagem from '../pages/locais/local.listagem.page';
import LocaisCadastro from '../pages/locais/local.cadastro';
import usuarioListagem from '../pages/seguranca/usuario/usuario.listagem.page';
import usuarioCadastro from '../pages/seguranca/usuario/usuario.cadastro.page';
import UnidadeListagem from '../pages/units/units.list';
import UnidadeCadastro from '../pages/units/units.register';
import ServicoListagem from '../pages/servico/listagem/servico.listagem';
import ServicoCadastro from '../pages/servico/cadastro/servico.cadastro';
import ParametroLavouraListagem from '../pages/parametros_da_lavoura/parametros.da.lavoura.listagem.page';
import ParametroLavouraCadastro from '../pages/parametros_da_lavoura/parametros.da.lavoura.cadastro';
import TipoMaquinaListagem from "../pages/tipo_maquina/tipo.maquina.listagem";
import TipoMaquinaCadastro from "../pages/tipo_maquina/tipo.maquina.cadastro.page";
import CulturasListagem from '../pages/cultura/cultura.listagem'
import CulturasCadastro from '../pages/cultura/cultura.cadastro'
import GrupoServicosListagem from "../pages/grupo_servicos/grupo.servicos.listagem"
import GrupoServicosCadastro from '../pages/grupo_servicos/grupo.servicos.cadastro'
import PlanejamentoRecursosListagem from '../pages/planejamento_recursos/recursos.listagem.page'
import PlanejamentoRecursosCadastro from '../pages/planejamento_recursos/recursos.cadastro.page'
import MaterialListing from '../pages/materials/material.list'
import MaterialRegister from '../pages/materials/material.register'
import FamiliaMaterialListagem from '../pages/familia_materiais/familia.material.listagem.page'
import FamiliaMaterialCadastro from '../pages/familia_materiais/familia.material.cadastro.page'
import SafraListagem from '../pages/safras/safra.listagem'
import SafraCadastro from '../pages/safras/safra.cadastro'
import TurnoListagem from "../pages/turnos/turno.listagem.page";
import TurnoCadastro from "../pages/turnos/turno.cadastro";
import PlanejamentoTemplateCadastro from '../pages/planejamento_template/template_planejamento.cadastro'
import TemplateListagem from '../pages/planejamento_template/template_planejamento.listagem'
import AppointmentEntryRegister from '../pages/apontamentos/insumos/insumos.cadastro';
import AppointmentEntryList from '../pages/apontamentos/insumos/insumos.listagem';
import PlanejamentoSafra from '../pages/planejamento_safra/planejamento_safra'
import PlanejamentoSafraItens from '../pages/planejamento_safra/planejamento_safra.itens'
import OrdemServico from '../pages/ordem_servico/ordem_servico.cadastro'
import PlanejamentoCalendarioListagem from '../pages/planejamento_calendario/calendario.listagem'
import PlanejamentoCalendarioCadastro from '../pages/planejamento_calendario/calendario.cadastro'
import AfastamentosListagem from '../pages/afastamento/afastamento.listagem'
import AfastamentosCadastro from '../pages/afastamento/afastamento.cadastro'
import InsumoAnaliseSoloListagem from '../pages/insumo_analise_solo/insumo_analise_solo.listagem'
import InsumoAnaliseSoloCadastro from '../pages/insumo_analise_solo/insumo_analise_solo.cadastro'
import PowerBi from '../pages/power_bi/power_bi.page'
import GeradorPlanejamentoSafra from '../pages/gerador_planejamento_safra/gerador'
import HistogramaListagem from '../pages/histograma/histograma.listagem'
import GrupoMaquinasListagem from '../pages/grupo_maquinas/grupo.maquina_listagem'
import GrupoMaquinas from '../pages/grupo_maquinas/grupo_maquina.cadastro'
import Painel from '../pages/painel/painel.listagem'
import painelMOPage from 'pages/painelMO/painelMO.page';
import importacao from 'pages/planejamento_safra/importacao/importacao.';
import { connect } from "react-redux";
import { validatePermission } from "../permissoes/permissionHelper";
import { PERMISSAO } from '../permissoes/permissoes';
import EditarUsuario from '../pages/seguranca/usuario/usuario.editar.page';
import PainelServicos from '../pages/painel_servicos/painel.servicos';
import GrupoLocaisListagem from '../pages/grupo_locais/grupp.locais.listagem';
import GrupoLocaisCadastro from '../pages/grupo_locais/grupo.locais.cadastro';
import FazendaListagem from 'pages/fazendas/fazenda.listagem';
import FazendaCadastro from 'pages/fazendas/fazenda.cadastro';
import RevisaoRecursos from 'pages/planejamento_safra/revisao_recursos/revisao_recursos.page';
import agendamentoCadastro from "pages/agendamento/agendamento.cadastro";
import agendamentoListagem from "pages/agendamento/agendamento.listagem";
import recomendacoesTecnicas from 'pages/recomendacoes_tecnicas/recomendacoes_tecnicas';
import LinhaBase from 'pages/planejamento_safra/linhaBase/linhaBase';
import NotFound from 'pages/default/home/home.page'
import RelatorioIndex from 'pages/relatorios/index'
import Relatorio from 'pages/relatorios/mao_de_obra/relatorio';
import RelatoriosMaMoIndex from 'pages/relatorio_ma_mo/index'
import RelatoriosMa from 'pages/relatorio_ma_mo/relatorio.ma'
import RelatoriosMo from 'pages/relatorio_ma_mo/relatorio.mo'
import AjustesApontamentos from 'pages/ajustes_apontamentos/ajustes_apontamentos';
import VideosPage from 'pages/videos/videos.page'
import ElementoAnaliseSoloListagem from 'pages/elemento_analise_solo/elementoanalisesolo.listagem'
import ElementoAnaliseSoloCadastro from 'pages/elemento_analise_solo/elementoanalisesolo.cadastro'
import ImportacaoAnaliseSolo from 'pages/importacao_analise_solo/importacaoAnaliseSolo'
import ImportacaoAnaliseSoloPage from 'pages/importacao_analise_solo/importacao'
import FormulasAnaliseSolo from 'pages/formulas_analise_solo/formulas_analise_solo'
import ImportacaoAnaliseSoloListagem from 'pages/importacao_analise_solo/importacaoAnaliseSolo.listagem';

export const history = createHashHistory();

let permissoes = []
const setPermissoes = (permiss) => {
	permissoes = permiss
}

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => (isAuthenticated() ? (
			<Component {...props} />
		) : (
				<Redirect
					to={{ pathname: '/login', state: { from: props.location } }}
				/>
			))
		}
	/>
);

const LoginContainer = () => (
	<LayoutLoginContainer>
		<Login />
	</LayoutLoginContainer>
);

const PerfilContainer = () => (
	<LayoutPerfilContainer history={history}>
		<PrivateRoute
			exact
			path='/redefinirSenha/:id'
			component={RedefinirSenha}
		/>
		<PrivateRoute
			exact
			path='/editarUsuario/:id'
			component={EditarUsuario}
		/>
	</LayoutPerfilContainer>
);

const Routes = () => (
	<Router history={history}>
		<Switch>
			<Route exact path='/login' component={LoginContainer} basename isAuthed />
			<Route exact path='/login/:id' component={LoginContainer} basename isAuthed />
			<Route exact path='/redefinirSenha/:id' component={PerfilContainer} basename isAuthed />
			<Route exact path='/editarUsuario/:id' component={PerfilContainer} basename isAuthed />
			<LayoutContainer history={history}>
				<PrivateRoute
					exact
					path='/relatorios'
					component={RelatorioIndex}
				/>


				<PrivateRoute
					exact
					path='/videos'
					component={VideosPage}
				/>
				<PrivateRoute
					exact
					path='/relatorios/:id'
					component={Relatorio}
				/>
				<PrivateRoute
					exact
					path='/planejamento/linhaBase'
					component={LinhaBase}
				/>
				<PrivateRoute
					exact
					path='/planejamento/revisao/recursos'
					component={RevisaoRecursos}
				/>
				<PrivateRoute
					exact
					path='/planejamento/importacao'
					component={importacao}
				/>
				<PrivateRoute
					exact
					path='/painel/maodeobra'
					component={
						validatePermission(PERMISSAO.MODULO_PAINEL_DE_CONTROLE_LEITURA, permissoes) ? painelMOPage : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/painel/servicos'
					component={
						validatePermission(PERMISSAO.MODULO_PAINEL_DE_CONTROLE_LEITURA, permissoes) ? PainelServicos : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/admin/fazendas'
					component={
						validatePermission(PERMISSAO.MODULO_FAZENDAS_CRIAR, permissoes) ? FazendaListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/admin/fazendas/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_FAZENDAS_CRIAR, permissoes) ? FazendaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/admin/fazendas/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_FAZENDAS_CRIAR, permissoes) ? FazendaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/painel'
					component={
						validatePermission(PERMISSAO.MODULO_PAINEL_DE_CONTROLE_LEITURA, permissoes) ? Painel : NotFound
					}

				/>
				<PrivateRoute
					exact
					path='/painel'
					component={
						validatePermission(PERMISSAO.MODULO_PAINEL_DE_CONTROLE_LEITURA, permissoes) ? Painel : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/gerador'
					component={
						validatePermission(PERMISSAO.MODULO_TEMPLATE_PLANEJAMENTO_LEITURA, permissoes) ? GeradorPlanejamentoSafra : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/maquinas/grupo'
					component={
						validatePermission(PERMISSAO.MODULO_GRUPO_MAQUINA_LEITURA, permissoes) ? GrupoMaquinasListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/maquinas/grupo/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_GRUPO_MAQUINA_CRIAR, permissoes) ? GrupoMaquinas : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/maquinas/grupo/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_GRUPO_MAQUINA_ATUALIZAR, permissoes) ? GrupoMaquinas : NotFound
					}
				/>
				<PrivateRoute
					exact
					path="/planejamento/histograma"
					component={
						validatePermission(PERMISSAO.MODULO_PAINEL_DE_CONTROLE_LEITURA, permissoes) ? HistogramaListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path="/planejamento/safra"
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA, permissoes) ? PlanejamentoSafra : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/safra/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR, permissoes) ? PlanejamentoSafraItens : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/safra/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR, permissoes) ? PlanejamentoSafraItens : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/ordemservico/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR, permissoes) ? OrdemServico : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/ordemservico/:id/:source'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR, permissoes) ? OrdemServico : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/dashboard'
					component={PowerBi}
				/>
				<PrivateRoute
					exact
					path='/apontamento/insumos/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_INSUMOS_CRIAR, permissoes) ? AppointmentEntryRegister : NotFound
					}

				/>
				<PrivateRoute
					exact
					path='/apontamento/insumos/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_INSUMOS_LEITURA, permissoes) ? AppointmentEntryList : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/insumos/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_INSUMOS_CRIAR, permissoes) ? AppointmentEntryRegister : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/'
					component={Dashboard}
				/>
				<PrivateRoute
					exact
					path='/planejamento/template/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA, permissoes) ? TemplateListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/template/cadastrar'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA, permissoes) ? PlanejamentoTemplateCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/template/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR, permissoes) ? PlanejamentoTemplateCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA, permissoes) ? ApontamentoMaoDeObraListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/relatorios'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA, permissoes) ? RelatoriosMaMoIndex : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/relatorios/ma'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA, permissoes) ? RelatoriosMa : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/relatorios/mo'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA, permissoes) ? RelatoriosMo : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maoDeObra/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_CRIAR, permissoes) ? ApontamentoMaoDeObraCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maoObra/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA, permissoes) ? ApontamentoMaoDeObraCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maoDeObra/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA, permissoes) ? ApontamentoMaoDeObraCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/categoriaPessoa/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_CATEGORIA_PESSOA_LEITURA, permissoes) ? CategoriaPessoaListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/categoriaPessoa/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_CATEGORIA_PESSOA_ATUALIZAR, permissoes) ? CategoriaPessoaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/categoriaPessoa/cadastro/'
					component={
						validatePermission(PERMISSAO.MODULO_CATEGORIA_PESSOA_CRIAR, permissoes) ? CategoriaPessoaCadastro : NotFound
					}
				/>

				<PrivateRoute
					exact
					path='/pessoa/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_PESSOA_LEITURA, permissoes) ? PessoaListagem : NotFound
					}
				/>

				<PrivateRoute
					exact
					path='/pessoa/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PESSOA_ATUALIZAR, permissoes) ? PessoaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/pessoa/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_PESSOA_CRIAR, permissoes) ? PessoaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maquina'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAQUINA_LEITURA, permissoes) ? ListMachineAppointment : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maquina/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAQUINA_CRIAR, permissoes) ? RegisterMachineAppointment : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maquina/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAQUINA_LEITURA, permissoes) ? RegisterMachineAppointment : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/maquinas/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAQUINA_LEITURA, permissoes) ? RegisterMachineAppointment : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/maquinas/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_MAQUINA_LEITURA, permissoes) ? MaquinaListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/maquinas/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_MAQUINA_CRIAR, permissoes) ? MaquinaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/maquina/visualizar/:fazendaId/:maquinaId/:modo'
					component={
						validatePermission(PERMISSAO.MODULO_MAQUINA_LEITURA, permissoes) ? MaquinaVisualizarEditar : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/equipes/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_EQUIPE_LEITURA, permissoes) ? EquipeListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/equipes/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_EQUIPE_CRIAR, permissoes) ? EquipeCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/equipes/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_EQUIPE_ATUALIZAR, permissoes) ? EquipeCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/local/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_LOCAL_LEITURA, permissoes) ? LocaisListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/local/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_LOCAL_CRIAR, permissoes) ? LocaisCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/local/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_LOCAL_CRIAR, permissoes) ? LocaisCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/locais/grupo'
					component={
						validatePermission(PERMISSAO.MODULO_LOCAL_LEITURA, permissoes) ? GrupoLocaisListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/locais/grupo/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_LOCAL_CRIAR, permissoes) ? GrupoLocaisCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/locais/grupo/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_LOCAL_ATUALIZAR, permissoes) ? GrupoLocaisCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/servico'
					component={
						validatePermission(PERMISSAO.MODULO_SERVICO_LEITURA, permissoes) ? ServicoListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/servico/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_SERVICO_CRIAR, permissoes) ? ServicoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/servico/editar/:id'
					component={
						validatePermission(PERMISSAO.MODULO_SERVICO_ATUALIZAR, permissoes) ? ServicoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/parametroLavoura/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_PARAMETRO_LAVOURA_LEITURA, permissoes) ? ParametroLavouraListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/parametroLavoura/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_PARAMETRO_LAVOURA_CRIAR, permissoes) ? ParametroLavouraCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/parametroLavoura/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PARAMETRO_LAVOURA_ATUALIZAR, permissoes) ? ParametroLavouraCadastro : NotFound
					}
				/>
				<PrivateRoute
					path='/servico/visualizar/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PARAMETRO_LAVOURA_LEITURA, permissoes) ? ServicoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/unidades/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_UNIDADE_LEITURA, permissoes) ? UnidadeListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/unidades/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_UNIDADE_CRIAR, permissoes) ? UnidadeCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/unidades/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_UNIDADE_ATUALIZAR, permissoes) ? UnidadeCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/tipo/maquina/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_TIPO_MAQUINA_LEITURA, permissoes) ? TipoMaquinaListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/tipo/maquina/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_TIPO_MAQUINA_CRIAR, permissoes) ? TipoMaquinaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/tipo/maquina/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_TIPO_MAQUINA_ATUALIZAR, permissoes) ? TipoMaquinaCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/culturas/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_LEITURA, permissoes) ? CulturasListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/culturas/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_CRIAR, permissoes) ? CulturasCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/culturas/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_ATUALIZAR, permissoes) ? CulturasCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/analiseSolo/elementos'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_LEITURA, permissoes) ? ElementoAnaliseSoloListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/analiseSolo/configuracao'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_CRIAR, permissoes) ? ImportacaoAnaliseSolo : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/analiseSolo'
					component={ImportacaoAnaliseSoloListagem}
				/>
				<PrivateRoute
					exact
					path='/analiseSolo/importacao'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_CRIAR, permissoes) ? ImportacaoAnaliseSoloPage : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/analiseSolo/elementos/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_CRIAR, permissoes) ? ElementoAnaliseSoloCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/analiseSolo/elementos/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_CULTURA_ATUALIZAR, permissoes) ? ElementoAnaliseSoloCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/gruposervicos/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_GRUPO_DE_SERVICOS_LEITURA, permissoes) ? GrupoServicosListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/gruposervicos/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_GRUPO_DE_SERVICOS_CRIAR, permissoes) ? GrupoServicosCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/gruposervicos/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_GRUPO_DE_SERVICOS_ATUALIZAR, permissoes) ? GrupoServicosCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/recursos/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_RECURSOS_LEITURA, permissoes) ? PlanejamentoRecursosListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/recursos/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_RECURSOS_ATUALIZAR, permissoes) ? PlanejamentoRecursosCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/recursos/editar/:id'
					component={
						validatePermission(PERMISSAO.MODULO_RECURSOS_CRIAR, permissoes) ? PlanejamentoRecursosCadastro : NotFound
					}
				/>
				<PrivateRoute
					path='/planejamento/recursos/visualizar/:id'
					component={
						validatePermission(PERMISSAO.MODULO_RECURSOS_LEITURA, permissoes) ? PlanejamentoRecursosCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/familiaMateriais/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_LEITURA, permissoes) ? FamiliaMaterialListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/familiaMateriais/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_CRIAR, permissoes) ? FamiliaMaterialCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/familiaMateriais/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_ATUALIZAR, permissoes) ? FamiliaMaterialCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/materiais/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_LEITURA, permissoes) ? MaterialListing : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/materiais/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_CRIAR, permissoes) ? MaterialRegister : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/materiais/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_ATUALIZAR, permissoes) ? MaterialRegister : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/materiais/:modo/:id/:source'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_ATUALIZAR, permissoes) ? MaterialRegister : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/usuario/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_USUARIO_LEITURA, permissoes) ? usuarioListagem : NotFound
					}
				/>
				<PrivateRoute
					path='/usuario/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_USUARIO_CRIAR, permissoes) ? usuarioCadastro : NotFound
					}
				/>
				<PrivateRoute
					path='/usuario/editar/:id'
					component={
						validatePermission(PERMISSAO.MODULO_USUARIO_CRIAR, permissoes) ? usuarioCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/safra'
					component={
						validatePermission(PERMISSAO.MODULO_SAFRA_LEITURA, permissoes) ? SafraListagem : NotFound
					}
				/>
				<PrivateRoute
					path='/safra/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_SAFRA_CRIAR, permissoes) ? SafraCadastro : NotFound
					}
				/>
				<PrivateRoute
					path='/safra/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_SAFRA_ATUALIZAR, permissoes) ? SafraCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/turnos/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_TURNO_LEITURA, permissoes) ? TurnoListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/turnos/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_TURNO_CRIAR, permissoes) ? TurnoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/turnos/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_TURNO_ATUALIZAR, permissoes) ? TurnoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/calendario'
					component={
						validatePermission(PERMISSAO.MODULO_CALENDARIO_LEITURA, permissoes) ? PlanejamentoCalendarioListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/calendario/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_CALENDARIO_CRIAR, permissoes) ? PlanejamentoCalendarioCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/calendario/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_CALENDARIO_CRIAR, permissoes) ? PlanejamentoCalendarioCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/afastamentos/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_AFASTAMENTOS_LEITURA, permissoes) ? AfastamentosListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/afastamentos/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_AFASTAMENTOS_CRIAR, permissoes) ? AfastamentosCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/afastamentos/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_AFASTAMENTOS_ATUALIZAR, permissoes) ? AfastamentosCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/insumosAnaliseSolo/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_LEITURA, permissoes) ? InsumoAnaliseSoloListagem : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/insumosAnaliseSolo/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_CRIAR, permissoes) ? InsumoAnaliseSoloCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/insumosAnaliseSolo/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_ATUALIZAR, permissoes) ? InsumoAnaliseSoloCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/agendamento/listagem'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA, permissoes) ? agendamentoListagem : NotFound

					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/agendamento/cadastro'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR, permissoes) ? agendamentoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/agendamento/:modo/:id'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR, permissoes) ? agendamentoCadastro : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/planejamento/ajustesemrecursos'
					component={
						validatePermission(PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR, permissoes) ? recomendacoesTecnicas : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/apontamento/AjustesApontamentos'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_CRIAR, permissoes) ? AjustesApontamentos : NotFound
					}
				/>
				<PrivateRoute
					exact
					path='/formulasAnaliseSolo'
					component={
						validatePermission(PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_CRIAR, permissoes) ? FormulasAnaliseSolo : NotFound
					}
				/>
			</LayoutContainer>
		</Switch>
	</Router>
);

const mapStateToProps = state => {
	setPermissoes(state.permissao.permissoes)
	return ({
		permissoes: state.permissao.permissoes
	});
}


export default connect(mapStateToProps)(Routes);
