import api from "./api";

export default {
	get(fazendaId) {
		return api.get(`/api/Safra/${fazendaId}`);
	},
	getGrupoSafraPorFazendaId(fazendaId) {
		return api.get(`/api/Safra/getGrupoSafraPorFazendaId/${fazendaId}`);
	},
	getSafraPorGruposSafra(params) {
		return api.post('/api/Safra/GetSafraPorGruposSafra', params)
	},
	salvar(param) {
		return api.post("/api/Safra", param);
	},
	duplicarSafra(id) {
		return api.get(`/api/Safra/DuplicarSafra/${id}`);
	},
	deletarLocal(id, safraId) {
		return api.delete("/api/Safra/DeletarLocal/" + id + "/" + safraId);
	},
	editar(param) {
		return api.put("/api/Safra", param);
	},
	buscarPorId(id) {
		return api.get(`/api/Safra/Get/${id}`);
	},
	buscarTodas(param) {
		return api.post("/api/Safra/BuscarTodas", param);
	},
	deletar(id) {
		return api.delete("/api/Safra/" + id);
	},
	locais(fazendaId, safraId, administrativo) {
		if (administrativo) {
			return api.get(
				`/api/Safra/GetLocaisSafraComAdministrativo/${fazendaId}/${safraId}`
			);
		}

		return api.get(`/api/Safra/GetLocaisSafra/${fazendaId}/${safraId}`);
	},
	ativaDesativa(safraId) {
		return api.get(`/api/Safra/AtivarDesativar/${safraId}`);
	},
	cadastrarLocalSafra(param) {
		return api.post(`/api/Safra/CadastrarLocalSafra`, param);
	},
	buscarLocalSafraPorId(id) {
		return api.get(`/api/Safra/BuscarLocalSafraPorId/${id}`);
	}
};
