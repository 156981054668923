import Modal from '../../../node_modules/react-bootstrap/es/Modal'
import Button from '../../../node_modules/react-bootstrap/es/Button'
import ButtonGroup from '../../../node_modules/react-bootstrap/es/ButtonGroup'
import PlanejamentoSafraService from '../../services/planejamentoSafraService'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import React, { useState, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import ToastHelper from './../../utils/toastHelper';
import './modal.progresso.scss'
import ApontamentoService from 'services/apontamentoColaboradorService';
import ModalHelper from 'utils/modalHelper';

export default function ModalProgresso(props) {

	const { show, nomeServico, nomeLocal, close, idPlanejamento, ativo } = props;
	const [
		showModal,
		setShowModal
	] = useState(false);

	const [atualProgresso, setAtualProgresso] = useState(props.progresso);

	useEffect(() => {
		setAtualProgresso(props.progresso);
	}, [props])

	const verificarApontamentos = async () => {
		const { data } = await ApontamentoService.findAll({
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			safraId: ObjectHelper.primeiraSafra(),
			localId: props.localId,
			servicoId: props.servicoId,
			servicoTipoId: props.servicoTipoId,
			page: 0,
			pageSize: 100
		});

		if (data.result.items.length === 0) {
			setShowModal(true);
			return true;
		} else {
			setAtualProgresso(100);
			alterarProgresso(true, 100);
		}
	}


	const alterarProgresso = async (alterar, progressoParam) => {
		var progresso = 0;
		if (atualProgresso === 100 && alterar === undefined) return verificarApontamentos();
		progressoParam !== undefined ? progresso = progressoParam : progresso = atualProgresso;

		setShowModal(false);
		close();

		let params = {
			progresso: progresso,
			id: idPlanejamento,
		}
		let { data } = await PlanejamentoSafraService.atualizarProgresso(params);

		if (data.isOk)
			ToastHelper.success("Progresso atualizado com sucesso!");
		else
			ToastHelper.error(data.mensagem);
		setTimeout(function () {
			props.close();
			props.atualizarLista()
		}, 800)
	}

	return (
		<>
			<Modal show={show} onHide={() => { close(); setAtualProgresso(0) }}>
				<Modal.Header closeButton >
					<Modal.Title>{"Alterar progresso de " + nomeServico + " em " + nomeLocal + "."}</Modal.Title>
				</Modal.Header>
				<div className="col-md-12 mt-2 text-center">
					<h6>Qual o nivel de progresso da atividade?</h6>
				</div>
				<div className="col-md-12 mt-2 text-center">
					<ButtonGroup >
						<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(0)}>0%</Button>
						<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(20)}>20%</Button>
						<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(80)}>80%</Button>
						<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(100)}>100%</Button>
					</ButtonGroup>
				</div>
				<div className="col-md-12 text-center">
					<div className="col-md-12  mt-3">
						<Progress percent={atualProgresso} status={ativo ? "" : "error"} />
					</div>
				</div>
				<Modal.Footer className="footer-modal mt-3">
					<Button bsStyle="primary" className="btn active mr-4 wd-30  modal-2-btn btn-padrao-rehagro-cancelar" onClick={() => {
						setAtualProgresso(0)
						close()
					}}>
						Cancelar
                </Button>
					<Button bsStyle="primary" className="btn active wd-30  modal-2-btn btn-padrao-rehagro" onClick={() =>
						alterarProgresso()
					}>
						Salvar
                </Button>
				</Modal.Footer>
			</Modal>
			<ModalHelper
				dialogClassName="modal-dialog-progresso"
				show={showModal}
				doubleButtons={true}
				title={"Não existem apontamentos lançados para essa tarefa!"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => setShowModal(false)}
				submit={() => { alterarProgresso(true, 100); close(); }}
				close={() => { setShowModal(false); close(); }}
				content={
					<>
						<div style={{ height: 125 }}>
							{"Tem certeza que quer atualizar o progresso da tarefa para 100% e concluir a tarefa? Se a tarefa for concluída, não será possível lançar apontamentos para essa tarefa."}
						</div>
					</>
				}
			/>
		</>
	)
}
