const linguagem = JSON.parse(localStorage.getItem('linguagem'));
export default class Traducoes {
    
    // loginpage.js------------------------------
    static erroMessageLogin(){
        switch(linguagem){
            case "BR":
                return 'Houve um problema com o login, verifique suas credenciais.';
            case "US":
                return 'There was a problem with login, please check your credentials.'
            case "ES":
                return 'Hubo un problema con el inicio de sesión, verifique sus credenciales.'
            default:
                return 'Houve um problema com o login, verifique suas credenciais.'
        }
    }

    static email(){
        switch(linguagem){
            case "BR":
                return 'Email';
            case "US":
                return 'Email'
            case "ES":
                return 'Correo Electronico'
            default:
                return 'Email'
        }
    }

    static inserirEmail(){
        switch(linguagem){
            case "BR":
                return 'Insira seu e-mail';
            case "US":
                return 'Enter your email'
            case "ES":
                return 'Ingrese su correo electrónico'
            default:
                return 'Insira seu e-mail'
        }
    }

    static senha(){
        switch(linguagem){
            case "BR":
                return 'Senha';
            case "US":
                return 'Password'
            case "ES":
                return 'Contraseña'
            default:
                return 'Senha'
        }
    }

    static inserirSenha(){
        switch(linguagem){
            case "BR":
                return 'Insira sua senha';
            case "US":
                return 'Enter your password'
            case "ES":
                return 'Ingrese su contraseña'
            default:
                return 'Insira sua senha'
        }
    }

    static erroAoTentarSalvarApontamento(){
        switch(linguagem){
            case "BR":
                return 'Erro ao tentar salvar seu apontameto';
            case "US":
                return 'Error trying to save your link'
            case "ES":
                return 'Error al intentar guardar tu enlace'
            default:
                return 'Erro ao tentar salvar seu apontameto'
        }
    }

    static entrar(){
        switch(linguagem){
            case "BR":
                return 'ENTRAR';
            case "US":
                return 'ENTER'
            case "ES":
                return 'Iniciar sesión'
            default:
                return 'ENTRAR'
        }
    }

    // menu.js------------------------------
    static menu(){
        switch(linguagem){
            case "BR":
                return 'Menu';
            case "US":
                return 'Menu'
            case "ES":
                return 'Menu'
            default:
                return 'Menu'
        }
    }

    static PainelControle(){
        switch(linguagem){
            case "BR":
                return 'Painel de Controle';
            case "US":
                return 'Dashboard'
            case "ES":
                return 'Panel de control'
            default:
                return 'Painel de Controle'
        }
    }

    static apontamento(){
        switch(linguagem){
            case "BR":
                return 'Apontamento';
            case "US":
                return 'Note'
            case "ES":
                return 'Nota'
            default:
                return 'Apontamento'
        }
    }
    
    static apontamentoMaodeObra(){
        switch(linguagem){
            case "BR":
                return 'Apontamento de Mão de Obra';
            case "US":
                return 'Pointing of Manpower'
            case "ES":
                return 'Trabajo Señalando'
            default:
                return 'Apontamento de Mão de Obra'
        }
    }

    static apontamentoMaquina(){
        switch(linguagem){
            case "BR":
                return 'Apontamento de Máquina';
            case "US":
                return 'Machine Pointing'
            case "ES":
                return 'Máquina Apuntando'
            default:
                return 'Apontamento de Máquina'
        }
    }

    static pessoas(){
        switch(linguagem){
            case "BR":
                return 'Pessoas';
            case "US":
                return 'People'
            case "ES":
                return 'La Gente'
            default:
                return 'Pessoas'
        }
    }

    static usuarios(){
        switch(linguagem){
            case "BR":
                return 'Usuários';
            case "US":
                return 'Users'
            case "ES":
                return 'Los usuarios'
            default:
                return 'Usuários'
        }
    }

    static categorias(){
        switch(linguagem){
            case "BR":
                return 'Categoria de Pessoa';
            case "US":
                return 'People Category'
            case "ES":
                return 'Categorías de Personas'
            default:
                return 'Categoria de Pessoa'
        }
    }
    
    static sair(){
        switch(linguagem){
            case "BR":
                return 'Sair';
            case "US":
                return 'Exit'
            case "ES":
                return 'Dejar'
            default:
                return 'Sair'
        }
    }
    
    // apontamento_Colaborador.page.js------------------------------
    // apontamento_maquina.page.js----------------------------------

    static inicio(){
        switch(linguagem){
            case "BR":
                return 'Início';
            case "US":
                return 'Opening'
            case "ES":
                return 'Comienzo'
            default:
                return 'Início'
        }
    }
    
    static fim(){
        switch(linguagem){
            case "BR":
                return 'Fim';
            case "US":
                return 'End'
            case "ES":
                return 'Fin'
            default:
                return 'Fim'
        }
    }

    static dadosPesquisa(){
        switch(linguagem){
            case "BR":
                return 'Dados da Pesquisa';
            case "US":
                return 'Survey Data'
            case "ES":
                return 'Datos de encuesta'
            default:
                return 'Dados da Pesquisa'
        }
    }

    static selecioneData(){
        switch(linguagem){
            case "BR":
                return 'Selecione uma data';
            case "US":
                return 'Select a date'
            case "ES":
                return 'Selecciona una fecha'
            default:
                return 'Selecione uma data'
        }
    }

    static equipe(){
        switch(linguagem){
            case "BR":
                return 'Equipe';
            case "US":
                return 'Team'
            case "ES":
                return 'Personal'
            default:
                return 'Equipe'
        }
    }


    static maquina(){
        switch(linguagem){
            case "BR":
                return 'Máquina';
            case "US":
                return 'Machine'
            case "ES":
                return 'Máquina'
            default:
                return 'Máquina'
        }
    }

    static novoApontamento(){
        switch(linguagem){
            case "BR":
                return 'Novo Apontamento';
            case "US":
                return 'New Note'
            case "ES":
                return 'Nueva Nota'
            default:
                return 'Novo Apontamento'
        }
    }


    static novaMaquina(linguagem){
        if(linguagem === 'BR'){
            return 'Nova Máquina'
        }else if(linguagem === 'US'){
            return 'New Machine'
        }
    }

    static tipo(linguagem){
        if(linguagem === 'BR'){
            return 'Tipo'
        }else if(linguagem === 'US'){
            return 'Type'
        }
    }

    static mensagemExclusaoMaquina(linguagem) {
        if(linguagem === 'BR'){
            return 'Deseja realmente excluir esta máquina?'
        }else if(linguagem === 'US'){
            return 'Do you really want to delete this machine?'
        }else return 'Deseja realmente excluir esta máquina?'
    }

    static mensagemExclusaolocal(linguagem) {
        if(linguagem === 'BR'){
            return 'Deseja realmente excluir este local?'
        }else if(linguagem === 'US'){
            return 'Do you really want to delete this location?'
        }else return 'Deseja realmente excluir este local?'
    }

    static excluirMaquina(linguagem) {
        if(linguagem === 'BR'){
            return 'Excluir Máquina'
        }else if(linguagem === 'US'){
            return 'Delete Machine'
        }else return 'Eliminar máquina'
    }

    static excluir(linguagem) {
        if(linguagem === 'BR'){
            return 'Excluir'
        }else if(linguagem === 'US'){
            return 'Delete'
        }else return 'Eliminar'
    }
    
    static excluirApontamento(){
        switch(linguagem){
            case "BR":
                return 'Excluir Apontamento';
            case "US":
                return 'Delete Note'
            case "ES":
                return 'Eliminar Nota'
            default:
                return 'Excluir Apontamento'
        }
    }

    static confirmar(){
        switch(linguagem){
            case "BR":
                return 'Confirmar';
            case "US":
                return 'Confirm'
            case "ES":
                return 'Confirmar'
            default:
                return 'Confirmar'
        }
    }

    static cancelar(){
        switch(linguagem){
            case "BR":
                return 'Cancelar';
            case "US":
                return 'Cancel'
            case "ES":
                return 'Cancelar'
            default:
                return 'Cancelar'
        }
    }

    static desejaExcluirApontamento(){
        switch(linguagem){
            case "BR":
                return 'Deseja realmente excluir este apontamento?';
            case "US":
                return 'Do you really want to delete this note'
            case "ES":
                return 'Realmente quieres eliminar esta nota'
            default:
                return 'Deseja realmente excluir este apontamento?'
        }
    }

    // apontamento_colaborador.formulario.js------------------------------

    static apontamentoAddSucesso(){
        switch(linguagem){
            case "BR":
                return 'Apontamento adicionado com sucesso!';
            case "US":
                return 'Note added successfully!'
            case "ES":
                return 'Nota añadida con éxito!'
            default:
                return 'Apontamento adicionado com sucesso!'
        }
    }

    static cliquePesquisarMembrosEquipe(){
        switch(linguagem){
            case "BR":
                return 'Clique no icone para pesquisar os membros da equipe';
            case "US":
                return 'Click icon to search team members'
            case "ES":
                return 'Haga clic en el icono para buscar miembros del equipo'
            default:
                return 'Clique no icone para pesquisar os membros da equipe'
        }
    }

    static cliquePesquisarGrupoServico(){
        switch(linguagem){
            case "BR":
                return 'Clique no icone para pesquisar o grupo de serviços';
            case "US":
                return 'Click the icon to search for the service group'
            case "ES":
                return 'Haga clic en el icono para buscar el grupo de servicio'
            default:
                return 'Clique no icone para pesquisar o grupo de serviços'
        }
    }

    static dadosGerais(){
        switch(linguagem){
            case "BR":
                return 'Dados Gerais';
            case "US":
                return 'General Data'
            case "ES":
                return 'Datos Generales'
            default:
                return 'Dados Gerais'
        }
    }

    static dadosApontamento(){
        switch(linguagem){
            case "BR":
                return 'Dados do Apontamento';
            case "US":
                return 'Pointing Data'
            case "ES":
                return 'Señalando datos'
            default:
                return 'Dados do Apontamento'
        }
    }

    static servico(){
        switch(linguagem){
            case "BR":
                return 'Serviço';
            case "US":
                return 'Service'
            case "ES":
                return 'Servicio'
            default:
                return 'Serviço'
        }
    }
    
    static perda(){
        switch(linguagem){
            case "BR":
                return 'Perda';
            case "US":
                return 'Loss'
            case "ES":
                return 'Pérdida'
            default:
                return 'Perda'
        }
    }

    static selecioneMembro(){
        switch(linguagem){
            case "BR":
                return 'Selecione um membro';
            case "US":
                return 'Select a member'
            case "ES":
                return 'Seleccione un miembro'
            default:
                return 'Selecione um membro'
        }
    }

    static selecioneServico(){
        switch(linguagem){
            case "BR":
                return 'Selecione um serviço';
            case "US":
                return 'Select a service'
            case "ES":
                return 'Seleccione un servicio'
            default:
                return 'Selecione um serviço'
        }
    }

    static selecioneGrupoServico(){
        switch(linguagem){
            case "BR":
                return 'Selecione um grupo de serviço';
            case "US":
                return 'Select a service group'
            case "ES":
                return 'Seleccione un grupo de servicio'
            default:
                return 'Selecione um grupo de serviço'
        }
    }


    static selecioneSupervisor(){
        switch(linguagem){
            case "BR":
                return 'Selecione um supervisor';
            case "US":
                return 'Select a supervisor'
            case "ES":
                return 'Seleccione un supervisor'
            default:
                return 'Selecione um supervisor'
        }
    }

    static servicos(){
        switch(linguagem){
            case "BR":
                return 'Serviços';
            case "US":
                return 'Services'
            case "ES":
                return 'Servicios'
            default:
                return 'Serviços'
        }
    }

    static grupoServicos(){
        switch(linguagem){
            case "BR":
                return 'Grupo de Serviços';
            case "US":
                return 'Service Group'
            case "ES":
                return 'Grupo de servicio'
            default:
                return 'Grupo de Serviços'
        }
    }

    static selecioneTurno(){
        switch(linguagem){
            case "BR":
                return 'Selecione um turno';
            case "US":
                return 'Select a rotation'
            case "ES":
                return 'Seleccione un turno'
            default:
                return 'Selecione um turno'
        }
    }

    static turno(){
        switch(linguagem){
            case "BR":
                return 'Turno';
            case "US":
                return 'Rotation'
            case "ES":
                return 'Turno'
            default:
                return 'Turno'
        }
    }
    
    static selecioneLocalDeTrabalho(){
        switch(linguagem){
            case "BR":
                return 'Selecione o local do trabalho';
            case "US":
                return 'Select job location'
            case "ES":
                return 'Seleccionar lugar de trabajo'
            default:
                return 'Selecione o local do trabalho'
        }
    }

    static local(){
        switch(linguagem){
            case "BR":
                return 'Local';
            case "US":
                return 'place'
            case "ES":
                return 'ubicación'
            default:
                return 'Local'
        }
    }

    static addHoras(){
        switch(linguagem){
            case "BR":
                return 'Adicione as horas';
            case "US":
                return 'Add the hours'
            case "ES":
                return 'Agrega las horas'
            default:
                return 'Adicione as horas'
        }
    }

    static membrosEquipe(){
        switch(linguagem){
            case "BR":
                return 'Membros da equipe';
            case "US":
                return 'Team Members'
            case "ES":
                return 'Miembros del equipo'
            default:
                return 'Membros da equipe'
        }
    }

    static observacao(){
        switch(linguagem){
            case "BR":
                return 'Observação';
            case "US":
                return 'Note'
            case "ES":
                return 'Nota'
            default:
                return 'Observação'
        }
    }

    static pronto(){
        switch(linguagem){
            case "BR":
                return 'PRONTO';
            case "US":
                return 'DONE'
            case "ES":
                return 'LISTO'
            default:
                return 'PRONTO'
        }
    }    
    
    static localDeTrabalho(){
        switch(linguagem){
            case "BR":
                return 'Local do trabalho';
            case "US":
                return 'Job location'
            case "ES":
                return 'Lugar de trabajo'
            default:
                return 'Local do trabalho'
        }
    }

    static horas(){
        switch(linguagem){
            case "BR":
                return 'Horas';
            case "US":
                return 'Hours'
            case "ES":
                return 'Horas'
            default:
                return 'Horas'
        }
    }

    static add(){
        switch(linguagem){
            case "BR":
                return 'Adicionar';
            case "US":
                return 'Add'
            case "ES":
                return 'Agregar'
            default:
                return 'Adicionar'
        }
    }
    
    // apontamento_colaborador.cadastro.page.js------------------------------

    static voltar(){
        switch(linguagem){
            case "BR":
                return 'Voltar';
            case "US":
                return 'Return'
            case "ES":
                return 'Volver'
            default:
                return 'Voltar'
        }
    }

    // modalMembros.js------------------------------

    static nomeFuncionario(){
        switch(linguagem){
            case "BR":
                return 'Nome do Funcionário';
            case "US":
                return 'Employee Name'
            case "ES":
                return 'Nombre del empleado'
            default:
                return 'Nome do Funcionário'
        }
    }
    
    // tables------------------------------
    
    static nenhumItemEncontrado(){
        switch(linguagem){
            case "BR":
                return 'Nenhum item encontrado.';
            case "US":
                return 'No items found.'
            case "ES":
                return 'No se encontraron artículos.'
            default:
                return 'Nenhum item encontrado.'
        }
    }
    
    // apontamento.maquina.formulario.js------------------------------

    static selecioneDataMaquina(){
        switch(linguagem){
            case "BR":
                return 'Selecione uma data e uma máquina para adicionar apontamentos';
            case "US":
                return 'Select a date and machine to add notes'
            case "ES":
                return 'No se encontraron artículos.'
            default:
                return 'Selecione uma data e uma máquina para adicionar apontamentos'
        }
    }

    static selecioneOperador(){
        switch(linguagem){
            case "BR":
                return 'Selecione o operador';
            case "US":
                return 'Select operator'
            case "ES":
                return 'Seleccionar operador'
            default:
                return 'Selecione o operador'
        }
    }
    
    static erroHorimetroInicialFinal(){
        switch(linguagem){
            case "BR":
                return 'O Horímetro de inicio não pode ser maior que o horímetro final.';
            case "US":
                return 'The hourmeter cannot be greater than End Hour.'
            case "ES":
                return 'La hora de inicio no puede ser mayor que la hora de finalización.'
            default:
                return 'O Horímetro de inicio não pode ser maior que o horímetro final.'
        }
    }
    
    static erroHorimetroInicialObrigatorio(){
        switch(linguagem){
            case "BR":
                return 'O Horímetro inicial é obrigatório.';
            case "US":
                return 'The start hourmeter is required.'
            case "ES":
                return 'Se requiere ohrimeter de inicio.'
            default:
                return 'O Horímetro inicial é obrigatório.'
        }
    }
    
    static erroHorimetroFinalObrigatorio(){
        switch(linguagem){
            case "BR":
                return 'O Horímetro inicial é obrigatório.';
            case "US":
                return 'The end hourmeter is required.'
            case "ES":
                return 'Se requiere hora de finalización.'
            default:
                return 'O Horímetro inicial é obrigatório.'
        }
    }
    
    static erroTotalHorasMaquinaObrigatorio(){
        switch(linguagem){
            case "BR":
                return 'O total de horas trabalhadas pela máquina é obrigatório.';
            case "US":
                return 'The total hours worked by the machine is mandatory.'
            case "ES":
                return 'El total de horas trabajadas por la máquina es obligatorio.'
            default:
                return 'O total de horas trabalhadas pela máquina é obrigatório.'
        }
    }
    
    static erroTotalHorasHomemObrigatorio(){
        switch(linguagem){
            case "BR":
                return 'O total de horas trabalhadas pelo homem é obrigatório.';
            case "US":
                return 'The total hours worked by man is mandatory.'
            case "ES":
                return 'El total de horas trabajadas por el hombre es obligatorio.'
            default:
                return 'O total de horas trabalhadas pelo homem é obrigatório.'
        }
    }

    static horimetroMaquina(){
        switch(linguagem){
            case "BR":
                return 'Horímetro máquina';
            case "US":
                return 'The machine hourmeter'
            case "ES":
                return 'El ohrimeter de la máquina'
            default:
                return 'Horímetro máquina'
        }
    }
    
    static operadores(){
        switch(linguagem){
            case "BR":
                return 'Operadores';
            case "US":
                return 'Operators'
            case "ES":
                return 'Operadores'
            default:
                return 'Operadores'
        }
    }
    
    static horimetroDesabilitado(){
        switch(linguagem){
            case "BR":
                return 'Desabilitado, máquina com horímetro parado.';
            case "US":
                return 'Disabled, machine with stopped hourmeter.'
            case "ES":
                return 'Desactivado, máquina con ohrimeter parado.'
            default:
                return 'Desabilitado, máquina com horímetro parado.'
        }
    }

    static inserirHorasNoFim(){
        switch(linguagem){
            case "BR":
                return 'Insira as horas do horímetro ao fim do serviço.';
            case "US":
                return 'Enter the hourmeter hours at the end of the service.'
            case "ES":
                return 'Ingrese las horas del medidor de horas al final del servicio.'
            default:
                return 'Insira as horas do horímetro ao fim do serviço.'
        }
    }

    static horasPorMaquina(){
        switch(linguagem){
            case "BR":
                return 'Horas/Máquina';
            case "US":
                return 'Hours/Machine'
            case "ES":
                return 'Horas/Maquina'
            default:
                return 'Horas/Máquina'
        }
    }
    
    static total(){
        switch(linguagem){
            case "BR":
                return 'Total';
            case "US":
                return 'Total'
            case "ES":
                return 'Total'
            default:
                return 'Total'
        }
    }
    
    static horasPorHomem(){
        switch(linguagem){
            case "BR":
                return 'Horas/Homem';
            case "US":
                return 'Hours/Man'
            case "ES":
                return 'Horas/Hombre'
            default:
                return 'Horas/Homem'
        }
    }

    // pessoa.listagem.page.js------------------------------

    static listaPessoas(){
        switch(linguagem){
            case "BR":
                return 'Lista de Pessoas';
            case "US":
                return 'List of People'
            case "ES":
                return 'Lista de Personas'
            default:
                return 'Lista de Pessoas'
        }
    }

    static pesquisar(){
        switch(linguagem){
            case "BR":
                return 'Pesquisar...';
            case "US":
                return 'Search...'
            case "ES":
                return 'Buscar...'
            default:
                return 'Pesquisar...'
        }
    }

    static novaPessoa(){
        switch(linguagem){
            case "BR":
                return 'NOVA PESSOA';
            case "US":
                return 'NEW PERSON'
            case "ES":
                return 'NUEVA PERSONA'
            default:
                return 'NOVA PESSOA'
        }
    }

    static nome(){
        switch(linguagem){
            case "BR":
                return 'Nome';
            case "US":
                return 'Name'
            case "ES":
                return 'Nombre'
            default:
                return 'Nome'
        }
    }

    static status(){
        switch(linguagem){
            case "BR":
                return 'Status';
            case "US":
                return 'Status'
            case "ES":
                return 'Estado'
            default:
                return 'Status'
        }
    }

    static cadastrosGerais(linguagem){
        if(linguagem === 'BR'){
            return 'Cadastros Gerais'
        }else if(linguagem === 'US'){
            return 'General Records'
        }else return 'Registros generales'
    }

    static seguranca(linguagem){
        if(linguagem === 'BR'){
            return 'Segurança'
        }else if(linguagem === 'US'){
            return 'Security'
        }else return 'Segurança'
    }

    static ativo(linguagem){
        if(linguagem === 'BR'){
            return 'Ativo'
        }else if(linguagem === 'US'){
            return 'Active'
        }else return 'Ativo'
    }

    static equipes(linguagem){
        if(linguagem === 'BR'){
            return 'Equipes'
        }else if(linguagem === 'US'){
            return 'Teams'
        }else return 'Equipos'
    }
    
    static inativo(){
        switch(linguagem){
            case "BR":
                return 'Inativo';
            case "US":
                return 'Inactive'
            case "ES":
                return 'Inativo'
            default:
                return 'Inativo'
        }
    }

    static excluirPessoa(){
        switch(linguagem){
            case "BR":
                return 'Excluir Pessoa';
            case "US":
                return 'Delete Person'
            case "ES":
                return 'Eliminar Persona'
            default:
                return 'Excluir Pessoa'
        }
    }

    static desejaExcluirPessoa(){
        switch(linguagem){
            case "BR":
                return 'Deseja realmente excluir esta pessoa?';
            case "US":
                return 'Do you really want to delete this person'
            case "ES":
                return 'Realmente quieres eliminar esta persona?'
            default:
                return 'Deseja realmente excluir esta pessoa?'
        }
    }

    static visualizar(){
        switch(linguagem){
            case "BR":
                return 'Visualizar';
            case "US":
                return 'View'
            case "ES":
                return 'Vista Previa'
            default:
                return 'Visualizar'
        }
    }

    static editar(){
        switch(linguagem){
            case "BR":
                return 'Editar';
            case "US":
                return 'Edit'
            case "ES":
                return 'Editar'
            default:
                return 'Editar'
        }
    }

    static apagar(){
        switch(linguagem){
            case "BR":
                return 'Apagar';
            case "US":
                return 'Delete'
            case "ES":
                return 'Eliminar'
            default:
                return 'Apagar'
        }
    }

    // pessoa.cadastro.page.js------------------------------

    static addNovaPessoa(){
        switch(linguagem){
            case "BR":
                return 'Adicionar Nova Pessoa';
            case "US":
                return 'Add New Person'
            case "ES":
                return 'Agregar Nueva Persona'
            default:
                return 'Adicionar Nova Pessoa'
        }
    }

    static nacionalidade(){
        switch(linguagem){
            case "BR":
                return 'Nacionalidade';
            case "US":
                return 'Nacionality'
            case "ES":
                return 'Nacionalidad'
            default:
                return 'Nacionalidade'
        }
    }

    static sexo(){
        switch(linguagem){
            case "BR":
                return 'Sexo';
            case "US":
                return 'Sex'
            case "ES":
                return 'Sexo'
            default:
                return 'Sexo'
        }
    }

    static dataNascimento(){
        switch(linguagem){
            case "BR":
                return 'Data de Nascimento';
            case "US":
                return 'Birthday'
            case "ES":
                return 'Fecha de Nacimiento'
            default:
                return 'Data de Nascimento'
        }
    }

    static telefone(){
        switch(linguagem){
            case "BR":
                return 'Telefone';
            case "US":
                return 'Telephone'
            case "ES":
                return 'Telefono'
            default:
                return 'Telefone'
        }
    }

    static categoria(){
        switch(linguagem){
            case "BR":
                return 'Categoria';
            case "US":
                return 'Category'
            case "ES":
                return 'Categoría'
            default:
                return 'Categoria'
        }
    }

    static salvar(){
        switch(linguagem){
            case "BR":
                return 'Salvar';
            case "US":
                return 'Save'
            case "ES":
                return 'Guardar'
            default:
                return 'Salvar'
        }
    }

    static maquinas(linguagem){
        if(linguagem === 'BR'){
            return 'Máquinas'
        }else if(linguagem === 'US'){
            return 'Machines'
        }else return 'Máquinas'
    }


    static maquinasCadastradas(linguagem){
        if(linguagem === 'BR'){
            return 'Máquinas Cadastradas'
        }else if(linguagem === 'US'){
            return 'Segistered Machines'
        }else return 'Máquinas Registradas'
    }

    static cadastroDeMaquinas(linguagem){
        if(linguagem === 'BR'){
            return 'Cadastro de Máquinas'
        }else if(linguagem === 'US'){
            return 'Registration of Machines'
        }else return 'Registro de Máquinas'
    }

    static descricao(){
        switch(linguagem){
            case "BR":
                return 'Descrição';
            case "US":
                return 'Description'
            case "ES":
                return 'Descripción'
            default:
                return 'Descrição'
        }
    }

    static novoLocal(){
        switch(linguagem){
            case "BR":
                return 'Novo local';
            case "US":
                return 'New Location'
            case "ES":
                return 'Nueva ubicación'
            default:
                return 'Novo local'
        }
    }

    static locaisCadastrados(){
        switch(linguagem){
            case "BR":
                return 'Locais cadastrados';
            case "US":
                return 'Registered Locations'
            case "ES":
                return 'Ubicaciones Registradas'
            default:
                return 'Locais cadastrados'
        }
    }

    static locais(){
        switch(linguagem){
            case "BR":
                return 'Locais';
            case "US":
                return 'Locations'
            case "ES":
                return 'Ubicaciones'
            default:
                return 'Locais'
        }
    }

    static excluirLocal(){
        switch(linguagem){
            case "BR":
                return 'Excluir Local';
            case "US":
                return 'Delete location'
            case "ES":
                return 'Eliminar ubicación'
            default:
                return 'Excluir Local';
        }
    }

    // pessoa.cadastro.page.js------------------------------

    

}
