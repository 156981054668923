import React, { useState, useEffect } from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Modal from '../../utils/modalHelper';
import Utils from '../../utils/utils';
import ModalAtivaDesativa from '../../utils/modalHelper';
import ToastHelper from "../../utils/toastHelper";
import SafraRequest from './safra.requests'
import SafraService from 'services/safraService'

function SafraListagem(props) {

	const columns = [
		{ id: 1, title: 'Safra', property: 'safra' },
		{ id: 1, title: 'Atividade', property: 'nome' },
		{ id: 1, title: 'Cultura', property: 'cultura' },
		{ id: 3, title: 'Ativo', property: 'isAtivo' }
	];

	const actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_SAFRA_LEITURA,
			action: item => visualizar(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_SAFRA_ATUALIZAR,
			action: item => editar(item),
		},
		{
			id: 2,
			name: 'Duplicar',
			icon: 'fas fa-copy',
			permissao: PERMISSAO.MODULO_SAFRA_ATUALIZAR,
			action: item => duplicar(item.id),
		},
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
			permissao: PERMISSAO.MODULO_SAFRA_ATUALIZAR,
			action: item => handleModalAtivaDesativa(item),
		},
		{
			id: 4,
			name: 'Apagar',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_SAFRA_EXCLUIR,
			action: item => handleModalExcluir(item),
		}
	];


	const duplicar = async id => {
		let result = await SafraRequest.duplicarSafra(id);
		if (result.isOk) {
			let data = await SafraRequest.getSafras(pagination);
			initPagination(data);
			ToastHelper.success("Safra duplicada com sucesso!");
		} else {
			ToastHelper.error(result.mensagem);
		}
	}

	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [items, setItems] = useState([]);
	const [excluirItem, setExcluirItem] = useState({ id: null, showModal: false })
	const [orderColumn, setOrderColumn] = useState()
	const [descending, setDescending] = useState(true)
	const [idSafraAtivaDesativa, setIdSafraAtivaDesativa] = useState();
	const [showModalAtivaDesativa, setShowModalAtivaDesativa] = useState();

	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: currentPage,
		pageSize: 10,
		filtro: '',
		order: "Nome"
	}

	const [pagination, setPagination] = useState(paginateParams)

	const filtrar = async (content) => {

		let newPagination = pagination;
		newPagination.filtro = content.toLowerCase();;
		setPagination(newPagination)
		newPagination.page = 1;
		let data = await SafraRequest.getSafras(newPagination)
		initPagination(data)
	}

	const handleModalAtivaDesativa = (item) => {
		if (item) {
			setIdSafraAtivaDesativa(item.id)
			setShowModalAtivaDesativa(!showModalAtivaDesativa)

		} else {
			setShowModalAtivaDesativa(!showModalAtivaDesativa)
		}
	}

	const editar = (item) => {
		props.history.push({
			pathname: `/safra/editar/${item.id}`
		});
	}

	const visualizar = (item) => {
		props.history.push({
			pathname: `/safra/visualizar/${item.id}`
		});
	}

	const cadastrarRota = () => {
		props.history.push(`/safra/cadastro`);
	}

	const handleModalExcluir = (item) => {
		if (item) {
			setExcluirItem({ id: item.id, showModal: !excluirItem.showModal })
		} else {
			setExcluirItem({ id: null, showModal: !excluirItem.showModal })
		}
	}


	useEffect(() => {
		async function fetch() {
			if (localStorage.getItem('fazendaId')) {
				let data = await SafraRequest.getSafras(pagination);
				initPagination(data);
			}
		}
		fetch();
	}, []);



	const orderTable = async (property, descending) => {
		setOrderColumn(Utils.parsePropertToFilter(property));
		setDescending(descending);
		let params = pagination;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		let data = await SafraRequest.getSafras(params)
		initPagination(data)
	}

	const initPagination = (data) => {
		setItems(data.items);
		setTotal(data.total);
		setPage(data.page);
	}

	const changePage = async (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
		let data = await SafraRequest.getSafras(newPagination)
		initPagination(data)
	}

	const apagarSafra = async () => {
		let data = await SafraRequest.deletar(excluirItem.id);
		if (data.isOk)
			ToastHelper.success("Safra deletada com sucesso!");
		else
			ToastHelper.error("Ocorreu um erro!");

		setTimeout(async function () {
			let data = await SafraRequest.getSafras(pagination);
			initPagination(data)
		}, 800)
		setExcluirItem({ showModal: false })
	}

	const ativaDesativa = async (item) => {
		let dados = await SafraService.ativaDesativa(item);

		if (dados.status === 200) {

			ToastHelper.success("Status da fazenda alterado com sucesso!");
			let data = await SafraRequest.getSafras(pagination)
			initPagination(data)
			handleModalAtivaDesativa(null);
		} else {
			ToastHelper.error("Erro ao tentar alterar o status da fazenda!");
		}
	};

	return (
		<>
			<Title
				acao="Nova Safra"
				permissaoBotao={PERMISSAO.MODULO_SAFRA_CRIAR}
				iconBotao="fas fa-plus"
				onClick={cadastrarRota}>
				Listagem de Safras
            </Title>
			<CardStyle className="card">
				<SimpleSearch
					placeHolder="Pesquisar"
					value={pagination.filtro}
					submit={(e) => filtrar(e)}
				/>
				<Table
					align='left'
					showPaginate={total > 10}
					totalElements={total}
					totalPages={page}
					columns={columns}
					actions={actions}
					data={items}
					currentPage={currentPage}
					onChangePage={page => changePage(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={columns ? columns.length + 1 : 1}
					orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
				/>
			</CardStyle>
			<Modal
				show={excluirItem.showModal}
				doubleButtons={true}
				title={"Excluir Safra"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => setExcluirItem({ id: excluirItem.id, showModal: false })}
				submit={apagarSafra}
				close={() => setExcluirItem({ id: excluirItem.id, showModal: false })}
				content={
					<h6>{"Deseja realmente excluir essa safra?"}</h6>
				}
			/>
			<ModalAtivaDesativa
				show={showModalAtivaDesativa}
				title={'Ativar / Desativar Fazenda'}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => handleModalAtivaDesativa(null)}
				submit={() => ativaDesativa(idSafraAtivaDesativa)}
				close={() => handleModalAtivaDesativa(null)}
				content={
					<h6>{'Deseja realmente alterar o status desta fazenda?'}</h6>
				}
			/>
		</>
	)
}

export default SafraListagem
