import React, { useState, useCallback, useEffect } from "react";
import MaterialTable from "material-table";
import { TextField } from "@material-ui/core";
import { MTablePagination, MTableToolbar } from "material-table";
import ToastHelper from "../../utils/toastHelper";
import ReactTooltip from "react-tooltip";
import "./tabelaAjustesApontamento.scss";
import { useSelector, useDispatch } from "react-redux";
import Toolbar from "../../components/BasicPageTopBar/Toolbar";
import ApontamentoMaService from "../../services/apontamentoMaquinaService";
import Utils from '../../utils/utils'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ServicoService from '../../services/servicoService';
import ObjectHelper from "../../utils/objectHelper"
import { Edit3 as Edit3Icon } from 'react-feather';
import ModalEdicaoEmGrupo from '../../pages/ajustes_apontamentos/ajustes_apontamentos_modal'
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";


const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});

export default function TabelaAjusteApontamentoMaquina(props) {
	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
		safraId: JSON.parse(localStorage.getItem("safraId")).value,
		page: 1,
		pageSize: 10,
		countItems: 0,
		filtro: "",
		servicoTipoId: "",
		servicoId: "",
		colaboradorMaquina: "",
		localId: "",
		periodoInicio: "",
		periodoFinal: "",
		descedescending: true,
		order: "Data"
	};

	const dispatch = useDispatch();
	const [pagination, setPagination] = useState(useSelector(state => state.ajusteApontamentoMa.filtroListagem));
	const [apontamentos, setApontamentos] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState();
	const [selecionados, setSelecionados] = useState([]);
	const [showModal, setShowModal] = useState(false)

	const validaHorimetroInputEdicao = (rowData, tipo) => {
		let error = false
		if (tipo === 'horimetroInicio') {
			if (
				(parseFloat(rowData.horimetroInicio) > parseFloat(rowData.horimetroFim)) ||
				(rowData.horimetroInicio === "") ||
				(parseFloat(rowData.horimetroInicio) < 0)
			) {
				error = true
			}
		} else if (
			(parseFloat(rowData.horimetroInicio) > parseFloat(rowData.horimetroFim)) ||
			(rowData.horimetroFim === "") ||
			(parseFloat(rowData.horimetroFim) < 0)
		) {
			error = true
		}
		return error
	}

	const colunas = [
		{
			title: "Data",
			field: "data",
			editable: "never",
		},
		{
			title: "Máquina",
			field: "maquina.label",
			editable: "never",
		},
		{
			title: "Atividade",
			editable: "never",
			field: "safra.label",
		},
		{
			title: "Safra",
			editable: "never",
			field: "grupoSafra",
		},
		{
			title: "Local",
			field: "local.label",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="local"
						size="small"
						options={props.locais}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={(event, newValue) => {
							var data = { ...propsLin.rowData };
							data.local = newValue;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.local}
						defaultValue={propsLin.rowData.local}
						renderInput={(params) => (
							<TextField {...params} variant="standard" />
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Grupo Serviço",
			field: "grupoServico.label",
			width: 100,
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="grupoServico"
						size="small"
						options={props.gruposServicos}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={async (event, newValue) => {
							var data = { ...propsLin.rowData };
							data.servico = '';
							data.grupoServico = newValue;
							data.opcoesServicos = await buscarServicosPorGrupo(newValue.value)
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.grupoServico}
						defaultValue={propsLin.rowData.grupoServico}
						renderInput={(params) => (
							<TextField {...params} variant="standard" />
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Serviço",
			field: "servico.label",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="servico"
						size="small"
						options={propsLin.rowData.opcoesServicos || []}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={(event, newValue) => {
							var data = { ...propsLin.rowData };
							data.servico = newValue;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.servico}
						defaultValue={propsLin.rowData.servico}
						renderInput={(params) => (
							<TextField {...params} variant="standard"
								error={propsLin.rowData.servico === ""}
								helperText={propsLin.rowData.servico === "" ? "Campo obrigatório" : ''}
							/>
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "H Inicial",
			field: "horimetroInicio",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					{
						!propsLin.rowData.horimetroAtivo &&
						<ReactTooltip
							place="right"
							type="dark"
							delayShow={200}
							effect="float"
						/>
					}
					<TextField
						id="horimetroInicio"
						name="horimetroInicio"
						data-tip={!propsLin.rowData.horimetroAtivo ? "Horimetro desativado" : ''}
						disabled={!propsLin.rowData.horimetroAtivo}
						type="number"
						error={validaHorimetroInputEdicao(propsLin.rowData, "horimetroInicio")}
						helperText={validaHorimetroInputEdicao(propsLin.rowData, "horimetroInicio") ? "Campo inválido" : ''}
						onChange={e => {
							var data = { ...propsLin.rowData };
							data.horimetroInicio = e.target.value;
							propsLin.onRowDataChange(data);
						}}
						onBlur={e => {
							var data = { ...propsLin.rowData };
							data = handleHours(data, e.target.value, "horimetroInicio")
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.value}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "H Final",
			field: "horimetroFim",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					{
						!propsLin.rowData.horimetroAtivo &&
						<ReactTooltip
							place="right"
							type="dark"
							delayShow={200}
							effect="float"
						/>
					}
					<TextField
						id="horimetroFim"
						name="horimetroFim"
						data-tip={!propsLin.rowData.horimetroAtivo ? "Horimetro desativado" : ''}
						type="number"
						disabled={!propsLin.rowData.horimetroAtivo}
						error={validaHorimetroInputEdicao(propsLin.rowData, "horimetroFim")}
						helperText={validaHorimetroInputEdicao(propsLin.rowData, "horimetroFim") ? 'Campo inválido' : ""}
						onChange={e => {
							var data = { ...propsLin.rowData };
							data.horimetroFim = e.target.value;
							propsLin.onRowDataChange(data);
						}}
						onBlur={e => {
							var data = { ...propsLin.rowData };
							data = handleHours(data, e.target.value, "horimetroFim")
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.value}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Horas M.",
			field: "horimetroTotal",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<TextField
						id="horimetroTotal"
						name="horimetroTotal"
						disabled={propsLin.rowData.horimetroAtivo}
						type="time"
						error={propsLin.rowData.horimetroTotal === ("" || "00:00")}
						helperText={propsLin.rowData.horimetroTotal === ("" || "00:00") ? "Campo obrigatório" : ''}
						onChange={e => {
							var data = { ...propsLin.rowData };
							data.horimetroTotal = e.target.value;
							propsLin.onRowDataChange(data);
						}}
						onBlur={e => {
							var data = { ...propsLin.rowData };
							data = handleHours(data, e.target.value, "horimetroTotal")
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.value}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Horas H.",
			field: "horaHomem",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<TextField
						id="horaHomem"
						name="horaHomem"
						type="time"
						error={propsLin.rowData.horaHomem === ""}
						helperText={propsLin.rowData.horaHomem === "" ? "Campo obrigatório" : ''}
						onChange={e => {
							var data = { ...propsLin.rowData };
							data.horaHomem = e.target.value;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.value}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},

	];

	useEffect(() => {
		async function init() {
			buscarApforEacharaAjustes(pagination)
		}
		init()
	}, [])

	const buscarServicosPorGrupo = async (grupoId) => {
		let opcoesServicos = []
		await ServicoService.buscarPorGrupoId(paginateParams.fazendaId, grupoId).then((response) => {
			if (response.status === 200) {
				opcoesServicos = ObjectHelper.getValuesParseToSelect(response.data.result)
			}
			else {
				ToastHelper.error(response.statusText);
				opcoesServicos = []
			}
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
		return opcoesServicos;
	};

	const buscarApforEacharaAjustes = (pagination) => {
		ApontamentoMaService.buscarParaAjustes(pagination).then((response) => {
			if (response.status === 200) {
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horaHomem = Utils.formatHour(x.horaHomem);
					x.horimetroTotal = Utils.formatHour(x.horimetroTotal);
				})
				verificaSelecionados(response.data.result.items)
				setTotalCount(response.data.result.total)
				setPageSize(pagination.pageSize)
				setPage(pagination.page)

				setApontamentos(response.data.result.items)

			} else {
				ToastHelper.error(response.statusText);
			}
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	}

	const handleFilter = async pag => {
		pag.descedescending = true;
		pag.page = 1;

		dispatch({
			type: "SET_FILTRO_AJUSTE_APONTAMENTO_MA_LISTAGEM",
			filtroListagem: pag
		});

		await setPagination(pag);
		buscarApforEacharaAjustes(pag);
	};

	const getFiltroServicosByGrupoId = async grupoServico => {
		/*let { data } = await servicoService.buscarPorGrupoId(
			filter.fazendaId,
			grupoServico
		);

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setServicosFiltro(newServicos);*/
	};

	const newAppointment = () => {
		props.history.push({
			pathname: `/apontamento/maquina/cadastro`
		});
	};

	const onChangePage = (e, b, d) => {
		let newPagination = pagination;
		newPagination.page = b + 1;
		setPagination(newPagination);
		buscarApforEacharaAjustes(newPagination);
	};

	const onOrderChange = (numeroColuna) => {
		let newPagination = { ...pagination };
		newPagination.descedescending = !newPagination.descedescending;
		newPagination.order = trataOrdenacaoColunas(colunas[numeroColuna].field)
		setPagination(newPagination);
		buscarApforEacharaAjustes(newPagination);
	};

	const trataOrdenacaoColunas = (coluna) => {
		let name = null;
		switch (coluna) {
			case 'grupoServico.label':
				name = 'ServicoTipo.Nome'
				break;
			case 'local.label':
				name = 'Local.Nome'
				break;
			case 'servico.label':
				name = 'Servico.Nome'
				break;
			case 'colaborador.label':
				name = 'Pessoa.Nome'
				break;
			case 'maquina.label':
				name = 'Maquinas.Nome'
				break;
			default:
				name = Utils.trataColunaParaOrdenacao(coluna)
				break;
		}
		return name
	}

	const validSubmit = async (lista) => {
		let error = true;
		await lista.forEach(item => {
			if (item.horimetroAtivo)
				if (
					(item.horimetroInicio === ("" || undefined)) ||
					(item.horimetroFim === ("" || undefined)) ||
					(item.horaHomem === ("" || undefined)) ||
					(item.servicoId === ("" || undefined))
				) error = false;
				else
					if (
						(item.horaHomem === ("" || undefined)) ||
						(item.servicoId === ("" || undefined))
					) error = false;
		});
		return error;
	}

	const atualizaApontamentos = async (apontamentos) => {
		await ApontamentoMaService.atualizaListaApontamentos({ apontamentos: apontamentos, filtro: pagination }).then((response) => {

			if (response.status === 200) {
				ToastHelper.success("Apontamentos atualizados com sucesso.");
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horaHomem = Utils.formatHour(x.horaHomem);
					x.horimetroTotal = Utils.formatHour(x.horimetroTotal);
				})
				setTotalCount(response.data.result.total)
				setPageSize(pagination.pageSize)
				setPage(pagination.page)

				setApontamentos(response.data.result.items)
			}
			else
				ToastHelper.error(response.statusText);

		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const deletarApontamento = async (apontamentoId) => {
		await ApontamentoMaService.delete(apontamentoId).then((response) => {

			if (response.status === 200) {
				buscarApforEacharaAjustes(pagination)
				ToastHelper.success("Apontamento excluído com sucesso.");
			}
			else
				ToastHelper.error(response.statusText);

		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};


	const handleHours = useCallback((rowData, valor, campo) => {
		let row = { ...rowData }
		if (row.horimetroAtivo) {

			let horimetroInicio = row.horimetroInicio || null;
			let horimetroFim = row.horimetroFim || null;



			if (row.horimetroInicio !== '')
				horimetroInicio = row.horimetroInicio;

			if (row.horimetroFim !== '')
				horimetroFim = row.horimetroFim;

			if (campo === "horimetroInicio") {
				horimetroInicio = valor.replace(',', '.');
			}

			if (campo === "horimetrofIM") {
				horimetroFim = valor.replace(',', '.');
			}

			if (horimetroFim !== null && horimetroInicio !== null) {
				let totalHorasMaquinaString = '';
				let horasMaquinaFinalDecimal = parseFloat(horimetroFim) - parseFloat(horimetroInicio)
				totalHorasMaquinaString = convertHoraParaDecimal(horasMaquinaFinalDecimal);
				row.horimetroTotal = totalHorasMaquinaString;
			}

		} else {

			let totalHorasMaquina = row.horimetroTotal || null;

			if (row.horimetroTotal !== '')
				totalHorasMaquina = row.horimetroTotal;

			if (campo === "horimetroTotal") {
				totalHorasMaquina = valor
			}
			row.horimetroTotal = totalHorasMaquina;
		}
		return row;
	})



	const convertHoraParaDecimal = useCallback((decimalTimeString) => {
		var hrs = parseInt(Number(decimalTimeString)).toString();
		if (hrs.length === 1) {
			hrs = '0' + hrs
		}

		var min = Math.round((Number(decimalTimeString) - hrs) * 60).toString();
		if (min.length === 1 && min !== '0') {
			min = '0' + min
		} else if (min.length === 1) {
			min = min + '0'
		}

		var totalHoursString = hrs + ':' + min;

		if (totalHoursString) {
			return totalHoursString;
		} else {
			return '00,00'
		}
	})

	const verificaSelecionados = (items) => {
		items.forEach(item => {
			const existe = (selecionados.indexOf(item.id) !== -1)
			if (existe) {
				item.tableData = { ...item.tableData, checked: true }
			}
		})
	}

	const excluirSelecionados = () => {
		ApontamentoMaService.excluirSelecionados({ apontamentosId: selecionados, filtro: pagination }).then((response) => {
			if (response.status === 200) {
				ToastHelper.success("Apontamentos excluidos com sucesso.");
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horaHomem = Utils.formatHour(x.horaHomem);
					x.horimetroTotal = Utils.formatHour(x.horimetroTotal);
				})
				verificaSelecionados(response.data.result.items)
				setApontamentos(response.data.result.items)
				setSelecionados([])
			}
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	}

	const mudaSelecionados = (listaSelecionados, item, ccc) => {
		let novaLista = [...selecionados]
		if (item !== undefined) {
			if (item.tableData.checked) {
				novaLista.push(item.id)
			} else {
				novaLista = novaLista.filter(x => x !== item.id)
			}
		} else {
			if (listaSelecionados.length > 0) {
				listaSelecionados.forEach(x =>
					novaLista.push(x.id)
				)
			} else {
				novaLista = []
			}
		}
		setSelecionados(novaLista)
	}

	const submitModal = (alteracoes) => {
		ApontamentoMaService.editarSelecionados({ apontamentosId: selecionados, filtro: pagination, alteracoes: alteracoes }).then((response) => {
			if (response.status === 200) {
				ToastHelper.success("Apontamentos editados com sucesso.");
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horaHomem = Utils.formatHour(x.horaHomem);
					x.horimetroTotal = Utils.formatHour(x.horimetroTotal);
				})
				setApontamentos(response.data.result.items)
				setSelecionados([])
			}
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	}

	return (
		<>
			<Toolbar
				style={{ marginTop: '-20px' }}
				handleFiltro={e => handleFilter(e)}
				filtroInicial={pagination}
				onClickAdd={() => newAppointment()}
				filtroLocais={props.locais}
				filtroServicos={props.servicos}
				filtroMaquinas={props.maquinas}
				getFiltroServicosByGrupoId={e => getFiltroServicosByGrupoId(e)}
				filtroPeriodo
				safras={JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2}
				atividades={JSON.parse(localStorage.getItem('safraId')).length >= 2}
				temFiltroAvancado
			/>
			<div style={{ margin: '20px' }}></div>
			<ThemeProvider theme={theme}>
				<MaterialTable
					title="Apontamentos de maquinas"
					columns={colunas}
					data={apontamentos}
					onOrderChange={(numeroColuna, orientacao1) =>
						onOrderChange(numeroColuna, orientacao1)
					}
					options={{
						search: false,
						selection: true,
						pageSize: pageSize,
						paging: true,
						rowStyle: {
							fontSize: 14
						},
						headerStyle: {
							fontSize: 14
						},
						filterCellStyle: {
							fontSize: 14
						},
						actionsColumnIndex: -1,
						selectionProps: rowData => ({
							color: 'primary'
						})
					}}
					onSelectionChange={(listaSelecionados, item) => mudaSelecionados(listaSelecionados, item)}
					icons={{ Edit: Edit3Icon }}
					components={{
						Pagination: propss => {
							const { classes, headerIsHidden, ...other } = propss;
							return (
								<>
									<div className={"col-md-12 div-sync"}>
										<div className={"col-md-4"}>
											<MTablePagination
												{...other}
												onChangePage={(event, page) =>
													onChangePage(event, page)
												}
												page={page - 1}
												count={totalCount}
												rowsPerPage={pageSize}
												localization={{
													labelRowsSelect: "Apontamento",
													labelDisplayedRows:
														"{from}-{to} de {count}"
												}}
											/>
										</div>
									</div>
								</>
							);
						},
						Toolbar: props => (
							<div>
								<MTableToolbar {...props} showTextRowsSelected={false} />
								<div style={{ padding: '0px 10px' }}>
									{
										selecionados.length > 0 &&
										(
											<div style={{ marginTop: '10px' }}>
												<i
													style={{ cursor: 'pointer' }}
													data-tip="Excluir selecionados"
													className="fas fa-trash-alt fa-lg"
													onClick={
														() => excluirSelecionados()
													}
												/>
												<ReactTooltip
													place="right"
													type="dark"
													delayShow={200}
													effect="float"
												/>
												<i
													style={{ cursor: 'pointer' }}
													data-tip="Editar Selecionados"
													className="fas fa-edit fa-lg ml-3"
													onClick={() => setShowModal(true)}
												/>
												<ReactTooltip
													place="right"
													type="dark"
													delayShow={200}
													effect="float"
												/>
											</div>
										)
									}
								</div>
							</div>
						)
					}}
					editable={{
						onBulkUpdate: changes =>
							new Promise(async (resolve, reject) => {
								let apontamentos = [];
								for (let [value] of Object.entries(changes)) {
									apontamentos.push({
										id: value.newData.id,
										horaHomem: value.newData.horaHomem,
										horimetroTotal: value.newData.horimetroTotal.toString(),
										horimetroInicio: value.newData.horimetroInicio.toString(),
										horimetroFim: value.newData.horimetroFim.toString(),
										localId: value.newData.local.value,
										servicoId: value.newData.servico.value,
										servicoTipoId: value.newData.grupoServico.value,
										tableData: value.newData.tableData,
										horimetroAtivo: value.newData.horimetroAtivo,
										fazendaId: value.newData.fazendaId,
										safraId: value.newData.safraId
									});
								}
								if (apontamentos.length > 0) {
									let valido = await validSubmit(apontamentos);
									if (valido) {
										atualizaApontamentos(apontamentos);
										resolve();
									} else {
										reject();
									}
								}
								resolve();
							}),
						onRowDelete: row =>
							new Promise(async (resolve, reject) => {
								deletarApontamento(row.id)
								resolve();
							})
					}}
					localization={{
						header: {
							actions: "Ações"
						},
						body: {
							emptyDataSourceMessage: "Nenhum registro para exibir",
							addTooltip: "Adicionar",
							deleteTooltip: "Excluir",
							bulkEditTooltip: "Edição rapida",
							editTooltip: "Editar item completo.",
							editRow: {
								deleteText: "Deseja excluir este apontamento?",
								cancelTooltip: "Cancelar",
								saveTooltip: "Confirmar"
							}
						},
						toolbar: {
							searchTooltip: "Pesquisar",
							searchPlaceholder: "Pesquisar",
							nRowsSelected: "{0} recurso(s) seleconado(s)"
						},
						pagination: {
							labelRowsSelect: "apontamentos",
							labelDisplayedRows: "{from}-{to} de {count}"
						}
					}}
				/>
			</ThemeProvider>
			<ModalEdicaoEmGrupo
				showModal={showModal}
				setShowModal={(e) => setShowModal(e)}
				locais={props.locais}
				gruposServicos={props.gruposServicos}
				servicos={props.servicos}
				salvarAlteracoes={(e) => submitModal(e)}
				tipoApontamento={'MA'}
			/>
		</>
	);
};
