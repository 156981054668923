import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
	static propTypes = {
		children: PropTypes.instanceOf(Object).isRequired,
		isOpen: PropTypes.bool.isRequired,
		label: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired
	};

	onClick = () => {
		this.props.onClick(this.props.label);
	};

	render() {
		const {
			onClick,
			props: { isOpen, label }
		} = this;

		return (
			<div
				style={{
					background: isOpen ? "#fff" : "#fff",
					border: "none",
					padding: "15px 10px"
				}}
			>
				<div onClick={onClick} style={{ cursor: "pointer" }}>
					<span style={{ fontSize: 18, fontWeight: 500 }}>
						{label}
					</span>
					<div style={{ float: "right" }}>
						{!isOpen && (
							<span>
								<i className="fas fa-chevron-up"> </i>
							</span>
						)}
						{isOpen && (
							<span>
								<i className="fas fa-chevron-down"> </i>
							</span>
						)}
					</div>
				</div>
				{isOpen && (
					<div
						style={{
							background: "#fff",
							border: "none"
						}}
					>
						{this.props.children}
					</div>
				)}
			</div>
		);
	}
}

export default AccordionSection;
