import React, { useState, useEffect } from 'react';
import PainelMOFiltro from './filtro/painel.filtro'
import { Creators as ModulosCreators } from './store/painel.ducks'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Table from '../../components/Table/table.component'
import { CardStyle } from "../../components/CardStyle/cardstyle.component"
import DateHelper from 'utils/dateHelper';
import ToastHelper from 'utils/toastHelper';
import painelService from '../../services/painelService'
import equipeService from '../../services/equipeService'
import categoriaService from '../../services/categoriaService'

function PainelMO(props) {

	const colunaResumo = [
		{ id: 0, title: 'Colaborador', property: 'nomeColaborador' },
		{ id: 2, title: 'Diurno', property: 'diurno' },
		{ id: 3, title: 'Hora Extra 100%', property: 'horaExtra100' },
		{ id: 4, title: 'Hora Extra 50%', property: 'horaExtra50' },
		{ id: 5, title: 'Bonificação', property: 'horaBonificacao' },
		{ id: 6, title: 'Máquina', property: 'maquina' },
		{ id: 7, title: 'Férias', property: 'ferias' },
		{ id: 8, title: 'Folga', property: 'folga' },
		{ id: 9, title: 'Falta c/ Atestado', property: 'faltaComAtestado' },
		{ id: 10, title: 'Falta s/ Atestado', property: 'faltaSemAtestado' },
		{ id: 11, title: 'Outros', property: 'outros' },
	];

	const [columns, setColumns] = useState(colunaResumo)

	const [currentPage, setCurrentPage] = useState(1);
	const [dados, setDados] = useState([])
	const [opcaoResumo, setOpcaoResumo] = useState(true);
	const [equipes, setEquipes] = useState([])
	const [categorias, setCategorias] = useState([])

	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
		page: currentPage,
		pageSize: 10,
	}

	const [pagination, setPagination] = useState(paginateParams)
	const filtrar = (filtro) => {
		if (opcaoResumo) {
			filtro.page = 1;

			if (filtro.inicio !== undefined || filtro.inicio !== "")
				filtro.inicio = DateHelper.formatarData(filtro.inicio);

			if (filtro.fim !== undefined || filtro.fim !== "")
				filtro.fim = DateHelper.formatarData(filtro.fim);

			filtro.pageSize = 10
			filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
			setPagination(filtro)
			if ((filtro.inicio !== undefined || filtro.inicio !== "") && (filtro.fim !== undefined || filtro.fim !== ""))
				if (!filtro.inicio || !filtro.fim)
					return ToastHelper.error("Formato de data invalido")
			buscarMO(filtro);
		} else {
			buscarMOSemanal(filtro);
		}
	}


	const buscarCategorias = async () => {
		var parametro = paginateParams;
		parametro.pageSize = 1000;
		const { data } = await categoriaService.buscarTodas(parametro);
		if (data.isOk)
			setCategorias(data.result.items)
	}

	const buscarEquipes = async () => {
		var parametro = paginateParams;
		parametro.pageSize = 1000;
		const { data } = await equipeService.buscarTodas(parametro);
		if (data.isOk)
			setEquipes(data.result.items)
	}

	const setarOpcaoResumo = opcao => {
		setOpcaoResumo(opcao)
		if (!opcao) {
			setPagination(paginateParams)
			buscarMOSemanal(paginateParams)
		} else {
			setColumns(colunaResumo);
			buscarMO(pagination)
		}
	}

	const buscarMOSemanal = async (filtro) => {
		const { data } = await painelService.buscarMOSemanal(filtro !== undefined ? filtro : pagination)
		setColumns(data.result.colunas);
		await setDados(data.result.items)
	}

	const buscarMO = async (filtro) => {
		const { data } = await painelService.buscarMO(filtro !== undefined ? filtro : pagination)
		if (data.isOk) {
			await setDados(data.result)

		}
	}

	useEffect(() => {
		async function fetchData() {
			await buscarEquipes();
			await buscarCategorias();
			const { data } = await painelService.buscarMO(pagination)
			if (data.isOk) {
				await setDados(data.result)

			}
		}
		fetchData();
	}, []);

	const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
		props.buscarPainelMO(pagination)
	}

	return (
		<>
			<PainelMOFiltro
				filtrar={filtrar}
				setarOpcao={setarOpcaoResumo}
				opcao={opcaoResumo}
				equipes={equipes}
				categorias={categorias}
				buscarMO={buscarMO}
			/>
			<CardStyle className="card">
				<div className="row">
					<div className="col-md-12">
						<div label='Filtros' style={{ margin: 10, fontSize: 18 }}>
							{/* Tabela de Colaboradores */}
						</div>

						<Table
							align='left'
							columns={columns || []}
							data={dados}
							trClass="table-painel-mo"
							onChangePage={page => changePage(page)}
							emptyMessage="Nenhum item encontrado."
							emptyColSpan={columns ? columns.length : 0}
						/>
					</div>
				</div>
			</CardStyle>
		</>
	)
}

const mapStateToProps = state => {
	return ({
		categoria: state.painelMO.categoria,
		painelMO: state.painelMO.painelMO
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PainelMO)
