import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getGrupoMaquina: ['params',  'callback'],
	readGrupoMaquina: ['params'],

	createGrupoMaquina: ['params', 'history', 'callback'],
	postGrupoMaquina: ['params'],

	getByIdGrupoMaquina: ['params', 'setUnitsConversions','callback'],
	setGetByIdGrupoMaquina: ['params'],

	updateGrupoMaquina: ['params', 'history'],

	deletarGrupoMaquina: ['params', 'props', 'callback'],
})

const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getGrupoMaquina = (state = INITIAL_STATE, action) => state.merge({itens: action.data})
const createGrupoMaquina = (state = INITIAL_STATE, action) => state.merge({item: action.data})
const getByIdGrupoMaquina = (state = INITIAL_STATE, action) => state.merge( action.data )
const updateGrupoMaquina = (state = INITIAL_STATE, action) => state.merge( action.params )
const deletarGrupoMaquina = (state = INITIAL_STATE, action) => state.merge({id: action.params })

export default createReducer(INITIAL_STATE, {
	[Types.SET_GET_BY_ID_GRUPO_MAQUINA]: getByIdGrupoMaquina,
	[Types.READ_GRUPO_MAQUINA]: getGrupoMaquina,
	[Types.POST_GRUPO_MAQUINA]: createGrupoMaquina,
	[Types.UPDATE_GRUPO_MAQUINA]: updateGrupoMaquina,
	[Types.DELETAR_GRUPO_MAQUINA]: deletarGrupoMaquina
})
