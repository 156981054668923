import React, { useState, useEffect } from "react";
import { PERMISSAO } from "../../permissoes/permissoes";
import { bindActionCreators } from "redux";
import { Creators as ModulosCreators } from "../../store/ducks/recomendacoes_tecnicas.ducks";
import { connect } from "react-redux";
import Title from "../../components/Title/title.component";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import {
	makeStyles
} from "@material-ui/core";
import ElementoAnaliseSoloService from 'services/ElementoAnaliseSoloService'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import CopyToClipBoard from "copy-to-clipboard";
import ToastHelper from "../../utils/toastHelper";
import FormulaAnaliseSoloService from '../../services/formulaAnaliseSoloService'

function FormulasAnaliseSolo(props) {
	const useStyles = makeStyles((theme) => ({
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
		},
	}));

	const classes = useStyles();
	const [formulas, setFormulas] = useState([]);
	const [elementos, setElementos] = useState([]);
	const [column, setColumn] = useState([]);

	useEffect(() => {
		buscarFormulas()
		buscarElementosAnaliseSolo()
	}, []);

	const HtmlTooltip = withStyles((theme) => ({
		tooltip: {
			minWidth: 'max-content',
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}))(Tooltip);

	const buscarFormulas = async () => {
		let params = {
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			filtro: "",
			page: 0,
			pageSize: 9999
		}

		await FormulaAnaliseSoloService.buscarTodos(params).then(response => {
			let newColuns = [...column]
			response.data.result.items.map(async item => {
				newColuns = [...newColuns, {
					id: item.id,
					nome: item.nome,
					formula: item.formula,
					descricao: item.descricao,
				}];
			})
			setColumn(newColuns)
			setFormulas(newColuns)
		})
	}

	const buscarElementosAnaliseSolo = async () => {
		let params = {
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			filtro: "",
			page: 0,
			pageSize: 9999
		}
		let { data } = await ElementoAnaliseSoloService.buscarTodas(params)
		if (data.isOk) {
			let novosElementos = []
			await data.result.items.map(elemento => {
				return novosElementos.push({
					id: elemento.id,
					nome: elemento.nome,
					codigo: elemento.codigo,
					sigla: elemento.sigla,
					unidade: elemento.unidade
				})
			})
			setElementos(novosElementos);
		}
	}

	const handleInputChange = async (e, index, coluna) => {
		const list = [...column];
		if (!coluna) {
			const { name, value } = e.target;
			list[index][name] = value;
		}
		else {

			if (coluna === "nome") {
				e ? list[index].nome = e : list[index].nome = null
			}

			if (coluna === "formula") {
				e ? list[index].formula = e : list[index].formula = null
			}

			if (coluna === "descricao") {
				e ? list[index].descricao = e : list[index].descricao = null
			}

			list[index][coluna] = e !== null ? e.value : null
		}

		setTimeout(function () {
			setColumn(list);
		}, 100)

	};

	const addColumn = () => {
		setColumn([...column, {
			id: "",
			nome: "",
			formula: "",
			descricao: "",
		}]);
	}

	const removeColumn = (item, index) => {
		let list = [...column];
		list.splice(index, 1);
		setTimeout(function () {
			setColumn(list);
		}, 100)
	}

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selecionado, setSelecionado] = React.useState('');

	const handleClick = async (event, elemento) => {
		setSelecionado(elemento.sigla)
		setAnchorEl(event.currentTarget);
	};

	const handleClickFormula = async (event, elemento) => {
		setSelecionado(elemento.nome)
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCopiar = async () => {
		let string = "[" + selecionado + "]"
		CopyToClipBoard(string)
		ToastHelper.success("Copiado para área de transferência.");
		handleClose()
	}

	function gerarTooltip(elemento, tipo) {
		if (tipo === 'ELEMENTO') {
			return (
				<>
					<p>Elemento</p>
					<ul>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Nome: {elemento.nome}
							</span>
						</li>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Sigla: {elemento.sigla}
							</span>
						</li>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Código: {elemento.codigo}
							</span>
						</li>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Unidade: {elemento.unidade}
							</span>
						</li>
					</ul>
				</>
			)
		} else {
			return (
				<>
					<p>Fórmula</p>
					<ul>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Nome: {elemento.nome}
							</span>
						</li>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Fórmula: {elemento.formula}
							</span>
						</li>
						<li style={{ marginRight: '10px', marginBottom: '10px' }}>
							<span>
								Descrição: {elemento.descricao}
							</span>
						</li>
					</ul>
				</>
			)
		}
	}

	const submit = () => {
		let param = {};
		let novaLista = []
		column.map(l => {
			return novaLista.push(l)
		})
		param.formulas = [...novaLista];

		param.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

		FormulaAnaliseSoloService.atualizaLista(param).then((response) => {
			if (response.data.isOk) {
				let novosElementos = []
				response.data.result.map(elemento => {
					return novosElementos.push({
						id: elemento.id,
						nome: elemento.nome,
						formula: elemento.formula,
						descricao: elemento.descricao
					})
				})
				setColumn(novosElementos);
				setFormulas(novosElementos)

				ToastHelper.success("Fórmulas atualizadas com sucesso.");
			} else {
				ToastHelper.error(response.statusText);
			}
		});
	}

	return (
		<>
			<Title permissaoBotao={PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR}>
				Fórmulas de análise de solo.
			</Title>
			<Grid container spacing={3}>
				<Grid item xs={10}>
					<Paper className={classes.paper}>
						<div className="row">
							<div className="col-md-2"><label style={{ fontWeight: 600 }}>Nome*</label></div>
							<div className="col-md-5"><label style={{ fontWeight: 600 }}>Fórmula*</label></div>
							<div className="col-md-3"><label style={{ fontWeight: 600 }}>Descrição</label></div>
							<div className="col-md-2">
								<button className="btn btn-primary active pull-right"
									id="basic-text1" onClick={addColumn}

								>
									<i className="fas fa-plus"
										aria-hidden="true" />
								</button>
							</div>
						</div>
						<hr></hr>
						{
							column.map((item, index) => {
								return (
									<>
										<div className="row">
											<div className="col-md-2">
												<input
													className="form-control my-0 py-1"
													placeholder="Digite o nome"
													value={item.nome}
													autoComplete="off"
													name="nome"
													id="nome"
													maxLength="50"
													onChange={(e) => handleInputChange(e, index)}
												/>
											</div>
											<div className="col-md-5">
												<input
													className="form-control my-0 py-1"
													placeholder="Digite a fórmula"
													value={item.formula}
													autoComplete="off"
													name="formula"
													id="formula"
													maxLength="200"
													onChange={(e) => handleInputChange(e, index)}
												/>
											</div>
											<div className="col-md-3">
												<input
													className="form-control my-0 py-1"
													placeholder="Digite a descrição"
													value={item.descricao}
													autoComplete="off"
													name="descricao"
													id="descricao"
													maxLength="200"
													onChange={(e) => handleInputChange(e, index)}
												/>
											</div>
											<div className="col-md-2">
												<button
													className="btn btn-primary"
													style={{ color: 'black', marginTop: 10 }}
													id="basic-text1"

													disabled={true}
													onClick={''/*() => removeColumn(item, index)*/} >
													<i class="fas fa-check" style={{ color: "#00635A" }} aria-hidden="true" ></i>
												</button>
												<button
													className="btn btn-primary"
													style={{ color: 'black', marginTop: 10 }}
													id="basic-text1"
													onClick={() => removeColumn(item, index)} >
													<i className="fas fa-trash" style={{ color: "red" }} aria-hidden="true" />
												</button>
											</div>
										</div>
									</>
								)
							})
						}
						<div className="col-md-12">
							<Button className="btn btn-primary active pull-right" size="small" onClick={() => submit()} variant="contained" style={{ borderRadius: '20px', marginRight: "15px" }}>Salvar</Button>
							<br></br>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={2}>
					<Paper className={classes.paper}>
						<label style={{ fontWeight: 600 }}>Elementos e fórmulas.</label>
						<div className={classes.root} style={{ marginTop: 10 }}>
							{elementos.length > 0 &&
								elementos.map(elemento =>
									<>
										<div style={{ marginTop: 8, borderRadius: '15px !important' }}>
											<Button size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, elemento)} color="primary" variant="contained" style={{ borderRadius: '20px' }}
												startIcon={
													<HtmlTooltip
														title={gerarTooltip(elemento, 'ELEMENTO')}
													>
														<InfoIcon />
													</HtmlTooltip>
												}
											>
												{elemento.sigla}

											</Button>
											<Menu
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}
											>
												<MenuItem onClick={(elemento) => handleCopiar(elemento)}>Copiar</MenuItem>
											</Menu>
										</div>
									</>
								)
							}
						</div>
						<div className={classes.root} style={{ marginTop: 10 }}>
							{formulas.length > 0 &&
								formulas.map(elemento =>
									<>
										<div style={{ marginTop: 8, borderRadius: '15px !important' }}>
											<Button size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClickFormula(e, elemento)} color="red" variant="contained" style={{ borderRadius: '20px' }}
												startIcon={
													<HtmlTooltip
														title={gerarTooltip(elemento, 'FORMULA')}
													>
														<InfoIcon />
													</HtmlTooltip>
												}
											>
												{elemento.nome}

											</Button>
											<Menu
												id="simple-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}
											>
												<MenuItem onClick={(elemento) => handleCopiar(elemento)}>Copiar</MenuItem>
											</Menu>
										</div>
									</>
								)
							}
						</div>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}

const mapStateToProps = state => {
	return {
		//recursos: state.recomendacoesTecnicas.recursos,
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FormulasAnaliseSolo);
