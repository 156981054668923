const validateArrayPermissions = (permissionsToValidate, userPermissions) => permissionsToValidate.some(
	permissao => userPermissions.indexOf(permissao) > -1,
);

/**
 * @param {array ou string} permissionsToValidate permissões para validação
 * @param {array} userPermissions permissões do usuário
 */
export const validatePermission = (permissionsToValidate, userPermissions) => {
	if (Array.isArray(permissionsToValidate)) {
		return validateArrayPermissions(permissionsToValidate, userPermissions);
	}
	return userPermissions.indexOf(permissionsToValidate) > -1;
};
