import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/es/Modal';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Table from '../../components/Table/table.component'
import { PERMISSAO } from '../../permissoes/permissoes';
import Utils from '../../utils/utils'
import ToastHelper from 'utils/toastHelper';

export default function PlanejamentoSafraItensModal(props) {
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [isEditar, setIsEditar] = useState(false);
	const [id, setId] = useState();
	const [unidade, setUnidade] = useState(null);
	const [recursoId, setRecursoId] = useState();
	const [recursoTipoId, setRecursoTipoId] = useState();
	const [recursoClasseId, setRecursoClasseId] = useState();
	const [recursosPlanejamento, setRecursosPlanejamento] = useState([]);
	const [recursosTemplate, setRecursosTemplate] = useState([]);
	const [quantidadePorHa, setQuantidadePorHa] = useState('');
	const [quantidadeTotal, setQuantidadeTotal] = useState('');
	const [quantidade, setQuantidade] = useState('');

	const actions = [
		{
			id: 1,
			name: 'Apagar',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_SAFRA_EXCLUIR,
			action: item => excluirRecursoDaTabela(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_SAFRA_EXCLUIR,
			action: item => editar(item),
		},
	];

	const columns = [
		{ id: 1, title: '', property: 'acao' },
		{ id: 2, title: 'Tipo', property: 'recursoTipo' },
		{ id: 3, title: 'Classe', property: 'recursoClasse' },
		{ id: 4, title: 'Nome', property: 'nome' },
		{ id: 5, title: 'Unidade', property: 'unidade' },
		{ id: 6, title: 'Quantidade/Ha', property: 'quantidadePorHa' },
		{ id: 7, title: 'Quantidade', property: 'quantidade' },
	];

	const columnsTemplate = [
		{ id: 1, title: '', property: 'acao' },
		{ id: 2, title: 'Tipo', property: 'recursoTipo' },
		{ id: 3, title: 'Classe', property: 'recursoClasse' },
		{ id: 4, title: 'Nome', property: 'nome' },
		{ id: 5, title: 'Quantidade/Ha', property: 'quantidade' },
	];

	useEffect(() => {
		if (props.show) {
			setRecursosPlanejamento([]);
			setRecursosTemplate([])
			if (props.recursos.length > 0) {
				let newList = []
				props.recursos.forEach(item => {
					let clone = Utils.clone(item);

					if (props.isPlanejamento) {
						clone.quantidadePorHa = (clone.quantidade / props.area).toFixed(2);
					} else {
						clone.unidade = undefined;
					}

					newList.push(clone)
				})
				if (props.isPlanejamento)
					setRecursosPlanejamento(newList)
				else
					setRecursosTemplate(newList)
			}
		}
	}, [props.show]);

	const editar = async value => {
		await setId(value.id);

		let recursoTipo = {
			label: value.recursoTipo,
			value: value.recursoTipoId,
			codigo: value.recursoTipoCodigo
		}
		await props.changeRecursoTipo(recursoTipo)
		await setRecursoTipoId(recursoTipo)

		let recursoClasse = {
			label: value.recursoClasse,
			value: value.recursoClasseId
		}
		await props.getRecursosPorClasseId(value.recursoClasseId)
		await setRecursoClasseId(recursoClasse)

		let recurso = {
			value: value.recursosId,
			label: value.nome
		};

		setValue('recursos', recurso);
		setValue('quantidade', value.quantidade === 0 ? undefined : value.quantidade);
		setValue('recursoTipo', { label: value.recursoTipo, value: value.recursoTipoId });
		setValue('recursoClasse', { label: value.recursoClasse, value: value.recursoClasseId });

		if (props.isPlanejamento) {
			setQuantidadePorHa(value.quantidadePorHa === 0 ? undefined : value.quantidadePorHa);
		}
		setQuantidade(value.quantidade === 0 ? undefined : value.quantidade);
		setQuantidadeTotal(value.quantidade === 0 ? undefined : value.quantidade);
		setUnidade(value.unidade);
		setRecursoId(recurso);

		value.acao = <div class="text-center"><i className="fas fa-pencil-alt" /></div>;;
		setIsEditar(true);
	}

	const submitForm = (value) => {
		if (!value.recursos || !value.recursos.value) {
			ToastHelper.error("Selecione algum recurso!");
			return;
		}

		if (!props.isPlanejamento && (value.quantidade === "" || value.quantidade === undefined)) {
			ToastHelper.error("O campo 'Quantidade' é obrigatório!");
			return;
		}

		if (props.isPlanejamento && (value.quantidadePorHa === "" || value.quantidadePorHa === undefined)) {
			ToastHelper.error("O campo 'Quantidade/Ha' é obrigatório!");
			return;
		}

		if (props.isPlanejamento && (value.quantidadeTotal === "" || value.quantidadeTotal === undefined)) {
			ToastHelper.error("O campo 'Quantidade Total' é obrigatório!");
			return;
		}
		if (props.isPlanejamento) {
			inserirOuAtualizarRecursoPlanejamento(value);
		} else {
			inserirOuAtualizarRecursoTemplate(value);
		}

		limparCampos();
	}

	const inserirOuAtualizarRecursoPlanejamento = (value) => {
		let recurso = null;
		if (isEditar) {
			recurso = recursosPlanejamento.find(item => item.id === id);
		} else {
			recurso = {};
			let newList = [...recursosPlanejamento];
			newList.push(recurso);
			setRecursosPlanejamento(newList);
		}

		recurso.nome = value.recursos.label;
		recurso.recursosId = value.recursos.value;
		recurso.recursoTipo = value.recursoTipo.label;
		recurso.recursoTipoCodigo = value.recursoTipo.codigo;
		recurso.recursoClasse = value.recursoClasse.label;
		recurso.planejamentoSafraId = props.id;
		recurso.unidade = unidade;
		recurso.quantidade = value.quantidadeTotal;
		recurso.quantidadePorHa = value.quantidadePorHa;
		recurso.acao = undefined;
	}

	const inserirOuAtualizarRecursoTemplate = (value) => {
		let recurso = null;
		if (isEditar) {
			recurso = recursosTemplate.find(item => item.id === id);
		} else {
			recurso = {};
			let newList = [...recursosTemplate];
			newList.push(recurso);
			setRecursosTemplate(newList);
		}

		recurso.nome = value.recursos.label;
		recurso.recursosId = value.recursos.value;
		recurso.recursoTipo = value.recursoTipo.label;
		recurso.recursoTipoCodigo = value.recursoTipo.codigo;
		recurso.recursoClasse = value.recursoClasse.label;
		recurso.quantidade = value.quantidade;
		recurso.perda = value.perda;
		recurso.acao = undefined;
	}

	const excluirRecursoDaTabela = async value => {
		if (props.isPlanejamento) {
			let list = [...recursosPlanejamento]
			var index = list.indexOf(value);
			list.splice(index, 1);
			setRecursosPlanejamento(list);
		} else {
			let list = [...recursosTemplate]
			var index2 = list.indexOf(value);
			list.splice(index2, 1);
			setRecursosTemplate(list);
		}
	}

	const salvar = () => {
		if (props.isPlanejamento) {
			props.modalClose(recursosPlanejamento);
			setRecursosPlanejamento([]);
		} else {
			let template = {
				recursos: recursosTemplate,
				templateId: props.id
			}

			props.modalClose(template);
			setRecursosTemplate([]);
		}
		fechar();
	}

	const fechar = () => {
		props.close();

		limparCampos();

		setRecursosPlanejamento([]);
		setRecursosTemplate([]);
	}

	const limparCampos = () => {
		setId();
		setRecursoTipoId();
		setRecursoClasseId();
		setQuantidadePorHa(null);
		setQuantidade(null);
		setQuantidadeTotal(null);
		setUnidade(null);
		setRecursoId(null);
		setIsEditar(false);
		setValue('recursoTipo', null);
		setValue('recursoClasse', null);
		setValue('quantidade', null);
		setValue('quantidadeTotal', null);
		setValue('quantidadePorHa', null);
		setValue('recursos', null);
	}

	const cancelar = () => {
		fechar();
	}

	const handleRecursosTipo = () => {
		setRecursoClasseId(null);
		setQuantidadePorHa(null);
		setQuantidade(null);
		setQuantidadeTotal(null);
		setValue('recursoClasse', null);
		setValue('quantidadeTotal', null);
		setValue('quantidadePorHa', null);
		setValue('recursos', null);
		setUnidade(null)
		setRecursoId(null)
	}

	const handleRecursoClasse = () => {
		setQuantidadePorHa(null);
		setQuantidade(null);
		setQuantidadeTotal(null);
		setValue('quantidadeTotal', null);
		setValue('quantidadePorHa', null);
		setValue('recursos', null);
		setUnidade(null)
		setRecursoId(null)
	}

	const handleQuantidade = (value, unidadeAlterada) => {
		if (unidadeAlterada === 'total') {
			let unidadePorHa = (value / props.area).toFixed(2)
			setQuantidadePorHa(unidadePorHa)
		} else if (unidadeAlterada === 'porHa') {
			let unidadeTotal = (value * props.area).toFixed(2)
			setQuantidadeTotal(unidadeTotal)
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<Modal
					show={props.show}
					dialogClassName="modal-dialog-lg"
					onHide={() => cancelar()}>
					<Modal.Body>
						<h3 className='mt-3' >{"Lista de recursos do serviço: " + (props.servicoSelecionado) + (props.localSelecionado ? " (Local: " + (props.localSelecionado) + ")" : "")}</h3>
						<div className='card-header row justify-content-start col-md-12' style={{ paddingTop: "initial" }}>
							<div className="card-header col-md-3" style={{ paddingTop: "initial" }}>
								<label className="label">
									Recursos Tipo:*
								</label>
								<Select
									id="recursosTipo"
									name="recursosTipo"
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									options={props.recursosTipo || []}
									onChange={recursos => {
										setValue('recursoTipo', recursos);
										setRecursoTipoId(recursos);
										props.changeRecursoTipo(recursos)
										setRecursoClasseId();
										setRecursoId();
										handleRecursosTipo();
									}}
									ref={() => register({ name: "recursoTipo" }, { required: false })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									value={recursoTipoId}
								/>
								{errors.recursoTipo && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="card-header col-md-3" style={{ paddingTop: "initial" }}>
								<label className="label">
									Recursos Classe:*
								</label>
								<Select
									id="recursoClasse"
									name="recursoClasse"
									placeholder='Selecionar'
									isDisabled={!recursoTipoId}
									className="select-style ajust-label-padrao disabled-select"
									onChange={element => {
										setValue('recursoClasse', element ? element : undefined);
										setRecursoClasseId(element);
										props.getRecursosPorClasseId(element.value)
										setRecursoId()
										handleRecursoClasse();
									}}
									options={props.recursosClasse}
									ref={() => register({ name: "recursoClasse" }, { required: false })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									value={recursoClasseId}
								/>
								{errors.recursoClasse && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="card-header col-md-4" style={{ paddingTop: "initial" }}>
								<label className="label">
									Recursos :*
								</label>
								<Select
									id="recursos"
									name="recursos"
									isDisabled={!recursoClasseId}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									options={props.recursosLista || []}
									onChange={recursos => {
										setValue('recursos', recursos);
										setUnidade(
											props.recursosLista.filter(item => item.value === recursos.value)[0] ?
												props.recursosLista.filter(item => item.value === recursos.value)[0].unidade.sigla :
												null
										)
										setRecursoId(recursos)
									}}
									ref={() => register({ name: "recursos" }, { required: false })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									value={recursoId}
								/>
								{errors.recursos && <span className="span-errors">Campo obrigatório</span>}
							</div>
							{
								props.isPlanejamento && <div className="card-header col-md-2 " style={{ paddingTop: "initial" }}>
									<label className="label">
										Área(ha):
								</label>
									<input
										name="area"
										className="form-control"
										autoComplete="off"
										disabled={true}
										value={props.area}
										type='number'
									/>
								</div>
							}

							{
								props.isPlanejamento &&

								<div className="card-header col-md-6" style={{ paddingTop: "initial" }}>
									<label className="label" htmlFor="quantidade">
										Quantidade ({unidade ? unidade + "/ha" : "unidade/ha"}):*
								</label>
									<input
										name="quantidadePorHa"
										placeholder='Digite a quantidade'
										className="form-control"
										autoComplete="off"
										disabled={!recursoId}
										onChange={item => {
											setQuantidadePorHa(item.target.value)
											handleQuantidade(item.target.value, 'porHa');
										}}
										type="number"
										step="any"
										value={quantidadePorHa}
										ref={register({ required: false })}
									/>
									{errors.quantidadePorHa && <span className="span-errors">Campo obrigatório</span>}
								</div>
							}
							{
								props.isPlanejamento &&

								<div className="card-header col-md-6" style={{ paddingTop: "initial" }}>
									<label className="label" htmlFor="quantidade">
										Quantidade total:*
								</label>
									<input
										name="quantidadeTotal"
										placeholder='Digite a quantidade'
										className="form-control"
										autoComplete="off"
										disabled={!recursoId}
										onChange={item => {
											setQuantidadeTotal(item.target.value)
											handleQuantidade(item.target.value, 'total');
										}}
										type="number"
										step="any"
										value={quantidadeTotal}
										ref={register({ required: true })}
									/>
									{errors.quantidadeTotal && <span className="span-errors">Campo obrigatório</span>}
								</div>
							}
							{
								!props.isPlanejamento &&
								<div className="card-header col-md-6" style={{ paddingTop: "initial" }}>
									<label className="label" htmlFor="quantidade">
										Quantidade:*
								</label>
									<input
										name="quantidade"
										placeholder='Selecionar'
										className="form-control"
										autoComplete="off"
										onChange={item => {
											setQuantidade(item.target.value)
										}}
										type="number"
										step="any"
										value={quantidade}
										ref={register({ required: false })}
									/>
									{errors.quantidade && <span className="span-errors">Campo obrigatório</span>}
								</div>
							}
						</div>
						<br />
						<div className="row col-md-12">
							<button className="btn btn-primary active centered" type="submit" onClick={handleSubmit(submitForm)}>
								<i className={isEditar ? "fas fa-pencil-alt" : "fas fa-plus"} /> {isEditar ? "Confirmar edição" : "Adicionar"}
							</button>
						</div>
						<br />
						<hr />
						{
							props.isPlanejamento && <Table
								align='left'
								data={recursosPlanejamento}
								emptyMessage="Nenhum recurso encontrado."
								emptyColSpan={columns ? columns.length + 1 : 1}
								columns={columns}
								actions={actions}
							></Table>
						}
						{
							!props.isPlanejamento && <Table
								align='left'
								data={recursosTemplate}
								emptyMessage="Nenhum recurso encontrado."
								emptyColSpan={columnsTemplate ? columnsTemplate.length + 1 : 1}
								columns={columnsTemplate}
								actions={actions}
							></Table>
						}

					</Modal.Body>
					<Modal.Footer className="footer-modal">
						<div className="row col-md-12">
							<div className="col-md-6">
								<button className="btn btn-outline-secondary pull-left" type="button" onClick={cancelar}>
									<i className='fas fa-close' /> Cancelar
								</button>
							</div>
							<div className="col-md-6">
								<button className="btn btn-primary active pull-right" type="submit" onClick={salvar}>
									<i className='fas fa-check' /> Salvar
								</button>
							</div>
						</div>
					</Modal.Footer>
				</Modal>
			</form>
		</>
	)
}
