import api from './api';

export default {

	buscarPorId(InsumosId) {
		return api.get(`/api/Insumos/BuscarPorId/${InsumosId}`);
	},

	buscarTodas(Insumos) {
		return api.post(`/api/Insumos/BuscarTodas`, Insumos);
	},

	editar(Insumos) {
		return api.put(`/api/Insumos/`, Insumos);
	},

	cadastrar(Insumos) {
		return api.post(`/api/Insumos/`, Insumos)
	},

	deletar(InsumosId) {
		return api.delete(`/api/Insumos/${InsumosId}`);
	},

	buscarPorTipo(fazendaId, materialTipoId) {
		return api.get(`/api/Insumos/GetByTipo/${fazendaId}/${materialTipoId}`);
	},
	ativaDesativa(id) {
		return api.get(`/api/Insumos/AtivaDesativa/${id}`);
	},
	getByGrupoInsumos(fazendaId, grupo) {
		return api.get(`/api/Insumos/GetByGrupoInsumos/${fazendaId}/${grupo}`)
	}
};
