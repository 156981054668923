import React, { useState, useEffect, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ModulosCreators } from "./store/planejamento_safra.ducks";
import { useForm } from "react-hook-form";
import Title from "../../components/Title/title.component";
import Select from "react-select";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import ObjectHelper from "../../utils/objectHelper";
import planejamentoSafraService from "../../services/planejamentoSafraService";
import DateHelper from "./../../utils/dateHelper";
import { Schema } from "./planejamento_safra.validation";
import Utils from "../../utils/utils";
import ToastHelper from "../../utils/toastHelper";
import TabelaRecursos from "../../components/TabelaRecursos/tabelaRecursos.component";
import safraService from "services/safraService";

function PlanejamentoSafraItens(props) {
	const { register, handleSubmit, errors, setValue, getValues } = useForm({
		validationSchema: Schema
	});

	const [inicio, setInicio] = useState(null);
	const [fim, setFim] = useState(null);
	const [valor, setValor] = useState([]);
	const {
		match: { params }
	} = props;
	const [visualizar, setVisualizar] = useState(false);
	const [recursos, setRecursos] = useState([]);

	const addRecurso = useCallback(async (item) => {
		setRecursos([...recursos, item]);
	});

	const editRecurso = useCallback(async (item) => {
		setRecursos([...item]);
	});

	const deleteRecurso = useCallback(async (item) => {
		setRecursos([...item]);
	});

	const validaData = values => {
		if (
			DateHelper.formatDate3(values.inicio) >
			DateHelper.formatDate3(values.fim)
		) {
			ToastHelper.error(
				"Data início não pode ser maior que a data final!"
			);
			return false;
		} else {
			return true;
		}
	};

	const setarUnidadesDeRecursos = (recursos) => {
		recursos.map(recurso => {
			if (recurso !== undefined) {
				let units = ObjectHelper.parseValuesUnitsToSelect(
					recurso.unidade
				);
				return recurso.unidades = units;
			} else {
				return recurso.unidades = null;
			}
		})
	}

	const submitForm = values => {
		if (validaData(values)) {
			values.fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;

			if (JSON.parse(localStorage.getItem("safraId")).length > 1 && getValues().safraId === undefined)
				return ToastHelper.error("O campo 'Atividades' é obrigatório!")

			if (JSON.parse(localStorage.getItem("safraId")).length === 1)
				values.safraId = ObjectHelper.primeiraSafra();
			else
				values.safraId = getValues().safraId

			values.inicio = DateHelper.formatDate3(values.inicio);
			values.fim = DateHelper.formatDate3(values.fim);

			if (params.id !== undefined) {
				values.id = params.id;
				values.recursos = recursos.map(recurso => {
					return {
						quantidade: recurso.quantidadeHa,
						recursosId: recurso.recurso.id,
						unidadeId: recurso.unidade.id,
						porcentagem: recurso.porcentagem
					};
				});
				props.updatePlanejamento(values, props.history);
			} else {
				values.localIds = valor.local.map(item => item.value);
				values.recursos = recursos.map(recurso => {
					return {
						quantidade: recurso.quantidadeHa,
						recursosId: recurso.recurso.id,
						unidadeId: recurso.unidade.id,
						porcentagem: recurso.porcentagem
					};
				});
				props.createPlanejamento(values, props.history)
			};
		}
	};

	useEffect(() => {
		if (localStorage.getItem("fazendaId")) {
			props.getGrupoServicos();
			getLocais(ObjectHelper.primeiraSafra())
			if (params.modo === "visualizar") setVisualizar(true);
		}
	}, []);

	const [locaisFiltro, setLocaisFiltro] = useState([]);
	const getLocais = async (safraId) => {

		let { data } = await safraService.locais(JSON.parse(
			localStorage.getItem("fazendaId")
		).value, safraId, true);


		let newLocais = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setLocaisFiltro(newLocais);
	};

	useEffect(() => {
		if (params.id !== undefined) {
			planejamentoSafraService.get(params.id).then(response => {
				let data = response.data.result
				let dataInicio = undefined;
				dataInicio = data.inicio.split("/");
				dataInicio = new Date(
					dataInicio[2],
					dataInicio[1] - 1,
					dataInicio[0]
				);
				let dataFim = undefined;
				dataFim = data.fim.split("/");
				dataFim = new Date(dataFim[2], dataFim[1] - 1, dataFim[0]);
				setValue("inicio", dataInicio);
				setValue("fim", dataFim);
				setValue("localId", data.local.value);
				setValue("safraId", data.safra.value);
				setValue("servicoTipoId", data.servicoTipo.value);
				setValue("servicoId", data.servico.value);
				setValor(data);
				let recursos = JSON.parse(JSON.stringify(data.recursos));
				setarUnidadesDeRecursos(recursos)
				setRecursos(recursos);

				props.getServicos(data.servicoTipo.value);
			});
		}
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="row">
					<div className="col">
						<Title>Cadastro de Atividade Prevista</Title>
					</div>
				</div>
				<div className="card">
					<div label="Filtros">
						<div
							className="card-body row justify-content-start"
							style={{ paddingTop: "inherit" }}
						>
							<div className="card-header col-md-4">
								<label className="label">Início:*</label>
								<DatePicker
									name="inicio"
									selected={inicio}
									placeholderText="Informe a data inicial"
									className="form-control bmd-form-group"
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									onChange={date => {
										setValue("inicio", date);
										setInicio(date);
										setValor({ ...valor, inicio: date });
									}}
									value={valor.inicio}
									ref={() => register({ name: "inicio" })}
									locale={pt}
									disabled={visualizar}
								/>
								{errors.inicio && (
									<span className="span-errors">
										{errors.inicio.message}
									</span>
								)}
							</div>

							<div className="card-header col-md-4">
								<label className="label">Fim:*</label>
								<DatePicker
									name="fim"
									disabled={visualizar}
									selected={fim}
									placeholderText="Informe a data final"
									className="form-control bmd-form-group"
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									onChange={date => {
										setValue("fim", date);
										setFim(date);
										setValor({ ...valor, fim: date });
									}}
									value={valor.fim}
									ref={() => register({ name: "fim" })}
									locale={pt}
								/>
								{errors.fim && (
									<span className="span-errors">
										{errors.fim.message}
									</span>
								)}
							</div>
							{/* </div>

						<div
							className="card-header row justify-content-start"
							style={{ marginTop: -35 }}
						> */}
							<div className="card-header col-md-4">
								<label
									className="label"
									htmlFor="servicoTipoId"
								>
									Grupo de Serviços :*
								</label>
								<Select
									id="servicoTipoId"
									name="servicoTipoId"
									placeholder="Selecionar"
									onChange={item => {
										setValue("servicoTipoId", item.value);
										setValue("servicoId", '');
										props.getServicos(item.value);
										setValor({
											...valor,
											servicoTipo: item,
											servico: ''
										});
									}}
									className="select-style ajust-label-padrao disabled-select"
									ref={() =>
										register({ name: "servicoTipoId" })
									}
									options={ObjectHelper.getValuesParseToSelect(
										props.grupoServicos.items
									)}
									theme={theme => ({
										...theme,
										borderRadius: 0
									})}
									value={valor.servicoTipo}
									isDisabled={visualizar}
								/>
								{errors.servicoTipoId && (
									<span className="span-errors">
										{errors.servicoTipoId.message}
									</span>
								)}
							</div>
							<div className="card-header col-md-4">
								<label className="label">Serviços :*</label>
								<Select
									id="servicoId"
									name="servicoId"
									htmlFor="servicoId"
									placeholder="Selecionar"
									className="select-style ajust-label-padrao disabled-select"
									options={
										ObjectHelper.getValuesParseToSelect(
											props.servicos
										) || []
									}
									onChange={item => {
										setValue("servicoId", item.value);
										setValor({ ...valor, servico: item });
									}}
									ref={() => register({ name: "servicoId" })}
									theme={theme => ({
										...theme,
										borderRadius: 0
									})}
									value={valor.servico}
									isDisabled={visualizar}
								/>
								{errors.servicoId && (
									<span className="span-errors">
										{errors.servicoId.message}
									</span>
								)}
							</div>
							{
								JSON.parse(localStorage.getItem('safraId')).length > 1 &&
								<div className="col-md-4 card-header ">
									<label className="label">Atividades:*</label>
									<Select
										value={valor.safra}
										ref={() => register({ name: "safraId" })}
										onChange={(item) => {
											getLocais(item.value);
											setValor({ ...valor, safra: item });
											setValue("safraId", item.value);
											setValue("localId", undefined);
											setValor({ ...valor, local: null });
										}}
										isDisabled={visualizar}
										className="select-style"
										placeholder={'Selecione uma safra'}
										options={JSON.parse(localStorage.getItem('safraId'))}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
										name="safras"
									/>
									{errors.safraId && (
										<span className="span-errors">
											{errors.safraId.message}
										</span>
									)}
								</div>

							}

							<div className="card-header col-md-4">
								<label className="label" htmlFor="localId">
									Locais :*
								</label>
								<Select
									id="localId"
									name="localId"
									placeholder="Selecionar"
									className="select-style ajust-label-padrao disabled-select"
									onChange={item => {
										setValue("localId", params.id !== undefined ? item.value : item);
										setValor({ ...valor, local: item });
									}}
									isMulti={params.id === undefined}
									ref={() => register({ name: "localId" })}
									options={locaisFiltro}
									theme={theme => ({
										...theme,
										borderRadius: 0
									})}
									value={valor.local}
									isDisabled={visualizar}
								/>
								{errors.localId && (
									<span className="span-errors">
										{errors.localId.message}
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="card-header col-md-12">
					<TabelaRecursos
						recursos={recursos}
						hideColunaLocal={true}
						hideColunaQuantidadeTotal={true}
						onAdd={addRecurso}
						onEdit={editRecurso}
						onDelete={deleteRecurso} />
				</div>
				<div className="col-md-12 mt-3">
					<button
						className="btn btn-outline-secondary pull-left"
						type="button"
						onClick={() => window.history.back()}
					>
						<i className="fas fa-close" /> Cancelar
					</button>
					{!visualizar && (
						<button
							className="btn btn-primary active pull-right"
							type="submit"
						>
							<i className="fas fa-check" /> Salvar
						</button>
					)}
				</div>
			</form>
		</>
	);
}

const mapStateToProps = state => ({
	...state,

	locais: state.planejamentoSafra.locais,
	grupoServicos: state.planejamentoSafra.grupoServicos,
	servicos: state.planejamentoSafra.servicos,
	recursos: Utils.removeInactiveToSelect(
		ObjectHelper.getValuesMaterialParseToSelect(
			state.planejamentoSafra.recursos.items
		)
	),
	planejamento: state.planejamentoSafra.planejamento
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlanejamentoSafraItens);
