import React, { useState, useCallback, useEffect } from 'react';
import { Creators as ModulosCreators } from '../store/ma.ducks';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateStartHourMeter, validateFinalHourMeter, validateTotalHourMeter } from '../ma.validate'

function formHourMeter(props) {

	const [hourTotal, setHourTotal] = useState()

	useEffect(() => {
		if (!props.params.id) {
			props.setForm({ ...props.form, startHourMeter: (props.selectedMachine.horimetroAtual > 0 ? props.selectedMachine.horimetroAtual : 0) })
		}
	}, [props.selectedMachine])

	const handleHours = useCallback((form, value) => {
		let newFor = { ...props.form }
		if (props.selectedMachine.horimetroAtivo) {

			let startHourMeter = form.startHourMeter || null;
			let finalHourMeter = form.finalHourMeter || null;

			if (form.startHourMeter !== '')
				startHourMeter = form.startHourMeter;

			if (form.finalHourMeter !== '')
				finalHourMeter = form.finalHourMeter;

			if (value.name === "startHourMeter") {
				startHourMeter = value.value.replace(',', '.');
			}

			if (value.name === "finalHourMeter") {
				finalHourMeter = value.value.replace(',', '.');
			}

			if (finalHourMeter !== null && startHourMeter !== null) {
				let totalHourMachineString = '';
				let hourMachineFinalDecimal = parseFloat(finalHourMeter) - parseFloat(startHourMeter)
				totalHourMachineString = convertHourToDecimal(hourMachineFinalDecimal);
				newFor.totalHourMeter = totalHourMachineString;
				setHourTotal(totalHourMachineString)
			}
		} else {

			let totalHourMeter = form.totalHourMeter || null;

			if (form.totalHourMeter !== '')
				totalHourMeter = form.totalHourMeter;

			if (value.name === "totalHourMeter") {
				totalHourMeter = value.value
			}
			newFor.totalHourMeter = totalHourMeter;
			setHourTotal(totalHourMeter)
		}
	})

	const labelHourMeter = useCallback(() => {
		if (props.selectedMachine.horimetroAtivo) {
			return (
				<div >
					<label>Horímetro máquina:*</label>
				</div>
			)
		} else {
			return (
				<div>
					<label>Horímetro máquina: (</label><label className="horimetro-desativado">Inativo</label><label>)</label>
				</div>
			)
		}
	})

	const convertHourToDecimal = useCallback((decimalTimeString) => {
		var hrs = parseInt(Number(decimalTimeString)).toString();
		if (hrs.length === 1) {
			hrs = '0' + hrs
		}

		var min = Math.round((Number(decimalTimeString) - hrs) * 60).toString();
		if (min.length === 1 && min !== '0') {
			min = '0' + min
		} else if (min.length === 1) {
			min = min + '0'
		}

		var totalHoursString = hrs + ':' + min;

		if (totalHoursString) {
			return totalHoursString;
		} else {
			return '00,00'
		}
	})

	const hourTotalChange = () => {
		let startHourMeter = props.form.startHourMeter || null;
		let finalHourMeter = props.form.finalHourMeter || null;

		if (finalHourMeter !== null && startHourMeter !== null)
			props.setForm({ ...props.form, totalHourMeter: hourTotal })
	}

	return (
		<>
			<div className="col-md-12 flex mt-3">
				<div className="col-md-6 flex card-externo-horas">
					<div className="col-md-12 flex label-horas">
						{labelHourMeter()}
					</div>
					<div className="col-md-12 flex card-border">
						<div className="col-md-6">
							<div className="col-md-12 label-horas">
								<label>Início:</label>
							</div>
							<div data-tip={
								(!props.selectedMachine.horimetroAtivo ?
									"Desabilitado, máquina com horimetro parado." :
									"Insira as horas do horimetro ao início do serviço. Valor maximo permitido 99999,9")}
								className={"col-md-12 card-interno-border " + (!props.selectedMachine.horimetroAtivo ? "disabled-input" : "")}>
								<input
									type='number'
									name="startHourMeter"
									disabled={!props.selectedMachine.horimetroAtivo || props.visualize}
									className="form-control"
									autoComplete="off"
									value={props.form.startHourMeter ? props.form.startHourMeter : ''}
									onBlur={() => {
										validateStartHourMeter(props.selectedMachine.horimetroAtivo, props.errorHourMeter, props.setErrorHourMeter, props.form);
										hourTotalChange()
									}}
									onChange={(e) => {
										handleHours(props.form, e ? e.target : '');
										props.setForm({ ...props.form, startHourMeter: e.target.value })
									}}
								/>
							</div>
							{(props.errorHourMeter ? props.errorHourMeter.startHourMeter : false) && (
								<span className="span-errors">
									{props.errorHourMeter.startHourMeter}
								</span>
							)}
						</div>
						<div className="col-md-6">
							<div className="col-md-12 label-horas">
								<label>Fim:</label>
							</div>
							<div data-tip={
								(!props.selectedMachine.horimetroAtivo ?
									"Desabilitado, máquina com horimetro parado." :
									"Insira as horas do horimetro ao início do serviço. Valor maximo permitido 99999,9")}
								className={"col-md-12 card-interno-border " + (!props.selectedMachine.horimetroAtivo ? "disabled-input" : "")}>
								<input
									type='number'
									name="finalHourMeter"
									disabled={!props.selectedMachine.horimetroAtivo || props.visualize}
									className="form-control"
									autoComplete="off"
									value={props.form.finalHourMeter ? props.form.finalHourMeter : ''}
									onBlur={() => {
										validateFinalHourMeter(props.selectedMachine.horimetroAtivo, props.errorHourMeter, props.setErrorHourMeter, props.form);
										hourTotalChange()
									}}
									onChange={(e) => {
										handleHours(props.form, e ? e.target : '');
										props.setForm({ ...props.form, finalHourMeter: e.target.value })
									}}
								/>
							</div>
							{(props.errorHourMeter ? props.errorHourMeter.finalHourMeter : false) && (
								<span className="span-errors">
									{props.errorHourMeter.finalHourMeter}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="col-md-3 flex card-externo-horas">
					<div className="col-md-12 flex label-horas">
						<label>{"Horas/Máquina:" + (props.selectedMachine.horimetroAtivo ? "" : "*")}</label>
					</div>
					<div className="col-md-12 flex card-border">
						<div className="col-md-12 ">
							<div className="col-md-12 label-horas">
								<label>Total:</label>
							</div>
							<div data-tip={(props.selectedMachine.horimetroAtivo ? "Cálculo de horas automático, máquina com horimetro funcionando." : "Insira as horas manualmente, máquina com horimetro parado.")}
								className={"col-md-12 card-interno-border " + (props.selectedMachine.horimetroAtivo ? "disabled-input" : "")}>
								<input
									type='time'
									name="totalHourMeter"
									disabled={props.selectedMachine.horimetroAtivo || props.visualize}
									className="form-control"
									autoComplete="off"
									value={props.form.totalHourMeter ? props.form.totalHourMeter : ''}
									onBlur={() => {
										validateTotalHourMeter(props.selectedMachine.horimetroAtivo, props.errorHourMeter, props.setErrorHourMeter, props.form);
									}}
									onChange={(e) =>
										props.setForm({ ...props.form, totalHourMeter: e.target.value })
									}
								/>
							</div>
							{(props.errorHourMeter ? props.errorHourMeter.totalHourMeter : false) && (
								<span className="span-errors">
									{props.errorHourMeter.totalHourMeter}
								</span>
							)}
							<ReactTooltip place="top" type="dark" delayShow={400} effect="float" />
						</div>
					</div>
				</div>
				<div className="col-md-3 flex card-externo-horas">
					<div className="col-md-12 flex label-horas">
						<label>Horas/Homem:*</label>
					</div>
					<div className="col-md-12 flex card-border">
						<div className="col-md-12 ">
							<div className="col-md-12 label-horas">
								<label>Total:</label>
							</div>
							<div className="col-md-12 card-interno-border">
								<input
									type='time'
									name="hourMan"
									disabled={props.visualize}
									className="form-control"
									autoComplete="off"
									value={props.form.hourMan}
									onChange={(e) => {
										props.setValue('hourMan', e);
										props.setForm({ ...props.form, hourMan: e.target.value })
									}}
									ref={() => props.register({ name: "hourMan" }, { required: true })}
								/>
							</div>
							{props.errors.hourMan && <span className="span-errors">Campo obrigatório</span>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(formHourMeter)
