import api from './api';

export default {
	getServicos(fazendaId){
		return api.get(`/api/Servico/${fazendaId}`);
	},
	salvar(param){
		return api.post('/api/Servico', param);
	},
	editar(param){
		return api.put('/api/Servico', param);
	},
	buscarPorId(id){
		return api.get(`/api/Servico/BuscarPorId/${id}`);
	},
	buscarTodas(param){
		return api.post('/api/Servico/BuscarTodas', param);
	},
	deletar(id){
		return api.delete('/api/Servico/' + id);
	},
	buscarPorGrupoId(fazendaId, grupoId){
		return api.get(`/api/Servico/${fazendaId}/${grupoId}`);
	},
	somatorioHorasServicoPeloDiaAtual(fazenda, safra){
		return api.get(`/api/Servico/SomatorioHorasServicoPeloDiaAtual/${fazenda}/${safra}`);
	},
	somatorioHorasPorSemana(fazenda, safra){
		return api.get(`/api/Servico/SomatorioHorasServicoPorSemanaAtual/${fazenda}/${safra}`);
	},
	somatorioHorasPeloMes(fazenda, safra){
		return api.get(`/api/Servico/SomatorioHorasServicoPorMesAtual/${fazenda}/${safra}`);
	},
	somatorioPorPeriodo(parametro){
		return api.post(`/api/Servico/SomatorioHorasServicoPorPeriodo`, parametro);
	},
	ativaDesativa(servicoId){
		return api.get(`/api/Servico/AtivaDesativa/${servicoId}`);
	}
};
