import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import {bindActionCreators} from 'redux';
import { Creators as ModulosCreators } from './store/material.duks';
import {connect} from 'react-redux';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Modal from '../../utils/modalHelper';
import Utils from '../../utils/utils';
import InsumoService from '../../services/materiaisService';
import ToastHelper from "../../utils/toastHelper";
import ModalAtivaDesativa from '../../utils/modalHelper';


function MaterialListing(props) {

    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [deleteItem, setDeleteItem] = useState({id:null, showModal:false})
    const [orderColumn, setOrderColumn] = useState()
    const [descending, setDescending] = useState(true)
    const [idInsumoAtivaDesativa, setIdInsumoAtivaDesativa] = useState();
    const [showModalAtivaDesativa, setShowModalAtivaDesativa] = useState();

    const paginateParams = {
        fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
        page: currentPage,
        pageSize: 10,
        filtro:'',
        descedescending: true,
        order: 'DataCriacao',
        unidadeId: '',
        familiaMateriais: '',
        nome: ''
    }

    const columns = [
        { id: 0, title: 'Nome',  property: 'nome' },
		{ id: 1, title: 'Código', property: 'codigo' },
        { id: 2, title: 'Grupo de Insumos',  property: 'grupoInsumos' },
        { id: 3, title: 'Unidade',  property: 'unidade.sigla' },
        { id: 4, title: 'Ativo', property: 'isAtivo'}
    ];

    const handleModalAtivaDesativa = (item) => {
        if(item) {
            setIdInsumoAtivaDesativa(item.id)
            setShowModalAtivaDesativa(!showModalAtivaDesativa)

        } else {
            setShowModalAtivaDesativa(!showModalAtivaDesativa)
        }
    }

    const ativaDesativa = async (item) => {
		let dados = await InsumoService.ativaDesativa(item);

		if(dados.status === 200) {
			ToastHelper.success("Status do insumo alterado com sucesso!");
			props.getMaterials(pagination)
			handleModalAtivaDesativa(null);
		} else {
			ToastHelper.error("Erro ao tentar alterar o status do insumo!");
		}
	};

    const orderTable = async (property, descending) => {
        await setOrderColumn(Utils.parsePropertToFilter(property));
        await setDescending(descending);
        let params = pagination;
        params.order = orderColumn;
        params.descedescending = descending;
        props.getMaterials(params)
    }

    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_INSUMOS_LEITURA,
            action: item => visualizer(item),
        },
        {
            id: 2,
            name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_INSUMOS_ATUALIZAR,
            action: item => edit(item)
        },
        {
            id: 3,
            name: 'Duplicar',
            icon: 'fas fa-copy',
            permissao: PERMISSAO.MODULO_INSUMOS_ATUALIZAR,
            action: item => duplicar(item)
        },
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
            permissao: PERMISSAO.MODULO_INSUMOS_ATUALIZAR,
			action: item => handleModalAtivaDesativa(item),
		},
        {
            id: 4,
            name: 'Excluir',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_INSUMOS_EXCLUIR,
            action: item => handleModalDelete(item),
        }
    ];

    const handleFilter = async (content) =>{
        let newPagination = pagination;
        newPagination.filtro = content.toLowerCase();
        newPagination.descedescending = true;
        newPagination.page = 1;
        setPagination(newPagination)
        props.getMaterials(newPagination)
    }

    const edit = (item) => {
        props.history.push({
            pathname: `/materiais/editar/${item.id}`
        });
    }

    const duplicar = (item) => {
        props.history.push({
            pathname: `/materiais/duplicar/${item.id}`
        });
    }

    const visualizer = (item) => {
        props.history.push({
            pathname: `/materiais/visualizar/${item.id}`
        });
    }

    const newMaterial = () => {
        props.history.push(`/materiais/cadastro`);
    }

    const handleModalDelete = (item) => {
        if(item) {
            setDeleteItem({id:item.id, showModal: !deleteItem.showModal})
        } else {
            setDeleteItem({id: null, showModal: !deleteItem.showModal})
        }
	}

    useEffect( () => {
        if(localStorage.getItem('fazendaId')){
            props.getMaterials(pagination)
        }
    }, []);

    useEffect(()=>{
		if(props.materials.items) initPagination()
    },[props.materials])

    const [pagination, setPagination] = useState(paginateParams)

	const initPagination = () => {
        setTotal(props.materials.total);
		setPage(props.materials.page);
    }

    const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
        props.getMaterials(pagination)
    }

    const deleteMaterial = () => {
        props.deleteMaterial(deleteItem.id, props)
        let newPagination = pagination
        newPagination.filtro = ""
        setTimeout(function(){
            props.getMaterials(newPagination)
            setDeleteItem({showModal: false})
        }, 800)
    }

    return (
        <>
            <Title
                acao="Novo Insumo"
                permissaoBotao={PERMISSAO.MODULO_INSUMOS_CRIAR}
                iconBotao="fas fa-plus"
                onClick={newMaterial}>
                    Insumos
            </Title>
            <CardStyle className="card">
                <SimpleSearch
                    placeHolder="Pesquisar"
                    value={pagination.filtro}
                    submit={(e) => handleFilter(e)}
                />
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={props.materials.items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
					orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
                />
			</CardStyle>
            <Modal
                show={deleteItem.showModal}
                doubleButtons={true}
                title={"Excluir Insumo"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                submit={deleteMaterial}
                close={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                content={
                    <h6>{"Deseja realmente excluir esse insumo?"}</h6>
                }
            />
            <ModalAtivaDesativa
                show={showModalAtivaDesativa}
                title={'Ativar / Desativar Insumo'}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => handleModalAtivaDesativa(null)}
                submit={() => ativaDesativa(idInsumoAtivaDesativa)}
                close={() => handleModalAtivaDesativa(null)}
                content={
                    <h6>{'Deseja realmente alterar o status deste insumo?'}</h6>
                }
            />
        </>
    )
}

const mapStateToProps = state =>{
	return ({
        materials: state.materials.materials
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MaterialListing)
