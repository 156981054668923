import React from 'react';
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import InsumoAnaliseSoloService from "../../../services/insumoAnaliseSoloService"
import { Types } from "./insumo_analise_solo.ducks";
import executeAction from "../../../store/sagas/executeAction";
import ToastHelper from "../../../utils/toastHelper";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 10,
	filtro: "",
	order: ""
}

function* getByIdInsumoAnaliseSolo(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(async () => await InsumoAnaliseSoloService.buscarPorId(params));

		yield put({
			type: Types.SET_GET_BY_ID_INSUMO_ANALISE_SOLO,
			data: data
		});
		callback();
	}, Types.GET_BY_ID_INSUMO_ANALISE_SOLO);
}

function* getInsumoAnaliseSolo(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(async () => await InsumoAnaliseSoloService.buscarTodas(params ? params : parametros));

		if (data.result) {
			data.result.items.forEach(item => {
				if (item.ativo) {
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});
		}

		yield put({ type: Types.READ_INSUMO_ANALISE_SOLO, data: data.result });
		callback();
	}, Types.READ_INSUMO_ANALISE_SOLO);
}

function* createInsumoAnaliseSolo(action) {
	yield executeAction(function* () {

		const { params, history, callback } = action;
		const { data } = yield call(async () => await InsumoAnaliseSoloService.cadastrar(params));
		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!")
			history.goBack()
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.POST_INSUMO_ANALISE_SOLO, data: data });
		callback();
	}, Types.CREATE_INSUMO_ANALISE_SOLO);
}

function* updateInsumoAnaliseSolo(action) {
	yield executeAction(function* () {

		const { params, history } = action;
		const { data } = yield call(async () => await InsumoAnaliseSoloService.editar(params));
		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!")
			history.goBack()
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.UPDATE_INSUMO_ANALISE_SOLO,
			data
		});
	}, Types.UPDATE_INSUMO_ANALISE_SOLO);
}

function* deleteInsumoAnaliseSolo(action) {
	yield executeAction(function* () {

		const { params, props, callback } = action;
		const { data } = yield call(async () => await InsumoAnaliseSoloService.deletar(params));
		if (data.isOk) {
			ToastHelper.success('Operação realizada com sucesso!');
			props.gets(parametros)
		}
		else
			ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
		callback();
	}, Types.DELETE_INSUMO_ANALISE_SOLO);
}

function* takeUpdateInsumoAnaliseSolo() {
	yield takeLatest(Types.UPDATE_INSUMO_ANALISE_SOLO, updateInsumoAnaliseSolo);
}

function* takeGetByIdInsumoAnaliseSolo() {
	yield takeLatest(Types.GET_BY_ID_INSUMO_ANALISE_SOLO, getByIdInsumoAnaliseSolo);
}

function* takeGetInsumoAnaliseSolo() {
	yield takeLatest(Types.GET_INSUMO_ANALISE_SOLO, getInsumoAnaliseSolo);
}

function* takeCreateInsumoAnaliseSolo() {
	yield takeLatest(Types.CREATE_INSUMO_ANALISE_SOLO, createInsumoAnaliseSolo);
}

function* takeDeleteInsumoAnaliseSolo() {
	yield takeLatest([Types.DELETE_INSUMO_ANALISE_SOLO], deleteInsumoAnaliseSolo);
}

export default function* root() {
	yield all([fork(takeGetByIdInsumoAnaliseSolo)])
	yield all([fork(takeGetInsumoAnaliseSolo)])
	yield all([fork(takeCreateInsumoAnaliseSolo)])
	yield all([fork(takeUpdateInsumoAnaliseSolo)])
	yield all([fork(takeDeleteInsumoAnaliseSolo)])
}
