import React, { Component } from "react";
import { connect } from "react-redux";
import ToastHelper from "../../../utils/toastHelper";
import { removeToken } from "../../../services/auth";
import { LoadingStyle } from "./layout.style";
import Menu from '../../../components/Menu/menu'
import './layout.container.scss'


const mapStateToProps = state => ({
	loading: state.loading
});

class LayoutContainer extends Component {
	constructor(props) {
		super(props);
		const { history } = this.props;
		
		this.history = history;
		this.state = {
			containerToggle: "container-fluid toggle-deactive"
		}
		
	}
	componentDidMount() {
		ToastHelper.create();
	}

	logout() {
		removeToken();
		window.location.href = "/";
	}

	activeToggle(param) {
		if(param === true)
			this.setState({containerToggle: "container-fluid toggle-active"})
		else
			this.setState({containerToggle: "container-fluid toggle-deactive"})
	}

	render() {
		const { children, loading, history, fazendas } = this.props;
		return (
			<>
				<Menu teste={this.activeToggle.bind(this)} history={history} fazendas={fazendas}></Menu>
				<div className={this.state.containerToggle}>
					{children}
				</div>
				{loading.loading && (
					<LoadingStyle className="loading">
						<div className="text-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</LoadingStyle>
				)}
			</>
		);
	}
}

export default connect(
	mapStateToProps,
	null
)(LayoutContainer);
