import React, { useState, useEffect } from 'react';
import ObjectHelper from 'utils/objectHelper';
import { CardStyle } from "components/CardStyle/cardstyle.component"
import Title from 'components/Title/title.component';
import Select from "react-select";
import { Table } from 'reactstrap'
import Pagination from 'components/Pagination/pagination.component';
import planejamentoSafraService from 'services/planejamentoSafraService';
import grupoServicoService from 'services/grupoServicoService';
import servicoService from 'services/servicoService';
import recursosService from 'services/recursosService';
import recursosTipoService from 'services/recursosTipoService';
import MaquinaTipoService from 'services/maquinaService'
import FamiliaMaterialService from 'services/familiaMaterialService'
import PessoaTipoService from 'services/categoriaService'
import RevisaoRecursosFiltros from './revisao_recursos.filtro'
import localService from 'services/localService'
import Modal from 'utils/modalHelper'
import ToastHelper from 'utils/toastHelper';

export default function RevisaoRecursos(props) {

	const [recursos, setrecursos] = useState([]);
	const [showModal, setshowModal] = useState(false);
	const [foiEditado, setfoiEditado] = useState(false);
	const [revisaoRecursos, setRevisaoRecursos] = useState([]);
	const [grupoServicos, setgrupoServicos] = useState([]);
	const [servicos, setservicos] = useState([]);
	const [recursosTipo, setrecursosTipo] = useState([]);
	const [recursosClasse, setrecursosClasse] = useState([]);
	const [local, setlocal] = useState([]);
	const [currentPage, setcurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, settotalPages] = useState(1);
	const [filtro, setFiltro] = useState({
		safraId: JSON.parse(localStorage.getItem('safraId')).value,
		page: currentPage,
		pageSize: pageSize
	});
	const [recursosModificados, setrecursosModificados] = useState([]);

	useEffect(() => {
		async function fetchData() {
			let { data } = await planejamentoSafraService.revisaoRecursos({
				safraId: JSON.parse(localStorage.getItem('safraId')).value,
				page: currentPage,
				pageSize: pageSize
			});

			const parametros = {
				page: 1,
				pageSize: 1000,
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value
			}

			let grupoServicos = await grupoServicoService.buscarTodos(parametros)
			let servicos = await servicoService.buscarTodas(parametros);
			let recursos = await recursosService.buscarTodas(parametros);
			let recursosTipo = await recursosTipoService.get(parametros.fazendaId);
			let local = await localService.buscarTodos(parametros);

			if (data.isOk) {
				await setRevisaoRecursos(data.result.items);
				await settotalPages(data.result.page)
			}


			if (local.data.isOk)
				await setlocal(ObjectHelper.getValuesParseToSelect(local.data.result.items, 'id', 'nome'));

			if (grupoServicos.data.isOk)
				await setgrupoServicos(ObjectHelper.getValuesParseToSelect(grupoServicos.data.result.items));

			if (servicos.data.isOk)
				await setservicos(ObjectHelper.getValuesParseToSelect(servicos.data.result.items));

			if (recursos.data.isOk)
				await setrecursos(ObjectHelper.getValuesParseToSelect(recursos.data.result.items));

			if (recursosTipo.data.isOk)
				await setrecursosTipo(ObjectHelper.getRecursoTipoParseToSelect(recursosTipo.data.result));
		}
		fetchData();
	}, []);

	const filtrar = async filtro => {
		if (foiEditado) {
			setshowModal(true);
			return;
		}

		filtro.page = currentPage
		filtro.pageSize = pageSize
		filtro.safraId = JSON.parse(localStorage.getItem('safraId')).value

		setFiltro(filtro);
		let { data } = await planejamentoSafraService.revisaoRecursos(filtro);
		if (data.isOk) {
			await setRevisaoRecursos(data.result.items);
			await settotalPages(data.result.page)
		}
	}

	const onChangePage = async page => {
		var param = filtro;
		param.page = page;
		param.pageSize = pageSize;
		param.safraId = JSON.parse(localStorage.getItem('safraId')).value
		let { data } = await planejamentoSafraService.revisaoRecursos(param);
		if (data.isOk) {
			await setRevisaoRecursos(data.result.items);
			await settotalPages(data.result.page)
			await setcurrentPage(page)
		}
	}

	const changePageSize = async pageSize => {
		if (foiEditado) {
			setshowModal(true);
			return;
		}
		var param = filtro;
		param.page = 1;
		param.pageSize = pageSize;
		param.safraId = JSON.parse(localStorage.getItem('safraId')).value;
		let { data } = await planejamentoSafraService.revisaoRecursos(param);
		if (data.isOk) {
			await setRevisaoRecursos(data.result.items);
			await settotalPages(data.result.page);
			await setcurrentPage(1);
			await setPageSize(pageSize)
		}
	}

	const changeRecursoTipo = async (e) => {
		if (e) {
			let param = {
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				page: 1,
				pageSize: 1000
			}

			let data = null;

			if (e.codigo === "MA") data = await MaquinaTipoService.buscarTodas(param);
			if (e.codigo === "IN" || e.codigo === "RE") data = await FamiliaMaterialService.buscarTodas(param);
			if (e.codigo === "MO") data = await PessoaTipoService.buscarTodas(param);

			if (data) {
				setrecursosClasse(ObjectHelper.getValuesParseToSelect(data.data.result.items));
			} else {
				setrecursosClasse(ObjectHelper.getValuesParseToSelect([]));
			}
		}
	}

	const onChange = (data, item, index, tipo) => {
		setfoiEditado(true);
		let id = item.id;
		let unidade = item.unidade;
		let qntdTotal = item.quantidadeTotal;
		let qndtHA = item.quantidadeHa
		if (tipo === "unidade") {
			unidade = data;
			setRevisaoRecursos(revisaoRecursos.map((o) => {
				if (o.id === item.id) return { ...item, unidade: data }
				return o;
			}));
		} else if (tipo === "qntdHA") {
			qndtHA = data;
			qntdTotal = data * item.areaHa
			setRevisaoRecursos(revisaoRecursos.map((o) => {
				if (o.id === item.id) return { ...item, quantidadeHa: data, quantidadeTotal: qntdTotal }
				return o;
			}));
		} else if (tipo === "qntdTotal") {
			qntdTotal = data;
			qndtHA = qntdTotal / item.areaHa
			setRevisaoRecursos(revisaoRecursos.map((o) => {
				if (o.id === item.id) return { ...item, quantidadeHa: qndtHA, quantidadeTotal: data }
				return o;
			}));
		}

		let modificados = recursosModificados;
		let recursoEncontrado = modificados.find(item => item.id === id);
		if (recursoEncontrado !== null && recursoEncontrado !== undefined) {
			modificados.forEach(item => {
				if (item.id === id) {
					item.unidade = unidade
					item.unidadeId = unidade.value
					item.quantidadeHa = qndtHA
					item.quantidadeTotal = qntdTotal
				}
			});
		} else {
			modificados.push({
				id: item.id,
				unidade: unidade,
				unidadeId: unidade.value,
				quantidadeHa: qndtHA,
				quantidadeTotal: qntdTotal
			});
		}

		setrecursosModificados(modificados);

		return;
	};

	const salvar = async () => {
		let { data } = await planejamentoSafraService.updatePlanejamentoSafraRecursos(recursosModificados);
		if (data.isOk) {
			return ToastHelper.success("Operação realizada com sucesso!")
		} else {
			return ToastHelper.error(data.mensagem)
		}
	}

	const handleModal = async () => {
		salvar();
		setshowModal(false);
		setfoiEditado(false);
	}

	const handleModalClose = () => {
		setshowModal(false);
		setfoiEditado(false);
	}

	return (
		<>
			<Title >
				Revisão de Recursos
			</Title>
			<CardStyle className="card">
				<div className="col-md-12">
					<hr></hr>
					<RevisaoRecursosFiltros
						servicos={servicos}
						grupoServicos={grupoServicos}
						recursos={recursos}
						recursosTipo={recursosTipo}
						local={local}
						recursosClasse={recursosClasse}
						changeRecursoTipo={changeRecursoTipo}
						filtrar={filtrar}
					></RevisaoRecursosFiltros>
					<hr></hr>
					<div className="row">
						<div className="col-md-10">
							<button
								style={{ marginTop: 22 }}
								className="btn btn-primary active pull-right"
								type="button"
								onClick={salvar}>
								<i class="fas fa-save"></i> Salvar Alterações
							</button>
						</div>
						<div className="col-md-2 pull-right">
							<strong style={{ fontSize: 15 }}>Itens por página:</strong>
							<Select
								placeholder='Selecionar'
								className=""
								options={[
									{ label: 10, value: 10 },
									{ label: 20, value: 20 },
									{ label: 50, value: 50 },
									{ label: 100, value: 100 }]}
								onChange={item => changePageSize(item.value)}
								theme={theme => ({
									...theme,
									borderRadius: 5, width: 30, float: "right", border: '1px solid #f1f1f1'
								})}
								value={{ label: pageSize, value: pageSize }}
							/>
							<br />
						</div>
					</div>
					<hr></hr>
					<div className='row'>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Grupo de Serviços</th>
									<th>Serviços</th>
									<th>Local</th>
									<th>Tipo</th>
									<th>Classe</th>
									<th>Nome</th>
									<th>Unidade</th>
									<th>Qtd Ha</th>
									<th>Qtd Total</th>
								</tr>
							</thead>
							<tbody>
								{
									revisaoRecursos !== undefined &&
									revisaoRecursos.length > 0 &&
									revisaoRecursos.map((item, index) => {
										return <>
											<tr>
												<td>{item.servicoTipo}</td>
												<td>{item.servico}</td>
												<td>{item.local}</td>
												<td>{item.tipo}</td>
												<td>{item.classe}</td>
												<td>{item.nome}</td>
												<td>{item.unidade.label}</td>
												<td>
													<input
														style={{ width: 80, backgroundColor: 'white' }}
														type="number"
														onChange={e => onChange(e.target.value, item, index, "qntdHA")}
														className="form-control bmd-form-group select-style ajust-label-padrao"
														value={item.quantidadeHa}
													/>
												</td>
												<td >
													<input
														style={{ width: 80, backgroundColor: 'white' }}
														type="number"
														onChange={e => onChange(e.target.value, item, index, "qntdTotal")}
														className="form-control bmd-form-group select-style ajust-label-padrao"
														value={item.quantidadeTotal}
													/>
												</td>
											</tr>
										</>
									})
								}

							</tbody>
						</Table>
						<Pagination
							onChangePage={onChangePage}
							totalPages={totalPages}
							currentPage={currentPage}
						/>
						<Modal
							show={showModal}
							doubleButtons={true}
							title={"Atenção!"}
							buttonTitle={"Sim"}
							button2Title={"Não"}
							buttom2Action={handleModal}
							submit={handleModal}
							close={handleModalClose}
							content={
								<div>
									<h6>{"Suas alterações não foram salvas e serão perdidas ao atualizar os dados. Deseja salvar as modificações antes de prosseguir?"}</h6>
								</div>
							}
						/>
					</div>
				</div>

			</CardStyle>


		</>
	)
}
