import React from 'react';
import biService from 'services/biService'
import YouTube from 'react-youtube';

class Videos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			videos: []
		}
		this.history = this.props.history;
	}

	async componentDidMount() {
		const { data } = await biService.buscarVideos();
		this.setState({
			videos: data.result
		});
	}
	_onReady(event) {
		// access to player in all event handlers via event.target
		event.target.pauseVideo();
	}

	render() {
		return (
			<div >
				{
					this.state.videos.map((item, index) => {
						return <>
							<h2>{item[0].agrupador}</h2>
							<div className="row">
								<br></br>

								{item.map((x) => {
									return <>
										<div className="col-md-4 mx-3" >
											<p>{x.titulo}</p>
											<YouTube
												videoId={x.url.split("/")[3]}
												onReady={this._onReady} />
										</div>
									</>
								})}
							</div>
							<hr></hr>
						</>
					})
				}
			</div>
		)
	}
}

export default Videos;
