import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import AppointmentEntrysService from "../../../../services/apontamentoInsumosService";
import { Types } from "./entry.appointment.ducks";
import executeAction from "../../../../store/sagas/executeAction";
import ToastHelper from "../../../../utils/toastHelper";
import PersonService from "../../../../services/pessoaService";
import ServiceGroupService from "../../../../services/grupoServicoService";
import ServicesService from "../../../../services/servicoService";
import FamilyMaterialService from "../../../../services/familiaMaterialService";
import ObjectHelper from "utils/objectHelper";
const parametros = {
	fazendaId:
		JSON.parse(localStorage.getItem("fazendaId")) !== null
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: null,
	page: 1,
	pageSize: 99999,
	order: "Nome"
};

function* asyncListAppointmentEntrys(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield AppointmentEntrysService.findAll(params);

		// dados.data.result.items.forEach(item => {
		// 	item.data = item.data.split(" ")[0];
		// });

		yield put({
			type: Types.SET_APPOINTMENT_ENTRYS,
			data: data.result
		});
		callback();
	}, Types.GET_APPOINTMENT_ENTRYS);
}

function* deleteAppointmentEntry(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield AppointmentEntrysService.deletar(params);
		if (data.isOk) ToastHelper.success("Apontamento excluído com sucesso!");
		else ToastHelper.error("Ocorreu um erro.");
		callback();
	}, Types.DELETE_APPOINTMENT_ENTRY);
}

function* asyncListResponsible(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield PersonService.buscar(parametros);

		yield put({ type: Types.SET_RESPONSIBLE, data: data.result });
		callback();
	}, Types.GET_RESPONSIBLE);
}

function* asyncListServiceGroups(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield ServiceGroupService.buscarTodos(parametros);

		yield put({ type: Types.SET_SERVICE_GROUPS, data: data.result });
		callback();
	}, Types.GET_SERVICE_GROUPS);
}

function* asyncListServicesByGroupId(action) {
	yield executeAction(function* () {
		const { params, callback } = action;

		const { data } = yield ServicesService.buscarPorGrupoId(
			params.farmId,
			params.groupServiceId
		);

		yield put({ type: Types.SET_SERVICES_BY_GROUP_ID, data: data.result });
		callback();
	}, Types.GET_SERVICES_BY_GROUP_ID);
}

function* asyncListFamilyMaterials(action) {
	yield executeAction(function* () {
		const { callback } = action;
		const { data } = yield FamilyMaterialService.buscarTodas({
			fazendaId:
				JSON.parse(localStorage.getItem("fazendaId")) !== null
					? JSON.parse(localStorage.getItem("fazendaId")).value
					: null,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});
		yield put({ type: Types.SET_FAMILY_MATERIALS, data: data.result });
		callback();
	}, Types.GET_FAMILY_MATERIALS);
}

function* asyncCreateEntryAppointment(action) {
	yield executeAction(function* () {
		const { params, history, callback } = action;
		let apontamentos = [
			{
				data: params.date,
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				safraId: ObjectHelper.primeiraSafra(),
				descricao: params.description,
				familiaMateriaisId: params.familyMaterial,
				localId: params.location,
				insumosId: params.material,
				quantidade: params.amount,
				responsavelId: params.responsible,
				servicoId: params.service,
				servicoTipoId: params.serviceGroup,
				unidadeId: params.unity,
				id: params.id
			}
		];
		const { data } = yield AppointmentEntrysService.salvar({
			apontamentos
		});
		if (data.isOk) {
			ToastHelper.success("Apontamento criado com sucesso!");
			history.goBack();
		} else {
			ToastHelper.error(
				data.mensagem ? data.mensagem : "Ocorreu um erro."
			);
		}
		yield put({ type: Types.POST_ENTRY_APPOINTMENT, data: data });
		callback();
	}, Types.CREATE_ENTRY_APPOINTMENT);
}

function* getEntryAppointmentById(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield AppointmentEntrysService.buscarPorId(params);
		yield put({
			type: Types.SET_ENTRY_APPOINTMENT_BY_ID,
			data: data
		});
		callback();
	}, Types.GET_ENTRY_APPOINTMENT_BY_ID);
}

function* updateEntryAppointment(action) {
	yield executeAction(function* () {
		const { params, history } = action;
		if (params) {
			const { data } = yield call(
				async () => await AppointmentEntrysService.editar(params)
			);

			if (data.isOk) {
				ToastHelper.success("Apontamento atualizado com sucesso!");
				history.goBack();
			} else {
				ToastHelper.error(
					data.mensagem ? data.mensagem : "Ocorreu um erro."
				);
			}
			yield put({
				type: Types.UPDATE_ENTRY_APPOINTMENT,
				data
			});
		}
	}, Types.UPDATE_ENTRY_APPOINTMENT);
}

function* takeListAppointmentEntrys() {
	yield takeLatest(Types.GET_APPOINTMENT_ENTRYS, asyncListAppointmentEntrys);
}

function* takeCreateEntryAppointment() {
	yield takeLatest(
		Types.CREATE_ENTRY_APPOINTMENT,
		asyncCreateEntryAppointment
	);
}

function* takeDeleteAppointmentEntry() {
	yield takeLatest([Types.DELETE_APPOINTMENT_ENTRY], deleteAppointmentEntry);
}

function* takeListResponsible() {
	yield takeLatest(Types.GET_RESPONSIBLE, asyncListResponsible);
}

function* takeListServiceGroups() {
	yield takeLatest(Types.GET_SERVICE_GROUPS, asyncListServiceGroups);
}

function* takeListServicesByGroupId() {
	yield takeLatest(
		Types.GET_SERVICES_BY_GROUP_ID,
		asyncListServicesByGroupId
	);
}

function* takeListFamilyMaterials() {
	yield takeLatest(Types.GET_FAMILY_MATERIALS, asyncListFamilyMaterials);
}

function* takeGetEntryAppointmentById() {
	yield takeLatest(
		Types.GET_ENTRY_APPOINTMENT_BY_ID,
		getEntryAppointmentById
	);
}

function* takeUpdateEntryAppointment() {
	yield takeLatest([Types.UPDATE_ENTRY_APPOINTMENT], updateEntryAppointment);
}

export default function* root() {
	yield all([fork(takeListAppointmentEntrys)]);
	yield all([fork(takeDeleteAppointmentEntry)]);
	yield all([fork(takeListResponsible)]);
	yield all([fork(takeListServiceGroups)]);
	yield all([fork(takeListServicesByGroupId)]);
	yield all([fork(takeListFamilyMaterials)]);
	yield all([fork(takeCreateEntryAppointment)]);
	yield all([fork(takeGetEntryAppointmentById)]);
	yield all([fork(takeUpdateEntryAppointment)]);
}
