import React from 'react';
import Modal from 'react-bootstrap/es/Modal';
import MaterialTable from "material-table";
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";


const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});

export default function ModalVisualizacao(props) {
	const close = () => {
		props.close()
	}

	return (
		<>
			<Modal dialogClassName="modal-dialog-lg" show={props.show} onHide={() => props.close()}>
				<Modal.Header closeButton >
				</Modal.Header>
				<Modal.Body>
					<ThemeProvider theme={theme}>
						<MaterialTable
							title={"Elementos da análise da planilha - " + props.row.nomeArquivo}
							columns={props.coluna}
							data={props.items}
							options={{
								paging: false,
								search: true,
								actionsColumnIndex: -1,
							}}
							localization={{
								header: {
									actions: 'Ações'
								},
								body: {
									emptyDataSourceMessage:
										'Nenhum registro para exibir',
									addTooltip: 'Adicionar',
									deleteTooltip: 'Baixar',
									editTooltip: 'Edição rápida',
								},
							}}
						/>
					</ThemeProvider>

				</Modal.Body>
				<Modal.Footer className="footer-modal-left" style={{ float: 'left !important' }}>
					<div className="row">
						<hr />
						<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => close()} style={{ width: 120, height: 40 }}>
							<i className='fas fa-close' /> Fechar
					</button>
					</div>
				</Modal.Footer>

			</Modal>
		</>
	)
}
