import React, { useState, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { useForm } from 'react-hook-form';
import Title from "../../../components/Title/title.component"
import Select from 'react-select';
import InputMask from 'react-input-mask';
import apontamentoColaboradorService from '../../../services/apontamentoColaboradorService'
import { Button, ButtonGroup } from 'reactstrap';

export default function HistogramaFiltro(props) {
	const { register, handleSubmit, setValue } = useForm({});
	const [inicio, setInicio] = useState(null);
	const [fim, setFim] = useState(null);
	const [opcao, setOpcao] = useState(true)


	useEffect(() => {

		setOpcao(props.opcao)

		if (!opcao) {
			async function fetchData() {
				var data = new Date()
				var dia = data.getDate() - 6;
				var mes = data.getMonth() + 1;
				var ano = data.getFullYear().toString();

				if (mes < 10)
					mes = "0" + mes;

				await setInicio(`${dia}/${mes}/${ano}`);
				await setFim("");
			}
			fetchData();
		} else {
			setInicio("");
			setFim("");
		}
	});

	const submitForm = values => {
		values.inicio = inicio;
		values.fim = fim
		props.filtrar(values)
	};

	const atualizar = async () => {
		const { data } = await apontamentoColaboradorService.atualizarPainel();
		props.buscarMO();
		return data;
	}

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>Painel dos Aponetamentos da Fazenda {JSON.parse(localStorage.getItem('fazendaId')).label}</Title>
					</div>
				</div>
				<div className="card">
					<div label='Filtros'>
						<div className='card-body row justify-content-start' style={{ paddingTop: "inherit" }}>
							<div className="col-md-12">
								<hr></hr>
								<span style={{ fontSize: 18, margin: 10, fontWeight: 500 }}>Filtros</span>
								<ButtonGroup style={{ margin: 5 }}>
									<Button
										outline color="success"
										className={"btn-outline-success-custom " + (props.opcao ? `btn-painel-ativo ` : "")}
										onClick={() => props.setarOpcao(true)}
									>
										Resumo
								</Button>
									<Button
										outline color="success"
										className={"btn-outline-success-custom " + (!props.opcao ? `btn-painel-ativo` : "")}
										onClick={() => props.setarOpcao(false)}
									>
										Semanal
								</Button>
								</ButtonGroup>
								<hr></hr>
							</div>
							<div className="row col-md-12">
								<div className="card-header col-md-3">
									<label className="label">
										{
											props.opcao ? "Período Inicial: " : "Prieiro dia da Semana:"
										}
									</label>
									<InputMask
										onChange={e => setInicio(e.target.value)}
										className='form-control bmd-form-group'
										name="inicio"
										mask="99/99/9999"
										maskChar={null}
										ref={() => register({ name: "inicio" })}
										placeholder="Digite aqui"
										autoComplete="off"
										value={inicio}
									/>
								</div>
								{
									props.opcao && <div className="card-header col-md-3">
										<label className="label" htmlFor="safraId">
											Período Final :
									</label>
										<InputMask
											onChange={e => setFim(e.target.value)}
											className='form-control bmd-form-group'
											name="fim"
											mask="99/99/9999"
											maskChar={null}
											ref={() => register({ name: "fim" })}
											placeholder="Digite aqui"
											autoComplete="off"
											disabled={!props.opcao}
											value={fim}
										/>
									</div>
								}

								<div className="card-header col-md-3">
									<label className="label" htmlFor="servicoTipoId">
										Grupo de Colaboradores :
								</label>
									<Select
										isClearable={true}
										placeholder='Selecionar'
										className="select-style ajust-label-padrao disabled-select"
										onChange={item => { setValue('grupoPessoaId', item ? item.value : undefined) }}
										ref={() => register({ name: "grupoPessoaId" })}
										options={ObjectHelper.getValuesParseToSelect(props.categorias)}
										theme={theme => ({ ...theme, borderRadius: 0 })}
									/>
								</div>
								{/* <div className="card-header col-md-3">
									<label className="label" htmlFor="servicoTipoId">
										Equipes :*
								</label>
									<Select
										isClearable={true}
										placeholder='Selecionar'
										className="select-style ajust-label-padrao disabled-select"
										onChange={item => { setValue('equipeId', item ? item.value : undefined) }}
										ref={() => register({ name: "equipeId" })}
										options={ObjectHelper.getValuesParseToSelect(props.equipes)}
										theme={theme => ({ ...theme, borderRadius: 0 })}
									/>
								</div> */}
							</div>
						</div>
						<div className="col-md-12 mt-3">
							<button className="btn btn-primary active pull-right" type="submit" style={{ marginBottom: 10, marginTop: -30 }}>
								<i className='fas fa-search' /> Buscar
							</button>
							<button
								className="btn btn-primary btn-load pull-left"
								type="button"
								onClick={atualizar}
								style={{ marginBottom: 10, marginTop: -30 }}
							>
								<i className="fas fa-redo"></i> Atualizar Dados
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}


