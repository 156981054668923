import React from 'react'; import ObjectHelper from 'utils/objectHelper'
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import StringHelper from 'utils/stringHelper';
import Subtitle from '../Subtitle/subtitle.component';
import Can from '../../permissoes/Can';
import {
	ButtonDropdownStyle, ButtonStyle, IconStyle, TdStyle, TrStyle,
} from './table.style';
import { NUMERO_MAX_CARACTERES_LISTAGEM } from '../../constants';
import InputMask from 'react-input-mask';
import DateHelper from '../../utils/dateHelper'
import ToastHelper from "../../utils/toastHelper";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// Função para caso não haja dados para serem mostrados
const EmptyMessage = ({ emptyColSpan, emptyMessage }) => (
	<TrStyle>
		<TdStyle
			colSpan={emptyColSpan}
			className='text-center'
		>
			<Subtitle>{emptyMessage}</Subtitle>
		</TdStyle>
	</TrStyle>
);


// Função para caso seja escolhido apenas um botão de ação
const SingleActionButton = ({ action, item }) => (
	<TdStyle className='text-left'>
		{action.map(actionItem => (
			<Can
				perform={actionItem.permissao}
				yes={() =>
					<ButtonStyle
						primary
						disabled={actionItem.enabled ? !actionItem.enabled(item) : false}
						key={actionItem.id}
						className='btn btn-secondary btn-sm'
						type='button'
						onClick={() => actionItem.action(item)}
						title={actionItem.name}
					>
						<IconStyle
							className={
								actionItem.icon
							}
						/>
					</ButtonStyle>
				}
			/>
		))}
	</TdStyle>
);

// Função para caso seja escolhido um select de ações
const MultiActionButton = ({ actions, item }) => {
	if (actions.legnth >= 5 && actions[5].name === "Planejamento/Ativar/Desativar") {
		if (item.ativo) {
			actions[5].name = "Desativar"
			actions[5].icon = "fas fa-close"
		} else {
			actions[5].name = "Ativar"
		}
	}
	return (

		<TdStyle className='text-right' id={'menu-acaoo'}>
			<div className='dropdown'>
				<ButtonDropdownStyle
					className='btn btn-secondary btn-sm dropdown-toggle'
					type='button'
					id={'menu-acao'}
					data-toggle='dropdown'
					aria-haspopup='true'
					aria-expanded='false'
				>
					Ações
				</ButtonDropdownStyle>
				<div
					className='dropdown-menu'
					aria-labelledby='menu-acao'
				>
					{/* Aplica regra para renderizar o action */}
					{actions.map(actionItem =>
						(actionItem.name === "Editar" && item && item.registroSistema && !actionItem.mostrarSeRegistroSistema) ? null :
							(actionItem.name === "Excluir" && item && item.registroSistema) ? null :
								(!actionItem.checkRule || actionItem.show(item)
									? (
										<Can
											perform={actionItem.permissao}
											key={actionItem.id}
											yes={() =>
												<button
													disabled={actionItem.enabled ? !actionItem.enabled(item) : false}
													key={actionItem.id}
													className='dropdown-item btn-sm'
													type='button'
													onClick={() => actionItem.action(item)}
												>
													<IconStyle className={actionItem.icon} />
													{' '}
													{actionItem.name}
												</button>
											}
										/>
									)
									: null))}
				</div>
			</div>
		</TdStyle>
	);
}

const validDate = (newState, event, document) => {
	var date = newState[event.target.name];
	if (!DateHelper.isValid(date)) {
		document.style.boxShadow = " rgb(255, 0, 0) 0px 0px 0px inset, rgb(255, 0, 0) 0px 0px 10px";
		document.style.borderRadius = "5px";
		ToastHelper.error(`A data ${date} não existe!`)
	} else {
		if (document) {

			document.style.boxShadow = "none";
			document.style.borderRadius = "none";
		}
	}
}

function gerarTooltip(item) {
	return (
		<>
			<p>Lista de recursos.</p>
			<ul>
				{item.recursos.map(r =>
					<li style={{ marginRight: '20px', marginBottom: '10px' }}>
						<span>
							{r.quantidadeHa} {r.unidade.sigla} de {r.recurso.nome} por Ha
						</span>
						{
							r.recurso.tipo && r.recurso.tipo.codigo === "RE" &&
							<div style={{ marginTop: '5px' }}>
								<span style={{ fontWeight: "bold", }}>{`Total para um volume de mistura de ${r.recurso.volumeMistura} ${r.unidade.sigla}`}</span>
								<br></br>
								<ul>
									{r.recurso.receita.map(item =>
										<li>
											{item.quantidade} {item.unidade.sigla} de {item.nome}
										</li>
									)}
								</ul>
							</div>
						}
					</li>

				)}
			</ul>
		</>
	)

}

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		minWidth: 'max-content',
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);


const Tbody = ({
	acoesSingleButton,
	actions,
	columns,
	data,
	emptyColSpan,
	emptyMessage,
	exibirTotal,
	onClick,
	showTotal,
	trClass,
	handleChangeInputTable,
	handleChangeDateTable,
	handleButtonTable,
	handleChangeDate,
	handleButton,
	handleCheckbox
}) => (
	<tbody>
		{/* Verifica se há dados, se não exibe a mensagem de que não há itens */}

		{_.isEmpty(data) && (
			<EmptyMessage emptyMessage={emptyMessage} emptyColSpan={emptyColSpan} />
		)}
		{data && data.map((item, index) => {

			return (
				<TrStyle className={trClass} key={index.toString()} onClick={onClick}>
					{columns.map((columnItem, indexColumn) => {
						let content = ObjectHelper.getValueByPropertyName(columnItem.property, item);


						let alignField = columnItem.number ? 'text-center' : '';

						//colunas que devem ser alinhadas a direita
						if (
							columnItem.property === "quantidadeHa" ||
							columnItem.property === "quantidadePorHa" ||
							columnItem.property === "quantidade" ||
							columnItem.property === "quantidadeTotal" ||
							columnItem.property === "custoPadrao" ||
							columnItem.property === "horasMo" ||
							columnItem.property === "horasMa" ||
							columnItem.property === "atividades" ||
							columnItem.property === "horasPrevistas" ||
							columnItem.property === "totalHoras" ||
							columnItem.property === "delta" ||
							columnItem.property === "fte" ||
							columnItem.property === "dias" ||
							columnItem.property === "horas" ||
							columnItem.property === "totalMO" ||
							columnItem.property === "totalMA"
						)
							alignField = 'text-right'

						//colunas com 2 casas decimais
						if (columnItem.property === "horasPrevistas" ||
							columnItem.property === "delta"
						) {
							content = content.toFixed(2)
						}

						return (
							<TdStyle className={alignField} key={indexColumn.toString()}>
								{
									columnItem.checkbox
										?
										<div className="text-center pr-1">
											<input
												type="checkbox"
												checked={content || false}
												onChange={() => handleCheckbox(item)}
											/>
										</div>
										:
										columnItem.painel ?
											<div
												className={content === null ? "red-column" : content.toString() === "AF" ? "gold-column" : ""}
												style={{ textAlign: "center" }}>
												{content !== null ? content : "-"}
											</div>
											:
											columnItem.recursos
												?
												<div className="text-center" type="button" title="" onClick={handleButton(item)} >
													<i style={{ fontSize: 14 }} className="fas fa-edit" /> Recursos
										</div>
												:
												columnItem.button
													?
													<>
														<HtmlTooltip
															title={gerarTooltip(item)}
														>
															<div className="text-left"
																type="button" title="" onClick={() => handleButtonTable(item)}
																style={{ minWidth: 'max-content' }} >
																<i style={{ fontSize: 14, cursos: 'pointer' }} className={columnItem.buttonIcon} /> {columnItem.buttonText ?
																	(item.recursos.length > 0 ? item.recursos[0].recurso.nome : content)
																	: content}
																{(item.recursos.length > 1 ? <span style={{ fontWeight: "bold", }}> (+)</span> : '')}
															</div>
														</HtmlTooltip>
													</>
													:
													columnItem.link
														?
														item.ativo ?
															<div className="text-center" type="button" title="" onClick={() => columnItem.link(item)} >
																<p className="text-primary" style={{ fontSize: 14, textDecoration: 'underline' }}> {columnItem.text ? columnItem.text : content}</p>
															</div>
															:
															<div className="text-center" style={{ fontSize: 14, textDecoration: 'line-through', color: 'gray' }}>
																{columnItem.text ? columnItem.text : content}
															</div>
														:
														columnItem.input2
															?
															<input
																className='form-control bmd-form-group max-width-input2 text-right'
																name={columnItem.property}
																onChange={(e) => handleChangeInputTable({ item: item, coluna: columnItem.property, valorInput: e.target.value })}
																value={content === null ? undefined : parseInt(content, 10)}
																htmlFor={columnItem.property}
																autoComplete="off"
																min="0"
																maxLength="10000"
																type="number"
															/>
															:
															columnItem.input
																?
																<input
																	className='form-control bmd-form-group  text-right'
																	name="nome"
																	onChange={handleChangeInputTable({ item: item, coluna: columnItem.property })}
																	value={content === null ? "" : parseInt(content, 10)}
																	htmlFor="nome"
																	autoComplete="off"
																	maxLength="40"
																	type="number"
																	min="0"
																/>
																:
																columnItem.datetime
																	?
																	<div className={`${item.id}${columnItem.property}`}>
																		<InputMask
																			//id={`${item.id}${columnItem.property}`}
																			className={'form-control bmd-form-group'}
																			name={columnItem.property}
																			placeholder="Escolha o período inicial"
																			mask="99/99/9999"
																			alwaysShowMask={true}
																			maskChar={null}
																			value={content}
																			onChange={
																				handleChangeDateTable({ item: item, coluna: columnItem.property })
																			}
																			onBlur={e => validDate(item, e, document.getElementById(`${item.id}${columnItem.property}`))}
																			autoComplete="off"
																		/>
																	</div>
																	:
																	columnItem.datetime2
																		?
																		<div className={item !== undefined ? `${item.id}${columnItem.property}` : ''}>
																			<InputMask
																				//id={`${item.id}${columnItem.property}`}
																				className={'form-control bmd-form-group'}
																				name={columnItem.property}
																				placeholder="Escolha o período inicial"
																				mask="99/99/9999"
																				alwaysShowMask={true}
																				maskChar={null}
																				style={{ width: '120px' }}
																				value={content}
																				onChange={
																					(e) => handleChangeDate(item, columnItem.property, e)
																				}
																				onBlur={e => validDate(item, e, document.getElementById(`${item.id}${columnItem.property}`))}
																				autoComplete="off"
																			/>
																		</div>
																		:
																		!columnItem.template
																			? StringHelper.truncate(content, NUMERO_MAX_CARACTERES_LISTAGEM)
																			: columnItem.template(content)
								}
							</TdStyle>
						);
					})}

					{acoesSingleButton && actions && (
						<SingleActionButton action={actions} item={item} />
					)}

					{/* Testa se ações será apenas um select com várias ações */}
					{!acoesSingleButton && actions && (
						<MultiActionButton actions={actions} item={item} />
					)}
				</TrStyle>
			);

		})}
		{showTotal === false ? null : (
			<TrStyle>
				{exibirTotal(columns, data)}
			</TrStyle>
		)}
	</tbody>
);


Tbody.propTypes = {
	/** true: botão único com ação | false: botão select com várias ações */
	acoesSingleButton: PropTypes.bool,
	/** Array de objetos das actions */
	actions: PropTypes.arrayOf(PropTypes.object),
	/** Array de objetos com as colunas: title, property e number */
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	/** Array de objeto vindo do backend que será renderizado pela tabela */
	data: PropTypes.arrayOf(PropTypes.object),
	/** Tamanho da coluna columns + 1 */
	emptyColSpan: PropTypes.number.isRequired,
	/** Mensagem caso não haja nada cadastro, ou seja, data = [] */
	emptyMessage: PropTypes.string.isRequired,
	/** Função para exibir quantidade total de uma coluna */
	exibirTotal: PropTypes.func,
	/** Soma dos valores das colunas */
	showTotal: PropTypes.bool,
};

Tbody.defaultProps = {
	acoesSingleButton: false,
	exibirTotal: () => { },
	actions: null,
	showTotal: false,
	data: [],
};

export default Tbody;
