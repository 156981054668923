import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import {bindActionCreators} from 'redux';
import { Creators as ModulosCreators } from './store/grupo_maquina.ducks';
import {connect} from 'react-redux';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Modal from '../../utils/modalHelper';
import ModalAtivaDesativa from '../../utils/modalHelper';
import GrupoMaquinaService from '../../services/grupoMaquinaService';
import ToastHelper from "../../utils/toastHelper";

function GrupoMaquinasListagem(props) {

    const columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
        { id: 1, title: 'Descrição', property: 'descricao'},
        { id: 2, title: 'Ativo', property: 'isAtivo'},
    ];

    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_GRUPO_MAQUINA_LEITURA,
            action: item => visualizer(item),
        },
        {
			id: 2,
			name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_GRUPO_MAQUINA_ATUALIZAR,
			action: item => edit(item),
        },
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
            permissao: PERMISSAO.MODULO_GRUPO_MAQUINA_ATUALIZAR,
			action: item => handleModalAtivaDesativa(item),
		},
        {
			id: 4,
			name: 'Excluir',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_GRUPO_MAQUINA_EXCLUIR,
			action: item => handleModalDelete(item),
		}
    ];

    
    const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [deleteItem, setDeleteItem] = useState({id:null, showModal:false})
    const [idGrupoAtivaDesativa, setIdGrupoAtivaDesativa] = useState();
    const [showModalAtivaDesativa, setShowModalAtivaDesativa] = useState();
    
    const handleModalAtivaDesativa = (item) => {
        if(item) {
            setIdGrupoAtivaDesativa(item.id)
            setShowModalAtivaDesativa(!showModalAtivaDesativa)
            
        } else {
            setShowModalAtivaDesativa(!showModalAtivaDesativa)
        }
    }
    
    const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: currentPage,
        pageSize: 10,
        filtro:''
    }

    const handleFilter = async (content) =>{
        let newPagination = pagination;
        newPagination.filtro = content.toLowerCase();
        newPagination.page = 1;
        setPagination(newPagination)
        props.getGrupoMaquina(newPagination)
    }

    const edit = (item) => {
        props.history.push({
            pathname: `/maquinas/grupo/editar/${item.id}`
        });
    }

    const visualizer = (item) => {
        props.history.push({
            pathname: `/maquinas/grupo/visualizar/${item.id}`
        });
    }

    const newUnit = () => {
        props.history.push(`/maquinas/grupo/cadastro`);
    }

    const handleModalDelete = (item) => {
        if(item) {
            setDeleteItem({id:item.id, showModal: !deleteItem.showModal})
        } else {
            setDeleteItem({id: null, showModal: !deleteItem.showModal})
        }
	}

    useEffect( () => {
        if(localStorage.getItem('fazendaId')){
            props.getGrupoMaquina(pagination)
        }
    }, []);

    useEffect(()=>{
		if(props.units) initPagination()
    })

    const [pagination, setPagination] = useState(paginateParams)

	const initPagination = () => {
		setItems(props.units.items);
		setTotal(props.units.total);
		setPage(props.units.page);
    }

    const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
        props.getGrupoMaquina(pagination)
    }

    const deletar = async () => {
        await props.deletarGrupoMaquina(deleteItem.id, props)
        let newPagination = pagination
        newPagination.filtro = ""
        await props.getGrupoMaquina(newPagination)
        setDeleteItem({showModal: false})
    }

    const ativaDesativa = async (item) => {
		let dados = await GrupoMaquinaService.ativaDesativa(item);

		if(dados.status === 200) {

			ToastHelper.success("Status do grupo alterado com sucesso!");
			props.getGrupoMaquina(pagination)
			handleModalAtivaDesativa(null);
		} else {

			ToastHelper.error("Erro ao tentar alterar o status do grupo!");
		}
	};

    return (
        <>
            <Title
                acao="Novo Grupo"
                permissaoBotao={PERMISSAO.MODULO_GRUPO_MAQUINA_CRIAR}
                iconBotao="fas fa-plus"
                onClick={newUnit}>
                    Grupos de Máquinas
            </Title>
            <CardStyle className="card">
                <SimpleSearch
                    placeHolder="Pesquisar"
                    value={pagination.filtro}
                    submit={(e) => handleFilter(e)}
                />
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
                    order={false}
                />
			</CardStyle>
            <Modal
                show={deleteItem.showModal}
                doubleButtons={true}
                title={"Excluir Grupo de Máquina"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                submit={() => deletar()}
                close={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                content={
                    <h6>{"Deseja excluir este Grupo de Máquina?"}</h6>
                }
            />
            <ModalAtivaDesativa
                show={showModalAtivaDesativa}
                title={'Ativar / Desativar Grupo'}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => handleModalAtivaDesativa(null)}
                submit={() => ativaDesativa(idGrupoAtivaDesativa)}
                close={() => handleModalAtivaDesativa(null)}
                content={
                    <h6>{'Deseja realmente alterar o status deste grupo?'}</h6>
                }
            />
        </>
    )
}

const mapStateToProps = state =>{
	return ({
        units: state.grupoMaquinas.itens
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GrupoMaquinasListagem)
