import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import AppointmentManpowerService from 'services/apontamentoColaboradorService'
import { Types } from "./mo.ducks";
import executeAction from "store/sagas/executeAction";
import Utils from 'utils/utils';
import ToastHelper from 'utils/toastHelper';
import TeamService from 'services/equipeService';
import TypesStatusService from 'services/statusServicoService';

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 99999,
	filtro: ''
}

function* asyncListAppointmentManpower(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		let dados = yield AppointmentManpowerService.findAll(params);

		dados.data.result.items.forEach(item => {
			item.data = item.data.split(' ')[0];
			item.horas = Utils.formatHour(item.horas);
		})

		yield put({ type: Types.SET_APPOINTMENT_MANPOWER, data: dados.data.result });
		callback();
	}, Types.GET_APPOINTMENT_MANPOWER);
}

function* deleteAppointmentManpower(action) {

	yield executeAction(function* () {

		const { params, callback } = action;
		const { data } = yield call(async () => await AppointmentManpowerService.deletar(params));
		if (data.isOk)
			ToastHelper.success('Apontamento excluído com sucesso!');
		else
			ToastHelper.error("Ocorreu um erro.");
		callback();
	}, Types.DELETE_APPOINTMENT_MANPOWER);
}

function* asyncListTeams(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		let dados = yield call(() => TeamService.buscarTodas(params || parametros));
		yield put({ type: Types.SET_TEAMS, data: dados.data.result.items });
		callback();
	}, Types.GET_TEAMS);
}

function* asyncListTypesStatus(action) {
	yield executeAction(function* () {
		const { callback } = action;
		let { data } = yield call(() => TypesStatusService.getStatusServico());
		yield put({ type: Types.SET_TYPES_STATUS, data: data.result });
		callback();
	}, Types.GET_TYPES_STATUS);
}

function* asyncListMembersTeam(action) {
	yield executeAction(function* () {
		const { params, data, callback } = action;
		let dados = yield call(() => TeamService.getMembrosEquipe(parametros.fazendaId, params, data));
		yield put({ type: Types.SET_MEMBERS_TEAM, data: dados.data.result });
		callback();
	}, Types.GET_MEMBERS_TEAM);
}

function* asyncCreateManpowerAppointments(action) {
	yield executeAction(function* () {
		const { appointment, history, callback } = action;
		const { data } = yield call(() => AppointmentManpowerService.salvar(appointment));
		if (data.isOk) {
			ToastHelper.success("Apontamento criado com sucesso!")
			history.goBack()
		} else ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.")
		yield put({ type: Types.POST_MANPOWER_APPOINTMENTS, data: data });
		callback();
	}, Types.CREATE_MANPOWER_APPOINTMENTS);
}

function* getManpowerAppointmentById(action) {
	yield executeAction(function* () {

		const { params, callback } = action;
		const { data } = yield call(async () => await AppointmentManpowerService.buscarPorId(params));
		yield put({
			type: Types.SET_MANPOWER_APPOINTMENT_BY_ID,
			data: { data: data, entrys: data.result }
		});
		callback();
	}, Types.GET_MANPOWER_APPOINTMENT_BY_ID);
}

function* updateManpowerAppointment(action) {
	yield executeAction(function* () {
		const { appointment, history } = action;
		if (appointment) {
			const { data } = yield call(async () => await AppointmentManpowerService.editar(appointment));
			if (data.isOk) {
				ToastHelper.success('Apontamento atualizado com sucesso!')
				history.goBack()
			} else {
				ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.")
			}
			yield put({
				type: Types.UPDATE_MANPOWER_APPOINTMENT,
				data
			});
		}
	}, Types.UPDATE_MANPOWER_APPOINTMENT);
}

function* takeListAppointmentManpower() {
	yield takeLatest(Types.GET_APPOINTMENT_MANPOWER, asyncListAppointmentManpower);
}

function* takeListTeams() {
	yield takeLatest(Types.GET_TEAMS, asyncListTeams);
}

function* takeListMembersTeam() {
	yield takeLatest(Types.GET_MEMBERS_TEAM, asyncListMembersTeam);
}

function* takeListTypesStatus() {
	yield takeLatest(Types.GET_TYPES_STATUS, asyncListTypesStatus);
}

function* takeDeleteAppointmentManpower() {
	yield takeLatest([Types.DELETE_APPOINTMENT_MANPOWER], deleteAppointmentManpower);
}

function* takeCreateManpowerAppointments() {
	yield takeLatest(Types.CREATE_MANPOWER_APPOINTMENTS, asyncCreateManpowerAppointments);
}

function* takeGetManpowerAppointmentById() {
	yield takeLatest(Types.GET_MANPOWER_APPOINTMENT_BY_ID, getManpowerAppointmentById);
}

function* takeUpdateManpowerAppointment() {
	yield takeLatest([Types.UPDATE_MANPOWER_APPOINTMENT], updateManpowerAppointment);
}


export default function* root() {
	yield all([fork(takeListAppointmentManpower)])
	yield all([fork(takeDeleteAppointmentManpower)])
	yield all([fork(takeListTeams)])
	yield all([fork(takeListTypesStatus)])
	yield all([fork(takeListMembersTeam)])
	yield all([fork(takeCreateManpowerAppointments)])
	yield all([fork(takeGetManpowerAppointmentById)])
	yield all([fork(takeUpdateManpowerAppointment)])
}
