import { all, fork } from "redux-saga/effects";
import permissao from "./permissao";
import unidade from "./unidade"
import planejamentoSafra from '../../pages/planejamento_safra/store/planejamento_safra.sagas'
import turnos from '../../pages/turnos/store/turno.sagas'
import GeradorPlanejamentoSafra from '../../pages/gerador_planejamento_safra/store/gerador.sagas'
import advancedSearch from '../../components/AdvancedSearch/store/advancedSearch.sagas'
import appointmentMachines from '../../pages/apontamentos/ma/store/ma.sagas'
import appointmentManpower from '../../pages/apontamentos/mo/store/mo.sagas'
import appointmentEntrys from '../../pages/apontamentos/insumos/store/entry.appointment.sagas'
import units from '../../pages/units/store/units.sagas'
import histogramas from '../../pages/histograma/store/histograma.sagas'
import grupoMaquina from '../../pages/grupo_maquinas/store/grupo_maquina.sagas'
import insumoAnaliseSolo from '../../pages/insumo_analise_solo/store/insumo_analise_solo.sagas'
import painel from '../../pages/painel/store/painel.sagas'
import painelMO from '../../pages/painelMO/store/painel.sagas'
import materials from '../../pages/materials/store/material.sagas'
import afastamentos from '../../pages/afastamento/store/afastamento.sagas'
import importacao from '../../pages/planejamento_safra/importacao/store/importacao.saga'
import painelServicos from '../../pages/painel_servicos/store/painel.servicos.sagas'
import grupoLocais from '../../pages/grupo_locais/store/grupo.locais.sagas'
import agendamento from '../../pages/agendamento/store/agendamento.sagas'
import recomendacoesTecnicas from './recomendacoes_tecnicas.sagas'
import ordemServico from '../../pages/ordem_servico/store/ordem_servico.sagas'


export default function* rootSaga() {
	yield all([
		fork(painelMO)
	])

	yield all([
		fork(importacao)
	])


	yield all([
		fork(painel)
	]);

	yield all([
		fork(GeradorPlanejamentoSafra)
	]);

	yield all([
		fork(grupoMaquina)
	]);

	yield all([
		fork(permissao)
	]);

	yield all([
		fork(unidade)
	]);

	yield all([
		fork(planejamentoSafra)
	]);

	yield all([
		fork(turnos)
	]);

	yield all([
		fork(advancedSearch)
	]);

	yield all([
		fork(appointmentMachines)
	]);

	yield all([
		fork(appointmentManpower)
	]);

	yield all([
		fork(appointmentEntrys)
	]);

	yield all([
		fork(units)
	]);

	yield all([
		fork(histogramas)
	]);

	yield all([
		fork(materials)
	]);

	yield all([
		fork(afastamentos)
	]);

	yield all([
		fork(insumoAnaliseSolo)
	]);

	yield all([
		fork(painelServicos)
	]);

	yield all([
		fork(grupoLocais)
	]);


	yield all([
		fork(agendamento)
	]);

	yield all([
		fork(recomendacoesTecnicas)
	]);

	yield all([
		fork(ordemServico)
	]);
}
