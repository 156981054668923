import React, { useState, useCallback, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Title from "../../components/Title/title.component";
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { Creators as ModulosCreators } from './store/afastamento.ducks';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons'
import pt from 'date-fns/locale/pt';
import './afastamento.css'
import DateHelper from '../../utils/dateHelper'
import Utils from '../../utils/utils'

function AfastamentoCadastro(props) {

	const [visualizar, setVisualizar] = useState(false)
	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue, getValues } = useForm({});
	const [form, setForm] = useState({})
	const [validDatas, setValidDatas] = useState()
	const [dateIni, setDateIni] = useState()
	const [dateFim, setDateFim] = useState()

	useEffect(() => {
		async function initialize() {
			try {
				await props.getPessoas()
				await props.getTiposAfastamentos()
			} catch (error) {

			}
		}
		initialize();
	}, [])

	useEffect(() => {

		if (params.modo === 'visualizar') setVisualizar(true)
		if (params.id !== undefined) {
			props.getAfastamentoById(params.id)
		}
	}, []);

	useEffect(() => {
		if (params.id !== undefined && props.afastamento) {
			let dataInicial = DateHelper.createDate(props.afastamento.dataInicio.split(' ')[0])
			let dataFinal = DateHelper.createDate(props.afastamento.dataFim.split(' ')[0])

			setValue('dateFim', dataFinal);
			setValue('dateIni', dataInicial);
			setValue('funcionario', { value: props.afastamento.pessoa.id, label: props.afastamento.pessoa.nome });
			setValue('tipoAfastamento', { value: props.afastamento.afastamentoTipo.afastamentoTipoId, label: props.afastamento.afastamentoTipo.nome });
			setValue('motivo', props.afastamento.motivo);
			setValue('id', props.afastamento.id);

			let item = {
				dateFim: dataFinal,
				dateIni: dataInicial,
				funcionario: { value: props.afastamento.pessoa.id, label: props.afastamento.pessoa.nome },
				tipoAfastamento: { value: props.afastamento.afastamentoTipo.afastamentoTipoId, label: props.afastamento.afastamentoTipo.nome },
				motivo: props.afastamento.motivo,
				id: props.afastamento.id
			}
			setForm(item)
			setDateIni(dataInicial)
			setDateFim(dataFinal)
		}
	}, [props.afastamento]);

	const validateDates = useCallback(() => {
		if (getValues().dateIni && getValues().dateFim) {
			if (getValues().dateIni > getValues().dateFim) {
				setValidDatas('O período inicial não pode ser maior que o periodo final')
				return false
			} else {
				setValidDatas('')
				return true
			}
		} else {
			setValidDatas('')
			return true
		}
	}, [dateIni, dateFim, setValidDatas]);

	const submitForm = useCallback(async (data) => {
		let datasValidas = validateDates()
		if (datasValidas) {
			let newAfastamento = {
				id: form.id ? form.id : '',
				motivo: data.motivo,
				dataInicio: new Date(dateIni),
				dataFim: new Date(dateFim),
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				pessoaId: data.funcionario.value,
				afastamentoTipoId: data.tipoAfastamento.value
			}
			if (!params.id) {
				props.createAfastamento(newAfastamento, props.history)
			} else {
				props.updateAfastamento(newAfastamento, props.history)
			}
		}
	}, [validDatas, dateIni, dateFim]);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							{visualizar ? 'Visualizar ' :
								(params.id ? 'Editar ' :
									'Cadastrar '
								)} afastamento
						</Title>
					</div>
				</div>
				<CardStyle className="card">
					<div className='card-header row justify-content-start col-md-12'>
						<div className="card-header col-md-6">
							<label className="label">
								Colaborador:*
                            </label>
							<Select
								id="funcionario"
								name="funcionario"
								htmlFor="funcionario"
								placeholder='Selecione o Colaborador'
								isDisabled={visualizar}
								className="select-style ajust-label-padrao disabled-select"
								options={props.pessoas ? props.pessoas : []}
								value={form.funcionario}
								onChange={item => {
									setValue('funcionario', item);
									setForm({ ...form, funcionario: item })
								}}
								ref={() => register({ name: "funcionario" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.funcionario && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-6">
							<label className="label">
								Tipo de afastamento:*
                            </label>
							<Select
								id="tipoAfastamento"
								name="tipoAfastamento"
								htmlFor="tipoAfastamento"
								placeholder='Selecione o tipo de afastamento'
								isDisabled={visualizar}
								className="select-style ajust-label-padrao disabled-select"
								options={props.tiposAfastamento ? props.tiposAfastamento : []}
								value={form.tipoAfastamento}
								onChange={item => {
									setValue('tipoAfastamento', item);
									setForm({ ...form, tipoAfastamento: item })
								}}
								ref={() => register({ name: "tipoAfastamento" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.tipoAfastamento && <span className="span-errors">Campo obrigatório</span>}
						</div>
					</div>
					<div className='row justify-content-start col-md-12'>
						<label className="label ml-3">
							Período:*
                        </label>
						<div className="box-periodo col-md-12 flex">
							<div className="label-periodo p-0 mr-3">
								<strong className="label">
									De:
                                </strong>
							</div>
							<div className="col-md-5 select-periodo pl-0">
								<DatePicker
									name="dateIni"
									selected={dateIni}
									placeholderText='Informe a data inicial'
									className='form-control bmd-form-group'
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									onChange={date => {
										setValue('dateIni', date ? date : '');
										setDateIni(date ? date : '');
										validateDates();
									}}
									value={dateIni ? dateIni : null}
									ref={() => register({ name: "dateIni" }, { required: true })}
									locale={pt}
									disabled={visualizar}
								/>
								{errors.dateIni && <span className="span-errors">Campo obrigatório. </span>}
								{validDatas && <span className="span-errors">{errors.dateIni ? <br /> : ''}{validDatas}</span>}
							</div>
							<div className=" col-md-1 label-periodo p-0">
								<i class="fas fa-angle-double-right icon mr-2"></i>
								<strong className="label">
									Até
                                </strong>
								<i className="fas fa-angle-double-right icon ml-2"></i>
							</div>
							<div className=" col-md-5 select-periodo">
								<DatePicker
									name="dateFim"
									selected={dateFim}
									placeholderText='Informe a data final'
									className='form-control bmd-form-group'
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									onChange={date => {
										setValue('dateFim', date ? date : '');
										setDateFim(date ? date : '');
										validateDates();
									}}
									value={dateFim ? dateFim : null}
									ref={() => register({ name: "dateFim" }, { required: true })}
									locale={pt}
									disabled={visualizar}
								/>
								{errors.dateFim && <span className="span-errors">Campo obrigatório. </span>}
							</div>
						</div>
					</div>
					<div className='row justify-content-start col-md-12'>
						<div className="card-header col-md-12">
							<label className="label">
								Motivo:
							</label>
							<input
								type='textarea'
								name="motivo"
								disabled={visualizar}
								className="form-control"
								autoComplete="off"
								ref={register({ required: false })}
							/>
							{errors.motivo && <span className="span-errors">Campo obrigatório</span>}
						</div>
					</div>
				</CardStyle>
				<SubmitFormButtons
					flagVisualizar={visualizar}
					flagEditar={params.id !== undefined}
					actionGoBack={() => props.history.goBack()}
				/>
			</form>
		</>
	)
}

const mapStateToProps = state => ({
	...state,
	pessoas: Utils.removeInactiveToSelect(ObjectHelper.getValuesParseToSelect(state.afastamentos.pessoas ? state.afastamentos.pessoas.items : [])),
	tiposAfastamento: ObjectHelper.getValuesParseToSelect(state.afastamentos.tipoAfastamentos),
	afastamento: state.afastamentos.afastamento
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AfastamentoCadastro)
