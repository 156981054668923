import api from './api';

export default {

	buscarPorId(InsumoAnaliseSoloId){
			return api.get(`/api/InsumoAnaliseSolo/${InsumoAnaliseSoloId}`);
	},
    
	buscarTodas(InsumoAnaliseSolo){
		return api.post(`/api/InsumoAnaliseSolo/BuscarTodas/`, InsumoAnaliseSolo);
	},

	editar(InsumoAnaliseSolo){
		return api.put(`/api/InsumoAnaliseSolo/`, InsumoAnaliseSolo);
	},

	cadastrar(InsumoAnaliseSolo){
		return api.post(`/api/InsumoAnaliseSolo/`, InsumoAnaliseSolo)
	}, 
	
	deletar(InsumoAnaliseSoloId){
		return api.delete(`/api/InsumoAnaliseSolo/${InsumoAnaliseSoloId}`);
	},

	ativaDesativa(id){
		return api.get(`/api/InsumoAnaliseSolo/AtivaDesativa/${id}`);
	},

	buscarPorPlanejamentoSafra(planejamentoSafraId){
		return api.get(`/api/InsumoAnaliseSolo/PorPlanejamentoSafra/${planejamentoSafraId}`);
	},
};
