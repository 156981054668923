import React, { useState, useCallback, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CardStyle } from '../../../components/CardStyle/cardstyle.component';
import { getUserId } from "../../../services/auth";
import SubmitFormButtons from '../../../components/submitFormButtons/submitFormButtons';
import ToastHelper from "../../../utils/toastHelper";
import { Creators as ModulosCreators } from '../../redefinir_senha/store/redefinir.senha.ducks';
import profileEmpty from '../../../assets/logo/profileEmpty.png';
import UsuarioService from '../../../services/usuarioService'
import SelectMulti from '../../../components/SelectMulti/selectmulti.component';
import "./usuario.editar.scss"

function EditarUsuario(props) {

	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [form, setForm] = useState([]);
	const [valid, setValid] = useState(false);

	useEffect(() => {
		var usuarioLocadoId = getUserId()
		if (usuarioLocadoId) {
			Buscar(usuarioLocadoId)
		}
		if (params.id === usuarioLocadoId) {
			setValid(true)
		} else {

			ToastHelper.error("Acesso Negado")
		}
	}, [])

	const Buscar = async (usuarioLocadoId) => {
		const fazendaId = await JSON.parse(localStorage.getItem('fazendaId')).value;
		const { data } = await UsuarioService.buscarPorId(usuarioLocadoId, fazendaId)
		if (data) {
			setForm({
				...form,
				id: data.result.id,
				nome: data.result.nome,
				nomeUsuario: data.result.userName,
				email: data.result.email,
				perfis: await ObjectHelper.getValuesParseToSelect(data.result.papeis),
				pessoaId: data.result.pessoaId
			})

			setValue('id', data.result.id)
			setValue('nome', data.result.nome)
			setValue('nomeUsuario', data.result.userName)
			setValue('email', data.result.email)
			setValue('perfis', await ObjectHelper.getValuesParseToSelect(data.result.papeis))
			setValue('pessoaId', data.result.pessoaId)
		}
	}

	const AlterarSenha = (item) => {
		var usuarioLocadoId = getUserId()
		props.history.replace({
			pathname: `/redefinirSenha/${usuarioLocadoId}`
		});
	}

	const submitForm = useCallback(async (data) => {
		let newUser = {
			id: form.id,
			nome: form.nome,
			userName: form.nomeUsuario,
			email: form.email,
			password: '',
			pessoaId: form.pessoaId,
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			papeis: [
			]
		}
		const resp = await UsuarioService.editar(newUser)
		if (resp.data) {
			if (resp.data.isOk) {

				ToastHelper.success("Usuário editado com sucesso")
				props.history.goBack({
					pathname: `/dashboard`
				});
			} else {

				ToastHelper.error(resp.data.mensagem)
			}
		} else {
			ToastHelper.error("Ocorreu um erro ao tentar editar o usuário")
		}
	}, [form]);

	return (
		<>
			{
				valid &&
				<>

					<h3 className="text-right title">Usuário</h3>
					<form onSubmit={handleSubmit(submitForm)}>
						<div className="text-left">
							<img
								className="img-editar-usuario"
								src={profileEmpty}
								alt="Logo"
								width={120}
								height={120}
							/>
						</div>
						<CardStyle className='card mb-0 pb-0 text-left'>
							<div className='card-header row col-md-12 flex mb-3'>
								<div className="col-md-12 mt-5 ">

								</div>
								<div className='row col-md-12 mt-4'>
									<div className="col-md-6 card-header">
										<label className="label">
											Nome:
                                        </label>
										<input type='text'
											className='form-control'
											disabled={false} name='nome'
											autoComplete="off"
											ref={() => register({ name: "nome" }, { required: true })}
											value={form.nome}
											onChange={item => {
												setValue('nome', item.target.value);
												setForm({ ...form, nome: item.target.value })
											}}
										/>
										{errors.nome && <span className='span-errors'> Campo Obrigatorio</span>}
									</div>
								</div>
								<div className='row col-md-12'>
									<div className="col-md-6 card-header">
										<label className="label">
											Nome de usuário:
                                        </label>
										<input type='text'
											className='form-control'
											disabled={false} name='nomeUsuario'
											autoComplete="off"
											ref={() => register({ name: "nomeUsuario" }, { required: true })}
											value={form.nomeUsuario}
											onChange={item => {
												setValue('nomeUsuario', item.target.value);
												setForm({ ...form, nomeUsuario: item.target.value })
											}}
										/>
										{errors.nomeUsuario && <span className='span-errors'> Campo Obrigatorio</span>}
									</div>
									<div className="col-md-6 card-header">
										<label className="label">
											E-mail:
                                        </label>
										<input type='text'
											className='form-control'
											disabled={false} name='email'
											autoComplete="off"
											ref={() => register({ name: "email" }, { required: true })}
											value={form.email}
											onChange={item => {
												setValue('email', item.target.value);
												setForm({ ...form, email: item.target.value })
											}}
										/>
										{errors.email && <span className='span-errors'> Campo Obrigatorio</span>}
									</div>
								</div>
								<div className='row col-md-12'>
									<div className="col-md-6 card-header">
										<label className="label">
											Tipo de Perfil:
                                        </label>
										<SelectMulti
											id="perfilId"
											name="perfilId"
											className="form-control"
											placeholder="Tipo de Perfil"
											label="nome"
											isClearable={true}
											options={[]}
											disabled={true}
											isMulti={true}
											noOptionsMessage={() => 'Lista vazia'}
											value={form.perfis}
										/>
										{errors.perfis && <span className='span-errors'> Campo Obrigatorio</span>}
									</div>
									<div className="col-md-4 card-header mt-2">
										<label className="label">
											Senha:
                                        </label>
										<input
											name="usuario"
											className="form-control"
											autoComplete="off"
											disabled={true}
											value={"123456789"}
											scale={2}
											lang="pt-br"
											onChange={item => {
											}}
											type='password'
										/>
										<div onClick={AlterarSenha} style={{ cursor: 'pointer' }}>
											<div className="pull-right" style={{ float: 'right' }}>
												<span className="mr-1" style={{ fontSize: 13, color: '#00635A', cursor: 'pointer' }}>Alterar senha</span>
												<i className="fas fa-edit mt-1 icon-edit-password"> </i>
											</div>
										</div>
									</div>
								</div>

							</div>
						</CardStyle>
						<SubmitFormButtons
							flagVisualizar={false}
							flagEditar={true}
							actionGoBack={() => window.history.back()}
						/>
					</form>
				</>
			}
		</>
	)
}

const mapStateToProps = state => {
	return ({
		usuario: undefined
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditarUsuario)

