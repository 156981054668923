import api from './api';

export default {
	buscarTodas(GrupoInsumos){
		return api.post(`/api/GrupoInsumos/BuscarTodas`, GrupoInsumos);
	},
	deletar(id){
		return api.delete(`/api/GrupoInsumos/${id}`);
	},
	cadastrar(GrupoInsumos){
		return api.post(`/api/GrupoInsumos`, GrupoInsumos);
	},
	buscarPorId(GrupoInsumosId){
		return api.get(`/api/GrupoInsumos/BuscarPorId/${GrupoInsumosId}`);
	},
	editar(GrupoInsumos){
		return api.put(`/api/GrupoInsumos`, GrupoInsumos);
	},
	ativaDesativa(familiaId){
		return api.get(`/api/GrupoInsumos/Ativar/${familiaId}`);
	}
};
