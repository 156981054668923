import React from "react";
import Title from "../../components/Title/title.component";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import { Formik, Field, ErrorMessage } from "formik";
import ToastHelper from "../../utils/toastHelper";
import CulturaService from "../../services/culturaService";
import Select from "react-select";
import TemplatePlanejamentoService from "../../services/templatePlanejamentoService";
import Table from "../../components/Table/table.component";
import Modal from "./modal.items";
import ServicoService from "../../services/servicoService";
import GrupoServicoService from "../../services/grupoServicoService";
import { PERMISSAO } from "../../permissoes/permissoes";
import DateHelper from "../../utils/dateHelper";
import { TemplateSchema } from "./template.validade";
import InputMask from "react-input-mask";
import ModalRecursos from "./planejamento_safra.recursos.modal";
import RecursoService from "services/recursosService";
import FamiliaMaterialService from "services/familiaMaterialService";
import PessoaTipoService from "services/categoriaService";
import recursosTipoService from "../../services/recursosTipoService";
import ObjectHelper from "utils/objectHelper";
import MaquinaTipoService from "services/tipoMaquinaService";
import Utils from "utils/utils";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const PARAMETROS = {
	fazendaId:
		JSON.parse(localStorage.getItem("fazendaId")) !== null
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: null,
	page: 0,
	pageSize: 1000,
	order: "Nome"
};

const editorConfiguration = {
	toolbar: [
		"heading",
		"|",
		"bold",
		"italic",
		"link",
		"bulletedList",
		"numberedList",
		"blockQuote"
	]
};

export default class TemplateCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: "",
			dados: {
				id: null,
				nome: null,
				culturaId: null,
				culturaCodigo: null,
				data: null,
				items: []
			},
			visualizar: false,
			editar: false,
			editarModal: false,
			item: [],
			culturas: [],
			grupoServicos: [],
			servicos: [],
			loop: false,
			save: false,
			dataSimulacao: false,
			data: null,
			showModal: false,
			showModalLocais: false,
			showModalRecursos: false,
			recursosLista: [],
			recursos: [],
			id_planejamento: "",
			areaPlanejamento: null,
			recursosClasse: [],
			recursosTipo: [],
			servicoSelecionado: "",
			descricao: ""
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		const {
			match: { params }
		} = this.props;
		if (params.id) {
			await this.setState({ id: params.id });
			await this.buscar(params.id);
		}

		let culturas = await CulturaService.buscarTodas({
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			page: 0,
			pageSize: 1000
		});

		this.setState({
			culturas: this.getCulturasParseToSelect(culturas.data.result.items)
		});

		this.getGrupoServico();
		this.getRecursosTipo();
	}

	async changeRecursoTipo(e) {
		if (e) {
			let param = {
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				page: 1,
				pageSize: 1000
			};

			let data = null;

			if (e.codigo === "MA")
				data = await MaquinaTipoService.buscarTodas(param);
			if (e.codigo === "IN" || e.codigo === "RE")
				data = await FamiliaMaterialService.buscarTodas(param);
			if (e.codigo === "MO")
				data = await PessoaTipoService.buscarTodas(param);

			if (data) {
				data = ObjectHelper.getValuesParseToSelect(
					ObjectHelper.removeInativos(data.data.result.items)
				);
				data = Utils.ordernar(data, "label");
			} else {
				data = [];
			}
			this.setState({ recursosClasse: data });

			this.getRecursosPorTipoId(e.value);
		}
	}

	async getRecursosPorTipoId(tipoId) {
		const { data } = await RecursoService.getTipoId(tipoId);
		let recursos = Utils.ordernar(data.result, "label");
		this.setState({
			recursosLista: ObjectHelper.removeInativos(recursos)
		});
	}

	async getRecursosPorClasseId(classeId) {
		const { data } = await RecursoService.getClasseId(classeId);
		let recursos = Utils.ordernar(data.result, "label");
		this.setState({
			recursosLista: ObjectHelper.removeInativos(recursos)
		});
	}

	async getGrupoServico() {
		const { data } = await GrupoServicoService.buscarTodos(PARAMETROS);
		let ativos = ObjectHelper.removeInativos(data.result.items);
		this.setState({
			grupoServicos: this.getValuesParseToSelect(ativos)
		});
	}

	async changeGrupoServico(id) {
		const { data } = await ServicoService.buscarPorGrupoId(
			PARAMETROS.fazendaId,
			id
		);
		var ativos = ObjectHelper.removeInativos(data.result);
		this.setState({
			servicos: this.getValuesParseToSelect(ativos)
		});
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || "id";
		labelName = labelName || "nome";
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] });
		});
		return options;
	}

	getCulturasParseToSelect(list, valueName, labelName, codigoName) {
		let options = [];

		valueName = valueName || "id";
		labelName = labelName || "nome";
		codigoName = codigoName || "codigo";
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName], codigo: item[codigoName] });
		});
		return options;
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar");
		var isEditar = url_atual.indexOf("editar");

		isVisualizar > 0
			? this.setState({ visualizar: true })
			: this.setState({ visualizar: false });
		isEditar > 0
			? this.setState({ editar: true })
			: this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar
			? "Editar"
			: this.state.visualizar
				? "Visualizar"
				: "Cadastrar";
	}

	async buscar(id) {
		const { data } = await TemplatePlanejamentoService.get(id);
		if (data.result.data !== null) {
			this.setState({ dataSimulacao: true });
		}
		data.result.culturaId = data.result.cultura.culturaId;
		data.result.culturaCodigo = data.result.cultura.codigo;
		data.result.id = id;
		await this.setState({
			data: data.result.data,
			descricao: data.result.descricao || ""
		});
		await this.setState({
			...this.state,
			dados: data.result
		});
	}

	voltar = async () => {
		if (this.state.id !== undefined && this.state.dados.items.length === 0)
			await TemplatePlanejamentoService.deletar(this.state.id);

		this.history.goBack();
	};

	verificadorData = items => {
		var prosseguir = true;
		items.forEach(item => {
			if (item.inicio !== "")
				if (DateHelper.isValid(item.inicio) === false) {
					ToastHelper.error(
						`A data ${item.inicio} está fora do padrão!`
					);
					prosseguir = false;
				}
			if (item.fim !== "")
				if (DateHelper.isValid(item.fim) === false) {
					ToastHelper.error(
						`A data ${item.fim} está fora do padrão!`
					);
					prosseguir = false;
				}
		});
		return prosseguir;
	};

	verificadorDuracao = async values => {
		let valido = true;
		await values.items.forEach(item => {
			if (item.duracao < 1) {
				ToastHelper.error(
					"O serviço" + item.servico + " está com duração inválida"
				);
				return (valido = false);
			}
		});
		return valido;
	};

	onSubmit = async values => {
		var ducacaoValida = await this.verificadorDuracao(values);
		if (ducacaoValida === false) return;

		if ((!this.state.dados.culturaCodigo || this.state.dados.culturaCodigo.toUpperCase() !== 'CAFE') && !DateHelper.isValid(this.state.data))
			return ToastHelper.error(
				"A data inicial para simulação é inválida."
			);

		if (this.state.data !== null)
			values.data = new Date(DateHelper.createDate(this.state.data));
		else
			values.data = new Date();

		const {
			match: { params }
		} = this.props;
		let acao = params.modo === "editar" ? "editado" : "cadastrado";

		values.fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		var prosseguir = this.verificadorData(values.items);
		if (prosseguir === false) return;

		values.descricao = this.state.descricao;

		let result = null;
		if (params.modo !== "editar")
			result = await TemplatePlanejamentoService.salvar(values);
		else {
			if (values.items.length > 0)
				result = await TemplatePlanejamentoService.update(values);
			else
				return ToastHelper.error(
					"Adicione pelo menos uma atividade no modelo!"
				);
		}

		if (result.data.isOk) {
			ToastHelper.success(
				"Modelo de manejo " + acao + " com sucesso!"
			);
			if (params.modo === "editar") return this.voltar();
			this.history.replace({
				pathname: `/planejamento/template/editar/${result.data.result}`
			});
		} else {
			return ToastHelper.error(
				"Ocorreu um erro. " +
				(result.data.exception
					? result.data.exception.Message
					: result.data.mensagem)
			);
		}
	};

	columnsGraos = [
		{ id: 0, title: "#", property: "ordem" },
		{ id: 1, title: "Grupo de Serviço", property: "servicoTipo" },
		{ id: 3, title: "Serviço", property: "servico" },
		{ id: 5, title: "Predecessora", property: "predecessora", input: true },
		{ id: 6, title: "Folga", property: "folga", input: true },
		{ id: 7, title: "Duração", property: "duracao", input: true },
		{ id: 8, title: "Recursos", property: "recursos", recursos: true },
		{ id: 9, title: "Início", property: "inicio", datetime: true },
		{ id: 10, title: "Fim", property: "fim", datetime: true }
	];

	columnsCafe = [
		{ id: 0, title: "#", property: "ordem" },
		{ id: 1, title: "Grupo de Serviço", property: "servicoTipo" },
		{ id: 3, title: "Serviço", property: "servico" },
		{ id: 4, title: "Recursos", property: "recursos", recursos: true },
		{ id: 5, title: "Início", property: "inicio", datetime: true },
		{ id: 6, title: "Fim", property: "fim", datetime: true }
	];

	actions = [
		{
			id: 1,
			name: "Editar",
			icon: "fas fa-edit",
			permissao: PERMISSAO.MODULO_RECURSOS_LEITURA,
			action: item => this.editarItem(item)
		},
		{
			id: 2,
			name: "Apagar",
			icon: "fas fa-trash-alt",
			permissao: PERMISSAO.MODULO_RECURSOS_LEITURA,
			action: item => this.excluirItem(item)
		}
	];

	async tableButton(item) {
		this.setState({
			showModalRecursos: true,
			recursos: item.recursos,
			areaPlanejamento: 0,
			id_planejamento: item.id,
			servicoSelecionado: item.servico
		});
	}

	async adicionarItem() {
		await this.setState({
			editarModal: false,
			item: []
		});

		this.handleModal();
	}

	async editarItem(item) {
		await this.setState({
			editarModal: true,
			item: item
		});

		this.handleModal();
	}

	async excluirItem(item) {
		let result = await TemplatePlanejamentoService.removerItem(item.id);

		if (result.data.isOk) ToastHelper.success("Atividade removida!");
		else ToastHelper.error(result.data.mensagem);

		await this.buscar(this.state.id);
	}

	async mudarPredecessoras(atividadeModificada, items) {
		try {
			let founds = [];
			items.forEach(item => {
				if (
					parseInt(item.predecessora) ===
					parseInt(atividadeModificada.ordem)
				)
					return founds.push(item);
			});

			founds.forEach(found => {
				if (found !== undefined) {
					let data = new Date(
						DateHelper.createDate(atividadeModificada.fim)
					);
					found.inicio = new Date(
						data.getTime() + parseInt(found.folga) * 86400000
					);
					found.fim = new Date(
						found.inicio.getTime() +
						parseInt(found.duracao) * 86400000
					);
					found.inicio = DateHelper.formatDate(found.inicio);
					found.fim = DateHelper.formatDate(found.fim);

					items.forEach(item => {
						if (parseInt(item.ordem) === parseInt(found.ordem))
							return (item = found);
					});
					this.mudarPredecessoras(found, items);
				}
			});

			return items;
		} catch (e) {
			return e;
		}
	}

	async verificacaoCircular(items, item, valor, ret) {
		const verificador = false;
		try {
			var found = items.find(
				element => element.predecessora === item.ordem
			);
			if (found !== undefined) {
				if (found.ordem.toString() === valor.toString()) {
					ToastHelper.warn("Referencia cicular");
					ret = false;
					return verificador;
				}
				if (!ret) return verificador;
				else
					return await this.verificacaoCircular(
						items,
						found,
						valor,
						ret
					);
			}
		} catch (e) {
			return e;
		}
	}

	async onChange(item, event) {
		let newDate = undefined;
		let duracao = undefined;
		let newDuracao = undefined;
		let date = undefined;
		let valor = event.target.value;
		item.item[item.coluna] = valor;
		var items = this.state.dados.items;
		var predecessora = null;

		if (item.coluna === "predecessora") {
			var atividadeEncontrada = items.find(
				element => element.ordem.toString() === valor.toString()
			);

			if (atividadeEncontrada !== undefined) {
				if (
					atividadeEncontrada.ordem.toString() ===
					item.item.ordem.toString()
				)
					return ToastHelper.warn(
						"Uma atividade não pode ter como PREDECESSORA ela mesma"
					);

				if (
					atividadeEncontrada.predecessora.toString() ===
					item.item.ordem.toString()
				) {
					return ToastHelper.warn(
						"Uma atividade não pode ter como PREDECESSORA outra atividade que tenha ela propria como PREDECESSORA"
					);
				}
			} else if (
				valor !== "" &&
				atividadeEncontrada === undefined &&
				valor.toString() !== "0"
			) {
				return ToastHelper.warn(
					`A atividade de ordem '${valor}' não existe!`
				);
			}

			if (valor !== "") {
				var ret = true;
				var verificacao = await this.verificacaoCircular(
					items,
					item.item,
					valor,
					ret
				);
				if (verificacao !== undefined && verificacao === false) return;
			}

			item.item.folga = 0;
			item.item.duracao = 0;

			items.forEach(item => {
				if (parseInt(item.ordem) === parseInt(valor)) {
					return (predecessora = item);
				}
			});

			if (predecessora !== null) {
				item.item.inicio = predecessora.fim;
				item.item.fim = predecessora.fim;
			} else {
				item.item.inicio = this.state.data;
				item.item.fim = this.state.data;
			}
		} else if (parseInt(item.item.predecessora) !== 0) {
			items.forEach(value => {
				if (
					parseInt(value.ordem) === parseInt(item.item.predecessora)
				) {
					return (predecessora = value);
				}
			});
		} else if (
			item.coluna === "predecessora" &&
			parseInt(item.item.predecessora) === 0
		) {
			item.item.folga = 0;
			item.item.duracao = 0;
			item.item.inicio = this.state.data;
			item.item.fim = this.state.data;
		}

		if (predecessora !== null) {
			date = new Date(DateHelper.createDate(predecessora.fim));
			if (item.coluna === "folga") {
				newDate = new Date(
					date.setTime(date.getTime() + parseInt(valor) * 86400000)
				);
				item.item.inicio = DateHelper.formatDate(newDate);
				duracao = parseInt(item.item.duracao);
				newDuracao = new Date(
					newDate.setTime(
						newDate.getTime() + parseInt(duracao - 1) * 86400000
					)
				);
				item.item.fim = DateHelper.formatDate(newDuracao);
			}
			if (item.coluna === "duracao") {
				valor = parseInt(item.item.folga) + parseInt(valor - 1);
				newDate = new Date(
					date.setTime(date.getTime() + parseInt(valor) * 86400000)
				);
				item.item.fim = DateHelper.formatDate(newDate);
			}
		} else {
			date = new Date(DateHelper.createDate(this.state.dados.data));
			if (item.coluna === "folga") {
				newDate = new Date(
					date.setTime(date.getTime() + parseInt(valor) * 86400000)
				);
				item.item.inicio = DateHelper.formatDate(newDate);
				duracao = parseInt(item.item.duracao);
				newDuracao = new Date(
					newDate.setTime(
						newDate.getTime() + parseInt(duracao - 1) * 86400000
					)
				);
				item.item.fim = DateHelper.formatDate(newDuracao);
			}
			if (item.coluna === "duracao") {
				valor = parseInt(item.item.folga) + parseInt(valor);
				newDate = new Date(
					date.setTime(
						date.getTime() + parseInt(valor - 1) * 86400000
					)
				);
				item.item.fim = DateHelper.formatDate(newDate);
			}
		}

		items = await this.mudarPredecessoras(item.item, items);
		items.forEach(element => {
			if (element.id === item.item.id) {
				element = item.item;
			}
		});

		var dados = this.state.dados;
		dados.items = items;

		await this.setState({
			dados: dados
		});
	}

	async onChangeDate(item, event) {
		item.item[item.coluna] = event.target.value;
		item.item.predecessora = 0;

		if (
			DateHelper.isValid(item.item.inicio) &&
			DateHelper.isValid(item.item.fim)
		) {
			if (DateHelper.isValid(event.target.value)) {
				var inicio = new Date(DateHelper.createDate(item.item.inicio));
				var fim = new Date(DateHelper.createDate(item.item.fim));
				var dataSimulacao = new Date(
					DateHelper.createDate(this.state.data)
				);

				var folga = Math.abs(inicio - dataSimulacao);
				var difFolga = Math.ceil(folga / (1000 * 60 * 60 * 24));
				item.item.folga = difFolga;

				var duracao = Math.abs(fim - inicio);
				var difDuracao = Math.ceil(duracao / (1000 * 60 * 60 * 24));
				item.item.duracao = difDuracao + 1;
			}
		}

		var items = this.state.dados.items;

		items = await this.mudarPredecessoras(item.item, items);

		items.forEach(element => {
			if (element.id === item.item.id) {
				element = item.item;
			}
		});

		var dados = this.state.dados;
		dados.items = items;

		await this.setState({
			dados: dados
		});
	}

	async handleModal() {
		await this.setState({
			...this.state,
			showModal: true
		});
	}

	confirmarItem = async item => {
		let result = null;
		item.templatePlanejamentoId = this.state.id;

		if (item.editar)
			result = await TemplatePlanejamentoService.updateItem(item);
		else {
			item.id = this.state.id;
			result = await TemplatePlanejamentoService.salvarItens(item);
		}

		if (result.data.isOk) {
			ToastHelper.success("Atividade salva!");
			await this.buscar(this.state.id);
			this.setState({ showModal: false, item: [], editarModal: false });

			return true;
		} else {
			ToastHelper.error(result.data.mensagem);
			return false;
		}
	};

	async calcularPrazos() {
		if (!DateHelper.isValid(this.state.data))
			return ToastHelper.error(
				"O Data inicial para simulação é inválido."
			);

		if (this.state.data !== null)
			this.state.dados.data = new Date(
				DateHelper.createDate(this.state.data)
			);

		let result = await TemplatePlanejamentoService.calcularPrazos(
			this.state.dados
		);
		if (result.data.isOk) ToastHelper.success("Prazos calculados!");
		else ToastHelper.error(result.data.mensagem);

		await this.setState({ save: true });
		await this.buscar(this.state.id);
	}

	modalLocaisClose = () => {
		this.setState({ showModalLocais: false });
	};

	modalClose = async param => {
		var items = this.state.dados.items;
		var itemTamplate;
		items.forEach(item => {
			if (item.id === param.templateId) {
				item.recursos = param.recursos;
				itemTamplate = item;
			}
		});

		await TemplatePlanejamentoService.updateItem(itemTamplate);
	};

	async getRecursosTipo() {
		const { data } = await recursosTipoService.get(
			JSON.parse(localStorage.getItem("fazendaId")).value
		);
		if (data !== undefined) {
			let recursos = Utils.ordernar(data.result, "nome");
			this.setState({
				recursosTipo: ObjectHelper.getRecursoTipoParseToSelect(recursos)
			});
		}
	}

	table() {
		return (
			<>
				<Modal
					show={this.state.showModal}
					editar={this.state.editarModal}
					atividade={this.state.item}
					title={
						this.state.editarModal
							? "Editar Atividade"
							: "Cadastrar Atividade"
					}
					buttonTitle="Adicionar"
					close={() =>
						this.setState({ showModal: false, editarModal: false })
					}
					tipoModal={"RECURSOS"}
					codigoCultura={this.state.dados.culturaCodigo}
					confirmar={this.confirmarItem}
					grupoServicos={this.state.grupoServicos}
					data={this.state.data || this.state.dados.data}
				/>
				<hr />
				<Table
					align="left"
					columns={this.state.dados.culturaCodigo && this.state.dados.culturaCodigo.toUpperCase() === 'CAFE' ? this.columnsCafe : this.columnsGraos}
					actions={this.actions}
					data={this.state.dados.items}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					handleButton={event => this.tableButton.bind(this, event)}
					handleChangeInputTable={event =>
						this.onChange.bind(this, event)
					}
					handleChangeDateTable={event =>
						this.onChangeDate.bind(this, event)
					}
				/>
			</>
		);
	}

	campos() {
		const {
			state: { dados }
		} = this;
		return (
			<Formik
				initialValues={dados}
				enableReinitialize
				validationSchema={TemplateSchema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleChange }) => (
					<form className="form " onSubmit={handleSubmit}>
						<div>
							<div className="row">
								<div className="col-md-6">
									<Title>
										{this.titulo() +
											" Modelo de Manejo"}
									</Title>
								</div>
							</div>
							<CardStyle className="card">
								<div className="card-header row justify-content-start col-md-12">
									<div className="card-header col-md-4">
										<label className="label">Nome:</label>
										<Field
											className="form-control bmd-form-group"
											placeholder="Informe o nome"
											name="nome"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.nome}
											htmlFor="nome"
											autoComplete="off"
											maxLength="40"
											type="text"
										/>
										<ErrorMessage name="nome">
											{msg => (
												<div className="error error-message error-validate">
													{"Campo Obrigatório"}
												</div>
											)}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Cultura:
										</label>
										<Select
											id="culturaId"
											name="culturaId"
											htmlFor="culturaId"
											onChange={e => {
												setFieldValue(
													"culturaId",
													e.value
												);
												var dados = this.state.dados;
												dados.culturaCodigo = e.codigo;

												this.setState({
													dados: dados
												});
											}
											}
											className="select-style ajust-label-padrao disabled-select"
											isDisabled={this.state.visualizar}
											value={this.state.culturas.filter(
												item =>
													item.value ===
													values.culturaId
											)}
											placeholder="Selecione a cultura"
											options={this.state.culturas}
											theme={theme => ({
												...theme,
												borderRadius: 0
											})}
										/>
										<ErrorMessage name="culturaId">
											{msg => (
												<div className="error error-message error-validate">
													{"Campo Obrigatório"}{" "}
												</div>
											)}
										</ErrorMessage>
									</div>

									{(!this.state.dados.culturaCodigo || this.state.dados.culturaCodigo.toUpperCase() !== 'CAFE') && <div
										className="col-md-4"
										style={{ marginTop: 12 }}
									>
										<label className="label">
											Data inicial para simulação:*
										</label>
										<InputMask
											className="form-control bmd-form-group"
											name="data"
											placeholder="Escolha o período inicial"
											htmlFor="data"
											mask="99/99/9999"
											maskChar={null}
											value={this.state.data}
											onChange={item => {
												setFieldValue("data", item);
												this.setState({
													dataSimulacao: true
												});
												this.setState({
													data: item.target.value
												});
											}}
											autoComplete="off"
										/>
										<ErrorMessage name="data">
											{msg => (
												<span className="span-errors">
													Campo Obrigatório{" "}
												</span>
											)}
										</ErrorMessage>
									</div>}
								</div>
								<div className="row col-md-12 flex mt-4">
									<div className="col-md-12">
										<label className="label">
											Descrição:
										</label>
										<CKEditor
											activeClass="editor"
											editor={ClassicEditor}
											config={editorConfiguration}
											data={this.state.descricao}
											onChange={(event, editor) => {
												const data = editor.getData();
												this.setState({
													descricao: data
												});
											}}
										/>
									</div>
								</div>

								<div className="col-md-12 mt-4">
									{/* <button className="btn btn-primary active pull-left" type='button' onClick={this.calcularPrazos.bind(this)} hidden={!this.state.dataSimulacao}>
										<i className='fas fa-check' /> Calcular Prazos
									</button> */}
									{!this.state.id && (
										<button
											className="btn btn-primary active pull-right"
											type="submit"
											hidden={this.state.visualizar}
										>
											<i className="fas fa-check" />{" "}
											Salvar Modelo
										</button>
									)}
									{this.state.id && (
										<button
											className="btn btn-primary active pull-right"
											type="submit"
											hidden={this.state.visualizar}
										>
											<i className="fas fa-check" />{" "}
											Salvar
										</button>
									)}
									<button
										className="btn btn-outline-secondary pull-right"
										type="button"
										onClick={this.voltar}
										style={{ marginRight: 10 }}
									>
										<i className="fas fa-close" /> Cancelar
									</button>
									{this.state.id && (
										<button
											className="btn btn-primary active pull-right"
											type="button"
											onClick={() => this.adicionarItem()}
											hidden={this.state.visualizar}
											style={{ marginRight: 10 }}
										>
											<i className="fas fa-plus"></i>{" "}
											Adicionar Atividade
										</button>
									)}
								</div>

								{this.state.id && this.table()}
							</CardStyle>

							<ModalRecursos
								show={this.state.showModalRecursos}
								close={() =>
									this.setState({ showModalRecursos: false })
								}
								recursos={this.state.recursos}
								insumosAnaliseSolo={[]}
								recursosLista={this.state.recursosLista}
								modalClose={item => this.modalClose(item)}
								id={this.state.id_planejamento}
								area={this.state.areaPlanejamento}
								isPlanejamento={false}
								recursosTipo={this.state.recursosTipo}
								recursosClasse={ObjectHelper.removeInativos(
									this.state.recursosClasse
								)}
								changeRecursoTipo={e =>
									this.changeRecursoTipo(e)
								}
								getRecursosPorClasseId={e =>
									this.getRecursosPorClasseId(e)
								}
								servicoSelecionado={
									this.state.servicoSelecionado
								}
							/>
						</div>
					</form>
				)}
			</Formik>
		);
	}

	render() {
		return <>{this.campos()}</>;
	}
}
