import api from './api';

export default {
	buscarTodas(grupo){
		return api.post(`/api/PessoaTipo/BuscarTodas`, grupo);
	},
	deletar(id){
		return api.delete(`/api/PessoaTipo/${id}`);
	},
	buscar(grupo){
		return api.post(`/api/PessoaTipo`, grupo)
	},
	salvar(grupo){
		return api.post(`/api/PessoaTipo`, grupo)
	},
	buscarPorId(grupoId){
		return api.get(`/api/PessoaTipo/${grupoId}`)
	},
	edit(grupo){
		return api.put(`/api/PessoaTipo`, grupo)
	},
	ativaDesativa(grupo){
		return api.get(`/api/PessoaTipo/Ativar/${grupo}`);
	}
};
