import api from './api';
import moment from 'moment';

export default {
	buscarTodas(param) {
		return api.post(`/api/Equipe/BuscarTodas`, param);
	},
	buscar(id) {
		return api.get(`/api/Equipe/${id}`);
	},
	getMembrosEquipe(fazendaId, equipeId, data) {
		return api.get(`/api/Pessoa/Membros/${fazendaId}/${equipeId}/${data ? moment(data).format('YYYYMMDD') : ''}`);
	},
	editar(equipe) {
		return api.put(`/api/Equipe`, equipe)
	},
	salvar(equipe) {
		return api.post(`/api/Equipe`, equipe)
	},
	deletar(id) {
		return api.delete(`/api/Equipe/${id}`);
	},
	buscarPorId(equipeId) {
		return api.get(`/api/Equipe/BuscarPorId/${equipeId}`)
	},
	ativaDesativa(equipeId) {
		return api.get(`/api/Equipe/AtivaDesativa/${equipeId}`)
	},
};
