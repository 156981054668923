import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	buscarPainelMO: ['params', 'callback'],
	readPainelMO: ['params'],

	getCategorias: ['params', 'callback'],
	setCategorias: ['params'],

	getPessoas: ['params', 'callback'],
	setPessoas: ['safra'],
})

const INITIAL_STATE_VALUES = {
	categorias: [],
	pessoas: [],
	painelMO: [],
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getCategorias = (state = INITIAL_STATE, action) => ({ ...state, categorias: action.data })
const getPessoas = (state = INITIAL_STATE, action) => ({ ...state, pessoas: action.data })
const buscarPainelMO = (state = INITIAL_STATE, action) => ({...state, painelMO: action.data})

export default createReducer(INITIAL_STATE_VALUES, {
	[Types.SET_CATEGORIAS]: getCategorias,
	[Types.SET_PESSOAS]: getPessoas,
	[Types.READ_PAINEL_MO]: buscarPainelMO,
})
