import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	buscarPainelServicos: ['params', 'callback'],
	readPainelServicos: ['params'],

	getLocaisSafra: ['params', 'callback'],
	setLocaisSafra: ['locais'],

	getGrupoServicos: ['params', 'callback'],
	setGrupoServicos: ['grupoServicos'],

	getDadosServicesByGrupo: ['params', 'callback'],
	setDadosServicesByGrupo: ['dadosPorGrupo'],
})

const INITIAL_STATE_VALUES = {
	categorias: [],
	pessoas: [],
	painelMO: [],
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const buscarPainelServicos = (state = INITIAL_STATE, action) => ({ ...state, planejamentos: action.data })
const getLocaisSafra = (state = INITIAL_STATE, action) => ({ ...state, locais: action.data })
const getGrupoServicos = (state = INITIAL_STATE_VALUES, action) => ({...state,grupoServicos: action.data})
const getDadosServicesByGrupo = (state = INITIAL_STATE_VALUES, action) => ({...state,dadosPorGrupo: action.data})

export default createReducer(INITIAL_STATE_VALUES, {
	[Types.READ_PAINEL_SERVICOS]: buscarPainelServicos,
	[Types.SET_LOCAIS_SAFRA]: getLocaisSafra,
	[Types.SET_GRUPO_SERVICOS]: getGrupoServicos,
	[Types.SET_DADOS_SERVICES_BY_GRUPO]: getDadosServicesByGrupo,
})
