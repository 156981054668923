import api from './api';

export default {
	getAll(unidade){
		return api.post(`/api/Unidade/BuscarTodas`, unidade);
	},
	deletar(id){
		return api.delete(`/api/Unidade/${id}`);
	},
	salvar(unidade){
		return api.post(`/api/Unidade`, unidade)
	},
	buscarPorId(unidadeId){
		return api.get(`/api/Unidade/${unidadeId}`)
	},
	editar(unidade){
		return api.put(`/api/Unidade`, unidade)
	}
};