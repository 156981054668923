import moment from "moment";

const format = (
	input,
	opt = {
		prefix: "",
		suffix: "",
		thousands: ".",
		decimal: ",",
		precision: 2
	}
) => {
	if (typeof input === "number") {
		input = input.toFixed(fixed(opt.precision));
	}
	var negative = input.indexOf("-") >= 0 ? "-" : "";

	var numbers = onlyNumbers(input);
	var currency = numbersToCurrency(numbers, opt.precision);
	var parts = toStr(currency).split(".");
	var integer = parts[0];
	var decimal = parts[1];
	integer = addThousandSeparator(integer, opt.thousands);
	return (
		opt.prefix +
		negative +
		joinIntegerAndDecimal(integer, decimal, opt.decimal) +
		opt.suffix
	);
};

const unformat = (input, precision) => {
	var negative = input.indexOf("-") >= 0 ? -1 : 1;
	var numbers = onlyNumbers(input);
	var currency = numbersToCurrency(numbers, precision);
	return parseFloat(currency) * negative;
};

const onlyNumbers = input => {
	return toStr(input).replace(/\D+/g, "") || "0";
};

// Uncaught RangeError: toFixed() digits argument must be between 0 and 20 at Number.toFixed
const fixed = precision => {
	return between(0, precision, 20);
};

const between = (min, n, max) => {
	return Math.max(min, Math.min(n, max));
};

const numbersToCurrency = (numbers, precision) => {
	var exp = Math.pow(10, precision);
	var float = parseFloat(numbers) / exp;
	return float.toFixed(fixed(precision));
};

const addThousandSeparator = (integer, separator) => {
	return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
};

const joinIntegerAndDecimal = (integer, decimal, separator) => {
	return decimal ? integer + separator + decimal : integer;
};

const toStr = value => {
	return value ? value.toString() : "";
};

export default class Utils {
	static formatDate(data) {
		if (data) {
			return moment(data).format("DD/MM/YYYY - HH:mm");
		}
		return null;
	}

	static formatDate2(data) {
		if (data) {
			let dat = data.split(' ')[0];
			dat = dat.split('/');
			let novaData = new Date(dat[2], dat[1] - 1, dat[0])
			return novaData
		}
		return null;
	}

	static validateLatitude(coordinateNumber) {
		if (
			coordinateNumber <= 90 &&
			coordinateNumber >= -90 &&
			coordinateNumber !== ""
		) {
			return true;
		} else {
			return false;
		}
	}

	static validateLongitude(coordinateNumber) {
		if (
			coordinateNumber <= 180 &&
			coordinateNumber >= -180 &&
			coordinateNumber !== ""
		) {
			return true;
		} else {
			return false;
		}
	}

	static unmask(string) {
		return string.replace(/[^a-zA-Z0-9]/g, "");
	}

	static formatNumber(value, options) {
		if (value) return format(value, options);

		return null;
	}

	static unformatNumber(value, precision) {
		return unformat(value, precision);
	}

	static validateAndSum(a, b) {
		if (a && b) {
			return a + b;
		} else if (a) {
			return a;
		} else if (b) {
			return b;
		} else {
			return null;
		}
	}

	static validateNumber(value) {
		return value ? value : undefined;
	}

	static formatCpfCnpj = cpfCnpj => {
		if (!cpfCnpj) return;
		var v = cpfCnpj.toString().replace(/\D/g, "");
		if (v.length <= 11) {
			// CPF
			v = v.replace(/(\d{3})(\d)/, "$1.$2");
			v = v.replace(/(\d{3})(\d)/, "$1.$2");
			v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
		} else {
			v = v.replace(/^(\d{2})(\d)/, "$1.$2");
			v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
			v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
			v = v.replace(/(\d{4})(\d)/, "$1-$2");
		}
		return v;
	};

	static formatCep = cep => {
		if (!cep) return;
		var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
		return cep.replace(re, "$1.$2-$3");
	};

	static formatDatepicker = () => {
		var datepicker_properties = {
			applyLabel: "Selecionar",
			cancelLabel: "Cancelar",
			format: "DD/MM/YYYY",
			separator: " - ",
			fromLabel: "De",
			toLabel: "até",
			daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
			monthNames: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outobro",
				"Novembro",
				"Dezembro"
			]
		};

		return datepicker_properties;
	};

	static isDateValid = (data) => {
		var valid = true;
		var dia = data.split("/")[0];
		var mes = data.split("/")[1];

		if (dia > 31 || mes > 12)
			valid = false

		return valid;
	}

	static formatHour = (item) => {
		let horas = '';
		if (item.toString().length === 4 && item < 10) {
			horas = '0' + item.toString();
			return item = horas.replace(".", ":");
		} else if (item.toString().length === 1) {
			horas = '0' + item.toString();
			horas = horas + ":00"
			return item = horas;
		} else if (item.toString().length === 3) {
			horas = '0' + item.toString();
			return item = horas.replace(".", ":") + "0";
		} else if (item.toString().length === 5) {
			horas = item.toString();
			return item = horas.replace(".", ":");
		} else if (item.toString().length === 4 && item > 10) {
			horas = item.toString();
			return item = horas.replace(".", ":") + "0";
		} else if (item.toString().length === 2) {
			return item = item.toString() + ":00";
		}
	}

	static tableOptions = () => {
		const options = {
			page: 1,
			pageStartIndex: 1,
			paginationSize: 3,
			prePage: "Anterior",
			nextPage: "Proxima",
			paginationPosition: "bottom",
			withFirstAndLast: false,
			alwaysShowAllBtns: true,
			noDataText: true
		};

		return options;
	}

	static validateHours = (hours, setHour) => {

		const splitedHours = hours.split(':', 2);
		const hour = parseInt(splitedHours[0]);
		const minute = parseInt(splitedHours[1]);

		if (hour && (minute || minute === 0)) {

			if ((hour > 24) || (minute > 59) || (hour > 24)) {
				setHour({ text: 'Valor invalido', invalid: true })
			} else {
				setHour({ text: '', invalid: false })
			}
		} else {
			setHour({ text: 'Campo obrigatório', invalid: true })
		}
	}

	static getValuesParseToSelect = (list, valueName, labelName) => {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ key: item[valueName], value: item[valueName], label: item[labelName], ativo: item.ativo ? item.ativo : undefined })
		});
		return options;
	}

	static getValuesActiveParseToSelect = (list, valueName, labelName) => {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			if (item.ativo) {
				options.push({ value: item[valueName], label: item[labelName] })
			}
		});
		return options;
	}

	static removeInactiveToSelect(list) {
		let options = [];
		if (list.length > 0) {
			list.forEach(item => {
				if (item.ativo) {
					options.push(item)
				}
			});
		}
		return options;
	}

	static trataColunaParaOrdenacao = (text) => {
		var primeiraLetra = text[0];
		var textoTratado = primeiraLetra.toUpperCase() + text.slice(1);

		let indexPonto = textoTratado.indexOf(".")
		if (indexPonto > -1) {
			let segundaPalavras = textoTratado.slice(indexPonto + 1)
			var primeiraLetraSegundaPalavras = segundaPalavras[0];
			segundaPalavras = primeiraLetraSegundaPalavras.toUpperCase() + segundaPalavras.slice(1);
			textoTratado = textoTratado.substr(0, indexPonto + 1) + segundaPalavras
		}
		return textoTratado
	}

	static parsePropertToFilter = (property) => {
		let name = null;
		switch (property) {
			case 'maquina':
				name = 'Maquinas.Nome'
				break;
			case 'ServicoTipo':
				name = 'ServicoTipo.Nome'
				break;
			case 'areaNome':
				name = 'Local.Nome'
				break;
			case 'area':
				name = 'Local.Nome'
				break;
			case 'local':
				name = 'Local.Nome'
				break;
			case 'servicoNome':
				name = 'Servico.Nome'
				break;
			case 'servico':
				name = 'Servico.Nome'
				break;
			case 'statusNome':
				name = 'Status.Nome'
				break;
			case 'operador':
				name = 'Pessoa.Nome'
				break;
			case '':
				name = 'Data'
				break;
			case 'isAtivo':
				name = 'Ativo'
				break;
			case 'recursosTipo':
				name = 'RecursosTipo.Nome'
				break;
			case 'categorias':
				name = 'PessoaTipo.Nome'
				break;
			case 'cultura':
				name = 'Cultura.Nome'
				break;
			case 'servico.servicoTipo.nome':
				name = 'Servico.ServicoTipo.Nome'
				break;
			case 'servicoTipo':
				name = 'ServicoTipo.Nome'
				break;
			case 'materiais':
				name = 'Materiais.Nome'
				break;
			case 'Turno':
				name = 'Turno.Nome'
				break;

			case 'responsavel':
				name = 'Responsavel.Nome'
				break;
			case 'pessoa':
				name = 'Pessoa.Nome'
				break;
			case 'familiaMateriais':
				name = 'FamiliaMateriais.Nome'
				break;
			case 'grupoInsumos':
				name = 'GrupoInsumos.Nome'
				break;
			case 'progressoBar':
				name = 'Progresso'
				break;
			case 'atividadeAgricola':
				name = 'atividadeAgricola.Nome'
				break;
			case 'parametros':
				name = 'Parametros.Nome'
				break;
			case 'ordemServicoCodigo':
				name = 'OrdemServico.Id'
				break;
			default:
				name = Utils.trataColunaParaOrdenacao(property)
				break;
		}
		return name

	}

	static ordernar = (lista, propriedade) => {
		let newList = lista
		if (lista.length > 0 && propriedade) {

			newList = lista.sort(function (a, b) {
				if (a[propriedade].toLowerCase() > b[propriedade].toLowerCase()) {
					return 1;
				}
				if (a[propriedade].toLowerCase() < b[propriedade].toLowerCase()) {
					return -1;
				}
				return 0;
			});
		}
		return newList
	}

	static clone(obj) {
		var copy;

		// Handle the 3 simple types, and null or undefined
		if (null == obj || "object" != typeof obj) return obj;

		// Handle Date
		if (obj instanceof Date) {
			copy = new Date();
			copy.setTime(obj.getTime());
			return copy;
		}

		// Handle Array
		if (obj instanceof Array) {
			copy = [];
			for (var i = 0, len = obj.length; i < len; i++) {
				copy[i] = Utils.clone(obj[i]);
			}
			return copy;
		}

		// Handle Object
		if (obj instanceof Object) {
			copy = {};
			for (var attr in obj) {
				if (obj.hasOwnProperty(attr)) copy[attr] = Utils.clone(obj[attr]);
			}
			return copy;
		}

		throw new Error("Unable to copy obj! Its type isn't supported.");
	}

}
