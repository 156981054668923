import Modal from '../../node_modules/react-bootstrap/es/Modal'
import Button from '../../node_modules/react-bootstrap/es/Button'
import React, { Component } from 'react';

export default class ModalHelper extends Component {

	render() {
		const { show, content, title, buttonTitle, submit, close, button2Title, style, dialogClassName } = this.props;

		if (!button2Title) {

			return (

				<Modal show={show} onHide={() => close()} style={style}>
					<Modal.Header closeButton >
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{content}</Modal.Body>
					<Modal.Footer className="footer-modal">
						<Button className="btn-padrao-rehagro btn-modal" variant="success" onClick={() => {
							submit()
						}}>
							{buttonTitle}
						</Button>
					</Modal.Footer>
				</Modal>

			)
		} else {

			return (

				<Modal dialogClassName={dialogClassName} show={show} onHide={() => close()} style={style}>
					<Modal.Header closeButton >
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{content}</Modal.Body>
					<Modal.Footer className="footer-modal">
						<Button className="btn-padrao-rehagro-cancelar modal-2-btn mr-4 wd-30" variant="success" onClick={() => {
							close()
						}}>
							{button2Title}
						</Button>
						<Button className="btn-padrao-rehagro modal-2-btn ml-4 wd-30" variant="success" onClick={() => {
							submit()
						}}>
							{buttonTitle}
						</Button>
					</Modal.Footer>
				</Modal>

			)
		}
	}
}
