import React from 'react'
import {CardStyle} from "../../components/CardStyle/cardstyle.component"
import Title from "../../components/Title/title.component"
import Table from '../../components/Table/table.component'
import FamiliaMaterialService from "../../services/familiaMaterialService"
import ToastHelper from "../../utils/toastHelper"
import Modal from '../../utils/modalHelper'
import SimpleSearch from '../../components/SimpleSearch/simplesSearch'
import Utils from '../../utils/utils'

import { PERMISSAO } from '../../permissoes/permissoes';

const NUMERO_ITENS_POR_PAGINA = 10;

export default class FamiliaMaterialListagem extends React.Component{
    constructor(props) {
		super(props);
		this.state = {
			toxicologicas: [],
            currentPage: 1,
            showModalExcluir: false,
            showModalAtivaDesativa: false,
            idFamiliaMaterialExcluir: '',
            idFamiliaAtivaDesativa: '',
            filtro: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
            },
        }
        this.history = this.props.history;
        this.handleModalExcluir = this.handleModalExcluir.bind(this);
    }

    async componentDidMount(){
		await this.buscar(this.paginate);

    }

    columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Descrição', property: 'descricao'},
		{ id: 2, title: 'Ativo', property: 'isAtivo'},
    ];

    actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_LEITURA,
            action: item => this.visualizar(item),
        },
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			show: item => item.registroSistema,
            permissao: PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_ATUALIZAR,
			action: item => this.editar(item),
        },
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
            permissao: PERMISSAO.MODULO_MAQUINA_ATUALIZAR,
			action: item => this.handleModal(item, 'SETAR_STATUS'),
		},
        {
			id: 4,
			name: 'Excluir',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
    ];

    paginate = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1, pageSize: 10, filtro: '', order: 'Nome', descedescending: false,
        safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
    }

    TIPO_CADASTRO = 'familiaMateriais';

    cadastrar = () => {
        this.history.push(`/${this.TIPO_CADASTRO}/cadastro`);
    }

    editar(item) {
        this.history.push({
            pathname: `/${this.TIPO_CADASTRO}/editar/${item.id}`
		});
    }

    visualizar(item) {
		this.history.push({
            pathname: `/${this.TIPO_CADASTRO}/visualizar/${item.id}`
		});
	}

    async apagar(item) {

        let data = await FamiliaMaterialService.deletar(item);
        if (data.data.isOk) {
            ToastHelper.success("Grupo de insumo excluído com sucesso!");
            await this.buscar(this.paginate);
        } else {
            ToastHelper.error(data.data.mensagem)
        }
        await this.handleModalExcluir();
    }

    async handlePaginacao(page) {
        await this.setState({ currentPage: page });
        let newPaginate = this.paginate
        newPaginate.page = page
        await this.buscar(newPaginate);
    }

    async buscar(param){

        let result = await FamiliaMaterialService.buscarTodas(param);

        if(result.data.result){
            await result.data.result.items.forEach(item => {
                if(item.ativo){
                    item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
                } else {
                    item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
                }
            });

            await this.setState({
                data: {
                    ...result.data.result
            }});
        }
    }

    async handleModal(item) {
        if(item) {

            await this.setState({
                ...this.state,
                idFamiliaAtivaDesativa: item.id,
                showModalAtivaDesativa: !this.state.showModalAtivaDesativa})
        } else {

            await this.setState({
                ...this.state,
                showModalAtivaDesativa: !this.state.showModalAtivaDesativa})
        }
    }

    async onChangeFilter(filtro){
        await this.setState({filtro: filtro});
        let newPaginate = this.paginate
        newPaginate.filtro = filtro
        newPaginate.page = 1
        await this.buscar(newPaginate);
    }

	orderTable = (property, descending) => {
        this.setOrderColumn(Utils.parsePropertToFilter(property));
        this.setDescending(descending);
        let params = this.paginate;
        params.order = Utils.parsePropertToFilter(property);
        params.descedescending = descending;
        this.buscar(params)
	}

	setOrderColumn= (property) => {
		this.setState({...this.state,
			orderColumn: property
		})
	}

	setDescending= (descending) => {
		this.setState({...this.state,
			descending: descending
		})
	}

    async ativaDesativa(item) {
		let dados = await FamiliaMaterialService.ativaDesativa(item);

		if(dados.status === 200) {

			ToastHelper.success("Status do grupo de insumo alterado com sucesso!");
			await this.buscar(this.paginate);
			await this.handleModal(null);
		} else {

			ToastHelper.error("Erro ao tentar alterar o status do grupo de insumo !");
		}
	};


    tableFamiliaMaterial() {
        return (
            <>
                <Table
                    align='left'
                    showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
                    totalElements={this.state.data.total}
                    totalPages={this.state.data.page}
                    columns={this.columns}
                    acoesSingleButton={false}
                    actions={this.actions}
                    data={this.state.data.items}
                    currentPage={this.state.currentPage}
                    onChangePage={page => this.handlePaginacao(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
                />
            </>
        )
    };

    async handleModalExcluir(item) {
		if(item) {

			this.setState({
				...this.state,
				idFamiliaMaterialExcluir: item.id,
				showModalExcluir: !this.state.showModalExcluir})
		} else {

			this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir})
		}
	}

    modalExcluir() {
        return (
            <Modal
                show={this.state.showModalExcluir}
                doubleButtons={true}
                title={"Excluir Família de Material"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => this.handleModalExcluir()}
                submit={() => this.apagar(this.state.idFamiliaMaterialExcluir)}
                close={() => this.handleModalExcluir()}
                content={
                    <h6>{"Deseja realmente excluir esse grupo?"}</h6>
                }
            />
        );
    };

    render(){
        return (
            <div>
                <Title
                    acao="Novo Grupo"
                    permissaoBotao={PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_CRIAR}
                    iconBotao="fas fa-plus"
                    onClick={this.cadastrar}>
                        Grupos de Insumos
                </Title>
                <CardStyle className="card">
                    <SimpleSearch
                        placeHolder="Pesquisar"
                        value={this.state.filtro}
                        submit={filtro => {this.onChangeFilter(filtro)}}
                    />
                    <div className="row">
                        <div className="col-md-12">
                            {this.tableFamiliaMaterial()}
                        </div>
                    </div>
                </CardStyle>
                {this.modalExcluir()}

				<Modal
					show={this.state.showModalAtivaDesativa}
					title={'Ativar / Desativar grupo de insumo'}
					buttonTitle={"Confirmar"}
					button2Title={"Cancelar"}
					buttom2Action={() => this.handleModal(null, 'SETAR_STATUS')}
					submit={() => this.ativaDesativa(this.state.idFamiliaAtivaDesativa)}
					close={() => this.handleModal(null, 'SETAR_STATUS')}
					content={
						<h6>{'Deseja realmente alterar o status deste grupo?'}</h6>
					}
				/>
            </div>
        )
    }
}
