import React, { Component } from 'react';
import ObjectHelper from 'utils/objectHelper';
import Title from "components/Title/title.component";
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import pt from 'date-fns/locale/pt';
import relatorioService from 'services/relatorioService';
import ToastHelper from 'utils/toastHelper';
import localService from "services/localService";
import grupoServicoService from "services/grupoServicoService";
import servicoService from "services/servicoService";
import pessoaTipoService from 'services/categoriaService';
import pessoaService from 'services/pessoaService'
import grupoInsumoService from 'services/familiaMaterialService';
import insumoService from 'services/materiaisService';
import maquinaTipoService from 'services/tipoMaquinaService';
import maquinaService from 'services/maquinaService';
import Select from "react-select";
import MaterialTable from 'material-table';
import { CardStyle } from '../../../components/CardStyle/cardstyle.component';

export default class RelatorioMaoDeObra extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dados: {
				dataInicial: '',
				dataFinal: '',
				localId: '',
				grupoServicoId: '',
				servicoId: '',
				pessoaTipoId: '',
				pessoaId: '',
				grupoInsumoId: '',
				insumoId: '',
				maquinaTipoId: '',
				maquinaId: '',
			},
			relatorios: {},
			schema: {},
			parametros: {},
			local: [],
			grupoServico: [],
			servico: [],
			pessoaTipo: [],
			pessoa: [],
			grupoInsumo: [],
			insumo: [],
			maquinaTipo: [],
			maquina: [],
			dataInicialFlag: false,
			dataFinalFlag: false,
			localFlag: false,
			grupoServicoFlag: false,
			servicoFlag: false,
			pessoaTipoFlag: false,
			pessoaFlag: false,
			grupoInsumoFlag: false,
			insumoFlag: false,
			maquinaTipoFlag: false,
			maquinaFlag: false,
			dadosTabela: [],
			colunas: [],
		}
	}

	async buscar() {
		const { match: { params } } = this.props;
		const { data } = await relatorioService.buscar(params.id);
		this.setState({ relatorios: data.result });
		this.montarValidacao(data.result.parametros)
	}

	montarValidacao(parametros) {
		var obj = {}
		var dataInicialFlag = false;
		var dataFinalFlag = false;
		var localFlag = false;
		var grupoServicoFlag = false;
		var servicoFlag = false;
		var pessoaTipoFlag = false;
		var pessoaFlag = false;
		var grupoInsumoFlag = false;
		var insumoFlag = false;
		var maquinaTipoFlag = false;
		var maquinaFlag = false;

		parametros.forEach(item => {

			if (item.parametro === "dataFinal") {
				dataFinalFlag = true;
				if (item.obrigatorio) {
					obj.dataFinal = Yup.string().required('Campo obrigatório');
				}
			}

			if (item.parametro === "dataInicial") {
				dataInicialFlag = true;
				if (item.obrigatorio) {
					obj.dataInicial = Yup.string().required('Campo obrigatório');
				}
			}

			if (item.parametro === "localId") {
				this.getLocais();
				localFlag = true;
				if (item.obrigatorio) {
					obj.localId = Yup.string().required('Campo obrigatório');
				}
			}

			if (item.parametro === "servicoId") {
				this.getServico();
				servicoFlag = true;
				if (item.obrigatorio) {
					obj.servicoId = Yup.string().required('Campo obrigatório').nullable();
				}
			}

			if (item.parametro === "grupoInsumoId") {
				this.getGrupoInsumos();
				grupoInsumoFlag = true;
				if (item.obrigatorio) {
					obj.grupoInsumosId = Yup.string().required('Campo obrigatório');
				}
			}
			if (item.parametro === "insumoId") {
				this.getInsumos();
				insumoFlag = true;
				if (item.obrigatorio) {
					obj.insumosId = Yup.string().required('Campo obrigatório');
				}
			}
			if (item.parametro === "maquinaId") {
				this.getMaquinas();
				maquinaFlag = true;
				if (item.obrigatorio) {
					obj.maquinaId = Yup.string().required('Campo obrigatório');
				}
			}

			if (item.parametro === "maquinaTipoId") {
				this.getMaquinasTipo();
				maquinaTipoFlag = true;
				if (item.obrigatorio) {
					obj.maquinasTipo = Yup.string().required('Campo obrigatório');
				}
			}

			if (item.parametro === "pessoaId") {
				this.getPessoas();
				pessoaFlag = true;
				if (item.obrigatorio) {
					obj.pessoaId = Yup.string().required('Campo obrigatório');
				}
			}

			if (item.parametro === "pessoaTipoId") {
				this.getPessoasTipo();
				pessoaTipoFlag = true;
				if (item.obrigatorio) {
					obj.pessoasTipoId = Yup.string().required('Campo obrigatório');
				}
			}


			if (item.parametro === "grupoServicoId") {
				this.getGrupoServico();
				grupoServicoFlag = true;
				if (item.obrigatorio) {
					obj.grupoServicoId = Yup.string().required('Campo obrigatório');
				}
			}
		});

		let schema = Yup.object().shape(obj);
		this.setState({
			schema: schema,
			dataInicialFlag: dataInicialFlag,
			dataFinalFlag: dataFinalFlag,
			localFlag: localFlag,
			grupoServicoFlag: grupoServicoFlag,
			servicoFlag: servicoFlag,
			pessoaTipoFlag: pessoaTipoFlag,
			pessoaFlag: pessoaFlag,
			grupoInsumoFlag: grupoInsumoFlag,
			insumoFlag: insumoFlag,
			maquinaTipoFlag: maquinaTipoFlag,
			maquinaFlag: maquinaFlag
		});
	}

	async componentDidMount() {
		await this.buscar();
	}

	async getLocais() {
		let safraId = ObjectHelper.primeiraSafra();
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		let { data } = await localService.getLocaisSafra(fazendaId, safraId);

		this.setState({
			local: data.result,
		})
	}

	async getGrupoServico() {
		let safraId = ObjectHelper.primeiraSafra();
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		const { data } = await grupoServicoService.buscarTodos({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		this.setState({
			grupoServico: data.result.items,
		})
	}

	async getServico(grupoServicoId) {
		let safraId = ObjectHelper.primeiraSafra();
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;

		if (!grupoServicoId) {
			const { data } = await servicoService.buscarTodas({
				fazendaId: fazendaId,
				safraId: safraId,
				page: 1,
				pageSize: 99999,
				order: "Nome"
			});

			this.setState({
				servico: data.result.items,
			});

		} else {
			const { data } = await servicoService.buscarPorGrupoId(
				fazendaId,
				grupoServicoId
			);
			this.setState({
				servico: data.result
			});
		}
	}

	async getPessoasTipo() {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			order: 'Nome',
			pageSize: 1000
		}

		const { data } = await pessoaTipoService.buscarTodas(param);
		this.setState({
			pessoaTipo: data.result.items,
		});
	}

	async getPessoas() {
		let params = {
			fazendaId: (JSON.parse(localStorage.getItem('fazendaId')).value),
			parametro: '',
			page: 1,
			order: 'Nome',
			pageSize: 1000
		}

		const { data } = await pessoaService.buscar(params);
		this.setState({
			pessoa: data.result.items,
		});
	}

	async getGrupoInsumos() {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			pageSize: 1000,
			order: "Nome"
		}

		const { data } = await grupoInsumoService.buscarTodas(param);

		this.setState({
			grupoInsumo: data.result.items,
		})
	}

	async getInsumos() {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			pageSize: 1000,
			order: "Nome"
		}

		const { data } = await insumoService.buscarTodas(param);

		this.setState({
			insumo: data.result.items,
		})

	}

	async getMaquinasTipo() {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			pageSize: 1000,
			order: "Nome"
		}
		const { data } = await maquinaTipoService.buscarTodas(param);
		this.setState({
			maquinaTipo: data.result.items,
		})
	}

	async getMaquinas() {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			pageSize: 1000,
			order: "Nome"
		}
		const { data } = await maquinaService.buscarTodas(param);
		this.setState({
			maquina: data.result.items,
		})
	}

	onSubmit = async (values) => {
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		values.safraId = JSON.parse(localStorage.getItem('safraId')).value;
		const { match: { params } } = this.props;
		values.id = params.id;

		const { data } = await relatorioService.validarRelatorio(values);

		// Não remover esse console.log
		// Ele é necessário para imprimir a SQL como debug do relatório
		// Ticket: https://trello.com/c/123BuYiL/24-colocar-a-op%C3%A7%C3%A3o-para-exibir-sql-executada-no-relat%C3%B3rio
		if (data.result.sql)
			console.log(data.result.sql);
		/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		if (!data.result.isOk) {
			if (data.sqls)
				return ToastHelper.error(data.result.mensagem);
		} else {
			this.setState({
				dadosTabela: data.result.items,
				colunas: data.result.colunas
			})
		}
	}


	async gerarRelatorio(values) {
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		values.safraId = ObjectHelper.multisafra();
		const { match: { params } } = this.props;
		values.id = params.id;
		const { data } = await relatorioService.validarRelatorio(values);
		if (!data.result.isOk) {
			if (data.sqls)
				return ToastHelper.error(data.result.mensagem);
		} else {
			const { data } = await relatorioService.gerarRelatorio(values);

			if (data.size > 0)
				ToastHelper.success('Relátorio gerado com sucesso!');

			let tempLink = document.createElement("a");

			var blob = new Blob([data], {
				type:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			});

			var planilhaURL = window.URL.createObjectURL(blob);
			tempLink = document.createElement("a");
			tempLink.href = planilhaURL;

			var nomeRelatorio = "relatorio_" + this.state.relatorios.nome.replaceAll(" ", "_");
			tempLink.setAttribute("download", `${nomeRelatorio.toLocaleLowerCase()}.xlsx`);
			tempLink.click();
			return;
		}
	}


	render() {
		return (
			<>
				<Title>
					Relatório - {this.state.relatorios.nome}
				</Title>
				<Formik
					initialValues={this.state.dados}
					enableReinitialize
					validationSchema={this.state.schema || null}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({ values, handleSubmit, setFieldValue, }) => (
						<form className="form " onSubmit={handleSubmit}>
							<CardStyle className="card mb-3">
								<div className="card-body">
									<div className="row">
										{
											this.state.dataInicialFlag &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Data Inicial: </label>
												<DatePicker
													id="dataInicial"
													htmlFor="dataInicial"
													name="dataInicial"
													placeholderText="Data Inicial"
													selected={values.dataInicial}
													onChange={(event) => { setFieldValue('dataInicial', event) }}
													className='form-control bmd-form-group margin-date'
													dateFormat="dd/MM/yyyy"
													locale={pt}
													autoComplete="off"
												/>
												<ErrorMessage
													name="dataInicial">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.dataFinalFlag &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Data final:</label>
												<DatePicker
													id="dataFinal"
													htmlFor="dataFinal"
													name="dataFinal"
													placeholderText="Data Final"
													selected={values.dataFinal}
													onChange={(event) => { setFieldValue('dataFinal', event) }}
													className='form-control bmd-form-group margin-date'
													dateFormat="dd/MM/yyyy"
													locale={pt}
													autoComplete="off"
												/>
												<ErrorMessage
													name="dataFinal">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.localFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Locais:</label>
												<Select
													name="localId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('localId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.local)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="localId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.grupoServicoFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Grupo Serviços:</label>
												<Select
													name="grupoServicoId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('grupoServicoId', item ? item.value : undefined);
														this.getServico(item ? item.value : undefined);
														if (!item) {
															setFieldValue('servicoId', item);
															var dados = this.state.dados;
															dados.servicoId = item;
															this.setState({
																dados: dados
															});
														}
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.grupoServico)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="grupoServicoId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.servicoFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Serviços:</label>
												<Select
													name="servicoId"
													isClearable={true}
													placeholder='Selecionar'
													value={this.state.dados.servicoId}
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('servicoId', item ? item.value : undefined);
														var dados = this.state.dados;
														dados.servicoId = item;
														this.setState({
															dados: dados
														});
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.servico)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="servicoId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.pessoaTipoFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Categoria de Pessoa:</label>
												<Select
													name="pessoaTipoId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('pessoaTipoId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.pessoaTipo)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="pessoaTipoId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.pessoaFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Pessoas:</label>
												<Select
													name="pessoaId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('pessoaId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.pessoa)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="pessoaId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.grupoInsumoFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Grupo de Insumos:</label>
												<Select
													name="grupoInsumoId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('grupoInsumoId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.grupoInsumo)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="grupoInsumoId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.insumoFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Insumos:</label>
												<Select
													name="insumoId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('insumoId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.insumo)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="insumoId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{

											this.state.maquinaTipoFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Tipo de Máquina:</label>
												<Select
													name="maquinaTipoId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('maquinaTipoId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.maquinaTipo)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="maquinaTipoId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
										{
											this.state.maquinaFlag > 0 &&
											<div className="col-md-4 mb-relatorio">
												<label className="label">Máquinas:</label>
												<Select
													name="maquinaId"
													isClearable={true}
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={item => {
														setFieldValue('maquinaId', item ? item.value : undefined);
													}}
													options={ObjectHelper.getValuesParseToSelect(this.state.maquina)}
													theme={theme => ({ ...theme, borderRadius: 0 })}
												/>
												<ErrorMessage
													name="maquinaId">
													{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
												</ErrorMessage>
											</div>
										}
									</div>
								</div>
								<div className="row" style={{ padding: 20 }}>
									<div className="col-md-6">
										<a className="btn btn-outline-secondary pull-left" type="button" href="#/relatorios">
											<i className='fas fa-arrow-left' /> Voltar
										</a>
									</div>
									<div className="col-md-6">
										<button className="btn btn-primary active pull-right" type="button" onClick={() => this.gerarRelatorio(values)} >
											<i className='fas fa-download' /> Baixar Relatório
										</button>
										<button style={{ marginRight: 5 }} className="btn btn-primary active pull-right" type="submit" >
											<i className='fas fa-search' /> Visualizar Dados
										</button>
									</div>
								</div>
							</CardStyle>
						</form>
					)}
				</Formik>
				<br></br>
				{
					this.state.dadosTabela.length > 0 &&
					<MaterialTable
						columns={this.state.colunas}
						data={this.state.dadosTabela}
						options={{
							showTitle: false,
							paging: false,
							actionsColumnIndex: -1
						}}
					/>
				}

			</>
		)
	}
}
