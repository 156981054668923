import React, { useState, useEffect } from "react";
import { Creators as ModulosCreators } from "./store/planejamento_safra.ducks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "../../components/Table/table.component";
import { PERMISSAO } from "../../permissoes/permissoes";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import ModalRecursos from "./recursos/modal.recursos";
import planejamentoSafraService from "../../services/planejamentoSafraService";
import grupoLocaisService from "../../services/grupoLocaisService";
import ToastHelper from "./../../utils/toastHelper";
import ModalExcluir from "./../../utils/modalHelper";
import ModalMesclarOS from "./../../utils/modalHelper";
import ModalNovaOS from "./../../utils/modalHelper";
import Title from "../../components/Title/title.component";
import Utils from "../../utils/utils";
import ModalProgresso from "./planejamento_safra.modal.progresso";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import ordemServicoService from "services/ordemServicoService";
import { useSelector, useDispatch } from "react-redux";
import Toolbar from "../../components/BasicPageTopBar/Toolbar";
import localService from "../../services/localService";
import grupoServicoService from "../../services/grupoServicoService";
import servicoService from "../../services/servicoService";
import ObjectHelper from "../../utils/objectHelper";
import { Progress } from "react-sweet-progress";
import ModalDesativar from './modal.desativar';
import ModalEditarSelecionadosEmLote from './recursos/modal.editar.selecionados'
import Tooltip from '@material-ui/core/Tooltip';
import AlertDialog from '../../components/AlertDialog';

function PlanejamentoSafra(props) {

	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [items, setItems] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [recursos, setRecursos] = useState([]);
	const [deleteItem, setDeleteItem] = useState({
		id: null,
		showModal: false
	});
	const [atualizarProgresso, setAtualizarProgresso] = useState({
		id: null,
		ativo: false,
		showModal: false
	});

	const [showModalDesativar, setShowModalDesativar] = useState({
		id: null,
		showModal: false,
		item: {}
	});


	const [id, setId] = useState(false);
	const [orderColumn, setOrderColumn] = useState();
	const [descending, setDescending] = useState(true);
	const [checkedAll, setCheckedAll] = useState(false);
	const [
		showModalExcluirSelecionados,
		setShowModalExcluirSelecionados
	] = useState(false);
	const [showModalMesclarOS, setShowModalMesclarOS] = useState(false);
	const [showModalNovaOS, setShowModalNovaOS] = useState(false);
	const [localSelecionado, setLocalSelecionado] = useState();
	const [servicoSelecionado, setServicoSelecionado] = useState();
	const [itemSelecionado, setItemSelecionado] = useState(null);
	const [utilizaPorcentagem, setUtilizaPorcentagem] = useState(null);
	const dispatch = useDispatch();
	const [filtro, setFiltro] = useState(
		useSelector(state => state.planejamentoSafraNovo.filtroListagem)
	);
	const [pageSize, setpageSize] = useState(filtro.pageSize);
	const [pageSizeSelect, setpageSizeSelect] = useState(filtro.pageSize);
	const [locaisFiltro, setLocaisFiltro] = useState([]);
	const [grupoLocaisFiltro, setGrupoLocaisFiltro] = useState([]);
	const [gruposServicoFiltro, setGruposServicoFiltro] = useState([]);
	const [servicosFiltro, setServicosFiltro] = useState([]);
	const [showModalEditarSelecionados, setShowModalEditarSelecionados] = useState(false);
	const [openAlertDialog, setOpenAlertDialog] = useState(false);
	const [alertDialogTitle, setAlertDialogTitle] = useState(null);
	const [alertDialogText, setAlertDialogText] = useState(null);

	const getLocaisFiltro = async (fazendaId, safraId) => {
		let { data } = await localService.getLocaisSafra(fazendaId, safraId);

		let newLocais = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setLocaisFiltro(newLocais);
	};

	const getGruposLocaisFiltro = async (fazendaId) => {
		let { data } = await grupoLocaisService.getAll({
			fazendaId: fazendaId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGrupoLocaisFiltro(newGrupos);
	};

	const getGruposServicoFiltro = async (fazendaId, safraId) => {
		let { data } = await grupoServicoService.buscarTodos({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGruposServicoFiltro(newGrupos);
	};

	const getServicosFiltro = async (fazendaId, safraId) => {
		let { data } = await servicoService.buscarTodas({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	useEffect(() => {
		if (localStorage.getItem("fazendaId")) {
			let safraId = ObjectHelper.primeiraSafra();
			let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
			props.getLocaisSafra();
			props.getRecursos({
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value
			});

			getLocaisFiltro(fazendaId, safraId);
			getGruposLocaisFiltro(fazendaId);
			getGruposServicoFiltro(fazendaId, safraId);
			getServicosFiltro(fazendaId, safraId);

			setpageSizeSelect({
				value: filtro.pageSize,
				label: filtro.pageSize
			});
			setpageSize(filtro.pageSize);
			setCurrentPage(filtro.page);
			handleFiltro(filtro);
		}
	}, []);

	const setarUnidadesDeRecursos = (items) => {
		items.map(planejamento => {
			planejamento.recursos.map(recurso => {
				if (recurso !== undefined) {
					let units = ObjectHelper.parseValuesUnitsToSelect(
						recurso.unidade
					);
					return recurso.unidades = units;
				} else {
					return recurso.unidades = null;
				}
			})

			return planejamento
		})

	}

	useEffect(() => {
		if (props.planejamentos) {
			setProgressoBar(props.planejamentos.items);
			initPagination();
		}
	}, [props.planejamentos]);

	const setProgressoBar = (dados) => {
		if (dados) {
			dados.forEach(item => {
				item.progressoBar = (
					<Tooltip title={item.status.label}>
						<div className="ml-1 mr-2" style={{ cursor: 'pointer' }} id={item.id} onClick={() => handleModalAtualizarProgresso(item)}>
							<Progress percent={item.progresso} status={item.ativo ? "" : "error"} />
						</div>
					</Tooltip>
				);
			});
		}
	};

	const initPagination = async () => {
		const { data } = await planejamentoSafraService.buscarTodas(filtro);

		setProgressoBar(data.result.items);
		setItems(data.result.items);
		setTotal(data.result.total);
		setPage(data.result.page);
	};

	const handleFiltro = async filtro => {
		filtro.pageSize = pageSize;

		dispatch({
			type: "SET_FILTRO_LISTAGEM_PLANEJAMENTO_SAFRA",
			filtroListagem: filtro
		});

		let dados = await planejamentoSafraService.buscarTodas(filtro);

		setarUnidadesDeRecursos(dados.data.result.items)

		setpageSize(filtro.pageSize);
		setTotal(dados.data.result.total);

		setCurrentPage(filtro.page);
		setPage(dados.data.result.page);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);
		setFiltro(filtro);
	};

	const columns = [
		{ id: 0, title: "", property: "selecionado", checkbox: true },
		{ id: 1, title: "Grupo de Serviços", property: "servicoTipo" },
		{ id: 2, title: "Serviços", property: "servico" },

		{ id: 3, title: "Local", property: "local.nome" },
		{
			id: 4,
			title: "Recursos",
			property: "recursos",
			button: item => tableButton(item),
			buttonText: "Recursos",
			buttonIcon: "fas fa-edit"
		},
		{
			id: 5,
			title: "Total HH",
			property: "hh",
			ordenation: false
		},
		{
			id: 6,
			title: "Total HM",
			property: "hm",
			ordenation: false
		},
		{ id: 7, title: "Data Inicial", property: "inicio", datetime2: true },
		{ id: 8, title: "Data Final", property: "fim", datetime2: true },
		{ id: 9, title: "Progresso", property: "progressoBar" },
		{
			id: 10,
			title: "Ordem de Serviço",
			property: "ordemServicoCodigo",
			link: item => abrirOrdemServico(item)
		},
	];


	const columnsMultiSafra = [
		{ id: 0, title: "", property: "selecionado", checkbox: true },
		{ id: 1, title: "Grupo de Serviços", property: "servicoTipo" },
		{ id: 2, title: "Serviços", property: "servico" },
		{ id: 2, title: "Safra", property: "safra" },
		{ id: 2, title: "Atividade", property: "atividade" },

		{ id: 3, title: "Local", property: "local.nome" },
		{
			id: 4,
			title: "Recursos",
			property: "recursos",
			button: item => tableButton(item),
			buttonText: "Recursos",
			buttonIcon: "fas fa-edit"
		},
		{
			id: 5,
			title: "Total HH/Ha",
			property: "hh",
			ordenation: false
		},
		{
			id: 6,
			title: "Total HM/Ha",
			property: "hm",
			ordenation: false
		},
		{ id: 7, title: "Data Inicial", property: "inicio", datetime2: true },
		{ id: 8, title: "Data Final", property: "fim", datetime2: true },
		{ id: 9, title: "Progresso", property: "progressoBar" },
		{
			id: 10,
			title: "Ordem de Serviço",
			property: "ordemServicoCodigo",
			link: item => abrirOrdemServico(item)
		},
	];

	const actions = [
		{
			id: 1,
			name: "Visualizar",
			icon: "fas fa-eye",
			permissao: PERMISSAO.MODULO_SAFRA_LEITURA,
			action: item => visualizar(item)
		},
		{
			id: 2,
			name: "Editar",
			icon: "fas fa-edit",
			permissao: PERMISSAO.MODULO_SAFRA_ATUALIZAR,
			action: item => editar(item)
		},
		{
			id: 3,
			name: "Progresso",
			icon: "fas fa-tasks",
			permissao: PERMISSAO.MODULO_SAFRA_ATUALIZAR,
			action: item => handleModalAtualizarProgresso(item)
		},
		{
			id: 4,
			name: "Gerar OS",
			icon: "fas fa-tools",
			permissao: PERMISSAO.MODULO_SAFRA_ATUALIZAR,
			action: item => handleModalNovaOS(item)
		},
		{
			id: 5,
			name: "Apagar",
			icon: "fas fa-trash-alt",
			permissao: PERMISSAO.MODULO_SAFRA_EXCLUIR,
			action: item => handleModalExcluir(item)
		},
		{
			id: 6,
			name: 'Cancelar/Restaurar',
			icon: "fas fa-check",
			permissao: PERMISSAO.MODULO_SAFRA_EXCLUIR,
			action: item => handleModalDesativar(item)
		}
	];

	const handleModalExcluir = item => {
		if (item) {
			setDeleteItem({ id: item.id, showModal: !deleteItem.showModal });
		} else {
			setDeleteItem({ id: null, showModal: !deleteItem.showModal });
		}
	};

	const handleModalDesativar = item => {
		if (item) {
			setShowModalDesativar({ id: item.id, showModal: !showModalDesativar.showModal, item: item });
		} else {
			setShowModalDesativar({ id: null, showModal: !showModalDesativar.showModal, item: {} });
		}
	};

	const handleModalExcluirSelecionados = item => {
		if (item) {
			setShowModalExcluirSelecionados(true);
		} else {
			setShowModalExcluirSelecionados(false);
		}
	};

	const handleModalNovaOS = item => {
		if (item) {
			setShowModalNovaOS(true);
		} else {
			setShowModalNovaOS(false);
		}

		setItemSelecionado(item);
	};

	const handleModalMesclarOS = item => {
		if (item) {
			setShowModalMesclarOS(true);
		} else {
			setShowModalMesclarOS(false);
		}
	};

	const handleModalEditarSelecionados = item => {
		if (item) {
			setShowModalEditarSelecionados(true);
		} else {
			setShowModalEditarSelecionados(false);
		}
	};

	const changePageSize = async param => {
		await setpageSizeSelect(param);
		await setpageSize(param.value);
		let newFiltro = { ...filtro };
		newFiltro.pageSize = param.value;
		newFiltro.page = 1;
		setFiltro(newFiltro);

		let dados = await planejamentoSafraService.buscarTodas(newFiltro);

		setarUnidadesDeRecursos(dados.data.result.items)

		dispatch({
			type: "SET_FILTRO_LISTAGEM_PLANEJAMENTO_SAFRA",
			filtroListagem: newFiltro
		});

		setTotal(dados.data.result.total);

		setCurrentPage(1);
		setPage(dados.data.result.page);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);
	};


	const handleModalAtualizarProgresso = item => {
		if (item) {
			setAtualizarProgresso({
				idAtividade: item.id,
				showModal: true,
				progresso: item.progresso,
				nomeServico: item.servico,
				nomeLocal: item.local.nome,
				localId: item.local.id,
				idPlanejamento: item.id,
				ativo: item.ativo,
				servicoId: item.servico.id,
				servicoTipoId: item.servicoTipoId
			});
		} else {
			setAtualizarProgresso({
				id: null,
				showModal: false,
				progresso: 0,
				ativo: false
			});
		}
	};

	const cadastrarRota = () => {
		props.history.push("/planejamento/safra/cadastro");
	};

	const editar = item => {
		props.history.push("/planejamento/safra/editar/" + item.id);
	};

	const visualizar = item => {
		props.history.push("/planejamento/safra/visualizar/" + item.id);
	};

	const abrirOrdemServico = item => {
		props.history.push("/ordemservico/" + item.ordemServicoId);
	};

	const changePage = async params => {
		window.scrollTo(0, 0);

		setCurrentPage(params);
		let newFiltro = { ...filtro };
		newFiltro.page = params;
		setFiltro(newFiltro);

		let dados = await planejamentoSafraService.buscarTodas(newFiltro);

		setarUnidadesDeRecursos(dados.data.result.items)

		setTotal(dados.data.result.total);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);
		setCheckedAll(false);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_PLANEJAMENTO_SAFRA",
			filtroListagem: newFiltro
		});
	};

	const tableButton = item => {
		setShowModal(true);
		setRecursos(item.recursos);
		setId(item.id);
		setLocalSelecionado(item.local);
		setServicoSelecionado(item.servico);
		setUtilizaPorcentagem(item.utilizaPorcentagem);
	};

	const submitRecursos = async (planejamentoId, recursos) => {
		const listaRecursos = recursos.map(item => {
			return {
				quantidade: item.quantidadeTotal,
				porcentagem: item.porcentagem,
				recursosId: item.recurso.id,
				unidadeId: item.unidade.id,
				planejamentoSafraId: planejamentoId
			};
		});

		let result = await planejamentoSafraService.updateRecursos(
			planejamentoId,
			listaRecursos
		);
		if (result.data.isOk) ToastHelper.success("Operação realizada com sucesso");
		else ToastHelper.error("Ocorreu um erro");
	};

	const closeRecursos = async () => {
		setShowModal(false)

		let dados = await planejamentoSafraService.buscarTodas(filtro);

		setarUnidadesDeRecursos(dados.data.result.items)

		setTotal(dados.data.result.total);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);
	}

	const onChangeDate = (item, coluna, event) => {
		if (event !== undefined) {
			item[coluna] = event.target.value;
			var itemsAux = items;
			itemsAux.forEach(element => {
				if (element.id === item.id) {
					element[coluna] = item[coluna];
				}
			});

			setItems([...items], itemsAux);
		}
	};

	const updatePlanejamentos = async () => {
		var obj = [];
		items.forEach(item => {
			obj.push({ id: item.id, inicio: item.inicio, fim: item.fim });
		});
		const { data } = await planejamentoSafraService.updatePlanejamentos(
			obj
		);
		if (data.isOk)
			return ToastHelper.success("Atividades atualizadas com sucesso!");
	};

	const deletePlanejamentoSafra = async () => {
		async function del() {
			await props.deletePlanejamentoSafra(deleteItem.id);
			await setDeleteItem({ showModal: false });
			setTimeout(async function () {
				let dados = await planejamentoSafraService.buscarTodas(filtro);

				setarUnidadesDeRecursos(dados.data.result.items)

				setTotal(dados.data.result.total);
				setProgressoBar(dados.data.result.items);
				setItems(dados.data.result.items);
			}, 800);
		}
		del();
	};

	const novaOS = async () => {
		const { data } = await ordemServicoService.create(itemSelecionado.id);
		if (data.isOk) ToastHelper.success("Operação realizada com sucesso!");
		await setShowModalNovaOS(false);

		let dados = await planejamentoSafraService.buscarTodas(filtro);

		setarUnidadesDeRecursos(dados.data.result.items)

		setTotal(dados.data.result.total);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);

		setItemSelecionado(null);
	};

	const orderTable = async (property, descending) => {
		if (property === "hh" || property === "hm") return;
		setOrderColumn(Utils.parsePropertToFilter(property));
		setDescending(descending);
		let newFiltro = filtro;
		newFiltro.order = Utils.parsePropertToFilter(property);
		newFiltro.descedescending = descending;
		setFiltro(newFiltro);

		let dados = await planejamentoSafraService.buscarTodas(newFiltro);

		setarUnidadesDeRecursos(dados.data.result.items)

		setTotal(dados.data.result.total);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_PLANEJAMENTO_SAFRA",
			filtroListagem: newFiltro
		});
	};

	/*const ToastContent = ({ closeToast, toastProps }) => {
		let quantidade = currentPage === 1 ? `os elementos de 0 a ${pageSize}` : `os elementos de ${(pageSize * currentPage) - pageSize} a ${pageSize * currentPage}`;
		if ((pageSize * currentPage) >= total) quantidade = `os elementos de ${(pageSize * currentPage) - pageSize} a ${total}`

		return (
			<div>
				<div style={{ fontSize: 18, textAlign: 'center' }}>{'⚠ Atenção!'}</div>
				<div style={{ fontSize: 16, textAlign: 'center' }}>{`Você está selecionando ${quantidade} de um total de ${total}.`}</div>
			</div>
		)
	}*/

	const closeAlertDialog = () => {
		setOpenAlertDialog(false);
	};

	const onChangeCheckbox = (item, coluna, event) => {
		if (item.selecionarTudo && item.value && currentPage < page) {
			setAlertDialogTitle('Atenção');
			setAlertDialogText(`Você está selecionando apenas os itens da página atual. Entretando, existem ${page} páginas com o filtro selecionado.`);
			setOpenAlertDialog(true);
		}

		const copyItems = [...items];
		if (item.selecionarTudo) {
			copyItems.forEach(o => {
				o.selecionado = item.value;
				return o;
			});
		} else {
			copyItems.forEach(o => {
				if (item.id === o.id) {
					o.selecionado = !o.selecionado;
					return o;
				}
			});
		}

		setItems(copyItems);

		if (items.some(item => item.selecionado === true)) {
			setCheckedAll(true);
		} else {
			setCheckedAll(false);
		}
	};

	const apagarSelecionados = async () => {
		let selecionados = items.filter(item => item.selecionado === true);
		const { data } = await planejamentoSafraService.DeletePlanejamentos(
			selecionados
		);

		if (data.isOk) ToastHelper.success("Operação realizada com sucesso!");

		let param = filtro;
		param.pageSize = pageSize;
		param.page = currentPage;
		setShowModalExcluirSelecionados(false);

		let dados = await planejamentoSafraService.buscarTodas(param);

		setarUnidadesDeRecursos(dados.data.result.items)

		setTotal(dados.data.result.total);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_PLANEJAMENTO_SAFRA",
			filtroListagem: param
		});
	};

	const trocaGrupoServico = (servicoTipoId) => {

		let novoFiltro = filtro;
		novoFiltro.servicoId = "";
		novoFiltro.servicoTipoId = servicoTipoId;
		dispatch({
			type: "SET_FILTRO_LISTAGEM_PLANEJAMENTO_SAFRA",
			filtroListagem: novoFiltro
		});
		if (servicoTipoId) {
			getFiltroServicosByGrupoId(servicoTipoId);
		} else {
			let safraId = JSON.parse(localStorage.getItem("safraId")) ? ObjectHelper.primeiraSafra() : "";
			let fazendaId = JSON.parse(localStorage.getItem("safraId")) ? JSON.parse(localStorage.getItem("fazendaId")).value : "";
			getServicosFiltro(fazendaId, safraId);
		}

	};

	const getFiltroServicosByGrupoId = async grupoServico => {
		let { data } = await servicoService.buscarPorGrupoId(
			filtro.fazendaId,
			grupoServico
		);

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	const validarMesclarOS = () => {
		var safras = [];
		var culturas = [];

		if (!items) return "NO_ITEMS";
		let selecionados = items.filter(item => item.selecionado === true);

		selecionados.forEach(item => {
			if (!safras.find(x => x.safra === item.safra))
				safras.push(item);

			if (culturas.find(x => x.atividade === item.atividade))
				culturas.push(item);
		})

		if (safras.length > 1 || culturas.legnth > 1) return "ERRO_SAFRA"

		if (selecionados.length <= 1) return "UMA_ATIVIDADE_SELECIONADA";
		if (
			selecionados
				.map(item => item.servico)
				.filter((value, index, self) => self.indexOf(value) === index)
				.length > 1
		)
			return "ATIVIDADES_COM_SERVICOS_DIFERENTES";

		return "OK";
	};

	const getContentMesclarOS = () => {
		const result = validarMesclarOS();
		if (result === "ERRO_SAFRA") {
			return (
				<h6>
					Não é possível mesclar Ordens de serviços de safras ou culturas diferentes.
				</h6>
			)
		}
		if (result === "UMA_ATIVIDADE_SELECIONADA") {
			return (
				<h6 className="mb-4">
					{
						"Devem existir pelo menos duas atividades selecionadas para que as ordens de serviço possam ser mescladas."
					}
				</h6>
			);
		} else if (result === "ATIVIDADES_COM_SERVICOS_DIFERENTES") {
			return (
				<h6 className="mb-4">
					{
						"Não é possível mesclar as ordens de serviço de atividades com serviços diferentes."
					}
				</h6>
			);
		} else if (result === "OK") {
			let selecionados = items.filter(item => item.selecionado === true);
			return (
				<div>
					<div>
						<h6 className="mb-4">
							{
								"Deseja realmente mesclar as ordens de serviço das atividades selecionadas? "
								+ "Caso as atividades selecionadas contenham listas diferentes de insumos ou receitas, "
								+ "as atividades serão mescladas em OS diferentes contendo a mesma lista de insumo/receita. "
								+ "Atenção: toda alteração feita anteriormente nas OS mescladas serão perdidas."
							}
						</h6>
						<h6>Serviço: {selecionados[0].servico}</h6>
						<h6>
							Locais:{" "}
							{selecionados
								.map(item => item.local.nome)
								.filter(
									(value, index, self) =>
										self.indexOf(value) === index
								)
								.join(", ")}
						</h6>
					</div>
				</div>
			);
		}
	};

	const mesclarOS = async () => {
		if (validarMesclarOS() === "OK") {
			let ordensServico = items
				.filter(item => item.selecionado === true)
				.map(item => item.ordemServicoId);

			const { data } = await ordemServicoService.mesclar(ordensServico);
			if (data.isOk)
				ToastHelper.success("Operação realizada com sucesso!");
			else
				ToastHelper.error(data.mensagem);

			setCheckedAll(false);
		}

		setShowModalMesclarOS(false);
	};

	const cronogramaDeServicos = async () => {
		let response = await planejamentoSafraService.downloadCronogramaServicos(filtro);
		let tempLink = document.createElement("a");
		var data = new Blob([response.data], {
			type:
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		});
		var planilhaURL = window.URL.createObjectURL(data);
		tempLink = document.createElement("a");
		tempLink.href = planilhaURL;
		tempLink.setAttribute("download", "cronograma_servicos.xlsx");
		tempLink.click();
	};

	useEffect(() => {
		if (filtro.servicoTipoId) {
			getFiltroServicosByGrupoId(filtro.servicoTipoId)
		}
	}, [])

	const closeModalDesativar = async () => {
		setShowModalDesativar({
			...showModalDesativar,
			id: null,
			showModal: !showModalDesativar.showModal,
			item: {}
		});

		let dados = await planejamentoSafraService.buscarTodas(filtro);
		setProgressoBar(dados.data.result.items);
		setTotal(dados.data.result.total);
		setItems(dados.data.result.items);
	}

	const submitModalEditarSelecionados = async (alteracoes) => {
		let planejamentosId = []
		let copyItems = [...items];
		await copyItems.map(item => {
			if (item.selecionado) {
				planejamentosId.push(item.id)
			}
			return planejamentosId
		})

		let params = { alteracoes: alteracoes, filtro: filtro, planejamentosId: planejamentosId }
		let dados = await planejamentoSafraService.editarEmLote(params);
		setarUnidadesDeRecursos(dados.data.result.items)
		setTotal(dados.data.result.total);
		setProgressoBar(dados.data.result.items);
		setItems(dados.data.result.items);
		setCheckedAll(false)
		setShowModalEditarSelecionados(!showModalEditarSelecionados);
	}

	return (
		<>
			<Title acao="Nova atividade" iconBotao="fas fa-plus">
				Planejamento de safra
			</Title>
			<Toolbar
				handleFiltro={handleFiltro}
				filtroInicial={filtro}
				onClickAdd={() => cadastrarRota()}
				filtroLocais={locaisFiltro}
				filtroGrupoLocais={grupoLocaisFiltro}
				filtroGruposServico={gruposServicoFiltro}
				filtroServicos={servicosFiltro}
				getFiltroServicosByGrupoId={e => getFiltroServicosByGrupoId(e)}
				filtroPeriodo
				filtroStatus
				safras={JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2}
				atividades={JSON.parse(localStorage.getItem('safraId')).length >= 2}
				trocaGrupoServico={trocaGrupoServico}
				temFiltroAvancado
			/>
			<CardStyle className="card  mt-3">
				<div className="col-md-12">
					<div className="row ml-1">
						<div className="col-md-12 mt-4">
							<div className="pull-left mt-5">
								{checkedAll && (
									<>
										<i
											data-tip="Excluir selecionados"
											className="fas fa-trash-alt fa-lg"
											style={{ cursor: 'pointer' }}
											onClick={
												handleModalExcluirSelecionados
											}
										/>
										<ReactTooltip
											place="right"
											type="dark"
											delayShow={200}
											effect="float"
										/>
										<i
											style={{ cursor: 'pointer' }}
											data-tip="Mesclar ordens de serviço"
											className="fa fa-compress-arrows-alt fa-lg ml-3"
											onClick={handleModalMesclarOS}
										/>
										<ReactTooltip
											place="right"
											type="dark"
											delayShow={200}
											effect="float"
										/>
										<i
											style={{ cursor: 'pointer' }}
											data-tip="Editar selecionados"
											className="fa fa-edit fa-lg ml-3"
											onClick={
												handleModalEditarSelecionados
											}
										/>
										<ReactTooltip
											place="right"
											type="dark"
											delayShow={200}
											effect="float"
										/>
									</>
								)}
							</div>
							<div className="pull-right mr-3">
								<strong style={{ fontSize: 15 }}>
									Itens por página:
								</strong>
								<Select
									placeholder="Selecionar"
									className=""
									options={[
										{ label: 10, value: 10 },
										{ label: 20, value: 20 },
										{ label: 50, value: 50 },
										{ label: 100, value: 100 }
									]}
									onChange={item => changePageSize(item)}
									theme={theme => ({
										...theme,
										borderRadius: 5,
										width: 30,
										float: "right",
										border: "1px solid #f1f1f1"
									})}
									value={pageSizeSelect}
								/>
								<br />
							</div>
							<button
								className="btn btn-primary active pull-right mr-3 mt-4"
								type="button"
								onClick={updatePlanejamentos}
							>
								<i className="fas fa-save"></i> Salvar
							</button>
							<button
								className="btn btn-primary active pull-right mt-4"
								type="button"
								onClick={cronogramaDeServicos}
								style={{ marginRight: 10 }}
							>
								<i className="fas fa-calendar"></i> Cronograma
								de Serviços
							</button>
							<a
								href="#/planejamento/importacao"
								className="btn btn-primary active pull-right mt-4"
								type="button"
								style={{ marginRight: 10 }}
							>
								<i className="fas fa-upload"></i> Importar
								Modelo
							</a>
							<a
								href="#/planejamento/linhaBase"
								className="btn btn-primary active pull-right mt-4"
								type="button"
								style={{ marginRight: 10 }}
							>
								<i className="fas fa-sync"></i> Linha de Base
							</a>
						</div>
					</div>
					<Table
						align="left"
						showPaginate={total > pageSize}
						totalElements={total}
						totalPages={page}
						columns={JSON.parse(localStorage.getItem("safraId")).length >= 2 ? columnsMultiSafra : columns}
						actions={actions}
						data={items || []}
						currentPage={currentPage}
						onChangePage={page => changePage(page)}
						emptyMessage="Nenhum item encontrado."
						emptyColSpan={columns ? columns.length + 1 : 1}
						handleButtonTable={event => tableButton(event)}
						handleChangeDate={(item, coluna, event) =>
							onChangeDate(item, coluna, event)
						}
						handleCheckbox={(item, coluna, event) =>
							onChangeCheckbox(item, coluna, event)
						}
						isChecked={checkedAll}
						orderColumn={orderColumn}
						descending={descending}
						orderFunction={orderTable}
						ordenation={true}
					/>
					<hr></hr>
				</div>
			</CardStyle>
			<ModalRecursos
				show={showModal}
				planejamentoId={id}
				recursos={recursos}
				locais={[localSelecionado]}
				servicoSelecionado={servicoSelecionado}
				localSelecionado={localSelecionado ? localSelecionado.nome : ""}
				areaLocal={localSelecionado ? localSelecionado.area : null}
				utilizaPorcentagem={utilizaPorcentagem}
				close={closeRecursos}
				submit={submitRecursos}
			/>
			<ModalExcluir
				show={deleteItem.showModal}
				doubleButtons={true}
				title={"Excluir atividade"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() =>
					setDeleteItem({ id: deleteItem.id, showModal: false })
				}
				submit={deletePlanejamentoSafra}
				close={() =>
					setDeleteItem({ id: deleteItem.id, showModal: false })
				}
				content={<h6>{"Deseja realmente excluir essa atividade?"}</h6>}
			/>

			<ModalDesativar
				show={showModalDesativar.showModal}
				close={closeModalDesativar}
				item={showModalDesativar.item}
			/>

			<ModalProgresso
				show={atualizarProgresso.showModal}
				close={() =>
					setAtualizarProgresso({
						...atualizarProgresso,
						id: null,
						showModal: !atualizarProgresso.showModal,
						progresso: 0,
						ativo: false
					})
				}
				nomeServico={atualizarProgresso.nomeServico}
				nomeLocal={atualizarProgresso.nomeLocal}
				idPlanejamento={atualizarProgresso.idAtividade}
				progresso={atualizarProgresso.progresso}
				atualizarLista={() => initPagination()}
				ativo={atualizarProgresso.ativo}
				local={atualizarProgresso.local}
				servicoTipoId={atualizarProgresso.servicoTipoId}
				servicoId={atualizarProgresso.servicoId}
			/>
			<ModalExcluir
				show={showModalExcluirSelecionados}
				doubleButtons={true}
				title={"Excluir atividades"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => setShowModalExcluirSelecionados(false)}
				submit={apagarSelecionados}
				close={() => setShowModalExcluirSelecionados(false)}
				content={
					<h6>
						{"Deseja realmente excluir as atividades selecionadas?"}
					</h6>
				}
			/>
			<ModalMesclarOS
				show={showModalMesclarOS}
				doubleButtons={true}
				title={"Mesclar Ordens de Serviço"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => setShowModalMesclarOS(false)}
				submit={mesclarOS}
				close={() => setShowModalMesclarOS(false)}
				content={getContentMesclarOS()}
			/>
			<ModalNovaOS
				show={showModalNovaOS}
				doubleButtons={true}
				title={"Gerar Nova Ordem de Serviço"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => setShowModalNovaOS(false)}
				submit={novaOS}
				close={() => setShowModalNovaOS(false)}
				content={
					<h6>
						{
							"Deseja realmente gerar uma nova OS para essa atividade?"
						}
					</h6>
				}
			/>
			<ModalEditarSelecionadosEmLote
				setShowModal={(e) => setShowModalEditarSelecionados(e)}
				showModal={showModalEditarSelecionados}
				planejamentoId={id}
				recursos={recursos}
				locais={[localSelecionado]}
				servicoSelecionado={servicoSelecionado}
				localSelecionado={localSelecionado ? localSelecionado.nome : ""}
				areaLocal={localSelecionado ? localSelecionado.area : null}
				utilizaPorcentagem={utilizaPorcentagem}
				close={() => setShowModalEditarSelecionados(false)}
				submit={submitModalEditarSelecionados}
			/>
			<AlertDialog open={openAlertDialog} title={alertDialogTitle} text={alertDialogText} onClose={closeAlertDialog} />
		</>
	);
}

const mapStateToProps = state => ({
	...state,
	locais: state.planejamentoSafra.locais,
	grupoServicos: state.planejamentoSafra.grupoServicos,
	servicos: state.planejamentoSafra.servicos,
	recursos: state.planejamentoSafra.recursos,
	recursosTipo: Utils.ordernar(state.planejamentoSafra.recursosTipo, "nome"),
	planejamentos: state.planejamentoSafra.planejamentos,
	insumosAnaliseSolo: state.planejamentoSafra.insumosAnaliseSolo
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PlanejamentoSafra);
