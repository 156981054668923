import React, { useState, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { connect } from 'react-redux'
import { CardStyle } from "components/CardStyle/cardstyle.component"
import painelService from 'services/painelService'
import categoriaService from 'services/categoriaService'
import Title from "components/Title/title.component"
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import MaterialTable from 'material-table';

function RelatoriosMa(props) {
	const [columns, setColumns] = useState([])
	const [dados, setDados] = useState([])
	const [inicio, setInicio] = useState("");
	const [categoriaId, setCategoriaId] = useState(null)
	const [categorias, setCategorias] = useState([])

	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
		page: 1,
		pageSize: 10000,
	}

	const filtrar = () => {
		var filtro = {};
		filtro.dataAtual = inicio;
		filtro.categoriaId = categoriaId;
		filtro.fazendaId = paginateParams.fazendaId;
		filtro.safraId = ObjectHelper.multisafra();

		buscarMO(filtro);
	}

	const buscarCategorias = async () => {
		var parametro = paginateParams;
		parametro.pageSize = 1000;
		const { data } = await categoriaService.buscarTodas(parametro);
		if (data.isOk)
			setCategorias(data.result.items)
	}

	const buscarMO = async (filtro) => {
		const { data } = await painelService.buscarRelatorioMO(filtro)
		if (data.isOk) {
			setDados(data.result.dados)
			setColumns(data.result.colunas)
		}
	}

	useEffect(() => {
		async function fetchData() {
			await buscarCategorias();
			setInicio(new Date());
		}
		fetchData();
	}, []);


	const gerarExcel = async () => {
		var filtro = {};
		filtro.page = 1;
		filtro.dataAtual = inicio;
		filtro.categoriaId = categoriaId;
		filtro.fazendaId = paginateParams.fazendaId;
		filtro.safraId = ObjectHelper.multisafra();

		const { data } = await painelService.gerarRelatorioMO(filtro);

		let tempLink = document.createElement("a");

		var blob = new Blob([data], {
			type:
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		});

		var planilhaURL = window.URL.createObjectURL(blob);
		tempLink = document.createElement("a");
		tempLink.href = planilhaURL;


		tempLink.setAttribute("download", `relatorio_horas_funcionarios_mao_de_obra.xlsx`);
		tempLink.click();
		return;
	}


	return (
		<>
			<div className='row'>
				<div className='col'>
					<Title>Painel dos Apontamentos de Mão de Obra da Fazenda {JSON.parse(localStorage.getItem('fazendaId')).label}</Title>
				</div>
			</div>
			<CardStyle className="card">
				<div className="row">
					<div className="col-md-12">
						<div label='Filtros' style={{ margin: 10, fontSize: 18 }}>
							<div className="row col-md-12">
								<div className="card-header col-md-3">
									<label className="label">
										Primeiro dia da Semana :
									</label>
									<DatePicker
										selected={inicio}
										className="form-control bmd-form-group"
										dateFormat="dd/MM/yyyy"
										autoComplete="off"
										onChange={date => {
											setInicio(date);
										}}
										value={inicio}
										locale={pt}
									/>
								</div>
								<div className="card-header col-md-3">
									<label className="label" htmlFor="servicoTipoId">
										Grupo de Colaboradores :
								</label>
									<Select
										isClearable={true}
										placeholder='Selecionar'
										className="select-style ajust-label-padrao disabled-select"
										onChange={item => { setCategoriaId(item ? item.value : null) }}
										options={ObjectHelper.getValuesParseToSelect(categorias)}
										theme={theme => ({ ...theme, borderRadius: 0 })}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<button className="btn btn-primary active pull-right" type="button" onClick={filtrar} style={{ marginBottom: 10, marginTop: -30 }}>
								<i className='fas fa-search' /> Buscar
							</button>
							{
								dados.length > 0 &&
								<button className="btn btn-primary active pull-right" type="button" onClick={gerarExcel} style={{ marginBottom: 10, marginTop: -30, marginRight: 10 }}>
									<i className='fas fa-download' /> Baixar Relatório
								</button>
							}
						</div>
						<br></br>
						{
							dados.length > 0 &&
							<MaterialTable
								title="Quantidade de Horas de Trabalho por cada Funcionário"
								columns={columns}
								data={dados}
								options={{
									paging: false,
									rowStyle: {
										fontSize: 14,
										textAlign: 'right', alignSelf: 'stretch'
									}
								}}
								localization={{
									header: {
										actions: 'Ações'
									},
									body: {
										emptyDataSourceMessage: 'Nenhum registro para exibir',
									},
									toolbar: {
										searchTooltip: 'Pesquisar',
										searchPlaceholder: 'Pesquisar'
									}
								}}
							/>
						}
					</div>
				</div>
			</CardStyle>
			<br></br>
			<div className="row col-md-6">
				<a className="btn btn-outline-secondary pull-left" type="button" href="#/relatorios">
					<i className='fas fa-arrow-left' /> Voltar
				</a>
			</div>
		</>
	)
}


const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withRouter(RelatoriosMa));
