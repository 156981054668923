import * as Yup from 'yup';

export const UsuarioSchema = Yup.object().shape({
	nome: Yup.string().trim()
                .required('Campo obrigatório'),
        userName: Yup.string().trim()
                .required('Campo obrigatório'),
        email: Yup.string().trim()
                .required('Campo obrigatório'),
        perfilId: Yup.string()
                .required('Campo obrigatório').nullable(),
        password: Yup.string().trim()
                .min(6, 'A senha deve conter no minímo 6 digítos')
                .required('Campo obrigatório'),
        confirmacao:Yup.string()
                .oneOf([Yup.ref('password')],  'A senha não confere')
                .required('Campo obrigatório')
});
