import React, { useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux'
import Toggle from 'react-toggle'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Table } from 'reactstrap'
import { Creators as ModulosCreators } from './store/gerador.ducks';
import Select from 'react-select';
import ObjectHelper from '../../utils/objectHelper';
import Modal from './gerador.modal'
import ModalLocais from './gerador.locais.modal'
import templateService from 'services/templatePlanejamentoService'

function GeradorPlanejamentoSafra(props) {
	const { register, handleSubmit, setValue, getValues } = useForm({});
	const [errors, setErrors] = useState({
		templateId: false,
		calendarioId: false,
		safraId: false
	})
	const [showModal, setShowModal] = useState(false);
	const [safra, setSafra] = useState({});
	const [usarDataTemplate, setUsarDataTemplate] = useState(false);
	const [templates] = useState([]);
	const [selectTemplates, setselectTemplates] = useState([]);
	const [showModalLocais, setShowModalLocais] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('fazendaId')) {
			props.getCalendarios()
			props.getLocaisSafra(safra)
			props.getSafra();
		}
	}, []);

	const getTemplates = async safraid => {
		const { data } = await templateService.buscarPorSafraId(safraid);
		await setselectTemplates(data.result)

	}

	const handleModal = useCallback(() => {
		if (getValues().safraId === undefined)
			setErrors({ ...errors, safraId: true })
		else
			setShowModal(true);
	}, [])

	const modalLocaisClose = () => {
		setShowModalLocais(false)
	}

	const modalClose = async (value, locaisSelecionados) => {
		submitForm(getValues(), value, templates, usarDataTemplate, locaisSelecionados)
	}

	const getParametros = () => {
		var parametros = [];
		let possuiSemParametro = false;
		props.locais.forEach((local) => {
			if (local.parametro) {
				if (!parametros.find(x => x.id === local.parametroId)) {
					parametros.push({ id: local.parametroId, nome: local.parametro });
				}
			} else {
				possuiSemParametro = true;
			}
		});
		if (possuiSemParametro) {
			parametros.push({ id: null, nome: 'Sem parametro' });
		}
		return parametros;
	}

	const changeTemplate = (data, item, index) => {
		templates[index] = { parametroId: item.parametro.id, templateId: data.value };
	}

	const handleChangeSafra = async (safraId) => {
		await getTemplates(safraId);
	}

	const exibirEscolhaTemplate = () => {
		let parametros = getParametros();
		if (parametros.length === 0) {
			return null;
		}
		else if (parametros.length === 1) {
			return (
				<div className="card-header col-md-6">
					<label className="label">
						Modelos de Manejo:*
					</label>
					<Select
						name="templateId"
						placeholder='Selecionar'
						className="select-style ajust-label-padrao disabled-select"
						options={selectTemplates}
						onChange={item => {
							templates[0] = { parametroId: null, templateId: item.value };;
						}}
						ref={() => register({ name: "templateId" }, { required: true })}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{errors.templateId && <span className="span-errors">Campo Obrigatório</span>}
				</div>
			);
		} else {
			let parametroTemplate = [];
			parametros.forEach((parametro) => {
				parametroTemplate.push({ parametro: { id: parametro.id, nome: parametro.nome }, template: null })
			});
			return (
				<div className="card-header col-md-12">
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Parametro</th>
								<th>Modelo de Manejo</th>
							</tr>
						</thead>
						<tbody>
							{
								parametroTemplate !== undefined &&
								parametroTemplate.map((item, index) => {
									return <>
										<tr>
											<td style={{ width: '30%' }}>{item.parametro.nome}</td>
											<td style={{ width: 160 }}>
												<Select
													key={item.parametro.id}
													placeholder='Selecionar modelo de manejo...'
													className="select-style ajust-label-padrao disabled-select"
													onChange={data => changeTemplate(data, item, index)}
													options={
														selectTemplates
													}
													theme={theme => ({
														...theme,
														borderRadius: 0
													})}
													ref={() => register({ name: "templates" }, { required: true })}
												/>
											</td>

										</tr>
									</>
								})
							}

						</tbody>
					</Table>
				</div>
			);
		}
	}

	const submitForm = useCallback(async (data, limpar, templates, usarDataTemplate, locaisSelecionados) => {
		data.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		data.safraId = getValues().safraId;
		data.usarDataTemplate = usarDataTemplate;
		data.locaisSelecionados = locaisSelecionados;
		data.templates = templates;
		data.limpar = limpar;
		await props.createGerador(data, props.history);
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col-md-6'>
						<Title>Gerador de Planejamento de Safra</Title>
					</div>
					<div className="col-md-6">
						<button className="btn btn-secundary active pull-right" style={{ marginTop: 10 }} type="button" onClick={() => { setShowModalLocais(true) }}>
							<i className='fas fa-search' />  Locais
						</button>
					</div>
				</div>
				<CardStyle className="card">
					<div className='card-header row justify-content-start col-md-12'>
						<div className="card-header col-md-4">
							<div className="card-header col-md-12">
								<label className="label">
									Safras :*
								</label>
								<Select
									name="safraId"
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									options={ObjectHelper.getValuesParseToSelect(props.safras) || []}
									onChange={item => {
										setValue('safraId', item.value);
										setSafra(item);
										handleChangeSafra(item.value);
										props.getLocaisSafra(item.value);
									}}
									ref={() => register({ name: "safraId" }, { required: true })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.safraId && <span className="span-errors">Campo Obrigatório</span>}
							</div>
							<div className="card-header col-md-12">
								<label className="label">
									Usar data do modelo?
								</label>
								<label className="col-md-12 custom-switch">
									<Toggle
										name='usarDataTemplate'
										defaultChecked={usarDataTemplate}
										className="custom-classname"
										checked={usarDataTemplate}
										icons={{
											checked: <i className='fas fa-check icon-toogle' />,
											unchecked: <i className='fas fa-times icon-toogle' />,
										}}
										onChange={() => {
											var value = !usarDataTemplate;
											setValue('usarDataTemplate', value);
											setUsarDataTemplate(value);
										}}
									/>
								</label>
							</div>
						</div>
						<div className="card-header col-md-8">
							{exibirEscolhaTemplate()}
						</div>
					</div>
				</CardStyle>
				<Modal
					show={showModal}
					safra={safra}
					close={() => setShowModal(false)}
					locais={props.locais}
					modalClose={modalClose}
				/>
				<ModalLocais
					show={showModalLocais}
					nomeSafra={safra.label}
					close={() => setShowModalLocais(false)}
					locais={props.locais}
					modalClose={modalLocaisClose}
					history={props.history}
				/>
				<div className="col-md-12 mt-3">
					<a className="btn btn-outline-secondary pull-left" type="button" href="/#/planejamento/safra">
						<i className='fas fa-close' /> Cancelar
					</a>

					<button className="btn btn-primary active pull-right" type="button" onClick={() => handleModal()}>
						<i className='fas fa-check' /> Gerar Planejamentos
					</button>
				</div>
			</form>
		</>
	)
}

const mapStateToProps = state => {
	return ({
		templates: state.geradorPlanejamentoSafra.templates,
		calendarios: state.geradorPlanejamentoSafra.calendarios,
		locais: state.geradorPlanejamentoSafra.locais,
		safras: state.geradorPlanejamentoSafra.safras
	});
}
const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(GeradorPlanejamentoSafra)
