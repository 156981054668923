import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import api from "../../services/api";
import { Types } from "../ducks/permissao";
import executeAction from "./executeAction";

function* asyncLista(action) {
	yield executeAction(function* () {
		const { usuarioId, callback } = action;

		const { data } = yield call(api.get, `api/Usuario/modulos/${usuarioId}/${JSON.parse(localStorage.getItem('fazendaId')).value}`);

		yield put({
			type: Types.PERMISSAO_SETAR_REDUCER,
			permissoes: data.result
		});

		callback();

	}, Types.PERMISSAO_SETAR);
}

function* takeLista() {
	yield takeLatest(Types.PERMISSAO_SETAR, asyncLista);
}

export default function* root() {
	yield all([fork(takeLista)]);
}
