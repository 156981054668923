import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import unidadeService from '../../services/unidadeService'
import ToastHelper from "../../utils/toastHelper";
import {Types} from "../ducks/unidade"
import executeAction from "./executeAction";

function* asyncLista(action) {
	yield executeAction(function* () {
		const { id, callback } = action;

		const { data } = yield call( async () => await unidadeService.buscarPorId(id));

		yield put({
			type: Types.SETAR_GET_BY_ID,
			data: data
		});

		callback();
	}, Types.GET_BY_ID);
}

function* createAsync(action){
	yield executeAction(function* () {
		const { params } = action;

		const { data } = yield call( async () => await unidadeService.salvar(params));

		if(data.isOk)
			ToastHelper.success('Unidade cadastrado com sucesso!');
		else
			ToastHelper.error("Ocorreu um erro.");

		yield put({
			type: Types.CREATE,
			data
		});

	}, Types.CREATE);
}

function* updateAsync(action){
	yield executeAction(function* () {
		const { params } = action;

		const { data } = yield call( async () => await unidadeService.editar(params));

		if(data.isOk)
			ToastHelper.success('Unidade atualizada com sucesso!');
		else
			ToastHelper.error("Ocorreu um erro.");

		yield put({
			type: Types.UPDATE,
			data
		});

	}, Types.UPDATE);
}

function* takeLista() {
	yield takeLatest(Types.GET_BY_ID, asyncLista);
}

function* create() {
	yield takeLatest(Types.CREATE, createAsync);
}

function* update() {
	yield takeLatest(Types.UPDATE, updateAsync);
}

export default function* root() {
	yield all([fork(takeLista)])
	yield all([fork(create)])
	yield all([fork(update)])
}
