import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Title from "components/Title/title.component";
import planejamentoSafraService from 'services/planejamentoSafraService';
import { CardStyle } from "components/CardStyle/cardstyle.component"
import ToastHelper from 'utils/toastHelper';
import MaterialTable from 'material-table';
import ObjectHelper from 'utils/objectHelper';
import Select from 'react-select';

export default function LinhaBase(props) {
	const { register, handleSubmit, errors } = useForm({});
	const [linhas, setLinhas] = useState([])
	const [copyLinhas, setCopyLinhas] = useState([]);

	const updateLinhaBase = useCallback(async (item) => {
		const { data } = await planejamentoSafraService.updateLinhaBase(item);
		if (data.isOk)
			ToastHelper.success("Operação ralizada com sucesso!");
		else
			ToastHelper.error("Ocorreu um erro!");

		getLinhasBase();
	});

	const excluirItemFunction = async (item) => {
		const { data } = await planejamentoSafraService.deleteLinhaBase(item.id)

		if (data.isOk)
			ToastHelper.success("Linha de base excluída com sucesso!");
		else
			ToastHelper.error("Ocorreu um erro!");

		getLinhasBase();
	}

	const [safraId, setSafraId] = useState("");
	const submitForm = async (values) => {
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		if (JSON.parse(localStorage.getItem('safraId')).length === 1)
			values.safraId = JSON.parse(localStorage.getItem('safraId'))[0].value
		else
			values.safraId = safraId

		try {
			const { data } = await planejamentoSafraService.insetLinhaBase(values);


			if (!data.isOk)
				return ToastHelper.error(data.mensagem)
			else
				ToastHelper.success("Linha de base salva com sucesso!");

		} catch (e) {

		}
		getLinhasBase();
	};


	const getLinhasBase = async () => {
		const { data } = await planejamentoSafraService.getLinhasBase({
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			safraIds: ObjectHelper.multisafra()
		});
		setLinhas(data.result)
	}

	useEffect(() => {
		getLinhasBase();
	}, []);


	useEffect(() => {
		if (linhas) {
			// É necessário fazer essa cópia devido a esse bug da Material-Table
			// https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
			setCopyLinhas(JSON.parse(JSON.stringify(linhas)));
		}
	}, [linhas]);

	const colunasLinhas = [
		{ title: 'Nome', field: 'nome' },
		{ title: 'Data', field: 'data', editable: 'never' },
	]

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							Linha de Base
						</Title>
					</div>
				</div>
				<CardStyle className="card">
					<div className=' row justify-content-start col-md-12'>
						<div className="card-header col-md-5">
							<label className="label">
								Nome :*
							</label>
							<input type="text"
								placeholder="Nome..."
								className='form-control bmd-form-group'
								autoComplete="off"
								name="nome"
								ref={register({ required: true })}>
							</input>
							{errors.nome && <span className="span-errors"> Campo Obrigatório</span>} <br />
						</div>
						{
							JSON.parse(localStorage.getItem("safraId")).length > 1 &&
							<div className="card-header col-md-5">
								<label className="label">
									Safras :*
									</label>
								<Select
									id="recursos"
									name="recursos"
									placeholder='Selecione...'
									className="select-style ajust-label-padrao disabled-select"
									options={JSON.parse(localStorage.getItem("safraId")) || []}
									onChange={item => {
										setSafraId(item.value);
									}}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.recursos && <span className="span-errors">Campo obrigatório</span>}

							</div>
						}

						<div className="col-md-2 pull-left">
							<button className="btn btn-primary active" style={{ marginTop: 50 }} type="submit">
								<i className='fas fa-check' /> Salvar
							</button>
						</div>
					</div>
					<hr></hr>

				</CardStyle>
			</form>
			<hr></hr>
			<MaterialTable
				title="Linhas"
				columns={colunasLinhas}
				data={copyLinhas}
				options={{
					paging: false,
					actionsColumnIndex: -1
				}}

				editable={{
					onRowUpdate: (newData, oldData) =>
						new Promise((resolve) => {
							updateLinhaBase(newData, oldData);
							resolve();
						}),
					onRowDelete: item =>
						new Promise((resolve) => {
							excluirItemFunction(item)
							resolve();
						}
						),

				}}
				localization={{
					header: {
						actions: 'Ações'
					},
					body: {
						emptyDataSourceMessage: 'Nenhum registro para exibir',
						addTooltip: 'Adicionar',
						deleteTooltip: 'Excluir',
						editTooltip: 'Editar',
						editRow: {
							deleteText: 'Deseja excluir essa linha?',
							cancelTooltip: 'Cancelar',
							saveTooltip: 'Confirmar'
						}
					},
					toolbar: {
						searchTooltip: 'Pesquisar',
						searchPlaceholder: 'Pesquisar'
					}
				}}
			/>
			<br></br>
			<button
				className="btn btn-outline-secondary pull-left"
				type="button"
				onClick={() => window.history.back()}>
				<i className="fas fa-close" /> Cancelar
			</button>
		</>
	)
}
