import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Title from "../../components/Title/title.component";
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import { Creators as ModulosCreators } from './store/grupo_maquina.ducks';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';

function GrupoMaquinasCadastro(props) {
	const [visualizar, setvisualizar] = useState(false);
	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue } = useForm({});

	useEffect(() => {
		if (params.modo === 'visualizar') setvisualizar(true);
		if (params.id !== undefined) props.getByIdGrupoMaquina(params.id);

	}, []);

	useEffect(() => {
		if (params.id !== undefined && props.item) {
			setValue('nome', props.item.nome);
			setValue('descricao', props.item.descricao);
		}
	}, [props.item]);

	const submitForm = useCallback((data) => {
		data.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		if (params.id === undefined) props.createGrupoMaquina(data, props.history)
		if (params.id !== undefined) {
			data.id = params.id
			props.updateGrupoMaquina(data, props.history)
		}
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							{visualizar ? 'Visualizar ' :
								(params.id ? 'Editar ' :
									'Cadastrar '
								)} Grupo de Máquinas
						</Title>
					</div>
				</div>
				<CardStyle className='card'>
					<div className='card-header row justify-content-start col-md-12'>
						<div className='card-header col-md-12'>
							<label className='label'>
								Nome:*
							</label>
							<input type='text'
								className='form-control bmd-form-group'
								readOnly={visualizar} name='nome'
								autoComplete="off"
								ref={register({ required: true })}
							/>
							{errors.nome && <span className='span-errors'> Campo Obrigatório</span>}
							<br />
							<label className='label'>
								Descrição:
							</label>
							<input type='text'
								className='form-control bmd-form-group'
								readOnly={visualizar} name='descricao'
								autoComplete="off"
								ref={register({ required: false })}
							/>
						</div>
					</div>
				</CardStyle>
				<SubmitFormButtons
					flagVisualizar={visualizar}
					flagEditar={params.id !== undefined}
					actionGoBack={() => props.history.goBack()}
				/>
			</form>
		</>
	);
}

const mapStateToProps = (state) => ({
	item: state.grupoMaquinas.result
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GrupoMaquinasCadastro)
	;
