import React from "react";

import { CardStyle } from 'components/CardStyle/cardstyle.component';
import DatePicker from "react-datepicker";
import pt from 'date-fns/locale/pt';
import Select from 'react-select';

export default function RegisterManpowerHead(props) {


	return (
		<>
			<div className="w-100 mb-3">
				<CardStyle className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className={JSON.parse(localStorage.getItem("safraId")).length > 1 ? "col-md-4" : "col-md-6"}>
								<label className="label"> Data:*                </label>
								<DatePicker
									placeholderText={'Selecione uma data'}
									disabled={props.visualize || props.edit}
									className='form-control'
									selected={props.date}
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									onChange={(item) => {
										props.setDate(item)
									}}
									locale={pt}
								/>
							</div>
							{
								!props.disabled && JSON.parse(localStorage.getItem("safraId")).length > 1 &&
								<div className="col-md-4">
									<label className="label">Safras:*</label>
									<Select
										hideSelectedOptions
										value={props.form.safra}
										onChange={(item) => {
											props.setSafras(item)
										}}
										isDisabled={props.visualize || props.edit}
										className="select-style"
										placeholder={'Selecione uma safra'}
										options={JSON.parse(localStorage.getItem("safraId"))}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
										name="safras"
									/>
								</div>
							}
							{
								!props.disabled &&
								<div className={JSON.parse(localStorage.getItem("safraId")).length > 1 ? "col-md-4" : "col-md-6"}>
									<label className="label">Equipe:*  </label>
									<Select
										hideSelectedOptions
										value={props.team}
										onChange={(item) => {
											props.setTeam(item)
										}}
										isDisabled={props.visualize || props.edit}
										className="select-style"
										placeholder={'Selecione a equipe'}
										options={props.teamOptions}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
										name="team"
									/>
								</div>
							}
							{
								props.disabled &&
								<div className="col-md-4 display-block">
									<label className="label">Equipe</label>
									<input className="form-control" type="text" autoComplete="off" disabled value={props.team ? props.team.label : ''} />
								</div>
							}
						</div>
					</div>
				</CardStyle>
			</div>
		</>
	);
}
