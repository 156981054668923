import "../maquina.scss";
import React, { Component } from 'react';
import { CardStyle } from './maquina.cadastro.style';
import Select from 'react-select';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from '../../../../node_modules/yup';
import MaquinaService from '../../../services/maquinaService'
import DatePicker from "react-datepicker";
import pt from 'date-fns/locale/pt';
import Toggle from 'react-toggle';
import NumberFormat from 'react-number-format';
// import DropzoneFile from './dragAndDropFile'
import Modal from '../../../components/CommonModal/commonModal'
import ToastHelper from '../../../utils/toastHelper';
import UploadService from '../../../services/uploadService';
import grupoMaquinasService from '../../../services/grupoMaquinaService';
import GrupoLocaisService from '../../../services/grupoLocaisService';
import ModalTipoMaquina from '../../../components/CommonModal/commonModal'
import TipoMaquinaService from "../../../services/tipoMaquinaService";
import Utils from "../../../utils/utils"

export default class FormularioApontamentoMaquina extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModalGrupoMaquina: false,
			showModalTipoMaquina: false,
			fazendaIf: undefined,
			tiposMaquinas: undefined,
			grupoMaquinas: [],
			grupoLocais: [],
			imagem: undefined,
			maquina: {
				nome: undefined,
				marca: undefined,
				modelo: undefined,
				tipoMaquinaId: undefined,
				anoFabricacao: undefined,
				dataAquisicao: undefined,
				grupoLocalId: undefined,
				valor: undefined,
				imagem: undefined,
				horimetroAtivo: true,
				implemento: false,
				observacao: undefined,
				horimetroAtual: undefined,
			}
		}

	}

	async UNSAFE_componentWillMount() {
		this.onSubmit = this.onSubmit.bind(this)
		this.handleClearForm = this.handleClearForm.bind(this)
		await this.getTiposMaquinas();
		await this.getGrupoLocais();
		this.baseState = { ...this.state }
	}

	handleClearForm = async () => {

		await this.setState({
			...this.baseState,
		});
	}

	getTiposMaquinas = async () => {

		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
		let param = {
			fazendaId: fazendaAtual.value,
			page: 1,
			pageSize: 9999,
			order: "Nome"
		}

		const { data } = await MaquinaService.buscarTiposDeMaquinas(param);
		if (data.isOk) {
			await this.setState({
				...this.state,
				tiposMaquinas: Utils.removeInactiveToSelect(Utils.getValuesParseToSelect(data.result.items)),
			});
		} else {

			ToastHelper.error("Erro ao buscar máquinas.");
		}

		let grupo = await grupoMaquinasService.getAll(param);

		if (grupo.data.isOk) {
			await this.setState({
				...this.state,
				grupoMaquinas: Utils.removeInactiveToSelect(Utils.getValuesParseToSelect(grupo.data.result.items)),
			});
		} else {
			ToastHelper.error("Erro ao buscar máquinas.");
		}
	}

	async getGrupoLocais() {
		let params = {
			fazendaId: (JSON.parse(localStorage.getItem('fazendaId')).value),
			parametro: '',
			page: 1,
			pageSize: 1000,
			order: "Nome",
			descedescending: false
		}
		let result = await GrupoLocaisService.getAll(params)
		await this.setState({
			...this.state,
			grupoLocais: Utils.removeInactiveToSelect(Utils.getValuesParseToSelect(result.data.result.items)),
		});
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];
		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	getValuesParseToTable(list, tipo) {
		let options = [];

		if (tipo === 'MEMBROS') {
			list = Array.isArray(list) ? list : [];
			list.forEach(item => {
				if (item.isSelect) {

					options.push({ id: item.value, nome: item.label })
				}
			});
		} else {
			list = Array.isArray(list) ? list : [];
			list.forEach(item => {
				options.push({ id: item.value, nome: item.label })
			});
		}
		return options;
	}

	handleTipoMaquinaSelecionado = async (event) => {
		if (event) {
			if (event) {
				await this.setState({
					...this.state,
					maquina: {
						...this.state.maquina,
						tipoMaquinaId: event.value
					}
				});
			} else {
				await this.setState({
					...this.state,
					maquina: {
						...this.state.maquina,
						tipoMaquinaId: undefined
					}
				});
			}
		}
	}

	onChangeDateAquisicao = (event) => {
		if (event) {

			this.setState({
				...this.state,
				maquina: {
					...this.state.maquina,
					dataAquisicao: event
				}
			})
		}
	}

	handleChangeHorimetro = async () => {
		let novoHorimetro = !this.state.maquina.horimetroAtivo
		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				horimetroAtivo: novoHorimetro
			}
		})
	}

	handleChangeImplemento = async () => {
		let novoImplemento = !this.state.maquina.implemento
		await this.setState({
			...this.state,
			maquina: {
				...this.state.maquina,
				implemento: novoImplemento
			}
		})
	}

	validateDataAquisicao() {
		let error;

		if (this.state.maquina.dataAquisicao === (null || undefined)) {
			error = 'Selecione uma data de aquisição da máquina.';
		}
		return error;
	};

	onChangeHorimetroAtual(value) {
		if (value) {

			this.setState({
				...this.state,
				maquina: {
					...this.state.maquina,
					horimetroAtual: value.replace(',', '.')
				}
			})
		} else {

			this.setState({
				...this.state,
				maquina: {
					...this.state.maquina,
					horimetroAtual: undefined
				}
			})
		}
	}

	validateHorimetroAtual() {
		let error;

		if (this.state.maquina.horimetroAtivo && !this.state.maquina.horimetroAtual) {

			error = 'Adicione o horimetro atual da máquina.';
		}
		return error;
	}

	async setImage(imagemNova) {
		const formData = new FormData();
		formData.append('file', imagemNova[0]);
		let image = await UploadService.upload(formData);
		if (image !== undefined)
			this.setState({ diretorio: image.data.diretorio, imagem: imagemNova[0].name });
	}

	async voltar() {
		if (this.state.diretorio !== undefined)
			await UploadService.remove({ diretorio: this.state.diretorio })

		this.props.voltar();
	}

	async removerImagem() {
		await UploadService.remove({ diretorio: this.state.diretorio })
		this.setState({ diretorio: undefined, imagem: undefined });

	}

	async visualizarImagem() {
		let response = await UploadService.download({ diretorio: this.state.diretorio })
		// var linkSource = Buffer.from(response.data, 'binary').toString('base64');
		// // const downloadLink = document.createElement("a");
		// // const fileName = this.state.imagem;

		// // var blob = new Blob([linkSource], {type: "data:image/png;base64"});
		// // // saveAs(blob, "hello world.png");

		// window.location.href = 'data:application/png;base64,' + response.data;

		const linkSource = `data:application/png;base64,${response.data}`;

		const downloadLink = document.createElement('a');

		downloadLink.href = linkSource;
		downloadLink.target = '_self';
		downloadLink.download = "teste.png";
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}

	async onSubmit(value) {
		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));

		let newValor = ''
		if (value.valor) {
			if (typeof value.valor === "string") {
				newValor = value.valor.replace('R$', '')
				newValor = newValor.replace(',', '.')
				newValor = newValor.replace('.', '')
			} else {
				newValor = value.valor
			}
		}
		value.maquina = {
			anoFabricacao: value.anoFabricacao,
			dataAquisicao: value.dataAquisicao,
			fazendaId: fazendaAtual.value,
			foto: this.state.imagem,
			horimetroAtivo: this.state.maquina.horimetroAtivo,
			horimetroAtual: this.state.maquina.horimetroAtual ? this.state.maquina.horimetroAtual.replace(',', '.') : "",
			implemento: this.state.maquina.implemento,
			maquinaTipoId: value.tipoMaquinaId,
			grupoMaquinasId: value.grupoMaquinasId,
			grupoLocalId: value.grupoLocalId,
			marca: value.marca,
			modelo: value.modelo,
			nome: value.nome,
			observacao: value.observacao,
			valor: newValor,
		}

		const { data } = await MaquinaService.cadastrar(value.maquina)

		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!")
			this.handleClearForm();
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
	}


	async modalClose(values) {
		if (values) {
			values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
			let result = await grupoMaquinasService.salvar(values);
			if (result.data.isOk) {
				this.setState({ showModalGrupoMaquina: false })
				ToastHelper.success("Grupo de Máquina cadastrado com sucesso");
			}

			let grupo = await grupoMaquinasService.getAll({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				page: 1,
				pageSize: 1000
			});

			if (grupo.data.isOk)
				await this.setState({
					...this.state,
					showModalGrupoMaquina: false,
					grupoMaquinas: this.getValuesParseToSelect(grupo.data.result.items),
				});
			else
				ToastHelper.error("Erro ao buscar Grupos de máquinas.");
		}
	}

	async modalTipoClose(values) {
		if (values) {
			values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
			let result = await TipoMaquinaService.salvar(values);

			if (result.data.isOk) {
				this.setState({ showModalTipoMaquina: false })
				ToastHelper.success("Tipo de Máquina cadastrado com sucesso");
			}

			let tipo = await TipoMaquinaService.buscarTodas({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				page: 1,
				pageSize: 1000
			});

			if (tipo.data.isOk)
				await this.setState({
					...this.state,
					showModalTipoMaquina: false,
					tiposMaquinas: this.getValuesParseToSelect(tipo.data.result.items),
				});
			else
				ToastHelper.error("Erro ao buscar tipos de máquinas.");
		}
	}

	render() {
		const signupValidationSchema = Yup.object().shape({
			nome: Yup.string()
				.required('Adicione um nome para a máquina.'),
			tipoMaquinaId: Yup.string()
				.required('Adicione um tipo para a  máquina.')
		});

		return (
			<CardStyle className="card">
				<Formik
					initialValues={{
						nome: undefined,
						marca: undefined,
						modelo: undefined,
						tipoMaquinaId: undefined,
						grupoMaquinasId: undefined,
						anoFabricacao: undefined,
						dataAquisicao: undefined,
						valor: undefined,
						horimetroAtual: undefined,
					}}
					validationSchema={signupValidationSchema}
					onSubmit={this.onSubmit}
				>
					{({ touched, errors, status, setFieldValue, handleSubmit, validateField, validateForm, isValidating, resetForm, handleReset, values, handleChange }) => (
						<form className="form" onSubmit={handleSubmit}>
							<div className="tab-pane fade show active" id="servicos" role="tabpanel" >
								<div className="col-md-12 flex   mt-5">
									<div className="col-md-4">
										<label className="label">
											Nome:*
                                        </label>
										<Field htmlFor="nome"
											placeholder="Informe o nome"
											type="text"
											maxLength="40"
											name="nome"
											autoComplete="off"
											className="form-control bmd-form-group" />
										<ErrorMessage
											name="nome">{msg => <div className="error error-message error-validate">{msg}</div>}
										</ErrorMessage>
									</div>
									<div className="col-md-4">
										<label className="label">
											Marca:
                                        </label>
										<Field htmlFor="marca"
											placeholder="Informe a marca"
											type="text"
											maxLength="40"
											autoComplete="off"
											name="marca"
											className="form-control bmd-form-group" />
									</div>
									<div className="col-md-4">
										<label className="label">
											Modelo:
                                        </label>
										<Field htmlFor="modelo"
											placeholder="Informe o modelo"
											type="text"
											name="modelo"
											maxLength="40"
											autoComplete="off"
											className="form-control bmd-form-group" />
									</div>
								</div>
								<div className="col-md-12 flex  mt-5">
									<div className="col-md-4">
										<label className="label">
											Data de aquisição:
                                        </label>
										<DatePicker
											id="dataAquisicao"
											htmlFor="dataAquisicao"
											name="dataAquisicao"
											placeholderText="Data de aquisição"
											selected={this.state.maquina.dataAquisicao}
											onChange={(event) => { this.onChangeDateAquisicao(event); setFieldValue('dataAquisicao', event) }}
											className='form-control bmd-form-group margin-date'
											dateFormat="dd/MM/yyyy"
											locale={pt}
											autoComplete="off"
										/>
									</div>
									<div className="col-md-4">
										<label className="label">
											Tipo de máquina:*
                                        </label>
										<div className="input-group mb-3">
											<Select id="tipoMaquinaId"
												name="tipoMaquinaId"
												className="form-control select-ajust"
												htmlFor="tipoMaquinaId"
												options={this.state.tiposMaquinas}
												onChange={(event) => { this.handleTipoMaquinaSelecionado(event); setFieldValue('tipoMaquinaId', event.value) }}
												placeholder="Informe o tipo de máquina"
												hideSelectedOptions
												theme={theme => ({
													...theme,
													borderRadius: 0,
												})}
											/>
											<div className="input-group-prepend">
												<button className="btn-custom float-right " type='button' onClick={() => this.setState({ showModalTipoMaquina: true })} >
													<i className="fas fa-plus"></i>
												</button>
											</div>
											<ErrorMessage name="tipoMaquinaId">
												{msg => <div className="error error-message error-validate">{msg}</div>}
											</ErrorMessage>
										</div>
									</div>

									<div className="col-md-4">
										<label className="label">
											Grupo de máquina:*
                                        </label>
										<div className="input-group mb-3">
											<Select
												id="grupoMaquinasId"
												name="grupoMaquinasId"
												className="form-control select-ajust"
												options={this.state.grupoMaquinas}
												onChange={(event) => { setFieldValue('grupoMaquinasId', event.value) }}
												placeholder="Informe o Grupo da máquina"
												hideSelectedOptions
												theme={theme => ({
													...theme,
													borderRadius: 0,
												})}
											/>
											<div className="input-group-prepend">
												<button className="btn-custom float-right " type='button' onClick={() => this.setState({ showModalGrupoMaquina: true })} >
													<i className="fas fa-plus"></i>
												</button>
											</div>
											<ErrorMessage name="grupoMaquinasId">
												{msg => <div className="error error-message error-validate">{msg}</div>}
											</ErrorMessage>
										</div>
									</div>

								</div>
								<div className="col-md-12 flex  mt-5">

									<div className="col-md-4">
										<label className="label">
											Valor:
                                        </label>
										<NumberFormat
											htmlFor="valor"
											autoComplete="off"
											name="valor"
											thousandSeparator={'.'}
											decimalSeparator={','}
											prefix={'R$'}
											className='form-control bmd-form-group'
											placeholder="Insira o valor"
											onChange={handleChange}
											value={values.valor}
										/>
									</div>
									<div className="col-md-4">
										<label className="label">
											Ano de fabricação:
                                        </label>
										<Field htmlFor="anoFabricacao"
											pattern="[0-9,.]*"
											placeholder="Informe o ano de Fabricação"
											type="number"
											name="anoFabricacao"
											autoComplete="off"
											className="form-control bmd-form-group " />
									</div>
									<div className="col-md-4">
										<label className="label">
											Fazenda:
                                        </label>
										<div className="input-group mb-3">
											<Select
												id="grupoLocalId"
												name="grupoLocalId"
												className="form-control select-ajust"
												options={this.state.grupoLocais}
												onChange={(event) => { setFieldValue('grupoLocalId', event.value) }}
												placeholder="Informe a Fazenda"
												hideSelectedOptions
												theme={theme => ({
													...theme,
													borderRadius: 0,
												})}
											/>
										</div>
									</div>
								</div>
								<hr />
								<div className="col-md-12 flex  mt-5">

									<div className="custom-control flex col-md-6">
										<div className="col-md-6 center-toggle">
											<div className="col-md-12 label-horas">
												<label>Horimetro Ativo ?</label>
											</div>
											<label className="col-md-12 custom-switch">
												<Toggle
													defaultChecked={this.state.maquina.horimetroAtivo}
													icons={{
														checked: <i className="fas fa-check icon-toogle" />,
														unchecked: <i className="fas fa-times icon-toogle" />,
													}}
													onChange={this.handleChangeHorimetro} />
											</label>
										</div>
										<div className="col-md-6  center-toggle-implemento">
											<div className="col-md-12 label-horas">
												<label>É um implemento ?</label>
											</div>
											<label className="col-md-12 custom-switch">
												<Toggle
													defaultChecked={this.state.maquina.implemento}
													icons={{
														checked: <i className="fas fa-check icon-toogle" />,
														unchecked: <i className="fas fa-times icon-toogle" />,
													}}
													onChange={this.handleChangeImplemento} />
											</label>
										</div>
									</div>
									<div data-tip={(!this.state.temHorimetro ? "Desabilitado, máquina com horimetro parado." : "Insira as horas do horimetro. Valor maximo permitido 99999,9")}
										className="col-md-6">
										<label className="label">
											Horimetro atual:*
                                        </label>
										<Field htmlFor="horimetroAtual"
											placeholder="Informe o horimetro Atual"
											onChange={(event) => { this.onChangeHorimetroAtual(event.target.value.replace(',', '.')) }}
											name="horimetroAtual"
											className="form-control  margin-date"
											autoComplete="off"
											validate={this.validateHorimetroAtual.bind(this)}
											disabled={!this.state.maquina.horimetroAtivo} />
										<ErrorMessage name="horimetroAtual">
											{msg => <div className="error error-message error-validate">{msg}</div>}
										</ErrorMessage>
									</div>
								</div>
								<div className="col-md-12 flex mt-3">
									<div className="col-md-12">
										<label className="label">
											Observação:
                                        </label>
										<Field htmlFor="observacao"
											placeholder="Informe uma observação"
											type="text"
											name="observacao"
											maxLength="200"
											autoComplete="off"
											className="form-control  margin-date" />
									</div>

								</div>
							</div>
							<div className="margin-button float-right flex">
								<button className={"btn btn-primary active pull-right  flex"} type="submit">
									<i className='fas fa-check mt-1 mr-1' /> Salvar
								</button>
							</div>
							<Modal
								title={"Cadastrar Grupo de Máquina"}
								show={this.state.showModalGrupoMaquina}
								close={() => this.setState({ showModalGrupoMaquina: false })}
								modalClose={(item) => this.modalClose(item)}
							/>
							<ModalTipoMaquina
								title={"Cadastrar Tipo de Máquina"}
								show={this.state.showModalTipoMaquina}
								close={() => this.setState({ showModalTipoMaquina: false })}
								modalClose={(item) => this.modalTipoClose(item)}
							/>
						</form>
					)}
				</Formik>
			</CardStyle>
		)
	}

}
