import React, { useState } from "react";
import clsx from "clsx";
import { Box, makeStyles } from "@material-ui/core";
import FiltroSimples from "./FiltroSimples";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiltroAvancado from "./FiltroAvancado";
import ObjectHelper from "utils/objectHelper";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	linkChangeFilter: {
		marginTop: "50px"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
		backgroundColor: "#FFFFFF"
	},
	setFilterStyle: {
		marginTop: "10px",
		color: "#0A9A84",
		fontSize: "13px"
	}
}));

const Toolbar = ({ handleFiltro, filtroInicial, className, ...rest }) => {
	const classes = useStyles();
	const [filtro, setFiltro] = useState(filtroInicial);
	const [filtroExpanded, setFiltroExpanded] = useState(filtro.filtroExpanded);

	const setStateAcocordion = () => {
		let newFiltro = { ...filtro };
		newFiltro.filtroExpanded = !filtroExpanded;
		if (newFiltro.filtroExpanded === false) {
			newFiltro = {
				filtro: newFiltro.filtro,
				filtroExpanded: false,
				page: filtro.page,
				pageSize: filtro.pageSize,
				order: filtro.order,
				descedescending: filtro.descedescending,
				periodoInicial: "",
				periodoFinal: "",
				nome: "",
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				safraId: JSON.parse(localStorage.getItem('grupoSafraId'))
			};
		}
		setFiltro(newFiltro);
		setFiltroExpanded(!filtroExpanded);
	};

	const changeFiltro = (campo, event, tipo) => {
		let newFiltro = { ...filtro };
		newFiltro[campo] = event.target.value;
		if (campo === 'date' || campo === 'filtro') {
			newFiltro[campo] = event.target.value;
		}
		if (campo === "servicoTipoId") {
			rest.trocaGrupoServico();
			newFiltro["servicoId"] = "";
		}
		newFiltro["servicoId"] = "";
		setFiltro(newFiltro);
	};

	const changeFiltroAutoComplete = (campo, event, newValue) => {
		let newFiltro = { ...filtro };
		if (campo === "servicoTipoId") {
			if (newValue)
				rest.trocaGrupoServico(newValue.value);
			else
				rest.trocaGrupoServico("");
			newFiltro["servicoId"] = "";
		}

		if (newValue)
			newFiltro[campo] = newValue.value;
		else
			newFiltro[campo] = "";

		if (campo === "status") {
			if (newValue)
				newFiltro[campo] = newValue;
			else
				newFiltro[campo] = "";
		}

		if (campo === "atividades") {
			var array = [];
			if (newValue) {
				array.push(newValue.value)
				newFiltro["atividadesId"] = array;
			} else {
				newFiltro["atividadesId"] = ObjectHelper.multisafra();
			}
		}

		setFiltro(newFiltro);
	};

	const submit = event => {
		if ((event ? event.keyCode : event.which) === 13) {
			handleFiltro(filtro);
		}
	};

	const submitAvancado = () => {
		handleFiltro(filtro);
	};

	return (
		<div className={clsx(classes.root, className)} {...rest}>
			<Box mt={3}>
				<Accordion
					expanded={filtroExpanded}
					onChange={e => setStateAcocordion}
				>
					<AccordionSummary
						expandIcon={
							!rest.temFiltroAvancado ? "" : <ExpandMoreIcon />
						}
						aria-controls="panel2a-content"
						id="panel2a-header"
						style={{
							backgroundColor: "#FFFFFF"
						}}
						onClick={() => setStateAcocordion(!filtroExpanded)}
					>
						<FiltroSimples
							filtro={filtro}
							changeFiltro={(campo, event) =>
								changeFiltro(campo, event)
							}
							submit={submit}
							/* alteraExibicao={setShowAdvancedSearch}*/

							temFiltroAvancado={rest.temFiltroAvancado}
							onClickAdd={rest.onClickAdd}
						/>
					</AccordionSummary>
					{rest.temFiltroAvancado && (
						<AccordionDetails>
							<FiltroAvancado
								filtro={filtro}
								filtroTiposDeConta={
									rest.filtroTiposDeConta || []
								}
								filtroNome={rest.filtroNome}
								safras={rest.safras}
								atividades={rest.atividades}
								changeFiltroAutoComplete={changeFiltroAutoComplete}
								filtroOperador={rest.filtroOperador}
								filtroOperacoes={rest.filtroOperacoes || []}
								filtroContas={rest.filtroContas || []}
								filtroAtivo={rest.filtroAtivo || false}
								filtroLocais={rest.filtroLocais || []}
								filtroGrupoLocais={rest.filtroGrupoLocais || []}
								filtroGruposServico={
									rest.filtroGruposServico || []
								}
								filtroGrupoMaquina={rest.filtroGrupoMaquina || []}
								filtroMaquinas={rest.filtroMaquinas || []}
								filtroServicos={rest.filtroServicos || []}
								filtroInsumos={rest.filtroInsumos || []}
								getFiltroServicosByGrupoId={
									rest.getFiltroServicosByGrupoId || ""
								}
								filtroPeriodo
								filtroStatus={rest.filtroStatus}
								changeFiltro={changeFiltro}
								submit={e => submitAvancado(e)}
							/>
						</AccordionDetails>
					)}
				</Accordion>
			</Box>
		</div>
	);
};

export default Toolbar;
