import api from './api';

export default {
	buscarTodas(tipoMaquina){
		return api.post(`/api/MaquinaTipo/BuscarTodas`, tipoMaquina);
	},
	deletar(id){
		return api.delete(`/api/MaquinaTipo/${id}`);
	},
	salvar(tipoMaquina){
		return api.post(`/api/MaquinaTipo`, tipoMaquina);
	},
	buscarPorId(tipoMaquinaId){
		return api.get(`/api/MaquinaTipo/${tipoMaquinaId}`);
	},
	editar(tipoMaquina){
		return api.put(`/api/MaquinaTipo`, tipoMaquina);
	},
	ativaDesativa(tipoMaquinaId){
		return api.get(`/api/MaquinaTipo/AtivaDesativa/${tipoMaquinaId}`);
	}
};