import { PERMISSAO } from "../../permissoes/permissoes";
import { validatePermission } from "../../permissoes/permissionHelper";

export function getTelasDoMenuRapido(permissoes, urlPowerBI) {
	let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
	let safraId = JSON.parse(localStorage.getItem('safraId'));
	let telas = getTelas(permissoes, fazendaAtual, safraId, urlPowerBI);
	telas.forEach(item => item.label = `${item.value} - ${item.label}`);
	return telas.sort(function (a, b) { return a.value > b.value ? 1 : -1 });
}

export function getTelasDoMenu(permissoes, urlPowerBI) {
	let telasDoMenu = [];
	let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
	let safraId = JSON.parse(localStorage.getItem('safraId'));
	let telas = getTelas(permissoes, fazendaAtual, safraId, urlPowerBI);
	grupos.forEach(grupo => {
		let telasDoGrupo = telas.filter(tela => tela.grupo === grupo.id);
		if (telasDoGrupo && telasDoGrupo.length > 0) {
			telasDoMenu.push({
				grupo: grupo,
				itens: telasDoGrupo
			});
		}
	});
	return telasDoMenu;
}

function getTelas(permissoes, fazendaAtual, safraId, urlPowerBI) {
	let telas = [];
	telasQueAparecemSemFazendaESafraSelecionada(permissoes, telas);
	if (fazendaAtual) {
		telasQueAparecemComFazendaSelecionada(permissoes, telas);
	}
	if (fazendaAtual && safraId) {
		telasQueAparecemComFazendaESafraSelecionadas(permissoes, telas, urlPowerBI);
	}
	return telas;
}

function checkPermissao(permissoes, permissao) {
	if (permissoes && permissao) return validatePermission(permissao, permissoes);
	return false;
}

/*
	COMO CRIAR NOVAS TELAS NO MENU:
	1 - Verificar se o grupo já existe no vetor 'grupos' abaixo. Se não existe, criar um novo grupo
	2 - Adicionar a tela em uma das 3 funções abaixo:
		- telasQueAparecemSemFazendaESafraSelecionada: telas que devem aparecer quando o usuário ainda não selecionou a fazenda e nem a safra
		- telasQueAparecemComFazendaSelecionada: telas que devem aparecer quando o usuário seleciona apenas a fazenda
		- telasQueAparecemComFazendaESafraSelecionadas: telas que devem aparecer quando o usuário seleciona uma fazenda E uma safra
	3 - O código de cada tela (property 'value') deve ser único começando com o código do grupo. Por exemplo, as telas de planejamento começam no número 100
	4 - NÃO MUDAR O CÓDIGO DAS TELAS. A ideia é que o usuário decore o código e busque no menu rápido apenas por ele
*/

const grupos = [
	{ id: 'RELATORIOS', nome: 'Relatórios', icone: 'fas fa-chart-bar' },
	{ id: 'PLANEJAMENTO', nome: 'Planejamento', icone: 'fas fa-tasks' },
	{ id: 'APONTAMENTOS', nome: 'Apontamentos', icone: 'fas fa-clock' },
	{ id: 'COLABORADORES', nome: 'Colaboradores', icone: 'fas fa-users' },
	{ id: 'MAQUINAS', nome: 'Máquinas', icone: 'fas fas fa-tractor' },
	{ id: 'SERVICOS', nome: 'Serviços', icone: 'fas fa-toolbox' },
	{ id: 'INSUMOS', nome: 'Insumos', icone: 'fas fa-spray-can' },
	{ id: 'FAZENDA', nome: 'Fazenda', icone: 'fas fa-hat-cowboy-side' },
	{ id: 'ADMINISTRADOR', nome: 'Configurações do Sistema', icone: 'fas fa-cog' }
];

function telasQueAparecemSemFazendaESafraSelecionada(permissoes, telas) {
	if (checkPermissao(permissoes, PERMISSAO.MODULO_FAZENDAS_CRIAR))
		telas.push({ icone: 'fas fa-hat-cowboy-side', grupo: 'ADMINISTRADOR', label: 'Clientes', value: '701', pathname: "/admin/fazendas" });
}

function telasQueAparecemComFazendaSelecionada(permissoes, telas) {
	if (checkPermissao(permissoes, PERMISSAO.MODULO_RECURSOS_LEITURA))
		telas.push({ icone: 'fas fa-tag', grupo: 'PLANEJAMENTO', label: 'Recursos (Insumos)', value: '103', pathname: "/materiais/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_TEMPLATE_PLANEJAMENTO_LEITURA))
		telas.push({ icone: 'fas fa-leaf', grupo: 'PLANEJAMENTO', label: 'Modelos de Manejo', value: '104', pathname: "/planejamento/template/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA))
		telas.push({ icone: 'fas fa-sync-alt', grupo: 'PLANEJAMENTO', label: 'Gerador de Planejamentos', value: '105', pathname: "/planejamento/gerador" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_CATEGORIA_PESSOA_LEITURA))
		telas.push({ icone: 'fas fa-user-tag', grupo: 'COLABORADORES', label: 'Grupos de Colaboradores', value: '301', pathname: "/categoriaPessoa/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_PESSOA_LEITURA))
		telas.push({ icone: 'fa fa-user-plus', grupo: 'COLABORADORES', label: 'Colaboradores', value: '302', pathname: "/pessoa/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_EQUIPE_LEITURA))
		telas.push({ icone: 'fas fa-user-friends', grupo: 'COLABORADORES', label: 'Equipes', value: '303', pathname: "/equipes/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_TURNO_LEITURA))
		telas.push({ icone: 'far fa-clock', grupo: 'COLABORADORES', label: 'Turnos', value: '304', pathname: "/turnos/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_AFASTAMENTOS_LEITURA))
		telas.push({ icone: 'fas fa-user-minus', grupo: 'COLABORADORES', label: 'Afastamentos', value: '305', pathname: "/afastamentos/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_GRUPO_MAQUINA_LEITURA))
		telas.push({ icone: 'fas fa-truck-monster', grupo: 'MAQUINAS', label: 'Grupos de Máquinas', value: '401', pathname: "/maquinas/grupo/" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_TIPO_MAQUINA_LEITURA))
		telas.push({ icone: 'fas fa-truck', grupo: 'MAQUINAS', label: 'Tipos de Máquinas', value: '402', pathname: "/tipo/maquina/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_MAQUINA_LEITURA))
		telas.push({ icone: 'fas fa-truck-pickup', grupo: 'MAQUINAS', label: 'Máquinas', value: '403', pathname: "/maquinas/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_GRUPO_DE_SERVICOS_LEITURA))
		telas.push({ icone: 'fas fa-tools', grupo: 'SERVICOS', label: 'Grupos de Serviços', value: '501', pathname: "/gruposervicos/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_SERVICO_LEITURA))
		telas.push({ icone: 'fas fa-hammer', grupo: 'SERVICOS', label: 'Serviços', value: '502', pathname: "/servico" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_FAMILIA_DE_INSUMOS_LEITURA))
		telas.push({ icone: 'fas fa-tags', grupo: 'INSUMOS', label: 'Grupos de Insumos', value: '601', pathname: "/familiaMateriais/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_RECURSOS_LEITURA))
		telas.push({ icone: 'fas fa-tag', grupo: 'INSUMOS', label: 'Insumos', value: '602', pathname: "/materiais/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_UNIDADE_LEITURA))
		telas.push({ icone: 'fas fa-balance-scale-left', grupo: 'INSUMOS', label: 'Unidades de Medida', value: '603', pathname: "/unidades/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_LOCAL_LEITURA))
		telas.push({ icone: 'fas fa-layer-group', grupo: 'FAZENDA', label: 'Fazendas', value: '704', pathname: "/locais/grupo/" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_LOCAL_LEITURA))
		telas.push({ icone: 'fas fa-map-marked-alt', grupo: 'FAZENDA', label: 'Locais', value: '705', pathname: "/local/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_CALENDARIO_LEITURA))
		telas.push({ icone: 'fas fa-calendar-alt', grupo: 'FAZENDA', label: 'Calendário', value: '702', pathname: "/planejamento/calendario" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_CULTURA_LEITURA))
		telas.push({ icone: 'fas fa-seedling', grupo: 'FAZENDA', label: 'Culturas', value: '703', pathname: "/culturas/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_PARAMETRO_LAVOURA_LEITURA))
		telas.push({ icone: 'fab fa-envira', grupo: 'FAZENDA', label: 'Parâmetros de Lavoura', value: '706', pathname: "/parametroLavoura/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_SAFRA_LEITURA))
		telas.push({ icone: 'fas fa-leaf', grupo: 'FAZENDA', label: 'Safras', value: '708', pathname: "/safra" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_USUARIO_LEITURA))
		telas.push({ icone: 'fa fa-users-cog', grupo: 'ADMINISTRADOR', label: 'Usuário', value: '801', pathname: "/usuario/listagem" });
}

function telasQueAparecemComFazendaESafraSelecionadas(permissoes, telas, urlPowerBI) {
	if (urlPowerBI) {
		if (checkPermissao(permissoes, PERMISSAO.MODULO_RELATORIOS_LEITURA))
			telas.push({ icone: 'fas fa-chart-pie', grupo: 'RELATORIOS', label: 'Dashboard', value: '001', pathname: "/dashboard" });
	}

	if (checkPermissao(permissoes, PERMISSAO.MODULO_RECURSOS_LEITURA))
		telas.push({ icone: 'fas fa-list-alt', grupo: 'PLANEJAMENTO', label: 'Recursos de Mão de Obra e Máquinas', value: '102', pathname: "/planejamento/recursos/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_RELATORIOS_LEITURA))
		telas.push({ icone: 'fas fa-table', grupo: 'RELATORIOS', label: 'Relatórios Gerais', value: '002', pathname: "/relatorios", });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_RELATORIOS_LEITURA))
		telas.push({ icone: 'fas fa-sticky-note', grupo: 'RELATORIOS', label: 'Painel de Serviços', value: '003', pathname: "/painel/servicos" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA))
		telas.push({ icone: 'fas fa-calendar-alt', grupo: 'PLANEJAMENTO', label: 'Agendamentos', value: '101', pathname: "/planejamento/agendamento/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_LEITURA))
		telas.push({ icone: 'far fa-clipboard', grupo: 'PLANEJAMENTO', label: 'Planejamento de Safra', value: '106', pathname: "/planejamento/safra" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR))
		telas.push({ icone: 'fas fa-pencil-alt', grupo: 'PLANEJAMENTO', label: 'Ajustes em Recursos', value: '107', pathname: "/planejamento/ajustesemrecursos" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_LEITURA))
		telas.push({ icone: 'fas fa-user-plus', grupo: 'APONTAMENTOS', label: 'Apontamento de Mão de Obra', value: '201', pathname: "/apontamento" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_APONTAMENTO_MAQUINA_LEITURA))
		telas.push({ icone: 'fas fa-tractor', grupo: 'APONTAMENTOS', label: 'Apontamento de Máquina', value: '202', pathname: "/apontamento/maquina" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_APONTAMENTO_INSUMOS_LEITURA))
		telas.push({ icone: 'fas fa-tags', grupo: 'APONTAMENTOS', label: 'Apontamento de Insumos', value: '203', pathname: "/apontamento/insumos/listagem" });
	if (checkPermissao(permissoes, PERMISSAO.MODULO_APONTAMENTO_MAO_OBRA_CRIAR))
		telas.push({ icone: 'fas fa-sliders-h', grupo: 'APONTAMENTOS', label: 'Ajustes em Apontamentos', value: '204', pathname: "/apontamento/ajustesApontamentos" });
}

