import { SET_FILTRO_LISTAGEM_APONTAMENTO_MO } from "../actions/apontamentoMo";
import ObjectHelper from "utils/objectHelper";
export const storageApontamentoMo = "apontamentoMo";

const initialState = {
	filtroListagem: {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId"))
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: "",
		atividadesId: JSON.parse(localStorage.getItem("safraId"))
			? ObjectHelper.multisafra()
			: "",
		order: "Pessoa.Nome",
		descedescending: false,
		page: 1,
		pageSize: 10,
		filtroExpanded: false
	}
};

export const apontamentoMo = (state = initialState, action) => {
	switch (action.type) {
		case SET_FILTRO_LISTAGEM_APONTAMENTO_MO:
			return {
				...state,
				filtroListagem: action.filtroListagem
			};
		default:
			return state;
	}
};
