import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import TurnoService from '../../../services/turnoService';
import {Types} from "./turno.ducks";
import executeAction from "../../../store/sagas/executeAction";
import ToastHelper from "../../../utils/toastHelper";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 1000
}

function* getByIdTurno(action) {
	yield executeAction(function* () {

		const { params, callback } = action;
		const { data } = yield call( async () => await TurnoService.buscarPorId(params));
		yield put({
			type: Types.SETAR_GET_BY_ID_TURNO,
			data: data.result
		});
		callback();
	}, Types.GET_BY_ID_TURNO);
}

function* asyncListaTurnos(action) {

	yield executeAction(function* () {

		const {params, callback } = action;
		const { data } = yield call( async () => await TurnoService.buscar(params || parametros.fazendaId));
		yield put({ type: Types.READ_TURNOS, data: data.result });
		callback();
	}, Types.READ_TURNOS);
}

function* asyncCreateTurno(action){
	yield executeAction(function* () {

		const { params, history, callback } = action;
		const { data } = yield call( async () => await TurnoService.salvar(params));

		if(data.isOk){
			ToastHelper.success('Turno criado com sucesso!');
			history.goBack()
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.POST_TURNO, data: data });
		callback();
	}, Types.CREATE_TURNO);
}

function* updateAsync(action){
	yield executeAction(function* () {

		const { params, history } = action;
		const { data } = yield call( async () => await TurnoService.editar(params));

		if(data.isOk){
			ToastHelper.success('Turno atualizado com sucesso!');
			history.goBack()
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.UPDATE_TURNO,
			data
		});
	}, Types.UPDATE_TURNO);
}

function* deleteTurno(action) {

	yield executeAction(function* () {

		const { params, callback } = action;
		const { data } = yield call( async () => await TurnoService.deletar(params));
		if(data.isOk)
			ToastHelper.success('Turno excluído com sucesso!');
		else
			ToastHelper.error(data.mensagem);
		callback();
	}, Types.DELETE_TURNO);
}


function* takeUpdateTurno() {
	yield takeLatest(Types.UPDATE_TURNO, updateAsync);
}

function* takeGetByIdTurno() {
	yield takeLatest(Types.GET_BY_ID_TURNO, getByIdTurno);
}

function* takeReadTurnos() {
	yield takeLatest(Types.BUSCAR_TURNOS, asyncListaTurnos);
}

function* takeCreateTurno() {
	yield takeLatest(Types.CREATE_TURNO, asyncCreateTurno);
}

function* takeDeleteTurno() {
	yield takeLatest([Types.DELETE_TURNO], deleteTurno);
}

export default function* root() {
	yield all([fork(takeGetByIdTurno)])
	yield all([fork(takeReadTurnos)])
	yield all([fork(takeCreateTurno)])
	yield all([fork(takeUpdateTurno)])
	yield all([fork(takeDeleteTurno)])
}
