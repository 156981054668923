import React from 'react';
import { CardStyle } from '../../../components/CardStyle/cardstyle.component'
import Title from "../../../components/Title/title.component"
import Table from '../../../components/Table/table.component';
import UsuarioService from '../../../services/usuarioService';
import { PERMISSAO } from '../../../permissoes/permissoes';
import Modal from "../../../utils/modalHelper";
import ToastHelper from "../../../utils/toastHelper";
import SimpleSearch from '../../../components/SimpleSearch/simplesSearch';

const NUMERO_ITENS_POR_PAGINA = 15;

export default class UsuarioListagem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			filtro: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 1,
				pageSize: 0,
			},
		}
		this.history = this.props.history
	}

	async componentDidMount() {
		await this.buscar();
	}

	TIPO_CADASTRO = 'usuario';

	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Usuário', property: 'userName' },
		{ id: 2, title: 'Email', property: 'email' },
	];

	actions = [
		{
			id: 1,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_USUARIO_ATUALIZAR,
			action: item => this.editar(item)
		},
		{
			id: 2,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_USUARIO_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];

	async handleModalExcluir(item) {
		if (item) {
			await this.setState({
				...this.state,
				idItem: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	async apagar(item) {
		let fazenda = JSON.parse(localStorage.getItem('fazendaId')).value;
		let { data } = await UsuarioService.deletar(item, fazenda);
		if (data.isOk) {
			ToastHelper.success("Usuário excluído com sucesso!");
			await this.buscar();
		} else {
			ToastHelper.error(data.mensagem);
		}
		this.setState({ showModalExcluir: false })
	}

	cadastrar = () => {
		this.history.push(`/${this.TIPO_CADASTRO}/cadastro`);
	}

	editar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/editar/${item.id}`
		});
	}

	async handlePaginacao(page) {
	}

	async buscar() {
		let param = {
			page: this.state.data.page,
			pageSize: 100,
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			filtro: this.state.filtro
		};

		let result = await UsuarioService.buscar(param);

		await this.setState({
			data: { ...result.data.result }
		});
	}

	table() {
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 1}
				/>
			</>
		)
	};

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				doubleButtons={true}
				title="Excluir"
				buttonTitle='Confirmar'
				button2Title='Cancelar'
				buttom2Action={() => this.handleModalExcluir()}
				submit={() => this.apagar(this.state.idItem)}
				close={() => this.handleModalExcluir()}
				content={
					<h4>Deseja realmente excluir este usuário ?</h4>
				}
			/>);
	};

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		await this.buscar();
	}

	render() {
		return (
			<div>
				<Title
					acao="Novo Usuário"
					permissaoBotao={PERMISSAO.MODULO_USUARIO_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.cadastrar}>
					Usuários
                </Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div>
						{this.table()}
					</div>
				</CardStyle>
				{this.modalExcluir()}
			</div>
		)
	}
}
