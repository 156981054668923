import { all, fork, put, takeLatest } from "redux-saga/effects";
import TemplateService from '../../../services/templatePlanejamentoService'
import CalendarioService from '../../../services/calendarioService'
import planejamentoSafraService from '../../../services/planejamentoSafraService'
import safraService from '../../../services/safraService'
import ToastHelper from "../../../utils/toastHelper";
import {Types} from "./gerador.ducks"
import executeAction from "../../../store/sagas/executeAction";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 1000
}

function* asyncListaTemplates(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield TemplateService.buscar(parametros);

		yield put({
			type: Types.SET_TEMPLATES,
			data: data.result.items
		});

		callback();
	}, Types.GET_TEMPLATES);
}

function* asyncListaLocais(action) {
	yield executeAction(function* () {
		const { params, callback } = action;

		const { data } = yield safraService.locais(
			JSON.parse(localStorage.getItem('fazendaId')).value,
			params || JSON.parse(localStorage.getItem('safraId')).value);
		yield put({
			type: Types.SET_LOCAIS_SAFRA,
			data: data.result
		});

		callback();
	}, Types.GET_LOCAIS_SAFRA);
}

function* asyncListaCalendarios(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield CalendarioService.buscarTodas(parametros);

		yield put({
			type: Types.SET_CALENDARIOS,
			data: data.result.items
		});

		callback();
	}, Types.GET_CALENDARIOS);
}

function* geradorAsync(action){
	yield executeAction(function* () {
		const { params, history, callback } = action;
		const { data } = yield planejamentoSafraService.gerador(params);

		if(data.isOk){
			ToastHelper.success('Operação Com Sucesso!');
			if(params.safraId === JSON.parse(localStorage.getItem('safraId')).value)
				history.replace({ pathname: "/planejamento/safra" })
		}
		else
			ToastHelper.error("Ocorreu um erro.");

		yield put({
			type: Types.POST_GERADOR,
			data
		});
		callback();
	}, Types.CREATE_GERADOR);
}

function* takeListaTemplates() {
	yield takeLatest(Types.GET_TEMPLATES, asyncListaTemplates);
}

function* takeListaLocais() {
	yield takeLatest(Types.GET_LOCAIS_SAFRA, asyncListaLocais);
}

function* takeListaCalendarios() {
	yield takeLatest(Types.GET_CALENDARIOS, asyncListaCalendarios);
}

function* takeGerador() {
	yield takeLatest(Types.CREATE_GERADOR, geradorAsync);
}

export default function* root() {
	yield all([fork(takeListaCalendarios)])
	yield all([fork(takeListaTemplates)])
	yield all([fork(takeListaLocais)])
	yield all([fork(takeGerador)])
}
