import * as Yup from 'yup';

export const CalendarioSchema = Yup.object().shape({
	nome: Yup.string()
        .required('Campo obrigatório'),
    codigo: Yup.string()
        .required('Campo obrigatório'),
    sabadoUtil: Yup.bool(),
    sabadoUtilPeriodo:  Yup.string()
        .when("sabadoUtil", {
            is: true,
            then: Yup.string().required("Selecione um periodo")
        }),
    domingoUtil: Yup.bool(),
    domingoUtilPeriodo:  Yup.string()
        .when("domingoUtil", {
            is: true,
            then: Yup.string().required("Selecione um periodo")
        
        })    
})