import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	buscarTurnos: ['params', 'callback'],
	readTurnos: ['params'],

	createTurno: ['params', 'history', 'callback'],
	postTurno: ['params'],

	getByIdTurno: ['params', 'callback'],
	setarGetByIdTurno: ['params'],

	updateTurno: ['params', 'history', 'callback'],

	deleteTurno: ['params', 'callback'],

})

const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const buscarTurnos = (state = INITIAL_STATE, action) => state.merge({itens: action.data})
const createTurno = (state = INITIAL_STATE, action) => state.merge({turno: action.data})
const getByIdTurno = (state = INITIAL_STATE, action) => state.merge( action.data )
const updateTurno = (state = INITIAL_STATE, action) => state.merge( action.params )
const deleteTurno = (state = INITIAL_STATE, action) => state.merge( {idItemexcluido: action.params })

export default createReducer(INITIAL_STATE, {
	[Types.SETAR_GET_BY_ID_TURNO]: getByIdTurno,
	[Types.READ_TURNOS]: buscarTurnos,
	[Types.POST_TURNO]: createTurno,
	[Types.UPDATE_TURNO]: updateTurno,
	[Types.DELETE_TURNO]: deleteTurno
})
