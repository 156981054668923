import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import pessoaService from '../../../services/pessoaService'
import categoriaService from '../../../services/categoriaService'
import painelService from '../../../services/painelService'
import { Types } from "./painel.ducks"
import executeAction from "../../../store/sagas/executeAction";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 1000,
	order: "Nome"
}

function* asyncListaCategorias(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield call( async () => await categoriaService.buscarTodas(parametros));

		yield put({ type: Types.SET_CATEGORIAS, data: data.result });
		callback();
	}, Types.GET_CATEGORIAS);
}

function* asyncListaPainel(action) {
	yield executeAction(function* () {
		const { params ,callback } = action;
		const { data } = yield call( async () => await painelService.buscarMO(params));

		yield put({ type: Types.READ_PAINEL_MO, data: data.result });
		callback();
	}, Types.BUSCAR_PAINEL_MO);
}

function* asyncListaPessoas(action) {
	yield executeAction(function* () {
		const { callback } = action;
		const { data } = yield call( async () => await pessoaService.buscar(parametros));

		yield put({ type: Types.SET_PESSOAS, data: data.result });
		callback();
	}, Types.GET_PESSOAS);
}


function* takeReadPainel() {
	yield takeLatest(Types.BUSCAR_PAINEL_MO, asyncListaPainel);
}

function* takeListaCategorias() {
	yield takeLatest(Types.GET_CATEGORIAS, asyncListaCategorias);
}

function* takeListaPessoas() {
	yield takeLatest(Types.GET_PESSOAS, asyncListaPessoas);
}


export default function* root() {
	yield all([fork(takeReadPainel)])
	yield all([fork(takeListaCategorias)])
	yield all([fork(takeListaPessoas)])
}
