import React from 'react';
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import Title from '../../components/Title/title.component';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';
import Modal from "../../utils/modalHelper";
import PessoaService from '../../services/pessoaService'
import ToastHelper from '../../utils/toastHelper';
import Can from '../../permissoes/Can'
import SimpleSearch from '../../components/SimpleSearch/simplesSearch'
import Utils from '../../utils/utils'

const NUMERO_ITENS_POR_PAGINA = 10;

export default class PessoaListagem extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			currentPage: 1,
			filtro: '',
			orderColumn: '',
			orderTable: '',
			descending: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
			showModalAtivaDesativa: false,
			idMaquinaAtivaDesativa: undefined
		}
		this.history = this.props.history
	}

	paginate = {
		fazendaid: JSON.parse(localStorage.getItem('fazendaId')).value,
		filtro: '',
		page: 1,
		pageSize: 10,
		order: "Nome"
	};

	async componentDidMount() {
		this.ativaDesativa = this.ativaDesativa.bind(this);
		this.buscar(this.paginate)
	}



	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'CPF/CNPJ', property: 'cpfCnpj' },
		{ id: 2, title: 'Grupo', property: 'categorias' },
		{ id: 3, title: 'Status', property: 'isAtivo' },
	];

	actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_PESSOA_LEITURA,
			action: item => this.visualizar(item),
		},

		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_PESSOA_ATUALIZAR,
			action: item => this.editar(item)
		},
		{
			id: 3,
			name: 'Ativa/Desat.',
			permissao: PERMISSAO.MODULO_PESSOA_ATUALIZAR,
			icon: 'fas fa-low-vision circ-item-desativar',
			action: item => this.handleModalStatus(item, 'SETAR_STATUS'),
		},
		{
			id: 4,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_PESSOA_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		},
	];

	handleModalStatus = async (item) => {
		if (item) {

			await this.setState({
				...this.state,
				idMaquinaAtivaDesativa: item.id,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			})
		} else {

			await this.setState({
				...this.state,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			})
		}
	}

	async buscar(param) {

		let result = await PessoaService.buscar(param);

		if (result.data.result) {
			result.data.result.items.forEach(item => {
				item.categorias = item.categoriaPessoa

				if (item.ativo) {
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});
			await this.setState({
				data: { ...result.data.result }
			});
		}
	}

	async handlePaginacao(page) {

		await this.setState({ currentPage: page });
		let param = this.paginate
		param.page = page
		param.filtro = this.state.filtro
		await this.buscar(param);
	}

	async onChangeFilter(filtro) {
		let param = this.paginate
		param.filtro = filtro
		param.page = 1
		await this.setState({ filtro: filtro });
		await this.buscar(param);
	}

	orderTable = (property, descending) => {
		this.setOrderColumn(Utils.parsePropertToFilter(property));
		this.setDescending(descending);
		let params = this.paginate;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		this.buscar(params)
	}

	setOrderColumn = (property) => {
		this.setState({
			...this.state,
			orderColumn: property
		})
	}

	setDescending = (descending) => {
		this.setState({
			...this.state,
			descending: descending
		})
	}

	table() {
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					acoesSingleButton={false}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
				/>
			</>
		)
	};

	adicionarPessoaBotao() {
		return <Can
			perform={PERMISSAO.MODULO_PESSOA_CRIAR}
			yes={() =>
				<button className="btn btn-primary btn-style active pull-right" onClick={this.cadastrar}>
					<i className="fas fa-plus"></i>  Novo Colaborador
                </button>
			}
		/>
	}

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				title='Excluir colaborador'
				buttonTitle='Confirmar'
				button2Title='Cancelar'
				buttom2Action={() => this.handleModalExcluir(null, 'EXCLUIR')}
				submit={() => this.apagar(this.state.idItem)}
				close={() => this.handleModalExcluir(null, 'EXCLUIR')}
				content={
					<h6>Quer realmente excluir este colaborador?</h6>
				}
			/>
		)
	}

	modalAtivaDesativa() {
		return (

			<Modal
				show={this.state.showModalAtivaDesativa}
				title={'Ativar / Desativar Colaborador'}
				buttonTitle='Confirmar'
				button2Title='Cancelar'
				buttom2Action={() => this.handleModalStatus(null, 'SETAR_STATUS')}
				submit={() => this.ativaDesativa(this.state.idMaquinaAtivaDesativa)}
				close={() => this.handleModalStatus(null, 'SETAR_STATUS')}
				content={
					<h6>{'Deseja realmente alterar o status deste colaborador?'}</h6>
				}
			/>
		)
	}

	async ativaDesativa(item) {
		let dados = await PessoaService.ativar(item);

		if (dados.status === 200) {

			ToastHelper.success("Status do colaborador alterado com sucesso!");
			await this.buscar(this.paginate);
			await this.handleModalStatus(null, 'SETAR_STATUS');
		} else {

			ToastHelper.error("Erro ao tentar alterar o status do colaborador!");
		}
	}

	async handleModalExcluir(item) {
		if (item) {
			await this.setState({
				...this.state,
				idItem: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	TIPO_CADASTRO = 'pessoa';

	cadastrar = () => {
		this.history.push(`/${this.TIPO_CADASTRO}/cadastro`);
	}

	editar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/editar/${item.id}`
		});
	}

	visualizar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/visualizar/${item.id}`
		});
	}

	async apagar(item) {
		let { data } = await PessoaService.deletar(item);
		if (data.isOk) {
			ToastHelper.success("Colaborador excluído com sucesso!");
			await this.buscar(this.paginate);
		} else {
			ToastHelper.error(data.mensagem);
		}
		await this.handleModalExcluir();
	}

	render() {
		return (
			<div className="w-100">
				<Title
					acao="Novo Colaborador"
					permissaoBotao={PERMISSAO.MODULO_PESSOA_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.cadastrar}>
					Colaboradores
                </Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div>
						{this.table()}
					</div>
				</CardStyle>
				{this.modalExcluir()}
				{this.modalAtivaDesativa()}
			</div>
		)

	}
}
