import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
	getAppointmentEntrys: ['params', 'callback'],
	setAppointmentEntrys: ['appointmentEntrys'],

	getResponsible: ['params', 'callback'],
	setResponsible: ['responsible'],

	getLocations: ['params', 'callback', 'toFilter'],
	setLocations: ['location'],

	getServiceGroups: ['params', 'callback'],
	setServiceGroups: ['serviceGroup'],

	getServicesByGroupId: ['params', 'callback'],
	setServicesByGroupId: ['services'],

	getFamilyMaterials: ['params', 'callback'],
	setFamilyMaterials: ['familyMaterials'],

	createEntryAppointment: ['params', 'history', 'callback'],
	postEntryAppointment: ['params'],

	getMaterialsByFamily: ['params', 'callback'],
	setMaterialsByFamily: ['params'],

	getEntryAppointmentById: ['params', 'callback'],
	setEntryAppointmentById: ['params'],

	updateEntryAppointment: ['params', 'history'],

	deleteAppointmentEntry: ['params', 'callback'],
})

const INITIAL_STATE_VALUES = {
	appointmentEntrys: [],
	locations: [],
	serviceGroup: [],
	services: [],
	familyMaterials: [],
	materials: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));
const getAppointmentEntrys = (state = INITIAL_STATE, action) => ({ ...state, appointmentEntrys: action.data })
const deleteAppointmentEntry = (state = INITIAL_STATE, action) => ({ ...state, idDeletedItem: action.params })
const getResponsible = (state = INITIAL_STATE, action) => ({ ...state, responsible: action.data })
const getLocations = (state = INITIAL_STATE, action) => ({ ...state, locations: action.data })
const getServiceGroups = (state = INITIAL_STATE, action) => ({ ...state, serviceGroup: action.data })
const getServicesByGroupId = (state = INITIAL_STATE, action) => ({ ...state, services: action.data })
const getFamilyMaterials = (state = INITIAL_STATE, action) => ({ ...state, familyMaterials: action.data })
const getMaterialsByFamily = (state = INITIAL_STATE, action) => ({ ...state, materials: action.data })
const createEntryAppointment = (state = INITIAL_STATE, action) => ({ ...state, createEntryAppointment: action.data })
const getEntryAppointmentById = (state = INITIAL_STATE, action) => ({ ...state, entryAppointment: action.data })
const updateEntryAppointment = (state = INITIAL_STATE, action) => ({})

export default createReducer(INITIAL_STATE, {
	[Types.SET_APPOINTMENT_ENTRYS]: getAppointmentEntrys,
	[Types.DELETE_APPOINTMENT_ENTRY]: deleteAppointmentEntry,
	[Types.SET_RESPONSIBLE]: getResponsible,
	[Types.SET_LOCATIONS]: getLocations,
	[Types.SET_SERVICES_BY_GROUP_ID]: getServicesByGroupId,
	[Types.SET_FAMILY_MATERIALS]: getFamilyMaterials,
	[Types.SET_MATERIALS_BY_FAMILY]: getMaterialsByFamily,
	[Types.POST_ENTRY_APPOINTMENT]: createEntryAppointment,
	[Types.SET_ENTRY_APPOINTMENT_BY_ID]: getEntryAppointmentById,
	[Types.UPDATE_ENTRY_APPOINTMENT]: updateEntryAppointment,
	[Types.SET_SERVICE_GROUPS]: getServiceGroups
})
