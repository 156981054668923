import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getGroupServices: ['params', 'callback'],
    setGroupServices: ['groupServices'],
    
    getServices: ['params', 'callback'],
    setServices: ['services'],
    
    getLocations: ['params', 'callback', 'toFilter'],
    setLocations: ['locations'],
    
    getRecursosTipo: ['params', 'callback', 'toFilter'],
	setRecursosTipo: ['locations'],
    
    getRecursos: ['params', 'callback', 'toFilter'],
	setRecursos: ['resources'],

})

const INITIAL_STATE_VALUES = {
    groupServices: [],
    services:[],
    locations:[],
    resourcesTypes:[],
    resources:[],
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getGroupServices = (state = INITIAL_STATE_VALUES, action) => ({...state,groupServices: action.data})
const getServices = (state = INITIAL_STATE_VALUES, action) => ({ ...state,services: action.data })
const getLocations = (state = INITIAL_STATE_VALUES, action) => ({ ...state,locations: action.data})
const setRecursosTipo = (state = INITIAL_STATE_VALUES, action) => ({ ...state,resourcesTypes: action.data})
const setRecursos = (state = INITIAL_STATE_VALUES, action) => ({ ...state,resources: action.data})

export default createReducer(INITIAL_STATE, {
    [Types.SET_GROUP_SERVICES]: getGroupServices,
    [Types.SET_SERVICES]: getServices,
    [Types.SET_LOCATIONS]: getLocations,
    [Types.SET_RECURSOS_TIPO]: setRecursosTipo,
    [Types.SET_RECURSOS]: setRecursos,
})
