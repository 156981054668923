import { SET_FILTRO_LISTAGEM_APONTAMENTO_MA } from "../actions/apontamentoMa";
import ObjectHelper from "utils/objectHelper";
export const storageApontamentoMa = "apontamentoMa";

const initialState = {
	filtroListagem: {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId"))
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: "",
		atividadesId: JSON.parse(localStorage.getItem("safraId"))
			? ObjectHelper.multisafra()
			: "",
		order: "Data",
		descedescending: false,
		page: 1,
		pageSize: 10,
		filtroExpanded: false
	}
};

export const apontamentoMa = (state = initialState, action) => {
	switch (action.type) {
		case SET_FILTRO_LISTAGEM_APONTAMENTO_MA:
			return {
				...state,
				filtroListagem: action.filtroListagem
			};
		default:
			return state;
	}
};
