import React, { useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Title from "../../components/Title/title.component";
import { CardStyle }  from '../../components/CardStyle/cardstyle.component';
import InputMask from 'react-input-mask';
import { Creators as ModulosCreators } from './store/turno.ducks';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons'
import Utils from '../../utils/utils'

function TurnosCadastro(props) {

    const [visualizar, setVisualizar] = useState(false)
	const { match: { params } } = props;
    const { register, handleSubmit, errors, setValue} = useForm({});
    const [validHoras, setValidHoras] = useState({text: '', invalid: true})
    const [horas, setHoras] = useState('__:__')

    useEffect( () => {

        if(params.modo === 'visualizar') setVisualizar(true)
        if(params.id !== undefined){
            props.getByIdTurno(params.id)
        }
    },[]);

    useEffect( () => {

        if(params.id !== undefined && props.turno.nome && props.turno.horas){
            setValue('nome', props.turno.nome)
            setHoras(props.turno.horas)
            setValue('descricao', props.turno.descricao)
        }
    },[props, setHoras]);

    const validateHoras = useCallback(() => {

        Utils.validateHours(horas, setValidHoras)
    },[horas, setValidHoras])

    const submitForm = useCallback( async (data) => {

        await validateHoras()

        if(!validHoras.invalid){
            let newData = {
                ...data, 
                horas: horas.replace('_', '0'),
                fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value
            }
            if(params.modo === undefined)
                await props.createTurno(newData, props.history);
            else{
                newData.id = params.id
                await props.updateTurno(newData, props.history);
            }
        }
    },[horas,validHoras]);

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
                        <Title>
                        {visualizar ? 'Visualizar ' :
							(params.id ? 'Editar ':
							'Cadastrar '
							)} turno
						</Title>
					</div>
				</div>
				<CardStyle className="card">
                    <div className='card-header row justify-content-start col-md-12'>
						<div className="card-header col-md-12">
							<label className="label">
								Nome:*
							</label>
							<input type="text" className='form-control bmd-form-group' autoComplete="off" readOnly={visualizar} name="nome" ref={register({required: true})}></input>
								{errors.nome && <span className="span-errors"> Campo Obrigatório</span>} <br/>
							<label className="label">
								Horas:*
							</label>
                            <InputMask mask='99:99' disabled={visualizar}
                                className='form-control col-md-2' 
                                placeholder="Insira as horas" 
                                autoComplete="off"
                                name="horas" readOnly={visualizar}
                                onChange={((e) => setHoras(e.target.value))}
                                onBlur = {() => validateHoras()}
                                value={horas}
                            />
								{validHoras.invalid && <span className="span-errors">{validHoras.text}</span>} <br/>
                            <label className="label">
								Descrição:
							</label>
							<input type="text" className='form-control bmd-form-group' readOnly={visualizar} name="descricao" autoComplete="off" ref={register({required: false})}></input>
						</div>
					</div>
				</CardStyle>
                <SubmitFormButtons 
                    flagVisualizar={visualizar}
                    flagEditar={params.id !== undefined}
                    actionGoBack={() => props.history.goBack()}
                />
			</form>
        </>
    )
}

const mapStateToProps = state =>{
	return  ({
        turno: state.turnos,
    });
}

const mapDispatchToProps = dispatch =>  bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(TurnosCadastro)