import * as Yup from 'yup';

export const UsuarioEditarSchema = Yup.object().shape({
	nome: Yup.string().trim()
                .required('Campo obrigatório'),
        userName: Yup.string().trim()
                .required('Campo obrigatório'),
        email: Yup.string().trim()
                .required('Campo obrigatório'),
        perfilId: Yup.string()
                .required('Campo obrigatório').nullable()
});
