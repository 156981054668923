import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import GrupoLocaisService from "../../../services/grupoLocaisService"
import {Types} from "./grupo.locais.ducks";
import executeAction from "../../../store/sagas/executeAction";
import ToastHelper from "../../../utils/toastHelper";
import React from 'react'

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 10,
	filtro:""
}

function* getById(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call( async () => await GrupoLocaisService.buscarPorId(params));

		yield put({
			type: Types.SET_GET_BY_ID_GRUPO_LOCAIS,
			data: data
		});
		callback();
	}, Types.GET_BY_ID_GRUPO_LOCAIS);
}

function* asyncLists(action) {
	yield executeAction(function* () {
		const {params, callback } = action;
		let { data } = yield call( async () => await GrupoLocaisService.getAll(params || parametros.fazendaId));
		
		if(data.result){
			data.result.items.forEach(item => {
				if(item.ativo){
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});
		}
		
		yield put({ type: Types.READ_GRUPO_LOCAIS, data: data.result });
		callback();
	}, Types.READ_GRUPO_LOCAIS);
}

function* asyncCreate(action){
	yield executeAction(function* () {

		const { params, history, callback } = action;
		const { data } = yield call( async () => await GrupoLocaisService.salvar(params));
		if(data.isOk){
			ToastHelper.success("Operação concluída com sucesso!")
			history.goBack()
		} else 
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.POST_GRUPO_LOCAIS, data: data });
		callback();
	}, Types.CREATE_GRUPO_LOCAIS);
}

function* updateAsync(action){
	yield executeAction(function* () {

		const { params, history} = action;
		const { data } = yield call( async () => await GrupoLocaisService.editar(params));
		if(data.isOk){
			ToastHelper.success("Operação concluída com sucesso!")
			history.goBack()
		} else 
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.UPDATE_GRUPO_LOCAIS,
			data
		});
	}, Types.UPDATE_GRUPO_LOCAIS);
}

function* deletarGrupoLocais(action) {
	yield executeAction(function* () {

		const { params, props, callback } = action;
		const { data } = yield call( async () => await GrupoLocaisService.deletar(params));
        if(data.isOk) {
            ToastHelper.success('Operação realizada com sucesso!');
            props.gets(parametros)
        }
		else
			ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
		callback();
	}, Types.DELETAR_GRUPO_LOCAIS);
}

function* takeUpdate() {
	yield takeLatest(Types.UPDATE_GRUPO_LOCAIS, updateAsync);
}

function* takeGetById() {
	yield takeLatest(Types.GET_BY_ID_GRUPO_LOCAIS, getById);
}

function* takeRead() {
	yield takeLatest(Types.GET_GRUPO_LOCAIS, asyncLists);
}

function* takeCreate() {
	yield takeLatest(Types.CREATE_GRUPO_LOCAIS, asyncCreate);
}

function* takeDeletarGrupoLocais() {
	yield takeLatest([Types.DELETAR_GRUPO_LOCAIS], deletarGrupoLocais);
}

export default function* root() {
	yield all([fork(takeGetById)])
	yield all([fork(takeRead)])
	yield all([fork(takeCreate)])
	yield all([fork(takeUpdate)])
	yield all([fork(takeDeletarGrupoLocais)])
}

