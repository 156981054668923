import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import Modal from 'react-bootstrap/es/Modal'
import * as Yup from 'yup';
import ToastHelper from "../../utils/toastHelper";


const SchemaData = Yup.object().shape({
	nome: Yup.string()
		.required('Campo obrigatório'),
});

export default class ModalDuplicacao extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nome: '',
			templateId: ''
		};
	}

	async componentWillReceiveProps(nextProps) {
		this.setState({
			templateId: nextProps.template.id
		})
	}

	async componentDidMount() {
		this.baseState = { ...this.state }
		this.onSubmit = this.onSubmit.bind(this)
	}

	onSubmit = (values, options) => {
		if (values.nome === this.props.template.nome)
			return ToastHelper.error("Os nomes dos tamplates não podem ser iguais.");

		values.templateId = this.state.templateId;
		this.props.submit(values);
		this.props.close()
	}

	modal() {
		const { show, close, template } = this.props;

		return (
			<Formik
				initialValues={{ nome: template.nome + " - Duplicado" }}
				enableReinitialize
				validationSchema={SchemaData}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleChange }) => (
					<form className="form" onSubmit={handleSubmit}>
						<div>
							<Modal dialogClassName="modal-dialog-lg-template-duplicacao" show={show} onHide={() => close()}>
								<Modal.Header closeButton>
									<h3>Duplicação de Modelo de Manejo</h3>
									<hr></hr>
								</Modal.Header>
								<Modal.Body className="" style={{ height: "80vh" }}>

									<div className="container-fluid">
										<h5>Você está duplicando o modelo "{template ? template.nome : ""}".</h5>
									</div>
									<div className='card-header'>
										<div className="row">
											<div className="col-md-12">
												<br />
												<label className="">
													Digite o nome do novo modelo de planejamento:*
														</label>
												<Field
													className='form-control bmd-form-group'
													placeholder="Digite o nome do novo modelo..."
													name="nome"
													onChange={handleChange}
													value={values.nome}
													autoComplete="off"
													type="text" />
												<ErrorMessage
													name="nome">
													{msg => <span className="span-errors">Campo Obrigatório </span>}
												</ErrorMessage>
											</div>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer className="footer-modal">
									<hr></hr>
									<div className="row col-md-12">
										<div className="col-md-6 pull-left">
											<button className="btn btn-outline-secondary pull-left" variant="success" onClick={() => close()}>
												<i className='fas fa-close' /> Cancelar
											</button>
										</div>
										<div className="col-md-6 pull-right">
											<button className="btn btn-primary active pull-right" type="submit" variant="success" onClick={handleSubmit}>
												<i className='fas fa-check' /> Salvar
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Modal>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{this.modal()}
			</>
		)
	}
}
