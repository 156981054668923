import React, { Component } from "react";
import MaterialTable from 'material-table';

class CardComponent extends Component {

	colunas = [
		{ title: 'Serviço', field: 'servico', editable: 'never' },
		{ title: 'Local', field: 'locais', editable: 'never' },
		{ title: 'início', field: 'dataInicio', editable: 'never' },
		{ title: 'Fim', field: 'dataFim', editable: 'never' },
		{ title: 'Status', field: 'status', editable: 'never' },
	];



	render() {
		return (
			<>
				<div >
					<div className="card-header">
						{this.props.agendaAtiva}
					</div>
					<div className="card-body">
						<MaterialTable
							title={this.props.title}
							data={this.props.data}
							actions={[
								{
									icon: 'save',
									tooltip: 'Save User',
									onClick: (event, rowData) => alert("You saved " + rowData.name)
								}
							]}
							columns={this.colunas}

							components={{
								Action: props => (
									<a
										href={`/#/ordemservico/${props.data.ordemServicoId}`}
										target="_blank"
										className="link"
										rel="noreferrer noopener"
									>
										{props.data.ordemServico}
									</a>
								),
							}}
							options={{
								paging: false,
								padding: "dense",
								actionsColumnIndex: 0,
								actionsCellStyle: { width: "100px" },
								filtering: false,
								search: false
							}}

							localization={{
								header: {
									actions: 'Ordem Serviço'
								},
								body: {
									emptyDataSourceMessage: 'Nenhum registro para exibir',
									addTooltip: 'Adicionar',
									deleteTooltip: 'Excluir',
									editTooltip: 'Editar',
									editRow: {
										deleteText: 'Deseja excluir essa linha?',
										cancelTooltip: 'Cancelar',
										saveTooltip: 'Confirmar'
									}
								},
								toolbar: {
									searchTooltip: 'Pesquisar',
									searchPlaceholder: 'Pesquisar'
								}
							}}
						/>
						{
							this.props.data.length === 0 &&
							<div><hr></hr> Não existe nenhuma agenda cadastrada para a semana atual, <a href="#/planejamento/agendamento/cadastro">clique aqui</a> para cadastrar uma nova <strong>agenda</strong> para a semana!.</div>
						}

						<br></br>
						<div >
							<a href={this.props.route1} className="btn btn-primary btn-border float-left">{this.props.buttonTitle1}</a>
							<a href={this.props.route2} className="btn btn-primary btn-border float-right">{this.props.buttonTitle2}</a>
						</div>
					</div>
				</div>
				<br></br>
			</>
		);
	}
}

export default CardComponent;
