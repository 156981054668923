import React, { Component } from "react";
import Title from "../../../components/Title/title.component";
import { withRouter } from "react-router-dom";
import "../maquina.scss";
import FormularioMaquina from "./maquina.formlario";
import MaquinaService from '../../../services/maquinaService';

class MaquinaCadastro extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: undefined,
			maquina: undefined,
		};

		this.handleInit();
	}

	handleInit() {
		this.salvar = this.salvar.bind(this);
		this.voltar = this.voltar.bind(this);
	}

	async UNSAFE_componentWillMount() {
		await this.setFazendaId();
	}

	setFazendaId() {
		let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
		this.setState({fazendaId: fazendaAtual.value});
	}

	adicionarMaquina = (maquina) => {

		this.setState({maquina: maquina});
	}

	async salvar () {
		await MaquinaService.salvar(this.state.maquina);

	}

	voltar() {
		this.props.history.goBack();
	}

	render() {
		return (
			<>
				<Title>Cadastro de Máquinas</Title>
				<div className="row">
					<div className="col-sm-12 col-md-12">
						<div className="card-deck mb-2">
							<FormularioMaquina
								fazendaId={this.state.fazendaId}
								adicionarMaquina={this.adicionarMaquina}
								voltar={() => this.voltar()}
								salvar={() => this.salvar()}
								location={this.props.location.state}
							/>
						</div>
						<div className="card-deck  mb-2">
							<div className="card-body">
								<button className="btn btn-outline-secondary pull-left" type="button" onClick={this.voltar}>
									<i className='fas fa-close'/>  Cancelar
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(MaquinaCadastro);
