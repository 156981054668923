import React, { useState, useCallback, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Title from '../../components/Title/title.component';
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import { Creators as ModulosCreators } from './store/insumo_analise_solo.ducks';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

function InsumoAnaliseSoloCadastro(props) {

	const { register, handleSubmit, errors, setValue } = useForm({});
	const [visualize, setVisualize] = useState(false)
	const [form, setForm] = useState({});
	const [materiais, setInsumos] = useState([]);
	const { match: { params } } = props;

	useEffect(() => {
		let params = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			culturaId: JSON.parse(localStorage.getItem('safraId')).culturaId
		}

		props.getMaterialsByFamily('reset');
		props.getLocations();
		props.getServiceGroupByCulturaSafra(params);
	}, []);

	useEffect(() => {
		if (params.modo === 'visualizar') setVisualize(true);
		if (params.id !== undefined) {
			props.getByIdInsumoAnaliseSolo(params.id);
		}
	}, []);

	useEffect(() => {
		if (params.id !== undefined && props.insumoAnaliseSolo !== undefined) {
			let initialValue = {
				id: props.insumoAnaliseSolo.id,
				quantidadeHa: props.insumoAnaliseSolo.quantidadeHa,
				quantidadeTotal: props.insumoAnaliseSolo.quantidadeTotal,
				local: { value: props.insumoAnaliseSolo.local.id, label: props.insumoAnaliseSolo.local.nome },
				servicoTipo: { value: props.insumoAnaliseSolo.servico.servicoTipo.id, label: props.insumoAnaliseSolo.servico.servicoTipo.nome },
				servico: { value: props.insumoAnaliseSolo.servico.id, label: props.insumoAnaliseSolo.servico.nome },
				familiaMateriais: { value: props.insumoAnaliseSolo.materiais.familiaMateriais.id, label: props.insumoAnaliseSolo.materiais.familiaMateriais.nome },
				materiais: { value: props.insumoAnaliseSolo.materiais.id, label: props.insumoAnaliseSolo.materiais.nome },
			}
			setForm(initialValue)

			setValue('quantidadeHa', props.insumoAnaliseSolo.quantidadeHa);
			setValue('quantidadeTotal', props.insumoAnaliseSolo.quantidadeTotal);
			setValue('local', { value: props.insumoAnaliseSolo.local.id, label: props.insumoAnaliseSolo.local.nome },);
			setValue('servicoTipo', { value: props.insumoAnaliseSolo.servico.servicoTipo.id, label: props.insumoAnaliseSolo.servico.servicoTipo.nome },);
			setValue('servico', { value: props.insumoAnaliseSolo.servico.id, label: props.insumoAnaliseSolo.servico.nome },);
			setValue('familiaMateriais', { value: props.insumoAnaliseSolo.materiais.familiaMateriais.id, label: props.insumoAnaliseSolo.materiais.familiaMateriais.nome },);
			setValue('materiais', { value: props.insumoAnaliseSolo.materiais.id, label: props.insumoAnaliseSolo.materiais.nome },);
			setValue('id', props.insumoAnaliseSolo.id);
			props.getServicesByGroupId({
				farmId: JSON.parse(localStorage.getItem('fazendaId')).value,
				groupServiceId: props.insumoAnaliseSolo.servico.servicoTipo.id
			})
		}
	}, [props.insumoAnaliseSolo]);

	useEffect(() => {
		setInsumos(props.materiais)
	}, [props.materiais]);

	useEffect(() => {
		if (params.id !== undefined && props.insumoAnaliseSolo !== undefined) {
			let local = props.locais.find(obj => {
				return obj.value === props.insumoAnaliseSolo.local.id;
			});
			let areaHa;
			if (local !== undefined && local.areaHa !== undefined) {
				areaHa = local.areaHa;
			}
			setValue('areaHa', areaHa);
		}
	}, [props.locais])

	useEffect(() => {
		if (form.familiaMateriais) {
			props.getMaterialsByFamily(form.familiaMateriais.value)
			form.familiaMateriais ?
				setForm({ ...form, familiaMateriais: form.familiaMateriais }) :
				setForm({ ...form })
		}
	}, [form.familiaMateriais]);

	useEffect(() => {
		if (form.materiais) {
			if (materiais ? materiais.length > 0 : false) {
				if (props.insumoAnaliseSolo) {
					let materiais = { label: props.insumoAnaliseSolo.materiais.label, value: props.insumoAnaliseSolo.materiais.id, unidade: props.insumoAnaliseSolo.materiais.unidade }
					if (materiais !== undefined) {
						let unidade = props.insumoAnaliseSolo.materiais.unidade.sigla;
						setValue('unidade', unidade);
						setValue('id', props.insumoAnaliseSolo.id);
						setForm({ ...form, unidade: unidade })
					}
				}
			}
		}
	}, [materiais]);

	const handleGrupoServico = useCallback((group) => {
		if (group) {

			props.getServicesByGroupId({
				farmId: JSON.parse(localStorage.getItem('fazendaId')).value,
				groupServiceId: group.value ? group.value : group
			});

			props.getFamilyMaterials({
				farmId: JSON.parse(localStorage.getItem('fazendaId')).value,
				groupServiceId: group.value ? group.value : group
			});
		}
	}, [form, props]);

	const handleLocal = useCallback((local) => {
		if (local !== undefined) {
			let qdeTotal = 0;
			if (props.insumoAnaliseSolo !== undefined) {
				qdeTotal = props.insumoAnaliseSolo.quantidadeHa * local.areaHa;
				setValue('quantidadeTotal', qdeTotal);
			} else if (form.quantidadeHa !== undefined) {
				qdeTotal = form.quantidadeHa * local.areaHa;
			}
			setValue('quantidadeTotal', qdeTotal);
			setValue('areaHa', local.areaHa);
			setForm({ ...form, areaHa: local.areaHa, quantidadeTotal: qdeTotal, local: local })
		} else {
			setValue('quantidadeTotal', null);
			setValue('areaHa', null);
			setForm({ ...form, areaHa: null, quantidadeTotal: null, local: null })
		}
	}, [form, setForm, setValue])

	const handleInsumo = useCallback((materiais) => {
		if (materiais !== undefined) {
			setValue('unidade', materiais.unidade.sigla);
			setForm({ ...form, unidade: materiais.unidade.sigla, materiais: materiais })
		} else {
			setValue('unidade', null);
			setForm({ ...form, unidade: null, materiais: null })
		}
	}, [form, setForm, setValue])

	const handleQuantidadeHa = useCallback((event) => {
		if (event.target.value !== undefined) {
			let areaHa;
			if (form.local.areaHa !== undefined) {
				areaHa = form.local.areaHa;
			} else if (params.id !== undefined && props.insumoAnaliseSolo !== undefined) {
				let local = props.locais.find(obj => {
					return obj.value === props.insumoAnaliseSolo.local.id;
				});
				if (local !== undefined && local.areaHa !== undefined) {
					areaHa = local.areaHa;
				}
			}
			let qdeTotal = event.target.value * areaHa;
			setValue('quantidadeTotal', qdeTotal);
			setForm({ ...form, quantidadeTotal: qdeTotal })
		} else {
			setValue('quantidadeTotal', null);
			setForm({ ...form, quantidadeTotal: null })
		}
	}, [form, setForm, setValue])

	const submitForm = useCallback(async (data) => {
		let insumoAnaliseSolo =
		{
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			safraId: JSON.parse(localStorage.getItem('safraId')).value,
			localId: form.local.value,
			materiaisId: form.materiais.value,
			quantidadeHa: data.quantidadeHa,
			quantidadeTotal: data.quantidadeTotal,
			servicoId: form.servico ? form.servico.value : data.servico.value
		}
		if (params.id !== undefined) {
			insumoAnaliseSolo.id = form.id;
			props.updateInsumoAnaliseSolo(insumoAnaliseSolo, props.history)
		} else {
			props.createInsumoAnaliseSolo(insumoAnaliseSolo, props.history)
		}
	}, [form]);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							{visualize ? 'Visualizar ' :
								(params.id ? 'Editar ' :
									'Cadastrar '
								)} materiais da análise do solo
						</Title>
					</div>
				</div>
				<CardStyle className='card mt-0' >
					<div className='card-header row justify-content-start col-md-12'>
						<div className="card-header col-md-3">
							<label className="label">
								Locais:*
							</label>
							<Select
								id="local"
								name="local"
								placeholder='Selecionar'
								isDisabled={visualize}
								className="select-style ajust-label-padrao disabled-select"
								options={props.locais}
								value={form.local}
								onChange={item => {
									setValue('local', item.value);
									setForm({ ...form, local: item });
									handleLocal(item);
								}}
								ref={() => register({ name: "local" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.local && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-1">
							<label className="label">
								Area (Ha):
							</label>
							<input
								type='number'
								name="areaHa"
								disabled={true}
								className="form-control"
								autoComplete="off"
								ref={register({ required: false })}
							/>
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Grupo de serviço:*
							</label>
							<Select
								id="servicoTipo"
								name="servicoTipo"
								htmlFor="servicoTipo"
								placeholder='Selecionar'
								isDisabled={visualize}
								className="select-style ajust-label-padrao disabled-select"
								options={props.servicoTipos}
								value={form.servicoTipo}
								onChange={item => {
									setValue('servicoTipo', item.value);
									setForm({ ...form, servicoTipo: item, servico: null })
									handleGrupoServico(item);
									setValue('servico', null);
								}}
								ref={() => register({ name: "servicoTipo" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.servicoTipo && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Serviço:*
							</label>
							<Select
								id="servico"
								name="servico"
								placeholder='Selecionar'
								isDisabled={!form.servicoTipo || visualize}
								className="select-style ajust-label-padrao disabled-select"
								options={props.servicos}
								value={form.servico}
								onChange={item => {
									setValue('servico', item.value);
									setForm({ ...form, servico: item })
								}}
								ref={() => register({ name: "servico" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.servico && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Grupo de Insumos:*
							</label>
							<Select
								id="familiaMateriais"
								name="familiaMateriais"
								placeholder='Selecionar'
								isDisabled={visualize}
								className="select-style ajust-label-padrao disabled-select"
								options={props.familiasMateriais}
								value={form.familiaMateriais}
								onChange={item => {
									setValue('familiaMateriais', item.value);
									setForm({ ...form, familiaMateriais: item, materiais: null, unidade: null });
									setValue('materiais', null);
									setValue('unidade', null);
								}}
								ref={() => register({ name: "familiaMateriais" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.familiaMateriais && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Insumo:*
							</label>
							<Select
								id="materiais"
								name="materiais"
								placeholder='Selecionar'
								isDisabled={visualize || props.materiais.length < 1}
								className="select-style ajust-label-padrao disabled-select"
								options={materiais}
								value={form.materiais}
								onChange={item => {
									setValue('materiais', item.value);
									setForm({ ...form, materiais: item });
									handleInsumo(item)
								}}
								ref={() => register({ name: "materiais" }, { required: true })}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.materiais && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Unidade:*
							</label>
							<input
								type='text'
								name="unidade"
								disabled={true}
								className="form-control"
								autoComplete="off"
								ref={register({ required: false })}
							/>
							{errors.unidade && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Quantidade Ha:*
							</label>
							<input
								type='number'
								min='0'
								step="any"
								name="quantidadeHa"
								disabled={visualize}
								placeholder='Quantidade Ha'
								className="form-control"
								autoComplete="off"
								onChange={item => {
									handleQuantidadeHa(item)
								}}
								ref={register({ required: true })}
							/>
							{errors.quantidadeHa && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">
								Quantidade Total:*
							</label>
							<input
								type='number'
								name="quantidadeTotal"
								disabled={true}
								placeholder='Quantidade Total'
								className="form-control"
								autoComplete="off"
								ref={register({ required: true })}
							/>
							{errors.quantidadeTotal && <span className="span-errors">Campo obrigatório</span>}
						</div>
					</div>
				</CardStyle>
				<SubmitFormButtons
					flagVisualizar={visualize}
					flagEditar={params.id !== undefined}
					actionGoBack={() => props.history.goBack()}
				/>
			</form>
		</>
	);
}

const mapStateToProps = state => ({
	...state,
	locais: state.insumoAnaliseSolo.locais ? ObjectHelper.getValuesLocationParseToSelect(state.insumoAnaliseSolo.locais) : [],
	servicoTipos: state.insumoAnaliseSolo.servicoTipos ? ObjectHelper.getValuesParseToSelect(state.insumoAnaliseSolo.servicoTipos) : [],
	servicos: state.insumoAnaliseSolo.servicos ? ObjectHelper.getValuesParseToSelect(state.insumoAnaliseSolo.servicos) : [],
	familiasMateriais: state.insumoAnaliseSolo.familiasMateriais ? ObjectHelper.getValuesParseToSelect(state.insumoAnaliseSolo.familiasMateriais) : [],
	materiais: state.insumoAnaliseSolo.materiais ? ObjectHelper.getValuesMaterialParseToSelect(state.insumoAnaliseSolo.materiais) : [],
	insumoAnaliseSolo: state.insumoAnaliseSolo.insumoAnaliseSolo,
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(InsumoAnaliseSoloCadastro);

