import React, { useState, useCallback, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import Title from "../../components/Title/title.component";
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import { Creators as ModulosCreators } from './store/material.duks';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';
import Select from 'react-select';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';
import Modal from '../../components/CommonModal/commonModal';
import MaterialFamilyService from '../../services/familiaMaterialService';
import ToastHelper from "../../utils/toastHelper";
import Utils from "../../utils/utils"
import './material.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import toxicologicasService from 'services/toxicologicasService'
import volumeReceitaService from "services/volumeReceitaService";

function MaterialRegister(props) {

	const [visualize, setVisualize] = useState(false);
	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [optionsMaterials, setOptionsMaterials] = useState([]);
	const [form, setForm] = useState([])
	const [compositeMaterial, setCompositeMaterial] = useState(false)
	const [auxUnits, setAuxUnits] = useState([])
	const [auxUnity, setAuxUnity] = useState()
	const [auxMaterial, setAuxMaterial] = useState()
	const [auxMaterials, setAuxMaterials] = useState([])
	const [amount, setAmount] = useState()
	const [ordem, setOrdem] = useState()
	const [showModalFamilyMaterial, setShowModalFamilyMaterial] = useState(false)
	const [toxicologicas, settoxicologicas] = useState([])
	const [volumesReceita, setVolumesReceita] = useState([])

	const filtroFamiliaMateriais = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
		page: 1,
		pageSize: 99999,
		order: "nome"
	}

	useEffect(() => {
		props.getFamilyMaterials(filtroFamiliaMateriais)

		props.getUnits({
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
			page: 1,
			pageSize: 99999,
			filtro: ""
		})

		async function fetch() {
			const { data } = await toxicologicasService.buscarTodas();
			settoxicologicas(data.result)

			const resultVolumeReceita = await volumeReceitaService.buscar();
			if (resultVolumeReceita.data && resultVolumeReceita.data.length > 1) {
				setVolumesReceita(resultVolumeReceita.data.map(dado => { return { label: dado.valor, value: dado.valor }; }
				));
			}
		}
		fetch();
	}, []);

	useEffect(() => {
		if (params.modo === 'visualizar') setVisualize(true);
		if (params.id !== undefined) props.getMaterialById(params.id, setAuxMaterials);
	}, []);

	useEffect(() => {
		if (compositeMaterial) props.getMaterials({
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		})
	}, [compositeMaterial, form.familyMaterial]);

	const handleAuxMaterial = useCallback((unidade) => {

		if (unidade) {
			let newUnits = []
			let newUnity = {}
			newUnity = {
				label: unidade.sigla,
				value: unidade.id
			}
			newUnits.push(newUnity)
			if (unidade.unidadesConversao) {

				unidade.unidadesConversao.forEach(subUnity => {

					let newSubUnity = {

						label: subUnity.unidadeConvertida.sigla,
						value: subUnity.unidadeConvertida.id
					}
					newUnits.push(newSubUnity)
				});
			}
			setAuxUnits(newUnits)
			setAuxUnity(null)
		}
	}, [auxUnits, setAuxUnits, setAuxUnity])

	const addAuxMaterial = useCallback(() => {
		let newAuxMaterials = [...auxMaterials]
		let newMaterial = {
			material: auxMaterial,
			unidade: auxUnity,
			quantidade: amount,
			ordem: ordem
		}
		newAuxMaterials.push(newMaterial)
		setAuxMaterial(null)
		setAuxUnity(null)
		setAmount('')
		setOrdem('')
		setAuxMaterials(newAuxMaterials)
	})

	const tableCompositeMaterial = () => (
		<Table
			className='mt-3'
			align='left'
			columns={columns}
			actions={!visualize ? actions : null}
			acoesSingleButton={false}
			data={auxMaterials}
			emptyMessage='Nenhum item adicionado.'
			emptyColSpan={columns ? columns.length + 1 : 0}
		/>
	);

	const columns = [
		{ id: 0, title: 'Ordem', property: 'ordem' },
		{ id: 1, title: 'Insumo', property: 'material.label' },
		{ id: 2, title: 'Unidade', property: 'unidade.label' },
		{ id: 3, title: 'Quantidade', property: 'quantidade' },
	];

	const actions = [
		{
			id: 0,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_INSUMOS_CRIAR,
			action: item => deleteAuxMaterial(item),
		},
		{
			id: 1,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_INSUMOS_CRIAR,
			action: item => EditAuxMaterial(item),
		},
	];

	const EditAuxMaterial = useCallback(async (item) => {
		if (item) {
			let newList = [...auxMaterials];
			await newList.splice(newList.indexOf(item), 1);
			setAuxMaterials([]);
			setAuxMaterials(newList);
			props.getMaterials({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
				page: 1,
				pageSize: 99999,
				order: "Nome"
			});
			deleteAuxMaterial(item);
			setAuxMaterial(item.material)
			setAuxUnity(item.unidade)
			setAmount(item.quantidade);
			setOrdem(item.ordem)
		}
	}, [auxMaterials, setAuxMaterials]);

	useEffect(() => {
		props.updateOptionsAuxMaterials(optionsMaterials, setOptionsMaterials, auxMaterials)
	}, [auxMaterials, compositeMaterial])

	useEffect(() => {
		if (props.material && params.id !== undefined) {
			if (props.material.unidade) {
				setForm({
					id: params.modo === 'duplicar' ? null : props.material.id,
					description: props.material.descricao,
					unity: { value: props.material.unidade.id, label: props.material.unidade.sigla },
					familyMaterial: { value: props.material.familiaMateriais.id, label: props.material.familiaMateriais.nome },
					name: params.modo === 'duplicar' ? null : props.material.nome,
					code: params.modo === 'duplicar' ? null : props.material.codigo,
					volumeMistura: { value: props.material.volumeMistura, label: props.material.volumeMistura },
					toxicologica: props.material.toxicologicas
				})
				if (props.material.id) {
					setValue('id', params.modo === 'duplicar' ? null : props.material.id);
					setValue('description', props.material.descricao);
					setValue('unity', { value: props.material.unidade.id, label: props.material.unidade.sigla },);
					setValue('familyMaterial', { value: props.material.familiaMateriais.id, label: props.material.familiaMateriais.nome },);
					setValue('name', params.modo === 'duplicar' ? null : props.material.nome);
					setValue('code', params.modo === 'duplicar' ? null : props.material.codigo);
					setValue('volumeMistura', { value: props.material.volumeMistura, label: props.material.volumeMistura });
					setValue('pincipioAtivo', props.material.pincipioAtivo);
					setValue('enfermidade', props.material.enfermidade);
					setValue('carencia', props.material.carencia);
					setValue('reentrada', props.material.reentrada);

				}
				if (auxMaterials.length > 0) {
					setCompositeMaterial(true)
				}
			}

		}
	}, [props.material])

	useEffect(() => {
		let newListOptionsMaterials = [...optionsMaterials]
		newListOptionsMaterials.forEach(material => {
			if (material.composto) {
				newListOptionsMaterials.splice(newListOptionsMaterials.indexOf(material), 1)
				setOptionsMaterials(newListOptionsMaterials)
			}
		});
	}, [optionsMaterials])

	useEffect(() => {
		if (props.materials) {
			if (props.materials.length > 0) {
				setOptionsMaterials(props.materials)
			}
		}
	}, [props.materials])

	const deleteAuxMaterial = (item) => {
		if (item) {
			const newList = [...auxMaterials];
			newList.splice(newList.indexOf(item), 1);
			setAuxMaterials(newList);
			props.getMaterials({
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
				page: 1,
				pageSize: 99999,
				order: "Nome"
			});
		}
	};

	const submitForm = useCallback((data) => {
		let fazendaI = JSON.parse(localStorage.getItem('fazendaId')).value
		let material = {
			id: data.id ? data.id : (form.id ? form.id : ''),
			descricao: data.description,
			fazendaId: fazendaI.toString(),
			idFazenda: fazendaI.toString(),
			unidadeId: data.unity.value,
			familiaMateriaisId: data.familyMaterial.value,
			nome: data.name,
			codigo: data.code,
			composto: compositeMaterial,
			materiaisAuxiliares: [],
			volumeMistura: data.volumeMistura ? data.volumeMistura.value : null,
			pincipioAtivo: data.pincipioAtivo,
			toxicologicasId: data.toxicologica !== undefined ? data.toxicologica.value : undefined,
			enfermidade: data.enfermidade,
			carencia: data.carencia,
			reentrada: data.reentrada,
		}

		if (auxMaterials.length > 0) {
			auxMaterials.forEach(auxMaterial => {
				let newAuxMaterial = {
					materialAuxId: auxMaterial.material.value,
					unidadeId: auxMaterial.unidade.value,
					quantidade: auxMaterial.quantidade,
					ordem: auxMaterial.ordem
				}
				material.materiaisAuxiliares.push(newAuxMaterial)
			})
			material.composto = true
		}
		if (params.id === undefined || params.modo === 'duplicar') {
			props.createMaterial(material, props.history, params.source)
		} else {
			props.updateMaterial(material, props.history, params.source)
		}
	}, [form, auxMaterials]);

	const submitModal = useCallback(async (item) => {
		if (item) {
			let newMaterialFamily = {
				descricao: item.descricao,
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				nome: item.nome
			}
			const { data } = await MaterialFamilyService.cadastrar(newMaterialFamily)

			if (data && data.isOk) {
				ToastHelper.success('Grupo de insumo criado com sucesso!')
				setShowModalFamilyMaterial(false)
				props.getFamilyMaterials(filtroFamiliaMateriais)
			} else
				ToastHelper.error("Ocorreu um erro.");
		}
	})

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={handleSubmit(submitForm)}>
					<div className='row'>
						<div className='col'>
							<Title> {visualize ? 'Visualizar ' : (params.id ? 'Editar ' : 'Cadastrar ')} insumos </Title>
						</div>
					</div>
					<Tabs>
						<TabList>
							<Tab>Insumo</Tab>
							<Tab>Ficha Técnica</Tab>
						</TabList>
						<TabPanel forceRender>
							<CardStyle style={{ marginTop: -10, boxShadow: "-1px 3px 5px -2px rgba(0,0,0,0.16)" }} className='card mb-0 pb-0'>
								<div className='card-header row col-md-12 flex'>
									<div className="col-md-4 mt-3">
										<label className="label"> Grupo de insumos:*  </label>
										<div className="input-group mb-3 " data-tip="">
											<Select
												id="familyMaterial"
												name="familyMaterial"
												htmlFor="familyMaterial"
												placeholder='Selecione'
												isDisabled={visualize}
												className="form-control select-ajust"
												options={props.familyMaterials}
												value={form.familyMaterial}
												autoComplete="off"
												onChange={item => {
													setValue('familyMaterial', item);
													setForm({ ...form, familyMaterial: item })
												}}
												ref={() => register({ name: "familyMaterial" }, { required: true })}
												theme={theme => ({ ...theme, borderRadius: 0 })}
											/>
											<div className="input-group-prepend" data-tip={'Adicionar Insumo'} >
												<button className="btn-custom float-right " type='button' onClick={() => setShowModalFamilyMaterial(true)} >
													<i className="fas fa-plus"></i>
												</button>
											</div>
										</div>
										{errors.familyMaterial && <span className="span-errors">Campo obrigatório</span>}
									</div>


									<div className="card-header col-md-8">
										<label className="label"> Nome:*</label>
										<input
											type='text'
											name="name"
											disabled={visualize}
											placeholder='Digite um nome'
											className="form-control"
											autoComplete="off"
											ref={register({ required: true })}
										/>
										{errors.name && <span className="span-errors">Campo obrigatório</span>}
									</div>
								</div>
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-3">
										<label className="label">
											Codigo:*
                            </label>
										<input
											type='text'
											name="code"
											disabled={visualize}
											placeholder='Digite um codigo'
											className="form-control"
											autoComplete="off"
											ref={register({ required: true })}
										/>
										{errors.code && <span className="span-errors">Campo obrigatório</span>}
									</div>
									<div className="card-header col-md-3">
										<label className="label">Unidade:*</label>
										<Select
											id="unity"
											name="unity"
											htmlFor="unity"
											placeholder='Selecione'
											isDisabled={visualize}
											className="select-style ajust-label-padrao disabled-select"
											options={props.units}
											autoComplete="off"
											value={form.unity}
											onChange={item => {
												setValue('unity', item);
												setForm({ ...form, unity: item })
											}}
											ref={() => register({ name: "unity" }, { required: true })}
											theme={theme => ({ ...theme, borderRadius: 0 })}
										/>
										{errors.unity && <span className="span-errors">Campo obrigatório</span>}
									</div>
									<div className="card-header col-md-6">
										<label className="label">Descrição:</label>
										<input
											type='textarea'
											name="description"
											disabled={visualize}
											placeholder='Descrição'
											className="form-control"
											autoComplete="off"
											ref={register({ required: false })}
										/>
									</div>
								</div>
								<div className={"flex col-md-6 " + (compositeMaterial ? "head-type-entry-blue" : "head-type-entry-green")}>
									<label className="col-md-3 label ml-0 pl-0">
										<strong>Tipo de insumo:</strong>
									</label>
									<div className="col-md-12 flex">
										<label className="label text-right mr-3 mt-2">
											<strong>Simples</strong>
										</label>
										<div className="p-0 m-0 text-center custom-switch mt-1">
											<Toggle
												disabled={visualize}
												className="custom-classname"
												checked={compositeMaterial}
												icons={{
													checked: <i className="fas fa-long-arrow-alt-left white-arrow" />,
													unchecked: <i className="fas fa-long-arrow-alt-right white-arrow" />,
												}}
												onChange={() => setCompositeMaterial(!compositeMaterial)}
											/>
										</div>
										<label className="label text-left ml-3 mt-2 mb-3">
											<strong>Composto</strong>
										</label>
									</div>
									<div className={`${!compositeMaterial ? "hidden " : ""}` +
										'card-header col-md-6'}>
										<label className="label">Volume da Mistura (Litros):</label>
										<Select
											id="volumeMistura"
											name="volumeMistura"
											htmlFor="volumeMistura"
											placeholder='Selecione'
											isDisabled={visualize}
											className="select-style ajust-label-padrao disabled-select"
											options={volumesReceita}
											autoComplete="off"
											value={form.volumeMistura}
											onChange={item => {
												setValue('volumeMistura', item);
												setForm({ ...form, volumeMistura: item })
											}}
											ref={() => register({ name: "volumeMistura" }, { required: compositeMaterial })}
											theme={theme => ({ ...theme, borderRadius: 0 })}
										/>
										{errors.volumeMistura && <span className="span-errors">Campo obrigatório</span>}
									</div>
								</div>


							</CardStyle>
							{
								compositeMaterial &&
								<CardStyle className='card mt-0 pb-3' >
									<div className="col-md-12 flex card-externo-horas">
										<div className="col-md-12 ml-0">
											<div className="card-header">Insumos complementares</div>
											{
												!visualize &&
												<div className='row justify-content-start col-md-12 card-border'>
													<div className="card-header col-md-2">
														<label className="label"> Ordem:* </label>
														<input
															type='number'
															// disabled={!auxUnity}
															placeholder='Ordem'
															className="form-control"
															autoComplete="off"
															min="1"
															value={ordem}
															onChange={item => {
																setOrdem(item.target.value);
															}}
														/>
													</div>
													<div className="col-md-3 card-header">
														<label className="label">Insumo complementar:</label>
														<Select
															placeholder='Selecione'
															isDisabled={visualize}
															className="select-style ajust-label-padrao disabled-select"
															options={optionsMaterials}
															value={auxMaterial}
															onChange={item => {
																setAuxMaterial(item);
																handleAuxMaterial(item.unidade)
															}}
															theme={theme => ({ ...theme, borderRadius: 0 })}
														/>
													</div>
													<div className="card-header col-md-3">
														<label className="label">Unidade:</label>
														<Select
															placeholder='Selecione'
															isDisabled={!auxMaterial}
															className="select-style ajust-label-padrao disabled-select"
															options={auxUnits}
															value={auxUnity}
															onChange={item => {
																setAuxUnity(item);
															}}
															theme={theme => ({ ...theme, borderRadius: 0 })}
														/>
													</div>
													<div className="card-header col-md-3">
														<label className="label">Quantidade:*</label>
														<input
															type='number'
															disabled={!auxUnity}
															placeholder='Quantidade'
															className="form-control"
															autoComplete="off"
															min="0"
															value={amount}
															onChange={item => {
																setAmount(item.target.value);
															}}
														/>
													</div>
													<div data-tip="col-md-1" >
														<div className="col-md-12 label-horas margin-button-insumo mt-ngt">
															<div data-tip={'Adicionar Material'} className="col-md-12 flex padding-input-modal">
																<button
																	className="btn btn-primary btn-style active pull-right"
																	type='button'
																	disabled={!amount || !auxMaterial || !auxUnity || !ordem}
																	onClick={addAuxMaterial}
																>
																	<i className="fas fa-plus"></i>
																</button>
															</div>
														</div>
													</div>
												</div>
											}
										</div>
										{tableCompositeMaterial()}
									</div>
									<div className="col-md-12">
									</div>
								</CardStyle>
							}

						</TabPanel>
						<TabPanel forceRender>
							<CardStyle style={{ marginTop: -10 }} className='card mb-0 pb-0'>
								<div className='card-header row col-md-12 flex'>
									<div className="col-md-4">
										<label className="label">Toxicologicas:</label>
										<Select
											name="toxicologica"
											placeholder='Selecione'
											isDisabled={visualize}
											className="select-style ajust-label-padrao disabled-select"
											options={ObjectHelper.getValuesParseToSelect(toxicologicas)}
											autoComplete="off"
											value={form.toxicologica}
											onChange={item => {
												setValue('toxicologica', item);
												setForm({ ...form, toxicologica: item })
											}}
											ref={() => register({ name: "toxicologica" }, { required: false })}
											theme={theme => ({ ...theme, borderRadius: 0 })}
										/>
										<br></br>
									</div>
									<div className="card-header col-md-12">
										<label className="label"> Princípio ativo </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Princípio ativo..."
											name="pincipioAtivo"
											type="textarea"
											maxLength="200"
											disabled={visualize}
											ref={register({ required: false })}
										/>
									</div>

									<div className="card-header col-md-12">
										<label className="label"> Enfermidade </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Enfermidade..."
											name="enfermidade"
											type="textarea"
											maxLength="200"
											ref={register({ required: false })}
											disabled={visualize}
										/>
									</div>
									<div className="card-header col-md-12">
										<label className="label"> Carência </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Carência..."
											name="carencia"
											type="textarea"
											maxLength="200"
											ref={register({ required: false })}
											disabled={visualize}
										/>
									</div>
									<div className="card-header col-md-12">
										<label className="label"> Reentrada </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Reentrada..."
											name="reentrada"
											type="textarea"
											maxLength="200"
											ref={register({ required: false })}
											disabled={visualize}
										/>
									</div>
								</div>
							</CardStyle>
						</TabPanel>
					</Tabs>
					<SubmitFormButtons
						flagVisualizar={visualize}
						flagEditar={params.id !== undefined}
						actionGoBack={() => {
							props.history.goBack();
							if (params.source) window.close();
						}}
					/>

					<Modal
						title={"Cadastrar Grupo de Insumo"}
						show={showModalFamilyMaterial}
						close={() => setShowModalFamilyMaterial(false)}
						modalClose={(item) => submitModal(item)}
					/>
				</form>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({

	unit: state.units,
	material: state.materials.material,
	materials: Utils.removeInactiveToSelect(ObjectHelper.getValuesMaterialParseToSelect(state.materials.materials.items)),
	familyMaterials: Utils.removeInactiveToSelect(ObjectHelper.getValuesParseToSelect(state.materials.familyMaterials ? state.materials.familyMaterials.items : [])),
	units: Utils.removeInactiveToSelect(ObjectHelper.getValuesUnitsParseToSelect(state.materials.units ? state.materials.units.items : []))
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MaterialRegister);

