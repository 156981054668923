import api from './api';

export default {
	buscarPorId(servicoGrupoId){
		return api.get(`/api/ServicoTipo/BuscarPorId/${servicoGrupoId}`)
	},
	buscarTodos(param){
		return api.post(`/api/ServicoTipo/BuscarTodas`, param);
	},
	editar(grupoServico){
		return api.put(`/api/ServicoTipo`, grupoServico)
	},
	cadastrar(grupoServico){
		return api.post(`/api/ServicoTipo`, grupoServico)
	},
	deletar(id){
		return api.delete(`/api/ServicoTipo/${id}`);
	},
	ativaDesativa(id){
		return api.get(`/api/ServicoTipo/Ativar/${id}`);
	},
	getByCulturaSafra(fazendaId, culturaId){
		return api.get(`/api/ServicoTipo/GetByCultura/${fazendaId}/${culturaId}`);
	},

};