import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Copyright } from '../../../components/Copyright';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import loginService from '../../../services/loginService';
import { isAuthenticated, setToken } from '../../../services/auth';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#006461',
		},
	},
});

const useStyles = makeStyles(() => ({
	root: {
		minHeight: '100%',
		width: '100%',
		height: 'auto',
		position: 'fixed',
		backgroundSize: 'cover',
		top: 0,
		left: 0,
	},
	image: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/images/login-background.jfif'
			})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[50]
				: theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	logo: {
		width: '400px'
	},
	logos: {
		margin: theme.spacing(4, 0, 0, 0),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	paper: {
		margin: theme.spacing(0, 4, 0, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		height: '200px'
	},
}));

const Login = (props) => {
	const classes = useStyles();

	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [loginInvalido, setLoginInvalido] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (isAuthenticated()) {
			props.history.push('/login');
		}
	}, []);

	const handleLoginChange = (e) => {
		setLogin(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleRememberChange = (e) => {
		setRemember(!remember);
	};

	const handleSubmit = (e) => {
		setIsLoading(true);
		e.preventDefault();
		loginService.login(login.trim(), password).then((result) => {
			localStorage.clear();
			localStorage.setItem("userEmail", result.data.email);
			setToken(result.data.token);
			props.history.push('/');

		}).catch(() => {
			setLoginInvalido(true)
		}).finally(() => {
			setIsLoading(false)
		});
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" className={classes.root}>
				<CssBaseline />
				<Grid container xs={false} sm={4} md={7} className={classes.image} />
				<Grid
					container
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					square
				>
					<Grid container justify="center" spacing={1} className={classes.logos}>
						<Grid>
							<img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="SGAGRO" className={classes.logo} />
						</Grid>
					</Grid>
					<Grid container className={classes.paper}>
						<form className={classes.form} noValidate onSubmit={handleSubmit} autocomplete="off">
							<TextField
								value={login}
								margin="normal"
								required
								fullWidth
								id="login"
								label="Usuário"
								name="login"
								autoComplete="none"
								autoFocus
								onChange={handleLoginChange}
							/>
							<TextField
								value={password}
								margin="normal"
								required
								fullWidth
								name="password"
								label="Senha"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={handlePasswordChange}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value={remember}
										color="primary"
										onChange={handleRememberChange}
									/>
								}
								label="Lembrar"
							/>
							{loginInvalido && (
								<Alert
									severity="error"
									onClose={() => {
										setLoginInvalido(false);
									}}
								>
									Login ou senha inválidos
								</Alert>
							)}
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								{isLoading ? (
									<CircularProgress
										size={25}
										color={theme.palette.text.primary}
									/>
								) : (
									'Entrar'
								)}
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="#" variant="body2">
										Esqueceu a senha?
                </Link>
								</Grid>
								<Grid item>
									<Link href="#" variant="body2">
										{'Não tem uma conta? Cadastre-se'}
									</Link>
								</Grid>
							</Grid>
							<Box mt={5}>
								<Copyright />
							</Box>
						</form>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withRouter(Login));
