import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getOrdemServicoById: ['params', 'callback'],
	setOrdemServicoById: ['params'],

	getEpis: ['params', 'callback'],
	setEpis: ['params'],

	updateOrdemServico: ['params', 'history'],
})

const INITIAL_STATE_VALUES = {
	item: {
		id: null,
		codigo: null,
		dataInicio: null,
		dataFim: null,
		servico: {},
		areaTotal: 0,
		areaAplicacao: 0,
		locais: [],
		epis: [],
		insumos: [],
		procedimento: '',
		observacoes: ''
	}
 };

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getOrdemServicoById = (state = INITIAL_STATE, action) => state.merge({...state, item: action.data});
const getEpis = (state = INITIAL_STATE, action) => state.merge({...state, EPIs: action.data})
const updateOrdemServico = (state = INITIAL_STATE, action) => state.merge( action.params )

export default createReducer(INITIAL_STATE, {
	[Types.SET_ORDEM_SERVICO_BY_ID]: getOrdemServicoById,
	[Types.SET_EPIS]: getEpis,
	[Types.UPDATE_ORDEM_SERVICO]: updateOrdemServico,
})
