import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getUnits: ['params',  'callback'],
	readUnits: ['params'],

	createUnit: ['params', 'history', 'callback'],
	postUnit: ['params'],

	getUnitById: ['params', 'setUnitsConversions','callback'],
	setGetUnitById: ['params'],

	updateUnit: ['params', 'history'],

	deleteUnit: ['params', 'props', 'callback'],

	updateOptionsUnits: ['optionsUnits', 'setOptionsUnits', 'addUnits', 'callback']

})

const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getUnits = (state = INITIAL_STATE, action) => state.merge({itens: action.data})
const createUnit = (state = INITIAL_STATE, action) => state.merge({unit: action.data})
const getUnitById = (state = INITIAL_STATE, action) => state.merge( action.data )
const updateUnit = (state = INITIAL_STATE, action) => state.merge( action.params )
const deleteUnit = (state = INITIAL_STATE, action) => state.merge({idDeleteItem: action.params })
const updateOptionsUnits = (state = INITIAL_STATE, action) => state.merge({})

export default createReducer(INITIAL_STATE, {
	[Types.SET_GET_UNIT_BY_ID]: getUnitById,
	[Types.READ_UNITS]: getUnits,
	[Types.POST_UNIT]: createUnit,
	[Types.UPDATE_UNIT]: updateUnit,
	[Types.DELETE_UNIT]: deleteUnit,
	[Types.UPDATE_OPTIONS_UNITS]: updateOptionsUnits
})
