import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getLocais: ['params', 'callback'],
	setLocais: ['locais'],

	getServicos: ['params', 'callback'],
	setServicos: ['servicos'],

	getSafra: ['params', 'callback'],
	setSafra: ['safra'],

	getGrupoServicos: ['params', 'callback'],
	setGrupoServicos: ['grupoServicos'],

	getRecursos: ['params', 'callback'],
	setRecursos: ['recursos'],

	// getRecursosClasse: ['params', 'callback'],
	// setRecursosClasse: ['recursosClasse'],

	getRecursosTipo: ['params', 'callback'],
	setRecursosTipo: ['recursosTipo'],

	buscarHistograma: ['params', 'callback'],
	readHistograma: ['params']

})

const INITIAL_STATE_VALUES = {
	locais: [],
	grupoServicos: [],
	servicos:[],
	safra:[],
	recursos: [],
	recursosClasse: [],
	recursosTipo: [],
	histogramas: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getLocais = (state = INITIAL_STATE, action) => ({ ...state, locais: action.data })
const getServicos = (state = INITIAL_STATE, action) => ({ ...state, servicos: action.data })
const getSafra = (state = INITIAL_STATE, action) => ({ ...state, safra: action.data })
const getGrupoServicos = (state = INITIAL_STATE, action) => ({...state, grupoServicos: action.data})
const getRecursos = (state = INITIAL_STATE, action) => ({...state, recursos: action.data})
// const getRecursosClasse = (state = INITIAL_STATE, action) => ({...state,recursosClasse: action.data})
const getRecursosTipo = (state = INITIAL_STATE, action) => ({...state, recursosTipo: action.data})
const buscarHistogramas = (state = INITIAL_STATE, action) => ({...state, histogramas: action.data})


export default createReducer(INITIAL_STATE_VALUES, {
	[Types.SET_LOCAIS]: getLocais,
	[Types.SET_SERVICOS]: getServicos,
	[Types.SET_SAFRA]: getSafra,
	[Types.SET_GRUPO_SERVICOS]: getGrupoServicos,
	[Types.SET_RECURSOS]: getRecursos,
	// [Types.SET_RECURSOS_CLASSE]: getRecursosClasse,
	[Types.SET_RECURSOS_TIPO]: getRecursosTipo,
	[Types.READ_HISTOGRAMA]: buscarHistogramas,
})
