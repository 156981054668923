import api from './api';

export default {
	buscar(pessoa){
		return api.post(`/api/Pessoa/BuscarTodas`, pessoa);
	},
	salvar(pessoa){
		return api.post(`/api/Pessoa`, pessoa);
	},
	deletar(id){
		return api.delete(`/api/Pessoa/${id}`);
	},
	buscarPorId(pessoaId){
		return api.get(`/api/Pessoa/${pessoaId}`);
	},
	editar(pessoa){
		return api.put('/api/Pessoa', pessoa);
	},
	ativar(idPessoa){
		return api.get(`/api/Pessoa/AtivarPessoa/${idPessoa} `)
	},
	getOperadores(fazendaId) {
		return api.get(`/api/Pessoa/Operadores/${fazendaId}`);
	},
	getPessoasAtivas(fazendaId) {
		return api.get(`/api/Pessoa/BuscarPessoasAtivas/${fazendaId}`);
	},
};


