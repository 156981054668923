import React from 'react'

export default function SubmitFormButtons(props) {

	return (
		<div className="col-md-12 mt-3">
			<button className="btn btn-outline-secondary pull-left" type="button" onClick={props.actionGoBack}>
				<i className='fas fa-arrow-left' /> Voltar
            </button>
			{
				props.customSubmitAction &&
				<button className="btn btn-primary active pull-right" onClick={() => props.customSubmitAction()} hidden={props.flagVisualizar}>
					<i className='fas fa-check' /> {props.flagEditar ? 'Salvar' : 'Cadastrar'}
				</button>
			}
			{
				!props.customSubmitAction &&
				<button className="btn btn-primary active pull-right" type="submit" hidden={props.flagVisualizar}>
					<i className='fas fa-check' /> {props.flagEditar ? 'Salvar' : 'Cadastrar'}
				</button>
			}
		</div>
	)
}
