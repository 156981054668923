import api from './api';

export default {
	getAll(grupoMaquina){
		return api.post(`/api/GrupoMaquinas/BuscarTudo`, grupoMaquina);
	},
	deletar(id){
		return api.delete(`/api/GrupoMaquinas/${id}`);
	},
	salvar(grupoMaquina){
		return api.post(`/api/GrupoMaquinas`, grupoMaquina)
	},
	buscarPorId(grupoMaquinaId){
		return api.get(`/api/GrupoMaquinas/${grupoMaquinaId}`)
	},
	editar(grupoMaquina){
		return api.put(`/api/GrupoMaquinas`, grupoMaquina)
	},
	ativaDesativa(id){
		return api.get(`/api/GrupoMaquinas/AtivarDesativar/${id}`);
	},
};
