import { all, fork, put, takeLatest } from "redux-saga/effects";
import maquinasService from '../../../services/maquinaService'
import safraService from '../../../services/safraService'
import grupoMaquinasService from '../../../services/grupoMaquinaService'
import painelService from '../../../services/painelService'
import { Types } from "./painel.ducks"
import executeAction from "../../../store/sagas/executeAction";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 1000
}

function* asyncListaAnoMes(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield painelService.buscarAnoMeses(parametros.fazendaId);

		yield put({ type: Types.SET_ANO_MES, data: data.result });
		callback();
	}, Types.GET_ANO_MES);
}

function* asyncListaPainel(action) {
	yield executeAction(function* () {
		const { params ,callback } = action;
		const { data } = yield painelService.buscarPainel(params);

		yield put({ type: Types.READ_PAINEL, data: data.result });
		callback();
	}, Types.BUSCAR_PAINEL);
}

function* asyncListaPainelMaquinas(action) {
	yield executeAction(function* () {
		const { params ,callback } = action;
		const { data } = yield painelService.buscarMaquinas(params);

		yield put({ type: Types.READ_PAINEL_MAQUINAS, data: data.result });
		callback();
	}, Types.BUSCAR_PAINEL_MAQUINAS);
}

function* asyncListaGrupoMaquinas(action) {
	yield executeAction(function* () {

		const { callback } = action;
		const { data } = yield grupoMaquinasService.getAll(parametros);
		yield put({ type: Types.SET_GRUPO_MAQUINAS, data: data.result });
		callback();
	}, Types.GET_GRUPO_MAQUINAS);
}

function* asyncListaMaquinas(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield maquinasService.buscarTodas(parametros);

		yield put({ type: Types.SET_MAQUINAS, data: data.result });
		callback();
	}, Types.GET_MAQUINAS);
}

function* asyncListaSafra(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield safraService.get(parametros.fazendaId);

		yield put({ type: Types.SET_SAFRA, data: data.result });
		callback();
	}, Types.GET_SAFRA);
}


function* takeListaGrupoMaquinas() {
	yield takeLatest(Types.GET_GRUPO_MAQUINAS, asyncListaGrupoMaquinas);
}

function* takeListaMaquinas() {
	yield takeLatest(Types.GET_MAQUINAS, asyncListaMaquinas);
}

function* takeReadPainel() {
	yield takeLatest(Types.BUSCAR_PAINEL, asyncListaPainel);
}

function* takeListaSafra() {
	yield takeLatest(Types.GET_SAFRA, asyncListaSafra);
}

function* takeListaAnoMes() {
	yield takeLatest(Types.GET_ANO_MES, asyncListaAnoMes);
}

function* takeListaPainelMaquinas() {
	yield takeLatest(Types.BUSCAR_PAINEL_MAQUINAS, asyncListaPainelMaquinas);
}


export default function* root() {
	yield all([fork(takeListaGrupoMaquinas)])
	yield all([fork(takeListaMaquinas)])
	yield all([fork(takeListaSafra)])
	yield all([fork(takeReadPainel)])
	yield all([fork(takeListaAnoMes)])
	yield all([fork(takeListaPainelMaquinas)])
}
