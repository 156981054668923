import api from './api';

export default {

	buscarPainel(params){
		return api.post(`/api/Painel/Equipamentos/Grafico`, params);
	},
	buscarAnoMeses(fazendaId){
		return api.get(`/api/Painel/Equipamentos/AnoMes/${fazendaId}`);
	},
	buscarMaquinas(params){
		return api.post(`/api/Painel/Equipamentos/Tabela`, params);
	},
	buscarMO(params){
		return api.post(`/api/Painel/MO/BuscarTudo`, params);
	},
	buscarMOSemanal(params){
		return api.post(`/api/Painel/MO/BuscarTudo/Semanal`, params);
	},
	buscarDadosServicesByGrupo(params){
		return api.post(`/api/Painel/Servicos/BuscarDadosGrupoServico`, params);
	},
	buscarDadosServicoPorServicoTipoId(params){
		return api.post(`/api/Painel/Servicos/BuscarDadosServicoPorServicoTipoId`, params);
	},
	buscarRelatorioMO(params){
		return api.post(`/api/Painel/RelatorioMO`, params);
	},
	gerarRelatorioMO(params){
		return api.post(`/api/Painel/RelatorioMO/GerarExcel`, params, { responseType: 'blob' });
	},
	buscarRelatorioMA(params){
		return api.post(`/api/Painel/RelatorioMA`, params);
	},
	gerarRelatorioMA(params){
		return api.post(`/api/Painel/RelatorioMA/GerarExcel`, params, { responseType: 'blob' });
	}
};
