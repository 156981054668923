import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	buscarAfastamentos: ['params', 'callback'],
	readAfastamentos: ['params'],

	createAfastamento: ['params', 'history', 'callback'],
	postAfastamento: ['params'],

	getAfastamentoById: ['params', 'callback'],
	setAfastamentoById: ['params'],

	updateAfastamento: ['params', 'history'],

	deleteAfastamento: ['params', 'callback'],

	getAfastamentos: ['params', 'callback'],
	setAfastamentos: ['afastamentos'],

	getPessoas: ['params', 'callback'],
	setPessoas: ['pessoas'],
	
	getTiposAfastamentos: ['params', 'callback'],
    setTiposAfastamentos: ['tiposAfastamentos'],
})

const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getAfastamentos = (state = INITIAL_STATE, action) => state.merge({afastamentos: action.data})
const createAfastamento = (state = INITIAL_STATE, action) => state.merge({afastamento: action.data})
const getAfastamentoById = (state = INITIAL_STATE, action) => state.merge({afastamento: action.data})
const updateAfastamento = (state = INITIAL_STATE, action) => state.merge( )
const deleteAfastamento = (state = INITIAL_STATE, action) => state.merge( {idItemexcluido: action.params })
const getPessoas = (state = INITIAL_STATE, action) => state.merge({ pessoas: action.data })
const getTiposAfastamentos = (state = INITIAL_STATE, action) => state.merge({ tipoAfastamentos: action.data })

export default createReducer(INITIAL_STATE, {
	[Types.SET_AFASTAMENTO_BY_ID]: getAfastamentoById,
	[Types.SET_AFASTAMENTOS]: getAfastamentos,
	[Types.POST_AFASTAMENTO]: createAfastamento,
	[Types.UPDATE_AFASTAMENTO]: updateAfastamento,
	[Types.DELETE_AFASTAMENTO]: deleteAfastamento,
	[Types.SET_PESSOAS]: getPessoas,
	[Types.SET_TIPOS_AFASTAMENTOS]: getTiposAfastamentos,
})