import React from 'react';
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { Types } from "./material.duks";
import executeAction from "../../../store/sagas/executeAction";
import MaterialService from '../../../services/materiaisService';
import ToastHelper from "../../../utils/toastHelper";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 99999,
	filtro: "",
}

function* asyncListMaterialsByFamily(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		if (params === 'reset') {
			yield put({ type: Types.SET_MATERIALS_BY_FAMILY, data: [] });
			callback();
		} else {
			const { data } = yield call(async () => await MaterialService.buscarPorTipo(parametros.fazendaId, params));
			yield put({ type: Types.SET_MATERIALS_BY_FAMILY, data: data.result });
			callback();
		}
	}, Types.GET_MATERIALS_BY_FAMILY);
}

function* asyncListMaterials(action) {
	yield executeAction(function* () {
		const { params, callback } = action;

		const { data } = yield call(async () => await MaterialService.buscarTodas(params ? params : parametros));

		if (data.result) {
			data.result.items.forEach(item => {
				if (item.ativo) {
					item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
				} else {
					item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
				}
			});
		}

		yield put({ type: Types.SET_MATERIALS, data: data.result });
		callback();
	}, Types.GET_MATERIALS);
}

function* asyncCreateMaterial(action) {
	yield executeAction(function* () {

		const { params, history, source } = action;
		const { data } = yield call(async () => await MaterialService.cadastrar(params));

		if (data.isOk) {
			ToastHelper.success("Material criado com sucesso!")
			history.goBack()
			if (source) window.close();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.POST_MATERIAL, data: data });
	}, Types.CREATE_MATERIAL);
}

function* deleteMaterial(action) {
	yield executeAction(function* () {

		const { params, callback } = action;
		const { data } = yield call(async () => await MaterialService.deletar(params));
		if (data.isOk) {
			ToastHelper.success('Material excluído com sucesso!');
		}
		else
			ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
		callback();
	}, Types.DELETE_UNIT);
}

function* updateOptionsAuxMaterials(action) {
	yield executeAction(function* () {
		const { optionsMaterials, setOptionsMaterials, addAuxMaterials, callback } = action;
		let newOptions = [...optionsMaterials]
		newOptions.forEach(material => {
			addAuxMaterials.forEach(addMaterial => {
				if (material.value === addMaterial.material.value) {
					newOptions.splice(newOptions.indexOf(material), 1)
					setOptionsMaterials(newOptions)
				}
			})
		});
		callback();
		yield put({
			type: Types.UPDATE_OPTIONS_AUX_MATERIALS,
			data: ''
		});
	}, Types.UPDATE_OPTIONS_AUX_MATERIALS);
}

function* getMaterialById(action) {
	yield executeAction(function* () {

		const { params, setAuxMaterials, callback } = action;
		const { data } = yield call(async () => await MaterialService.buscarPorId(params));
		if (data.result.result.materiaisAuxiliares ? data.result.result.materiaisAuxiliares.length > 0 : false) {
			let newAuxMaterials = []
			data.result.result.materiaisAuxiliares.forEach(materialAux => {
				newAuxMaterials.push(
					{
						material: { value: materialAux.material.id, label: materialAux.material.nome },
						unidade: { value: materialAux.unidade.id, label: materialAux.unidade.sigla },
						quantidade: materialAux.quantidade,
						ordem: materialAux.ordem
					}
				)
			});
			setAuxMaterials(newAuxMaterials)
		}
		yield put({
			type: Types.SET_GET_MATERIAL_BY_ID,
			data: data.result.result
		});
		callback();
	}, Types.GET_MATERIAL_BY_ID);
}

function* updateAsyncMaterial(action) {
	yield executeAction(function* () {

		const { params, history, source } = action;

		const { data } = yield call(async () => await MaterialService.editar(params));
		if (data.isOk) {
			ToastHelper.success("Material atualizado com sucesso!")
			history.goBack()
			if (source) window.close();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.SET_UPDATE_MATERIAL,
			data
		});
	}, Types.UPDATE_MATERIAL);
}

function* takeGetMaterialsByFamily() {
	yield takeLatest([Types.GET_MATERIALS_BY_FAMILY], asyncListMaterialsByFamily);
}

function* takeListMaterials() {
	yield takeLatest(Types.GET_MATERIALS, asyncListMaterials);
}


function* takeCreateMaterial() {
	yield takeLatest(Types.CREATE_MATERIAL, asyncCreateMaterial);
}

function* takeDeleteMaterial() {
	yield takeLatest([Types.DELETE_MATERIAL], deleteMaterial);
}

function* takeUpdateOptionsAuxMaterials() {
	yield takeLatest([Types.UPDATE_OPTIONS_AUX_MATERIALS], updateOptionsAuxMaterials);
}

function* takeGetMaterialById() {
	yield takeLatest(Types.GET_MATERIAL_BY_ID, getMaterialById);
}

function* takeUpdateMaterial() {
	yield takeLatest(Types.UPDATE_MATERIAL, updateAsyncMaterial);
}

export default function* root() {
	yield all([fork(takeGetMaterialsByFamily)])
	yield all([fork(takeListMaterials)])
	yield all([fork(takeDeleteMaterial)])
	yield all([fork(takeUpdateOptionsAuxMaterials)])
	yield all([fork(takeCreateMaterial)])
	yield all([fork(takeGetMaterialById)])
	yield all([fork(takeUpdateMaterial)])
}
