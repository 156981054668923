import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import {bindActionCreators} from 'redux';
import { Creators as ModulosCreators } from './store/insumo_analise_solo.ducks';
import InsumoAnaliseSoloService from '../../services/insumoAnaliseSoloService';
import {connect} from 'react-redux';
import Title from '../../components/Title/title.component';
import AdvancedSearch from '../../components/AdvancedSearch/advancedSearch';
import Modal from '../../utils/modalHelper';
import Utils from '../../utils/utils';
import ToastHelper from "../../utils/toastHelper";
import ModalAtivaDesativa from '../../utils/modalHelper';

function InsumosAnaliseSoloListagem(props) {
	useEffect(() => { localStorage.removeItem('planejamentoParams'); }, []);

    const columns = [
        { id: 0, title: 'Grupo Serviço', property: 'servico.servicoTipo.nome' },
        { id: 1, title: 'Seviço', property: 'servico.nome' },
        { id: 2, title: 'Local', property: 'local.nome' },
        { id: 3, title: 'Insumo', property: 'materiais.nome' },
		{ id: 4, title: 'Quantidade Ha', property: 'quantidadeHa' },
        { id: 5, title: 'Quantidade Total', property: 'quantidadeTotal'},
        { id: 6, title: 'Ativo', property: 'isAtivo'}
    ];

    const filterBase = {
		page: 1, pageSize: 10, filtro: '', servicoTipoId: '', order: 'Local.Nome',
        servicoId: '', localId: '', descedescending: false,
        fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
        safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
    }

    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_LEITURA,
            action: item => visualizer(item),
        },
        {
			id: 2,
			name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_ATUALIZAR,
			action: item => edit(item),
        },
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
            permissao: PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_ATUALIZAR,
			action: item => handleModalAtivaDesativa(item),
		},
        {
			id: 4,
			name: 'Excluir',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_EXCLUIR,
			action: item => handleModalDelete(item),
		}
    ];

    const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
    const [deleteItem, setDeleteItem] = useState({id:null, showModal:false});
    const [orderColumn, setOrderColumn] = useState();
    const [descending, setDescending] = useState(true);
    const [idInsumoAtivaDesativa, setIdInsumoAtivaDesativa] = useState();
    const [showModalAtivaDesativa, setShowModalAtivaDesativa] = useState();

    const handleFilter = async (newFilter) => {
        newFilter.descedescending = false;
        newFilter.page = 1;
        props.getInsumoAnaliseSolo(newFilter)
    }

    const handleModalAtivaDesativa = (item) => {
        if(item) {
            setIdInsumoAtivaDesativa(item.id)
            setShowModalAtivaDesativa(!showModalAtivaDesativa)

        } else {
            setShowModalAtivaDesativa(!showModalAtivaDesativa)
        }
    }

    const ativaDesativa = async (item) => {
        let dados = await InsumoAnaliseSoloService.ativaDesativa(item);

		if(dados.status === 200) {
			ToastHelper.success("Status do insumo alterado com sucesso!");
			await props.getInsumoAnaliseSolo(filterBase)
			handleModalAtivaDesativa(null);
		} else {
			ToastHelper.error("Erro ao tentar alterar o status do insumo!");
		}
    }

    const edit = (item) => {
        props.history.push({
            pathname: `/planejamento/insumosAnaliseSolo/editar/${item.id}`
        });
    }

    const visualizer = (item) => {
        props.history.push({
            pathname: `/planejamento/insumosAnaliseSolo/visualizar/${item.id}`
        });
    }

    const novoInsumo = () => {
        props.history.push(`/planejamento/insumosAnaliseSolo/cadastro`);
    }

    const handleModalDelete = (item) => {
        if(item) {
            setDeleteItem({id:item.id, showModal: !deleteItem.showModal})
        } else {
            setDeleteItem({id: null, showModal: !deleteItem.showModal})
        }
	}

    useEffect( () => {
        if(localStorage.getItem('fazendaId')){
            props.getInsumoAnaliseSolo(filterBase)
        }
    }, []);

    useEffect(()=>{
		if(props.insumosAnaliseSolo) initPagination()
    })

	const initPagination = () => {
		setTotal(props.insumosAnaliseSolo.total);
		setPage(props.insumosAnaliseSolo.page);
    }

    const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = filterBase;
		newPagination.page = params;
        props.getInsumoAnaliseSolo(filterBase)
    }

    const deletar = async () => {
        await props.deleteInsumoAnaliseSolo(deleteItem.id, props)
        let newPagination = filterBase
        newPagination.filtro = ""
        await props.getInsumoAnaliseSolo(newPagination)
        setDeleteItem({showModal: false})
    }

    const orderTable = (property, descending) => {
        setOrderColumn(Utils.parsePropertToFilter(property));
        setDescending(descending);
        let params = filterBase;
        params.order = Utils.parsePropertToFilter(property);
        params.descedescending = descending;
        props.getInsumoAnaliseSolo(params)
    }

    return (
        <>
            <Title
                acao="Novo Insumo"
                permissaoBotao={PERMISSAO.MODULO_INSUMOS_ANALISE_SOLO_CRIAR}
                iconBotao="fas fa-plus"
                onClick={novoInsumo}>
                    Insumos - Análise de Solo
            </Title>
            <CardStyle className="card">
                <AdvancedSearch
                    handleFilter={(e) => handleFilter(e)}
                    material={true}
                    location={true}
                    serviceGroup={true}
                    service={true}
                />
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={props.insumosAnaliseSolo.items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
                    orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
                />
			</CardStyle>
            <Modal
                show={deleteItem.showModal}
                doubleButtons={true}
                title={"Excluir Insumo"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                submit={deletar}
                close={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                content={
                    <h6>{"Deseja excluir este insumo?"}</h6>
                }
            />
            <ModalAtivaDesativa
                show={showModalAtivaDesativa}
                title={'Ativar / Desativar Insumo'}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => handleModalAtivaDesativa(null)}
                submit={() => ativaDesativa(idInsumoAtivaDesativa)}
                close={() => handleModalAtivaDesativa(null)}
                content={
                    <h6>{'Deseja realmente alterar o status deste insumo?'}</h6>
                }
            />
        </>
    )
}

const mapStateToProps = state =>{
	return ({
        insumosAnaliseSolo: state.insumoAnaliseSolo.insumosAnaliseSolo
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(InsumosAnaliseSoloListagem)
