import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import fazendaService from 'services/fazendaService';
import ToastHelper from "../../utils/toastHelper";
import ModalAtivaDesativa from '../../utils/modalHelper';
import Utils from '../../utils/utils';

export default function FazendaListagem(props){

	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [idFazendaAtivaDesativa, setIdFazendaAtivaDesativa] = useState();
	const [showModalAtivaDesativa, setShowModalAtivaDesativa] = useState();
    const [orderColumn, setOrderColumn] = useState()
    const [descending, setDescending] = useState(true)
	
	const columns = [
		{ id: 0, title: 'Nome', property: 'nomeRazao' },
		{ id: 1, title: 'Cidade', property: 'cidade'},
		{ id: 2, title: 'Atividade Agricola', property: 'atividadeAgricola'},
        { id: 3, title: 'Ativo', property: 'isAtivo'}
	];
    
    const paginateParams = {
		page: currentPage,
        pageSize: 10,
        descedescending: false,
        order: 'NomeRazao',
        filtro:''
	}

    const [pagination, setPagination] = useState(paginateParams)

    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_FAZENDAS_CRIAR,
            action: item => visualizar(item),
        },
        {
			id: 2,
			name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_FAZENDAS_CRIAR,
			action: item => editar(item),
        },
		{
			id: 3,
			name: 'Ativa/Desat.',
			icon: 'fas fa-low-vision circ-item-desativar',
            permissao: PERMISSAO.MODULO_FAZENDAS_CRIAR,
			action: item => handleModalAtivaDesativa(item),
		}
	];

    const editar = (item) => {
        props.history.push({
            pathname: `/admin/fazendas/editar/${item.id}`
        });
    }

    const visualizar = (item) => {
        props.history.push({
            pathname: `/admin/fazendas/visualizar/${item.id}`
        });
    }

    const handleModalAtivaDesativa = (item) => {
        if(item) {
            setIdFazendaAtivaDesativa(item.id)
            setShowModalAtivaDesativa(!showModalAtivaDesativa)
            
        } else {
            setShowModalAtivaDesativa(!showModalAtivaDesativa)
        }
	}
	
	useEffect(() => {
        async function initialize() {
            getFazendas(pagination)
		}
		initialize();
    }, []);
    
    const getFazendas = (pag) => {
        async function getAllFazendas() {

            const { data } = await fazendaService.buscarTudo(pag)
            
            await data.result.items.forEach(item => {
                if(item.ativo){
                    item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
                } else {
                    item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
                }
            });

			if (data.isOk) {
				await setItems(data.result.items);
				await setTotal(data.result.total);
				await setPage(data.result.page);
			}
		}
		getAllFazendas();
    }

	const handleFilter = async (content) =>{
        let newPagination = pagination;
        newPagination.filtro = content.toLowerCase();
        newPagination.page = 1;
        setPagination(newPagination);
        getFazendas(newPagination)
	}

	const changePage = (params) => {
		setCurrentPage(params);
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination);
        getFazendas(newPagination)
    }

    const ativaDesativa = async (item) => {
		let dados = await fazendaService.ativaDesativa(item);

		if(dados.status === 200) {

			ToastHelper.success("Status do cliente alterado com sucesso!");
			getFazendas(pagination)
			handleModalAtivaDesativa(null);
		} else {

			ToastHelper.error("Erro ao tentar alterar o status do cliente!");
		}
    };
    
    const orderTable = (property, descending) => {
        setOrderColumn(Utils.parsePropertToFilter(property));
        setDescending(descending);
        let params = pagination;
        params.order = Utils.parsePropertToFilter(property);
        params.descedescending = descending;
        getFazendas(params)
    }

	return (
		<>
			<Title
                acao="Adicionar Cliente"
                permissaoBotao={PERMISSAO.MODULO_FAZENDAS_CRIAR}
                iconBotao="fas fa-plus"
                onClick={() => props.history.push(`/admin/fazendas/cadastro`)}>
                    Clientes
            </Title>
            <CardStyle className="card">
                <SimpleSearch
                    placeHolder="Pesquisar"
                    value={pagination.filtro}
                    submit={(e) => handleFilter(e)}
                />
				<br></br>
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
					orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
                />
                <ModalAtivaDesativa
                    show={showModalAtivaDesativa}
                    title={'Ativar / Desativar Cliente'}
                    buttonTitle={"Confirmar"}
                    button2Title={"Cancelar"}
                    buttom2Action={() => handleModalAtivaDesativa(null)}
                    submit={() => ativaDesativa(idFazendaAtivaDesativa)}
                    close={() => handleModalAtivaDesativa(null)}
                    content={
                        <h6>{'Deseja realmente alterar o status deste cliente?'}</h6>
                    }
                />
			</CardStyle>
		</>
	)
}
