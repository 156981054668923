import React, { useState, useEffect } from 'react';
import ObjectHelper from 'utils/objectHelper';
import { Creators as ModulosCreators } from './store/painel.servicos.duks'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CardStyle } from "../../components/CardStyle/cardstyle.component"
import Select from 'react-select';
import Title from '../../components/Title/title.component';
import { useForm } from 'react-hook-form';
import { Table } from 'reactstrap';
import "./painel.servicos.scss";
import painelService from 'services/painelService';
import servicoService from 'services/servicoService';
import grupoLocaisService from 'services/grupoLocaisService';
import Utils from 'utils/utils'
import StringHelper from 'utils/stringHelper'

function PainelServicos(props) {

	const paginateParams = {
		page: 10,
		pageSize: 10,
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : "",
		safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : ""
	}

	const [pagination, setPagination] = useState(paginateParams)
	const [columns, setColumns] = useState([])
	const [dadosPorServico, setDadosPorServico] = useState([])
	const { register, handleSubmit, setValue } = useForm();
	const [valor] = useState([]);
	const [opicoesServicos, setOpicoesServicos] = useState([])
	const [opcoesGruposLocais, setOpicoesGruposLocais] = useState([])



	useEffect(() => {

		async function initialize() {
			try {
				if (localStorage.getItem('fazendaId')) {
					const params = {
						fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : "",
						safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : "",
						page: 1,
						pageSize: 999999,
					}
					await props.getLocaisSafra()
					await props.getGrupoServicos()
					await getServicos(params)
					await getGrupoLocais(params)
					await props.getDadosServicesByGrupo(pagination)
				}
			} catch (error) {
			}
		}
		initialize();
	}, []);

	useEffect(() => {
		if (props.locais.length > 0) {
			let newColumns = []
			props.locais.forEach(item => {
				newColumns.push({
					dataField: item.nome.toLowerCase().trim().replace(/ /g, ''),
					text: item.nome
				})
			})
			setColumns(newColumns)
		}
	}, [props.locais]);

	const getServicos = async (params) => {
		const { data } = await servicoService.buscarTodas(params);
		if (data.isOk) {
			let servicos = await Utils.removeInactiveToSelect(ObjectHelper.getValuesParseToSelect(data.result.items))
			setOpicoesServicos(servicos)
		}
	}

	const getGrupoLocais = async (params) => {
		const { data } = await grupoLocaisService.getAll(params);
		if (data.isOk) {
			let grupoLocais = await Utils.removeInactiveToSelect(ObjectHelper.getValuesParseToSelect(data.result.items))
			setOpicoesGruposLocais(grupoLocais)
		}
	}

	const selectGrupoServico = async (value, e) => {
		let open = e.currentTarget.getAttribute("aria-expanded");
		await setDadosPorServico([])
		if (open === "true") {

			value.grupoLocaisId = pagination.grupoLocaisId !== undefined ? pagination.grupoLocaisId : undefined
			value.servicoId = pagination.servicoId !== undefined ? pagination.servicoId : undefined

			value.locais = pagination.locais;

			value.fazendaId = JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : "";
			value.safraId = JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : "";
			const { data } = await painelService.buscarDadosServicoPorServicoTipoId(value);
			if (data.isOk) {
				var newColumns = []
				let locais = data.result[0].locais
				await data.result.map(async item => {
					item.servicoNome = StringHelper.truncate(item.servicoNome, 26)
					await setStatusCodItem(item)
					item.locais.sort(function (a, b) {
						if (a.localNome > b.localNome) {
							return 1;
						}
						if (a.localNome < b.localNome) {
							return -1;
						}
						return 0;
					});
				})
				await locais.forEach(loc => {
					newColumns.push({
						dataField: loc.localNome.toLowerCase().trim().replace(/ /g, ''),
						text: loc.localNome
					})
				})
				await newColumns.sort(function (a, b) {
					if (a.localNome > b.localNome) {
						return 1;
					}
					if (a.localNome < b.localNome) {
						return -1;
					}
					return 0;
				});
				setColumns(newColumns)
				setDadosPorServico(data.result)
			}
		}
	}

	const setStatusCodItem = item => {
		item.locais.forEach(local => {
			switch (local.status) {
				case "ATRASADO":
					local.statusCod = 6
					break;
				case "INDEFINIDO":
					local.statusCod = 0
					break;
				case "CONCLUIDO":
					local.statusCod = 4
					break;
				case "CONCLUIDO_COM_ATRASO":
					local.statusCod = 5
					break;
				case "EM_ANDAMENTO":
					local.statusCod = 3
					break;
				case "CANCELADO":
					local.statusCod = 2
					break;
				case "SOLICITADO":
					local.statusCod = 1
					break;
				case "FUTURO":
					local.statusCod = 8
					break;
				case "REALIZADO_SEM_PLANEJAMENTO":
					local.statusCod = 7
					break;
				default:
					local.statusCod = 0
			}
		})
	}

	const submitForm = (values) => {
		let filtro = values
		filtro.page = 1;
		filtro.pageSize = 99999;
		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : "";
		filtro.safraId = JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : "";

		var locais = []
		if (values.locais !== undefined) {
			values.locais.forEach(item => {
				locais.push(item.value)
			});
			filtro.locais = locais;
		}
		setPagination(filtro)
		props.getDadosServicesByGrupo(filtro)
	}

	const legenda = <>
		<div className="col-md-12 flex ml-1 mb-3">
			<span style={{ fontSize: 10, alignSelf: "center" }}> Área total:</span>
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Concluida.</span><div className="grupo-concluido mr-2  numero-legenda">4</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Concluida sem planejamento.</span><div className="grupo-realizado-sem-planejamento mr-2 numero-legenda fonte-branca">7</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Concluida com atraso.</span><div className="grupo-concluido-com-atraso mr-2  numero-legenda">5</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Em andamento.</span><div className="grupo-em-andamento mr-2 numero-legenda">3</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Cancelada.</span><div className="grupo-cancelado mr-2  numero-legenda">2</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Solicitada.</span><div className="grupo-solicitado mr-2  numero-legenda">1</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Futura.</span><div className="grupo-futuro mr-2 numero-legenda">8</div>|
			<span className="ml-2" style={{ fontSize: 10, alignSelf: "center" }}> Atrasada.</span><div className="grupo-atrasado  numero-legenda">6</div>
		</div>
	</>

	return (
		<>
			<Title>
				Painel Serviços
            </Title>
			<CardStyle className="card">
				<div className="">
					<div className="mb-1 ml-3 card-header">
						<strong >
							Filtro
								</strong>
					</div>
					<form onSubmit={handleSubmit(submitForm)}>
						<div className="row col-md-12 flex">
							<div className="col-md-6">
								<label className="label" htmlFor="localId">
									Fazenda:
									</label>
								<Select
									id="grupoLocaisId"
									name="grupoLocaisId"
									placeholder='Selecionar'
									isClearable={true}
									className="select-style ajust-label-padrao disabled-select"
									onChange={item => {
										if (item)
											setValue('grupoLocaisId', item ? item.value : undefined);
									}}
									ref={() => register({ name: "grupoLocaisId" })}
									options={opcoesGruposLocais}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									value={valor.grupoLocaisId}
								/>
							</div>
							<div className="col-md-6">
								<label className="label" htmlFor="localId">
									Serviço :
									</label>
								<Select
									id="servicoId"
									name="servicoId"
									placeholder='Selecionar'
									isClearable={true}
									className="select-style ajust-label-padrao disabled-select"
									onChange={item => {
										setValue('servicoId', item ? item.value : undefined);
									}}
									ref={() => register({ name: "servicoId" })}
									options={opicoesServicos}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									value={valor.servicoId}
								/>
							</div>
						</div>
						<div className="row col-md-12 flex mt-3">
							<div className="col-md-11">
								<label className="label" htmlFor="localId">
									Locais :
									</label>
								<Select
									id="locais"
									name="locais"
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={item => {
										setValue('locais', item ? item : undefined);
									}}
									ref={() => register({ name: "locais" })}
									options={ObjectHelper.getValuesLocaisParseToSelect(props.locais)}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									value={valor.locais}
									isMulti={true}
								/>
							</div>
							<div className="col-md-1  botao-buscar text-center">
								<button className="btn btn-primary active" type="submit" onClick={() => setDadosPorServico([])}>
									<i className='fas fa-search' />
								</button>
							</div>
						</div>
					</form>
					{legenda}
					<div className="row col-md-12 ml-1 mb-3">
						{
							props.dadosPorGrupo.result !== undefined && props.dadosPorGrupo.result.length > 0 &&
							<Table bordered className="mb-0 table-fixed">
								<thead>
									<tr>
										<th className="text-center" style={{ width: 40, minWidth: 40, maxWidth: 40 }}>#</th>
										<th className="text-center" style={{ width: 290, minWidth: 290, maxWidth: 290 }}>Serviços por grupo</th>
										<th className="text-center" >Percentual(%) da área total</th>
									</tr>
								</thead>
								<tbody id="accordion">
									{
										props.dadosPorGrupo.result !== undefined && props.dadosPorGrupo.result.map((row, index) => {
											return <>
												<tr id={"#" + index.toString()} data-toggle="collapse" data-target={"#" + index.toString()} onClick={(e) => selectGrupoServico(row, e)} aria-expanded="false" aria-controls="collapseOne" className="accordion-toggle collapse-row clickable accordion-icons collapsed">
													<td className="align" style={{ width: 40, minWidth: 40, maxWidth: 40 }}><i className="fas fa-chevron-down"> </i></td>
													<td className="align" style={{ width: 290, minWidth: 290, maxWidth: 290 }} >{row.grupoServicoNome}</td>
													<td className="">
														<div className="box-porcent">{Math.round(row.concluido) + "% "} <div className="grupo-concluido mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.realizadoSemPlanejamento) + "% "} <div className="grupo-realizado-sem-planejamento mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.concluidoComAtraso) + "% "} <div className="grupo-concluido-com-atraso mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.emAndamento) + "% "} <div className="grupo-em-andamento mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.cancelado) + "% "} <div className="grupo-cancelado mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.solicitado) + "% "} <div className="grupo-solicitado mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.futuro) + "% "} <div className="grupo-futuro mr-4"></div> |</div>
														<div className="box-porcent" style={{ marginLeft: 20 }}>{Math.round(row.atrasado) + "% "} <div className="grupo-atrasado"></div></div>
													</td>
												</tr>
												<tr className="tr-colap ">
													<td colSpan="3" className="td-colspan" id={index.toString()} data-parent="#accordion"  >
														{
															dadosPorServico.length > 0 &&
															<div className="flex">
																<div>
																	<Table bordered className="m-0 p-0">
																		<thead>
																			<tr>
																				<th className="text-center" style={{ width: 40, minWidth: 40, maxWidth: 40 }}>#</th>
																				<th className="text-center" style={{ width: 290, minWidth: 290, maxWidth: 290 }}>Serviços</th>
																			</tr>
																		</thead>
																		<tbody className="m-0 p-0 tbody-font">
																			{
																				dadosPorServico.map((subRow, index2) => {
																					return <>
																						{
																							<tr>
																								<td className="align" >
																									{
																										subRow.statusDeAlerta === 6 ? <i className="fas fa-minus-circle" style={{ color: "#E5505E" }}></i> :
																											subRow.statusDeAlerta === 5 ? <i className="fas fa-exclamation-circle" style={{ color: "#f3f382" }}></i> :
																												subRow.statusDeAlerta === 4 ? <i className="fas fa-check-circle" style={{ color: "#b0dab0" }}></i> :
																													subRow.statusDeAlerta === 3 ? <i className="fas fa-check-circle" style={{ color: "#c8d1ff" }}></i> :
																														subRow.statusDeAlerta === 2 ? <i className="fas fa-check-circle" style={{ color: "rgb(216, 210, 210)" }}></i> :
																															subRow.statusDeAlerta === 0 ? <i className="fas fa-check-circle" style={{ color: "rgb(216, 210, 210)" }}></i> :
																																subRow.statusDeAlerta === 1 ? <i className="fas fa-check-circle" style={{ color: "#a8f1ee" }}></i> :
																																	subRow.statusDeAlerta === 8 ? <i className="fas fa-check-circle" style={{ color: "#b9f8f8" }}></i> :
																																		subRow.statusDeAlerta === 7 ? <i className="fas fa-check-circle" style={{ color: "#0d583f" }}></i> :
																																			<i className="fas fa-check-circle" style={{ color: "#E5505E" }}></i>
																									}
																								</td>
																								<td className="align">{subRow.servicoNome}</td>
																							</tr>
																						}
																					</>
																				})
																			}
																		</tbody>
																	</Table>
																</div>
																<div className="table-scroll">
																	<Table className="mb-0 pb-0">
																		<thead className={"nowrap"}>
																			<tr>
																				{
																					columns.map(item =>
																						<th ><div>{item.text}</div></th>
																					)
																				}
																			</tr>
																		</thead>
																		<tbody>
																			{
																				dadosPorServico.map((subRow, index2) => {
																					return <>
																						<tr>
																							{
																								subRow.locais.map((itemSubRow) => {
																									return <>
																										{
																											itemSubRow.statusCod !== 0 &&
																											<td

																												className={
																													itemSubRow.statusCod === 6 ? "atrasado" :
																														itemSubRow.statusCod === 5 ? "concluido-com-atraso" :
																															itemSubRow.statusCod === 4 ? "concluido" :
																																itemSubRow.statusCod === 3 ? "em-andamento" :
																																	itemSubRow.statusCod === 0 ? undefined :
																																		itemSubRow.statusCod === 1 ? "solicitado" :
																																			itemSubRow.statusCod === 8 ? "futuro" :
																																				itemSubRow.statusCod === 7 ? "realizado-sem-planejamento" :
																																					"cancelado"
																												}
																											>

																												{itemSubRow.statusCod}
																											</td>
																										}
																										{
																											itemSubRow.statusCod === 0 &&
																											<td

																											>
																											</td>
																										}
																									</>
																								})
																							}
																						</tr>
																					</>
																				})
																			}
																		</tbody>
																	</Table>
																</div>
															</div>
														}
													</td>
												</tr>
											</>
										})
									}
									<tr className="col-md-12"></tr>
								</tbody>
							</Table>
						}
					</div>
				</div>

			</CardStyle>
		</>
	)



}

const mapStateToProps = state => {
	return ({
		...state,
		dadosPorGrupo: state.painelServicos.dadosPorGrupo ? state.painelServicos.dadosPorGrupo : [],
		locais: state.painelServicos.locais ? state.painelServicos.locais : [],
		grupoServicos: state.painelServicos.grupoServicos,
	})

};


const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PainelServicos)
