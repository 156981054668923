import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import {bindActionCreators} from 'redux';
import { Creators as ModulosCreators } from './store/turno.ducks';
import {connect} from 'react-redux';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Modal from '../../utils/modalHelper';

function TurnosListagem(props) {

    const columns = [
		{ id: 1, title: 'Nome', property: 'nome'},
		{ id: 2, title: 'Descrição', property: 'descricao'},
		{ id: 3, title: 'Horas', property: 'horas'}
    ];

    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_TURNO_LEITURA,
            action: item => visualizar(item),
        },
        {
			id: 2,
			name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_TURNO_ATUALIZAR,
			action: item => editar(item),
        },
        {
			id: 3,
			name: 'Apagar',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_TURNO_EXCLUIR,
			action: item => handleModalExcluir(item),
		}
    ];

    const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [excluirItem, setExcluirItem] = useState({id:null, showModal:false})
    
    
    const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: currentPage,
        pageSize: 10,
        filtro:''
    }

    const filtrar = async (content) =>{
        
        let newPagination = pagination;
        newPagination.filtro = content.toLowerCase();;
		setPagination(newPagination)
        props.buscarTurnos(pagination)

    }

    const editar = (item) => {
        props.history.push({
            pathname: `/turnos/editar/${item.id}`
        });
    }

    const visualizar = (item) => {
        props.history.push({
            pathname: `/turnos/visualizar/${item.id}`
        });
    }
    
    const cadastrarRota = () => {
        props.history.push(`/turnos/cadastro`);
    }

    const handleModalExcluir = (item) => {
        if(item) {
            setExcluirItem({id:item.id, showModal: !excluirItem.showModal})
        } else {
            setExcluirItem({id: null, showModal: !excluirItem.showModal})
        }
	}
    
    const [pagination, setPagination] = useState(paginateParams)
    
    useEffect( () => {
        if(localStorage.getItem('fazendaId')){
            props.buscarTurnos(pagination)
        }
    }, []);
    
    useEffect(()=>{
		if(props.turnos) initPagination()
    })

	const initPagination = () => {
		setItems(props.turnos.items);
		setTotal(props.turnos.total);
		setPage(props.turnos.page);
    }

    const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
        props.buscarTurnos(pagination)
    }
    
    const apagarTurno = async () => {
        await props.deleteTurno(excluirItem.id)
        setTimeout(function(){
            props.buscarTurnos(pagination)
        }, 800)
        setExcluirItem({showModal: false})
    }
    
    return (
        <>  
            <Title 
                acao="Novo turno"
                permissaoBotao={PERMISSAO.MODULO_TURNO_CRIAR}
                iconBotao="fas fa-plus"
                onClick={cadastrarRota}>
                    Turnos
            </Title>
            <CardStyle className="card">
                <SimpleSearch 
                    placeHolder="Pesquisar"
                    value={pagination.filtro}
                    submit={(e) => filtrar(e)}
                    
                />
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
                />
			</CardStyle>
            <Modal
                show={excluirItem.showModal}
                doubleButtons={true}
                title={"Excluir Turno"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => setExcluirItem({id: excluirItem.id, showModal: false})}
                submit={apagarTurno}
                close={() => setExcluirItem({id: excluirItem.id, showModal: false})}
                content={
                    <h6>{"Deseja realmente excluir esse turno?"}</h6>
                }
            />
        </>
    )
}

const mapStateToProps = state =>{

	return ({
        turnos: state.turnos.itens
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TurnosListagem)