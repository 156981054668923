import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	getTemplates: ['params', 'callback'],
	setTemplates: ['templates'],

	getCalendarios: ['params', 'callback'],
	setCalendarios: ['calendarios'],

	// getPlanejamentos: ['params', 'callback'],
	// setPlanejamentos: ['planejamentos'],

	getLocaisSafra: ['params', 'callback'],
	setLocaisSafra: ['locais'],

	createGerador: ['params', 'history', 'callback'],
	postGerador: ['params'],


	getSafra: ['params', 'callback'],
	setSafra: ['safra']

})

const INITIAL_STATE_VALUES = {
	templates: [],
	calendarios: [],
	locais:[],
	safras: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getTemplates = (state = INITIAL_STATE, action) => ({ ...state, templates: action.data })
const getCalendarios = (state = INITIAL_STATE, action) => ({ ...state, calendarios: action.data })
const getLocaisSafra = (state = INITIAL_STATE, action) => ({ ...state, locais: action.data })
const getSafras = (state = INITIAL_STATE, action) => ({ ...state, safras: action.data })
const createGerador = (state = INITIAL_STATE, action) => ({...state, gerador: action.data})

export default createReducer(INITIAL_STATE_VALUES, {
	[Types.SET_TEMPLATES]: getTemplates,
	[Types.SET_CALENDARIOS]: getCalendarios,
	[Types.SET_LOCAIS_SAFRA]: getLocaisSafra,
	[Types.POST_GERADOR]: createGerador,
	[Types.SET_SAFRA]: getSafras,
})
