import api from './api';

export default {
	salvar(apontamentoMaquina){
		return api.post(`/api/ApontamentoMaquina/Cadastrar`, apontamentoMaquina);
	},
	findAll(apontamentoMaquina){
		return api.post(`/api/ApontamentoMaquina/BuscarTodos`, apontamentoMaquina);
	},
	buscarPorId(apontamentoMaquinaId){
		return api.get(`/api/ApontamentoMaquina/${apontamentoMaquinaId}`);
	},
	editar(apontamentoMaquinaNovo){
		return api.put(`/api/ApontamentoMaquina`, apontamentoMaquinaNovo);
	},
	delete(apontamentoMaquinaId){
		return api.delete(`/api/ApontamentoMaquina/${apontamentoMaquinaId}`);
	},
	somatorioHorasPeloDiaAtual(fazenda, safra){
		return api.get(`/api/ApontamentoMaquina/SomatorioHorasPeloDiaAtual/${fazenda}/${safra}`);
	},
	somatorioHorasPorSemana(fazenda, safra){
		return api.get(`/api/ApontamentoMaquina/SomatorioHorasPorSemanaAtual/${fazenda}/${safra}`);
	},
	somatorioHorasPeloMes(fazenda, safra){
		return api.get(`/api/ApontamentoMaquina/SomatorioHorasPorMesAtual/${fazenda}/${safra}`);
	},
	somatorioPorPeriodo(parametro){
		return api.post('/api/ApontamentoMaquina/SomatorioHorasPorPeriodo', parametro);
	},
	buscarServicosApontamentoInteligente(params){
		return api.post(`/api/ApontamentoMaquina/BuscarServicosApontamentoInteligente`, params);
	},
	buscarParaAjustes(params){
		return api.post(`/api/ApontamentoMaquina/BuscarParaAjustes`, params);
	},
	atualizaListaApontamentos(params){
		return api.put(`/api/ApontamentoMaquina/AtualizaApontamentos`, params);
	},
	excluirSelecionados(params){
		return api.put(`/api/ApontamentoMaquina/ExcluirSelecionados`, params);
	},
	editarSelecionados(params){
		return api.put(`/api/ApontamentoMaquina/EditarSelecionados`, params);
	}
};
