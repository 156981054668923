import React, { useState, useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Title from "components/Title/title.component";
import { CardStyle } from "components/CardStyle/cardstyle.component";
import { Creators as ModulosCreators } from "./store/mo.ducks";
import Button from "react-bootstrap/es/Button";
import { useForm } from "react-hook-form";
import Head from "./body/register.head";
import ModalMembros from "pages/modais/modalMembros";
import Modal from "utils/modalHelper";
import ReactTooltip from "react-tooltip";
import ObjectHelper from "utils/objectHelper";
import Utils from "utils/utils";
import apontamentoMaquinaService from "services/apontamentoMaquinaService";
import Select, { components } from "react-select";
import servicoService from 'services/servicoService'
import ToastHelper from 'utils/toastHelper';
import apontamentoColaboradorService from "services/apontamentoColaboradorService";
import servicoTipoService from "services/grupoServicoService";
import safraService from "services/safraService";
import pessoaService from 'services/pessoaService'
import turnoService from 'services/turnoService';

function ManpowerRegister(props) {
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [visualize, setVisualize] = useState(false);
	const [form, setForm] = useState({ date: new Date() });
	const [labelSelectMembers, setLabelSelectMembers] = useState(
		"Selecione os membros"
	);
	const [membersOfTeam, setMembersOfTeam] = useState([]);
	const [showModalMembers, setShowModalMembers] = useState();
	const [selectedMembers, setSelectedMembers] = useState([]);

	const [supervisores, setSupervisores] = useState();
	const [members, setMembers] = useState([]);
	const [errorMembers, setErrorMembers] = useState("");
	const { match: { params } } = props;
	const [optionServicos, setOptionServicos] = useState([]);
	const [servicosTipo, setServicosTipo] = useState([]);
	const [turnos, setTurnos] = useState([]);

	const buscarTurnos = async () => {
		const { data } = await turnoService.buscarTudo(JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null);
		let turnos = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setTurnos(turnos)
	}

	async function buscarServicosTipo() {
		const { data } = await servicoTipoService.buscarTodos({
			fazendaId:
				JSON.parse(localStorage.getItem("fazendaId")) !== null
					? JSON.parse(localStorage.getItem("fazendaId")).value
					: null,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});
		let servicosTipo = ObjectHelper.getValuesParseToSelect(data.result.items)

		setServicosTipo(servicosTipo);

	}



	const getPessoas = async () => {
		const { data } = await pessoaService.buscar({
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
			page: 1,
			pageSize: 1000,
			order: "Nome"
		});
		let supervisores = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setSupervisores(supervisores)
	}

	useEffect(() => {
		props.getTeams();
		getPessoas();
		buscarServicosTipo();
		buscarTurnos();


	}, []);

	useEffect(() => {
		if (params.modo === "visualizar") setVisualize(true);
		if (params.id !== undefined) {
			props.getManpowerAppointmentById(params.id);
		}
	}, []);

	useEffect(() => {
		if (params.id !== undefined && props.appointment) {
			let novaData = null;
			if (props.appointment.data) {
				let string = props.appointment.data.split(" ")[0];
				var dia = string.split("/")[0];
				var mes = string.split("/")[1];
				var ano = string.split("/")[2];
				novaData = new Date(`${ano}-${mes}-${dia}T00:00:00-03:00`);
			}
			let valuesToForm = {
				id: props.appointment.id,
				date: novaData ? new Date(novaData) : new Date(),
				team: {
					value: props.appointment.equipe.id,
					label: props.appointment.equipe.nome
				},
				description: props.appointment.descricao,
				serviceGroup: {
					value: props.appointment.servicoTipoId,
					label: props.appointment.servicoTipoNome
				},
				service: {
					value: props.appointment.servicoId,
					label: props.appointment.servicoNome
				},
				workPlace: {
					value: props.appointment.localId,
					label: props.appointment.areaNome
				},
				workShift: {
					value: props.appointment.turnoId,
					label: props.appointment.tunoNome
				},
				person: {
					value: props.appointment.pessoaId,
					label: props.appointment.pessoaNome
				},
				supervisor: {
					value: props.appointment.supervisorId,
					label: props.appointment.supervisorNome
				},
				safra: props.appointment.safra,
				hours: Utils.formatHour(props.appointment.horas)
			};
			buscarLocais(props.appointment.safra);
			setForm(valuesToForm);

			getServiceByIdGroup({
				farmId: JSON.parse(localStorage.getItem("fazendaId")).value,
				groupServiceId: props.appointment.servicoTipoId
			});
		}
	}, [props.appointment]);

	useEffect(() => {
		if (params.id !== undefined && props.appointment) {
			console.log("aqui");
			let novaData = undefined;
			if (props.appointment.data) {
				let dat = props.appointment.data.split(" ")[0];
				dat = dat.split("/");
				novaData = new Date(dat[2], dat[1] - 1, dat[0]);
			}
			setValue("id", props.appointment.id);
			setValue("date", novaData);
			setValue("team", {
				value: props.appointment.equipe.id,
				label: props.appointment.equipe.nome
			});
			setValue("description", props.appointment.descricao);
			setValue("service", {
				value: props.appointment.servicoId,
				label: props.appointment.servicoNome
			});
			setValue("safra", props.appointment.safra);
			setValue("serviceGroup", {
				value: props.appointment.servicoTipoId,
				label: props.appointment.servicoTipoNome
			});
			setValue("workPlace", {
				value: props.appointment.localId,
				label: props.appointment.areaNome
			});
			setValue("workShift", {
				value: props.appointment.turnoId,
				label: props.appointment.tunoNome
			});
			setValue("person", {
				value: props.appointment.pessoaId,
				label: props.appointment.pessoaNome
			});
			setValue("supervisor", {
				value: props.appointment.supervisorId,
				label: props.appointment.supervisorNome
			});
			setValue("hours", Utils.formatHour(props.appointment.horas));
		}
	}, [form.date]);

	useEffect(() => {
		if (form.team) {
			props.getMembersTeam(form.team.value, form.date);
			if (form.team.supervisor) {
				setValue("supervisor", {
					value: form.team.supervisor.id,
					label: form.team.supervisor.nome
				});
				setForm({
					...form,
					supervisor: {
						value: form.team.supervisor.id,
						label: form.team.supervisor.nome
					}
				});
			}
		}
	}, [form.team]);

	useEffect(() => {
		if (props.membersTeam) {
			setMembersOfTeam(props.membersTeam);
		}
	}, [props.membersTeam]);

	const [servicosSugeridos, setServicosSugeridos] = useState([]);
	const getServiceByIdGroup = async (grupoServicoId) => {
		let { data } = await servicoService.buscarPorGrupoId(
			JSON.parse(localStorage.getItem("fazendaId")).value,
			grupoServicoId
		);
		delete form["service"];
		atualizarServicosSugeridos(grupoServicoId, data.result)
	}

	const handleModal = useCallback(
		async modal => {
			if (modal === "members") {
				setShowModalMembers(!showModalMembers);
			}
		},
		[setShowModalMembers, showModalMembers]
	);

	const selectAndSubmitModalMembers = useCallback(
		async members => {
			membersOfTeam.forEach(item => {
				members.forEach(member => {
					if (item.value === member.value) {
						if (member.isSelect) {
							member.isSelect = true;
						} else {
							member.isSelect = false;
						}
					}
				});
			});
			if (members) {
				setMembers(members);
			}
			handleSelectedMembers();
		},
		[form, setMembers, membersOfTeam]
	);

	const handleSelectedMembers = useCallback(async () => {
		let lineSelecteds = [];
		await membersOfTeam.forEach(member => {
			if (member.isSelect) {
				lineSelecteds.push(member.value);
			}
		});
		setSelectedMembers(lineSelecteds);
		handleLabelSelectedMembers();
	}, [form, membersOfTeam, membersOfTeam, setSelectedMembers]);

	const handleLabelSelectedMembers = useCallback(async () => {
		if (props.membersTeam.length > 0) {
			let contMembros = 0;
			let contSelecteds = 0;
			let label = "Membros da equipe";

			membersOfTeam.forEach(member => {
				if (member.isSelect) {
					contSelecteds = contSelecteds + 1;
				}
				contMembros = contMembros + 1;
			});
			if (contSelecteds > 1) {
				label =
					contSelecteds +
					" selecionados de " +
					contMembros +
					" membros";
			} else if (contSelecteds === 1) {
				label =
					contSelecteds +
					" selecionado de " +
					contMembros +
					" membros";
			}
			setLabelSelectMembers(label);
		}
	}, [form, membersOfTeam, setLabelSelectMembers]);


	const validateMembers = useCallback(() => {
		handleSelectedMembers();
		if (selectedMembers.length === 0) {
			setErrorMembers("Selecione ao menos um funcionário");
		} else {
			setErrorMembers("");
		}
	}, [selectedMembers, membersOfTeam, setErrorMembers, members]);

	const submitForm = async (voltar) => {
		if (form.workPlace === undefined || form.service === undefined) return;
		if (typeof (voltar) === "object") return false;
		if (form.date > new Date()) {
			ToastHelper.error("A 'Data' do apontamento não pode ser uma data futura!");
			return false;
		}

		let lineSelecteds = [];
		members.forEach(member => {
			if (member.isSelect) {
				lineSelecteds.push(member);
			}
		});


		let locaisSafra = [];
		if (params.id) {
			locaisSafra.push(form.workPlace.value)
		} else {
			form.workPlace.forEach(item => {
				locaisSafra.push(item.value)
			})
		}

		if (params.id === undefined) {
			if (lineSelecteds.length > 0) {
				let apontamentos = [];
				lineSelecteds.forEach(item => {
					locaisSafra.forEach(local => {

						let apontamento = {
							id: form.id ? form.id : undefined,
							horas: form.hours,
							descricao: form.description,
							pessoaNome: item.label,
							data: form.date,
							servicoId: form.service.value,
							apontamentoTipoId:
								"2a630e6e-e396-47f1-90f4-b192c49c830c",
							fazendaId: JSON.parse(
								localStorage.getItem("fazendaId")
							).value,
							pessoaId: item.value,
							turnoId: form.workShift.value,
							equipeId: form.team.value,
							areaId: local,
							safraId: form.safra.value !== undefined ? form.safra.value : ObjectHelper.primeiraSafra(),
							servicoTipoId: form.serviceGroup.value,
							supervisorId: form.supervisor.value
						};
						apontamentos.push(apontamento);
					})
				});

				const { data } = await apontamentoColaboradorService.salvar({ apontamentos: apontamentos, locaisSafraId: locaisSafra });
				if (data.isOk) {
					ToastHelper.success("Apontamento criado com sucesso!")
					if (!voltar) {
						salvarSemVoltar(data.result)
						window.scrollTo(0, 0);
					} else {
						props.history.goBack();
					}
				} else {
					ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.")
				}
			} else {
				setErrorMembers("Selecione ao menos um funcionário");
			}
		} else {
			if (params.id !== undefined) {
				let apontamento = {
					hectares: 0,
					id: params.id,
					horas: form.hours,
					descricao: form.description,
					pessoaNome: form.person.label,
					data: form.date,
					servicoId: form.service.value,
					apontamentoTipoId: "2a630e6e-e396-47f1-90f4-b192c49c830c",
					fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
					pessoaId: form.person.value,
					turnoId: form.workShift.value,
					equipeId: form.team.value,
					safraId: ObjectHelper.primeiraSafra(),
					servicoTipoId: form.serviceGroup.value,
					supervisorId: form.supervisor.value,
					areaId: form.workPlace.value
				};
				props.updateManpowerAppointment(apontamento, props.history);
			} else {
			}
		}
	}

	const salvarSemVoltar = data => {
		setValue('workPlace', undefined);
		setForm(
			{
				...form,
				workPlace: null,
			}
		)
	}

	const [locais, setLocais] = useState([]);

	useEffect(() => {
		buscarLocais();
	}, [form.team])

	const buscarLocais = async () => {

		const { data } = await safraService.locais(
			JSON.parse(localStorage.getItem('fazendaId')).value,
			form.safra ? form.safra.value : ObjectHelper.primeiraSafra(),
			true)

		if (form.team && form.team.grupoLocal) {
			var sugeridos = [
				{
					label: form.team.grupoLocal,
					options: ObjectHelper.getValuesParseToSelect(data.result.filter(item => item.grupoLocaisId === form.team.grupoLocalId))
				},
				{
					label: "Todos",
					options: ObjectHelper.getValuesParseToSelect(data.result)
				}
			]

			setLocais(sugeridos);
		} else {
			setLocais(ObjectHelper.getValuesParseToSelect(data.result));
		}
	}

	const [grupoServicos, setGrupoServicos] = useState([]);
	const apontamentoInteligente = async (params) => {
		if (params === null) return
		var filtro = {}
		filtro.safraId = form.safra.value !== undefined ? form.safra.value : ObjectHelper.primeiraSafra();
		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		var locais = [];
		params.forEach(item => {
			locais.push(item.value)
		})
		filtro.locaisId = locais;

		const { data } = await apontamentoMaquinaService.buscarServicosApontamentoInteligente(filtro);

		setGrupoServicos(data.result.grupoServicos);
		setServicosSugeridos(data.result.servicos);
	}



	const CustomGroupHeading = props => {
		return (
			<div
				className="group-heading-wrapper"
				onClick={() => handleHeaderClick(props.id)}
			>
				<components.GroupHeading {...props} />
			</div>
		);
	};

	const formatGroupLabel = data => (
		<div className="groupStyles">
			<span>{data.label}</span>
			<span className="groupBadgeStyles">{data.options.length}</span>
		</div>
	);

	const handleHeaderClick = id => {
		const node = document.querySelector(`#${id}`).parentElement
			.nextElementSibling;
		const classes = node.classList;
		if (classes.contains("grupoRecursosCollapsed")) {
			node.classList.remove("grupoRecursosCollapsed");
		} else {
			node.classList.add("grupoRecursosCollapsed");
		}
	};

	const groupedStyles = {
		menuPortal: base => ({ ...base, zIndex: 9999, width: "250px" }),
		groupHeading: provided => ({
			...provided,
			textTransform: "none",
			fontSize: 16,
			color: "black"
		}),
		option: provided => ({
			...provided
		})
	};

	const atualizarServicosSugeridos = (servicoTipoId, listaServicos) => {
		if (servicoTipoId === null) return
		var servicosSugeridosFiltrados = [];

		servicosSugeridos.forEach(item => {
			if (item.tipo === servicoTipoId)
				servicosSugeridosFiltrados.push(item);
		});

		let listaServicosFiltrados = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(listaServicos),
			"label"
		);

		if (servicosSugeridosFiltrados.length > 0) {
			var obj = [{
				label: "Serviços Sugeridos",
				options: servicosSugeridosFiltrados
			},
			{
				label: "Todos",
				options: listaServicosFiltrados
			}]
			setOptionServicos(obj)
		} else {
			setOptionServicos(listaServicosFiltrados)
		}
	}

	console.log(form);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="row">
					<div className="col">
						<Title>
							{visualize
								? "Visualizar "
								: params.id
									? "Editar "
									: "Cadastrar "}{" "}
							Apontamento de Mão de Obra
						</Title>
					</div>
				</div>
				<Head
					disabled={false}
					teamOptions={props.teams}
					team={form.team}
					setTeam={e => setForm({ ...form, team: e })}
					date={form.date}
					setDate={e => setForm({ ...form, date: e, team: null, safra: null })}
					setForm={setForm}
					form={form}
					setSafras={(e) => setForm({ ...form, safra: e })}
					visualize={visualize || params.id !== undefined}
					edit={false}
				/>
				{form.team && form.date && (
					<CardStyle className="card mt-0">
						<div className="card-header">Dados do Apontamento</div>
						<div className="card-body">
							<section id="tabs" className="project-tab">
								<div className="row">
									<div className="col-md-12">
										<ul
											className="nav nav-tabs"
											role="tablist"
										>
											<li className="nav-item">
												<a
													className="nav-item nav-link active show"
													id="nav-servicos-tab"
													href="#servicos"
													role="tab"
													data-toggle="tab"
													aria-controls="nav-servicos"
													aria-selected="true"
												>
													Serviço
												</a>
											</li>
											<li className="nav-item">
												<a
													className="nav-item nav-link disabled"
													id="nav-perda-tab"
													href="#perda"
													role="tab"
													data-toggle="tab"
												>
													Perda
												</a>
											</li>
										</ul>

										<div
											className="tab-pane fade show active  "
											id="servicos"
											role="tabpanel"
										>
											<div className="col-md-12 flex">
												{(visualize ||
													params.id !==
													undefined) && (
														<div className="card-header col-md-6 display-block">
															<label className="label">
																Funcionário
															</label>
															<input
																className="form-control disabled-input"
																type="text"
																disabled
																value={
																	form.person
																		? form
																			.person
																			.label
																		: ""
																}
															></input>
														</div>
													)}
												{!visualize &&
													params.id ===
													undefined && (
														<div className="card-header col-md-6 display-block">
															<label className="label">
																Membros à
																apontar:*
																</label>
															<div
																data-tip={
																	"Clique no icone para pesquisar os membros da equipe"
																}
																className="col-md-12 flex padding-input-modal"
															>
																<input
																	placeholder={
																		labelSelectMembers
																	}
																	type="text"
																	name="members"
																	disabled
																	htmlFor="members"
																	autoComplete="off"
																	className="form-control  disabled-input"
																/>
																<Button
																	className="button-search-new"
																	variant="success"
																	onClick={() =>
																		handleModal(
																			"members"
																		)
																	}
																>
																	<i
																		className="fas fa-search text-secondary "
																		aria-hidden="true"
																	></i>
																</Button>
																<Modal
																	show={
																		showModalMembers
																	}
																	title={
																		"Membros da equipe"
																	}
																	buttonTitle={
																		"Pronto"
																	}
																	submit={() => {
																		handleModal(
																			"members"
																		);
																		validateMembers();
																	}}
																	close={() => {
																		handleModal(
																			"members"
																		);
																		validateMembers();
																	}}
																	content={
																		<ModalMembros
																			membrosEquipe={
																				props.membersTeam
																			}
																			selectAndSubmitModalMembros={e =>
																				selectAndSubmitModalMembers(
																					e
																				)
																			}
																			membrosSelecionados={
																				selectedMembers
																			}
																			mostrarHorasApontadas={true}
																		/>
																	}
																/>
															</div>
															<ReactTooltip
																place="top"
																type="dark"
																delayShow={
																	400
																}
																effect="float"
															/>
															{errorMembers && (
																<span className="span-errors">
																	{
																		errorMembers
																	}
																</span>
															)}
														</div>
													)}
												<div className="card-header col-md-6">
													<label className="label">
														Supervisor:*
														</label>
													<Select
														name="supervisors"
														placeholder="Selecionar"
														className="select-style ajust-label-padrao disabled-select"
														isDisabled={visualize}
														options={supervisores}
														value={form.supervisor}
														ref={() => register({ name: "supervisor" }, { required: true })}
														onChange={item => {
															setValue("supervisor", item);
															setForm({ ...form, supervisor: item });
														}}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
													{errors.supervisor && (
														<span className="span-errors">
															Campo
															obrigatório
														</span>
													)}
												</div>
											</div>

											<div className="row col-md-12">
												<div className="card-header col-md-6">
													<label className="label">
														Locais de Trabalho:*
														</label>
													<Select
														styles={groupedStyles}
														menuPortalTarget={document.body}
														components={{ GroupHeading: CustomGroupHeading }}
														formatGroupLabel={formatGroupLabel}
														name="workPlace"
														placeholder="Selecionar"
														isMulti={!params.id}
														className="select-style ajust-label-padrao disabled-select"
														isDisabled={params.id ? true : false}
														options={locais}
														value={form.workPlace}
														ref={() => register({ name: "workPlace" }, { required: true })}
														onChange={item => {
															setValue(
																"workPlace",
																item
															);
															setForm({
																...form,
																workPlace: item
															});
															apontamentoInteligente(item);
														}}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
													{errors.workPlace && (
														<span className="span-errors">
															Campo
															obrigatório
														</span>
													)}
												</div>

												<div className="card-header col-md-6">
													<label className="label">
														Grupo de serviço:*
														</label>
													<Select
														styles={groupedStyles}
														menuPortalTarget={document.body}
														components={{ GroupHeading: CustomGroupHeading }}
														formatGroupLabel={formatGroupLabel}

														name="serviceGroup"
														placeholder="Selecionar"
														className="select-style ajust-label-padrao disabled-select"
														isDisabled={
															visualize
														}
														options={
															grupoServicos.length > 0 ? [{
																label: "Grupos de Serviço Sugeridos",
																options: grupoServicos
															},
															{
																label: "Todos",
																options: servicosTipo
															}] : servicosTipo
														}

														value={
															form.serviceGroup
														}
														ref={() =>
															register(
																{
																	name:
																		"serviceGroup"
																},
																{
																	required: true
																}
															)
														}

														onChange={item => {
															getServiceByIdGroup(item.value);
															setValue("serviceGroup", item);
															setValue("service", undefined);
															setForm({
																...form,
																serviceGroup: item,
																service: null
															});
														}}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
													{errors.serviceGroup && (
														<span className="span-errors">
															Campo
															obrigatório
														</span>
													)}
												</div>
											</div>
											<div className="row col-md-12">
												<div className="card-header col-md-6">
													<label className="label">
														Serviço:*
														</label>
													<Select
														styles={groupedStyles}
														menuPortalTarget={document.body}
														components={{ GroupHeading: CustomGroupHeading }}
														formatGroupLabel={formatGroupLabel}
														name="service"
														placeholder="Selecionar"
														className="select-style ajust-label-padrao disabled-select"
														isDisabled={
															visualize ||
															optionServicos.length <
															1
														}
														options={optionServicos}
														ref={() =>
															register(
																{
																	name:
																		"service"
																},
																{
																	required: true
																}
															)
														}
														value={
															form.service
																? form.service
																: null
														}
														onChange={item => {
															setValue(
																"service",
																item
															);
															setForm({
																...form,
																service: item
															});
														}}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
													{errors.service && (
														<span className="span-errors">
															Campo
															obrigatório
														</span>
													)}
												</div>
												<div className="card-header col-md-3">
													<label className="label">
														Turno:*
														</label>
													<Select
														name="workShift"
														placeholder="Selecionar"
														className="select-style ajust-label-padrao disabled-select"
														isDisabled={
															visualize
														}
														options={
															turnos
														}
														value={
															form.workShift
														}
														ref={() =>
															register(
																{
																	name:
																		"workShift"
																},
																{
																	required: true
																}
															)
														}
														onChange={item => {
															setValue(
																"workShift",
																item
															);
															setForm({
																...form,
																workShift: item
															});
														}}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
													{errors.workShift && (
														<span className="span-errors">
															Campo
															obrigatório
														</span>
													)}
												</div>
												<div className="card-header col-md-3">
													<label className="label">
														Horas:
														</label>
													<input
														type="time"
														className="form-control bmd-form-group"
														readOnly={visualize}
														name="hours"
														value={form.hours}
														onChange={item => {
															setValue(
																"hours",
																item.target
																	.value
															);
															setForm({
																...form,
																hours: item.target.value
															});
														}}
														autoComplete="off"
														ref={() =>
															register(
																{
																	name:
																		"hours"
																},
																{
																	required: true
																}
															)
														}
													/>
													{errors.hours && (
														<span className="span-errors">
															Campo
															obrigatório
														</span>
													)}
												</div>
											</div>
											<div className="col-md-12 ">
												<div className="card-header col-md-12">
													<label className="label">
														Descrição:
														</label>
													<input
														type="textarea"
														className="form-control bmd-form-group"
														readOnly={visualize}
														name="description"
														htmlFor="description"
														value={
															form.description
														}
														autoComplete="off"
														onChange={item => {
															setValue(
																"description",
																item.target
																	.value
															);
															setForm({
																...form,
																description:
																	item
																		.target
																		.value
															});
														}}
														ref={() =>
															register(
																{
																	name:
																		"description"
																},
																{
																	required: false
																}
															)
														}
													/>
												</div>
											</div>
										</div>
										<div
											role="tabpanel"
											className="tab-pane fade"
											id="perda"
										>
											apontamento de perda
											</div>
									</div>
								</div>
							</section>
						</div>
					</CardStyle>
				)}
				<br></br>
				<button className="btn btn-primary active pull-right" type="submit" onClick={() => submitForm(false)} hidden={props.flagVisualizar}>
					<i className='fas fa-check' /> {params.id ? 'Salvar' : 'Cadastrar'}
				</button>
				<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => props.history.goBack()}>
					<i className='fas fa-close' /> {visualize ? 'Voltar' : 'Cancelar'}
				</button>
				{
					!params.id && <button className="btn btn-primary active pull-right" type="submit" name="button_1" onClick={() => submitForm(true)} hidden={props.flagVisualizar} style={{ marginRight: 10 }}>
						<i className='fas fa-check' /> {params.id ? 'Salvar e Voltar' : 'Cadastrar e voltar'}
					</button>
				}

			</form>
		</>
	);
}

const mapStateToProps = state => {
	return {
		teams: Utils.removeInactiveToSelect(
			ObjectHelper.getValuesEquipeParseToSelect(
				state.appointmentManpower.teams
			)
		),
		membersTeam: Utils.removeInactiveToSelect(
			ObjectHelper.getMembersTeamParseToSelect(
				state.appointmentManpower.membersOfSelectedTeam
			)
		),

		appointment: state.appointmentManpower.appointment
			? state.appointmentManpower.appointment
			: undefined,

	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManpowerRegister);
