import api from './api';

export default {
	salvar(apontamentoInsumos){
		return api.post(`/api/ApontamentoInsumos`, apontamentoInsumos);
	},
	findAll(apontamentoInsumos){
		return api.post(`/api/ApontamentoInsumos/BuscarTodas`, apontamentoInsumos);
	},
	buscarPorId(apontamentoInsumosId){
		return api.get(`/api/ApontamentoInsumos/${apontamentoInsumosId}`);
	},
	buscarPorIdApontamentoMaquina(idApontamento){
		return api.get(`/api/ApontamentoInsumos/ByApontamentoMaquina/${idApontamento}`);
	},
	editar(apontamentoInsumosNovo){
		return api.put(`/api/ApontamentoInsumos`, apontamentoInsumosNovo);
	},
	deletar(apontamentoInsumosId){
		return api.delete(`/api/ApontamentoInsumos/${apontamentoInsumosId}`);
	},
	buscarParaAjustes(params){
		return api.post(`/api/ApontamentoInsumos/BuscarParaAjustes`, params);
	},
	atualizaListaApontamentos(params){
		return api.put(`/api/ApontamentoInsumos/AtualizaApontamentos`, params);
	},
	excluirSelecionados(params){
		return api.put(`/api/ApontamentoInsumos/ExcluirSelecionados`, params);
	},
	editarSelecionados(params){
		return api.put(`/api/ApontamentoInsumos/EditarSelecionados`, params);
	},
	buscarApInsumosSugeridos(params){
		return api.post(`/api/ApontamentoInsumos/BuscarSugeridosParaApontamento`, params);
	}
};
