import React from 'react';
import ApontamentoService from "../../services/apontamentoColaboradorService";
import ApontamentoMaquinaService from "../../services/apontamentoMaquinaService";
import { CardStyle } from 'components/CardStyle/cardstyle.component';
import DatePicker from "react-datepicker";
import Table from '../../components/Table/table.component';
import ServicoService from '../../services/servicoService';
import pt from 'date-fns/locale/pt';
import Card from "components/Card/card";
import agendamentoService from 'services/agendamentoService';
import Grafico from './grafico'

export default class PessoaCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inicio: "",
			fim: "",
			data: [],
			dia: true,
			mes: false,
			semana: false,
			servicos: [],
			maquinas: [],
			apontamentos: [],
			safra: null,
			fazenda: null,
			agenda: null,
			items: [],
			itemsGrafico: []
		}
		this.history = this.props.history;
	}

	async init(params) {
		await this.changeServico(params);
		await this.changeApontamentos(params);
		await this.changeApontamentoMaquinas(params);
		await this.popularGrafico()
	}

	async componentDidMount() {
		await this.setState({
			fazenda: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
			safra: JSON.parse(localStorage.getItem('safraId')) !== null ? JSON.parse(localStorage.getItem('safraId')).value : null
		})

		if (this.state.safra && this.state.fazenda) {
			this.init('dia')
		}
		this.buscarAgenda()

	}

	popularGrafico = async (param) => {
		var fazendaId = JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null;
		var safraId = JSON.parse(localStorage.getItem('safraId')) !== null ? JSON.parse(localStorage.getItem('safraId')).value : null;
		const { data } = await ApontamentoService.popularGrafico(fazendaId, safraId,
			param !== undefined ? param : "MO");
		this.setState({
			itemsGrafico: data.result
		})
	}

	buscarAgenda = async () => {

		var fazendaId = JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null;
		var safraId = JSON.parse(localStorage.getItem('safraId')) !== null ? JSON.parse(localStorage.getItem('safraId')).value : null;

		if (fazendaId !== null && safraId !== null) {
			const { data } = await agendamentoService.buscarAtiva(fazendaId, safraId);

			//if(data.result === null)
			//	return ToastHelper.warn("Você ainda não possui nenhum Agendamento ativo no momento!")

			var items = []
			if (data.result) {
				data.result.items.forEach(item => {
					items.push(item);
				})
				this.setState({
					agenda: data.result,
					items: items
				})

			}
		}
	}

	async changeServico(param) {
		let data = [];

		if (param === 'dia') data = await ServicoService.somatorioHorasServicoPeloDiaAtual(this.state.fazenda, this.state.safra);
		if (param === 'semana') data = await ServicoService.somatorioHorasPorSemana(this.state.fazenda, this.state.safra);
		if (param === 'mes') data = await ServicoService.somatorioHorasPeloMes(this.state.fazenda, this.state.safra);
		await this.setState({ servicos: data.data });
	}

	async changeApontamentos(param) {
		let data = [];
		if (param === 'dia') data = await ApontamentoService.somatorioHorasPeloDiaAtual(this.state.fazenda, this.state.safra);
		if (param === 'semana') data = await ApontamentoService.somatorioHorasPorSemana(this.state.fazenda, this.state.safra);
		if (param === 'mes') data = await ApontamentoService.somatorioHorasPeloMes(this.state.fazenda, this.state.safra);
		await this.setState({ apontamentos: data.data });
	}

	async changeApontamentoMaquinas(param) {
		let data = []
		if (param === 'dia') data = await ApontamentoMaquinaService.somatorioHorasPeloDiaAtual(this.state.fazenda, this.state.safra);
		if (param === 'semana') data = await ApontamentoMaquinaService.somatorioHorasPorSemana(this.state.fazenda, this.state.safra);
		if (param === 'mes') data = await ApontamentoMaquinaService.somatorioHorasPeloMes(this.state.fazenda, this.state.safra);
		await this.setState({ maquinas: data.data });
	}

	async onChangeDate(tipo, data) {
		await this.setState({
			[tipo]: data
		})

		if (this.state.inicio !== "" && this.state.fim !== "") {
			if (this.state.inicio <= this.state.fim) {

				let result = {
					inicio: this.state.inicio,
					fim: this.state.fim,
					fazendaId: this.state.fazenda,
					safraId: this.state.safra
				}
				this.setState({ data: result.data })
				let servico = await ServicoService.somatorioPorPeriodo(result);
				await this.setState({
					errorDataFim: "",
					servicos: servico.data
				});
				let apt = await ApontamentoService.somatorioPorPeriodo(result);
				await this.setState({
					errorDataFim: "",
					apontamentos: apt.data
				});
				let maquina = await ApontamentoMaquinaService.somatorioPorPeriodo(result);
				await this.setState({
					errorDataFim: "",
					maquinas: maquina.data
				});
			} else {
				await this.setState({
					errorDataFim: "Data final não pode ser maior que data inicial"
				})
			}
		}
	}

	onClickButton(parametro) {
		if (parametro === 'dia') {
			this.setState({
				dia: true, semana: false, mes: false
			})
			this.init('dia')
		} else if (parametro === 'semana') {
			this.setState({
				dia: false, semana: true, mes: false
			})
			this.init('semana')

		} else {
			this.setState({
				dia: false, semana: false, mes: true
			})
			this.init('mes')
		}
	}

	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Horas Trabalhadas', property: 'horas' },
	];

	columnsServico = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Horas Homem', property: 'horasHomem' },
		{ id: 2, title: 'Horas Máquina', property: 'horasMaquina' },
		{ id: 3, title: 'Total Horas', property: 'total' },
	];
	setChart = (item) => {
		this.popularGrafico(item.value);
	}

	table(parametro, tipo) {
		return (
			<>
				<hr></hr>
				<Table
					align='left'
					showPaginate={false}
					columns={tipo === 'servico' ? this.columnsServico : this.columns}
					data={parametro}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
				/>
			</>
		)
	};

	render() {

		return (
			<>
				<div className="container-fluid">

					{!this.state.safra &&
						<div className="jumbotron">
							<h1 className="display-4 ">Bem vindo!</h1>
							<br></br>
							<h4 >Este é o Sistema de Gestão Agrícola do Grupo Rehagro.</h4>
							<hr className="my-4" />
							<h4 className="lead">Para iniciar o sistema é necessário selecionar uma fazenda e uma safra no menu superior.</h4>
						</div>
					}
					{this.state.safra && this.state.fazenda && this.state.agenda &&
						<>
							<div className="card">
								<div className="row">

									<div className=" col-md-6">
										<Card
											title="Atividades da Agenda da Semana"
											buttonTitle1="Ver Agendamentos"
											buttonTitle2="Ver Planejamentos"
											route1="#/planejamento/agendamento/listagem"
											route2="#/planejamento/safra"
											agendaAtiva={this.state.agenda && this.state.agenda.nome !== undefined ? "Agenda da Semana - " + this.state.agenda.nome : ""}
											data={this.state.items}
										/>
									</div>
									<div className="col-md-6">
										<Grafico
											data={this.state.itemsGrafico}
											setChart={this.setChart}
										/>
									</div>
								</div>
							</div>
						</>
					}
					{this.state.safra && this.state.fazenda &&

						<CardStyle className="card">
							<div className='card-header row justify-content-start col-md-12'>
								<div className="card-header col-md-4">
									<div className="btn-group" role="group" aria-label="Basic example">
										<button
											type="button"
											className={`btn btn-primary btn-home ${this.state.dia ? 'btn-home-selected' : ''}`}
											onClick={this.onClickButton.bind(this, 'dia')}

										>
											Dia</button>
										<button
											type="button"
											className={`btn btn-primary btn-home ${this.state.semana ? 'btn-home-selected' : ''}`}
											onClick={this.onClickButton.bind(this, 'semana')}

										>
											Semana
									</button>
										<button
											type="button"
											className={`btn btn-primary btn-home ${this.state.mes ? 'btn-home-selected' : ''}`}
											onClick={this.onClickButton.bind(this, 'mes')}
										>
											Mês
									</button>
									</div>
								</div>
								<div className="card-header col-md-4">
									<label className="label">
										Início:*
											</label>
									<DatePicker
										name="inicio"
										htmlFor="inicio"
										placeholderText='Informe a data inicial'
										className='form-control bmd-form-group'
										selected={this.state.inicio}
										dateFormat="dd/MM/yyyy"
										onChange={
											this.onChangeDate.bind(this, "inicio")
										}
										locale={pt}
										autoComplete="off"

									/>
								</div>
								<div className="card-header col-md-4">
									<label className="label">
										Fim:*
								</label>
									<DatePicker
										name="fim"
										htmlFor="fim"
										placeholderText='Informe a data final'
										className='form-control bmd-form-group'
										selected={this.state.fim}
										dateFormat="dd/MM/yyyy"
										onChange={
											this.onChangeDate.bind(this, "fim")
										}
										locale={pt}
										isClearable
										autoComplete="off"
									/>
									{this.state.errorDataFim && <span className="span-errors">{this.state.errorDataFim}</span>}

								</div>
							</div>
							<hr></hr>
							<br></br>
							<div className="col-md-12">
								<h5>Apontamento de Mão de Obra</h5>
								{this.table(this.state.apontamentos, 'apontamentos')}
								<br></br>

							</div>
							<div className="col-md-12">
								<h5>Apontamento de Máquina</h5>
								{this.table(this.state.maquinas, 'maquinas')}
								<br></br>

							</div>
							<div className="col-md-12">
								<h5>Serviços</h5>
								{this.table(this.state.servicos, 'servico')}
								<br></br>

							</div>
						</CardStyle>
					}
				</div>
			</>
		)
	}
}
