import React from 'react';
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import Title from '../../components/Title/title.component';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';
import Modal from "../../utils/modalHelper";
import ToastHelper from '../../utils/toastHelper';
import localService from 'services/localService';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Utils from '../../utils/utils'

const NUMERO_ITENS_POR_PAGINA = 10;

export default class LocaisListagem extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			currentPage: 1,
			filtro: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
		}
		this.history = this.props.history
	}

	async componentDidMount() {
		await this.buscar(this.paginate);
	}

	TIPO_CADASTRO = 'local';

	columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Descrição', property: 'descricao' },
		{ id: 2, title: 'Fazenda', property: 'grupoLocais.nome' }
	];

	actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_LOCAL_LEITURA,
			action: item => this.visualizar(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_LOCAL_ATUALIZAR,
			action: item => this.editar(item)
		},
		{
			id: 3,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_LOCAL_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];

	paginate = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: 1, pageSize: 10, filtro: '', order: 'Nome', descedescending: false
	}

	async buscar(param) {

		let result = await localService.buscarTodos(param);
		await this.setState({
			data: { ...result.data.result }
		});
	}

	table() {
		return (
			<>
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					acoesSingleButton={false}
					actions={this.actions}
					data={this.state.data.items}
					onChangePage={page => this.handlePaginacao(page)}
					currentPage={this.state.currentPage}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
				/>
			</>
		)
	};

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				title='Excluir local'
				buttonTitle='Confirmar'
				button2Title='Cancelar'
				buttom2Action={() => this.handleModalExcluir(null, 'EXCLUIR')}
				submit={() => this.apagar(this.state.idItem)}
				close={() => this.handleModalExcluir(null, 'EXCLUIR')}
				content={
					<h6>Quer realmente excluir esse local ?</h6>
				}
			/>
		)
	}

	async handleModalExcluir(item) {
		if (item) {
			await this.setState({
				...this.state,
				idItem: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	cadastrar = () => {
		this.history.push(`/${this.TIPO_CADASTRO}/cadastro`);
	}

	editar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/editar/${item.id}`
		});
	}

	visualizar(item) {
		this.history.push({
			pathname: `/${this.TIPO_CADASTRO}/visualizar/${item.id}`
		});
	}

	async apagar(item) {

		let { data } = await localService.deletar(item);
		if (data.isOk) {
			ToastHelper.success("Operação realizada com sucesso!");
			await this.buscar(this.paginate);
		} else {
			ToastHelper.error(data.mensagem)
		}
		await this.handleModalExcluir();
	}

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro, currentPage: 1 });
		let newPaginate = this.paginate
		newPaginate.filtro = filtro
		newPaginate.page = 1
		await this.buscar(newPaginate);
	}

	async handlePaginacao(page) {
		await this.setState({ currentPage: page });
		let newPaginate = this.paginate
		newPaginate.page = page
		await this.buscar(newPaginate);
	}

	orderTable = (property, descending) => {
		this.setOrderColumn(Utils.parsePropertToFilter(property));
		this.setDescending(descending);
		let params = this.paginate;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		this.buscar(params)
	}

	setOrderColumn = (property) => {
		this.setState({
			...this.state,
			orderColumn: property
		})
	}

	setDescending = (descending) => {
		this.setState({
			...this.state,
			descending: descending
		})
	}

	render() {
		return (
			<div className="w-100">
				<Title
					acao="Novo Local"
					permissaoBotao={PERMISSAO.MODULO_LOCAL_CRIAR}
					iconBotao="fas fa-plus"
					onClick={this.cadastrar}>
					Locais
                </Title>
				<CardStyle className="card">
					<SimpleSearch
						placeHolder="Pesquisar"
						value={this.state.filtro}
						submit={filtro => { this.onChangeFilter(filtro) }}
					/>
					<div>
						{this.table()}
					</div>
				</CardStyle>
				{this.modalExcluir()}
			</div>
		)

	}
}
