import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import safraService from '../../../services/safraService'
import recursosTipoService from '../../../services/recursosTipoService'
import histogramaService from '../../../services/histogramaService'
import { Types } from "./histograma.ducks"
import executeAction from "../../../store/sagas/executeAction";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 1000
}

function* asyncListaHistogramas(action) {
	yield executeAction(function* () {
		const {params, callback } = action;
		const { data } = yield call( async () => await histogramaService.buscarTudo(params || parametros));

		yield put({ type: Types.READ_HISTOGRAMA, data: data.result });
		callback();
	}, Types.BUSCAR_HISTOGRAMA);
}

function* asyncListaSafra(action) {
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield call( async () => await safraService.get(parametros.fazendaId));

		yield put({ type: Types.SET_SAFRA, data: data.result });
		callback();
	}, Types.GET_SAFRA);
}

function* asyncListaRecursosTipo (action){
	yield executeAction(function* () {
		const { callback } = action;

		const { data } = yield call( async () => await recursosTipoService.get(parametros.fazendaId));

		yield put({ type: Types.SET_RECURSOS_TIPO, data: data.result });
		callback();
	}, Types.GET_RECURSOS_TIPO);
}

function* takeReadHistogramas() {
	yield takeLatest(Types.BUSCAR_HISTOGRAMA, asyncListaHistogramas);
}

function* takeListaRecursosTipo() {
	yield takeLatest(Types.GET_RECURSOS_TIPO, asyncListaRecursosTipo);
}

function* takeListaSafra() {
	yield takeLatest(Types.GET_SAFRA, asyncListaSafra);
}


export default function* root() {
	yield all([fork(takeReadHistogramas)])
	yield all([fork(takeListaRecursosTipo)])
	yield all([fork(takeListaSafra)])
}
