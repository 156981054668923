import api from './api';

export default {
	logout(){
		return api.get(`/api/Login`);
	},
	login(username, password) {

		return api.post(`/api/Login`, { username, password });
	},
	recuperarSenha(email){
		return api.post(`/api/Login/RecuperarSenha`, { email: email });
	}
};
