import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { CalendarioSchema } from './calendario.validate';
import ToastHelper from "../../utils/toastHelper";
import Toggle from 'react-toggle'
import Table from '../../components/Table/table.component';
import calendarioService from '../../services/calendarioService'
import { PERMISSAO } from '../../permissoes/permissoes';
import DatePicker from "react-datepicker";
import 'moment/locale/pt';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import pt from 'date-fns/locale/pt';
import './calendario.cadastro.css'
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';
import TabelaHorasPorDia from './horas-por-dia.component';
import ObjectHelper from 'utils/objectHelper';
import Select from "react-select";

const NUMERO_ITENS_POR_PAGINA = 4;

export default class CalendarioCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dados: {
				nome: undefined,
				codigo: undefined,
				descricao: undefined,
			},
			feriado: {
				nomeFeriado: '',
				dataFeriado: undefined,
				dia: undefined,
				mes: undefined,
				mesTabela: undefined,
				ano: undefined,
				periodoIntegral: true,
			},
			pagination: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 5,
			},
			feriadosAdicionados: [],
			horas: {
				horasDomingo: 0,
				horasQuarta: 0,
				horasQuinta: 0,
				horasSabado: 0,
				horasSegunda: 0,
				horasSexta: 0,
				horasTerca: 0,
			},
			currentPage: 1,
			visualizar: false,
			editar: false,
			idExcluir: 0,
			safraId: undefined
		};
		this.history = this.props.history;
	}

	FAZENDA = JSON.parse(localStorage.getItem('fazendaId')).value;
	PERIODOS = [
		{ value: '4', label: 'Meio Período' },
		{ value: '8', label: 'Integral' },
	]

	columns = [
		{ id: 0, title: 'Nome do Feriado', property: 'nome' },
		{ id: 1, title: 'Data do Feriado', property: 'dataTabela' },
		{ id: 2, title: 'Periodo Integral', property: 'periodoTabela' },
	];

	columnsEditar = [
		{ id: 3, title: 'Nome do Feriado', property: 'nome' },
		{ id: 4, title: 'Data do Feriado', property: 'dataTabela' },
		{ id: 5, title: 'Periodo Integral', property: 'periodoTabela' },
	];

	actions = [
		{
			id: 0,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_PESSOA_EXCLUIR, // TROCAR PARA PERMISSAO CALENDARIO
			action: item => this.handleModalExcluir(item),
		},
		{
			id: 2,
			name: 'Inte./Meio',
			icon: 'fas fa-low-vision circ-item-desativar',
			permissao: PERMISSAO.MODULO_PESSOA_ATUALIZAR,
			action: item => this.handleModalPeriodo(item),
		}
	];


	async componentDidMount() {
		await this.setOperacao()

		this.validateFeriados = this.validateFeriados.bind(this);
		const { match: { params } } = this.props;
		if (params.id) {
			await this.buscar(params.id);
		}
		await this.setPaginacao()
		this.onChangeNomeFeriado = this.onChangeNomeFeriado.bind(this);
	}

	async handleModalExcluir(item) {
		let feriadoAntigo = this.state.feriadosAdicionados
		let feriadoNovo = []
		if (item) {
			feriadoAntigo.forEach(feriado => {
				if (feriado.id !== item.id) {
					feriadoNovo.push(feriado)
				}
			});
			await this.setState({
				feriadosAdicionados: feriadoNovo
			});
		}
		this.setPaginacao();
	}

	async handleModalPeriodo(item) {
		var periodoAntigo = this.state.feriadosAdicionados

		var feriadoNovo = []
		if (item) {
			periodoAntigo.forEach(feriado => {
				if (feriado.id === item.id) {
					if (feriado.periodoIntegral) {
						feriado.periodoIntegral = false
						feriado.periodoTabela = <i className="far fa-times-circle inativo-check"></i>
					}
					else {
						feriado.periodoIntegral = true
						feriado.periodoTabela = <i className="far fa-check-circle ativo-check"></i>
					}
				}
				feriadoNovo.push(feriado)
			})
		}
		await this.setState({
			feriadosAdicionados: feriadoNovo
		});
	}

	async buscar(id) {
		let { data } = await calendarioService.buscarPorId(id);
		let dados = { ...data.result };
		this.setState({
			safraId: data.result.safraId
		})
		await this.setState({
			dados,
		});
		let feriados = []

		data.result.feriados.forEach(item => {

			if (item.periodoIntegral === true) {
				item.periodoTabela = <i className="far fa-check-circle ativo-check"></i>
			} else {
				item.periodoTabela = <i className="far fa-times-circle inativo-check"></i>
			}
			let dataParse = item.data.substring(0, 10)

			let feriado = {

				id: item.id,
				data: item.data,
				dataTabela: dataParse,
				periodoIntegral: item.periodoIntegral,
				nome: item.nome,
				periodoTabela: item.periodoTabela,
				dia: dataParse.substring(0, 2),
				mes: dataParse.substring(3, 5),
				ano: dataParse.substring(6, 10),

			}
			feriados.push(feriado)
		});

		feriados.sort(function (a, b) {
			if (a.nome < b.nome) { return -1; }
			if (a.nome > b.nome) { return 1; }
			return 0;
		});

		await this.setState({
			feriadosAdicionados: feriados,
			horas: dados.horas,
			data: {
				items: data.result.items,
				page: data.result.page,
				total: data.result.total
			},
		});

		await this.setPaginacao()

	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	voltar = () => {
		window.location.href = "/#/planejamento/calendario"
	}

	async handleChangePeriodo(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		const newFeriado = { ...this.state.feriado }
		newFeriado.periodoIntegral = value
		await this.setState({
			feriado: newFeriado
		});
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	onSubmit = async (values) => {
		values.feriados = this.state.feriadosAdicionados;
		values.fazendaId = this.FAZENDA;
		values.safraId = this.state.safraId || ObjectHelper.primeiraSafra();
		values.horas = this.state.horas;

		let metodo = this.state.editar ? "edit" : "salvar";
		let acao = this.state.editar ? "editado" : "cadastrado";

		let service = await calendarioService[metodo](values);

		if (service.data.isOk) {
			ToastHelper.success('Calendário ' + acao + ' com sucesso!');
			window.location.href = '/#/planejamento/calendario'
			window.scrollTo(0, 0);
		} else
			ToastHelper.error("Ocorreu um erro. " + (service.data.exception ? service.data.exception.Message : service.data.mensagem));
	}

	handlePaginacao(page) {
		let index1 = ((page - 1) * 5);
		let pagination = { ...this.state.pagination }
		pagination.items = [...this.state.feriadosAdicionados].splice(index1, 5)
		this.setState({
			currentPage: page,
			pagination
		})
	}

	setPaginacao() {
		const pagination = { ...this.state.pagination }
		let totalPages = this.state.feriadosAdicionados.length / 5
		totalPages = Math.trunc(totalPages + 1);
		pagination.items = [...this.state.feriadosAdicionados.slice(0, 5)]
		pagination.total = this.state.feriadosAdicionados.length
		pagination.page = totalPages;
		if ((this.state.feriadosAdicionados.length % 5) === 0) {
			pagination.page = this.state.pagination.page
		}
		this.setState({
			pagination: pagination
		})
	}

	tableFeriados() {
		return (
			<>
				<hr />
				<Table
					align='left'
					currentPage={this.state.currentPage}
					showPaginate={this.state.pagination.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.pagination.total}
					totalPages={this.state.pagination.page}
					onChangePage={page => this.handlePaginacao(page)}
					columns={this.state.visualizar || this.state.editar ? this.columnsEditar : this.columns}
					acoesSingleButton={false}
					actions={this.state.visualizar ? undefined : this.actions}
					data={this.state.pagination.items}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
				/>
			</>
		)
	}

	Calendario() {

		var dataFeriado = this.state.feriadosAdicionados
		var modifiers = {};
		var modifiersStyles = {};
		var feriadosCalendario = []

		if (dataFeriado.length !== 0) {
			dataFeriado.forEach(e => {
				feriadosCalendario.push(new Date(e.ano, e.mes, e.dia))
			})
			dataFeriado.forEach(e => {
				modifiers = {
					thursdays: { daysOfWeek: [4] },
					feriadosCalendario,
					monday: { daysOfWeek: [0] },
				};

				modifiersStyles = {
					feriadosCalendario: {
						color: 'white',
						backgroundColor: "rgb(10, 154, 132,0.5)",
						borderRadius: "0px"
					},
				};
			});

			let initialMonth = new Date()
			let ano = initialMonth.getFullYear()



			return (
				<DayPicker
					locale={'pt'}
					localeUtils={MomentLocaleUtils}
					modifiers={modifiers}
					modifiersStyles={modifiersStyles}
					numberOfMonths={12}
					pagedNavigation
					showOutsideDays={false}
					fixedWeeks
					initialMonth={new Date(ano, 1, 0)}
				/>
			);

		}
	}

	async onChangeDataFeriado(params) {
		const newFeriado = { ...this.state.feriado }
		newFeriado.dataFeriado = params
		newFeriado.dia = params.getDate()
		newFeriado.mes = params.getMonth()
		newFeriado.ano = params.getFullYear()
		newFeriado.mesTabela = params.getMonth() + 1
		await this.setState({
			feriado: newFeriado
		})
	}

	onChangeNomeFeriado(e) {
		const newFeriado = { ...this.state.feriado }
		newFeriado.nomeFeriado = e.target.value
		this.setState({
			feriado: newFeriado
		});
	}

	adicionaData = async () => {
		this.Calendario();
		var feriados = []

		var feriado = {
			nome: this.state.feriado.nomeFeriado,
			data: this.state.feriado.dataFeriado,
			dataTabela: this.state.feriado.dia + '/' + this.state.feriado.mesTabela + '/' + this.state.feriado.ano,
			periodoIntegral: this.state.feriado.periodoIntegral,
			dia: this.state.feriado.dia,
			mes: this.state.feriado.mes,
			ano: this.state.feriado.ano,
			id: this.state.idExcluir + 1,
			periodoTabela: '',
		}

		if (feriado.periodoIntegral === true) {
			feriado.periodoTabela = <i className="far fa-check-circle ativo-check"></i>
		} else {
			feriado.periodoTabela = <i className="far fa-times-circle inativo-check"></i>
		}

		feriados = [...this.state.feriadosAdicionados];
		feriados.push(feriado);

		if (this.state.feriado.nomeFeriado && this.state.feriado.dataFeriado) {
			const newFeriado = { ...this.state.feriado }
			newFeriado.nomeFeriado = '';
			newFeriado.dataFeriado = '';
			await this.setState({
				currentPage: 1,
				feriado: newFeriado,
				feriadosAdicionados: feriados,
				idExcluir: feriado.id
			})

			await this.setPaginacao();
		}


	}

	validateFeriados() {
		let error;
		if (!(this.state.feriadosAdicionados.length > 0)) {
			error = "Adicione ao menos 1 feriado ao calendário"
		}
		return error;
	}

	onDadosHorasPorDiaChange = async (dados) => {
		await this.setState({
			horas: dados
		});
	}

	campos() {
		const { state: { dados } } = this;
		return (
			<Formik
				initialValues={dados
				}
				enableReinitialize
				validationSchema={CalendarioSchema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, handleSubmit, handleChange, setFieldValue }) => (
					<form className="form " onSubmit={handleSubmit}>
						<div>
							<div className='row'>
								<div className='col'>
									<Title>{(this.titulo()) + ' Calendário'}</Title>
								</div>
							</div>
							<CardStyle className="card">
								<div className="card-header">
									Dados do calendário
                            </div>
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-4">
										<label className="label">
											Código :*
                                    </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o código"
											name="codigo"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.codigo ? values.codigo : ''}
											htmlFor="codigo"
											maxLength="40"
											autoComplete="off"
											type="text" />
										<ErrorMessage
											name="codigo">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Nome :*
                                    </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o Nome"
											name="nome"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.nome ? values.nome : ''}
											htmlFor="nome"
											maxLength="40"
											autoComplete="off"
											type="text"
										/>
										<ErrorMessage
											name="nome">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									{
										JSON.parse(localStorage.getItem('safraId')).length > 1 &&
										<div className="col-md-4 card-header ">
											<label className="label">Safras:*</label>
											<Select
												hideSelectedOptions
												value={JSON.parse(localStorage.getItem('safraId')).find(x => x.value === this.state.safraId)}
												onChange={(item) => {
													this.setState({ safraId: item.value })
												}}
												isDisabled={this.state.visualizar}
												className="select-style"
												placeholder={'Selecione uma safra'}
												options={JSON.parse(localStorage.getItem('safraId'))}
												theme={theme => ({
													...theme,
													borderRadius: 0
												})}
												name="safras"
											/>
										</div>

									}
									<div className="card-header col-md-12">
										<label className="label">
											Descrição
                                    </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Informe a descrição do Calendário"
											name="descricao"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.descricao ? values.descricao : ''}
											htmlFor="descricao"
											type="textarea"
											maxLength="200"
										/>
									</div>
									<div className="card-header col-md-12 ajust-label-padrao text-aling-center">
										<TabelaHorasPorDia
											onDadosChange={this.onDadosHorasPorDiaChange}
											dados={this.state.horas}
											disabled={this.state.visualizar} />
									</div>
								</div>
							</CardStyle>
							<CardStyle className="card mt-3">
								<div className="card-header">
									Feriados
                            </div>
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-4">
										<label className="label">
											Nome do feriado :*
                                    </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o Nome do Feriado"
											name="nomeFeriado"
											disabled={this.state.visualizar}
											onChange={(e) => { this.onChangeNomeFeriado(e); setFieldValue('nomeFeriado', e.value) }}
											value={this.state.feriado.nomeFeriado}
											htmlFor="nomeFeriado"
											maxLength="40"
											autoComplete="off"
											type="text"
										/>

									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Data do feriado:*
                                    </label>
										<DatePicker
											placeholderText={"Selecione a Data do Feriado"}
											className='form-control'
											selected={this.state.feriado.dataFeriado}
											value={this.state.feriado.dataFeriado}
											disabled={this.state.visualizar}
											dateFormat="dd/MM/yyyy"
											onChange={this.onChangeDataFeriado.bind(this)}
											locale={pt}
											autoComplete="off"
										/>
									</div>

									<div className="card-header col-md-3 ajust-label-padrao text-aling-center">
										<label className="label col-md-12 text-aling-center">
											Período Integral ?
                                    </label>
										<Toggle
											className="text-aling-center"
											defaultChecked={this.state.cheeseIsReady}
											name="periodoIntegral"
											disabled={this.state.visualizar}
											checked={this.state.feriado.periodoIntegral}
											icons={{
												checked: <i className="fas fa-check icon-toogle" />,
												unchecked: <i className="fas fa-times icon-toogle" />,
											}}
											onClick={this.handleChangePeriodo.bind(this)}
											onChange={(e) => { setFieldValue('periodoIntegral', e.value) }}
											value={values.periodoIntegral}
										/>
									</div>
									<div className="card-header col-md-1 ajust-label-padrao centro">
										<label className="label col-md-12 text-aling-center">
											Adicionar
                                    </label>
										<button className="btn btn-primary active radius" type='button' onClick={this.adicionaData} hidden={this.state.visualizar} disabled={!(this.state.feriado.dataFeriado && this.state.feriado.nomeFeriado)}>
											<i className="fas fa-plus border-icon"></i>
										</button>
									</div>
								</div>

								{
									this.state.feriadosAdicionados && this.state.feriadosAdicionados.length > 0 &&
									<div className='card-header row justify-content-start col-md-12'>
										<div className="card-header col-md-6">
											{this.tableFeriados()}
										</div >
										{/*<div className="card-header col-md-6">
											{this.Calendario()}
										</div >*/}
									</div>
								}
							</CardStyle>
							<SubmitFormButtons
								flagVisualizar={this.state.visualizar}
								flagEditar={this.state.dados.id !== undefined}
								actionGoBack={() => this.voltar()}
							/>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{
					this.campos()
				}
			</>
		)
	}
}
