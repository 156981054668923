import Modal from 'react-bootstrap/es/Modal';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import pt from 'date-fns/locale/pt';
import { useForm } from "react-hook-form";
import SafraRequest from '../safra.requests'
import ObjectHelper from 'utils/objectHelper'
import ToastHelper from 'utils/toastHelper';
import SafraRequests from '../safra.requests';

const FAZENDA = JSON.parse(localStorage.getItem('fazendaId'));
export default function ModalDesativar(props) {
	const initialValues = {
		localId: undefined,
		area: undefined,
		linha: undefined,
		planta: undefined,
		parametroId: undefined,
		cultivarId: undefined,
		dataPlantio: undefined,
		tipo: undefined
	};
	const { show, close, cultivares, addLocalSafra, local, safraId, cultura } = props;
	const { register, handleSubmit, errors, setValue, getValues } = useForm({});
	const [locais, setLocais] = useState([]);
	const [talhao, setTalhao] = useState(initialValues);
	const [parametros, setparametros] = useState([]);
	const [cultivar, setcultivar] = useState([]);

	const fecharModal = () => {
		setTalhao(initialValues);
		close();
	}

	useEffect(() => {
		async function fechData() {
			setLocais(await SafraRequest.getLocais());
			setparametros(await SafraRequest.getParametros());
		}
		fechData()
	}, [])

	useEffect(() => {
		if (props.local)
			BuscarLocalSafraPorId(props.local);
	}, [props.local])

	useEffect(() => {
		setcultivar(ObjectHelper.getValuesParseToSelect(cultivares));
	}, [cultivares])

	const opcoesTipos = [
		{
			value: "CUSTEIO",
			label: "Custeio"
		},
		{
			value: "INVESTIMENTO",
			label: "Investimento"
		}
	];

	const BuscarLocalSafraPorId = async id => {
		let data = await SafraRequests.buscarLocalSafraPorId(id);
		data.tipo = opcoesTipos.find(x => x.value === data.tipo);
		setTalhao(data);
		setValue("localId", data.local.value)
		setValue("area", data.area)
		setValue("linha", data.linha)
		setValue("planta", data.planta)
		setValue("parametroId", data.parametro.value)
		setValue("cultivarId", data.cultivar.value)
		setValue("dataPlantio", new Date(data.dataPlantio))
		setValue("tipo", data.tipo.value)
	}

	const validate = () =>
		getValues().localId &&
			getValues().area &&
			getValues().linha &&
			getValues().planta &&
			getValues().parametroId &&
			(cultura.codigo === "CAFE" || getValues().dataPlantio) &&
			getValues().tipo ? true : false;

	const submitForm = async () => {
		if (validate()) {
			let params = getValues();
			if (local) params.id = local;
			params.fazendaId = FAZENDA.value;
			params.safraId = safraId;
			let data = await SafraRequests.cadastrarLocal(params);
			if (!data.isOk) return ToastHelper.error(data.mensagem);
			setTalhao(initialValues);
			return addLocalSafra();
		} else {
			return ToastHelper.error("Todos os campos são obrigatórios");
		}
	}

	return (
		<>
			<Modal dialogClassName="modal-dialog-lg" show={show} onHide={() => { close(); }}>
				<Modal.Body className="modal-body">
					<h3 className='mt-3'>Locais de Plantio da Safra</h3>
					<hr />
					<form onSubmit={handleSubmit(submitForm)}>
						<div className='row justify-content-start col-md-12 '>
							<div className={"col-md-3 card-header"}>
								<label className="label">Locais:*</label>
								<Select
									placeholder='Selecione'
									value={talhao.local}
									className="select-style ajust-label-padrao disabled-select"
									options={locais}
									onChange={item => {
										setValue("localId", item.value);
										setValue("area", item.areaHa);
										setTalhao({ ...talhao, local: item, area: item.areaHa });
									}}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									ref={() => register({ name: "localId" }, { required: true })}
								/>
								{errors.localId && <span className="span-errors">Campo obrigatório</span>}

							</div>
							<div className={"col-md-3 card-header"}>
								<label className="label">Área de uso do local:*</label>
								<input
									type='number'
									className="form-control input-number"
									onKeyPress={() => "return event.charCode >= 48"}
									autoComplete="off"
									value={talhao.area}
									onChange={item => {
										setTalhao({ ...talhao, area: item.target.value });
										setValue("area", item.target.value)
									}}
									ref={() => register({ name: "area" }, { required: true })}
								/>
								{errors.area < 0 && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="col-md-3 card-header">
								<label className="label">Espaçamento Linhas/Plantas (m):*</label>
								<div className="flex">
									<input
										type='number'
										className="form-control input-number"
										onKeyPress={() => "return event.charCode >= 48"}
										autoComplete="off"
										value={talhao.linha}
										onChange={item => {
											setTalhao({ ...talhao, linha: item.target.value });
											setValue("linha", item.target.value);
										}}
										ref={() => register({ name: "linha" }, { required: true })}
									/>

									<label className="label ml-1 mr-1 mt-2">X</label>
									<input
										type='number'
										className="form-control input-number"
										onKeyPress={() => "return event.charCode >= 48"}
										autoComplete="off"
										value={talhao.planta}
										onChange={item => {
											setTalhao({ ...talhao, planta: item.target.value });
											setValue("planta", item.target.value);
										}}
										ref={() => register({ name: "planta" }, { required: true })}
									/>

								</div>
								{/* {errors.planta && errors.linha && <span className="span-errors">Campo obrigatório</span>} */}
							</div>
							<div className={"col-md-3 card-header"}>
								<label className="label">Parâmetros da Lavoura:*</label>
								<Select
									placeholder='Selecione'
									className="select-style ajust-label-padrao disabled-select"
									options={parametros}
									value={talhao.parametro}
									onChange={item => {
										setTalhao({ ...talhao, parametro: item });
										setValue("parametroId", item.value);
									}}
									ref={() => register({ name: "parametroId" }, { required: true })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.parametroId && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="card-header col-md-3">
								<label className="label">Variedade:</label>
								<Select
									placeholder='Selecione'
									className="select-style ajust-label-padrao disabled-select"
									options={cultivar}
									value={talhao.cultivar}
									onChange={item => {
										setTalhao({ ...talhao, cultivar: item });
										setValue("cultivarId", item.value);
									}}
									ref={() => register({ name: "cultivarId" }, { required: true })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.cultivarId && <span className="span-errors">Campo obrigatório</span>}

							</div>
							{cultura.codigo !== "CAFE" &&
								<div className={"col-md-2 card-header"}>
									<label className="label">Data do Plantio:*</label>
									<DatePicker
										selected={talhao.dataPlantio}
										isClearable={true}
										placeholderText='Informe a data final'
										className='form-control bmd-form-group'
										dateFormat="dd/MM/yyyy"
										autoComplete="off"
										onChange={date => {
											setTalhao({ ...talhao, dataPlantio: date });
											setValue("dataPlantio", date);
										}}
										value={talhao.dataPlantio}
										locale={pt}
										ref={() => register({ name: "dataPlantio" }, { required: true })}
									/>
									{errors.dataPlantio && <span className="span-errors">Campo obrigatório</span>}

								</div>
							}
							<div className="card-header col-md-4">
								<label className="label">Tipo:</label>
								<Select
									placeholder='Selecione'
									className="select-style ajust-label-padrao disabled-select"
									options={opcoesTipos}
									value={talhao.tipo}
									onChange={item => {
										setTalhao({ ...talhao, tipo: item });
										setValue("tipo", item.value);
									}}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									ref={() => register({ name: "tipo" }, { required: true })}
								/>
								{errors.tipo && <span className="span-errors">Campo obrigatório</span>}

							</div>
						</div>
						<hr />
						<div className="row col-md-12">
							<div className="col-md-6">
								<button className="btn btn-outline-secondary pull-left" name="cancelar-talhao" type="button" onClick={() => fecharModal()}>
									<i className='fas fa-close' /> Cancelar
                                </button>
							</div>
							<div className="col-md-6">
								<button className="btn btn-primary active pull-right" name="add-talhao" type="button" onClick={submitForm}>
									<i className='fas fa-check' /> Adicionar
                                </button>
							</div>
						</div>
					</form>

				</Modal.Body>
			</Modal>
		</>
	)
}



