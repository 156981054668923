import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { elementoSchema } from './elementoanalisesolo.validate';
import ElementoAnaliseSoloService from '../../services/ElementoAnaliseSoloService'
import ToastHelper from "../../utils/toastHelper";
import Select from 'react-select'
import UnidadeService from '../../services/unidadeService';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';

export default class ElementoAnaliseSoloCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: undefined,
				nome: undefined,
				descricao: undefined,
				unidadeId: undefined,
				sigla: undefined,
				codigo: undefined,
			},
			unidades: [],
			cultivares: [],
			visualizar: false,
			editar: false,
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		const { match: { params } } = this.props;
		if (params.id) {

			await this.buscar(params.id);
		}
		await this.getUnidades()

		await this.setOperacao()
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false })
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	async buscar(elementoId) {

		let { data } = await ElementoAnaliseSoloService.buscarPorId(elementoId);
		await this.setState({
			dados: { ...data.result },
			cultivares: data.result.cultivares
		});
	}

	getUnidades = async () => {

		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			order: 'Sigla',
			page: 1,
			pageSize: 100000
		}

		let data = await UnidadeService.getAll(param);
		await this.setState({ unidades: this.getValuesParseToSelect(data.data.result.items) })
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'sigla';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	voltar = () => {
		this.history.goBack();
	}

	setCultivares = (newList) => {
		this.setState({
			...this.state,
			cultivares: newList
		})
	}

	onSubmit = async (values) => {
		let metodo = this.state.editar ? "editar" : "cadastrar";
		let acao = this.state.editar ? "editada" : "cadastrada";
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		values.safraId = JSON.parse(localStorage.getItem('safraId')).value;
		values.unidadeId = (values.unidadeId ? values.unidadeId : null);
		let service = await ElementoAnaliseSoloService[metodo](values);

		if (service.data.isOk) {
			ToastHelper.success('Elemento  da análise do solo ' + acao + ' com sucesso!');
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (service.data.exception ? service.data.exception.Message : service.data.mensagem));

	}

	campos() {
		const { state: { dados } } = this;
		return (
			<Formik
				initialValues={dados}
				enableReinitialize
				validationSchema={elementoSchema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleChange }) => (
					<form className="form " onSubmit={handleSubmit}>
						<div>
							<div className='row'>
								<div className='col'>
									<Title>{(this.titulo()) + ' Elementos da Análise do Solo'}</Title>
								</div>
							</div>
							<CardStyle className="card">
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-4">
										<label className="label">
											Nome:*
                                    	</label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o nome"
											maxLength="40"
											name="nome"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.nome}
											autoComplete="off"
											htmlFor="nome"
											type="text" />
										<ErrorMessage
											name="nome">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Código:
                                    	</label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o código"
											maxLength="40"
											name="codigo"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.codigo}
											autoComplete="off"
											htmlFor="nome"
											type="text" />
										<ErrorMessage
											name="codigo">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Sigla:
                                    </label>
										<input
											className='form-control bmd-form-group'
											placeholder="Digite uma sigla"
											name="sigla"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.sigla}
											htmlFor="sigla"
											type="text"
										/>
										<ErrorMessage
											name="sigla">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Unidade:
                                    </label>
										<Select id="unidadeId"
											name="unidadeId"
											htmlFor="unidadeId"
											placeholder='Selecione a unidade'
											className="select-style ajust-label-padrao disabled-select"
											isDisabled={this.state.visualizar}
											value={this.state.unidades.filter(item => item.value === values.unidadeId)}
											onChange={(e) => { setFieldValue('unidadeId', (e ? e.value : undefined)) }}
											options={this.state.unidades}
											isClearable
											theme={theme => ({
												...theme,
												borderRadius: 0
											})}
										/>
										<ErrorMessage
											name="unidadeId">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-8">
										<label className="label">
											Descrição:
                                    </label>
										<input
											className='form-control bmd-form-group'
											placeholder="Digite a descrição"
											name="descricao"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.descricao}
											htmlFor="descricao"
											type="text"
											maxLength="200"
										/>
									</div>
								</div>
							</CardStyle>
							<SubmitFormButtons
								flagVisualizar={this.state.visualizar}
								flagEditar={this.state.dados.id !== undefined}
								actionGoBack={() => this.voltar()}
							/>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{
					this.campos()
				}
			</>
		)
	}
}
