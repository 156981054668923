import { SET_FILTRO_AJUSTE_APONTAMENTO } from "../actions/ajusteApontamento";

export const storageAjusteApontamento = "ajusteApontamento";

const initialState = {
	tipoApontamento: 0
};

export const ajusteApontamento = (state = initialState, action) => {
	switch (action.type) {
		case SET_FILTRO_AJUSTE_APONTAMENTO:
			return {
				...state,
				tipoApontamento: action.tipoApontamento
			};
		default:
			return state;
	}
};
