import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { TipoMaquinaSchema } from './tipo.maquina.cadastro.validate';
import TipoMaquinaService from '../../services/tipoMaquinaService'
import ToastHelper from "../../utils/toastHelper";
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';

export default class CulturaCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: undefined,
				nome: undefined,
				descricao: undefined,
			},
			visualizar: false,
			editar: false,
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		const { match: { params } } = this.props;
		if (params.id) {

			await this.buscar(params.id);
		}
		await this.setOperacao()
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	async buscar(unidadeId) {

		let { data } = await TipoMaquinaService.buscarPorId(unidadeId);
		await this.setState({
			dados: { ...data },
			editar: true,
		});
	}

	voltar = () => {
		this.history.goBack();
	}

	onSubmit = async (values) => {

		let metodo = this.state.editar ? "editar" : "salvar";
		let acao = this.state.editar ? "editada" : "cadastrada";
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		const { data } = await TipoMaquinaService[metodo](values);

		if (data.isOk) {
			ToastHelper.success('Tipo de Máquina ' + acao + ' com sucesso!');
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
	}

	campos() {
		const { state: { dados } } = this;
		return (
			<Formik
				initialValues={dados}
				enableReinitialize
				validationSchema={TipoMaquinaSchema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleChange }) => (
					<form className="form " onSubmit={handleSubmit}>
						<div>
							<div className='row'>
								<div className='col'>
									<Title>{(this.titulo()) + ' Tipo de Máquina'}</Title>
								</div>
							</div>
							<CardStyle className="card">
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-12">
										<label className="label">
											Nome:*
                                    </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o nome"
											name="nome"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.nome}
											htmlFor="nome"
											maxLength="40"
											autoComplete="off"
											type="text" />
										<ErrorMessage
											name="nome">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-12">
										<label className="label">
											Descrição:
                                    </label>
										<textarea
											className='form-control bmd-form-group'
											disabled={this.state.visualizar}
											placeholder="Informe a descrição do tipo de máquina"
											name="descricao"
											onChange={handleChange}
											value={values.descricao}
											htmlFor="descricao"
											maxLength="200"
											type="textarea"
										/>
										<ErrorMessage
											name="descricao">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
								</div>
							</CardStyle>
							<SubmitFormButtons
								flagVisualizar={this.state.visualizar}
								flagEditar={this.state.dados.id !== undefined}
								actionGoBack={() => this.voltar()}
							/>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{
					this.campos()
				}
			</>
		)
	}
}
