import React, { useState, useEffect } from 'react';
import {
	BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Legend,
} from 'recharts';
import { Button, ButtonGroup } from 'reactstrap';


export default function HistogramaGrafico(props) {
	const [array, setArray] = useState([])
	const [ativoGrupo, setAtivoGrupo] = useState({
		grupo: true,
		maquina: false
	});

	const [ativoMedia, setAtivoMedia] = useState({
		mediaHorasDia: true,
		mediaDiaMes: false,
		mediaHorasMes: false,
		label: "horas por dia"
	})


	const handleEquipamentos = param => {
		if (param === "grupo") {
			let ativo = {
				grupo: true,
				maquina: false
			}
			setAtivoGrupo(ativo)
		} else {
			let ativo = {
				grupo: false,
				maquina: true
			}
			setAtivoGrupo(ativo)
		}
		props.controlar({ controleEquipamentos: param })
	}

	const handleMedia = param => {
		if (param === "mediaHorasDia") {
			let ativo = {
				mediaHorasDia: true,
				mediaDiaMes: false,
				mediaHorasMes: false,
				label: "horas por dia"
			}
			setAtivoMedia(ativo)
		} else if (param === "mediaDiaMes") {
			let ativo = {
				mediaHorasDia: false,
				mediaDiaMes: true,
				mediaHorasMes: false,
				label: "horas por mês"
			}
			setAtivoMedia(ativo)
		} else {
			let ativo = {
				mediaHorasDia: false,
				mediaDiaMes: false,
				mediaHorasMes: true,
				label: "dias úteis "
			}
			setAtivoMedia(ativo)
		}
		props.controlar({ controleHoras: param })
	}

	useEffect(() => {
		setArray(props.data)
	}, [props.data]);

	return (
		<>
			<div>
				<div className="row">
					<div className="col-md-6">
						<div label='Filtros' style={{ marginLeft: 10, fontSize: 16 }}>
							Separar os gráficos por:
							</div>
						<ButtonGroup style={{ margin: 5 }}>
							<Button
								outline color="success"
								className={"btn-outline-success-custom " + (ativoGrupo.grupo ? `btn-painel-ativo ` : "")}
								onClick={() => handleEquipamentos("grupo")}
							>
								Grupo de Máquinas
							</Button>
							<Button
								outline color="success"
								className={"btn-outline-success-custom " + (ativoGrupo.maquina ? `btn-painel-ativo` : "")}
								onClick={() => handleEquipamentos("maquina")}
							>
								Máquinas Individuais
							</Button>
						</ButtonGroup>

					</div>
					<div className="col-md-6">
						<div label='Filtros' style={{ marginLeft: 10, fontSize: 16 }}>
							Filtrar por média:
							</div>
						<ButtonGroup style={{ margin: 5 }}>
							<Button
								outline color="success"
								className={"btn-outline-success-custom " + (ativoMedia.mediaHorasDia ? `btn-painel-ativo` : "")}
								onClick={() => handleMedia("mediaHorasDia")}
							>
								Média Horas/Dias
						</Button>
							<Button
								outline color="success"
								className={"btn-outline-success-custom " + (ativoMedia.mediaDiaMes ? `btn-painel-ativo` : "")}
								onClick={() => handleMedia("mediaDiaMes")}
							>
								Média Dias/Mês
						</Button>
							<Button
								outline color="success"
								className={"btn-outline-success-custom " + (ativoMedia.mediaHorasMes ? `btn-painel-ativo` : "")}
								onClick={() => handleMedia("mediaHorasMes")}
							>
								Média Horas/Mês
						</Button>
						</ButtonGroup>

					</div>
				</div>
				<hr></hr>
				<BarChart width={window.innerWidth - 130} height={300} data={array || []}
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey={`${ativoGrupo.grupo === true ? "grupo" : 'máquinas'}`} domain={[0, 'dataMax + 1000']} />
					<YAxis
						yAxisId="left"
						orientation="left"
						stroke="#8884d8"
						domain={[dataMin => 0, dataMax => (dataMax * 2)]}
					/>
					<Tooltip content={<CustomTooltipGrupo ativoGrupo={ativoGrupo} ativoMedia={ativoMedia} />} />
					{
						array.length > 0 &&
						<Legend
							payload={
								[
									{ id: 'media', value: `Media de ${ativoMedia.label} por ${ativoGrupo.grupo === true ? `Grupo de Máquinas` : `Máquinas`}`, type: 'square', color: '#8884d8' },
								]
							}
						/>
					}
					<ReferenceLine y={props.data[0] !== undefined ? props.data[0].controle : 0}
						label={{ position: 'top', value: `${props.data[0] !== undefined ? props.data[0].controle : 0} ${ativoMedia.label}`, fill: 'black', fontSize: 14 }}
						stroke="red" strokeDasharray="3 3" yAxisId="left" />					<Bar maxBarSize={50} yAxisId="left" dataKey="media" fill="#8884d8" />
				</BarChart>
			</div>

		</>
	);
}

const CustomTooltipGrupo = ({ ativoMedia, ativoGrupo, active, payload, label }) => {
	let title = ativoGrupo.grupo === true ? `Grupo de Máquinas:  ${label}` : `Máquinas:  ${label}`
	let customLabel = ativoMedia.label
	if (ativoMedia.label === "dias úteis ") customLabel = "dias úteis trabalhados"
	if (ativoMedia.label === "horas por mês ") customLabel = "horas trabalhadas por mês"
	if (ativoMedia.label === "horas por dia") customLabel = "horas trabalhadas por dia"

	if (active && payload) {
		return (
			<div className="custom-tooltip-rechart" >
				<p className="">{title}</p>
				<p>{`Média de ${payload[0].value} ${customLabel}`}</p>

			</div>
		);
	}

	return null;
};
