import React, { useState, useCallback, useEffect } from 'react';
import { CardStyle } from '../../../../components/CardStyle/cardstyle.component';
import TabelaApontamentoInsumos from '../../../../components/TabelaApontamentoInsumos/tabelaApontamentoInsumos.component'

function FormEntry(props) {

	const [insumos, setInsumos] = useState([]);

	useEffect(() => {
		if (props.insumos) {
			setInsumos([...props.insumos]);
		}
	}, [props.insumos]);

	const addInsumo = useCallback(async (item) => {
		setInsumos([...insumos, item]);
		props.addInsumo(item);
	});

	const editInsumo = useCallback(async (item) => {
		setInsumos([...item]);
		props.editInsumo(item);
	});

	const deleteInsumo = useCallback(async (item) => {
		setInsumos([...item]);
		props.deleteInsumo(item);
	});

	return (
		<>
			<div className="w-100 mb-3">
				<CardStyle className="card mb-3">
					<TabelaApontamentoInsumos
						recursos={insumos}
						onAdd={addInsumo}
						onEdit={editInsumo}
						onDelete={deleteInsumo}
						opcoesRecursosComCodigo={['IN', 'RE']}
						removerClasses={['EPI']} />
				</CardStyle>
			</div>
		</>
	);
}

export default FormEntry;
