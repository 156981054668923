import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { CategoriaPessoaSchema } from './categoriaPessoa.validate';
import ToastHelper from "../../utils/toastHelper";
import Toggle from 'react-toggle'
import CategoriaService from '../../services/categoriaService'
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons'

import './categoriaPessoa.cadastro.page.scss'

export default class CategoriaPessoa extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: undefined,
				nome: undefined,
				descricao: undefined,
				operador: undefined,
			},
			visualizar: false,
			editar: false,
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		let fazendaId = JSON.parse(localStorage.getItem('fazendaId'));

		await this.setState({
			fazendaId
		})

		await this.setOperacao()

		const { match: { params } } = this.props;
		if (params.id) {
			await this.buscar(params.id);
		}
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	async buscar(categoriaId) {
		let data = await CategoriaService.buscarPorId(categoriaId);
		await this.setState({
			dados: { ...data.data },
		});
		await this.setState({
			...this.state,
			operador: this.state.dados.operador,
		});
	}

	voltar = () => {
		this.history.goBack();
	}

	async handleChangeOperador(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		await this.setState({
			...this.state,
			operador: value,
		});
	}

	onSubmit = async (values) => {

		let metodo = this.state.editar ? "edit" : "salvar";
		let acao = this.state.editar ? "editado" : "cadastrado";
		values.fazendaId = this.state.fazendaId.value;
		let service = await CategoriaService[metodo](values);

		if (service.data.isOk) {
			ToastHelper.success('Grupo ' + acao + ' com sucesso!');
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (service.data.exception ? service.data.exception.Message : service.data.mensagem));
	}

	campos() {
		const { state: { dados } } = this;
		return (
			<Formik
				initialValues={dados}
				enableReinitialize
				validationSchema={CategoriaPessoaSchema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, handleSubmit, handleChange }) => (
					<form className="form " onSubmit={handleSubmit}>
						<div>
							<div className='row'>
								<div className='col'>
									<Title>{(this.titulo()) + ' Grupo de Colaboradores'}</Title>
								</div>
							</div>
							<CardStyle className="card">
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-9">
										<label className="label">
											Nome:*
                                    </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Informe o nome"
											name="nome"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.nome}
											htmlFor="nome"
											maxLength="40"
											autoComplete="off"
											type="text" />
										<ErrorMessage
											name="nome">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-3 ajust-label-padrao text-aling-center">
										<label className="label col-md-12 text-aling-center">
											Pode operar máquina ?
                                        </label>
										<Toggle
											className="text-aling-center"
											defaultChecked={this.state.cheeseIsReady}
											name="operador"
											disabled={this.state.visualizar}
											checked={this.state.operador}
											icons={{
												checked: <i className="fas fa-check icon-toogle" />,
												unchecked: <i className="fas fa-times icon-toogle" />,
											}}
											onClick={this.handleChangeOperador.bind(this)}
											onChange={handleChange}
											value={values.operador}
										/>
									</div>
									<div className="card-header col-md-12">
										<label className="label">
											Descrição
                                    </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Informe a descrição da categoria"
											name="descricao"
											disabled={this.state.visualizar}
											onChange={handleChange}
											value={values.descricao}
											htmlFor="descricao"
											type="textarea"
											maxLength="200"
										/>
									</div>
								</div>
							</CardStyle>
							<SubmitFormButtons
								flagVisualizar={this.state.visualizar}
								flagEditar={this.state.dados.id !== undefined}
								actionGoBack={() => this.voltar()}
							/>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{
					this.campos()
				}
			</>
		)
	}
}
