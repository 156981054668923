import api from './api';

export default {

	buscarPorId(cultivarId) {
		return api.get(`/api/Cultivar/BuscarPorId/${cultivarId}`);
	},
	getByCulturaId(culturaId) {
		return api.get(`/api/Cultivar/GetByCulturaId/${culturaId}`);
	},
	deletarDaCultura(cultivarId, culturaId) {
		return api.delete(`/api/Cultivar/deletarDaCultura/${culturaId}/${cultivarId}`);
	},
	adicionaCultivarComId(cultivar) {
		return api.post(`/api/Cultivar/adicionaAtualizaCultivar`, cultivar);
	},
	buscarTodas(param) {
		return api.post(`/api/Cultivar/BuscarTodas`, param);
	},
	editar(cultivar) {
		return api.put(`/api/Cultivar`, cultivar);
	},
	cadastrar(cultivar) {
		return api.post(`/api/Cultivar`, cultivar)
	},
	deletar(cultivarId) {
		return api.delete(`/api/Cultivar/${cultivarId}`);
	},
};
