import React from 'react';
import { CardStyle } from '../../../../components/CardStyle/cardstyle.component';
import DatePicker from "react-datepicker";
import pt from 'date-fns/locale/pt';
import Select from 'react-select';
import '../style/machine.appointment.css';

export default function RegisterAppointmentHead(props) {

	return (
		<>
			<div className="w-100 mb-3">
				<CardStyle className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-4">
								<label className="label">Data:*</label>
								<DatePicker
									placeholderText={'Selecione uma data'}
									disabled={props.visualize || props.edit}
									className='form-control'
									selected={props.date}
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									onChange={(item) => {
										props.setDate(item)
									}}
									locale={pt}
								/>
							</div>
							{
								!props.disabled && JSON.parse(localStorage.getItem('safraId')).length > 1 &&
								<div className="col-md-4">
									<label className="label">Safras:*</label>
									<Select
										hideSelectedOptions
										value={props.form.safra}
										onChange={(item) => {
											props.setSafras(item)
										}}
										isDisabled={props.visualize || props.edit}
										className="select-style"
										placeholder={'Selecione uma safra'}
										options={JSON.parse(localStorage.getItem('safraId'))}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
										name="safras"
									/>
								</div>
							}
							{
								!props.disabled &&
								<div className="col-md-4">
									<label className="label">Máquina:*</label>
									<Select
										hideSelectedOptions
										value={props.machine}
										onChange={(item) => {
											props.setMachine(item)
										}}
										isDisabled={props.visualize || props.edit}
										className="select-style"
										placeholder={'Selecione uma máquina'}
										options={props.machineOptions}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
										name="maquina"
									/>
								</div>
							}
							{
								props.disabled &&
								<div className="col-md-4 display-block">
									<label className="label">
										Máquina
                  </label>
									<input className="form-control" type="text" autoComplete="off" disabled value={props.machine ? props.machine.label : ''} />
								</div>
							}

						</div>
					</div>
				</CardStyle>
			</div>
		</>
	);
}
