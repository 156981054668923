import React, { useState, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { useForm } from 'react-hook-form';
import Title from "../../../components/Title/title.component"
import Select from 'react-select';

export default function HistogramaFiltro(props) {
	const { register, handleSubmit, setValue } = useForm({});
	const [anoMes, setAnoMes] = useState([])



	const handleChange = value => {
		setAnoMes(value)
	}

	useEffect(() => {
		if (props.anoMes !== undefined && anoMes.length === 0) {
			var lastItem = props.anoMes.slice(-1)
			setAnoMes(ObjectHelper.getValuesParseToSelect(lastItem, "id", "anoMes"))
		}
	}, [props]);

	const submitForm = values => {
		let arrayAnoMeses = []
		if (anoMes !== undefined) {
			anoMes.forEach(item => {
				arrayAnoMeses.push(item.value)
			})
		}
		values.anosMeses = arrayAnoMeses
		props.filtrar(values)
	};

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>Painel de Equipamentos</Title>
					</div>
				</div>
				<div className="card">
					<span style={{ fontSize: 18, margin: 10, fontWeight: 500 }}>Filtros</span>
					<div label='Filtros'>
						<div className='card-body row justify-content-start' style={{ paddingTop: "inherit" }}>
							<div className="card-header col-md-3">
								<label className="label">
									Períodos:*
								</label>
								<Select
									isClearable={true}
									isMulti={true}
									value={anoMes}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={anoMes => handleChange(anoMes)}
									ref={() => register({ name: "anoMes" })}
									options={ObjectHelper.getValuesParseToSelect(props.anoMes, "id", "anoMes")}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
							</div>
							<div className="card-header col-md-3">
								<label className="label" htmlFor="safraId">
									Safras :*
								</label>
								<Select
									id="safraId"
									name="safraId"
									isClearable={true}
									isMulti={false}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={safra => { setValue('safraId', safra ? safra.value : undefined) }}
									ref={() => register({ name: "safraId" })}
									options={ObjectHelper.getValuesParseToSelect(props.safras)}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
							</div>
							<div className="card-header col-md-3">
								<label className="label" htmlFor="servicoTipoId">
									Grupo de Máquinas :*
								</label>
								<Select
									id="servicoTipoId"
									name="servicoTipoId"
									isClearable={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={local => { setValue('grupoMaquinasId', local ? local.value : undefined) }}
									ref={() => register({ name: "grupoMaquinasId" })}
									options={ObjectHelper.getValuesParseToSelect(props.grupoMaquinas)}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
							</div>
							<div className="card-header col-md-3">
								<label className="label">
									Máquinas :*
									</label>
								<Select
									id="servicoId"
									name="servicoId"
									isClearable={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									options={ObjectHelper.getValuesParseToSelect(props.maquinas) || []}
									onChange={servico => { setValue('maquinasId', servico ? servico.value : undefined); }}
									ref={() => register({ name: "maquinasId" })}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
							</div>
						</div>
						<div className="col-md-12 mt-3">
							<button className="btn btn-primary active pull-right" type="submit" style={{ marginBottom: 10, marginTop: -30 }}>
								<i className='fas fa-search' /> Buscar
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}


