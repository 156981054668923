import React, { useState, useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Title from "components/Title/title.component";
import { CardStyle } from "components/CardStyle/cardstyle.component";
import { Creators as ModulosCreators } from "./store/entry.appointment.ducks";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import ObjectHelper from "utils/objectHelper";
import pt from "date-fns/locale/pt";
import Utils from "utils/utils";
import AppointmentEntrysService from "services/apontamentoInsumosService";
import ToastHelper from "utils/toastHelper";
import apontamentoMaquinaService from "services/apontamentoMaquinaService";
import servicoService from 'services/servicoService'
import { List, ListItem, ListItemText, ListItemIcon, Grid } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import safraService from "services/safraService";

function EntryRegister(props) {
	const { register, handleSubmit, errors, setValue, getValues } = useForm({});

	const [visualize, setVisualize] = useState(false);
	const [units, setUnits] = useState([]);
	const [form, setForm] = useState({});
	const [date, setDate] = useState();
	const [materials, setMaterials] = useState([]);
	const [familyMaterialsOptions, setFamilyMaterialsOptions] = useState([]);
	const {
		match: { params }
	} = props;
	const [insumoSelecionado, setInsumoSelecionado] = useState();
	const [quantidade, setQuantidade] = useState();
	const [unidadeSelecionada, setUnidadeSelecionada] = useState();

	const getEntryAppointmentById = async (id) => {
		const { data } = await AppointmentEntrysService.buscarPorId(id);
		setarDados(data);
	}

	useEffect(() => {
		if (params.modo === "visualizar") setVisualize(true);
		if (params.id !== undefined) getEntryAppointmentById(params.id);

		props.getMaterialsByFamily("reset");
		props.getResponsible();
		props.getServiceGroups();
		getMaterials();
		buscarLocais();
	}, []);

	const setarDados = async entryAppointment => {
		if (params.id !== undefined && entryAppointment !== undefined) {
			let newDate = undefined;
			let dat = entryAppointment.date.split(" ")[0];
			dat = dat.split("/");
			newDate = new Date(dat[2], dat[1] - 1, dat[0]);
			setDate(newDate);
			let initialValue = {
				id: entryAppointment.id,
				amount: entryAppointment.quantidade,
				date: entryAppointment ? entryAppointment.date : "",
				responsible: {
					value: entryAppointment.responsavel.id,
					label: entryAppointment.responsavel.nome
				},

				serviceGroup: {
					value: entryAppointment.servicoTipo.id,
					label: entryAppointment.servicoTipo.nome
				},
				service: {
					value: entryAppointment.servico.id,
					label: entryAppointment.servico.nome
				},
				familyMaterial: {
					value: entryAppointment.familiaMateriais.id,
					label: entryAppointment.familiaMateriais.nome
				},
				material: {
					value: entryAppointment.materiais.id,
					label: entryAppointment.materiais.nome
				},
				unity: {
					value: entryAppointment.unidade.id,
					label: entryAppointment.unidade.sigla
				},
				safra: entryAppointment.safra,
				description: entryAppointment.descricao
			};
			setForm(initialValue);
			buscarLocais(initialValue.safra)
			setLocais({
				value: entryAppointment.local.id,
				label: entryAppointment.local.nome
			})
			setValue("amount", entryAppointment.quantidade);
			setValue(
				"date",
				entryAppointment ? entryAppointment.date : ""
			);
			setValue("responsible", {
				value: entryAppointment.responsavel.id,
				label: entryAppointment.responsavel.nome
			});
			setValue("location", {
				value: entryAppointment.local.id,
				label: entryAppointment.local.nome
			});
			setValue("serviceGroup", {
				value: entryAppointment.servicoTipo.id,
				label: entryAppointment.servicoTipo.nome
			});
			setValue("service", {
				value: entryAppointment.servico.id,
				label: entryAppointment.servico.nome
			});
			setValue("familyMaterial", {
				value: entryAppointment.familiaMateriais.id,
				label: entryAppointment.familiaMateriais.nome
			});
			setValue("material", {
				value: entryAppointment.materiais.id,
				label: entryAppointment.materiais.nome
			});
			setValue("unity", {
				value: entryAppointment.unidade.id,
				label: entryAppointment.unidade.sigla
			});
			setValue("safra", {
				value: entryAppointment.safra.value,
				label: entryAppointment.safra.label
			});
			setValue("description", entryAppointment.descricao);
			setValue("id", entryAppointment.id);
			setQuantidade(entryAppointment.quantidade)
			setInsumoSelecionado(entryAppointment.materiais)
			setUnidadeSelecionada({ value: entryAppointment.unidade.id, label: entryAppointment.unidade.sigla })
			let units = ObjectHelper.parseValuesUnitsToSelect(
				entryAppointment.materiais.unidade
			);
			setUnits(units);
			props.getServicesByGroupId({
				farmId: JSON.parse(localStorage.getItem("fazendaId")).value,
				groupServiceId: entryAppointment.servicoTipo.id
			});

			let params = {
				farmId: JSON.parse(localStorage.getItem("fazendaId")).value,
				groupServiceId: entryAppointment.servicoTipo.id
			};
			props.getFamilyMaterials(params);
		}
	}

	useEffect(() => {
		setMaterials(props.materials);
	}, [props.materials]);

	useEffect(() => {
		if (form.material) {
			if (materials ? materials.length > 0 : false) {
				if (props.entryAppointment) {
					let material = {
						label: props.entryAppointment.materiais.label,
						value: props.entryAppointment.materiais.id,
						unidade: props.entryAppointment.materiais.unidade
					};
					if (material !== undefined) {
						let units = ObjectHelper.parseValuesUnitsToSelect(
							material.unidade
						);
						setUnits(units);
						let unity = {
							value: props.entryAppointment.unidade.id,
							label: props.entryAppointment.unidade.sigla
						};
						setValue("unity", unity);
						setValue("id", props.entryAppointment.id);
						setForm({ ...form, unity: unity });
					}
				}
			}
		}
	}, [materials]);

	useEffect(() => {
		if (props.familyMaterials.length > 0) {
			setFamilyMaterialsOptions(props.familyMaterials);
		} else {
			setFamilyMaterialsOptions([]);
		}
	}, [props.familyMaterials]);

	useEffect(() => {
		if (form.familyMaterial) {
			props.getMaterialsByFamily(form.familyMaterial.value);
			form.familyMaterial
				? setForm({ ...form, familyMaterial: form.familyMaterial })
				: setForm({ ...form });
		}
	}, [form.familyMaterial]);

	const handleGroupServie = useCallback(
		group => {
			if (group) {
				props.getServicesByGroupId({
					farmId: JSON.parse(localStorage.getItem("fazendaId")).value,
					groupServiceId: group.value ? group.value : group
				});
			}
		},
		[form, props]
	);

	const submitForm = useCallback(
		async voltar => {
			if (typeof (voltar) === "object") return false;

			var data = form;
			if (!getValues().amount ||
				getValues().amount === "" ||
				getValues().amount === 0 ||
				getValues().date === undefined ||
				form.familyMaterial.value === undefined ||
				getValues().location.value === undefined ||
				form.material.value === undefined ||
				form.responsible.value === undefined ||
				(form.service && form.service.value === undefined) ||
				(!form.service && data.service.value === undefined) ||
				form.serviceGroup.value === undefined ||
				form.unity.value === undefined) return false;

			let apontamentos = [{
				data: getValues().date,
				fazendaId: JSON.parse(localStorage.getItem("fazendaId"))
					.value,
				safraId: form.safra !== undefined ? form.safra.value : ObjectHelper.primeiraSafra(),
				descricao: data.description,
				familiaMateriaisId: form.familyMaterial.value,
				localId: getValues().location.value,
				insumosId: form.material.value,
				quantidade: getValues().amount,
				responsavelId: form.responsible.value,
				servicoId: form.service
					? form.service.value
					: data.service.value,
				servicoTipoId: form.serviceGroup.value,
				unidadeId: form.unity.value,
				id: form.id
			}
			];

			if (params.id !== undefined) {
				props.updateEntryAppointment(apontamentos[0], props.history);
			} else {
				const { data } = await AppointmentEntrysService.salvar({ apontamentos });
				if (data.isOk) {
					ToastHelper.success("Apontamento criado com sucesso!");
					if (voltar) {
						return props.history.goBack();
					} else {
						window.scrollTo(0, 0);
						setValue("location", undefined);

						setValue('amount', undefined);
						setLocais({
							label: undefined,
							value: undefined
						})
					}
				}
				else
					ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
			}
		},
		[form]
	);


	const [locais, setLocais] = useState([]);

	const handleMaterial = useCallback(
		material => {
			if (material !== undefined) {
				let units = ObjectHelper.parseValuesUnitsToSelect(
					material.unidade
				);
				setUnits(units);
				setValue("unity", null);
				setForm({ ...form, unity: null, material: material });
			} else {
				setValue("unity", null);
				setForm({ ...form, unity: null });
				setUnits([]);
			}
		},
		[form, setUnits, setForm, setValue]
	);

	const getMaterials = group => {
		props.getFamilyMaterials();
	};

	const apontamentoInteligente = async (params) => {
		if (params === null) return
		var filtro = {}
		filtro.safraId = safra === null ? ObjectHelper.primeiraSafra() : safra;
		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		var locais = [];
		params.forEach(item => {
			locais.push(item.value)
		})
		filtro.locaisId = locais;

		const { data } = await apontamentoMaquinaService.buscarServicosApontamentoInteligente(filtro);

		setGrupoServicos(data.result.grupoServicos);
		setServicosSugeridos(data.result.servicos);
	}

	const [grupoServicos, setGrupoServicos] = useState([]);
	const [safra, setSafra] = useState(null);

	const [servicosSugeridos, setServicosSugeridos] = useState([])

	const CustomGroupHeading = props => {
		return (
			<div
				className="group-heading-wrapper"
				onClick={() => handleHeaderClick(props.id)}
			>
				<components.GroupHeading {...props} />
			</div>
		);
	};

	const formatGroupLabel = data => (
		<div className="groupStyles">
			<span>{data.label}</span>
			<span className="groupBadgeStyles">{data.options.length}</span>
		</div>
	);

	const handleHeaderClick = id => {
		const node = document.querySelector(`#${id}`).parentElement
			.nextElementSibling;
		const classes = node.classList;
		if (classes.contains("grupoRecursosCollapsed")) {
			node.classList.remove("grupoRecursosCollapsed");
		} else {
			node.classList.add("grupoRecursosCollapsed");
		}
	};

	const groupedStyles = {
		menuPortal: base => ({ ...base, zIndex: 9999, width: "250px" }),
		groupHeading: provided => ({
			...provided,
			textTransform: "none",
			fontSize: 16,
			color: "black"
		}),
		option: provided => ({
			...provided
		})
	};


	const atualizarServicosSugeridos = (servicoTipoId, listaServicos) => {
		if (servicoTipoId === null) return
		var servicosSugeridosFiltrados = [];

		servicosSugeridos.forEach(item => {
			if (item.tipo === servicoTipoId)
				servicosSugeridosFiltrados.push(item);
		});

		let listaServicosFiltrados = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(listaServicos),
			"label"
		);

		if (servicosSugeridosFiltrados.length > 0) {
			var obj = [{
				label: "Serviços Sugeridos",
				options: servicosSugeridosFiltrados
			},
			{
				label: "Todos",
				options: listaServicosFiltrados
			}]
			setOptionServicos(obj)
		} else {
			setOptionServicos(listaServicosFiltrados)
		}
	}

	const [optionServicos, setOptionServicos] = useState([])

	const getServiceByIdGroup = async (grupoServicoId) => {
		let { data } = await servicoService.buscarPorGrupoId(
			JSON.parse(localStorage.getItem("fazendaId")).value,
			grupoServicoId
		);

		atualizarServicosSugeridos(grupoServicoId, data.result)
	}

	const detalhesDeInsumos = () => {
		return (
			<div className="col-md-12 mt-3">
				<div className="col-md-12 mt-3">
					<h6 style={{ fontWeight: "bold" }}>{`Detalhes da Receita:`}</h6>
				</div>
				<div className="col-md-12 mt-3">
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<div className="col-md-12 mt-3">
								<h6 style={{ fontWeight: "bold" }}>{`Total para ${insumoSelecionado.volumeMistura} litros de mistura`}</h6>
							</div>
							<List dense={true}>
								{insumoSelecionado.materiaisAuxiliares.map(item =>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize='small' />
										</ListItemIcon>
										<ListItemText
											primary={`${item.quantidade} ${item.unidade.sigla} de ${item.material ? item.material.nome : item.insumosAux.nome}`}
										/>
									</ListItem>
								)}
							</List>
						</Grid>
						<Grid item xs={4}>
							<div className="col-md-12 mt-3">
								<h6 style={{ fontWeight: "bold" }}>{`Total para ${quantidade} ${unidadeSelecionada.label} de mistura`}</h6>
							</div>
							<List dense={true}>
								{insumoSelecionado.materiaisAuxiliares.map(item =>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecordIcon fontSize='small' />
										</ListItemIcon>
										<ListItemText
											primary={`${(Math.round((((item.quantidade * (quantidade / insumoSelecionado.volumeMistura)) + Number.EPSILON) / (unidadeSelecionada.valorConversao ? unidadeSelecionada.valorConversao : 1)) * 100) / 100)
												} ${item.unidade.sigla} de ${item.material ? item.material.nome : item.insumosAux.nome}`}
										/>
									</ListItem>
								)}
							</List>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}

	const [selectLocais, setSelectLocais] = useState([])
	const buscarLocais = async (param) => {
		setForm({ ...form, safra: param });
		const { data } = await safraService.locais(
			JSON.parse(localStorage.getItem('fazendaId')).value,
			param ? param.value : ObjectHelper.primeiraSafra(),
			true);

		let locais = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setSelectLocais(locais);
	}

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="row">
					<div className="col">
						<Title>
							{visualize
								? "Visualizar "
								: params.id
									? "Editar "
									: "Cadastrar "}{" "}
							apontamento de insumos
						</Title>
					</div>
				</div>
				<CardStyle className="card mt-0">
					<div className="card-header row justify-content-start col-md-12">
						<div className="card-header col-md-4">
							<label className="label">Data:*</label>
							<DatePicker
								name="date"
								selected={date}
								placeholderText="Informe a data inicial"
								className="form-control bmd-form-group"
								dateFormat="dd/MM/yyyy"
								autoComplete="off"
								onChange={date => {
									setValue("date", date ? date : "");
									setDate(date ? date : "");
								}}
								value={date}
								ref={() =>
									register(
										{ name: "date" },
										{ required: true }
									)
								}
								locale={pt}
								disabled={visualize}
							/>
							{errors.date && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						{
							JSON.parse(localStorage.getItem("safraId")).length > 1 &&
							<div className="card-header col-md-4">
								<label className="label">Safras:*</label>
								<Select
									id="responsible"
									name="responsible"
									htmlFor="responsible"
									placeholder="Selecionar"
									isDisabled={params.id ? true : false}
									className="select-style ajust-label-padrao disabled-select"
									options={JSON.parse(localStorage.getItem("safraId"))}
									value={form.safra}
									onChange={item => {
										setValue("safra", item.value);
										buscarLocais(item);
										setSafra(item.value)
									}}
									ref={() =>
										register(
											{ name: "safra" },
											{ required: true }
										)
									}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.safra && (
									<span className="span-errors">
										Campo obrigatório
									</span>
								)}
							</div>
						}
						<div className="card-header col-md-4">
							<label className="label">Locais:*</label>
							<Select
								id="location"
								name="location"
								placeholder="Selecionar"
								isDisabled={visualize}
								className="select-style ajust-label-padrao disabled-select"
								options={selectLocais}
								value={locais}
								onChange={item => {
									setValue("location", item);
									setLocais(item);
									apontamentoInteligente([item]);
								}}
								ref={() =>
									register(
										{ name: "location" },
										{ required: true }
									)
								}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.location && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-4">
							<label className="label">Responsáveis:*</label>
							<Select
								id="responsible"
								name="responsible"
								htmlFor="responsible"
								placeholder="Selecionar"
								isDisabled={visualize}
								className="select-style ajust-label-padrao disabled-select"
								options={props.responsible}
								value={form.responsible}
								onChange={item => {
									setValue("responsible", item.value);
									setForm({ ...form, responsible: item });
								}}
								ref={() =>
									register(
										{ name: "responsible" },
										{ required: true }
									)
								}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.responsible && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-4">
							<label className="label"> Grupos de Serviço:* </label>
							<Select
								styles={groupedStyles}
								menuPortalTarget={document.body}
								components={{ GroupHeading: CustomGroupHeading }}
								formatGroupLabel={formatGroupLabel}
								className="select-style ajust-label-padrao disabled-select"
								name="serviceGroup"
								placeholder='Selecionar'
								isDisabled={visualize}
								options={grupoServicos.length > 0 ? [{
									label: "Grupos de Serviço Sugeridos",
									options: grupoServicos
								},
								{
									label: "Todos",
									options: props.serviceGroup
								}] : props.serviceGroup}
								value={form.serviceGroup}
								ref={() => register({ name: "serviceGroup" }, { required: true })}
								onChange={item => {
									setValue("serviceGroup", item.value);
									setForm({
										...form,
										serviceGroup: item,
										service: null
									});
									handleGroupServie(item);
									setValue("service", null);
									getServiceByIdGroup(item.value);
								}}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.serviceGroup && <span className="span-errors">Campo obrigatório</span>}

						</div>


						<div className="card-header col-md-4">
							<label className="label"> Serviços:* </label>
							<Select
								styles={groupedStyles}
								menuPortalTarget={document.body}
								components={{ GroupHeading: CustomGroupHeading }}
								formatGroupLabel={formatGroupLabel}
								className="select-style ajust-label-padrao disabled-select"
								name="service"
								placeholder='Selecionar'
								isDisabled={visualize || optionServicos.length < 1}
								options={optionServicos}
								ref={() => register({ name: "service" }, { required: true })}
								value={form.service ? form.service : null}
								onChange={item => {

									setValue("service", item.value);
									setForm({ ...form, service: item });
								}}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.service && <span className="span-errors">Campo obrigatório</span>}
						</div>
						<div className="card-header col-md-4">
							<label className="label">Grupos de insumo:*</label>
							<Select
								id="familyMaterial"
								name="familyMaterial"
								placeholder="Selecionar"
								isDisabled={
									visualize ||
									familyMaterialsOptions.length < 1
								}
								className="select-style ajust-label-padrao disabled-select"
								options={familyMaterialsOptions}
								value={form.familyMaterial}
								onChange={item => {
									setValue("familyMaterial", item.value);
									setForm({
										...form,
										familyMaterial: item,
										material: null,
										unity: null
									});
									setValue("material", null);
									setValue("unity", null);
								}}
								ref={() =>
									register(
										{ name: "familyMaterial" },
										{ required: true }
									)
								}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.familyMaterial && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-4">
							<label className="label">Insumos:*</label>
							<Select
								id="material"
								name="material"
								placeholder="Selecionar"
								isDisabled={
									visualize || props.materials.length === 0
								}
								className="select-style ajust-label-padrao disabled-select"
								options={materials}
								value={form.material}
								onChange={item => {
									setValue("material", item.value);
									setInsumoSelecionado(item)
									setForm({ ...form, material: item });
									handleMaterial(item);
								}}
								ref={() =>
									register(
										{ name: "material" },
										{ required: true }
									)
								}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.material && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-4">
							<label className="label">Unidades:*</label>
							<Select
								name="unity"
								placeholder="Selecionar"
								className="select-style ajust-label-padrao disabled-select"
								isDisabled={!form.material || visualize}
								options={units}
								value={form.unity}
								onChange={item => {
									setValue("unity", item.value);
									setUnidadeSelecionada(item);
									setForm({ ...form, unity: item });
								}}
								ref={() =>
									register(
										{ name: "unity" },
										{ required: true }
									)
								}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.unity && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-4">
							<label className="label">Quantidade:*</label>
							<input
								type="number"
								name="amount"
								disabled={visualize}
								placeholder="Quantidade"
								className="form-control"
								autoComplete="off"
								onChange={item => {
									setValue("amount", item.target.value);
									setQuantidade(item.target.value)
								}}
								ref={() =>
									register(
										{ name: "amount" },
										{ required: true }
									)
								}
							/>
							{errors.amount && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-12">
							<label className="label">Descrição:</label>
							<input
								type="textarea"
								name="description"
								disabled={visualize}
								className="form-control"
								autoComplete="off"
								ref={register({ required: false })}
							/>
							{errors.textArea && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="card-header col-md-12">
							{
								insumoSelecionado && insumoSelecionado.composto && quantidade && unidadeSelecionada &&
								detalhesDeInsumos()
							}
						</div>
					</div>
				</CardStyle>
				<br></br>
				<button className="btn btn-primary active pull-right" type="submit" onClick={() => submitForm(false)} hidden={props.flagVisualizar}>
					<i className='fas fa-check' /> {params.id ? 'Salvar' : 'Cadastrar'}
				</button>
				<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => props.history.goBack()}>
					<i className='fas fa-close' /> {visualize ? 'Voltar' : 'Cancelar'}
				</button>
				{
					!params.id && <button className="btn btn-primary active pull-right" type="submit" name="button_1" onClick={() => submitForm(true)} hidden={props.flagVisualizar} style={{ marginRight: 10 }}>
						<i className='fas fa-check' /> {params.id ? 'Salvar e Voltar' : 'Cadastrar e voltar'}
					</button>
				}

			</form>
		</>
	);
}

const mapStateToProps = state => ({
	...state,
	responsible: state.appointmentEntrys.responsible
		? Utils.removeInactiveToSelect(
			ObjectHelper.getValuesParseToSelect(
				state.appointmentEntrys.responsible.items
			)
		)
		: [],
	locations: state.appointmentEntrys.locations
		? ObjectHelper.getValuesParseToSelect(state.appointmentEntrys.locations)
		: [],
	serviceGroup: state.appointmentEntrys.serviceGroup
		? Utils.removeInactiveToSelect(
			ObjectHelper.getValuesParseToSelect(
				state.appointmentEntrys.serviceGroup.items
			)
		)
		: [],
	services: state.appointmentEntrys.services
		? Utils.removeInactiveToSelect(
			ObjectHelper.getValuesParseToSelect(
				state.appointmentEntrys.services
			)
		)
		: [],
	familyMaterials: state.appointmentEntrys.familyMaterials
		? Utils.removeInactiveToSelect(
			ObjectHelper.getValuesParseToSelect(
				state.appointmentEntrys.familyMaterials.items
			)
		)
		: [],
	materials: state.appointmentEntrys.materials
		? Utils.removeInactiveToSelect(
			ObjectHelper.getValuesMaterialParseToSelect(
				state.appointmentEntrys.materials
			)
		)
		: [],
	entryAppointment: state.appointmentEntrys.entryAppointment
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EntryRegister);
