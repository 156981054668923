import React, { useState, useEffect } from "react";
import { CardStyle } from "components/CardStyle/cardstyle.component";
import { bindActionCreators } from "redux";
import { Creators as SearchCreators } from "components/AdvancedSearch/store/advancedSearch.ducks";
import { Creators as ListingEntrysCreators } from "./store/entry.appointment.ducks";
import { connect } from "react-redux";
import Utils from "utils/utils";
import Toolbar from "components/BasicPageTopBar/Toolbar";
import safraService from "services/safraService";
import grupoServicoService from "services/grupoServicoService";
import grupoLocaisService from "services/grupoLocaisService";
import servicoService from "services/servicoService";
import ObjectHelper from "utils/objectHelper";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from 'material-table';
import { MTablePagination } from 'material-table';
import Grid from '@material-ui/core/Grid';
import EditCompleteIcon from '@material-ui/icons/EditOutlined';

function ListingEntrysAppointment(props) {
	const actions = [
		{
			icon: EditCompleteIcon,
			tooltip: 'Edição',
			onClick: (event, rowData) => edit(rowData.id),
		},
	];

	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState(
		useSelector(state => state.apontamentoIn.filtroListagem)
	);

	const dispatch = useDispatch();
	const [locaisFiltro, setLocaisFiltro] = useState([]);
	const [grupoLocaisFiltro, setGrupoLocaisFiltro] = useState([]);
	const [gruposServicoFiltro, setGruposServicoFiltro] = useState([]);
	const [servicosFiltro, setServicosFiltro] = useState([]);

	const getLocaisFiltro = async (fazendaId, safraId) => {
		let { data } = await safraService.locais(fazendaId, safraId, true);

		let newLocais = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setLocaisFiltro(newLocais);
	};

	const getGruposLocaisFiltro = async (fazendaId) => {
		let { data } = await grupoLocaisService.getAll({
			fazendaId: fazendaId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGrupoLocaisFiltro(newGrupos);
	};

	const getGruposServicoFiltro = async (fazendaId, safraId) => {
		let { data } = await grupoServicoService.buscarTodos({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newGrupos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setGruposServicoFiltro(newGrupos);
	};

	const getServicosFiltro = async (fazendaId, safraId) => {
		let { data } = await servicoService.buscarTodas({
			fazendaId: fazendaId,
			safraId: safraId,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	useEffect(() => {
		async function initialize() {
			try {
				verificadorDeExistenciaDeSafra();
				await setCurrentPage(filter.page);
				filter.Descedescending = true;
				let safraId = ObjectHelper.primeiraSafra();
				let fazendaId = JSON.parse(localStorage.getItem("fazendaId"))
					.value;
				await getGruposLocaisFiltro(fazendaId);
				await getLocaisFiltro(fazendaId, safraId);
				await getGruposServicoFiltro(fazendaId, safraId);
				await getServicosFiltro(fazendaId, safraId);
				await props.getAppointmentEntrys(filter);
			} catch (error) { }
		}
		initialize();
	}, []);

	const handleFilter = async newFilter => {
		newFilter.descedescending = true;
		newFilter.page = 1;

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_IN",
			filtroListagem: newFilter
		});

		await setFilter(newFilter);
		props.getAppointmentEntrys(newFilter);
	};

	const edit = item => {
		props.history.push({
			pathname: `/apontamento/insumos/editar/${item}`
		});
	};


	const newAppointment = () => {
		props.history.push({
			pathname: `/apontamento/insumos/cadastro`
		});
	};

	const changePage = params => {
		window.scrollTo(0, 0);

		setCurrentPage(params);
		let newFilter = filter;
		newFilter.page = params;
		setFilter(newFilter);
		props.getAppointmentEntrys(newFilter);

		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_IN",
			filtroListagem: newFilter
		});
	};

	const deleteAppointment = async (deleteItem) => {
		async function del() {
			await props.deleteAppointmentEntry(deleteItem.id);
			setTimeout(function () {
				changePage(1);
			}, 800);
		}
		del();
	};

	const trocaGrupoServico = (servicoTipoId) => {
		let novoFiltro = filter;
		novoFiltro.servicoId = "";
		novoFiltro.servicoTipoId = servicoTipoId;
		dispatch({
			type: "SET_FILTRO_LISTAGEM_APONTAMENTO_IN",
			filtroListagem: novoFiltro
		});
		if (servicoTipoId !== "") {
			getFiltroServicosByGrupoId(servicoTipoId);
		} else {
			let safraId = JSON.parse(localStorage.getItem("safraId")) ? ObjectHelper.primeiraSafra() : "";
			let fazendaId = JSON.parse(localStorage.getItem("safraId")) ? JSON.parse(localStorage.getItem("fazendaId")).value : "";
			getServicosFiltro(fazendaId, safraId);
		}
	};

	const getFiltroServicosByGrupoId = async grupoServico => {
		let { data } = await servicoService.buscarPorGrupoId(
			filter.fazendaId,
			grupoServico
		);

		let newServicos = await Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setServicosFiltro(newServicos);
	};

	useEffect(() => {
		if (filter.servicoTipoId) {
			getFiltroServicosByGrupoId(filter.servicoTipoId)
		}
	}, []);

	const [columns, setColumns] = useState([]);
	const verificadorDeExistenciaDeSafra = () => {
		let array = [
			{ title: "Responsável", field: "responsavel", editable: 'never' },
			{ title: "Data", field: "data", editable: 'never' },
			{ title: "Insumo", field: "materiais", editable: 'never' },
			{ title: "Serviço realizado", field: "servico", editable: 'never' },
			{ title: "Local", field: "local", editable: 'never' },
			{ title: "Unidade", field: "unidade", editable: 'never' },
			{ title: "Quantidade", field: "quantidade", editable: 'never' },
		]

		if (JSON.parse(localStorage.getItem('safraId')).length >= 2)
			array.splice(2, 0, { title: "Atividade", field: "atividade", editable: 'never' });

		if (JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2)
			array.splice(2, 0, { title: "Safra", field: "safra", editable: 'never' });

		setColumns(array);
	}




	return (
		<>
			<Toolbar
				style={{ marginTop: '-20px' }}
				safras={JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2}
				atividades={JSON.parse(localStorage.getItem('safraId')).length >= 2}
				handleFiltro={handleFilter}
				filtroInicial={filter}
				onClickAdd={() => newAppointment()}
				filtroNome
				filtroGrupoLocais={grupoLocaisFiltro}
				filtroLocais={locaisFiltro}
				filtroGruposServico={gruposServicoFiltro}
				filtroServicos={servicosFiltro}
				getFiltroServicosByGrupoId={e => getFiltroServicosByGrupoId(e)}
				filtroPeriodo
				trocaGrupoServico={trocaGrupoServico}
				temFiltroAvancado
			/>
			<CardStyle className="card mt-4">
				<MaterialTable
					title="Apontamentos de Insumos"
					columns={columns}
					data={props.appointments ? props.appointments.items : []}
					options={{
						paging: true,
						pageSize: 10,
						search: false,
						actionsColumnIndex: -1,
					}}
					components={{
						Pagination: (paginat) => {
							const {
								classes,
								headerIsHidden,
								...other
							} = paginat;
							return (
								<>
									<Grid
										item
										container
										xs={12}
										justify="flex-end"
									>
										<MTablePagination
											{...other}
											onChangePage={(event, page) =>
												changePage(page + 1)
											}
											page={currentPage - 1}
											count={props.appointments ? props.appointments.total : 0}
											localization={{
												labelRowsSelect: 'recursos',
												labelDisplayedRows:
													'{from}-{to} de {count}',
											}}
										/>
									</Grid>
								</>
							);
						},
					}}
					actions={actions}
					editable={{
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								deleteAppointment(oldData);
								resolve();
							})
					}}
					localization={{
						header: {
							actions: 'Ações'
						},
						body: {
							emptyDataSourceMessage:
								'Nenhum registro para exibir',
							addTooltip: 'Adicionar',
							deleteTooltip: 'Excluir',
							editTooltip: 'Edição rápida',
							editRow: {
								deleteText: 'Deseja excluir essa linha?',
								cancelTooltip: 'Cancelar',
								saveTooltip: 'Confirmar',
							},
						},

					}}
				/>
			</CardStyle>
		</>
	);
}

const mapStateToProps = state => {
	return {
		appointments: state.appointmentEntrys.appointmentEntrys
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ ...SearchCreators, ...ListingEntrysCreators },
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListingEntrysAppointment);
