import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import pt from "date-fns/locale/pt";
import DatePicker from "react-datepicker";
import AccordionFilter from "../../components/AccordionSectionFilter/Accordion";
import ObjectHelper from "utils/objectHelper";
import SimpleSearch from "../../components/SimpleSearch/simplesSearch";
import { bindActionCreators } from "redux";
import MaquinaTipoService from "../../services/tipoMaquinaService";
import FamiliaMaterialService from "../../services/familiaMaterialService";
import PessoaTipoService from "../../services/categoriaService";
import { Creators as ModulosCreators } from "./store/advancedSearch.ducks";
import { connect } from "react-redux";
import Utils from "../../utils/utils";
import ServicoService from "services/servicoService";
import servicoTipoService from "services/grupoServicoService";
import "./advancedSearch.css";
import RecursosService from "../../services/recursosService";
import safraService from "services/safraService";

function AdvancedSearch(props) {
	const { register, setValue, getValues, reset } = useForm({});
	const [validPeriod, setValidPeriod] = useState({ text: "", invalid: true });
	const [initialDate, setInitialDate] = useState(null);
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [classes, setClasses] = useState([]);
	const [operator, setOperator] = useState("");
	const [finalDate, setFinalDate] = useState(null);

	//popular os selects
	const [locais, setLocais] = useState([]);
	const [selectLocais, setSelectLocais] = useState(null);
	const [servicos, setServicos] = useState([]);
	const [selectServicos, setSelectServicos] = useState(null);
	const [servicosTipo, setServicosTipo] = useState([]);
	const [selectServicosTipo, setSelectServicosTipo] = useState(null);
	const [recursos, setRecursos] = useState([]);
	const [classeSelecionado, setClasseSelecionado] = useState();
	const [recursoSelecionado, setRecursoSelecionado] = useState();

	const [text, setText] = useState("");
	const [showSimpleSearch, setShowSimpleSearch] = useState(
		JSON.parse(localStorage.getItem("planejamentoParams")) !== null
			? JSON.parse(localStorage.getItem("planejamentoParams"))
				.showSimpleSearch
			: props.pagination
				? props.pagination.showSimpleSearch
				: true
	);
	const [firstLoad, setFirstLoad] = useState(true);

	useEffect(() => {
		if (localStorage.getItem("fazendaId")) {
			let params = {
				toFilter: true
			};
			init(params);
		}
	}, []);

	function init(params) {
		buscarLocais();
		buscarServicosTipo();
		props.getRecursosTipo(params);
		props.getRecursos(params);
		var filtro = JSON.parse(localStorage.getItem("planejamentoParams"));
		if ((filtro !== null) & (filtro !== undefined)) {
			setShowSimpleSearch(filtro.showSimpleSearch);
		}
	}

	async function buscarLocais() {
		let safraId = ObjectHelper.primeiraSafra();
		let fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		const { data } = await safraService.locais(fazendaId, safraId);
		let locais = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result),
			"label"
		);
		setLocais(locais);

		var filtro = JSON.parse(localStorage.getItem("planejamentoParams"));
		if (filtro !== null && filtro.localId !== "") {
			let filtroStorage = locais.find(
				x => x.value.toString() === filtro.localId.toString()
			);
			if (filtroStorage) {
				setSelectLocais(filtroStorage);
				setValue("locationId", filtroStorage.value);
			}
		}
	}

	async function buscarServicosTipo() {
		const { data } = await servicoTipoService.buscarTodos({
			fazendaId:
				JSON.parse(localStorage.getItem("fazendaId")) !== null
					? JSON.parse(localStorage.getItem("fazendaId")).value
					: null,
			page: 1,
			pageSize: 99999,
			order: "Nome"
		});
		let servicosTipo = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(data.result.items)
		);
		setServicosTipo(servicosTipo);
		var filtro = JSON.parse(localStorage.getItem("planejamentoParams"));
		if (filtro !== null && filtro.servicoTipoId !== "") {
			var filtroStorage = servicosTipo.find(
				x => x.value === filtro.servicoTipoId
			);
			setSelectServicosTipo(filtroStorage);
			setValue("serviceTypeId", filtroStorage.value);
			await buscarServicos(filtroStorage.value);
		}
	}

	const buscarServicos = async servicoTipoId => {
		var fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		const { data } = await ServicoService.buscarPorGrupoId(
			fazendaId,
			servicoTipoId
		);
		setServicos(
			Utils.ordernar(ObjectHelper.getValuesParseToSelect(data.result))
		);
		var filtro = JSON.parse(localStorage.getItem("planejamentoParams"));
		if (filtro !== null && filtro.servicoId !== "") {
			var servicos = Utils.ordernar(
				ObjectHelper.getValuesParseToSelect(data.result)
			).find(x => x.value === filtro.servicoId);
			if (servicos !== undefined) {
				setValue("serviceId", servicos.value);
				setSelectServicos(servicos);
			}
		}
	};

	useEffect(() => {
		if (props.pagination) {
			let newFilter = { ...props.pagination };
			if (!newFilter.showSimpleSearch)
				setShowSimpleSearch(newFilter.showSimpleSearch);
			setText(newFilter.filtro);
			if (props.operator) {
				props.operator ? setOperator(newFilter.nome) : setOperator("");
			} else {
				props.name ? setName(newFilter.nome) : setName("");
			}

			setValue("locationId", newFilter.localId);
		}
	}, [props.pagination]);

	const validatePeriod = useCallback(() => {
		setValidPeriod({ text: "", invalid: false });
		if (initialDate && finalDate && initialDate > finalDate) {
			setValidPeriod({
				text: "Período inicial não pode ser maior que o final",
				invalid: true
			});
			return false;
		} else {
			return true;
		}
	}, [setValidPeriod, initialDate, finalDate]);

	const handleShowSimpleSearch = show => {
		setShowSimpleSearch(show);
	};

	const submitForm = async manterPagina => {
		if (manterPagina) {
			props.handleFilter(manterPagina);
		} else if (validatePeriod()) {
			let filter = {
				dataApontamento: "",
				maquinaId: "",
				page: 1,
				pageSize: 10,
				order: Utils.parsePropertToFilter(""),
				descedescending: false,
				filtro: text,
				localId: selectLocais ? selectLocais.value : "",
				servicoId: selectServicos ? selectServicos.value : "",
				servicoTipoId: selectServicosTipo
					? selectServicosTipo.value
					: "",
				periodoInicial: initialDate ? initialDate : "",
				periodoFinal: finalDate ? finalDate : "",
				nome: "",
				codigo: code ? code : "",
				recursosId: getValues().resourceId
					? getValues().resourceId
					: "",
				recursosTipoId: getValues().resourceTypeId
					? getValues().resourceTypeId
					: "",
				recursosClasseId: getValues().resourceClassId
					? getValues().resourceClassId
					: "",
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				safraId: JSON.parse(localStorage.getItem("safraId"))
					? ObjectHelper.primeiraSafra()
					: ""
			};

			if (props.operator) {
				filter.nome = operator ? operator.toLowerCase() : "";
			} else {
				filter.nome = name ? name.toLowerCase() : "";
			}
			filter.showSimpleSearch = showSimpleSearch;
			props.handleFilter(filter);
			if (props.planejamento) {
				localStorage.setItem(
					"planejamentoParams",
					JSON.stringify(filter)
				);
			}
		}
	};

	useEffect(() => {
		if (props.planejamento === undefined) {
			submitForm();
		} else {
			var filtro = JSON.parse(localStorage.getItem("planejamentoParams"));
			if (filtro !== undefined) {
				submitForm(filtro);
			}
		}
	}, [text]);

	const changeRecursoTipo = async e => {
		if (e) {
			let param = {
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				page: 1,
				pageSize: 1000
			};

			let data = null;

			if (e.codigo === "MA")
				data = await MaquinaTipoService.buscarTodas(param);
			if (e.codigo === "IN" || e.codigo === "RE")
				data = await FamiliaMaterialService.buscarTodas(param);
			if (e.codigo === "MO")
				data = await PessoaTipoService.buscarTodas(param);

			setClasses(
				Utils.getValuesParseToSelect(
					data.data ? data.data.result.items : []
				)
			);
		} else {
			setClasses(Utils.getValuesParseToSelect([]));
		}
	};

	useEffect(() => {
		if (!showSimpleSearch) {
			setText("");
		} else if (showSimpleSearch) {
			reset({
				serviceTypeId: undefined,
				serviceId: undefined,
				locationId: undefined,
				initialDate: undefined,
				finalDate: undefined,
				resourceTypeId: undefined,
				resourceClassId: undefined,
				resourceId: undefined
			});
			setValue("initialDate", "");
			setInitialDate("");
			setValue("finalDate", "");
			setFinalDate("");
			setValue("locationId", "");
			setName("");
			setCode("");
			if (!firstLoad) {
				submitForm();
			}
			setFirstLoad(false);
		}
	}, [showSimpleSearch]);

	useEffect(() => {
		if (props.pagination !== undefined) {
			setText(props.pagination.filtro);
		}
	}, []);

	const buscarRecursos = async id => {
		if (id) {
			const { data } = await RecursosService.getClasseId(id);
			setRecursos(data.result);
		} else {
			setRecursos([]);
		}
	};

	return (
		<div className="col-md-12">
			<SimpleSearch
				placeHolder="Pesquisar"
				value={text}
				submit={e => setText(e)}
				show={showSimpleSearch}
			/>
			<AccordionFilter
				handleShow={show => handleShowSimpleSearch(show)}
				isOpen={!showSimpleSearch}
			>
				<form>
					<div
						className="row justify-content-start"
						style={{ paddingTop: "inherit" }}
						onKeyDown={e =>
							e.keyCode === 13 ? () => submitForm() : undefined
						}
					>
						<div className="row col-md-12">
							{props.name && (
								<div className="col-md-4">
									<label className="label">
										Nome{" "}
										{props.responsible
											? " do responsável:"
											: ":"}
									</label>
									<input
										type={"text"}
										onChange={event => {
											setName(event.target.value);
										}}
										ref={() =>
											register({
												name: "name",
												required: false
											})
										}
										className="form-control bmd-form-group"
										name="name"
										autoComplete="off"
										value={name}
										placeholder={"Insira um nome"}
									/>
								</div>
							)}
							{props.code && (
								<div className="col-md-4">
									<label className="label">Código:</label>
									<input
										type={"text"}
										onChange={event => {
											setCode(event.target.value);
										}}
										ref={() =>
											register({
												name: "code",
												required: false
											})
										}
										className="form-control bmd-form-group"
										name="code"
										autoComplete="off"
										value={code}
										placeholder={"Insira um código"}
									/>
								</div>
							)}
							{props.resourceType && (
								<div className="col-md-4">
									<label className="label">
										Tipo de recurso:
									</label>
									<Select
										id="resourceTypeId"
										name="resourceTypeId"
										isClearable={true}
										placeholder="Selecionar"
										className="select-style ajust-label-padrao disabled-select"
										options={
											props.resourcesTypes
												? Utils.ordernar(
													ObjectHelper.getRecursoTipoParseToSelect(
														props.showOnlyResourceTypes &&
															props
																.showOnlyResourceTypes
																.length > 0
															? props.resourcesTypes.filter(
																item =>
																	props.showOnlyResourceTypes.includes(
																		item.codigo
																	)
															)
															: props.resourcesTypes
													),
													"label"
												)
												: []
										}
										onChange={resource => {
											setValue(
												"resourceTypeId",
												resource
													? resource.value
													: undefined
											);
											setValue(
												"resourceClassId",
												undefined
											);
											setValue("resourceId", undefined);
											setClasseSelecionado(null);
											setRecursoSelecionado(null);
											changeRecursoTipo(resource);
										}}
										ref={() =>
											register({
												name: "resourceTypeId",
												required: false
											})
										}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
									/>
								</div>
							)}
							{props.resourceClass && (
								<div className="col-md-4">
									<label className="label">
										Classe do recurso:
									</label>
									<Select
										id="resourceClassId"
										name="resourceClassId"
										isDisabled={
											getValues().resourceTypeId ===
											undefined
										}
										isClearable={true}
										placeholder="Selecionar"
										className="select-style ajust-label-padrao disabled-select"
										options={
											Utils.ordernar(classes, "label") ||
											[]
										}
										value={classeSelecionado}
										onChange={resourceClass => {
											setValue(
												"resourceClassId",
												resourceClass
													? resourceClass.value
													: undefined
											);
											setValue("resourceId", undefined);
											setClasseSelecionado(resourceClass);
											setRecursoSelecionado(null);
											buscarRecursos(
												resourceClass
													? resourceClass.value
													: undefined
											);
										}}
										ref={() =>
											register({
												name: "resourceClassId",
												required: false
											})
										}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
									/>
								</div>
							)}
							{props.resource && (
								<div className="col-md-4">
									<label className="label">Recurso:</label>
									<Select
										id="resourceId"
										name="resourceId"
										isDisabled={
											getValues().resourceClassId ===
											undefined
										}
										isClearable={true}
										placeholder="Selecionar"
										className="select-style ajust-label-padrao disabled-select"
										options={
											Utils.ordernar(recursos, "label") ||
											[]
										}
										value={recursoSelecionado}
										onChange={resource => {
											setValue(
												"resourceId",
												resource
													? resource.value
													: undefined
											);
											setRecursoSelecionado(resource);
										}}
										ref={() =>
											register({
												name: "resourceId",
												required: false
											})
										}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
									/>
								</div>
							)}
							{props.operator && (
								<div className="col-md-4">
									<label className="label">Operador:</label>
									<input
										type={"text"}
										onChange={event => {
											setOperator(event.target.value);
										}}
										ref={() =>
											register({
												name: "operator",
												required: false
											})
										}
										className="form-control bmd-form-group"
										name="operator"
										value={operator}
										autoComplete="off"
										placeholder={"Nome do operador"}
									/>
								</div>
							)}
							{props.firstPeriod && (
								<div className="col-md-4">
									<label className="label">
										Período inicial:
									</label>
									<DatePicker
										name="initialDate"
										htmlFor="initialDate"
										selected={initialDate}
										isClearable={true}
										placeholderText="Informe a data inicial"
										className="form-control bmd-form-group"
										dateFormat="dd/MM/yyyy"
										autoComplete="off"
										onChange={date => {
											localStorage.removeItem(
												"planejamentoParams"
											);
											setValue("initialDate", date);
											setInitialDate(date);
										}}
										onBlur={() => validatePeriod()}
										value={initialDate}
										ref={() =>
											register({
												name: "initialDate",
												required: false
											})
										}
										locale={pt}
									/>
									{validPeriod.invalid && (
										<span className="span-errors">
											{validPeriod.text}
										</span>
									)}{" "}
									<br />
								</div>
							)}
							{props.finalPeriod && (
								<div className="col-md-4">
									<label className="label">
										Período final:
									</label>
									<DatePicker
										name="finalDate"
										htmlFor="finalDate"
										selected={finalDate}
										isClearable={true}
										placeholderText="Informe a data inicial"
										className="form-control bmd-form-group"
										dateFormat="dd/MM/yyyy"
										autoComplete="off"
										onChange={date => {
											localStorage.removeItem(
												"planejamentoParams"
											);
											setValue("finalDate", date);
											setFinalDate(date);
										}}
										onBlur={() => validatePeriod()}
										value={finalDate}
										ref={() =>
											register({
												name: "finalDate",
												required: false
											})
										}
										locale={pt}
									/>
								</div>
							)}
						</div>
						<div className="row col-md-12 mt-3">
							{props.location && (
								<div className="col-md-4">
									<label
										className="label"
										htmlFor="locationId"
									>
										Locais:
									</label>
									<Select
										id="locationId"
										name="locationId"
										isClearable={true}
										placeholder="Selecionar"
										value={selectLocais}
										className="select-style ajust-label-padrao disabled-select"
										onChange={location => {
											localStorage.removeItem(
												"planejamentoParams"
											);
											setSelectLocais(location);
											setValue(
												"locationId",
												location
													? location.value
													: undefined
											);
										}}
										ref={() =>
											register({
												name: "locationId",
												required: false
											})
										}
										options={locais}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
									/>
								</div>
							)}
							{props.serviceGroup && (
								<div className="col-md-4">
									<label
										className="label"
										htmlFor="serviceTypeId"
									>
										Grupo de Serviços:
									</label>
									<Select
										id="serviceTypeId"
										name="serviceTypeId"
										isClearable={true}
										placeholder="Selecionar"
										className="select-style ajust-label-padrao disabled-select"
										onChange={serviceType => {
											localStorage.removeItem(
												"planejamentoParams"
											);
											setValue(
												"serviceTypeId",
												serviceType
													? serviceType.value
													: undefined
											);
											if (serviceType !== null) {
												buscarServicos(
													serviceType.value
												);
											} else {
												setSelectServicos(null);
												setValue(
													"serviceId",
													undefined
												);
											}
											setSelectServicosTipo(serviceType);
										}}
										ref={() =>
											register({
												name: "serviceTypeId",
												required: false
											})
										}
										options={servicosTipo}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
										value={selectServicosTipo}
									/>
								</div>
							)}
							{props.service && (
								<div className="col-md-4">
									<label className="label">Serviços:</label>
									<Select
										id="serviceId"
										name="serviceId"
										isDisabled={
											getValues().serviceTypeId ===
											undefined
										}
										isClearable={true}
										placeholder="Selecionar"
										value={selectServicos}
										className="select-style ajust-label-padrao disabled-select"
										options={servicos}
										onChange={service => {
											localStorage.removeItem(
												"planejamentoParams"
											);
											setSelectServicos(service);
											setValue(
												"serviceId",
												service !== null
													? service.value
													: undefined
											);
										}}
										ref={() =>
											register({
												name: "serviceId",
												required: false
											})
										}
										theme={theme => ({
											...theme,
											borderRadius: 0
										})}
									/>
								</div>
							)}
						</div>
					</div>
					<button
						className="btn btn-primary active pull-right"
						onClick={() => submitForm()}
						style={{ marginBottom: 15, marginTop: -35 }}
					>
						<i className="fas fa-search" /> Buscar
					</button>
				</form>
			</AccordionFilter>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		resourceClasses: state.advancedSearch.resourceClasses,
		resourcesTypes: state.advancedSearch.resourcesTypes,
		resources: state.advancedSearch.resources.items
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
