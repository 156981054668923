import React from 'react';
import Modal from 'react-bootstrap/es/Modal'
import { useForm } from 'react-hook-form';

export default function CommonModal(props) {

	const { register, handleSubmit, errors, getValues } = useForm({});

	const submitForm = values => {
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		props.modalClose(getValues())
	}

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<Modal dialogClassName="modal-dialog-md" show={props.show} onHide={() => props.close()}>
					<Modal.Header closeButton >
						<Modal.Title>{props.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='card-header row justify-content-start col-md-12'>
							<div className="card-header col-md-12">
								<label className="label">
									Nome :*
							</label>
								<input
									id="nome"
									name="nome"
									type="text"
									placeholder='Selecionar'
									className="form-control"
									autoComplete="off"
									ref={register({ required: true })}
								/>
								{errors.nome && <span className="span-errors">Campo obrigatório</span>}

							</div>
							<div className="card-header col-md-12">
								<label className="label" htmlFor="descricao">
									Descrição :
								</label>
								<input
									autoComplete="off"
									rows="5" cols="33"
									className='form-control'
									placeholder="Descrição..."
									name="descricao"
									htmlFor="descricao"
									type="text"
									ref={register({ required: false })}
								/>

							</div>
						</div>
						<br />
						<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => props.close()}>
							<i className='fas fa-close' /> Cancelar
							</button>
						<button className="btn btn-primary active pull-right" type="submit" onClick={handleSubmit(submitForm)}>
							<i className='fas fa-check' /> Salvar
							</button>

					</Modal.Body>
					<Modal.Footer className="footer-modal">

					</Modal.Footer>
				</Modal>
			</form>
		</>
	)
}
