import * as Yup from 'yup';

export const RecursosSchema = Yup.object().shape({
        codigo: Yup.string()
                .required('Campo obrigatório'),
        nome: Yup.string()
                .required('Campo obrigatório'),
        tipo: Yup.string()
                .required('Campo obrigatório').nullable(),
        classe: Yup.string()
                .required('Campo obrigatório').nullable(),
        unidadeId: Yup.string()
                .required('Campo obrigatório').nullable(),
});


