import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import ImportacaoAnaliseSoloService from "services/ImportacaoAnaliseSoloService";
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";
import EditCompleteIcon from '@material-ui/icons/GetApp';
import Title from "components/Title/title.component";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalVisualizar from './importacaoAnaliseSolo.modal.visualizacao'

const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});

export default function ImportacaoAnaliseSoloListagem(props) {
	const [dados, setDados] = useState([])

	const columns = [
		{ title: "Nome do Arquivo", field: "nomeArquivo", editable: 'never' },
		{ title: "Safra", field: "safra", editable: 'never' },
		{ title: "Data e hora da importação", field: "data", editable: 'never' },
	];

	const [showModal, setShowModal] = useState(false);


	useEffect(() => {
		async function init() {
			buscar()
		}
		init()
	}, [])


	const buscar = async () => {
		const { data } = await ImportacaoAnaliseSoloService.BuscarTodosArquivos(JSON.parse(localStorage.getItem('fazendaId')).value, JSON.parse(localStorage.getItem('safraId')).value);
		setDados(data.result)
	}


	const downloadModelo = async (param) => {
		let response = await ImportacaoAnaliseSoloService.BaixarArquivo(param.id)
		let tempLink = document.createElement('a');
		var data = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		var csvURL = window.URL.createObjectURL(data);
		tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', param.nomeArquivo);
		tempLink.click();
	}


	const actions = [
		{
			icon: EditCompleteIcon,
			tooltip: 'Baixar Arquivo',
			onClick: (event, rowData) => downloadModelo(rowData),
		},
		{
			icon: VisibilityIcon,
			tooltip: 'Visualizar',
			onClick: (event, rowData) => visualizar(rowData),
		}
	]


	const [row, setRow] = useState({});
	const [coluna, setColuna] = useState([]);
	const [items, setItems] = useState([]);

	const visualizar = async (row) => {
		setShowModal(true);
		setRow(row);
		const { data } = await ImportacaoAnaliseSoloService.VisualizarResultado(row.id);
		setColuna(data.result.coluna);
		setItems(data.result.items);

	}

	const modalClose = async () => {
		setShowModal(false);
		setRow({});
		setColuna([]);
		setItems([]);
	}

	const deletarArquivo = async id => {
		await ImportacaoAnaliseSoloService.deletarArquivo(id)
		await buscar();
	}


	return (
		<>
			<div className="row">
				<div className="col-md-10">
					<Title>Arquivos de Importação da Análise do Solo</Title>
				</div>
				<div className="col-md-2">
					<button
						className="btn btn-primary active pull-right bt-mg"
						onClick={() => { window.location.href = "/#/analiseSolo/configuracao" }}>
						<i className={"fas fa-upload mr-2"}></i>
							Importar Planilha
					</button>
				</div>
			</div>
			<div style={{ margin: '20px' }}></div>
			<ThemeProvider theme={theme}>
				<MaterialTable
					title="Lista dos arquivos importados"
					columns={columns}
					data={dados}
					actions={actions}
					options={{
						paging: false,
						search: true,
						actionsColumnIndex: -1,
					}}
					editable={{
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								// deleteAppointment(oldData);
								deletarArquivo(oldData.id);
								resolve();
							})
					}}
					localization={{
						header: {
							actions: 'Ações'
						},
						body: {
							emptyDataSourceMessage:
								'Nenhum registro para exibir',
							addTooltip: 'Adicionar',
							deleteTooltip: 'Baixar',
							editTooltip: 'Edição rápida',
							editRow: {
								deleteText: 'Deseja excluir essa linha?',
								cancelTooltip: 'Cancelar',
								saveTooltip: 'Confirmar',
							},
						},

					}}
				/>
			</ThemeProvider>
			<ModalVisualizar
				show={showModal}
				close={() => setShowModal(false)}
				modalClose={modalClose}
				row={row}
				coluna={coluna}
				items={items}
			/>
		</>
	);
};
