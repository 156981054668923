import api from './api';

export default {
	buscarTodos(params) {
		return api.post(`/api/FormulaAnaliseSolo/BuscarTodas`, params);
	},
	atualizaLista(params) {
		return api.post(`/api/FormulaAnaliseSolo/CadastrarLista`, params);
	},
};
