import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import AppointmentMachinesService from "../../../../services/apontamentoMaquinaService";
import EntryAppointmentService from "../../../../services/apontamentoInsumosService";
import MachineService from "../../../../services/maquinaService";
import { Types } from "./ma.ducks";
import executeAction from "../../../../store/sagas/executeAction";
import ObjectHelper from "../../../../utils/objectHelper";
import ToastHelper from "../../../../utils/toastHelper";
import PersonService from "../../../../services/pessoaService";
import WorkShifts from "../../../../services/turnoService";
import Utils from "../../../../utils/utils";
import SafraService from "../../../../services/safraService";

let parametros = {
	fazendaId:
		JSON.parse(localStorage.getItem("fazendaId")) !== null
			? JSON.parse(localStorage.getItem("fazendaId")).value
			: null,
	page: 1,
	pageSize: 9999,
	order: "Data",
	descedescending: true
};

function* asyncListAppointmentMachines(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(
			async () =>
				await AppointmentMachinesService.findAll(
					params ? params : parametros
				)
		);
		data.result.items.forEach(item => {
			item.data = item.data.split(" ")[0];
			item.horaHomem = Utils.formatHour(item.horaHomem);
			item.horimetroTotal = Utils.formatHour(item.horimetroTotal);
		});
		yield put({ type: Types.SET_APPOINTMENT_MACHINES, data: data.result });
		callback();
	}, Types.GET_APPOINTMENT_MACHINES);
}

function* asyncListMachines(action) {
	yield executeAction(function* () {
		const { callback } = action;
		parametros.order = "DataCriacao";
		const { data } = yield call(() =>
			MachineService.buscarTodasPorId(parametros.fazendaId)
		);

		const machines = Utils.removeInactiveToSelect(
			ObjectHelper.getValuesMachineParseToSelect(data)
		);
		if (machines.length > 0) {
			yield put({ type: Types.SET_MACHINES, data: machines });
			callback();
		}
	}, Types.GET_MACHINES);
}

function* asyncListOperators(action) {
	yield executeAction(function* () {
		const { callback } = action;
		const { data } = yield call(
			async () => await PersonService.getOperadores(parametros.fazendaId)
		);
		const operators = Utils.removeInactiveToSelect(
			ObjectHelper.getValuesParseToSelect(data)
		);
		if (operators.length > 0) {
			yield put({ type: Types.SET_OPERATORS, data: operators });
			callback();
		}
	}, Types.GET_OPERATORS);
}

function* deleteAppointmentMachine(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(
			async () => await AppointmentMachinesService.delete(params)
		);
		if (data.isOk) {
			ToastHelper.success("Operação realizada com sucesso!");
		} else {
			ToastHelper.error("Ocorreu um erro!");
		}
		callback();
	}, Types.DELETE_TURNO);
}

function* asyncListWorkShifts(action) {
	yield executeAction(function* () {
		const { callback } = action;
		const { data } = yield call(
			async () => await WorkShifts.buscar(parametros)
		);
		const workShifts = ObjectHelper.getValuesParseToSelect(
			data.result.items
		);
		if (workShifts.length > 0) {
			yield put({ type: Types.SET_WORK_SHIFTS, data: workShifts });
			callback();
		}
	}, Types.GET_WORK_SHIFTS);
}

function* asyncListWorkPlaces(action) {
	yield executeAction(function* () {
		const { callback } = action;
		let params = parametros;
		params.safraId = ObjectHelper.primeiraSafra();
		const { data } = yield call(
			async () =>
				await SafraService.locais(
					params.fazendaId,
					params.safraId,
					true
				)
		);
		yield put({ type: Types.SET_WORK_PLACES, data: data.result });
		callback();
	}, Types.GET_WORK_PLACES);
}

function* asyncCreateAppointmentMachine(action) {
	yield executeAction(function* () {
		const { appointment, listEntrys, history, callback } = action;
		let apontamento = appointment;
		const { data } = yield call(
			async () => await AppointmentMachinesService.salvar(apontamento)
		);

		if (data.isOk) {
			ToastHelper.success("Apontamentos criado com sucesso!");

			data.result.forEach(async itemSalvo => {

				if (listEntrys.length > 0) {
					let newEntryAppointments = [];
					listEntrys.forEach(item => {
						if (!item.value && data.result) {
							newEntryAppointments.push({
								data: apontamento.date,
								safraId: apontamento.safraId,
								fazendaId: apontamento.fazendaId,
								descricao: apontamento.descricao,
								localId: itemSalvo.idLocal,
								responsavelId: apontamento.pessoaId,
								servicoId: apontamento.servicoId,
								servicoTipoId: apontamento.servicoTipoId,
								apontamentoMaquinaId: itemSalvo.idApontamento,
								insumosId: item.recurso.insumosId,
								quantidade: item.quantidade / apontamento.locaisSafraId.length,
								unidadeId: item.unidade.id
							});
						}
					});
					let returnEntry = undefined;
					if (newEntryAppointments.length > 0) {
						returnEntry = await EntryAppointmentService.salvar({ apontamentos: newEntryAppointments });
					}
					if (
						(returnEntry ? returnEntry.data.isOk : false) &&
						newEntryAppointments.length > 0
					) {
						ToastHelper.success(
							"Levantamentos de Insumos cadastrado com sucesso!"
						);
					} else if (newEntryAppointments.length > 0) {
						ToastHelper.error(
							"Erro ao tentar salvar apontamentos de insumos."
						);

						let returnDelAppointment = await AppointmentMachinesService.deletar(itemSalvo.idApontamento);

						if (returnDelAppointment.data.isOk) {
							ToastHelper.success(
								"Apontamento de máquina deletado devido ao erro ocorrido."
							);
						} else {
							ToastHelper.error(
								"Erro ao tentar apagar o apontamento de máquina criado."
							);
						}
					}
				}
			})
			history.goBack();
		} else ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
		yield put({ type: Types.POST_APPOINTMENT_MACHINE, data: data });
		callback();
	}, Types.CREATE_APPOINTMENT_MACHINE);
}

function* getMachineAppointmentById(action) {
	yield executeAction(function* () {
		const { params, setForm, callback } = action;
		const { data } = yield call(
			async () => await AppointmentMachinesService.buscarPorId(params)
		);

		let dat = data.result.data.split(" ")[0];
		dat = dat.split("/");
		let novaData = new Date(dat[2], dat[1] - 1, dat[0]);
		setForm({
			id: data.result.id,
			date: novaData,
			machine: {
				value: data.result.maquina.id,
				label: data.result.maquina.nome,
				horimetroAtivo: data.result.maquina.horimetroAtivo,
				startHourMeter: data.result.maquina.horimetroInicio
			},
			operator: {
				value: data.result.operador.id,
				label: data.result.operador.nome
			},
			serviceGroup: {
				value: data.result.servicoTipo.id,
				label: data.result.servicoTipo.nome
			},
			service: {
				value: data.result.servico.id,
				label: data.result.servico.nome
			},
			workShift: {
				value: data.result.turno.id,
				label: data.result.turno.nome
			},
			workPlace: {
				value: data.result.localDeTrabalho.id,
				label: data.result.localDeTrabalho.nome
			},
			totalHourMeter: Utils.formatHour(data.result.horimetroTotal),
			startHourMeter: data.result.horimetroInicio,
			finalHourMeter: data.result.horimetroFim,
			hourMan: Utils.formatHour(data.result.horaHomem),
			description: data.result.descricao
		});

		let returnEntry = undefined;
		if (data.isOk) {
			returnEntry = yield call(
				async () =>
					await EntryAppointmentService.buscarPorIdApontamentoMaquina(
						data.result.id
					)
			);
		}
		yield put({
			type: Types.SET_MACHINE_APPOINTMENT_BY_ID,
			data: { data: data, entrys: returnEntry.data.result }
		});
		callback();
	}, Types.GET_MACHINE_APPOINTMENT_BY_ID);
}

function* updateMachineAppointment(action) {
	yield executeAction(function* () {
		const { appointment, listEntrys, history } = action;

		if (appointment) {
			const { data } = yield call(
				async () => await AppointmentMachinesService.editar(appointment)
			);
			if (data.isOk) {
				ToastHelper.success("Apontamento atualizado com sucesso!");
				let newEntryAppointments = [];
				listEntrys.forEach(item => {
					newEntryAppointments.push({
						data: appointment.date,
						safraId: appointment.safraId,
						fazendaId: appointment.fazendaId,
						descricao: appointment.descricao,
						localId: appointment.areaId,
						responsavelId: appointment.pessoaId,
						servicoId: appointment.servicoId,
						servicoTipoId: appointment.servicoTipoId,
						apontamentoMaquinaId: appointment.id,
						insumosId: item.recurso.insumosId,
						quantidade: item.quantidade,
						unidadeId: item.unidade.id || item.unidade.value
					});
				});
				EntryAppointmentService.salvar({
					apontamentoMaquinaId: appointment.id,
					apontamentos: newEntryAppointments
				});
				history.goBack();
			} else {
				ToastHelper.error(
					data.mensagem ? data.mensagem : "Ocorreu um erro."
				);
			}
			yield put({
				type: Types.UPDATE_MACHINE_APPOINTMENT,
				data
			});
		}
	}, Types.UPDATE_MACHINE_APPOINTMENT);
}

function* takeListAppointmentMachines() {
	yield takeLatest(
		Types.GET_APPOINTMENT_MACHINES,
		asyncListAppointmentMachines
	);
}

function* takeDeleteAppointmentMachine() {
	yield takeLatest(
		[Types.DELETE_APPOINTMENT_MACHINE],
		deleteAppointmentMachine
	);
}

function* takeGetMachines() {
	yield takeLatest([Types.GET_MACHINES], asyncListMachines);
}

function* takeGetOperators() {
	yield takeLatest([Types.GET_OPERATORS], asyncListOperators);
}

function* takeGetWorkShifts() {
	yield takeLatest([Types.GET_WORK_SHIFTS], asyncListWorkShifts);
}

function* takeGetWorkPlaces() {
	yield takeLatest([Types.GET_WORK_PLACES], asyncListWorkPlaces);
}

function* takeCreateAppointmentMachine() {
	yield takeLatest(
		Types.CREATE_APPOINTMENT_MACHINE,
		asyncCreateAppointmentMachine
	);
}

function* takeGetMachineAppointmentById() {
	yield takeLatest(
		Types.GET_MACHINE_APPOINTMENT_BY_ID,
		getMachineAppointmentById
	);
}

function* takeUpdateMachineAppointment() {
	yield takeLatest(
		[Types.UPDATE_MACHINE_APPOINTMENT],
		updateMachineAppointment
	);
}

export default function* root() {
	yield all([fork(takeListAppointmentMachines)]);
	yield all([fork(takeDeleteAppointmentMachine)]);
	yield all([fork(takeGetMachines)]);
	yield all([fork(takeGetOperators)]);
	yield all([fork(takeGetWorkShifts)]);
	yield all([fork(takeGetWorkPlaces)]);
	yield all([fork(takeCreateAppointmentMachine)]);
	yield all([fork(takeGetMachineAppointmentById)]);
	yield all([fork(takeUpdateMachineAppointment)]);
}
