import api from './api';

export default {
	salvar(param) {
		return api.post('/api/PlanejamentoSafra', param);
	},
	editar(param) {
		return api.put('/api/PlanejamentoSafra', param);
	},
	get(id) {
		return api.get(`/api/PlanejamentoSafra/${id}`);
	},
	buscarTodas(param) {
		return api.post('/api/PlanejamentoSafra/BuscarTodas', param);
	},
	deletar(id) {
		return api.delete('/api/PlanejamentoSafra/' + id);
	},
	desativar(id) {
		return api.get('/api/PlanejamentoSafra/Desativar/' + id);
	},
	gerador(params) {
		return api.post('/api/PlanejamentoSafra/Gerador', params);
	},
	limpar(params) {
		return api.post('/api/PlanejamentoSafra/Limpar', params);
	},
	updateRecursos(id, params) {
		return api.post('/api/PlanejamentoSafra/UpdateRecursos/' + id, params);
	},
	updatePlanejamentos(params) {
		return api.post('/api/PlanejamentoSafra/UpdatePlanejamentos', params);
	},
	atualizarProgresso(params) {
		return api.post('/api/PlanejamentoSafra/AtualizarProgresso', params);
	},
	salvarPlanilha(params) {
		return api.post(`/api/PlanejamentoSafra/SalvarPlanilha`, params);
	},
	revisaoRecursos(params) {
		return api.post(`/api/PlanejamentoSafra/GetPlanejamentoSafraRecursos`, params);
	},
	updatePlanejamentoSafraRecursos(params) {
		return api.post(`/api/PlanejamentoSafra/UpdatePlanejamentoSafraRecursos`, params);
	},
	DeletePlanejamentos(params) {
		return api.post(`/api/PlanejamentoSafra/DeletePlanejamentos`, params);

	},
	downloadCronogramaServicos(filtro) {
		return api.post(`/api/PlanejamentoSafra/CronogramaServicos`, filtro, { responseType: 'blob' });
	},
	insetLinhaBase(parametro) {
		return api.post(`/api/PlanejamentoSafra/LinhaBase`, parametro);
	},
	updateLinhaBase(parametro) {
		return api.put(`/api/PlanejamentoSafra/LinhaBase`, parametro);
	},
	getLinhasBase(params) {
		return api.post('/api/PlanejamentoSafra/BuscarLinhaBase', params);
	},
	deleteLinhaBase(id) {
		return api.delete(`/api/PlanejamentoSafra/LinhaBase/${id}`);
	},
	updateRecursosRecomendacoesTecnicas(recursos) {
		return api.put(`/api/PlanejamentoSafra/Recursos/Atualizar`, recursos);
	},
	getRecursosToUpdate(params) {
		return api.post(`/api/PlanejamentoSafra/Recursos/BuscarParaRefinar`, params);
	},
	deleteRecurso(params) {
		return api.post(`/api/PlanejamentoSafra/Recursos/Deletar`, params);
	},
	editarEmLote(params) {
		return api.put(`/api/PlanejamentoSafra/EditarEmLote`, params);
	}
};
