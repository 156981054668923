import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({

	getAgendamentoById: ['params', 'callback'],
    setAgendamentoById: ['params'],

	createAgendamento: ['params', 'history', 'callback'],
	postAgendamento: ['params'],

    updateAgendamento: ['params', 'history'],
    setUpdateAgendamento: ['params', 'history'],

})

const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));
const getAgendamentoById = (state = INITIAL_STATE, action) => state.merge({agendamento: action.data} )
const createAgendamento = (state = INITIAL_STATE, action) => state.merge({item: action.data} )
const updateAgendamento = (state = INITIAL_STATE, action) => state.merge( {agend: action.params} )

export default createReducer(INITIAL_STATE, {
	[Types.SET_AGENDAMENTO_BY_ID]: getAgendamentoById,
	[Types.POST_AGENDAMENTO]: createAgendamento,
	[Types.UPDATE_AGENDAMENTO]: updateAgendamento,
})
