import api from './api';

export default {
	buscarTodas(param){
		return api.post(`/api/Maquinas/BuscarTodas`, param);
	},
	buscarTodasPorId(fazendaId){
		return api.get(`/api/Maquinas/BuscarTodas/${fazendaId}`);
	},
	findById(maquinaId){
		return api.get(`/api/Maquinas/id`, maquinaId);
	},
	buscarTiposDeMaquinas(param){
		return api.post(`/api/MaquinaTipo/BuscarTodas`, param);
	},
	cadastrar(maquina){
		return api.post(`/api/Maquinas/Cadastrar`, maquina);
	},
	buscarPorId(maquinaId){
		return api.get(`/api/Maquinas/${maquinaId}`);
	},
	editar(maquinaNova){
		return api.put(`/api/Maquinas`, maquinaNova);
	},
	deletar(maquinaId){
		return api.delete(`/api/Maquinas/${maquinaId}`);
	},
	ativaDesativa(maquinaId){
		return api.get(`/api/Maquinas/Ativar/${maquinaId}`);
	}
};
