import React from 'react';
import Select from 'react-select';

const SelectMulti = ({
	name,
	onChange,
	value,
	disabled,
	options,
	uniqueKey = 'id',
	useKey = true,
	noSelect = false,
	label,
	isMulti,
	isClearable = true,
	placeholder,
}) => {
	const formatedArray = options.map((originalValue) => {
		const formatedValue = {
			key: originalValue[uniqueKey],
			value: originalValue[uniqueKey],
			label: originalValue[label],
			object: originalValue,
		};
		if (value && value !== '') {

			let valueKey = useKey ? value : value[uniqueKey];
			if (originalValue[uniqueKey] === valueKey) {
				value = formatedValue;
			}
		}

		if (noSelect) {
			value = '';
		}
		return formatedValue;

	});

	return (
		<Select
			name={name}
			value={value}
			noOptionsMessage={() => 'Lista vazia'}
			isClearable={isClearable}
			isMulti={isMulti}
			placeholder={placeholder || 'Selecione...'}
			onChange={onChange}
			isDisabled={disabled}
			className={`disabled-multiselect`}
			options={formatedArray}
			theme={theme => ({
				...theme,
				borderRadius: 0,
			})}
		/>
	);
};

export default SelectMulti;
