import * as Yup from 'yup';

export const LocalSchema = Yup.object().shape({
	nome: Yup.string()
        .required('Campo obrigatório'),
    areaHa: Yup.string()
        .required('Campo obrigatório'),
    grupoLocais: Yup.string()
        .nullable()
        .required('Campo obrigatório')
});