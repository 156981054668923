import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Select, { components } from "react-select";
import { TextField } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import RecursosService from "../../services/recursosService";
import { MTablePagination } from "material-table";
import ToastHelper from "../../utils/toastHelper";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./tabelaAjusteRecursos.scss";

const TabelaAjusteRecursos = props => {
	const {
		recursos = [],
		opcoesRecursosComCodigo = [],
		removerClasses = [],
		hideColunaLocal = false
	} = props;

	const [recursosTabela, setRecursosTabela] = useState([]);
	const [opcoesRecursos, setOpcoesRecursos] = useState([]);
	const [listaRecursos, setListaRecursos] = useState([]);


	const [recursoError, setRecursoError] = useState({
		error: false,
		helperText: ""
	});

	useEffect(() => {
		async function getRecursos() {
			const resposta = await RecursosService.getPorCodigoTipo({
				fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
				codigosTipo: opcoesRecursosComCodigo
			});
			let recursosFiltrados = resposta.data.result.filter(
				item => !removerClasses.includes(item.categoria)
			);
			setListaRecursos(recursosFiltrados);

			const itensAgrupados = getItensAgrupados(recursosFiltrados);
			setOpcoesRecursos(itensAgrupados);
		}
		getRecursos();
	}, []);

	useEffect(() => {
		if (recursos) {
			// É necessário fazer essa cópia devido a esse bug da Material-Table
			// https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
			setRecursosTabela(
				JSON.parse(JSON.stringify(recursos)).map(item => {
					if (item.servico.utilizaPorcentagem !== null && item.recursoTipo.codigo === "RE") {
						item.porcentagem = item.servico.utilizaPorcentagem ? item.porcentagem : item.porcentagem * item.local.espacamentoLinhas;
					} else item.porcentagem = "N/A";

					item.localComArea = item.local.nome + " (" + item.local.areaHa + " ha)";

					return item;
				})
			);
		}
	}, [recursos]);

	const getItensAgrupados = itens => {
		let itensAgrupados = [];
		const tipos = itens
			.map(item => item.tipo.nome)
			.filter((value, index, self) => self.indexOf(value) === index);
		tipos.forEach(element => {
			itensAgrupados.push({
				label: element,
				options: itens
					.filter(item => item.tipo.nome === element)
					.map(item => {
						return {
							label: item.nome,
							value: item.id
						};
					})
			});
		});
		return itensAgrupados;
	};

	// handle options group header click event
	// hide and show the options under clicked group
	const handleHeaderClick = id => {
		const node = document.querySelector(`#${id}`).parentElement
			.nextElementSibling;
		const classes = node.classList;
		if (classes.contains("collapsed")) {
			node.classList.remove("collapsed");
		} else {
			node.classList.add("collapsed");
		}
	};

	// Create custom GroupHeading component, which will wrap
	// react-select GroupHeading component inside a div and
	// register onClick event on that div
	const CustomGroupHeading = props => {
		return (
			<div
				className="group-heading-wrapper"
				onClick={() => handleHeaderClick(props.id)}
			>
				<components.GroupHeading {...props} />
			</div>
		);
	};

	const formatGroupLabel = data => (
		<div className="groupStyles">
			<span>{data.label}</span>
			<span className="groupBadgeStyles">{data.options.length}</span>
		</div>
	);

	const groupedStyles = {
		menuPortal: base => ({ ...base, zIndex: 9999 }),
		groupHeading: provided => ({
			...provided,
			textTransform: "none",
			fontSize: 14,
			color: "black"
		}),
		option: provided => ({
			...provided
		})
	};

	const getPorcentagemDesabilitada = props => {
		if (props.rowData.servico.utilizaPorcentagem !== null) {
			if (
				props.rowData.recursos.tipo &&
				props.rowData.recursos.tipo.codigo === "RE"
			) {
				return false;
			} else if (
				!props.rowData.recursos.tipo &&
				props.rowData.recursoTipo.codigo === "RE"
			) {
				return false;
			}
		}

		return true;
	};

	const validSubmit = async changes => {
		let lista = [];
		let error = true;
		for (let value of Object.entries(changes)) {
			lista.push({
				id: value[1].newData.id,
				recursoId: value[1].newData.recursos.value,
				porcentagem: value[1].newData.porcentagem,
				quantidadeTotal: value[1].newData.quantidadeTotal,
				quantidadeHa: value[1].newData.quantidadeHa,
				utilizaPorcentagem: value[1].newData.servico.utilizaPorcentagem,
				espacamentoLinhas: value[1].newData.local.espacamentoLinhas
			});
		}
		await lista.forEach(item => {
			if (item.porcentagem <= 0 || (item.utilizaPorcentagem === true && item.porcentagem > 1) || (item.utilizaPorcentagem === false && item.porcentagem > item.espacamentoLinhas) || item.quantidadeHa <= 0) error = false;
		});

		return error;
	};

	const colunasRecursos = [
		{
			title: "OS",
			field: "ordemServico.codigo",
			editable: "onAdd",
			cellStyle: { fontSize: "14px" },
			sorting: false,
			render: item => {
				if (item.ordemServico) {
					return (
						<Link
							style={{ color: "#009688", cursor: "pointer" }}
							target="_blank"
							to={'/ordemservico/' + item.ordemServico.id + '/recomendacaoTecnica'}
						>
							{item.ordemServico.codigo}
						</Link>
					);
				} else {
					return <></>;
				}
			}
		},
		{
			title: "Serviço",
			field: "servico.nome",
			editable: "onAdd",
			cellStyle: { fontSize: "14px" }
		},
		{
			title: "Recurso",
			field: "recursos.label",
			validate: rowData =>
				rowData.recursos === ""
					? { isValid: false, helperText: "Name cannot be empty" }
					: true,
			editComponent: props => (
				<div className="width-100">
					<Select
						id="recursos"
						name="recursos"
						menuPlacement="top"
						value={
							props.rowData.recursos
								? {
									key: props.rowData.recursos.id,
									label: props.value
								}
								: {}
						}
						styles={groupedStyles}
						menuPortalTarget={document.body}
						components={{ GroupHeading: CustomGroupHeading }}
						formatGroupLabel={formatGroupLabel}
						placeholder="Selecionar"
						className="select-style ajust-label-padrao disabled-select"
						options={opcoesRecursos}
						onChange={item => {
							const recurso = listaRecursos.filter(
								element => element.id === item.value
							)[0];
							var data = { ...props.rowData };
							data.recursos = {
								value: recurso.id,
								label: recurso.nome,
								tipo: recurso.tipo
							};

							if (data.local) {
								data.porcentagem = data.local.espacamentoLinhas;
							} else {
								data.porcentagem = 0;
							}

							if (data.recursos.tipo.codigo === "RE") {
								data.quantidadeTotal =
									Math.round(
										((data.quantidadeHa
											? data.quantidadeHa
											: 0) *
											(data.local
												? data.servico.utilizaPorcentagem !== null ? (data.servico.utilizaPorcentagem === false ? data.porcentagem / data.local.espacamentoLinhas : data.porcentagem) : 1
												: 1) *
											(data.local
												? data.local.areaHa
												: 0) +
											Number.EPSILON) *
										100
									) / 100;
							} else {
								data.porcentagem = "N/A";
								data.quantidadeTotal =
									Math.round(
										((data.quantidadeHa
											? data.quantidadeHa
											: 0) *
											(data.local
												? data.local.areaHa
												: 0) +
											Number.EPSILON) *
										100
									) / 100;
							}

							props.onRowDataChange(data);

							setRecursoError({
								error: false,
								helperText: ""
							});
						}}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{recursoError.error && (
						<span className="span-errors">
							{recursoError.helperText}
						</span>
					)}
				</div>
			)
		},
		{
			title: "Tipo",
			field: "recursoTipo.nome",
			cellStyle: { fontSize: "14px" },
			editable: "onAdd"
		},
		{
			title: "Un",
			field: "unidade.sigla",
			cellStyle: { fontSize: "14px" },
			editable: "onAdd"
		},
		{
			title: "Local",
			field: "localComArea",
			cellStyle: { fontSize: "14px" },
			hidden: hideColunaLocal,
			editable: "never"
		},
		{
			title: "Faixa ou %",
			field: "porcentagem",
			type: "numeric",
			cellStyle: { fontSize: "14px" },
			editComponent: props => {
				return (
					<TextField
						type="number"
						step="any"
						disabled={getPorcentagemDesabilitada(props)}
						value={props.value}
						error={props.value <= 0 || (props.rowData.servico.utilizaPorcentagem === true && props.value > 1) || (props.rowData.servico.utilizaPorcentagem === false && props.value > props.rowData.local.espacamentoLinhas)}
						helperText={(props.rowData.servico.utilizaPorcentagem === true && props.value <= 0) || (props.rowData.servico.utilizaPorcentagem === true && props.value > 1) ? "Entre 0 e 1" : (props.rowData.servico.utilizaPorcentagem === false && props.value <= 0) || (props.rowData.servico.utilizaPorcentagem === false && props.value > props.rowData.local.espacamentoLinhas) ? "Entre 0 e " + props.rowData.local.espacamentoLinhas : ""}
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									{props.rowData.servico.utilizaPorcentagem !== null ?
										props.rowData.servico.utilizaPorcentagem === true ? "%  " : "Faixa  "
										: ''
									}
								</InputAdornment>,
						}}
						onChange={e => {
							var data = { ...props.rowData };
							data.porcentagem = e.target.value;
							if (data.recursoTipo.codigo === "RE") {
								data.quantidadeTotal =
									Math.round(
										((data.quantidadeHa
											? data.quantidadeHa
											: 0) *
											(data.local
												? data.servico.utilizaPorcentagem !== null ? (data.servico.utilizaPorcentagem === false ? data.porcentagem / data.local.espacamentoLinhas : data.porcentagem) : 1
												: 1) *
											(data.local
												? data.local.areaHa
												: 0) +
											Number.EPSILON) *
										100
									) / 100;
							} else {
								data.quantidadeTotal =
									Math.round(
										((data.quantidadeHa
											? data.quantidadeHa
											: 0) *
											(data.local
												? data.local.areaHa
												: 0) +
											Number.EPSILON) *
										100
									) / 100;
							}
							props.onRowDataChange(data);
						}}
					/>
				);
			}
		},
		{
			title: "Quant Ha",
			field: "quantidadeHa",
			type: "numeric",
			cellStyle: { fontSize: "14px" },
			sorting: false,
			editComponent: props => {
				return (
					<TextField
						type="number"
						step="any"
						value={props.value}
						error={props.value <= 0}
						helperText={props.value <= 0 ? "Inválido" : ""}
						onChange={e => {
							var data = { ...props.rowData };
							data.quantidadeHa = e.target.value;
							if (data.recursoTipo.codigo === "RE") {
								data.quantidadeTotal =
									Math.round(
										((data.quantidadeHa
											? data.quantidadeHa
											: 0) *
											(data.local
												? data.servico.utilizaPorcentagem !== null ? (data.servico.utilizaPorcentagem === false ? data.porcentagem / data.local.espacamentoLinhas : data.porcentagem) : 1
												: 1) *
											(data.local
												? data.local.areaHa
												: 0) +
											Number.EPSILON) *
										100
									) / 100;
							} else {
								data.quantidadeTotal =
									Math.round(
										((data.quantidadeHa
											? data.quantidadeHa
											: 0) *
											(data.local
												? data.local.areaHa
												: 0) +
											Number.EPSILON) *
										100
									) / 100;
							}
							props.onRowDataChange(data);
						}}
					/>
				);
			}
		},
		{
			title: "Quant Total",
			field: "quantidadeTotal",
			type: "numeric",
			cellStyle: { fontSize: "14px" },
			editable: "never",
			sorting: false
		}
	];

	return (
		<>
			<ReactTooltip
				place="left"
				type="dark"
				delayShow={200}
				effect="float"
			/>
			<MaterialTable
				title="Recursos"
				columns={colunasRecursos}
				data={recursosTabela}
				onOrderChange={(numeroColuna, orientacao1) =>
					props.onOrderChange(numeroColuna, orientacao1)
				}
				options={{
					search: false,
					selection: false,
					pageSize: props.pageSize,
					paging: true,
					headerStyle: {
						fontSize: 14
					},
					filterCellStyle: {
						fontSize: 14
					},
					actionsColumnIndex: -1
				}}
				components={{
					Pagination: propss => {
						const { classes, headerIsHidden, ...other } = propss;
						return (
							<>
								<div className={"col-md-12 div-sync"}>
									<div className={"col-md-4"}>
										<MTablePagination
											{...other}
											onChangePage={(event, page) =>
												props.onChangePage(event, page)
											}
											page={props.page - 1}
											count={props.totalCount}
											rowsPerPage={props.pageSize}
											localization={{
												labelRowsSelect: "recursos",
												labelDisplayedRows:
													"{from}-{to} de {count}"
											}}
										/>
									</div>
								</div>
							</>
						);
					}
				}}
				editable={{
					onBulkUpdate: changes =>
						new Promise(async (resolve, reject) => {
							let valido = await validSubmit(changes, props);
							if (valido) {
								for (let value of Object.entries(changes)) {
									if (value[1].newData.porcentagem === 'N/A') {
										value[1].newData.porcentagem = 1;
									}
									value[1].newData.porcentagem = value[1].newData.servico.utilizaPorcentagem !== null ?
										value[1].newData.servico.utilizaPorcentagem ? value[1].newData.porcentagem : value[1].newData.porcentagem / value[1].newData.local.espacamentoLinhas
										: 1;
								}
								props.updateTable(changes);
								resolve();
							} else {
								reject();
							}
						}),
					onRowDelete: row =>
						new Promise((resolve, reject) => {
							props.deletRow(row);
							resolve();
							ToastHelper.success('Recurso excluído com sucesso!');
							props.syncTable();
						})
				}}
				actions={[
					{
						icon: "refresh",
						tooltip: "Atualizar Tabela",
						isFreeAction: true,
						onClick: () => props.syncTable()
					}
				]}
				localization={{
					header: {
						actions: "Ações"
					},
					body: {
						emptyDataSourceMessage: "Nenhum registro para exibir",
						addTooltip: "Adicionar",
						deleteTooltip: "Excluir",
						editTooltip: "Editar",
						editRow: {
							deleteText: "Deseja excluir este recurso?",
							cancelTooltip: "Cancelar",
							saveTooltip: "Confirmar"
						}
					},
					toolbar: {
						searchTooltip: "Pesquisar",
						searchPlaceholder: "Pesquisar",
						nRowsSelected: "{0} recurso(s) seleconado(s)"
					},
					pagination: {
						labelRowsSelect: "recursos",
						labelDisplayedRows: "{from}-{to} de {count}"
					}
				}}
			/>
		</>
	);
};

export default TabelaAjusteRecursos;
