import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
	requestRecursosToAdjust: ['params', 'callback'],
	successRecursosToAdjust: ['recursos'],
	setError: ['error'],

	getLocaisSafra: ['params', 'callback'],
	setLocaisSafra: ['locais'],

	updateRecursos: ['items', 'filtro','callback'],

	deletarRecurso: ['recursoId', 'filtro','callback'],
})

const INITIAL_STATE_VALUES = {
	locais: [],
	recursos: [],
	loading: false,
	error: false
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const requestRecursosToAdjust = (state = INITIAL_STATE_VALUES, action) => ({ ...state, loading: true , error: false})
const successRecursosToAdjust = (state = INITIAL_STATE_VALUES, action) => ({ ...state, recursos: action.data.dados, loading: false, error: false, pagination: action.data.pagination })
const updateRecursos = (state = INITIAL_STATE_VALUES, action) => ({ ...state, loading: true, error: false})
const deletarRecurso = (state = INITIAL_STATE_VALUES, action) => ({ ...state, loading: true, error: false})
const getLocaisSafra = (state = INITIAL_STATE, action) => ({ ...state, locais: action.data })
const setError = (state = INITIAL_STATE_VALUES, action) => ({ ...state, error: action.data.error,  loading: false, pagination: action.data.pagination })

export default createReducer(INITIAL_STATE_VALUES, {
	[Types.REQUEST_RECURSOS_TO_ADJUST]: requestRecursosToAdjust,
	[Types.SUCCESS_RECURSOS_TO_ADJUST]: successRecursosToAdjust,
	[Types.SET_ERROR]: setError,
	[Types.SET_LOCAIS_SAFRA]: getLocaisSafra,
	[Types.UPDATE_RECURSOS]: updateRecursos,
	[Types.DELETAR_RECURSO]: deletarRecurso,
})
