import React, { useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import Select, { components } from "react-select";
import { TextField, List, ListItem, ListItemText, ListItemIcon, Grid } from "@material-ui/core";
import RecursosService from "../../services/recursosService";
import Utils from "../../utils/utils";
import "./tabelaEditarRecursosEmLote.scss";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ObjectHelper from "../../utils/objectHelper";


const TabelaRecursos = props => {
	const {
		titulo = "Recursos",
		recursos = [],
		locais = [],
		onAdd = () => { },
		onEdit = () => { },
		onDelete = () => { },
		opcoesRecursosComCodigo = [],
		removerClasses = [],
		hideColunaLocal = false,
		utilizaPorcentagem = null
	} = props;

	const [recursosTabela, setRecursosTabela] = useState([]);
	const [opcoesRecursos, setOpcoesRecursos] = useState([]);
	const [listaRecursos, setListaRecursos] = useState([]);
	const [porcentagemError, setPorcentagemError] = useState({
		error: false,
		helperText: ""
	});
	const [quantidadeHaError, setQuantidadeHaError] = useState({
		error: false,
		helperText: ""
	});
	const [localError, setLocalError] = useState({
		error: false,
		helperText: ""
	});
	const [recursoError, setRecursoError] = useState({
		error: false,
		helperText: ""
	});
	const [unidadeError, setUnidadeError] = useState({
		error: false,
		helperText: ""
	});
	const tableRef = useRef(null);


	const getRecursos = async () => {
		const resposta = await RecursosService.getPorCodigoTipo({
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			codigosTipo: opcoesRecursosComCodigo
		});
		let recursosFiltrados = resposta.data.result.filter(
			item => !removerClasses.includes(item.categoria)
		);
		setListaRecursos(recursosFiltrados);

		const itensAgrupados = getItensAgrupados(recursosFiltrados);
		setOpcoesRecursos(itensAgrupados);

		return recursosFiltrados;
	}



	useEffect(() => {
		getRecursos();
	}, []);

	useEffect(() => {
		if (recursos) {
			// É necessário fazer essa cópia devido a esse bug da Material-Table
			// https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
			setRecursosTabela(
				JSON.parse(JSON.stringify(recursos)).map(item => {
					if (utilizaPorcentagem != null) {
						if (item.recurso.tipo.codigo === "RE")
							item.porcentagemOuFaixaTabela = getPorcentagemOuFaixaValue(
								item.porcentagem,
								item.local ? item.local.espacamentoLinhas : 1
							);
						else item.porcentagemOuFaixaTabela = "N/A";
					}
					return item;
				})
			);
		}
	}, [recursos]);

	const getPorcentagemOuFaixaValue = (porcentagem, espacamentoLinhas) => {
		return utilizaPorcentagem
			? porcentagem
			: Math.round((((porcentagem ? porcentagem : 1) * (espacamentoLinhas ? espacamentoLinhas : 1)) + Number.EPSILON) * 100) / 100;
	};

	const getItensAgrupados = itens => {
		let itensAgrupados = [];
		const tipos = itens
			.map(item => item.tipo.nome)
			.filter((value, index, self) => self.indexOf(value) === index);
		tipos.forEach(element => {
			itensAgrupados.push({
				label: element,
				options: itens
					.filter(item => item.tipo.nome === element)
					.map(item => {
						return {
							label: item.nome,
							value: item.id
						};
					})
			});
		});
		return itensAgrupados;
	};

	// handle options group header click event
	// hide and show the options under clicked group
	const handleHeaderClick = id => {
		const node = document.querySelector(`#${id}`).parentElement
			.nextElementSibling;
		const classes = node.classList;
		if (classes.contains("grupoRecursosCollapsed")) {
			node.classList.remove("grupoRecursosCollapsed");
		} else {
			node.classList.add("grupoRecursosCollapsed");
		}
	};

	// Create custom GroupHeading component, which will wrap
	// react-select GroupHeading component inside a div and
	// register onClick event on that div
	const CustomGroupHeading = props => {
		return (
			<div
				className="group-heading-wrapper"
				onClick={() => handleHeaderClick(props.id)}
			>
				<components.GroupHeading {...props} />
			</div>
		);
	};

	const formatGroupLabel = data => (
		<div className="groupStyles">
			<span>{data.label}</span>
			<span className="groupBadgeStyles">{data.options.length}</span>
		</div>
	);

	const groupedStyles = {
		menuPortal: base => ({ ...base, zIndex: 9999, width: "250px" }),
		groupHeading: provided => ({
			...provided,
			textTransform: "none",
			fontSize: 16,
			color: "black"
		}),
		option: provided => ({
			...provided
		})
	};

	const validaItem = item => {
		let valido = true;

		// Recurso
		if (!item.recurso) {
			setRecursoError({
				error: true,
				helperText: "Obrigatório"
			});
			valido = false;
		} else {
			setRecursoError({
				error: false,
				helperText: ""
			});
		}

		// Unidade
		if (!item.unidade) {
			setUnidadeError({
				error: true,
				helperText: 'Obrigatório',
			});
			valido = false;
		} else {
			setUnidadeError({
				error: false,
				helperText: '',
			});
		}

		// Local
		if (!item.local && !hideColunaLocal) {
			setLocalError({
				error: true,
				helperText: "Obrigatório"
			});
			valido = false;
		} else {
			setLocalError({
				error: false,
				helperText: ""
			});
		}

		// Quantidade Ha
		if (!item.quantidadeHa) {
			setQuantidadeHaError({
				error: true,
				helperText: "Obrigatório"
			});
			valido = false;
		} else if (item.quantidadeHa < 0) {
			setQuantidadeHaError({
				error: true,
				helperText: "Mínimo 0"
			});
			valido = false;
		} else {
			setQuantidadeHaError({
				error: false,
				helperText: ""
			});
		}

		// Porcentagem
		if (item.recurso && item.recurso.tipo.codigo === "RE") {
			if (!item.porcentagem) {
				setPorcentagemError({
					error: true,
					helperText: "Obrigatório"
				});
				valido = false;
			} else if (item.porcentagem < 0) {
				setPorcentagemError({
					error: true,
					label: "",
					helperText: "Mínimo 0"
				});
				valido = false;
			} else if (utilizaPorcentagem && item.porcentagem > 1) {
				setPorcentagemError({
					error: true,
					label: "",
					helperText: "Máximo 1"
				});
				valido = false;
			} else if (
				utilizaPorcentagem != null &&
				!utilizaPorcentagem &&
				item.porcentagem > (item.local ? item.local.espacamentoLinhas : 1)
			) {
				setPorcentagemError({
					error: true,
					label: "",
					helperText: "Máximo " + (item.local ? item.local.espacamentoLinhas : 1)
				});
				valido = false;
			} else {
				setPorcentagemError({
					error: false,
					helperText: ""
				});
			}
		}

		return valido;
	};

	const cancelar = () => {
		setRecursoError({
			error: false,
			helperText: ""
		});

		setLocalError({
			error: false,
			helperText: ""
		});

		setUnidadeError({
			error: false,
			helperText: '',
		});

		setQuantidadeHaError({
			error: false,
			helperText: ""
		});

		setPorcentagemError({
			error: false,
			helperText: ""
		});
	};

	const selectLocal = props => {
		if (locais.length === 1) {
			props.rowData.local = locais[0];
			props.rowData.porcentagem = props.rowData.porcentagem
				? props.rowData.porcentagem
				: 1;
			if (
				props.rowData.recurso &&
				props.rowData.recurso.tipo.codigo === "RE"
			) {
				props.rowData.porcentagemOuFaixaTabela = getPorcentagemOuFaixaValue(
					props.rowData.porcentagem,
					(props.rowData.local ? props.rowData.local.espacamentoLinhas : 1)
				);
			} else {
				props.rowData.porcentagemOuFaixaTabela = "N/A";
			}
			return { key: locais[0].id, label: locais[0].nome };
		}

		if (props.rowData.local) {
			return { key: props.rowData.local.id, label: props.value };
		}

		return {};
	};

	const getPorcentagemDesabilitada = props => {
		if (
			props.rowData.recurso &&
			props.rowData.recurso.tipo &&
			props.rowData.recurso.tipo.codigo === "RE"
		) {
			return false;
		}

		return true;
	};

	const colunasRecursos = [
		{ title: "Tipo", field: "recurso.tipo.nome", editable: "never" },
		{ title: "Categoria", field: "recurso.categoria", editable: "never" },
		{
			title: "Recurso",
			field: "recurso.nome",
			editComponent: props => (
				<div>
					<Select
						id="recursos"
						name="recursos"
						menuPlacement="top"
						value={
							props.rowData.recurso
								? {
									key: props.rowData.recurso.id,
									label: props.value
								}
								: {}
						}
						styles={groupedStyles}
						menuPortalTarget={document.body}
						components={{ GroupHeading: CustomGroupHeading }}
						formatGroupLabel={formatGroupLabel}
						placeholder="Selecionar"
						className="select-style ajust-label-padrao disabled-select"
						options={opcoesRecursos}
						onChange={item => {
							const recurso = listaRecursos.filter(
								element => element.id === item.value
							)[0];
							var data = { ...props.rowData };
							data.recurso = recurso;

							if (recurso !== undefined) {
								let units = ObjectHelper.parseValuesUnitsToSelect(
									recurso.unidade
								);

								data.unidades = units;
								data.unidade = null;
							} else {
								data.unidade = null;
								data.unidades = null;
							}

							if (hideColunaLocal && locais.length === 1) {
								data.local = locais[0];
							}

							if (
								data.recurso &&
								data.recurso.tipo.codigo === "RE"
							) {
								if (!data.porcentagem) {
									data.porcentagem = 1;
								}
								data.porcentagemOuFaixaTabela = getPorcentagemOuFaixaValue(
									data.porcentagem,
									data.local ? data.local.espacamentoLinhas : 1
								);
							} else {
								data.porcentagem = 1;
								data.porcentagemOuFaixaTabela = "N/A";
							}

							props.onRowDataChange(data);
						}}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{recursoError.error && (
						<span className="span-errors">
							{recursoError.helperText}
						</span>
					)}
				</div>
			)
		},
		{
			title: "Unidade", field: "unidade.sigla",
			editComponent: props => (
				<div>
					<Select
						id="unidade"
						name="unidade"
						menuPlacement="top"
						value={
							props.rowData.unidade
								? {
									key: props.rowData.unidade.id,
									label: props.value
								}
								: {}

						}
						styles={groupedStyles}
						menuPortalTarget={document.body}
						components={{ GroupHeading: CustomGroupHeading }}
						formatGroupLabel={formatGroupLabel}
						placeholder='Selecionar'
						className="select-style ajust-label-padrao disabled-select"
						options={props.rowData.unidades}
						onChange={item => {
							const unidade = item;
							var data = { ...props.rowData };
							data.unidade = { sigla: unidade.label, id: unidade.value };
							props.onRowDataChange(data);
						}}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{unidadeError.error && <span className="span-errors">{unidadeError.helperText}</span>}
				</div>
			)
		},
		{
			title: "Local",
			field: "local.nome",
			hidden: hideColunaLocal,
			editComponent: props => (
				<div>
					<Select
						id="locais"
						name="locais"
						menuPlacement="top"
						menuPortalTarget={document.body}
						error={localError.error}
						helperText={localError.helperText}
						placeholder="Selecionar"
						className="select-style ajust-label-padrao disabled-select"
						value={selectLocal(props)}
						options={Utils.getValuesParseToSelect(locais)}
						onChange={item => {
							const local = locais.filter(
								element => element.id === item.key
							)[0];
							var data = { ...props.rowData };
							data.local = local;
							data.porcentagem = 1;
							if (
								data.recurso &&
								data.recurso.tipo.codigo === "RE"
							) {
								data.porcentagemOuFaixaTabela = getPorcentagemOuFaixaValue(
									data.porcentagem,
									data.local ? data.local.espacamentoLinhas : 1
								);
							} else {
								data.porcentagemOuFaixaTabela = "N/A";
							}
							props.onRowDataChange(data);
						}}
						theme={theme => ({ ...theme, borderRadius: 0 })}
					/>
					{localError.error && (
						<span className="span-errors">
							{localError.helperText}
						</span>
					)}
				</div>
			)
		},
		{
			title: "Area (ha)",
			field: "local.area",
			hidden: hideColunaLocal,
			editable: "never"
		},
		{
			title: "Quantidade (ha)",
			field: "quantidadeHa",
			type: "numeric",
			editComponent: props => {
				return (
					<TextField
						type="number"
						step="any"
						value={props.value}
						error={quantidadeHaError.error}
						helperText={quantidadeHaError.helperText}
						onChange={e => {
							var data = { ...props.rowData };
							data.quantidadeHa = e.target.value;
							props.onRowDataChange(data);
						}}
					/>
				);
			}
		},
		{
			title: utilizaPorcentagem ? "Porcentagem" : "Faixa",
			field: "porcentagemOuFaixaTabela",
			hidden: utilizaPorcentagem == null,
			type: "numeric",
			editComponent: props => {
				return (
					<TextField
						type="number"
						step="any"
						disabled={getPorcentagemDesabilitada(props)}
						value={props.value}
						error={porcentagemError.error}
						helperText={porcentagemError.helperText}
						onChange={e => {
							var data = { ...props.rowData };
							data.porcentagem = utilizaPorcentagem
								? e.target.value
								: data.local
									? e.target.value / (data.local ? data.local.espacamentoLinhas : 1)
									: 1;
							if (
								data.recurso &&
								data.recurso.tipo.codigo === "RE"
							) {
								data.porcentagemOuFaixaTabela = e.target.value;
							} else {
								data.porcentagemOuFaixaTabela = "N/A";
							}
							props.onRowDataChange(data);
						}}
					/>
				);
			}
		}
	];

	const renderDetailPanel = (rowData) => {
		return (<div className="col-md-12 mt-3">
			<div className="col-md-12 mt-3">
				<h6 style={{ fontWeight: "bold" }}>{`Detalhes da Receita:`}</h6>
			</div>
			<div className="col-md-12 mt-3">
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<div className="col-md-12 mt-3">
							<h6 style={{ fontWeight: "bold" }}>{`Total para ${rowData.recurso.volumeMistura} litros de mistura`}</h6>
						</div>
						<List dense={true}>
							{rowData.recurso.receita.map(item =>
								<ListItem>
									<ListItemIcon>
										<FiberManualRecordIcon fontSize='small' />
									</ListItemIcon>
									<ListItemText
										primary={`${item.quantidade} ${item.unidade} de ${item.nome}`}
									/>
								</ListItem>
							)}
						</List>
					</Grid>
					{/*<Grid item xs={4}>
						<div className="col-md-12 mt-3">
							<h6 style={{ fontWeight: "bold" }}>{`Total para ${rowData.quantidadeTotal} litros de mistura`}</h6>
						</div>
						<List dense={true}>
							{rowData.recurso.receita.map(item =>
								<ListItem>
									<ListItemIcon>
										<FiberManualRecordIcon fontSize='small' />
									</ListItemIcon>
									<ListItemText
										primary={`${Math.round(((item.quantidade * (rowData.quantidadeTotal / rowData.recurso.volumeMistura)) + Number.EPSILON) * 100) / 100} ${item.unidade} de ${item.nome}`}
									/>
								</ListItem>
							)}
						</List>
					</Grid>*/}
				</Grid>
			</div>
			<div className="col-md-12 mt-3">
				<button
					className="btn btn-primary pull-left"
					type="button"
					onClick={() => {
						const win = window.open(`#/materiais/editar/${rowData.recurso.insumosId}/source=recursos`, "_blank");
						win.addEventListener('beforeunload', () => {
							getRecursos().then((lista) => {
								const recurso = lista.filter(
									element => element.id === rowData.recurso.id
								)[0];
								rowData.recurso = recurso;
								tableRef.current.onToggleDetailPanel(
									[rowData.tableData.id],
									() => renderDetailPanel(rowData)
								);
							});
						}, false);
					}}
				>
					<i className="fas fa-edit" /> Editar
			  </button>
				<button
					className="btn btn-primary pull-left"
					type="button"
					onClick={() => {
						const win = window.open(`#/materiais/duplicar/${rowData.recurso.insumosId}/source=recursos`, "_blank");
						win.addEventListener('beforeunload', () => {
							getRecursos().then((lista) => {
								const recurso = lista.filter(
									element => element.id === rowData.recurso.id
								)[0];
								rowData.recurso = recurso;
							});
						}, false);
					}}
				>
					<i className="fas fa-clone" /> Duplicar
			  </button>
			</div>
		</div>);
	}

	const detailPanel = [
		rowData => ({
			disabled: !(rowData.recurso && rowData.recurso.tipo.codigo === "RE" && rowData.recurso.receita),
			render: () => renderDetailPanel(rowData)
		})
	];

	return (
		<>
			<MaterialTable
				tableRef={tableRef}
				title={titulo}
				columns={colunasRecursos}
				data={recursosTabela}
				options={{
					paging: false,
					actionsColumnIndex: -1
				}}
				editable={props.disabled ?

					{

						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								const valido = validaItem(newData);
								if (valido) {
									onAdd(newData);
									resolve();
								} else {
									reject();
								}
							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								const valido = validaItem(newData);
								if (valido) {
									const dataUpdate = [...recursosTabela];
									const index = oldData.tableData.id;
									dataUpdate[index] = newData;
									onEdit(dataUpdate);
									resolve();
								} else {
									reject();
								}
							}),
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								const dataDelete = [...recursosTabela];
								const index = oldData.tableData.id;
								dataDelete.splice(index, 1);
								onDelete(dataDelete);
								resolve();
							}),
						onRowAddCancelled: () => {
							cancelar();
						},
						onRowUpdateCancelled: () => {
							cancelar();
						}
					} : ''}
				localization={{
					header: {
						actions: "Ações"
					},
					body: {
						emptyDataSourceMessage: "Nenhum registro para exibir",
						addTooltip: "Adicionar",
						deleteTooltip: "Excluir",
						editTooltip: "Editar",
						editRow: {
							deleteText: "Deseja excluir essa linha?",
							cancelTooltip: "Cancelar",
							saveTooltip: "Confirmar"
						}
					},
					toolbar: {
						searchTooltip: "Pesquisar",
						searchPlaceholder: "Pesquisar"
					}
				}}
				detailPanel={detailPanel}
			/>
		</>
	);
};

export default TabelaRecursos;
