import React from 'react'
import { CardStyle } from "../../components/CardStyle/cardstyle.component"
import Title from "../../components/Title/title.component"
import Table from '../../components/Table/table.component'
import ToastHelper from "../../utils/toastHelper"
import Modal from '../../utils/modalHelper'
import { PERMISSAO } from '../../permissoes/permissoes';
import calendarioService from '../../services/calendarioService'

const NUMERO_ITENS_POR_PAGINA = 10;

export default class CalendarioListagem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			showModalExcluir: false,
			idSafraExcluir: '',
			filtro: '',
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
		}
		this.history = this.props.history;
		this.handleModalExcluir = this.handleModalExcluir.bind(this);
	}

	async componentDidMount() {
		await this.buscar();
	}

	columns = [
		{ id: 0, title: 'Código', property: 'codigo' },
		{ id: 1, title: 'Nome', property: 'nome' },
		{ id: 2, title: 'Descrição', property: 'descricao' }
	];

	actions = [
		{
			id: 1,
			name: 'Visualizar',
			icon: 'fas fa-eye',
			permissao: PERMISSAO.MODULO_PESSOA_LEITURA, // TROCAR PARA PERMISSAO CALENDARIO
			action: item => this.visualizar(item),
		},
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_PESSOA_ATUALIZAR,
			action: item => this.editar(item),
		},
		{
			id: 3,
			name: 'Apagar',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_PESSOA_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];

	botaoCadastrar() {
		return (
			<button className="btn btn-primary btn-style active pull-right" onClick={this.cadastrar} >
				<i className='fas fa-plus' /> Adicionar Calendário
			</button>
		)
	}

	TIPO_CADASTRO = 'calendario';

	async visualizar(item) {
		this.history.push(`/planejamento/${this.TIPO_CADASTRO}/visualizar/${item.id}`);
	}

	cadastrar = () => {
		this.history.push(`/planejamento/${this.TIPO_CADASTRO}/cadastro`);
	}

	async editar(item) {
		this.history.push({
			pathname: `/planejamento/${this.TIPO_CADASTRO}/editar/${item.id}`
		});

	}

	async handleModalExcluir(item) {
		if (item) {
			this.setState({
				...this.state,
				idCalendarioExcluir: item.id,
				showModalExcluir: !this.state.showModalExcluir
			})
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			})
		}
	}

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				doubleButtons={true}
				title={"Excluir Calendário"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => this.handleModalExcluir()}
				submit={() => this.apagar(this.state.idCalendarioExcluir)}
				close={() => this.handleModalExcluir()}
				content={
					<h6>{"Deseja realmente excluir este calendário ?"}</h6>
				}
			/>
		);
	};

	async apagar(item) {
		let result = await calendarioService.deletar(item);
		if (result.data.isOk) {
			ToastHelper.success("Calendário excluído com sucesso!");
			this.buscar();
			this.handleModalExcluir();
		} else {
			ToastHelper.error(result.data.mensagem);
		}
	}

	async handlePaginacao(page) {
		await this.setState({
			currentPage: page
		})

		await this.buscar();
	}

	async buscar() {
		const { data } = await calendarioService.buscarTodas({
			fazendaid: JSON.parse(localStorage.getItem('fazendaId')).value,
			filtro: this.state.filtro,
			page: this.state.currentPage,
			pageSize: 10
		})
		this.setState({ ...this.state, data: data.result })
	}

	table() {
		return (
			<>
				<hr />
				<Table
					align='left'
					showPaginate={this.state.data.total > NUMERO_ITENS_POR_PAGINA}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 1}
				/>
			</>
		)
	};

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		await this.buscar();
	}

	campos() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6">
						<Title>Calendário</Title>
					</div>
					<div className="col-md-6 float-right">
						{this.botaoCadastrar()}
					</div>
				</div>
				<CardStyle className="card">
					<div className="card-header col-md-6">
						Calendários cadastrados
                    </div>
					<div className="row">
						<div className="col-md-12">
							{this.table()}
						</div>
					</div>
				</CardStyle>
				{this.modalExcluir()}
			</div>
		)
	}

	render() {
		return (
			<>
				{
					this.campos()
				}
			</>
		)
	}
}
