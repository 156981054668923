import api from './api';

export default {
	listar(){
		return api.get(`/api/perfil`);
	},
	buscar(read){
		return api.post(`/api/perfil/filtro`, read);
	},
	salvar(data){
		return api.post(`/api/perfil`, data);
	},
	deletar(id){
		return api.delete(`/api/perfil/${id}`);
	},
	buscarPorId(dataId){
		return api.get(`/api/perfil/${dataId}`);
	},
	editar(data){
		return api.put('/api/perfil', data);
	}
};
