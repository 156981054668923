import { connect } from "react-redux";
import { validatePermission } from "./permissionHelper";

const mapStateToProps = state => ({ permissoes: state.permissao.permissoes });

const check = (perform, permissoes) => {

	if(permissoes.length === 0)
		return false;

	if (
		permissoes &&
		perform &&
		validatePermission(perform, permissoes)
	) {
		return true;
	}
	return false;
};

const Can = props =>
	check(props.perform, props.permissoes) ? props.yes() : props.no();

Can.defaultProps = {
	yes: () => null,
	no: () => null
};

export default connect(mapStateToProps)(Can);
