import api from './api';

export default {
	buscarTodas(params){
		return api.post(`/api/calendario/BuscarTodas`, params);
	},
	deletar(id){
		return api.delete(`/api/calendario/${id}`);
	},
	buscar(params){
		return api.post(`/api/calendario`, params)
	},
	salvar(params){
		return api.post(`/api/calendario`, params)
	},
	buscarPorId(paramsId){
		return api.get(`/api/Calendario/BuscarPorId/${paramsId}`)
	},
	edit(params){
		return api.put(`/api/calendario`, params)
	}
};
