import api from './api';

export default {

	buscarPorId(fazendaid, safraid) {
		return api.get(`/api/ImportacaoAnaliseSolo/BuscarPorIdConfig/${fazendaid}/${safraid}`);
	},
	cadastrarCofig(param) {
		return api.post(`/api/ImportacaoAnaliseSolo/CadastrarCofig `, param);
	},
	VisualizarResultado(id) {
		return api.get(`/api/ImportacaoAnaliseSolo/VisualizarResultado/${id}`);
	},
	upload(fazendaid, safraId, param) {
		return api.post(`/api/ImportacaoAnaliseSolo/upload/${fazendaid}/${safraId}`, param);
	},
	deletarArquivo(id) {
		return api.delete(`/api/ImportacaoAnaliseSolo/deletarArquivo/${id}`);

	},
	BaixarArquivo(id) {
		return api.get(`/api/ImportacaoAnaliseSolo/BaixarArquivo/${id}`, { responseType: 'blob' });
	},
	BuscarTodosArquivos(fazendaid, safraId) {
		return api.get(`/api/ImportacaoAnaliseSolo/BuscarTodosArquivos/${fazendaid}/${safraId}`);
	},

	buscarTodas(param) {
		return api.post(`/api/ImportacaoAnaliseSolo/BuscarTodas`, param);
	},
	editar(ImportacaoAnaliseSolo) {
		return api.put(`/api/ImportacaoAnaliseSolo`, ImportacaoAnaliseSolo);
	},
	cadastrar(ImportacaoAnaliseSolo) {
		return api.post(`/api/ImportacaoAnaliseSolo`, ImportacaoAnaliseSolo)
	},
	deletar(ImportacaoAnaliseSoloId) {
		return api.delete(`/api/ImportacaoAnaliseSolo/${ImportacaoAnaliseSoloId}`);
	},
};
