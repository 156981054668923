import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { PessoaSchema } from './pessoa.cadastro.validate';
import ToastHelper from "../../utils/toastHelper";
import PessoaService from '../../services/pessoaService';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import CategoriaService from '../../services/categoriaService';
import SelectMulti from '../../components/SelectMulti/selectmulti.component';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons'
import InputMask from "react-input-mask";
import Utils from '../../utils/utils'

import pt from 'date-fns/locale/pt';

export default class PessoaCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: undefined,
				nacionalidade: undefined,
				nome: undefined,
				categorias: undefined,
				cpfCnpj: undefined,
				dataNascimento: undefined,
				email: undefined,
				rg: undefined,
				sexo: undefined,
				telefone: undefined
			},
			sexos: [],
			categorias: [],
			visualizar: false,
			editar: false,
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		let fazendaId = JSON.parse(localStorage.getItem('fazendaId'));

		let sexos = [
			{
				value: 0,
				label: 'Masculino'
			},
			{
				value: 1,
				label: 'Feminino'
			}
		]

		await this.setState({
			fazendaId,
			sexos
		})

		const { match: { params } } = this.props;
		if (params.id) {

			await this.buscar(params.id);
		}

		await this.getCategorias();
		await this.setOperacao()
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	async buscar(PesoaId) {

		let { data } = await PessoaService.buscarPorId(PesoaId);
		if (data.dataNascimento !== "")
			data.dataNascimento = new Date(data.dataNascimento.toString())

		let categorias = { value: data.pessoaTipoId, label: data.pessoaTipoNome }

		await this.setState({
			dados: {
				...data,
				categorias: categorias
			},
			editar: true,
		});

		await this.setState({
			reinitializar: true
		});
	}

	getCategorias = async () => {
		let param = {
			fazendaId: this.state.fazendaId.value,
			parametro: '',
			page: 1,
			pageSize: 99999
		}

		let { data } = await CategoriaService.buscarTodas(param);
		await this.setState({ categorias: Utils.removeInactiveToSelect(Utils.getValuesParseToSelect(data.result.items)) })
	}

	voltar = () => {
		this.history.goBack();
	}

	handleAdicionarCategoria = (categorias) => {
		if (categorias.length > 1) {
			categorias.forEach(item => {
				item.fazendaId = this.state.fazendaId.value;
			})
		} else {
			categorias.fazendaId = this.state.fazendaId.value;
		}
		this.setState({
			...this.state,
			dados: {
				...this.state.dados,
				categorias: categorias,
			}

		});
	}

	async onChangeDate(data) {
		await this.setState({
			...this.state,
			dados: {
				...this.state.dados,
				dataNascimento: data
			}
		})
	}

	onSubmit = async (values, options) => {
		values.pessoaTipoId = values.categorias.value

		if (values.telefone) {
			values.telefone = values.telefone.replace(' ', '');
			values.telefone = values.telefone.replace('-', '');
			values.telefone = values.telefone.replace('(', '');
			values.telefone = values.telefone.replace(')', '');
		}

		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

		let metodo = this.state.editar ? "editar" : "salvar";
		let acao = this.state.editar ? "editado" : "cadastrado";
		let service = await PessoaService[metodo](values);

		if (service.data.isOk) {
			ToastHelper.success('Colaborador ' + acao + ' com sucesso!');
			this.voltar();
		}
		else
			ToastHelper.error("Ocorreu um erro. " + service.data.exception.Message);
	}

	render() {
		const { state: { dados } } = this;
		return (
			<>
				<Formik
					initialValues={dados}
					enableReinitialize={this.state.editar && !this.state.reinitializar}
					validationSchema={PessoaSchema || null}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({ values, setFieldValue, handleSubmit, handleChange }) => (
						<form className="form " onSubmit={handleSubmit}>
							<div>
								<div className='row'>
									<div className='col'>
										<Title>{(this.titulo()) + ' Colaborador'}</Title>
									</div>
								</div>
								<CardStyle className="card">
									<div className='card-header row justify-content-start col-md-12'>
										<div className="card-header col-md-12">
											<label className="label">
												Nome:*
                                    </label>
											<Field
												className='form-control bmd-form-group'
												disabled={this.state.visualizar}
												placeholder="Informe um nome"
												name="nome"
												maxLength="40"
												onChange={handleChange}
												value={values.nome}
												autoComplete="off"
												htmlFor="nome"
												type="text" />
											<ErrorMessage
												name="nome">
												{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Cpf/Cnpj:
                                    </label>
											<Field
												className='form-control bmd-form-group'
												disabled={this.state.visualizar}
												placeholder="Informe o CPF ou CNPJ"
												name="cpfCnpj"
												maxLength="18"
												onChange={handleChange}
												autoComplete="off"
												value={values.cpfCnpj}
												htmlFor="cpfCnpj"
												type="text" />
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												RG:
                                    </label>
											<Field
												className='form-control bmd-form-group'
												disabled={this.state.visualizar}
												placeholder="Informe o RG"
												name="rg"
												maxLength="12"
												onChange={handleChange}
												value={values.rg}
												htmlFor="rg"
												type="text"
												autoComplete="off" />
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Data de Nascimento:
                                    </label>
											<DatePicker
												name="dataNascimento"
												htmlFor="dataNascimento"
												disabled={this.state.visualizar}
												placeholderText='Informe a data de nascimento'
												className='form-control bmd-form-group'
												selected={values.dataNascimento}
												dateFormat="dd/MM/yyyy"
												onChange={(item) => {
													this.onChangeDate.bind(this);
													setFieldValue('dataNascimento', item)
												}}
												locale={pt}
												autoComplete="off"
											/>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Sexo:
                                    </label>
											<Select id="sexo"
												name="sexo"
												htmlFor="sexo"
												onChange={(e) => { setFieldValue('sexo', e.label) }}
												className="select-style ajust-label-padrao disabled-select"
												isDisabled={this.state.visualizar}
												value={this.state.sexos.filter(item => item.label === values.sexo)}
												placeholder='Selecione o sexo'
												options={this.state.sexos}
												theme={theme => ({
													...theme,
													borderRadius: 0
												})}
											/>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Nacionalidade:
                                    </label>
											<Field
												className='form-control bmd-form-group'
												placeholder="Informe sua nacionalidade"
												disabled={this.state.visualizar}
												name="nacionalidade"
												maxLength="15"
												autoComplete="off"
												onChange={handleChange}
												value={values.nacionalidade}
												htmlFor="nacionalidade"
												type="text" />
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												E-mail:
                                    </label>
											<Field
												className='form-control bmd-form-group'
												disabled={this.state.visualizar}
												placeholder="Informe o E-mail"
												name="email"
												autoComplete="off"
												onChange={handleChange}
												value={values.email}
												htmlFor="email"
												maxLength="40"
												type="email" />
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Telefone:
                                    </label>
											<InputMask mask='(99) 99999-9999'
												disabled={this.state.visualizar}
												className='form-control bmd-form-group'
												name="telefone"
												id="telefone"
												placeholder="Informe o Telefone"
												type="text"
												autoComplete="off"
												value={values.telefone}
												onChange={handleChange}
											/>
										</div>
										<div className="card-header col-md-12 ajust-label-padrao">
											<label className="label">
												Grupo:*
                                    </label>
											<SelectMulti
												disabled={this.state.visualizar}
												id="categorias"
												name="categorias"
												uniqueKey="value"
												label="label"
												useKey={false}
												placeholder={'Selecione o grupo do colaborador'}
												isMulti={false}
												noOptionsMessage={() => 'Lista vazia'}
												isClearable={true}
												options={this.state.categorias}
												onChange={(item) => {
													if (item) {
														this.handleAdicionarCategoria(item);
														setFieldValue('categorias', item);
													}
												}}
												value={values.categorias}
											/>
											<ErrorMessage
												name="categorias">
												{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
											</ErrorMessage>
										</div>

									</div>
								</CardStyle>
								<SubmitFormButtons
									flagVisualizar={this.state.visualizar}
									flagEditar={this.state.dados.id !== undefined}
									actionGoBack={() => this.voltar()}
								/>
							</div>
						</form>
					)}
				</Formik>
			</>
		)
	}
}
