import api from './api';

export default {
	buscar(read){
		return api.post(`/api/Turno/filtro`, read);
	},
	salvar(data){
		return api.post(`/api/Turno`, data);
	},
	deletar(id){
		return api.delete(`/api/Turno/${id}`);
	},
	buscarPorId(dataId){
		return api.get(`/api/Turno/porId/${dataId}`);
	},
	editar(data){
		return api.put('/api/Turno', data);
	},
	buscarTudo(fazendaId){
		return api.get(`/api/Turno/${fazendaId}`);
	}
};
