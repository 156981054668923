import React, { useState, useEffect} from 'react';
import HistogramaFiltro from './filtro/histograma.filtro'
import { Creators as ModulosCreators } from './store/histograma.ducks'
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import Table from '../../components/Table/table.component'
import { CardStyle } from "../../components/CardStyle/cardstyle.component"
import HistogramaGrafico from './grafico/grafico'

function HistogramaListagem(props){
	const columns = [
		{ id: 1, title: 'Recursos', property: 'recursos'},
		{ id: 2, title: 'Unidade', property: 'unidade'},
		{ id: 3, title: 'Mes/Ano', property: 'mesAno'},
    ];

	const [page, setPage] = useState(1);
	const [colunas, setColunas] = useState([])
	const [colunasTabela, setColunasTabela] = useState([])
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [items, setItems] = useState([]);
	const [array, setArray] = useState([]);

	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: currentPage,
		pageSize: 10000
	}

	const [pagination, setPagination] = useState(paginateParams)

	const filtrar = (filtro) =>{
		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value
		filtro.page = currentPage
		filtro.pageSize = 1000
		props.buscarHistograma(filtro)
	}

	const getServicos = (id) => props.getServicos(id)

	useEffect( () => {
		if(localStorage.getItem('fazendaId')){
			props.getLocais()
			props.getGrupoServicos()
			props.getRecursos()
			props.getRecursosTipo()
			props.getSafra()
			props.buscarHistograma(pagination)
		}
	}, []);

	useEffect(()=>{
		if(props.histogramas) initPagination()
	})

	useEffect(()=>{
		var colunasAux = []
		var array = []
		if(items !== undefined){
			items.forEach(item => {
				var teste = {}

				teste.recursos = item.recursos
				teste.unidade = item.unidade
				item.mesesAno.forEach(mesAno => {
					teste[mesAno.mesAno] = mesAno.quantidade
				})
				array.push(teste)
			});
		}
		setArray(array)

		colunasAux.push({
			id: 1,
			title: "Recursos",
			property: 'recursos'
		});

		colunasAux.push({
			id: 2,
			title: "Unidade",
			property: 'unidade'
		});
		if(colunas !== undefined){
			for(var i = 0; i< colunas.length; i++){
				colunasAux.push({
					id: i,
					title: colunas[i],
					property: colunas[i]
				});
			}
			setColunasTabela(colunasAux);
		}
	},[items])

	const initPagination = () => {
		setItems(props.histogramas.items);
		setTotal(props.histogramas.total);
		setPage(props.histogramas.page);
		setColunas(props.histogramas.colunas);
	}

	const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
		props.buscarHistograma(pagination)
	}

	return(
		<>
			<HistogramaFiltro
				locais={props.locais.items}
				grupoServicos={props.grupoServicos.items}
				getServicos={getServicos}
				servicos={props.servicos}
				safras={props.safras}
				filtrar={filtrar}
				recursos={props.recursos.items}
				recursosTipo={props.recursosTipo}
			/>
			<CardStyle className="card">
				<hr></hr>
				<HistogramaGrafico
					data={items || []}
				/>
				<div className="row">
					<div className="col-md-12">
					<hr></hr>
						<Table
							align='left'
							showPaginate={total > 10}
							totalElements={total}
							totalPages={page}
							columns={colunasTabela || columns}
							data={array}
							currentPage={currentPage}
							onChangePage={page => changePage(page)}
							emptyMessage="Nenhum item encontrado."
							emptyColSpan={colunasTabela ? colunasTabela.length + 1 : 1}
						/>
					<hr></hr>
					</div>
				</div>
			</CardStyle>
		</>
	)
}

const mapStateToProps = state =>{
	return ({
		locais: state.histogramas.locais,
		grupoServicos: state.histogramas.grupoServicos,
		servicos: state.histogramas.servicos,
		safras: state.histogramas.safra,
		recursos: state.histogramas.recursos,
		recursosTipo: state.histogramas.recursosTipo,
		histogramas: state.histogramas.histogramas,
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(HistogramaListagem)
