import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
	static propTypes = {
		children: PropTypes.instanceOf(Object).isRequired,
		isOpen: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired
	};

	onClick = () => {
		this.props.onClick(this.props.label);
	};

	render() {
		const {
			onClick,
			props: { isOpen }
		} = this;

		return (
			<div
				className={"mb-3 " + (isOpen ? "mt-3" : "")}
				style={{
					background: isOpen ? "#fff" : "#fff",
					border: isOpen ? " 1px solid #F2F2F2" : "#fff",
					padding: "15px 10px"
				}}
			>
				<div onClick={onClick} style={{ cursor: "pointer" }}>
					<div className="pull-right" style={{ float: "right" }}>
						{!isOpen && (
							<span>
								<span
									className="mr-1"
									style={{
										fontSize: 13,
										color: "#00635A",
										cursor: "pointer"
									}}
								>
									Pesquisa avançada
								</span>
								<i className="fas fa-chevron-down pull-right mt-1">
									{" "}
								</i>
							</span>
						)}
						{isOpen && (
							<span>
								<span
									className="mr-1"
									style={{
										fontSize: 13,
										color: "#00635A",
										cursor: "pointer"
									}}
								>
									Pesquisa simples
								</span>
								<i className="fas fa-chevron-up pull-right mt-1">
									{" "}
								</i>
							</span>
						)}
					</div>
				</div>
				{isOpen && (
					<div
						style={{
							background: "#fff",
							border: "none"
						}}
					>
						{this.props.children}
					</div>
				)}
			</div>
		);
	}
}

export default AccordionSection;
