import React from 'react';
import fazendaService from 'services/fazendaService';

class PowerBiPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			urlPowerBI: ''
		};
		this.history = this.props.history;
	}

	componentDidMount() {
		fazendaService.buscarPorId(JSON.parse(localStorage.getItem('fazendaId')).value).then(response => {
			const fazenda = response.data.result.result;
			if (!fazenda || !fazenda.urlPowerBI) {
				window.location.href = "/";
			} else {
				this.setState({ urlPowerBI: fazenda.urlPowerBI });
			}
		});
	}

	render() {
		return (
			<div style={{ marginLeft: -20, marginTop: -18 }}>
				<iframe
					width={window.screen.width - 80}
					height={window.screen.height - 156}
					src={this.state.urlPowerBI}
					frameborder="0"
					title="powerbi"
					allowFullScreen="true" />
			</div>
		)
	}
}

export default PowerBiPage;
