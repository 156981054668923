import React from "react";
import Title from "../../../components/Title/title.component";
import { CardStyle } from "../../../components/CardStyle/cardstyle.component";
import { Formik, Field, ErrorMessage } from "formik";
import ServiceServicoTipo from "../../../services/grupoServicoService";
import ServicoService from "../../../services/servicoService";
import SelectMulti from "../../../components/SelectMulti/selectmulti.component";
import { ServicoSchema } from "./servico.cadastro.validacao";
import ToastHelper from "../../../utils/toastHelper";
import SubmitFormButtons from "../../../components/submitFormButtons/submitFormButtons";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import YouTube from "react-youtube";
import materiaisService from "services/materiaisService";
import Toggle from "react-toggle";

const editorConfiguration = {
	toolbar: [
		"heading",
		"|",
		"bold",
		"italic",
		"link",
		"bulletedList",
		"numberedList",
		"blockQuote"
	]
};

export default class ServicoCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			editar: false,
			servicos: [],
			procedimento: "",
			utilizaPorcentagem: "",
			utilizaFaixa: "",
			dados: {
				nome: "",
				descricao: "",
				servicoTipoId: "",
				fazendaId: "",
				procedimento: "",
				link: ""
			},
			disabled: false,
			selectedsEpi: [],
			epis: []
		};
		this.history = this.props.history;
	}

	getEpis = async () => {
		var fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		const { data } = await materiaisService.getByGrupoInsumos(
			fazendaId,
			"EPI"
		);
		this.setState({
			epis: data.result
		});
	};

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];
		valueName = valueName || "id";
		labelName = labelName || "nome";
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ id: item[valueName], nome: item[labelName] });
		});
		return options;
	}

	async componentDidMount() {
		this.setState(prevState => ({
			dados: {
				...prevState.dados
			}
		}));

		this.getEpis();

		let servicos = await ServiceServicoTipo.buscarTodos({
			fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
			page: 0,
			order: "Nome",
			pageSize: 1000
		});

		this.setState({
			servicos: this.getValuesParseToSelect(servicos.data.result.items)
		});

		const {
			match: { params, path }
		} = this.props;

		var isVisualizar = path.search("visualizar");

		isVisualizar > 0
			? this.setState({ disabled: true })
			: this.setState({ disabled: false });

		if (params.id) {
			this.setState(prevState => ({
				dados: {
					...prevState.dados,
					id: params.id
				}
			}));
			await this.buscar(params.id);
		}
	}

	async buscar(usuarioId) {
		let { data } = await ServicoService.buscarPorId(usuarioId);
		this.setState({
			procedimento: data.result.procedimento
				? data.result.procedimento
				: "",
			utilizaPorcentagem: data.result.utilizaPorcentagem
				? data.result.utilizaPorcentagem
				: false,
			utilizaFaixa:
				data.result.utilizaPorcentagem !== null &&
				!data.result.utilizaPorcentagem
					? true
					: false,
			dados: { ...data.result },
			editar: true,
			selectedsEpi: data.result.epi
		});
	}

	insertEpis(item) {
		this.setState({
			selectedsEpi: item
		});
	}

	handleChangeUtilizaPorcentagem() {
		let utilizaPorcentagem = !this.state.utilizaPorcentagem;
		this.setState({
			utilizaPorcentagem: utilizaPorcentagem
		});

		if (utilizaPorcentagem) {
			this.setState({
				utilizaFaixa: false
			});
		}
	}

	handleChangeUtilizaFaixa() {
		let utilizaFaixa = !this.state.utilizaFaixa;
		this.setState({
			utilizaFaixa: utilizaFaixa
		});

		if (utilizaFaixa) {
			this.setState({
				utilizaPorcentagem: false
			});
		}
	}

	onSubmit = async values => {
		values.procedimento = this.state.procedimento;

		let epis = [];

		this.state.selectedsEpi.forEach(item => {
			epis.push(item.value);
		});

		values.epis = epis;

		values.utilizaPorcentagem =
			!this.state.utilizaPorcentagem && !this.state.utilizaFaixa
				? null
				: this.state.utilizaPorcentagem;

		const {
			match: { params }
		} = this.props;
		values.fazendaId = JSON.parse(localStorage.getItem("fazendaId")).value;
		var data;

		let acao = this.state.editar ? "editado" : "cadastrado";

		if (params.id) data = await ServicoService.editar(values);
		else data = await ServicoService.salvar(values);

		if (data.data.isOk) {
			ToastHelper.success("Serviço " + acao + " com sucesso!");
			this.history.goBack();
		} else
			ToastHelper.error(
				"Ocorreu um erro. " +
					(data.data.exception
						? data.data.exception.Message
						: data.data.mensagem)
			);
	};

	titulo() {
		return this.state.disabled && this.state.editar
			? "Visualizar"
			: this.state.editar
			? "Editar"
			: "Cadastrar";
	}

	render() {
		const {
			state: { dados }
		} = this;
		return (
			<>
				<Formik
					initialValues={dados}
					enableReinitialize
					validationSchema={ServicoSchema || null}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({
						values,
						setFieldValue,
						handleSubmit,
						handleChange
					}) => (
						<form className="form" onSubmit={handleSubmit}>
							<div>
								<div className="row">
									<div className="col">
										<Title>
											{this.titulo() + " serviço"}
										</Title>
									</div>
								</div>
								<CardStyle className="card">
									<div className="card-header row justify-content-start col-md-12">
										<div className="card-header col-md-6">
											<label className="label">
												Nome:*
											</label>
											<Field
												className="form-control"
												placeholder="Informe o nome"
												name="nome"
												disabled={this.state.disabled}
												onChange={handleChange}
												maxLength="40"
												value={values.nome}
												autoComplete="off"
												htmlFor="nome"
												type="text"
											/>
											<ErrorMessage name="nome">
												{msg => (
													<div className="error error-message error-validate error-field">
														{msg}{" "}
													</div>
												)}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Grupo de serviço:*
											</label>
											<SelectMulti
												id="servicoTipoId"
												name="servicoTipoId"
												label="nome"
												useKey
												disabled={this.state.disabled}
												placeholder="Selecione um grupo de serviço"
												options={this.state.servicos}
												onChange={item => {
													if (item)
														setFieldValue(
															"servicoTipoId",
															item.value
														);
												}}
												value={values.servicoTipoId}
											/>
											<ErrorMessage name="servicoTipoId">
												{msg => (
													<div className="error error-message error-validate">
														{msg}{" "}
													</div>
												)}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-12">
											<label className="label">
												Descrição:
											</label>
											<Field
												className="form-control"
												placeholder="Informe a descrição do serviço"
												name="descricao"
												value={values.descricao}
												htmlFor="descricao"
												maxLength="200"
												autoComplete="off"
												disabled={this.state.disabled}
												type="descricao"
											/>
										</div>
									</div>
									<div className="card-header col-md-12">
										<label className="label">EPIs:*</label>
										<SelectMulti
											id="epiId"
											name="epiId"
											label="nome"
											useKey
											isMulti={true}
											disabled={this.state.disabled}
											placeholder="Selecione um EPI"
											options={this.getValuesParseToSelect(
												this.state.epis
											)}
											onChange={item => {
												this.insertEpis(
													item ? item : []
												);
											}}
											value={this.state.selectedsEpi}
										/>
										<ErrorMessage name="epiId">
											{msg => (
												<div className="error error-message error-validate">
													{msg}{" "}
												</div>
											)}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-12">
										<label className="label">
											{" "}
											Link do Vídeo:{" "}
										</label>
										<Field
											className="form-control"
											placeholder="Informe a o link do vídeo"
											name="link"
											value={values.link}
											htmlFor="link"
											maxLength="200"
											autoComplete="off"
											disabled={this.state.disabled}
											type="text"
										/>
										{values.link && (
											<div style={{}} className="center">
												<YouTube
													videoId={values.link
														.split("=")
														.pop()} // defaults -> null
												/>
											</div>
										)}
									</div>
									<div className="card-header col-md-12">
										<label className="label">
											Utiliza Porcentagem
										</label>
										<div className="col-md-4">
											<Toggle
												name="utilizaPorcentagem"
												disabled={this.state.disabled}
												className="custom-classname"
												value={
													this.state
														.utilizaPorcentagem
												}
												checked={
													this.state
														.utilizaPorcentagem
												}
												icons={{
													checked: (
														<i className="fas fa-long-arrow-alt-left white-arrow" />
													),
													unchecked: (
														<i className="fas fa-long-arrow-alt-right white-arrow" />
													)
												}}
												onChange={item => {
													this.handleChangeUtilizaPorcentagem();
												}}
											/>
										</div>
										<label className="label">
											Utiliza Faixa
										</label>
										<div className="col-md-4">
											<Toggle
												name="utilizaFaixa"
												disabled={this.state.disabled}
												className="custom-classname"
												value={this.state.utilizaFaixa}
												checked={
													this.state.utilizaFaixa
												}
												icons={{
													checked: (
														<i className="fas fa-long-arrow-alt-left white-arrow" />
													),
													unchecked: (
														<i className="fas fa-long-arrow-alt-right white-arrow" />
													)
												}}
												onChange={item => {
													this.handleChangeUtilizaFaixa();
												}}
											/>
										</div>
									</div>
									<div
										className="col-md-12"
										style={{ marginBottom: 20 }}
									>
										<label className="label">
											{" "}
											Descrição do procedimento:{" "}
										</label>
										<CKEditor
											activeClass="editor"
											editor={ClassicEditor}
											disabled={this.state.disabled}
											config={editorConfiguration}
											data={this.state.procedimento}
											onChange={(event, editor) => {
												const data = editor.getData();
												this.setState({
													procedimento: data
												});
											}}
										/>
									</div>
								</CardStyle>
								<SubmitFormButtons
									flagVisualizar={this.state.disabled}
									flagEditar={this.state.editar}
									actionGoBack={() => this.history.goBack()}
								/>
							</div>
						</form>
					)}
				</Formik>
			</>
		);
	}
}
