import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/es/Modal';
import TabelaEditarRecursosEmLote from '../../../components/TabelaEditarRecursosEmLote/tabelaEditarRecursosEmLote.component';
import Grid from "@material-ui/core/Grid";
import {
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
	Button,
	TextField,
	makeStyles
} from "@material-ui/core";
import DateHelper from "./../../../utils/dateHelper";
import DateFnsUtils from "@date-io/date-fns";
import pt from "date-fns/locale/pt-BR";
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import ButtonGroup from '../../../../node_modules/react-bootstrap/es/ButtonGroup'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import '../modal.progresso.scss'
import Toggle from 'react-toggle'


const useStyles = makeStyles(theme => ({
	root: {},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	linkChangeFilter: {
		marginTop: "50px"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: "80%"
	},
	initialGrid: {
		marginBottom: 15
	},
	margin: {
		marginTop: "15px"
	},
	textField: {
		'& input': {
			color: '#000000',
		},
		'& input:disabled': {
			color: '#000000 !important',
		},
	},
}));

export default function ModalEditarSelecionadosEmLote(props) {
	const classes = useStyles();
	const [periodoInicial, setPeriodoInicial] = useState();
	const [periodoFinal, setPeriodoFinal] = useState();

	const [recursos, setRecursos] = useState();
	const [atualProgresso, setAtualProgresso] = useState();

	const [editarPeriodo, setEditarPeriodo] = useState(false);
	const [editarProgresso, setEditarProgresso] = useState(false);
	const [editarRecursos, setEditarRecursos] = useState(false);


	const [periodoError, setPeriodoError] = useState({
		error: false,
		helperText: ""
	});

	useEffect(() => {
		if (props.recursos) {
			setRecursos(JSON.parse(JSON.stringify(props.recursos)));
		}
	}, [props.show]);

	const addRecurso = useCallback(async (item) => {
		setRecursos([...recursos, item]);
	});

	const limparModal = () => {
		setEditarPeriodo(false)
		setPeriodoInicial('')
		setPeriodoFinal('')
		setEditarProgresso(false)
		setAtualProgresso(0)
		setEditarRecursos(false)
		setRecursos([])

		setPeriodoError({
			error: false,
			helperText: ""
		});
	}

	const editRecurso = useCallback(async (item) => {
		setRecursos([...item]);
	});

	const deleteRecurso = useCallback(async (item) => {
		setRecursos([...item]);
	});

	const cancelar = useCallback(async () => {
		props.close();
		limparModal()
	});

	const validaSubmit = () => {
		let valido = true
		if (
			editarPeriodo &&
			(periodoInicial === '' || periodoFinal === '')
		) {
			setPeriodoError({
				error: true,
				helperText: "Periodo obrigatório"
			});
			valido = false;
		}
		if (
			editarPeriodo &&
			(DateHelper.convertStringDate(periodoInicial, 'YYYY-MM-DD', 'DD/MM/YYYY') >
				DateHelper.convertStringDate(periodoFinal, 'YYYY-MM-DD', 'DD/MM/YYYY'))
		) {
			setPeriodoError({
				error: true,
				helperText: "Data início não pode ser maior que a data final!"
			});
			valido = false;
		}

		return valido
	}

	const salvar = useCallback(() => {
		if (editarPeriodo || editarProgresso || editarRecursos) {

			let valido = validaSubmit()
			if (valido) {
				let dados = {
					editarPeriodo: editarPeriodo,
					editarProgresso: editarProgresso,
					editarRecursos: editarRecursos,
					periodoInicial: periodoInicial !== '' ? DateHelper.convertStringDate(periodoInicial, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
					periodoFinal: periodoFinal !== '' ? DateHelper.convertStringDate(periodoFinal, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
					progresso: atualProgresso,
					recursos: recursos
				}
				props.submit(dados);
				props.close();
				limparModal()
			}
		} else {
			props.close();
			limparModal()
		}
	});

	return (
		<>
			<Modal
				show={props.showModal}
				dialogClassName="modal-dialog-lg"
				onHide={() => cancelar()}>
				<Modal.Body>
					<h3 className='mt-3' >{"Edição em lote"}</h3>
					<div className='row col-md-12 flex mt-4 ml-1'>

						<div className='row col-md-6 flex mt-3 ml-2'>
							<label className="mt-1 mr-3">
								Editar período?
							</label>
							<Toggle
								className="text-aling-center"
								defaultChecked={editarPeriodo}
								name="editarPeriodo"
								checked={editarPeriodo}
								icons={{
									checked: '',
									unchecked: ''
								}}
								onClick={(e) => {
									setEditarPeriodo(e.target.checked);
									setPeriodoInicial('');
									setPeriodoFinal('');
								}}
								value={editarPeriodo}
							/>
							<div className='row col-md-12 flex mt-3 '>
								<Grid container xs={6} item display="flex">
									<MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
										<TextField
											id="date"
											label="Período inicial"
											type="date"
											className={classes.formControl}
											value={periodoInicial}
											disabled={!editarPeriodo}
											onChange={e => (setPeriodoInicial(e.target.value))}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment onClick={(e) => (setPeriodoInicial(e.target.value))} position="end">
														<CloseIcon style={{ cursor: 'pointer' }} />
													</InputAdornment>
												)
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid container xs={6} item display="flex">
									<MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
										<TextField
											id="date"
											label="Período final"
											type="date"
											disabled={!editarPeriodo}
											className={classes.formControl}
											value={periodoFinal}
											onChange={e => setPeriodoFinal(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment onClick={(e) => setPeriodoFinal(e.target.value)} position="end">
														<CloseIcon style={{ cursor: 'pointer' }} />
													</InputAdornment>
												)
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								{periodoError.error && <span className="span-errors">{periodoError.helperText}</span>}
							</div>
						</div>
						<div className='row col-md-6 mt-3 flex'>
							<label className="mt-1 mr-3">
								Editar progresso?
							</label>
							<Toggle
								className="text-aling-center"
								defaultChecked={editarProgresso}
								name="editarProgresso"
								checked={editarProgresso}
								icons={{
									checked: '',
									unchecked: '',
								}}
								onClick={(e) => {
									setEditarProgresso(e.target.checked);
									(e.target.checked ? setAtualProgresso(0) : setAtualProgresso(0))
								}

								}
								value={editarProgresso}
							/>
							<div className='row col-md-12 flex mt-1 '>
								<div className="col-md-12 mt-2 text-center">
									<h6>Qual o nivel de progresso da atividade?</h6>
								</div>
								<div className="col-md-12 mt-2 text-center">
									<ButtonGroup >
										<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(0)} disabled={!editarProgresso}>0%</Button>
										<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(20)} disabled={!editarProgresso}>20%</Button>
										<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(80)} disabled={!editarProgresso}>80%</Button>
										<Button bsStyle="primary" className={"btn active"} onClick={() => setAtualProgresso(100)} disabled={!editarProgresso}>100%</Button>
									</ButtonGroup>
								</div>
								<div className="col-md-12 text-center">
									<div className="col-md-12  mt-3">
										<Progress percent={atualProgresso} status={true ? "" : "error"} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row col-md-12 flex mt-3'>
						<label className="mt-1 mr-3 ml-4">
							Substituir recursos?
						</label>
						<Toggle
							className="text-aling-center"
							defaultChecked={editarRecursos}
							name="editarRecursos"
							checked={editarRecursos}
							icons={{
								checked: '',
								unchecked: '',
							}}
							onClick={(e) => {
								setEditarRecursos(e.target.checked);
								setRecursos([])
							}}
							value={editarRecursos}
						/>
						<div className="card-header col-md-12">
							<TabelaEditarRecursosEmLote
								recursos={recursos}
								hideColunaLocal={true}
								locais={props.locais}
								utilizaPorcentagem={props.utilizaPorcentagem}
								onAdd={addRecurso}
								onEdit={editRecurso}
								onDelete={deleteRecurso}
								disabled={editarRecursos}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="footer-modal">
					<div className="row col-md-12">
						<div className="col-md-6">
							<button className="btn btn-outline-secondary pull-left" type="button" onClick={cancelar}>
								<i className='fas fa-close' /> Cancelar
							</button>
						</div>
						<div className="col-md-6">
							<button className="btn btn-primary active pull-right" type="submit" onClick={salvar}>
								<i className='fas fa-check' /> Salvar
							</button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}
