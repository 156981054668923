import React, { useState, useEffect} from 'react';
import { PERMISSAO } from '../../permissoes/permissoes';
import {CardStyle} from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import {bindActionCreators} from 'redux';
import { Creators as ModulosCreators } from './store/units.ducks';
import {connect} from 'react-redux';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import Modal from '../../utils/modalHelper';
import Utils from '../../utils/utils';

function UnitListing(props) {

    const columns = [
		{ id: 0, title: 'Sigla', property: 'sigla' },
		{ id: 1, title: 'Descrição', property: 'descricao'}
    ];
    
    const actions = [
        {
            id: 1,
            name: 'Visualizar',
            icon: 'fas fa-eye',
            permissao: PERMISSAO.MODULO_UNIDADE_LEITURA,
            action: item => visualizer(item),
        },
        {
			id: 2,
			name: 'Editar',
            icon: 'fas fa-edit',
            permissao: PERMISSAO.MODULO_UNIDADE_ATUALIZAR,
			action: item => edit(item),
        },
        {
			id: 3,
			name: 'Excluir',
            icon: 'fas fa-trash-alt',
            permissao: PERMISSAO.MODULO_UNIDADE_EXCLUIR,
			action: item => handleModalDelete(item),
		}
    ];

    const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [deleteItem, setDeleteItem] = useState({id:null, showModal:false})
    const [orderColumn, setOrderColumn] = useState()
    const [descending, setDescending] = useState(true)
    
    const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		page: currentPage,
        pageSize: 10,
        filtro:'',
        descedescending: false,
        order: 'Sigla'
    }

    const handleFilter = async (content) =>{
        let newPagination = pagination;
        newPagination.page = 1;
        newPagination.filtro = content.toLowerCase();
        setPagination(newPagination)
        props.getUnits(newPagination)
    }

    const edit = (item) => {
        props.history.push({
            pathname: `/unidades/editar/${item.id}`
        });
    }

    const visualizer = (item) => {
        props.history.push({
            pathname: `/unidades/visualizar/${item.id}`
        });
    }
    
    const newUnit = () => {
        props.history.push(`/unidades/cadastro`);
    }

    const handleModalDelete = (item) => {
        if(item) {
            setDeleteItem({id:item.id, showModal: !deleteItem.showModal})
        } else {
            setDeleteItem({id: null, showModal: !deleteItem.showModal})
        }
	}
    
    useEffect( () => {
        if(localStorage.getItem('fazendaId')){
            props.getUnits(pagination)
        }
    }, []);
    
    useEffect(()=>{
		if(props.units) initPagination()
    })

    const [pagination, setPagination] = useState(paginateParams)

	const initPagination = () => {
		setItems(props.units.items);
		setTotal(props.units.total);
		setPage(props.units.page);
    }

    const changePage = (params) => {
		setCurrentPage(params)
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination)
        props.getUnits(pagination)
    }
    
    const deleteUnit = async () => {
        await props.deleteUnit(deleteItem.id, props)
        let newPagination = pagination
        newPagination.filtro = ""
        await props.getUnits(newPagination)
        setDeleteItem({showModal: false})
    }
    
    const orderTable = (property, descending) => {
        setOrderColumn(Utils.parsePropertToFilter(property));
        setDescending(descending);
        let params = pagination;
        params.order = Utils.parsePropertToFilter(property);
        params.descedescending = descending;
        props.getUnits(params)
    }

    return (
        <>  
            <Title 
                acao="Nova unidade"
                permissaoBotao={PERMISSAO.MODULO_UNIDADE_CRIAR}
                iconBotao="fas fa-plus"
                onClick={newUnit}>
                    Unidades
            </Title>
            <CardStyle className="card">
                <SimpleSearch 
                    placeHolder="Pesquisar"
                    value={pagination.filtro}
                    submit={(e) => handleFilter(e)}
                />
                <Table
                    align='left'
                    showPaginate={total > 10}
                    totalElements={total}
                    totalPages={page}
                    columns={columns}
                    actions={actions}
                    data={items}
                    currentPage={currentPage}
                    onChangePage={page => changePage(page)}
                    emptyMessage="Nenhum item encontrado."
                    emptyColSpan={columns ? columns.length + 1 : 1}
					orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
                />
			</CardStyle>
            <Modal
                show={deleteItem.showModal}
                doubleButtons={true}
                title={"Excluir Unidade"}
                buttonTitle={"Confirmar"}
                button2Title={"Cancelar"}
                buttom2Action={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                submit={deleteUnit}
                close={() => setDeleteItem({id: deleteItem.id, showModal: false})}
                content={
                    <h6>{"Deseja realmente excluir essa unidade?"}</h6>
                }
            />
        </>
    )
}

const mapStateToProps = state =>{
	return ({
        units: state.units.itens
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UnitListing)