import React, { useState, useEffect } from 'react';
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import Title from '../../components/Title/title.component';
import ElementoAnaliseSoloService from 'services/ElementoAnaliseSoloService'
import ImportacaoAnaliseSoloService from 'services/ImportacaoAnaliseSoloService'
import unidadeService from 'services/unidadeService'

import Select from 'react-select'

function ImportacaoAnaliseSolo(props) {
	const [elementos, setElementos] = useState([]);
	const [unidades, setUnidades] = useState([]);

	const [column, setColumn] = useState([{
		coluna: "",
		linha: "",
		itemId: "",
		unidadeId: ""
	}]);

	const buscarUnidades = async () => {
		const { data } = await unidadeService.getAll({
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1, pageSize: 1000, descedescending: false,
			safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : "",
		});

		setUnidades(getValuesParseToSelect(data.result.items, "id", "sigla"));
	};

	const submit = async () => {
		let param = {};
		param.itens = [...column];
		param.safraId = JSON.parse(localStorage.getItem('safraId')).value;
		param.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

		ImportacaoAnaliseSoloService.cadastrarCofig(param).then(() => {
			props.history.push("/analiseSolo/importacao");
		});
	}

	const buscarElementos = async () => {
		const { data } = await ElementoAnaliseSoloService.buscarTodas({
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1, pageSize: 1000, order: 'Nome', descedescending: false,
			safraId: JSON.parse(localStorage.getItem('safraId')) ? JSON.parse(localStorage.getItem('safraId')).value : "",
		});
		setElementos(getValuesParseToSelect(data.result.items));
	};

	const getValuesParseToSelect = (list, valueName, labelName) => {
		let options = [];
		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	const buscarPorId = async () => {
		let safraId = JSON.parse(localStorage.getItem('safraId')).value;
		let fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

		const { data } = await ImportacaoAnaliseSoloService.buscarPorId(fazendaId, safraId);
		if (data.result.length)
			setColumn(data.result);
	}

	useEffect(() => {
		buscarElementos();
		buscarUnidades();
		buscarPorId();
	}, []);

	const handleInputChange = async (e, index, coluna) => {
		const list = [...column];
		if (!coluna) {
			const { name, value } = e.target;
			list[index][name] = value;
		}
		else {
			if (coluna === "unidadeId") {
				e ? list[index].unidade = e : list[index].unidade = null
			}

			if (coluna === "itemId") {
				e ? list[index].item = e : list[index].item = null
			}

			list[index][coluna] = e !== null ? e.value : null
		}

		setTimeout(function () {
			setColumn(list);
		}, 100)

	};

	const addColumn = () => {
		setColumn([...column, {
			coluna: "",
			linha: "",
			itemId: "",
			unidadeId: ""
		}]);
	}

	const removeColumn = (item, index) => {
		let list = [...column];
		list.splice(index, 1);
		setTimeout(function () {
			setColumn(list);
		}, 100)
	}


	return (
		<>
			<Title>
				Importação da Análise de Solo
            </Title>
			<CardStyle className="card">
				<div className="container-fluid">
					<div className="jumbotron" style={{ padding: "2rem 2rem", marginTop: 20 }}>
						<p className="lead"> Para configurar a importação da análise do solo confira a sua planilha com os resultados da análise e preencha os dados que você deseja registrar. </p>
						<hr></hr>
						<p> Por exemplo: se os dados de 'Profundidade' na sua planilha estiverem na coluna B e os valores (desprezando cabeçalhos) começarem na linha 4, preencha esses dados nos campos abaixo.</p>
					</div>
					<div className="card-helper">
						<div class="card-helper-body">
							<div className="row">
								<div className="col-md-4"><label style={{ fontWeight: 600 }}>Elemento da análise do solo*</label></div>
								<div className="col-md-2"><label style={{ fontWeight: 600 }}>Coluna*</label></div>
								<div className="col-md-2"><label style={{ fontWeight: 600 }}>Linha*</label></div>
								<div className="col-md-3"><label style={{ fontWeight: 600 }}>Unidade de medida</label></div>
							</div>
							<hr></hr>
							{
								column.map((item, index) => {
									return (
										<>
											<div className="row">
												<div className="col-md-4">
													<Select
														id="itemId"
														name="itemId"
														htmlFor="itemId"
														placeholder='Selecione o elemento'
														className="select-style ajust-label-padrao disabled-select"
														value={item.item}
														onChange={(e) => handleInputChange(e, index, 'itemId')}
														options={elementos}
														isClearable
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
												</div>
												<div className="col-md-2">
													<input
														className="form-control my-0 py-1"
														placeholder="Digite a Coluna"
														value={item.coluna}
														autoComplete="off"
														name="coluna"
														id="coluna"
														maxLength="2"
														onChange={(e) => handleInputChange(e, index)}
													/>
												</div>
												<div className="col-md-2">
													<input
														className="form-control my-0 py-1"
														placeholder="Digite a Linha"
														value={item.linha}
														autoComplete="off"
														name="linha"
														id="linha"
														maxLength="2"
														onChange={(e) => handleInputChange(e, index)}
													/>
												</div>
												<div className="col-md-3">
													<Select
														id="itemId"
														name="itemId"
														htmlFor="itemId"
														placeholder='Selecione a unidade'
														className="select-style ajust-label-padrao disabled-select"
														value={item.unidade}
														onChange={(e) => handleInputChange(e, index, 'unidadeId')}
														options={unidades}
														isClearable
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
												</div>
												<button
													className="btn btn-primary pull-right"
													style={{ color: 'black', marginTop: 10 }}
													id="basic-text1"
													onClick={() => removeColumn(item, index)} >
													<i className="fas fa-trash" aria-hidden="true" />
												</button>
											</div>
										</>
									)
								})
							}
						</div>
					</div>

					<br />
					<button className="btn btn-primary active pull-right"
						id="basic-text1" onClick={addColumn} >
						<i className="fas fa-plus"
							aria-hidden="true" />
					</button>
					<br />
				</div>
			</CardStyle>
			<br />
			<button className="btn btn-primary active pull-right"
				style={{ marginRight: "10px" }}
				id="basic-text1" onClick={submit}> Próximo
				<i className="fas fa-arrow-right"
					style={{ marginLeft: 7 }}
					aria-hidden="true" />
			</button>
			<a className="btn btn-outline-secondary pull-left" type="button" href="/#/analiseSolo">
				<i className='fas fa-arrow-left' />  Voltar
            	</a>

		</>
	)
}

export default ImportacaoAnaliseSolo
