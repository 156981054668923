import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Title from "../../components/Title/title.component";
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import Select from 'react-select';
import Table from '../../components/Table/table.component';
import { PERMISSAO } from '../../permissoes/permissoes';
import ToastHelper from "../../utils/toastHelper";
import DatePicker from "react-datepicker";
import pt from 'date-fns/locale/pt';
import './safra.css'
import SafraRequests from './safra.requests'
import ModalTalhao from './modal/modal.talhao'
import ModalGrupoSafra from './modal/modal.grupoSafra'

const FAZENDA = JSON.parse(localStorage.getItem('fazendaId'));

function SafraCadastro(props) {
	const [visualize, setVisualize] = useState(false);
	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue, getValues } = useForm({});
	const [local, setlocal] = useState(null)
	const [showModalTalhao, setShowModalTalhao] = useState(false);
	const [showModalGrupoSafra, setShowModalGrupoSafra] = useState(false);
	const initialState = {
		cultura: {
			value: "",
			label: "",
			cultivares: []
		},
		safra: {},
		atividade: null,
		unidade: {},
		inicio: null,
		fim: null,
		locais: []
	}

	const [form, setform] = useState(initialState);

	useEffect(() => {
		async function initialize() {
			try {
				if (params.modo === 'visualizar') setVisualize(true);
				if (params.id) await getById(params.id)
			} catch (error) {
			}
		}
		initialize();
	}, []);

	const getById = async id => {
		const data = await SafraRequests.getById(id);
		setform(data);
		setValue('descricao', data.descricao);
		setValue('unidadeId', data.unidade.value);
		setValue('grupoSafraId', data.grupoSafra.value);
		setValue('culturaId', data.cultura.value);
		setValue('nome', data.nome)
		setValue('inicio', new Date(data.inicio))
		setValue('fim', new Date(data.fim))
	};

	const actions = [
		{
			id: 0,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_SAFRA_CRIAR,
			action: item => deleteLocalSafra(item),
		},
		{
			id: 1,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_SAFRA_CRIAR,
			action: item => editLocalSafra(item),
		},
	];

	const editLocalSafra = async (item) => {
		setlocal(item.id);
		setShowModalTalhao(true);
	}

	const validatePeriod = useCallback(() => {
		if (form.inicio && form.fim && (form.inicio > form.fim)) {
			return false
		} else {
			return true
		}
	}, [form.inicio, form.fim])


	const columns = [
		{ id: 6, title: 'Local', property: 'local' },
		{ id: 7, title: 'Área de Uso', property: 'valorAreaLocal' },
		{ id: 8, title: 'Espaçamento  L/P(m)', property: 'espacamento' },
		{ id: 9, title: 'Cultivar', property: 'cultivar' },
		{ id: 10, title: 'Tipo', property: 'tipo' },
		{ id: 11, title: 'Parâmetro da Lavoura', property: 'parametrosNome' },
		{ id: 12, title: 'Data do Plantio (Lavoura)', property: 'dataPlantio' }
	];

	const deleteLocalSafra = async (item) => {
		const { match: { params } } = props;
		const data = await SafraRequests.deletarLocal(item.id, params.id);
		if (data.isOk)
			ToastHelper.success("Local deletado com sucesso!");
		else
			ToastHelper.error(data.mensagem)
		await getById(params.id);
	};

	const submitForm = async () => {
		if (form.grupoSafra === null || form.grupoSafra.value === "00000000-0000-0000-0000-000000000000") return ToastHelper.error("O campo Safra é obrigatório!")
		if (form.cultura === null || form.cultura.value === "00000000-0000-0000-0000-000000000000") return ToastHelper.error("O campo Cultura é obrigatório!")
		var parametros = getValues();
		parametros.fazendaId = FAZENDA.value;
		parametros.id = params.id || null;
		parametros.locais = [];
		const data = await SafraRequests.cadastro(parametros);
		toast(data);
	};

	const toast = (data) => {
		if (data.isOk) {
			if (params.id) {
				ToastHelper.success("Edição realizada com sucesso!");
				window.location.href = "/#/safra";
			}
			else {
				ToastHelper.success("Cadastro realizado com sucesso!");
				window.location.href = "/#/safra/editar/" + data.result.id;
			}
		} else {
			ToastHelper.error(data.mensagem);
		}
	}

	const tableLocalSafraCafe = () => (
		params.id &&
		<div className="container-fluid">
			<div className='row pb-0 mb'>
				<div className='col-9  pb-0 mb-0'>
					{props.children}
				</div>
				{
					!visualize &&
					<div className='col-3  pb-0 mb'>
						<button
							type="button"
							name="modal-talhao"
							className="btn btn-primary active pull-right mb-2"
							onClick={() => setShowModalTalhao(true)}>
							<i className="fas fa-plus"></i>
						</button>
					</div>
				}

			</div>

			<Table
				className='mt-3'
				align='left'
				columns={columns}
				actions={!visualize ? actions : null}
				acoesSingleButton={false}
				data={form.locais}
				emptyMessage='Nenhum item adicionado.'
				emptyColSpan={columns ? columns.length + 1 : 0}
			/>
		</div>
	);

	const addLocalSafra = async () => {
		await getById(params.id);
		setShowModalTalhao(false);
	}

	const [safras, setSafras] = useState([]);
	const [unidades, setUnidades] = useState([]);
	const [culturas, setCulturas] = useState([]);
	useEffect(() => {
		async function fetchData() {
			setSafras(await SafraRequests.getGrupoSafra());
			setUnidades(await SafraRequests.getUnidades());
			setCulturas(await SafraRequests.getCulturas());
		}
		fetchData();
	}, []);


	const addGrupoSafra = async () => {
		setSafras(await SafraRequests.getGrupoSafra());
		setShowModalGrupoSafra(false);
	}

	return (
		<>
			<div className='row'>
				<div className='col'>
					<Title>
						{visualize ? 'Visualizar ' :
							(params.id ? 'Editar ' :
								'Cadastrar '
							)} Safra
                            </Title>
				</div>
			</div>
			{

				<form onSubmit={handleSubmit(submitForm)}>

					<CardStyle className='card mb-0 pb-0'>
						<div className='row col-md-12 flex  mt-4'>
							<div className="col-md-4">
								<label className="label">
									Safras:*
                                </label>
								<div className="input-group mb-3">
									<Select
										placeholder='Selecione'
										isDisabled={visualize}
										className="form-control select-ajust"
										options={safras}
										value={form.grupoSafra}
										ref={() => register({ name: "grupoSafraId" }, { required: true })}
										onChange={item => {
											setform({ ...form, grupoSafra: item });
											setValue('grupoSafraId', item.value);
										}}
										theme={theme => ({ ...theme, borderRadius: 0 })}
									/>
									<div className="input-group-prepend">
										<button name="modal-grupo-safra" className="btn-custom float-right " type='button' onClick={() => setShowModalGrupoSafra(true)} >
											<i className="fas fa-plus"></i>
										</button>
									</div>
								</div>

								{errors.grupoSafraId && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="col-md-4">
								<label className="label">
									Atividade:*
                                </label>
								<input
									type='text'
									name="nome"
									disabled={visualize}
									placeholder='Digite um nome'
									className="form-control"
									autoComplete="off"
									value={form.atividade}
									onChange={e => setform({ ...form, atividade: e.target.value })}
									ref={register({ required: true })}
								/>
								{errors.nome && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="col-md-4">
								<label className="label">
									Culturas:*
                                </label>
								<Select
									placeholder='Selecione'
									isDisabled={visualize}
									className="select-style ajust-label-padrao disabled-select"
									options={culturas}
									value={culturas.find(x => x.value === form.cultura.value)}
									ref={() => register({ name: "culturaId" }, { required: true })}
									onChange={item => {
										setValue('culturaId', item.value);
										setform({ ...form, cultura: item });
									}}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.culturaId && <span className="span-errors">Campo obrigatório</span>}
							</div>
						</div>
						<div className='row justify-content-start col-md-12 mt-3'>
							<div className="col-md-4">
								<label className="label">
									Unidade de produção:*
                                </label>
								<Select
									placeholder='Selecione'
									isDisabled={visualize}
									className="select-style ajust-label-padrao disabled-select"
									options={unidades}
									value={form.unidade}
									ref={() => register({ name: "unidadeId" }, { required: true })}
									onChange={item => {
										setValue('unidadeId', item.value);
										setform({ ...form, unidade: item });
									}}
									theme={theme => ({ ...theme, borderRadius: 0 })}
								/>
								{errors.unidadeId && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="col-md-4">
								<label className="label">
									Período inicial:*
                                </label>
								<DatePicker
									selected={form.inicio}
									isClearable={true}
									disabled={visualize}
									placeholderText='Informe a data inicial'
									className='form-control bmd-form-group'
									dateFormat="dd/MM/yyyy"
									autoComplete="off"
									ref={() => register({ name: "inicio" }, { required: true })}
									onChange={date => {
										setValue('inicio', date);
										setform({ ...form, inicio: date });
									}}
									onBlur={() => validatePeriod()}
									locale={pt}
								/>
								{errors.inicio && <span className="span-errors">Campo obrigatório</span>}
							</div>
							<div className="col-md-4">
								<label className="label">
									Período final:*
                                </label>
								<DatePicker
									selected={form.fim}
									isClearable={true}
									placeholderText='Informe a data final'
									className='form-control bmd-form-group'
									dateFormat="dd/MM/yyyy"
									disabled={visualize}
									ref={() => register({ name: "fim" }, { required: true })}
									autoComplete="off"
									onChange={date => { setValue('fim', date); setform({ ...form, fim: date }); }}
									onBlur={() => validatePeriod()}
									locale={pt}
								/>
								{errors.fim && <span className="span-errors">Campo obrigatório</span>}
							</div>

						</div>

						<div className='row justify-content-start col-md-12 mt-4'>
							<div className="col-md-12">
								<label className="label ">
									Descrição:
                                </label>
								<input
									type='textarea'
									name="descricao"
									disabled={visualize}
									placeholder='Descrição'
									className="form-control"
									onChange={e => setform({ ...form, descricao: e.target.value })}
									autoComplete="off"
									ref={register({ required: false })}
								/>
							</div>
						</div>
						<br />
						<div className="col-md-12 ml-0 p-0">
							{tableLocalSafraCafe()}
						</div>
					</CardStyle>
					<div className="col-md-12 mt-3">
						<button type="button" name="voltar" className="btn btn-outline-secondary pull-left" onClick={() => window.location.href = '/#/safra'}>
							<i className='fas fa-arrow-left' /> Voltar
            			</button>
						<button type="submit" name="cadastrar" className="btn btn-primary active pull-right" onClick={() => submitForm}>
							<i className='fas fa-check' /> {(params.id ? 'Editar ' :
								'Cadastrar '
							)}
						</button>
					</div>
				</form>
			}

			<ModalTalhao
				visualize={visualize}
				addLocalSafra={addLocalSafra}
				show={showModalTalhao}
				close={() => setShowModalTalhao(false)}
				cultivares={form.cultura.cultivares}
				local={local}
				safraId={params.id}
				cultura={form.cultura}
			/>
			<ModalGrupoSafra
				visualize={visualize}
				show={showModalGrupoSafra}
				close={() => setShowModalGrupoSafra(false)}
				addGrupoSafra={addGrupoSafra}
			/>
		</>
	);
}

export default SafraCadastro;

