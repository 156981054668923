import api from './api';

export default {

	buscarPorId(ElementoAnaliseSoloId) {
		return api.get(`/api/ElementoAnaliseSolo/BuscarPorId/${ElementoAnaliseSoloId}`);
	},

	buscarTodas(param) {
		return api.post(`/api/ElementoAnaliseSolo/BuscarTodas`, param);
	},

	editar(ElementoAnaliseSolo) {
		return api.put(`/api/ElementoAnaliseSolo`, ElementoAnaliseSolo);
	},

	cadastrar(ElementoAnaliseSolo) {
		return api.post(`/api/ElementoAnaliseSolo`, ElementoAnaliseSolo)
	},

	deletar(ElementoAnaliseSoloId) {
		return api.delete(`/api/ElementoAnaliseSolo/${ElementoAnaliseSoloId}`);
	},
};
