import api from './api';

export default {
	create(params) {
		return api.post(`/api/Agendamento`, params);
	},
	buscarTudo(params) {
		return api.post(`/api/Agendamento/BuscarTodos`, params);
	},
	buscarPorId(agendamentoId){
		return api.get(`/api/Agendamento/${agendamentoId}`);
	},
	update(agendamento){
		return api.put(`/api/Agendamento`, agendamento);
	},
	ativaDesativa(id){
		return api.get(`/api/Agendamento/AtivarDesativar/${id}`);
	},
	buscarOrdensServico(param){
		return api.post(`/api/Agendamento/BuscarOrdensServico`, param);
	},
	deletar(id){
		return api.delete(`/api/Agendamento/${id}`);
	},
	buscarAtiva(fazendaId, safraId){
		return api.get(`/api/Agendamento/ativa/${fazendaId}/${safraId}`);
	},
};
