import React, { useState, useCallback, useEffect } from 'react'; import ObjectHelper from 'utils/objectHelper';
import { useForm } from 'react-hook-form';
import Title from "components/Title/title.component";
import RegisterHead from './body/register.head'
import './style/machine.appointment.css';
import FormEntry from './body/formEntry'
import Utils from 'utils/utils'
import { validateStartHourMeter, validateFinalHourMeter, validateTotalHourMeter } from './ma.validate'
import Select, { components } from "react-select";
import { Creators as ModulosCreators } from './store/ma.ducks';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormHourMeter from './body/formHourMeter';
import apontamentoMaquinaService from "services/apontamentoMaquinaService";
import servicoService from 'services/servicoService'
import { CardStyle } from 'components/CardStyle/cardstyle.component';
import ToastHelper from "utils/toastHelper";
import apontamentoInsumosService from "services/apontamentoInsumosService";
import safraService from "services/safraService";

function MachineAppointmentRegister(props) {
	let defaltValues = {
		date: new Date(),
		machine: undefined,
		operator: undefined,
		serviceGroup: undefined,
		service: undefined,
		workShift: undefined,
		workPlace: undefined,
		totalHourMeter: undefined,
		startHourMeter: undefined,
		finalHourMeter: undefined,
		hourMan: '',
		description: '',
		safra: undefined
	}

	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [errorHourMeter, setErrorHourMeter] = useState()
	const [visualize, setVisualize] = useState(false);
	const [edit, setEdit] = useState(false);
	const [form, setForm] = useState(defaltValues);
	const [listEntrys, setListEntrys] = useState([])
	const [selectedMachine, setSelectedMachine] = useState({})
	const [alertaSugestoesInsumo, setAlertaSugestoesInsumo] = useState(null)

	useEffect(() => {
		if (params.modo === 'visualizar') setVisualize(true);
		if (params.modo === 'editar') setEdit(true);
		props.getMachines()
	}, [])

	useEffect(() => {
		if (params.id !== undefined)
			getById(params.id);
	}, []);

	const getById = async (id) => {
		const { data } = await apontamentoMaquinaService.buscarPorId(id)
		let dat = data.result.data.split(" ")[0];
		dat = dat.split("/");
		let novaData = new Date(dat[2], dat[1] - 1, dat[0]);
		setForm({
			id: data.result.id,
			date: novaData,
			machine: {
				value: data.result.maquina.id,
				label: data.result.maquina.nome,
				horimetroAtivo: data.result.maquina.horimetroAtivo,
				startHourMeter: data.result.maquina.horimetroInicio
			},
			safra: {
				value: data.result.safra.id,
				label: data.result.safra.nome
			},
			operator: {
				value: data.result.operador.id,
				label: data.result.operador.nome
			},
			serviceGroup: {
				value: data.result.servicoTipo.id,
				label: data.result.servicoTipo.nome
			},
			service: {
				value: data.result.servico.id,
				label: data.result.servico.nome
			},
			workShift: {
				value: data.result.turno.id,
				label: data.result.turno.nome
			},
			workPlace: {
				value: data.result.localDeTrabalho.id,
				label: data.result.localDeTrabalho.nome
			},
			totalHourMeter: Utils.formatHour(data.result.horimetroTotal),
			startHourMeter: data.result.horimetroInicio,
			finalHourMeter: data.result.horimetroFim,
			hourMan: Utils.formatHour(data.result.horaHomem),
			description: data.result.descricao
		});


		setValue('id', data.result.id);
		setValue('date', form.date);
		setValue('safra', { value: data.result.safra.id, label: data.result.safra.nome });
		setValue('machine', { value: data.result.maquina.id, label: data.result.maquina.nome });
		setValue('operator', { value: data.result.operador.id, label: data.result.operador.nome });
		setValue('serviceGroup', { value: data.result.servicoTipo.id, label: data.result.servicoTipo.nome });
		setValue('service', { value: data.result.servico.id, label: data.result.servico.nome });
		setValue('workShift', { value: data.result.turno.id, label: data.result.turno.nome });
		setValue('workPlace', { value: data.result.localDeTrabalho.id, label: data.result.localDeTrabalho.nome });
		setValue('totalHourMeter', Utils.formatHour(data.result.horimetroTotal));
		setValue('startHourMeter', data.result.horimetroInicio);
		setValue('finalHourMeter', data.result.horimetroFim);
		setValue('hourMan', Utils.formatHour(data.result.horaHomem));
		setValue('description', data.result.descricao);

		if (data.isOk) {
			let returnEntry = await apontamentoInsumosService.buscarPorIdApontamentoMaquina(data.result.id);
			let insumos = []
			returnEntry.data.result.map(item => {
				let novoInsumo = { ...item }
				novoInsumo.unidade = { id: novoInsumo.unidade.id, sigla: novoInsumo.unidade.sigla, value: novoInsumo.unidade.id, label: novoInsumo.unidade.sigla, unidadeConversao: novoInsumo.unidade.unidadeConversao }
				let units = ObjectHelper.parseValuesUnitsToSelect(
					item.unidade
				);
				novoInsumo.unidades = units
				return insumos.push(novoInsumo)
			})
			setListEntrys(insumos);
		}

		props.getServicesByGroupId({
			farmId: JSON.parse(localStorage.getItem('fazendaId')).value,
			groupServiceId: data.result.servicoTipo.id
		})

		buscarLocais(data.result.safra.id);

		return data;
	}

	const submitForm = async (voltar) => {
		if (typeof (voltar) === "object") return false;
		if (form.date > new Date()) {
			ToastHelper.error("A 'Data' do apontamento não pode ser uma data futura!");
			return false;
		}
		if (!form.workShift || !form.service || !form.serviceGroup) {
			return false;
		}
		if (form.machine && form.date) {
			if (
				validateStartHourMeter(form.machine.horimetroAtivo, errorHourMeter, setErrorHourMeter, form) &&
				validateFinalHourMeter(form.machine.horimetroAtivo, errorHourMeter, setErrorHourMeter, form) &&
				validateTotalHourMeter(form.machine.horimetroAtivo, errorHourMeter, setErrorHourMeter, form)
			) {

				let fazenda = JSON.parse(localStorage.getItem('fazendaId')).value

				let locaisSafra = [];
				if (params.id) {
					locaisSafra.push(form.workPlace.value)
				} else {

					form.workPlace.forEach(item => {
						locaisSafra.push(item.value)
					})
				}

				let apontamento = {
					id: form.id ? form.id : undefined,
					ativo: true,
					dataCriacao: form.date,
					date: form.date,
					horimetroInicio: form.startHourMeter ? form.startHourMeter.toString() : "",
					horimetroFim: form.finalHourMeter ? form.finalHourMeter.toString() : "",
					horimetroTotal: form.totalHourMeter ? form.totalHourMeter.toString() : "",
					horaHomem: form.hourMan,
					descricao: form.description,
					fazendaId: fazenda,
					maquinaId: form.machine ? form.machine.value : '',
					turnoId: form.workShift ? form.workShift.value : '',
					safraId: form.safra.value !== undefined ? form.safra.value : ObjectHelper.primeiraSafra(),
					pessoaId: form.operator ? form.operator.value : '',
					locaisSafraId: locaisSafra,
					areaId: params.id ? locaisSafra[0] : locaisSafra[0],
					servicoId: form.service ? form.service.value : '',
					servicoTipoId: form.serviceGroup ? form.serviceGroup.value : ''
				}

				listEntrys.forEach(item => {
					item.local = form.workPlace.value;
				});

				if (params.id === undefined) {
					const { data } = await apontamentoMaquinaService.salvar(apontamento);
					if (data.isOk) {
						ToastHelper.success("Apontamentos criado com sucesso!");
						data.result.idsApsMaquinasLocais.forEach(async itemSalvo => {
							if (listEntrys.length > 0) {
								let newEntryAppointments = [];
								listEntrys.forEach(item => {
									if (!item.value && data.result) {
										newEntryAppointments.push({
											data: apontamento.date,
											safraId: apontamento.safraId,
											fazendaId: apontamento.fazendaId,
											descricao: apontamento.descricao,
											localId: itemSalvo.idLocal,
											responsavelId: apontamento.pessoaId,
											servicoId: apontamento.servicoId,
											servicoTipoId: apontamento.servicoTipoId,
											apontamentoMaquinaId: itemSalvo.idApontamento,
											insumosId: item.recurso.insumosId,
											quantidade: item.quantidade / apontamento.locaisSafraId.length,
											unidadeId: item.unidade.id || item.unidade.value
										});
									}
								});
								let returnEntry = undefined;
								if (newEntryAppointments.length > 0) {
									returnEntry = await apontamentoInsumosService.salvar({ apontamentos: newEntryAppointments });
								}
								if (
									(returnEntry ? returnEntry.data.isOk : false) &&
									newEntryAppointments.length > 0
								) {
									ToastHelper.success(
										"Levantamentos de Insumos cadastrado com sucesso!"
									);
								} else if (newEntryAppointments.length > 0) {
									ToastHelper.error(
										"Erro ao tentar salvar apontamentos de insumos."
									);

									let returnDelAppointment = await apontamentoMaquinaService.delete(itemSalvo.idApontamento);

									if (returnDelAppointment.data.isOk) {
										ToastHelper.success(
											"Apontamento de máquina deletado devido ao erro ocorrido."
										);
									} else {
										ToastHelper.error(
											"Erro ao tentar apagar o apontamento de máquina criado."
										);
									}
								}
							}
						});

						if (!voltar) {
							data.result.maquina.value = data.result.maquina.id;
							setValue('startHourMeter', data.result.maquina.horimetroAtual);
							setValue('finalHourMeter', undefined);
							setValue('hourMan', undefined);
							setValue('totalHourMeter', undefined);
							setValue('workPlace', undefined);
							window.scrollTo(0, 0);
							var maquina = data.result.maquina;
							maquina.label = data.result.maquina.nome;
							maquina.value = data.result.maquina.id
							setForm(
								{
									...form,
									machine: maquina,
									startHourMeter: maquina.horimetroAtual,
									finalHourMeter: null,
									totalHourMeter: '',
									workPlace: null,
									hourMan: ''
								}
							)
						} else {
							props.history.goBack();
						}
					} else {
						ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
					}

				} else {
					props.updateMachineAppointment(apontamento, listEntrys, props.history);
				}
			}
		}
	};

	const addInsumo = useCallback(async (item) => {
		setListEntrys([...listEntrys, item]);
	});

	const editInsumo = useCallback(async (item) => {
		setListEntrys([...item]);
	});

	const deleteInsumo = useCallback(async (item) => {
		setListEntrys([...item]);
	});

	const [optionServicos, setOptionServicos] = useState([])
	const [workPlaces, setWorkPlaces] = useState([])

	const buscarLocais = async () => {
		const { data } = await safraService.locais(
			JSON.parse(localStorage.getItem('fazendaId')).value,
			form.safra ? form.safra.value : ObjectHelper.primeiraSafra(),
			true
		)

		if (form.machine && form.machine.grupoLocal) {
			var sugeridos = [
				{
					label: form.machine.grupoLocal,
					options: ObjectHelper.getValuesParseToSelect(data.result.filter(item => item.grupoLocaisId === form.machine.grupoLocalId))
				},
				{
					label: "Todos",
					options: ObjectHelper.getValuesParseToSelect(data.result)
				}
			]

			setWorkPlaces(sugeridos);
		} else {
			setWorkPlaces(ObjectHelper.getValuesParseToSelect(data.result));
		}
	}

	useEffect(() => {
		props.getOperators()
		let params = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			culturaId: JSON.parse(localStorage.getItem('safraId')).culturaId,
			order: "Nome"
		}
		props.getServiceGroups(params)
		props.getWorkShifts()

		props.getFamilyMaterials({
			farmId: JSON.parse(localStorage.getItem('fazendaId')).value,
			groupServiceId: 'undefined'
		})
	}, [])

	const getServiceByIdGroup = async (grupoServicoId) => {
		let { data } = await servicoService.buscarPorGrupoId(
			JSON.parse(localStorage.getItem("fazendaId")).value,
			grupoServicoId
		);

		atualizarServicosSugeridos(grupoServicoId, data.result)
	}

	const getMaterialsGroupsByServiceGroupId = (group) => {
		let params = {
			farmId: JSON.parse(localStorage.getItem('fazendaId')).value,
			groupServiceId: group.value ? group.value : group
		}
		props.getFamilyMaterials(params)
	}

	const [grupoServicos, setGrupoServicos] = useState([]);

	const apontamentoInteligente = async (params) => {
		if (params === null) return
		var filtro = {}
		filtro.safraId = form.safra.value !== undefined ? form.safra.value : ObjectHelper.primeiraSafra();
		filtro.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		var locais = [];
		params.forEach(item => {
			locais.push(item.value)
		})
		filtro.locaisId = locais;

		const { data } = await apontamentoMaquinaService.buscarServicosApontamentoInteligente(filtro);

		setGrupoServicos(data.result.grupoServicos);
		setServicosSugeridos(data.result.servicos);
	}

	const [servicosSugeridos, setServicosSugeridos] = useState([])

	const CustomGroupHeading = props => {
		return (
			<div
				className="group-heading-wrapper"
				onClick={() => handleHeaderClick(props.id)}
			>
				<components.GroupHeading {...props} />
			</div>
		);
	};

	const formatGroupLabel = data => (
		<div className="groupStyles">
			<span>{data.label}</span>
			<span className="groupBadgeStyles">{data.options.length}</span>
		</div>
	);

	const handleHeaderClick = id => {
		const node = document.querySelector(`#${id}`).parentElement
			.nextElementSibling;
		const classes = node.classList;
		if (classes.contains("grupoRecursosCollapsed")) {
			node.classList.remove("grupoRecursosCollapsed");
		} else {
			node.classList.add("grupoRecursosCollapsed");
		}
	};

	const groupedStyles = {
		menuPortal: base => ({ ...base, zIndex: 9999, width: "250px" }),
		groupHeading: provided => ({
			...provided,
			textTransform: "none",
			fontSize: 16,
			color: "black"
		}),
		option: provided => ({
			...provided
		})
	};

	const atualizarServicosSugeridos = (servicoTipoId, listaServicos) => {
		if (servicoTipoId === null) return
		var servicosSugeridosFiltrados = [];

		servicosSugeridos.forEach(item => {
			if (item.tipo === servicoTipoId)
				servicosSugeridosFiltrados.push(item);
		});

		let listaServicosFiltrados = Utils.ordernar(
			ObjectHelper.getValuesParseToSelect(listaServicos),
			"label"
		);

		if (servicosSugeridosFiltrados.length > 0) {
			var obj = [{
				label: "Serviços Sugeridos",
				options: servicosSugeridosFiltrados
			},
			{
				label: "Todos",
				options: listaServicosFiltrados
			}]
			setOptionServicos(obj)
		} else {
			setOptionServicos(listaServicosFiltrados)
		}
	}


	useEffect(() => {
		buscarLocais();
		setSelectedMachine(form.machine);
	}, [form.machine])

	useEffect(() => {
		if (!params.id && form.workPlace && form.service) {
			async function verificaInsumos() {
				let returnEntry = await apontamentoInsumosService.buscarApInsumosSugeridos({ locais: form.workPlace, servicoId: form.service.value });
				if (returnEntry.status === 200) {
					let insumos = []
					returnEntry.data.result.map(item => {
						let novoInsumo = { ...item }
						novoInsumo.unidade = { id: novoInsumo.unidade.id, sigla: novoInsumo.unidade.sigla, value: novoInsumo.unidade.id, label: novoInsumo.unidade.sigla, unidadeConversao: novoInsumo.unidade.unidadeConversao }
						let units = ObjectHelper.parseValuesUnitsToSelect(
							item.unidade
						);
						novoInsumo.unidades = units
						return insumos.push(novoInsumo)
					})

					if (insumos.length > 0) {
						setAlertaSugestoesInsumo('Os insumos foram sugeridos de acordo com o planejamento. Por favor, revise os itens sugeridos.')
					} else {
						setAlertaSugestoesInsumo(null);
					}

					setListEntrys(insumos);
				}
			}
			verificaInsumos()
		}
	}, [form.workPlace, form.service]);


	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							{visualize ? 'Visualizar ' :
								(params.id ? 'Editar ' :
									'Cadastrar '
								)} apontamento de máquina
						</Title>
					</div>
				</div>
				<RegisterHead
					disabled={false}
					machineOptions={Utils.removeInactiveToSelect(props.machines || [])}
					machine={form.machine}
					setMachine={(e) => setForm({ ...form, machine: e })}
					date={form.date}
					setSafras={(e) => setForm({ ...form, safra: e })}
					setDate={(e) => setForm({ ...form, date: e })}
					form={form}
					visualize={visualize}
					edit={edit}
				/>
				{
					form.machine && form.date &&
					<div className="w-100 mb-3">
						<CardStyle className="card mb-3">
							<div className="card-header"> Dados do apontamento </div>
							<div className="card-body">
								<section id="tabs" className="project-tab">
									<div className="row">
										<div className="col-md-12">
											<ul className="nav nav-tabs" role="tablist">
												<li className="nav-item">
													<a className="nav-item nav-link active show"
														id="nav-servicos-tab" href="#servicos"
														role="tab" data-toggle="tab"
														aria-controls="nav-servicos"
														aria-selected="true">Serviço
                                  					</a>
												</li>
											</ul>
											<div className="tab-content tap-content-auto-height bmd-form-group mb-4">
												<div className="tab-pane fade show active bmd-form-group " id="servicos" role="tabpanel" >
													<div className="col-md-12 flex">
														<div className="card-header col-md-6">
															<label className="label"> Operadores:* </label>
															<Select
																name="operator"
																placeholder='Selecionar'
																className="select-style ajust-label-padrao disabled-select"
																isDisabled={visualize}
																// isMulti={!props.edit}
																options={props.operators}
																value={form.operator}
																ref={() => register({ name: "operator" }, { required: true })}
																onChange={item => {
																	setValue('operator', item);
																	setForm({ ...form, operator: item });
																}}
																theme={theme => ({ ...theme, borderRadius: 0 })}
															/>
															{errors.operator && <span className="span-errors">Campo obrigatório</span>}
														</div>
														<div className="card-header col-md-6">
															<label className="label"> Locais de trabalho:* </label>
															<Select
																styles={groupedStyles}
																menuPortalTarget={document.body}
																components={{ GroupHeading: CustomGroupHeading }}
																formatGroupLabel={formatGroupLabel}
																name="workPlace"
																placeholder='Selecionar'
																className="select-style ajust-label-padrao disabled-select"
																isDisabled={visualize}
																isMulti={!params.id}
																options={workPlaces}
																value={form.workPlace}
																ref={() => register({ name: "workPlace" }, { required: true })}
																onChange={item => {
																	setValue('workPlace', item);
																	apontamentoInteligente(params.id ? [item] : item);
																	setForm({ ...form, workPlace: item });
																}}
																theme={theme => ({ ...theme, borderRadius: 0 })}
															/>
															{errors.workPlace && <span className="span-errors">Campo obrigatório</span>}
														</div>

													</div>
													<div className="col-md-12 flex">
														<div className="card-header col-md-4">
															<label className="label"> Grupo de Serviço:* </label>
															<Select
																styles={groupedStyles}
																menuPortalTarget={document.body}
																components={{ GroupHeading: CustomGroupHeading }}
																formatGroupLabel={formatGroupLabel}
																className="select-style ajust-label-padrao disabled-select"
																name="serviceGroup"
																placeholder='Selecionar'
																isDisabled={visualize}
																options={grupoServicos.length > 0 ? [{
																	label: "Grupos de Serviço Sugeridos",
																	options: grupoServicos
																},
																{
																	label: "Todos",
																	options: props.serviceGroup
																}] : props.serviceGroup}
																value={form.serviceGroup}
																ref={() => register({ name: "serviceGroup" }, { required: true })}
																onChange={item => {
																	getMaterialsGroupsByServiceGroupId(item);
																	getServiceByIdGroup(item.value);
																	setValue('serviceGroup', item);
																	setForm({ ...form, serviceGroup: item, service: null });
																}}
																theme={theme => ({ ...theme, borderRadius: 0 })}
															/>
															{errors.serviceGroup && <span className="span-errors">Campo obrigatório</span>}

														</div>
														<div className="card-header col-md-4">
															<label className="label"> Serviço:* </label>
															<Select
																styles={groupedStyles}
																menuPortalTarget={document.body}
																components={{ GroupHeading: CustomGroupHeading }}
																formatGroupLabel={formatGroupLabel}
																name="service"
																placeholder='Selecionar'
																className="select-style ajust-label-padrao disabled-select"
																isDisabled={visualize || optionServicos.length < 1}
																options={optionServicos}
																ref={() => register({ name: "service" }, { required: true })}
																value={form.service ? form.service : null}
																onChange={item => {
																	setValue('service', item);
																	setForm({ ...form, service: item });
																}}
																theme={theme => ({ ...theme, borderRadius: 0 })}
															/>
															{errors.service && <span className="span-errors">Campo obrigatório</span>}
														</div>
														<div className="card-header col-md-4">
															<label className="label"> Turno:* </label>
															<Select
																name="workShift"
																placeholder='Selecionar'
																className="select-style ajust-label-padrao disabled-select"
																isDisabled={visualize}
																options={props.workShifts}
																value={form.workShift}
																ref={() => register({ name: "workShift" }, { required: true })}
																onChange={item => {
																	setValue('workShift', item);
																	setForm({ ...form, workShift: item });
																}}
																theme={theme => ({ ...theme, borderRadius: 0 })}
															/>
															{errors.workShift && <span className="span-errors">Campo obrigatório</span>}
														</div>
													</div>
													<FormHourMeter
														visualize={visualize}
														selectedMachine={selectedMachine || {}}
														setForm={setForm}
														form={form}
														errors={errors}
														setErrors={props.setErrors}
														errorHourMeter={errorHourMeter}
														setErrorHourMeter={setErrorHourMeter}
														setValue={setValue}
														register={register}
														params={params}
													/>
													<div className="col-md-12 flex">
														<div className="card-header col-md-12">
															<label className="label">
																Descrição:
                                    						</label>
															<input type='textarea'
																className='form-control bmd-form-group'
																readOnly={visualize}
																name='description'
																htmlFor='description'
																value={form.description}
																autoComplete="off"
																onChange={item => {
																	setValue('description', item.target.value);
																	setForm({ ...form, description: item.target.value });
																}}
																ref={() => register({ name: "description" }, { required: false })}
															/>
														</div>
													</div>
												</div>
												{/*<div role="tabpanel" className="tab-pane fade" id="perda">
													Apontamento de perda
												</div>*/}
											</div>
										</div>
									</div>
								</section>
							</div>
						</CardStyle>
					</div>
				}
				{alertaSugestoesInsumo && listEntrys.length > 0 && <span className="span-errors">{alertaSugestoesInsumo}</span>}
				{
					form.machine && form.date &&
					<FormEntry
						insumos={listEntrys}
						addInsumo={addInsumo}
						editInsumo={editInsumo}
						deleteInsumo={deleteInsumo}
						visualize={visualize}
					/>
				}

				<button className="btn btn-primary active pull-right" type="submit" onClick={() => submitForm(false)} hidden={props.flagVisualizar}>
					<i className='fas fa-check' /> {params.id ? 'Salvar' : 'Cadastrar'}
				</button>
				<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => props.history.goBack()}>
					<i className='fas fa-close' /> {visualize ? 'Voltar' : 'Cancelar'}
				</button>
				{
					!params.id && <button className="btn btn-primary active pull-right" type="submit" name="button_1" onClick={() => submitForm(true)} hidden={props.flagVisualizar} style={{ marginRight: 10 }}>
						<i className='fas fa-check' /> {params.id ? 'Salvar e Voltar' : 'Cadastrar e voltar'}
					</button>
				}

			</form>
		</>
	);
}

const mapStateToProps = (state) => {

	return ({
		appointment: state.appointmentMachines.appointment,
		entrys: state.appointmentMachines.entrys,
		machines: state.appointmentMachines.machines,
		workPlaces: state.appointmentMachines.workPlaces,
		operators: state.appointmentMachines.operators,
		serviceGroup: state.appointmentMachines.serviceGroup ? Utils.removeInactiveToSelect(ObjectHelper.getValuesParseToSelect(state.appointmentMachines.serviceGroup.items)) : undefined,
		services: state.appointmentMachines.services ? Utils.removeInactiveToSelect(ObjectHelper.getValuesParseToSelect(state.appointmentMachines.services)) : [],
		workShifts: state.appointmentMachines.workShifts ? state.appointmentMachines.workShifts : [],
	});
}

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MachineAppointmentRegister)
