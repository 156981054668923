export const PERMISSAO = {
    MODULO_APONTAMENTO_MAO_OBRA_LEITURA:"MODULO_APONTAMENTO_MAO_OBRA.LEITURA",
    MODULO_APONTAMENTO_MAO_OBRA_EXCLUIR:"MODULO_APONTAMENTO_MAO_OBRA.EXCLUIR",
    MODULO_APONTAMENTO_MAO_OBRA_ATUALIZAR:"MODULO_APONTAMENTO_MAO_OBRA.ATUALIZAR",
    MODULO_APONTAMENTO_MAO_OBRA_CRIAR:"MODULO_APONTAMENTO_MAO_OBRA.CRIAR",

    MODULO_APONTAMENTO_MAQUINA_EXCLUIR:"MODULO_APONTAMENTO_MAQUINA.EXCLUIR",
    MODULO_APONTAMENTO_MAQUINA_ATUALIZAR:"MODULO_APONTAMENTO_MAQUINA.ATUALIZAR",
    MODULO_APONTAMENTO_MAQUINA_CRIAR:"MODULO_APONTAMENTO_MAQUINA.CRIAR",
    MODULO_APONTAMENTO_MAQUINA_LEITURA:"MODULO_APONTAMENTO_MAQUINA.LEITURA",

    MODULO_MAQUINA_EXCLUIR:"MODULO_MAQUINA.EXCLUIR",
    MODULO_MAQUINA_ATUALIZAR:"MODULO_MAQUINA.ATUALIZAR",
    MODULO_MAQUINA_CRIAR:"MODULO_MAQUINA.CRIAR",
    MODULO_MAQUINA_LEITURA:"MODULO_MAQUINA.LEITURA",

    MODULO_EQUIPE_EXCLUIR:"MODULO_EQUIPE.EXCLUIR",
    MODULO_EQUIPE_ATUALIZAR:"MODULO_EQUIPE.ATUALIZAR",
    MODULO_EQUIPE_CRIAR:"MODULO_EQUIPE.CRIAR",
    MODULO_EQUIPE_LEITURA:"MODULO_EQUIPE.LEITURA",

    MODULO_USUARIO_EXCLUIR:"MODULO_USUARIO.EXCLUIR",
    MODULO_USUARIO_ATUALIZAR:"MODULO_USUARIO.ATUALIZAR",
    MODULO_USUARIO_CRIAR:"MODULO_USUARIO.CRIAR",
    MODULO_USUARIO_LEITURA:"MODULO_USUARIO.LEITURA",

    MODULO_PERFIL_EXCLUIR:"MODULO_PERFIL.EXCLUIR",
    MODULO_PERFIL_ATUALIZAR:"MODULO_PERFIL.ATUALIZAR",
    MODULO_PERFIL_CRIAR:"MODULO_PERFIL.CRIAR",
    MODULO_PERFIL_LEITURA:"MODULO_PERFIL.LEITURA",

    MODULO_PESSOA_EXCLUIR:"MODULO_PESSOA.EXCLUIR",
    MODULO_PESSOA_ATUALIZAR:"MODULO_PESSOA.ATUALIZAR",
    MODULO_PESSOA_CRIAR:"MODULO_PESSOA.CRIAR",
    MODULO_PESSOA_LEITURA:"MODULO_PESSOA.LEITURA",

    MODULO_CATEGORIA_PESSOA_EXCLUIR:"MODULO_PESSOA_CATEGORIA.EXCLUIR",
    MODULO_CATEGORIA_PESSOA_ATUALIZAR:"MODULO_PESSOA_CATEGORIA.ATUALIZAR",
    MODULO_CATEGORIA_PESSOA_CRIAR:"MODULO_PESSOA_CATEGORIA.CRIAR",
    MODULO_CATEGORIA_PESSOA_LEITURA:"MODULO_PESSOA_CATEGORIA.LEITURA",

    MODULO_CULTURA_EXCLUIR:"MODULO_CULTURA.EXCLUIR",
    MODULO_CULTURA_ATUALIZAR:"MODULO_CULTURA.ATUALIZAR",
    MODULO_CULTURA_CRIAR:"MODULO_CULTURA.CRIAR",
    MODULO_CULTURA_LEITURA:"MODULO_CULTURA.LEITURA",

    MODULO_GRUPO_DE_SERVICOS_EXCLUIR:"MODULO_GRUPO_DE_SERVICOS.EXCLUIR",
    MODULO_GRUPO_DE_SERVICOS_ATUALIZAR:"MODULO_GRUPO_DE_SERVICOS.ATUALIZAR",
    MODULO_GRUPO_DE_SERVICOS_CRIAR:"MODULO_GRUPO_DE_SERVICOS.CRIAR",
    MODULO_GRUPO_DE_SERVICOS_LEITURA:"MODULO_GRUPO_DE_SERVICOS.LEITURA",

    MODULO_RECURSOS_EXCLUIR:"MODULO_RECURSOS.EXCLUIR",
    MODULO_RECURSOS_ATUALIZAR:"MODULO_RECURSOS.ATUALIZAR",
    MODULO_RECURSOS_CRIAR:"MODULO_RECURSOS.CRIAR",
    MODULO_RECURSOS_LEITURA:"MODULO_RECURSOS.LEITURA",

    MODULO_UNIDADE_EXCLUIR:"MODULO_UNIDADE.EXCLUIR",
    MODULO_UNIDADE_ATUALIZAR:"MODULO_UNIDADE.ATUALIZAR",
    MODULO_UNIDADE_CRIAR:"MODULO_UNIDADE.CRIAR",
    MODULO_UNIDADE_LEITURA:"MODULO_UNIDADE.LEITURA",

    MODULO_TIPO_MAQUINA_EXCLUIR:"MODULO_TIPO_MAQUINA.EXCLUIR",
    MODULO_TIPO_MAQUINA_ATUALIZAR:"MODULO_TIPO_MAQUINA.ATUALIZAR",
    MODULO_TIPO_MAQUINA_CRIAR:"MODULO_TIPO_MAQUINA.CRIAR",
    MODULO_TIPO_MAQUINA_LEITURA:"MODULO_TIPO_MAQUINA.LEITURA",

    MODULO_SERVICO_EXCLUIR:"MODULO_SERVICO.EXCLUIR",
    MODULO_SERVICO_ATUALIZAR:"MODULO_SERVICO.ATUALIZAR",
    MODULO_SERVICO_CRIAR:"MODULO_SERVICO.CRIAR",
    MODULO_SERVICO_LEITURA:"MODULO_SERVICO.LEITURA",

    MODULO_TURNO_EXCLUIR:"MODULO_TURNO.EXCLUIR",
    MODULO_TURNO_ATUALIZAR:"MODULO_TURNO.ATUALIZAR",
    MODULO_TURNO_CRIAR:"MODULO_TURNO.CRIAR",
    MODULO_TURNO_LEITURA:"MODULO_TURNO.LEITURA",

    MODULO_MODULO_CRIAR:"MODULO_MODULO.CRIAR",
    MODULO_MODULO_LEITURA:"MODULO_MODULO.LEITURA",
    MODULO_MODULO_ATUALIZAR:"MODULO_MODULO.ATUALIZAR",
    MODULO_MODULO_EXCLUIR:"MODULO_MODULO.EXCLUIR",

    MODULO_LOCAL_CRIAR:"MODULO_LOCAL.CRIAR",
    MODULO_LOCAL_LEITURA:"MODULO_LOCAL.LEITURA",
    MODULO_LOCAL_ATUALIZAR:"MODULO_LOCAL.ATUALIZAR",
    MODULO_LOCAL_EXCLUIR:"MODULO_LOCAL.EXCLUIR",

    MODULO_COMPOSICAO_EXCLUIR:"MODULO_COMPOSICAO.EXCLUIR",
    MODULO_COMPOSICAO_ATUALIZAR:"MODULO_COMPOSICAO.ATUALIZAR",
    MODULO_COMPOSICAO_CRIAR:"MODULO_COMPOSICAO.CRIAR",
    MODULO_COMPOSICAO_LEITURA:"MODULO_COMPOSICAO.LEITURA",

    MODULO_PARAMETRO_LAVOURA_EXCLUIR:"MODULO_PARAMETRO_LAVOURA.EXCLUIR",
    MODULO_PARAMETRO_LAVOURA_ATUALIZAR:"MODULO_PARAMETRO_LAVOURA.ATUALIZAR",
    MODULO_PARAMETRO_LAVOURA_CRIAR:"MODULO_PARAMETRO_LAVOURA.CRIAR",
    MODULO_PARAMETRO_LAVOURA_LEITURA:"MODULO_PARAMETRO_LAVOURA.LEITURA",

    MODULO_SAFRA_EXCLUIR:"MODULO_SAFRA.EXCLUIR",
    MODULO_SAFRA_ATUALIZAR:"MODULO_SAFRA.ATUALIZAR",
    MODULO_SAFRA_CRIAR:"MODULO_SAFRA.CRIAR",
    MODULO_SAFRA_LEITURA:"MODULO_SAFRA.LEITURA",

    MODULO_AFASTAMENTOS_EXCLUIR:"MODULO_AFASTAMENTOS.EXCLUIR",
    MODULO_AFASTAMENTOS_ATUALIZAR:"MODULO_AFASTAMENTOS.ATUALIZAR",
    MODULO_AFASTAMENTOS_CRIAR:"MODULO_AFASTAMENTOS.CRIAR",
    MODULO_AFASTAMENTOS_LEITURA:"MODULO_AFASTAMENTOS.LEITURA",

    MODULO_PAINEL_DE_CONTROLE_EXCLUIR:"MODULO_PAINEL_DE_CONTROLE.EXCLUIR",
    MODULO_PAINEL_DE_CONTROLE_ATUALIZAR:"MODULO_PAINEL_DE_CONTROLE.ATUALIZAR",
    MODULO_PAINEL_DE_CONTROLE_CRIAR:"MODULO_PAINEL_DE_CONTROLE.CRIAR",
    MODULO_PAINEL_DE_CONTROLE_LEITURA:"MODULO_PAINEL_DE_CONTROLE.LEITURA",

    MODULO_CALENDARIO_EXCLUIR:"MODULO_CALENDARIO.EXCLUIR",
    MODULO_CALENDARIO_ATUALIZAR:"MODULO_CALENDARIO.ATUALIZAR",
    MODULO_CALENDARIO_CRIAR:"MODULO_CALENDARIO.CRIAR",
    MODULO_CALENDARIO_LEITURA:"MODULO_CALENDARIO.LEITURA",

    MODULO_PLANEJAMENTO_SAFRA_EXCLUIR:"MODULO_PLANEJAMENTO_SAFRA.EXCLUIR",
    MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR:"MODULO_PLANEJAMENTO_SAFRA.ATUALIZAR",
    MODULO_PLANEJAMENTO_SAFRA_CRIAR:"MODULO_PLANEJAMENTO_SAFRA.CRIAR",
    MODULO_PLANEJAMENTO_SAFRA_LEITURA:"MODULO_PLANEJAMENTO_SAFRA.LEITURA",

    MODULO_TEMPLATE_PLANEJAMENTO_EXCLUIR:"MODULO_TEMPLATE_PLANEJAMENTO.EXCLUIR",
    MODULO_TEMPLATE_PLANEJAMENTO_ATUALIZAR:"MODULO_TEMPLATE_PLANEJAMENTO.ATUALIZAR",
    MODULO_TEMPLATE_PLANEJAMENTO_CRIAR:"MODULO_TEMPLATE_PLANEJAMENTO.CRIAR",
    MODULO_TEMPLATE_PLANEJAMENTO_LEITURA:"MODULO_TEMPLATE_PLANEJAMENTO.LEITURA",

    MODULO_APONTAMENTO_INSUMOS_EXCLUIR:"MODULO_APONTAMENTO_INSUMOS.EXCLUIR",
    MODULO_APONTAMENTO_INSUMOS_ATUALIZAR:"MODULO_APONTAMENTO_INSUMOS.ATUALIZAR",
    MODULO_APONTAMENTO_INSUMOS_CRIAR:"MODULO_APONTAMENTO_INSUMOS.CRIAR",
    MODULO_APONTAMENTO_INSUMOS_LEITURA:"MODULO_APONTAMENTO_INSUMOS.LEITURA",

    MODULO_INSUMOS_EXCLUIR:"MODULO_INSUMOS.EXCLUIR",
    MODULO_INSUMOS_ATUALIZAR:"MODULO_INSUMOS.ATUALIZAR",
    MODULO_INSUMOS_CRIAR:"MODULO_INSUMOS.CRIAR",
    MODULO_INSUMOS_LEITURA:"MODULO_INSUMOS.LEITURA",

    MODULO_GRUPO_MAQUINA_EXCLUIR:"MODULO_GRUPO_MAQUINA.EXCLUIR",
    MODULO_GRUPO_MAQUINA_ATUALIZAR:"MODULO_GRUPO_MAQUINA.ATUALIZAR",
    MODULO_GRUPO_MAQUINA_CRIAR:"MODULO_GRUPO_MAQUINA.CRIAR",
    MODULO_GRUPO_MAQUINA_LEITURA:"MODULO_GRUPO_MAQUINA.LEITURA",

    MODULO_FAMILIA_DE_INSUMOS_EXCLUIR:"MODULO_FAMILIA_DE_INSUMOS.EXCLUIR",
    MODULO_FAMILIA_DE_INSUMOS_ATUALIZAR:"MODULO_FAMILIA_DE_INSUMOS.ATUALIZAR",
    MODULO_FAMILIA_DE_INSUMOS_CRIAR:"MODULO_FAMILIA_DE_INSUMOS.CRIAR",
    MODULO_FAMILIA_DE_INSUMOS_LEITURA:"MODULO_FAMILIA_DE_INSUMOS.LEITURA",

    MODULO_INSUMOS_ANALISE_SOLO_EXCLUIR:"MODULO_INSUMOS_ANALISE_SOLO.EXCLUIR",
    MODULO_INSUMOS_ANALISE_SOLO_ATUALIZAR:"MODULO_INSUMOS_ANALISE_SOLO.ATUALIZAR",
    MODULO_INSUMOS_ANALISE_SOLO_CRIAR:"MODULO_INSUMOS_ANALISE_SOLO.CRIAR",
	MODULO_INSUMOS_ANALISE_SOLO_LEITURA:"MODULO_INSUMOS_ANALISE_SOLO.LEITURA",

	MODULO_FAZENDAS_EXCLUIR:"MODULO_FAZENDAS.EXCLUIR",
    MODULO_FAZENDAS_ATUALIZAR:"MODULO_FAZENDAS.ATUALIZAR",
    MODULO_FAZENDAS_CRIAR:"MODULO_FAZENDAS.CRIAR",
	MODULO_FAZENDAS_LEITURA:"MODULO_FAZENDAS.LEITURA",

    MODULO_RELATORIOS_LEITURA:"MODULO_RELATORIOS.LEITURA",
}
