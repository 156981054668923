import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { Types } from "./afastamento.ducks";
import executeAction from "../../../store/sagas/executeAction";
import TipoAfastamentoService from '../../../services/tipoAfastamentoService';
import AfastamentoService from '../../../services/afastamentoService';
import ToastHelper from '../../../utils/toastHelper';

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	filtro: '',
	pageSize: 99999,
	order: "Nome"
}

function* asyncListTiposAfastamentos(action) {
	yield executeAction(function* () {
		const { callback } = action;
		let dados = yield call(async () => await TipoAfastamentoService.buscarTodos(parametros));
		yield put({ type: Types.SET_TIPOS_AFASTAMENTOS, data: dados.data.result.items });
		callback();
	}, Types.GET_TIPOS_AFASTAMENTOS);
}

function* asyncCreateAfastamento(action) {
	yield executeAction(function* () {
		const { params, history, callback } = action;
		const { data } = yield call(async () => await AfastamentoService.salvar(params));

		if (data.isOk) {
			ToastHelper.success("Operação concluída com sucesso!")
			history.goBack()
		} else ToastHelper.error(data.mensagem)

		callback();
	}, Types.CREATE_AFASTAMENTO);
}

function* asyncListAfastamentos(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		let { data } = yield call(async () => await AfastamentoService.buscar(params ? params : parametros));
		data.result.items.forEach(item => {
			item.dataCriacao = item.dataCriacao.split(' ')[0];
			item.dataInicio = item.dataInicio.split(' ')[0];
			item.dataFim = item.dataFim.split(' ')[0];
		})
		yield put({ type: Types.SET_AFASTAMENTOS, data: data.result });
		callback();
	}, Types.GET_AFASTAMENTOS);
}

function* getAfastamentoById(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(async () => await AfastamentoService.buscarPorId(params));
		yield put({
			type: Types.SET_AFASTAMENTO_BY_ID,
			data: data.result.result
		});
		callback();
	}, Types.GET_AFASTAMENTO_BY_ID);
}

function* updateAfastamento(action) {
	yield executeAction(function* () {
		const { params, history } = action;
		if (params) {
			const { data } = yield call(async () => await AfastamentoService.editar(params));
			history.goBack()
			if (data.isOk) {
				ToastHelper.success('Afastamento atualizada com sucesso!')
			} else
				ToastHelper.error("Ocorreu um erro.");
			yield put({
				type: Types.UPDATE_AFASTAMENTO,
				data
			});
		}
	}, Types.UPDATE_AFASTAMENTO);
}

function* deleteAfastamento(action) {
	yield executeAction(function* () {
		const { params, callback } = action;
		const { data } = yield call(async () => await AfastamentoService.deletar(params));
		if (data.isOk)
			ToastHelper.success('Afastamento excluído com sucesso!');
		else
			ToastHelper.error("Ocorreu um erro.");
		callback();
	}, Types.DELETE_AFASTAMENTO);
}

function* takeTiposAfastamentos() {
	yield takeLatest([Types.GET_TIPOS_AFASTAMENTOS], asyncListTiposAfastamentos);
}

function* takeListAfastamentos() {
	yield takeLatest(Types.GET_AFASTAMENTOS, asyncListAfastamentos);
}

function* takeCreateAfastamento() {
	yield takeLatest(Types.CREATE_AFASTAMENTO, asyncCreateAfastamento);
}

function* takeGetAfastamentoById() {
	yield takeLatest(Types.GET_AFASTAMENTO_BY_ID, getAfastamentoById);
}

function* takeUpdateAfastamentoById() {
	yield takeLatest([Types.UPDATE_AFASTAMENTO], updateAfastamento);
}

function* takeDeleteAfastamento() {
	yield takeLatest([Types.DELETE_AFASTAMENTO], deleteAfastamento);
}

export default function* root() {
	yield all([fork(takeTiposAfastamentos)])
	yield all([fork(takeListAfastamentos)])
	yield all([fork(takeCreateAfastamento)])
	yield all([fork(takeGetAfastamentoById)])
	yield all([fork(takeUpdateAfastamentoById)])
	yield all([fork(takeDeleteAfastamento)])
}
