import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { ParametrosLavouraSchema } from './parametros.da.lavoura.validate';
import ParametroLavouraService from '../../services/parametroLavouraService'
import ToastHelper from "../../utils/toastHelper";
import Select from 'react-select';
import CulturaService from '../../services/culturaService'
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';


export default class ParametroLavouraCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: undefined,
				nome: undefined,
				descricao: undefined,
				culturaId: undefined,
			},
			culturas: [],
			visualizar: false,
			editar: false,
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		this.getValuesParseToSelect = this.getValuesParseToSelect.bind(this);
		this.getCulturas = this.getCulturas.bind(this)
		this.getCulturas()

		const { match: { params } } = this.props;
		if (params.id) {
			await this.buscar(params.id);
		}
		await this.setOperacao()
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	async getCulturas() {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 0,
			pageSize: 10
		}
		let data = await CulturaService.buscarTodas(param);
		await this.setState({ culturas: this.getValuesParseToSelect(data.data.result.items) })
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];
		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	async buscar(paramId) {
		let { data } = await ParametroLavouraService.buscarPorId(paramId);
		await this.setState({
			dados: { ...data.result },
			editar: true,
		});
	}

	voltar = () => {
		this.history.goBack();
	}

	onSubmit = async (values, options) => {
		let metodo = this.state.editar ? "editar" : "salvar";
		let acao = this.state.editar ? "editada" : "cadastrada";
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;
		const { data } = await ParametroLavouraService[metodo](values);

		if (data.isOk) {
			ToastHelper.success('Parâmetro da lavoura ' + acao + ' com sucesso!');
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
	}

	render() {
		const { state: { dados } } = this;
		return (
			<>
				<Formik
					initialValues={dados}
					enableReinitialize
					validationSchema={ParametrosLavouraSchema || null}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({ values, setFieldValue, handleSubmit, handleChange }) => (
						<form className="form " onSubmit={handleSubmit}>
							<div>
								<div className='row'>
									<div className='col'>
										<Title>{(this.titulo()) + ' Parâmetro da Lavoura'}</Title>
									</div>
								</div>
								<CardStyle className="card">
									<div className='card-header row justify-content-start col-md-12'>
										<div className="card-header col-md-12">
											<label className="label">
												Nome:*
                                    </label>
											<Field
												className='form-control bmd-form-group'
												disabled={this.state.visualizar}
												placeholder="Informe o nome"
												name="nome"
												maxLength="40"
												onChange={handleChange}
												value={values.nome}
												autoComplete="off"
												htmlFor="nome"
												type="text" />
											<ErrorMessage
												name="nome">
												{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Cultura:*
                                    </label>
											<Select id="culturaId"
												name="culturaId"
												htmlFor="culturaId"
												placeholder='Selecione uma cultura'
												className="select-style ajust-label-padrao disabled-select"
												isDisabled={this.state.visualizar}
												onChange={(e) => { setFieldValue('culturaId', e.value) }}
												value={this.state.culturas.filter(item => item.value === values.culturaId)}
												options={this.state.culturas}
												theme={theme => ({
													...theme,
													borderRadius: 0
												})}
											/>
											<ErrorMessage
												name="culturaId">
												{msg => <div className="error error-message error-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-12">
											<label className="label">
												Descrição:
                                    </label>
											<textarea
												className='form-control bmd-form-group'
												disabled={this.state.visualizar}
												placeholder="Informe a descrição do parâmetro"
												name="descricao"
												maxLength="200"
												onChange={handleChange}
												value={values.descricao}
												htmlFor="descricao"
												type="textarea"
											/>
										</div>
									</div>
								</CardStyle>
								<SubmitFormButtons
									flagVisualizar={this.state.visualizar}
									flagEditar={this.state.dados.id !== undefined}
									actionGoBack={() => this.voltar()}
								/>
							</div>
						</form>
					)}
				</Formik>
			</>
		)
	}
}
