import React from 'react';
import SafraService from 'services/safraService'
import LocalService from 'services/localService'
// import ParametroService from 'services/parametroLavouraService'
import UnidadesService from 'services/unidadeService'
// import ToastHelper from "utils/toastHelper";
import ObjectHelper from 'utils/objectHelper'
import CulturaService from 'services/culturaService'
import ParametrosLavouraService from 'services/parametroLavouraService'
import AtividadeAgricolaService from 'services/atividadeAgricolaService';
import GrupoSafraService from 'services/grupoSafraService'


const FAZENDA = JSON.parse(localStorage.getItem('fazendaId'));
export default class SafraRequests {
	static getGrupoSafra = async () => {
		const { data } = await GrupoSafraService.getAll(FAZENDA.value);
		return data.result;
	}

	static duplicarSafra = async (id) => {
		const { data } = await SafraService.duplicarSafra(id);
		return data;
	}

	static deletar = async (id) => {
		const { data } = await SafraService.deletar(id);
		return data;
	}

	static deletarLocal = async (id, safraId) => {
		const { data } = await SafraService.deletarLocal(id, safraId);
		return data;
	}

	static cadastrarLocal = async dados => {
		const { data } = await SafraService.cadastrarLocalSafra(dados);
		return data;
	}

	static cadastro = async (dados) => {
		let metodo = dados.id ? "editar" : "salvar";
		const { data } = await SafraService[metodo](dados);
		return data;
	}

	static salvarGrupoSafra = async (param) => {
		const { data } = await GrupoSafraService.create(param);
		return data;
	}

	static getUnidades = async () => {
		const { data } = await UnidadesService.getAll({ fazendaId: FAZENDA.value, order: "Sigla", filtro: "", page: 1, pageSize: 99999 });
		return ObjectHelper.getValuesUnitsParseToSelect(data.result.items);
	}

	static fazendaComAtividadeAgricola = async () => {
		if (JSON.parse(localStorage.getItem('fazendaId')).atividadeAgricolaId) {
			const { data } = await AtividadeAgricolaService.buscarPorId(FAZENDA.atividadeAgricolaId);
			if (data.result && data.result.nome === "Cafeicultura")
				return true
		}
		else
			return false;
	}

	static getParametros = async () => {
		const { data } = await ParametrosLavouraService.buscarTodos({ fazendaId: FAZENDA.value, order: "Nome", filtro: "", page: 1, pageSize: 99999 });
		return ObjectHelper.getValuesParseToSelect(data.result.items);
	}

	static getSafras = async (param) => {
		const { data } = await SafraService.buscarTodas(param);
		data.result.items.forEach(item => {
			if (item.ativo) {
				item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
			} else {
				item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
			}
		});
		return data.result;
	}

	static getCulturas = async () => {
		const { data } = await CulturaService.buscarTodas({ fazendaId: FAZENDA.value, order: "Nome", filtro: "", page: 1, pageSize: 99999 });
		return ObjectHelper.getValuesCulturaParseToSelect(data.result.items);
	}

	static getById = async (id) => {
		const { data } = await SafraService.buscarPorId(id);
		data.locais.forEach(item => {
			if (item.dataPlantio) {
				item.dataPlantio = item.dataPlantio.split(" ").shift()
				item.espacamento = item.espacamentoLinhas.toString() + " x " + item.espacamentoPlantas.toString()
			}
		})

		return data;
	}

	static buscarLocalSafraPorId = async id => {
		const { data } = await SafraService.buscarLocalSafraPorId(id);
		return data.result;
	}


	static getLocais = async () => {
		const { data } = await LocalService.buscarTodos({
			fazendaId: FAZENDA.value,
			page: 1,
			pageSize: 99999,
			ativo: true,
			administrativo: false,
			order: "Nome"
		});

		return (ObjectHelper.getValuesLocationParseToSelect(data.result.items))
	};

}
