import api from "./api";

export default {
	salvar(param) {
		return api.post("/api/Recursos", param);
	},
	editar(param) {
		return api.put("/api/Recursos", param);
	},
	get(id) {
		return api.get(`/api/Recursos/${id}`);
	},
	buscarTodas(param) {
		return api.post("/api/Recursos/BuscarTodas", param);
	},
	deletar(id) {
		return api.delete("/api/Recursos/" + id);
	},
	getClasseId(classeId) {
		return api.get("/api/Recursos/PorClasse/" + classeId);
	},
	getTipoId(tipoId) {
		return api.get("/api/Recursos/PorTipo/" + tipoId);
	},
	getPorCodigoTipo(params) {
		return api.post("/api/Recursos/PorCodigoTipo", params);
	},
	ativaDesativa(recursoId) {
		return api.get(`/api/Recursos/Ativar/${recursoId}`);
	},
	updateRecursos(recursos, filtro) {
		return api.put(`/api/Recursos/Atualizar`, recursos, filtro);
	}
};
