import React from 'react';
import Modal from 'react-bootstrap/es/Modal'
import Table from '../../components/Table/table.component'

export default function LocaisModal(props) {

	const columns = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Área em Hectares', property: 'areaHa' },
		{ id: 2, title: 'Parâmetro', property: 'parametro' },
		{ id: 3, title: 'Atividades', property: 'cultura' }
	];

	const columnsCafe = [
		{ id: 0, title: 'Nome', property: 'nome' },
		{ id: 1, title: 'Área em Hectares', property: 'areaHa' },
		{ id: 2, title: 'Parâmetro', property: 'parametro' },
	];

	const editar = () => {
		props.history.replace({
			pathname: "/safra/editar/" + JSON.parse(localStorage.getItem('safraId')).value,
		})
		props.close()
	}

	return (
		<>
			<Modal show={props.show} dialogClassName="modal-dialog-md" onHide={() => props.close()}>
				<Modal.Header closeButton >
					<Modal.Title style={{ textAlign: "center" }}>
						{props.nomeSafra ?
							'Locais pertencentes à safra ' + props.nomeSafra
							: 'Nenhuma safra selecionada'
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table
						align='left'
						columns={props.codigoCultura === 'CAFE' ? columnsCafe : columns}
						data={props.locais}
						emptyMessage="Nenhum item encontrado."
						emptyColSpan={columns ? columns.length + 1 : 1}
					/>
					<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => props.modalClose()} style={{ width: 120, height: 40 }}>
						<i className='fas fa-close' /> Fechar
					</button>
					<button className="btn btn-primary active pull-right" type="submit" onClick={editar} style={{ width: 120, height: 40 }} disabled={props.nomeSafra ? false : true}>
						<i className='fas fa-edit' /> Editar
					</button>
				</Modal.Body>
				<Modal.Footer className="footer-modal">

				</Modal.Footer>
			</Modal>
		</>
	)
}
