import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import Title from "../../components/Title/title.component";
import { CardStyle } from '../../components/CardStyle/cardstyle.component';
import { Creators as ModulosCreators } from './store/units.ducks';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';
import ConversionUnits from './conversion.units';

function UnitRegister(props) {
	const [visualize, setVisualize] = useState(false);
	const { match: { params } } = props;
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [unitsConversions, setUnitsConversions] = useState([]);
	const [showUnitsConversion, setShowUnitsConversion] = useState(false);

	useEffect(() => {
		if (params.modo === 'visualizar') setVisualize(true);
		if (params.id !== undefined) {
			props.getUnitById(params.id, setUnitsConversions);
		}
	}, []);

	useEffect(() => {
		if (params.id !== undefined && props.unit.sigla) {
			setValue('abbreviation', props.unit.sigla);
			setValue('description', props.unit.descricao);
			if (unitsConversions.length > 0) {
				setShowUnitsConversion(true);
			}
		}
	}, [props.unit]);

	const submitForm = useCallback((data) => {
		const newData = { ...data };
		if (showUnitsConversion && unitsConversions.length > 0)
			newData.unitsConversion = [...unitsConversions]
		else { newData.unitsConversion = [] };
		if (params.modo === undefined) {
			props.createUnit(newData, props.history);
		} else {
			newData.id = params.id
			props.updateUnit(newData, props.history);
		}
	}, [unitsConversions, showUnitsConversion, unitsConversions]);

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							{visualize ? 'Visualizar ' :
								(params.id ? 'Editar ' :
									'Cadastrar '
								)} unidade
						</Title>
					</div>
				</div>
				<CardStyle className='card'>
					<div className='card-header row justify-content-start col-md-12'>
						<div className='card-header col-md-12'>
							<label className='label'>
								Sigla:*
							</label>
							<input type='text'
								className='form-control bmd-form-group'
								readOnly={visualize} name='abbreviation'
								autoComplete="off"
								ref={register({ required: true })}
							/>
							{errors.abbreviation && <span className='span-errors'> Campo Obrigatório</span>}
							<br />
							<label className='label'>
								Descrição:
							</label>
							<input type='text'
								className='form-control bmd-form-group'
								readOnly={visualize} name='description'
								autoComplete="off"
								ref={register({ required: false })}
							/>
							{
								!visualize &&
								<div className={"flex col-md-12 " + (showUnitsConversion ? "head-type-entry-blue" : "head-type-entry-green")}>
									<label className='col-md-3 label mt-2 flex'>
										Cadastrar conversão de unidade ?
									</label>
									<div className="col-md-6 flex">
										<div className="p-0 m-0 text-center custom-switch mt-1">
											<Toggle
												defaultChecked={showUnitsConversion}
												className="custom-classname"
												checked={showUnitsConversion}
												icons={{
													checked: <i className='fas fa-check icon-toogle' />,
													unchecked: <i className='fas fa-times icon-toogle' />,
												}}
												onChange={() => { setShowUnitsConversion(!showUnitsConversion); }}
											/>
										</div>
									</div>
								</div>
							}
							<ConversionUnits
								setUnitsConversion={setUnitsConversions}
								unitsConversion={unitsConversions}
								visualize={visualize}
								showComponent={showUnitsConversion}
								params={params}
							/>
						</div>
					</div>
				</CardStyle>
				<SubmitFormButtons
					flagVisualizar={visualize}
					flagEditar={params.id !== undefined}
					actionGoBack={() => props.history.goBack()}
				/>
			</form>
		</>
	);
}

const mapStateToProps = (state) => ({
	unit: state.units,
});

const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnitRegister)
	;
