import api from './api';

export default {
	salvar(params) {
		return api.post(`/api/TemplatePlanejamento`, params);
	},
	salvarItens(params) {
		return api.post(`/api/TemplatePlanejamento/Itens`, params);
	},
	get(id) {
		return api.get(`/api/TemplatePlanejamento/Get/${id}`);
	},
	updateItem(params) {
		return api.put('/api/TemplatePlanejamento/UpdateItem', params)
	},
	calcularPrazos(params) {
		return api.post(`/api/TemplatePlanejamento/Calcular`, params);
	},
	removerItem(id) {
		return api.delete(`/api/TemplatePlanejamento/RemoverItem/${id}`);
	},
	update(params) {
		return api.put(`/api/TemplatePlanejamento`, params);
	},
	buscar(params) {
		return api.post(`/api/TemplatePlanejamento/BuscarTodas`, params);
	},
	buscarPorSafraId(id) {
		return api.get(`/api/TemplatePlanejamento/BuscarPorSafraId/` + id);

	},
	deletar(id) {
		return api.delete(`/api/TemplatePlanejamento/${id}`);
	},
	duplicar(params) {
		return api.post(`/api/TemplatePlanejamento/Duplicar`, params);
	}
};
