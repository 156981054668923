import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Home extends Component {
	render() {
		return (
			<>
				<div className="card">
					<div className="card-header">Página não encontrada!</div>
					<div className="card-body">
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withRouter(Home));
