import api from './api';

export default {
	upload(param) {
		return api.post('/api/Upload', param);
	},
	download(param) {
		return api.post(`/api/Download`, param, { responseType: 'blob' });
	},
	remove(param) {
		return api.post(`/api/Upload/Remover`, param);
	},
	downloadModelo() {
		return api.get(`/api/DownloadTemplate`, { responseType: 'blob' });
	}
};
