import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { TextField } from "@material-ui/core";
import { MTablePagination, MTableToolbar } from "material-table";
import ToastHelper from "../../utils/toastHelper";
import ReactTooltip from "react-tooltip";
import "./tabelaAjustesApontamento.scss";
import { useSelector, useDispatch } from "react-redux";
import Toolbar from "../../components/BasicPageTopBar/Toolbar";
import ApontamentoMoService from "../../services/apontamentoColaboradorService";
import DateHelper from "../../utils/dateHelper"
import Utils from '../../utils/utils'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ServicoService from '../../services/servicoService';
import ObjectHelper from "../../utils/objectHelper"
import { Edit3 as Edit3Icon } from 'react-feather';
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";
import ModalEdicaoEmGrupo from '../../pages/ajustes_apontamentos/ajustes_apontamentos_modal'


const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});


export default function TabelaAjusteApontamentoMaoDeObra(props) {

	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
		safraId: JSON.parse(localStorage.getItem("safraId")).value,
		page: 1,
		pageSize: 10,
		countItems: 0,
		filtro: "",
		servicoTipoId: "",
		servicoId: "",
		colaboradorMaquina: "",
		localId: "",
		periodoInicio: "",
		periodoFinal: "",
		descedescending: true,
		order: "Data"
	};

	const dispatch = useDispatch();
	const [pagination, setPagination] = useState(useSelector(state => state.ajusteApontamentoMo.filtroListagem));
	const [apontamentos, setApontamentos] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState();
	const [selecionados, setSelecionados] = useState([]);
	const [showModal, setShowModal] = useState(false)

	const colunas = [
		{
			title: "Data",
			editable: "never",
			field: "data",
			editComponent: propsLin => (
				<div>
					<TextField
						id="data"
						name="data"
						type="date"
						error={(propsLin.rowData.data === ("" || "undefined/undefined/"))}
						helperText={(propsLin.rowData.data === ("" || "undefined/undefined/")) ? "Campo obrigatório" : ''}
						onChange={e => {
							var data = { ...propsLin.rowData };
							let parts = e.target.value.split("-")
							data.data = parts[2] + '/' + parts[1] + '/' + parts[0]
							propsLin.onRowDataChange(data);
						}}
						value={DateHelper.toDate(propsLin.value)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Atividade",
			editable: "never",
			field: "safra.label",
		},
		{
			title: "Safra",
			editable: "never",
			field: "grupoSafra",
		},
		{
			title: "Colaborador",
			editable: "never",
			field: "colaborador.label",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="colaborador"
						size="small"
						options={props.colaboradores}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={(event, newValue) => {
							var data = { ...propsLin.rowData };
							data.colaborador = newValue;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.colaborador}
						defaultValue={propsLin.rowData.colaborador}
						renderInput={(params) => (
							<TextField {...params} variant="standard" />
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Local",
			field: "local.label",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="local"
						size="small"
						options={props.locais}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={(event, newValue) => {
							var data = { ...propsLin.rowData };
							data.local = newValue;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.local}
						defaultValue={propsLin.rowData.local}
						renderInput={(params) => (
							<TextField {...params} variant="standard" />
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Grupo Serviço",
			field: "grupoServico.label",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="grupoServico"
						size="small"
						options={props.gruposServicos}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={async (event, newValue) => {
							var data = { ...propsLin.rowData };
							data.servico = '';
							data.grupoServico = newValue;
							data.opcoesServicos = await buscarServicosPorGrupo(newValue.value)
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.grupoServico}
						defaultValue={propsLin.rowData.grupoServico}
						renderInput={(params) => (
							<TextField {...params} variant="standard" />
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Serviço",
			field: "servico.label",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<Autocomplete
						id="servico"
						size="small"
						options={propsLin.rowData.opcoesServicos || []}
						disableClearable
						getOptionLabel={(option) => option.label}
						onChange={(event, newValue) => {
							var data = { ...propsLin.rowData };
							data.servico = newValue;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.rowData.servico}
						defaultValue={propsLin.rowData.servico}
						renderInput={(params) => (
							<TextField {...params} variant="standard"
								error={propsLin.rowData.servico === ""}
								helperText={propsLin.rowData.servico === "" ? "Campo obrigatório" : ''}
							/>
						)}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},
		{
			title: "Horas",
			field: "horas",
			cellStyle: { fontSize: "14px" },
			editComponent: propsLin => (
				<div>
					<TextField
						id="data"
						name="data"
						type="time"
						error={propsLin.rowData.horas === ""}
						helperText={propsLin.rowData.horas === "" ? "Campo obrigatório" : ''}
						onChange={e => {
							var data = { ...propsLin.rowData };
							data.horas = e.target.value;
							propsLin.onRowDataChange(data);
						}}
						value={propsLin.value}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</div>
			)
		},

	];

	useEffect(() => {
		async function init() {
			buscarApMoParaAjustes(pagination)
		}
		init()
	}, [])

	const buscarServicosPorGrupo = async (grupoId) => {
		let opcoesServicos = []
		await ServicoService.buscarPorGrupoId(paginateParams.fazendaId, grupoId).then((response) => {
			if (response.status === 200)
				opcoesServicos = ObjectHelper.getValuesParseToSelect(response.data.result)
			else {
				ToastHelper.error(response.statusText);
				opcoesServicos = []
			}
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
		return opcoesServicos;
	};

	const buscarApMoParaAjustes = (pagination) => {
		ApontamentoMoService.buscarParaAjustes(pagination).then((response) => {
			if (response.status === 200) {
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horas = Utils.formatHour(x.horas);
				})
				verificaSelecionados(response.data.result.items)
				setApontamentos(response.data.result.items)
				setTotalCount(response.data.result.total)
				setPageSize(pagination.pageSize)
				setPage(pagination.page)

			} else {
				ToastHelper.error(response.statusText);
			}
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	}

	const handleFilter = async pag => {
		pag.descedescending = true;
		pag.page = 1;

		dispatch({
			type: "SET_FILTRO_AJUSTE_APONTAMENTO_MO_LISTAGEM",
			filtroListagem: pag
		});

		await setPagination(pag);
		buscarApMoParaAjustes(pag);
	};

	const newAppointment = () => {
		props.history.push({
			pathname: `/apontamento/maoDeObra/cadastro`
		});
	};

	const onChangePage = (e, b, d) => {
		let newPagination = pagination;
		newPagination.page = b + 1;
		setPagination(newPagination);
		buscarApMoParaAjustes(newPagination);
	};

	const onOrderChange = (numeroColuna) => {
		let newPagination = { ...pagination };
		newPagination.descedescending = !newPagination.descedescending;
		newPagination.order = trataOrdenacaoColunas(colunas[numeroColuna].field)
		setPagination(newPagination);
		buscarApMoParaAjustes(newPagination);
	};

	const trataOrdenacaoColunas = (coluna) => {
		let name = null;
		switch (coluna) {
			case 'grupoServico.label':
				name = 'ServicoTipo.Nome'
				break;
			case 'local.label':
				name = 'Local.Nome'
				break;
			case 'servico.label':
				name = 'Servico.Nome'
				break;
			case 'colaborador.label':
				name = 'Pessoa.Nome'
				break;
			default:
				name = Utils.trataColunaParaOrdenacao(coluna)
				break;
		}
		return name
	}

	const validSubmit = async (lista) => {
		let error = true;
		await lista.forEach(item => {
			if (
				(item.servicoId === ("" || undefined)) ||
				(item.horas === "")
			) error = false;
		});
		return error;
	}

	const atualizaApontamentos = async (apontamentos) => {
		await ApontamentoMoService.atualizaListaApontamentos({ apontamentos: apontamentos, filtro: pagination }).then((response) => {

			if (response.status === 200) {
				ToastHelper.success("Apontamentos atualizados com sucesso.");
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horas = Utils.formatHour(x.horas);
				})
				setApontamentos(response.data.result.items)
			}
			else
				ToastHelper.error(response.statusText);

		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const deletarApontamento = async (apontamentoId) => {
		await ApontamentoMoService.deletar(apontamentoId).then((response) => {

			if (response.status === 200) {
				buscarApMoParaAjustes(pagination)
				ToastHelper.success("Apontamento excluído com sucesso.");
			}
			else
				ToastHelper.error(response.statusText);

		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const mudaSelecionados = (listaSelecionados, item, ccc) => {
		let novaLista = [...selecionados]
		if (item !== undefined) {
			if (item.tableData.checked) {
				novaLista.push(item.id)
			} else {
				novaLista = novaLista.filter(x => x !== item.id)
			}
		} else {
			if (listaSelecionados.length > 0) {
				listaSelecionados.forEach(x =>
					novaLista.push(x.id)
				)
			} else {
				novaLista = []
			}
		}
		setSelecionados(novaLista)
	}

	const verificaSelecionados = (items) => {
		items.forEach(item => {
			const existe = (selecionados.indexOf(item.id) !== -1)
			if (existe) {
				item.tableData = { ...item.tableData, checked: true }
			}
		})
	}

	const excluirSelecionados = () => {
		ApontamentoMoService.excluirSelecionados({ apontamentosId: selecionados, filtro: pagination }).then((response) => {
			if (response.status === 200) {
				ToastHelper.success("Apontamentos excluidos com sucesso.");
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horas = Utils.formatHour(x.horas);
				})
				setApontamentos(response.data.result.items)
				setSelecionados([])
			}
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	}

	const submitModal = (alteracoes) => {
		ApontamentoMoService.editarSelecionados({ apontamentosId: selecionados, filtro: pagination, alteracoes: alteracoes }).then((response) => {
			if (response.status === 200) {
				ToastHelper.success("Apontamentos editados com sucesso.");
				response.data.result.items.forEach(x => {
					var parts = x.data.split("/")
					let data = (parts[0].length !== 2 ? '0' + parts[0] : parts[0]) + "/" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "/" + parts[2]
					x.data = data
					x.horas = Utils.formatHour(x.horas);
				})
				setApontamentos(response.data.result.items)
				setSelecionados([])
			}
			else
				ToastHelper.error(response.statusText);
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	}

	return (
		<>
			<Toolbar
				style={{ marginTop: '-20px' }}
				handleFiltro={e => handleFilter(e)}
				filtroInicial={pagination}
				onClickAdd={() => newAppointment()}
				filtroNome
				filtroLocais={props.locais}
				filtroServicos={props.servicos}
				filtroPeriodo
				temFiltroAvancado
				safras={JSON.parse(localStorage.getItem('grupoSafraId')).length >= 2}
				atividades={JSON.parse(localStorage.getItem('safraId')).length >= 2}

			/>
			<div style={{ margin: '20px' }}></div>
			<ThemeProvider theme={theme}>

				<MaterialTable
					title="Apontamentos de mão de obra"
					columns={colunas}
					data={apontamentos}
					onOrderChange={(numeroColuna, orientacao1) =>
						onOrderChange(numeroColuna, orientacao1)
					}
					options={{
						selection: true,
						search: false,
						pageSize: pageSize,
						paging: true,
						rowStyle: {
							fontSize: 14
						},
						headerStyle: {
							fontSize: 14
						},
						filterCellStyle: {
							fontSize: 14
						},
						actionsColumnIndex: -1,
						selectionProps: rowData => ({
							color: 'primary'
						})
					}}
					onSelectionChange={(listaSelecionados, item, ccc) => mudaSelecionados(listaSelecionados, item, ccc)}
					icons={{ Edit: Edit3Icon }}
					components={{
						Pagination: propss => {
							const { classes, headerIsHidden, ...other } = propss;
							return (
								<>
									<div className={"col-md-12 div-sync"}>
										<div className={"col-md-4"}>
											<MTablePagination
												{...other}
												onChangePage={(event, page) =>
													onChangePage(event, page)
												}
												page={page - 1}
												count={totalCount}
												rowsPerPage={pageSize}
												localization={{
													labelRowsSelect: "Apontamento",
													labelDisplayedRows:
														"{from}-{to} de {count}"
												}}
											/>
										</div>
									</div>
								</>
							);
						},
						Toolbar: props => (
							<div>
								<MTableToolbar {...props} showTextRowsSelected={false} />
								<div style={{ padding: '0px 10px' }}>
									{
										selecionados.length > 0 &&
										(
											<div style={{ marginTop: '10px' }}>
												<i
													style={{ cursor: 'pointer' }}
													data-tip="Excluir selecionados"
													className="fas fa-trash-alt fa-lg"
													onClick={
														() => excluirSelecionados()
													}
												/>
												<ReactTooltip
													place="right"
													type="dark"
													delayShow={200}
													effect="float"
												/>
												<i
													style={{ cursor: 'pointer' }}
													data-tip="Editar Selecionados"
													className="fas fa-edit fa-lg ml-3"
													onClick={() => setShowModal(true)}
												/>
												<ReactTooltip
													place="right"
													type="dark"
													delayShow={200}
													effect="float"
												/>
											</div>
										)
									}
								</div>
							</div>
						),
					}}
					editable={{
						onBulkUpdate: changes =>
							new Promise(async (resolve, reject) => {
								let apontamentos = [];
								for (let [value] of Object.entries(changes)) {
									apontamentos.push({
										id: value.newData.id,
										horas: value.newData.horas,
										localId: value.newData.local.value,
										servicoId: value.newData.servico.value,
										grupoServicoId: value.newData.grupoServico.value,
										tableData: value.newData.tableData,
										fazendaId: value.newData.fazendaId,
										safraId: value.newData.safraId
									});
								}

								if (apontamentos.length > 0) {

									let valido = await validSubmit(apontamentos);
									if (valido) {
										atualizaApontamentos(apontamentos);
										resolve();
									} else {
										reject();
									}
								}
								resolve();
							}),
						onRowDelete: row =>
							new Promise(async (resolve, reject) => {
								deletarApontamento(row.id)
								resolve();
							})
					}}
					localization={{
						header: {
							actions: "Ações"
						},
						body: {
							emptyDataSourceMessage: "Nenhum registro para exibir",
							addTooltip: "Adicionar",
							deleteTooltip: "Excluir",
							bulkEditTooltip: "Edição rapida",
							editTooltip: "Editar item completo.",
							editRow: {
								deleteText: "Deseja excluir este apontamento?",
								cancelTooltip: "Cancelar",
								saveTooltip: "Confirmar"
							}
						},
						toolbar: {
							searchTooltip: "Pesquisar",
							searchPlaceholder: "Pesquisar",
							nRowsSelected: ""
						},
						pagination: {
							labelRowsSelect: "apontamentos",
							labelDisplayedRows: "{from}-{to} de {count}"
						}
					}}
				/>
			</ThemeProvider>
			<ModalEdicaoEmGrupo
				showModal={showModal}
				setShowModal={(e) => setShowModal(e)}
				locais={props.locais}
				gruposServicos={props.gruposServicos}
				servicos={props.servicos}
				salvarAlteracoes={(e) => submitModal(e)}
				tipoApontamento={'MO'}
			/>
		</>
	);
};
