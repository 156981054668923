import React, { useState } from 'react';
import Modal from 'react-bootstrap/es/Modal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap'


export default function Revisao(props) {

	const [textcomposicoes, setTextcomposicoes] = useState('')
	const [textServicos, setTextServicos] = useState('')

	const handleItemClick = (item) => {
		navigator.clipboard.writeText(item.target.textContent)
		toast("Wow so easy !");
		// ToastHelper.default("Item copiado para área de transferência")
	}

	const handleSubmit = tipo => {
		if (tipo === "servicos")
			return props.atualizarServicos(textServicos)
		else
			return props.atualizarlocal(textcomposicoes)
	}

	const { servicos, local } = props;
	return (
		<>
			<Modal dialogClassName="modal-dialog-lg-template" show={props.show} onHide={() => props.close()}>
				<Modal.Header closeButton >
					<h3>{props.title}</h3>
				</Modal.Header>
				<hr></hr>
				<Modal.Body className="" style={{ height: "80vh" }}>
					<div className="row">
						<div className="col-md-6">
							<label>Tabela de serviços</label>

							<div className="input-group md-form form-sm form-2 pl-0">
								<input
									className="form-control my-0 py-1"
									value={textServicos}
									autoComplete="off"
									onChange={(e) => setTextServicos(e.target.value)}
									onKeyDown={(event) => {
										if ((event ? event.keyCode : event.which) === 13)
											handleSubmit("servicos")
									}}
								/>
								<div className="input-group-append">
									<button className="input-group-text red lighten-3" id="basic-text1" onClick={() => handleSubmit("servicos")} >
										<i className="fas fa-search icon-color-green"
											aria-hidden="true" />
									</button>
								</div>
							</div>
							<Table striped bordered hover size="md" responsive>
								<thead>
									<tr>
										<th className="bold">#</th>
										<th className="bold">Nome</th>
									</tr>
								</thead>
								<tbody>
									{
										servicos !== undefined && servicos.map((servico, index) =>
											<tr key={index.toString()}>
												<td>{index + 1}</td>
												<td className="td-class pointer" onClick={(item) => handleItemClick(item)}>
													{servico.nome}
												</td>
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
						<div className="col-md-6">
							<label>Tabela de local</label>
							<div className="input-group md-form form-sm form-2 pl-0">
								<input
									className="form-control my-0 py-1"
									value={textcomposicoes}
									autoComplete="off"
									onChange={(e) => setTextcomposicoes(e.target.value)}
									onKeyDown={(event) => {
										if ((event ? event.keyCode : event.which) === 13)
											handleSubmit("local")
									}}
								/>
								<div className="input-group-append">
									<button className="input-group-text red lighten-3" id="basic-text1" onClick={() => handleSubmit("local")} >
										<i className="fas fa-search icon-color-green"
											aria-hidden="true" />
									</button>
								</div>
							</div>
							<Table striped bordered hover size="md" responsive>
								<thead>
									<tr>
										<th className="bold">#</th>
										<th className="bold">Nome</th>
									</tr>
								</thead>
								<tbody>

									{
										local !== undefined &&
										local.map((composicao, index) =>
											<tr key={index.toString()}>
												<td>{index + 1}</td>
												<td className="td-class" onClick={(item) => handleItemClick(item)}>
													{composicao.nome}
												</td>
											</tr>
										)
									}
								</tbody>
							</Table>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="footer-modal">
					<hr></hr>
					<div className="row col-md-12">
						<div className="col-md-6 pull-left">
							<button className="btn btn-outline-secondary pull-left" variant="success" onClick={() => props.close()} >
								<i className='fas fa-close' /> Cancelar
							</button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
			<ToastContainer />

		</>
	)
}
