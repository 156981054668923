import React from 'react';
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { EquipeSchema } from './equipe.cadastro.validate';
import ToastHelper from "../../utils/toastHelper";
import EquipeService from '../../services/equipeService';
import GrupoLocaisService from '../../services/grupoLocaisService';
import PessoaService from '../../services/pessoaService';
import { PERMISSAO } from '../../permissoes/permissoes';
import Table from '../../components/Table/table.component';
import Modal from "../../utils/modalHelper";
import Button from 'react-bootstrap/es/Button';
import ModalMembros from '../modais/modalMembros'
import ReactTooltip from 'react-tooltip';
import ModalSupervisores from '../modais/modalServicos';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';
import Utils from '../../utils/utils'
import './equipe.css'
import Select from 'react-select'

export default class EquipeCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: undefined,
				nome: undefined,
				descricao: undefined,
				supervisorSelecionado: undefined,
				membros: []
			},
			showModalMembros: false,
			funcionarios: [],
			supervisores: [],
			grupoLocais: [],
			membrosSelecionados: [],
			showModalSupervisores: false,
			labelSelectSupervisor: 'Selecione o supervisor',
			visualizar: false,
			editar: false,
		};
		this.history = this.props.history;
	}

	columns = [
		{ id: 0, title: 'Nome', property: 'label' }
	];

	actions = [
		{
			id: 0,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_PESSOA_EXCLUIR,
			action: item => this.handleModalExcluir(item),
		}
	];

	async componentDidMount() {
		await this.getFuncionarios();
		await this.getGrupoLocais();
		this.validateSupervisor = this.validateSupervisor.bind(this);
		this.selectAndSubmitModalSupervisores = this.selectAndSubmitModalSupervisores.bind(this);
		this.selectAndSubmitModalMembros = this.selectAndSubmitModalMembros.bind(this);
		this.getValuesParseToTable = this.getValuesParseToTable.bind(this);
		this.getValuesParseToSelect = this.getValuesParseToSelect.bind(this);
		let fazendaId = JSON.parse(localStorage.getItem('fazendaId'));

		await this.setState({
			fazendaId
		})

		const { match: { params } } = this.props;
		if (params.id) {

			await this.buscar(params.id);
		}

		await this.setOperacao()
	}

	async setOperacao() {
		var url_atual = window.location.href;

		var isVisualizar = url_atual.indexOf("visualizar")
		var isEditar = url_atual.indexOf("editar")

		isVisualizar > 0 ? this.setState({ visualizar: true }) : this.setState({ visualizar: false });
		isEditar > 0 ? this.setState({ editar: true }) : this.setState({ editar: false });
	}

	titulo() {
		return this.state.editar ? 'Editar' :
			this.state.visualizar ? 'Visualizar' :
				'Cadastrar'
	}

	getValuesParseToSelect(list, valueName, labelName) {

		let options = [];
		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	async buscar(EquipeId) {

		let { data } = await EquipeService.buscarPorId(EquipeId);
		let novosMembros = [];
		let novosSelecionados = [];

		data.result.equipePessoa.forEach(item => {
			novosMembros.push(
				{ value: item.id, label: item.nome }
			)
			novosSelecionados.push(item.id)
		})
		let novosFunc = this.state.funcionarios;
		novosFunc.forEach(item => {
			if (novosSelecionados.includes(item.value)) {
				item.isSelect = true
			} else {
				item.isSelect = false
			}
		})

		await this.setState({
			dados: {
				...data.result,
				membros: novosMembros
			},
			labelSelectSupervisor: data.result.supervisor.nome,
			funcionarios: novosFunc,
			membrosSelecionados: novosSelecionados,
			editar: true,
		});


		await this.setState({
			reinitializar: true,
			dados: {
				...this.state.dados,
				supervisorSelecionado: { value: data.result.supervisor.id, label: data.result.supervisor.nome }
			}
		});
	}

	async handleModal(tipo) {
		let prop = ((tipo === "SUPERVISORES") ? 'showModalSupervisores' : 'showModalMembros')
		await this.setState({
			...this.state,
			[prop]: !this.state[prop]
		});
	}

	handleLabelSupervisorSelecionado() {
		this.setState({
			...this.state,
			labelSelectSupervisor: this.state.dados.supervisorSelecionado.label
		})
	}

	async getFuncionarios() {
		let params = {
			fazendaId: (JSON.parse(localStorage.getItem('fazendaId')).value),
			parametro: '',
			page: 1,
			pageSize: 1000,
			order: "Nome",
			descedescending: false
		}
		let result = await PessoaService.buscar(params)
		this.setState({
			funcionarios: Utils.removeInactiveToSelect(this.getValuesParseToTable(result.data.result.items)),
			supervisores: Utils.removeInactiveToSelect(this.getValuesParseToTable(result.data.result.items))
		})
	}

	async getGrupoLocais() {
		let params = {
			fazendaId: (JSON.parse(localStorage.getItem('fazendaId')).value),
			parametro: '',
			page: 1,
			pageSize: 1000,
			order: "Nome",
			descedescending: false
		}
		let result = await GrupoLocaisService.getAll(params)
		this.setState({
			grupoLocais: Utils.removeInactiveToSelect(this.getValuesParseToTable(result.data.result.items))
		})
	}

	getValuesParseToTable(list) {
		let options = [];
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item.id, label: item.nome, ativo: item.ativo })
		});
		return options;
	}

	async handleModalExcluir(item) {
		if (item) {
			let novaLista = this.state.dados.membros.filter(function (ele) {

				ele.isSelect = true;
				return ele.value !== item.value
			});
			let novosSelecionados = this.state.membrosSelecionados.filter(function (ele) {
				return ele !== item.value
			});
			let novosFunc = this.state.funcionarios;
			novosFunc.forEach(item => {
				if (novosSelecionados.includes(item.value)) {
					item.isSelect = true
				} else {
					item.isSelect = false
				}
			})
			await this.setState({
				...this.state,
				membrosSelecionados: novosSelecionados,
				funcionarios: novosFunc,
				dados: {
					...this.state.dados,
					membros: novaLista
				}
			})
		}
	}


	voltar = () => {
		this.history.goBack();
	}

	async selectAndSubmitModalMembros(membros) {
		let selecionados = [];

		await membros.forEach(item => {

			if (item.isSelect) {
				selecionados.push(item)
			}
		})
		if (membros) {
			await this.setState({
				...this.state,
				dados: {
					...this.state.dados,
					membros: selecionados
				}
			});
		}
		await this.handleSelectedMembers()
	}

	async handleSelectedMembers() {
		let linhasSelecionados = [];
		this.state.dados.membros.forEach(membro => {
			if (membro.isSelect) {
				linhasSelecionados.push(membro.value)
			}
		});

		await this.setState({
			...this.state,
			membrosSelecionados: linhasSelecionados
		});
	}

	tableMembrosEditar() {
		return (
			<>
				<hr></hr>
				<Table
					align='left'
					columns={this.columns}
					acoesSingleButton={false}
					actions={this.actions}
					data={this.state.dados.membros}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
				/>
			</>

		)
	}

	tableMembrosVisualizar() {
		return (
			<>
				<hr />
				<Table
					align='left'
					columns={this.columns}
					acoesSingleButton={false}
					data={this.state.dados.membros}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 0}
				/>
			</>

		)
	}

	adicionarMembro() {
		return <button className="btn-custom float-right " type='button' hidden={this.state.visualizar} onClick={() => this.handleModal('MEMBROS')}>
			<i className="fas fa-plus"></i>
		</button>
	}

	validateSupervisor() {
		let error;
		if (!this.state.dados.supervisorSelecionado) {
			error = 'Campo obrigatório';
		}
		return error;
	};

	onSubmit = async (values, options) => {
		values.equipePessoas = [];

		await this.state.membrosSelecionados.forEach(item => {
			values.equipePessoas.push(
				{
					pessoaId: item
				}
			)
		})
		
		values.supervisorId = this.state.dados.supervisorSelecionado.value
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

		let metodo = this.state.editar ? "editar" : "salvar";
		let service = await EquipeService[metodo](values);

		if (service.data.isOk) {
			ToastHelper.success('Equipe ' + (metodo === "editar" ? "editada" : "cadastrada") + ' com sucesso!');
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (service.data.exception ? service.data.exception.Message : service.data.mensagem));
	}

	async selectAndSubmitModalSupervisores(supervisor) {
		if (supervisor) {
			await this.setState({
				...this.state,
				dados: {
					...this.state.dados,
					supervisorSelecionado: supervisor
				}
			});
			await this.handleLabelSupervisorSelecionado();
		}
	}

	render() {
		const { state: { dados } } = this;
		return (
			<>
				<Formik
					initialValues={dados}
					enableReinitialize={this.state.editar && !this.state.reinitializar}
					validationSchema={EquipeSchema || null}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({ values, setFieldValue, handleSubmit, handleChange, touched, errors, status, validateField, validateForm, isValidating, resetForm, handleReset }) => (
						<form className="form " onSubmit={handleSubmit}>
							<div>
								<div className='row'>
									<div className='col'>
										<Title>{(this.titulo()) + ' Equipe'}</Title>
									</div>
								</div>
								<CardStyle className="card">
									<div className='card-header row justify-content-start col-md-12'>
										<div className="card-header col-md-12">
											<label className="label">
												Nome:*
                                    		</label>
											<Field
												className='form-control bmd-form-group'
												placeholder="Informe o nome"
												name="nome"
												maxLength="40"
												autoComplete="off"
												disabled={this.state.visualizar}
												onChange={handleChange}
												value={values.nome}
												htmlFor="nome"
												type="text" />
											<ErrorMessage
												name="nome">
												{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div data-tip="Clique no icone para pesquisar o supervisor" className="card-header col-md-6">
											<label className="label">
												Supervisor:*
                                    		</label>
											<div className='flex'>
												<Field id="supervisorSelecionado" placeholder={this.state.labelSelectSupervisor} validate={() => this.validateSupervisor()} type="text"
													name="supervisorSelecionado" disabled
													htmlFor="supervisorSelecionado"
													autoComplete="off"
													className='form-control disabled-input bmd-form-group ' />
												<Button className="button-search-membros"
													variant="success"
													onClick={() => this.handleModal("SUPERVISORES")}
													hidden={this.state.visualizar}>
													<i className="fas fa-search text-secondary " aria-hidden="true"></i>
												</Button>
												<Modal
													show={this.state.showModalSupervisores}
													title={'Supervisor'}
													buttonTitle={'Confirmar'}
													submit={() => this.handleModal("SUPERVISORES")}
													close={() => this.handleModal("SUPERVISORES")}
													content={<ModalSupervisores
														onChange={(event) => setFieldValue('supervisorSelecionado', event)}
														listaServicos={this.state.supervisores}
														selectAndSubmitModalServico={this.selectAndSubmitModalSupervisores}
														servicoSelecionado={this.state.dados.supervisorSelecionado}
														nomeColuna="Supervisor"
													/>}
												/>

											</div>
											<ErrorMessage name="supervisorSelecionado">
												{msg => <div className="error error-message error-validate ml-3">{msg}</div>}
											</ErrorMessage>
										</div>
										<ReactTooltip place="top" type="dark" delayShow={400} effect="float" />
										<div className="card-header col-md-6 ajust-label-padrao">
											<label className="label">
												Fazenda:
											</label>
											<Select 
												id="grupoLocalId"
												name="grupoLocalId"
												htmlFor="grupoLocalId"
												placeholder='Informe a fazenda'
												className="select-style ajust-label-padrao disabled-select"
												isDisabled={this.state.visualizar}
												value={this.state.grupoLocais.filter(item => item.value === values.grupoLocalId)}
												onChange={(e) => { setFieldValue('grupoLocalId', (e ? e.value : undefined)) }}
												options={this.state.grupoLocais}
												isClearable
												theme={theme => ({
													...theme,
													borderRadius: 0
												})}
											/>
										</div>
										<div className="card-header col-md-12">
											<label className="label">
												Observação:
                                    		</label>
											<textarea
												className='form-control bmd-form-group'
												placeholder="Informe uma observação à equipe"
												name="descricao"
												onChange={handleChange}
												disabled={this.state.visualizar}
												value={values.descricao}
												htmlFor="descricao"
												maxLength="200"
												type="textarea"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="pull-left">
											<h5>Membros</h5>
										</div>
										<div className="pull-right" style={{ marginTop: 2 }}>
											{
												this.adicionarMembro()
											}
											<Modal
												show={this.state.showModalMembros}
												title='Membros'
												buttonTitle='Adicionar'
												submit={() => this.handleModal('MEMBROS')}
												close={() => this.handleModal('MEMBROS')}
												content={<ModalMembros
													membrosEquipe={this.state.funcionarios}
													selectAndSubmitModalMembros={this.selectAndSubmitModalMembros}
													membrosSelecionados={this.state.membrosSelecionados}
												/>}
											/>
										</div>
									</div>
									{
										this.state.visualizar === true ? this.tableMembrosVisualizar() : this.tableMembrosEditar()
									}
								</CardStyle>
								<SubmitFormButtons
									flagVisualizar={this.state.visualizar}
									flagEditar={this.state.dados.id !== undefined}
									actionGoBack={() => this.voltar()}
								/>
							</div>
						</form>
					)}
				</Formik>
			</>
		)
	}
}
