import React, { useState, useCallback, useEffect } from "react";
import { PERMISSAO } from "../../permissoes/permissoes";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import { bindActionCreators } from "redux";
import { Creators as ModulosCreators } from "../../store/ducks/recomendacoes_tecnicas.ducks";
import { connect } from "react-redux";
import Title from "../../components/Title/title.component";
import TabelaAjusteRecursos from "../../components/TabelaAjusteRecursos/tabelaAjusteRecursos.component";
import AdvancedSearch from "../../components/AdvancedSearch/advancedSearch";
import ObjectHelper from "utils/objectHelper";

function RecomendacoesTecnicas(props) {
	useEffect(() => {
		localStorage.removeItem("planejamentoParams");
	}, []);

	const paginateParams = {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
		safraId: ObjectHelper.primeiraSafra(),
		page: 1,
		pageSize: 10,
		countItems: 0,
		filtro: "",
		recursosId: "",
		servicoTipoId: "",
		servicoId: "",
		recursosClasseId: "",
		LocalId: "",
		descedescending: true,
		order: "Inicio"
	};

	const [pagination, setPagination] = useState(paginateParams);
	const [data, setData] = useState([]);
	const [locais, setLocais] = useState();

	useEffect(() => {
		getLocais(paginateParams);
		if (props.pagination !== undefined) {
			setPagination(props.pagination);
		}
	}, []);

	useEffect(() => {
		if (props.locais !== undefined) {
			setLocais(props.locais);
		}
	}, [props.locais]);

	useEffect(() => {
		if (props.recursos) {
			setData(props.recursos);
		}
	}, [props.recursos]);

	useEffect(() => {
		if (props.pagination) {
			setPagination(props.pagination);
		}
	}, [props.pagination]);

	const handleFilter = async newFilter => {
		let newPagination = newFilter;
		newPagination = newFilter;
		newPagination.descedescending = false;
		newPagination.order = "Nome";
		await setPagination(newPagination);
		props.requestRecursosToAdjust(newPagination);
	};

	const getLocais = async () => {
		props.getLocaisSafra(ObjectHelper.primeiraSafra());
	};

	const onChangePage = (e, b, d) => {
		let newPagination = pagination;
		newPagination.page = b + 1;
		setPagination(newPagination);
		props.requestRecursosToAdjust(newPagination);
	};

	const deletRow = row => {
		props.deletarRecurso(row.id, pagination);
	};

	const updateTable = useCallback(async items => {
		props.updateRecursos(items, pagination);
	});

	const onOrderChange = (numeroColuna, orientacao) => {
		let newPagination = { ...pagination };
		newPagination.descending = !newPagination.descending;
		newPagination.order = numeroColuna.toString();
		setPagination(newPagination);
		props.requestRecursosToAdjust(newPagination);
	};

	return (
		<>
			<Title permissaoBotao={PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR}>
				Ajustes em Recursos
			</Title>
			<CardStyle className="card">
				<div className="card-header col-md-12">
					<AdvancedSearch
						handleFilter={e => handleFilter(e)}
						name={false}
						firstPeriod={false}
						finalPeriod={false}
						location={true}
						serviceGroup={true}
						service={true}
						resourceClass={true}
						resourceType={true}
						resource={true}
					/>
					<TabelaAjusteRecursos
						recursos={data}
						locais={locais}
						opcoesRecursosComCodigo={["IN", "RE"]}
						removerClasses={["EPI"]}
						syncTable={() =>
							props.requestRecursosToAdjust(pagination)
						}
						onAdd={""}
						updateTable={updateTable}
						pageSize={pagination.pageSize}
						totalCount={pagination.rowCount}
						page={pagination.page}
						onChangePage={(event, page) =>
							onChangePage(event, page)
						}
						deletRow={(e, b) => deletRow(e, b)}
						onOrderChange={(numeroColuna, orientacao1) =>
							onOrderChange(numeroColuna, orientacao1)
						}
					/>
				</div>
			</CardStyle>
		</>
	);
}

const mapStateToProps = state => {
	return {
		recursos: state.recomendacoesTecnicas.recursos,
		loading: state.recomendacoesTecnicas.loading,
		error: state.recomendacoesTecnicas.error,
		pagination: state.recomendacoesTecnicas.pagination,
		locais: state.recomendacoesTecnicas.locais
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RecomendacoesTecnicas);
