import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({

    createMaterial: ['params', 'history', 'source'],
	postMaterial: ['params'],

    getMaterials: ['params', 'callback'],
    setMaterials: ['materials'],

    getMaterialsByFamily: ['params', 'callback'],
    setMaterialsByFamily: ['materials'],

    getMaterialById: ['params', 'setAuxMaterials','callback'],
	setGetMaterialById: ['params'],

    deleteMaterial: ['params', 'props', 'callback'],

    updateMaterial: ['params', 'history', 'source'],
    setUpdateMaterial: ['params', 'history', 'source'],

    updateOptionsAuxMaterials: ['optionsMaterials', 'setOptionsMaterials', 'addAuxMaterials', 'callback'],
    
    getFamilyMaterials: ['params', 'callback'],
    setFamilyMaterials: ['familyMaterials'],

    getUnits: ['params',  'callback'],
	readUnits: ['params'],
    
})

const INITIAL_STATE_VALUES = {
    materials: [],
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const createMaterial = (state = INITIAL_STATE, action) => state.merge({material: action.data})
const getMaterialById = (state = INITIAL_STATE, action) => state.merge( {...state, material: action.data } )
const getMaterials = (state = INITIAL_STATE, action) => state.merge({...state, materials: action.data })
const getMaterialsByFamily = (state = INITIAL_STATE, action) => state.merge({...state, materials: action.data })
const getUnits = (state = INITIAL_STATE, action) => state.merge({...state, units: action.data})
const deleteMaterial = (state = INITIAL_STATE, action) => state.merge({idDeleteItem: action.params })
const updateOptionsAuxMaterials = (state = INITIAL_STATE, action) => state.merge({})
const updateMaterial = (state = INITIAL_STATE, action) => state.merge( {mat: action.params} )
const getFamilyMaterials = (state = INITIAL_STATE, action) => state.merge({...state, familyMaterials: action.data })

export default createReducer(INITIAL_STATE, {
    
    [Types.SET_GET_MATERIAL_BY_ID]: getMaterialById,
    [Types.POST_MATERIAL]: createMaterial,
    [Types.SET_MATERIALS]: getMaterials,
    [Types.READ_UNITS]: getUnits,
    [Types.DELETE_MATERIAL]: deleteMaterial,
    [Types.UPDATE_OPTIONS_AUX_MATERIALS]: updateOptionsAuxMaterials,
    [Types.SET_FAMILY_MATERIALS]: getFamilyMaterials,
    [Types.UPDATE_MATERIAL]: updateMaterial,
    [Types.SET_MATERIALS_BY_FAMILY]: getMaterialsByFamily,

})