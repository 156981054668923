import React from 'react'; import ObjectHelper from 'utils/objectHelper'
import { Formik, Field, ErrorMessage } from 'formik';
import Modal from 'react-bootstrap/es/Modal'
import InputMask from 'react-input-mask';
import Utils from '../../utils/utils'
import Table from '../../components/Table/table.component';
import ToastHelper from "../../utils/toastHelper";
import ServicoService from '../../services/servicoService'
import RecursoService from '../../services/recursosService';
import MaquinaTipoService from 'services/tipoMaquinaService';
import FamiliaMaterialService from 'services/familiaMaterialService';
import PessoaTipoService from 'services/categoriaService';
import recursosTipoService from '../../services/recursosTipoService';
import { PERMISSAO } from '../../permissoes/permissoes';
import Select from 'react-select';
import * as Yup from 'yup';

const opcoes = [{
	label: "Padrão",
	value: "padrao"
},
{
	label: "Por data",
	value: "porData"
}]

const columnsRecursos = [
	{ id: 0, title: 'Recurso Tipo', property: 'recursoTipo' },
	{ id: 1, title: 'Recurso Classe', property: 'recursoClasse' },
	{ id: 2, title: 'Recurso', property: 'nome' },
	{ id: 3, title: 'Tipo', property: 'recursosTipo' },
	{ id: 4, title: 'Classe', property: 'recursoClasse' },
	{ id: 5, title: 'Quantidade', property: 'quantidade' },
	{ id: 6, title: 'Perda(%)', property: 'perda' },
	{ id: 7, title: 'Custo Padrão', property: 'custoPadrao' }
];

const SchemaData = Yup.object().shape({
	servicoTipoId: Yup.string()
		.required('Campo obrigatório'),
	servicoId: Yup.string()
		.required('Campo obrigatório'),
	inicio: Yup.string()
		.required('Campo obrigatório'),
	fim: Yup.string()
		.required('Campo obrigatório'),
	op: Yup.string()
		.required('Campo obrigatório')
});

const SchemaPredecessora = Yup.object().shape({
	servicoTipoId: Yup.string()
		.required('Campo obrigatório'),
	op: Yup.string()
		.required('Campo obrigatório'),
	servicoId: Yup.string()
		.required('Campo obrigatório'),
	folga: Yup.string()
		.required('Campo obrigatório'),
	duracao: Yup.string()
		.required('Campo obrigatório'),
	//predecessora: Yup.string()
	//	.required('Campo obrigatório'),
});


const PARAMS = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 0,
	pageSize: 1000
}

export default class ItemsCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fazendaId: '',
			dados: {
				id: null,
				recursos: [],
				servicoTipoId: null,
				servicoId: null,
				folga: null,
				duracao: null,
				predecessora: null,
				inicio: null,
				fim: null,
				op: null
			},
			recursos: [],
			recursosTipo: [],
			recursosClasse: [],
			servicos: [],
			padrao: null
		};
	}

	actionsRecursos = [
		{
			id: 1,
			name: 'Excluir',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_TEMPLATE_PLANEJAMENTO_ATUALIZAR,
			action: item => this.excluirRecursoAdicionado(item),
		}
	];

	async componentDidMount() {
		this.baseState = { ...this.state }
		this.onSubmit = this.onSubmit.bind(this)
		//		this.getRecursos = this.getRecursos.bind(this);

		//this.getRecursos();
		this.getRecursosTipo();

		if (this.props.codigoCultura && this.props.codigoCultura === 'CAFE') {
			this.setState({ padrao: 'porData' });
		}
	}

	async getRecursosTipo() {
		const { data } = await recursosTipoService.get(JSON.parse(localStorage.getItem('fazendaId')).value);
		if (data !== undefined)
			this.setState({ recursosTipo: ObjectHelper.getRecursoTipoParseToSelect(data.result) })
	}



	async changeRecursoTipo(e) {
		if (e) {
			let param = {
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
				page: 1,
				pageSize: 1000
			}

			let data = null;

			if (e.codigo === "MA") data = await MaquinaTipoService.buscarTodas(param);
			if (e.codigo === "IN" || e.codigo === "RE") data = await FamiliaMaterialService.buscarTodas(param);
			if (e.codigo === "MO") data = await PessoaTipoService.buscarTodas(param);

			if (data) {
				data = ObjectHelper.getValuesParseToSelect(data.data.result.items)
			}
			this.setState({ recursosClasse: data })
		}
	}

	async componentWillReceiveProps(nextProps) {
		if (!this.props.atividade) {
			await this.setState({
				dados: {
					id: null,
					servicoTipoId: null,
					recursos: [],
					servicoId: null,
					folga: null,
					duracao: null,
					predecessora: null,
					inicio: null,
					fim: null
				}
			})
		} else {
			var dados = this.state.dados;

			dados.id = nextProps.atividade.id;
			dados.servicoTipoId = nextProps.atividade.servicoTipoId;
			if (dados.servicoTipoId !== undefined)
				await this.changeGrupoServico(dados.servicoTipoId)
			dados.servicoId = nextProps.atividade.servicoId;
			dados.recursos = nextProps.atividade.recursos ? nextProps.atividade.recursos : [];
			dados.folga = nextProps.atividade.folga;
			dados.duracao = nextProps.atividade.duracao;
			dados.predecessora = nextProps.atividade.predecessora;
			dados.inicio = nextProps.atividade.inicio;
			dados.fim = nextProps.atividade.fim;
			dados.op = dados.predecessora ? opcoes[0].value : opcoes[1].value;
			dados.editar = true;
			await this.setState({ dados: dados })
		}
		if (this.props.codigoCultura && this.props.codigoCultura === 'CAFE') {
			this.setState({ padrao: 'porData' });
		}
	}

	async onChangeDate(data, tipo) {
		await this.setState({
			...this.state,
			dados: {
				...this.state.dados,
				[tipo]: data
			}
		})
	}

	onSubmit = (values, options) => {
		if (values.inicio) {
			var validInicio = Utils.isDateValid(values.inicio)
			if (validInicio === false) return ToastHelper.error("Formato de data inválido");
		}
		if (values.fim) {
			var validFim = Utils.isDateValid(values.fim)
			if (validFim === false) return ToastHelper.error("Formato de data inválido");
		}
		values.editar = this.props.editar
		if (values.predecessora === null || values.predecessora === undefined) values.predecessora = 0;
		if (values.predecessora < 0) values.predecessora = 0
		if (values.duracao < 0) values.duracao = 0
		if (values.folga < 0) values.folga = 0
		let teste = this.props.confirmar(values);
		teste.then(result => {
			if (result) {
				options.resetForm();
				this.setState({
					...this.state,
					dados: {
						servicoTipoId: null,
						recursos: [],
						servicoId: null,
						folga: null,
						duracao: null,
						predecessora: null,
						inicio: null,
						fim: null,
						op: null
					},
					padrao: null
				})
			}
		})
	}

	async getRecursosPorClasseId(classeId) {
		const { data } = await RecursoService.getClasseId(classeId);
		this.setState({
			recursos: data.result
		});
	}

	getValuesParseToSelectRecursos(list, valueName, labelName) {
		let options = [];
		valueName = valueName || 'id';
		labelName = labelName || 'nome';

		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({
				value: item[valueName],
				label: item[labelName],
				ativo: item.ativo,
				codigo: item.codigo,
				descricao: item.descricao,
				recursoClasse: item.recursoClasse,
				recursosTipo: item.recursosTipo,
				custoPadrao: item.custoPadrao
			})
		});

		return options;
	}

	excluirRecursoAdicionado(recurso) {
		let recursos = this.state.dados.recursos;
		this.state.dados.recursos.forEach(item => {

			if (item.id === recurso.id) {

				let indice = this.state.dados.recursos.indexOf(item)
				recursos.splice(indice, 1);
			}
		})
		this.setState({
			...this.state,
			dados: {
				...this.state.dados,
				recursos
			}
		})
		this.getRecursos();
	}

	async changeGrupoServico(id) {
		const { data } = await ServicoService.buscarPorGrupoId(PARAMS.fazendaId, id);
		this.setState({
			servicos: this.getValuesParseToSelect(data.result)
		})
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	botaoAdicionarRecurso() {
		return <button className="btn-custom btn float-right " type='button' onClick={() => this.handleModal('RECURSOS')} hidden={this.state.visualizar}>
			<i className="fas fa-plus"></i>
		</button>
	}

	tableRecursosEditar() {
		return (
			<>
				<hr />
				<Table
					align='left'
					columns={columnsRecursos}
					acoesSingleButton={false}
					actions={this.actionsRecursos}
					data={this.state.dados.recursos}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={columnsRecursos ? columnsRecursos.length + 1 : 0}
				/>
			</>
		)
	}

	async handleModal(tipo) {
		let prop = ((tipo === "RECURSOS") ? 'showModalRecursos' : 'showModalComposicaoAuxiliar')
		await this.setState({
			...this.state,
			[prop]: !this.state[prop]
		});
	}

	confirmarRecurso = async (recurso, prop) => {
		recurso.recursoId = recurso.id
		let propModal = (prop === "composicaoRecursos" ? 'showModalRecursos' : 'showModalComposicaoAuxiliar')
		let novaLista = this.state.dados.recursos

		novaLista.push(recurso)
		await this.setState({
			...this.state,
			dados: {
				...this.state.dados,
				recursos: novaLista
			},
			[propModal]: false,

		})
		// await this.getRecursos();
		//await this.getComposicoesAuxiliares();
	}

	modal() {
		const { show, title, close, data, editar } = this.props;
		let schema = null;
		if (data === null) schema = SchemaPredecessora
		if (this.state.padrao !== null) {
			if (this.state.padrao === "padrao")
				schema = SchemaPredecessora
			else
				schema = SchemaData
		}
		if (schema == null) schema = SchemaPredecessora
		return (
			<Formik
				initialValues={this.state.dados}
				enableReinitialize
				validationSchema={schema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleChange }) => (
					<form className="formm" onSubmit={handleSubmit}>
						<div>
							<Modal show={show} onHide={() => close()}>
								<Modal.Header closeButton >
									<h3>{title}</h3>
								</Modal.Header>
								<Modal.Body>
									<div className='card-header'>
										<div className="row col-md-12">
											<div className="card-header col-md-12">
												<label className="label">
													Grupo de Serviços :*
													</label>
												<Select
													id="servicoTipoId"
													name="servicoTipoId"
													htmlFor="servicoTipoId"
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													onChange={(e) => { this.changeGrupoServico(e.value); setFieldValue('servicoTipoId', e.value) }}
													value={this.props.grupoServicos.filter(item => item.value === values.servicoTipoId)}
													options={this.props.grupoServicos}
													theme={theme => ({
														...theme,
														borderRadius: 0
													})}
												/>
												<ErrorMessage name="servicoTipoId">{msg => <div className="error error-message error-validate">Campo Obrigatório</div>}</ErrorMessage>
											</div>
											<div className="card-header col-md-12">
												<label className="label">
													Serviços :*
													</label>
												<Select
													id="servicoId"
													name="servicoId"
													htmlFor="servicoId"
													placeholder='Selecionar'
													className="select-style ajust-label-padrao disabled-select"
													isDisabled={this.state.visualizar}
													onChange={(e) => { setFieldValue('servicoId', e.value) }}
													value={this.state.servicos.filter(item => item.value === values.servicoId)}
													options={this.state.servicos}
													theme={theme => ({
														...theme,
														borderRadius: 0
													})}
												/>
												<ErrorMessage name="servicoId">{msg => <span className="span-errors">Campo Obrigatório </span>}</ErrorMessage>
											</div>
											{
												!editar && data !== null &&
												<div className="card-header col-md-12">
													<label className="label">
														Opções:*
													</label>
													<Select
														id="op"
														isDisabled={this.props.codigoCultura && this.props.codigoCultura === 'CAFE'}
														name="op"
														htmlFor="op"
														placeholder='Selecione um serviço'
														className="select-style ajust-label-padrao disabled-select"
														onChange={(e) => { setFieldValue('op', e.value); this.setState({ padrao: e.value }) }}
														value={opcoes.filter(item => item.value === values.op)}
														options={opcoes}
														theme={theme => ({
															...theme,
															borderRadius: 0
														})}
													/>
													<ErrorMessage name="op">{msg => <span className="span-errors">Campo Obrigatório </span>}</ErrorMessage>
												</div>
											}
										</div>
										{
											data === null &&
											<div className="row col-md-12">
												<div className="card-header col-md-12">
													<label className="label">
														Predecessora:*
													</label>
													<Field
														className='form-control bmd-form-group'
														placeholder="Informe a Predecessora"
														name="predecessora"
														onChange={handleChange}
														value={values.predecessora}
														htmlFor="predecessora"
														autoComplete="off"
														min="0"
														type="number" />
													<ErrorMessage
														name="predecessora">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
												<div className="card-header col-md-12">
													<label className="label">
														Folga:*
													</label>
													<Field
														className='form-control bmd-form-group'
														placeholder="Informe a quantidade de folgas"
														name="folga"
														onChange={handleChange}
														value={values.folga}
														htmlFor="folga"
														autoComplete="off"
														type="number" />
													<ErrorMessage
														name="folga">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
												<div className="card-header col-md-12">
													<label className="label">
														Duração:*
													</label>
													<Field
														className='form-control bmd-form-group'
														placeholder="Informe a duração"
														name="duracao"
														onChange={handleChange}
														value={values.duracao}
														htmlFor="duracao"
														autoComplete="off"
														type="number" />
													<ErrorMessage
														name="duracao">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
											</div>
										}
										{
											this.state.padrao === "padrao" &&
											<div className="row col-md-12">
												<div className="card-header col-md-12">
													<label className="label">
														Predecessora:*
													</label>
													<Field
														className='form-control bmd-form-group'
														placeholder="Informe a Predecessora"
														name="predecessora"
														onChange={handleChange}
														value={values.predecessora}
														htmlFor="predecessora"
														autoComplete="off"
														type="number" />
													<ErrorMessage
														name="predecessora">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
												<div className="card-header col-md-12">
													<label className="label">
														Folga:*
													</label>
													<Field
														className='form-control bmd-form-group'
														placeholder="Informe a quantidade de folgas"
														name="folga"
														onChange={handleChange}
														value={values.folga}
														htmlFor="folga"
														autoComplete="off"
														type="number" />
													<ErrorMessage
														name="folga">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
												<div className="card-header col-md-12">
													<label className="label">
														Duração:*
													</label>
													<Field
														className='form-control bmd-form-group'
														placeholder="Informe a duração"
														name="duracao"
														onChange={handleChange}
														value={values.duracao}
														htmlFor="duracao"
														autoComplete="off"
														type="number" />
													<ErrorMessage
														name="duracao">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
											</div>
										}
										{
											this.state.padrao === "porData" && data &&
											<div className="row col-md-12">
												<div className="card-header col-md-12">
													<label className="label">
														Período Inicial:
													</label>
													<InputMask
														className='form-control bmd-form-group'
														name="inicio"
														placeholder="Escolha o período inicial"
														htmlFor="inicio"
														mask="99/99/9999"
														maskChar={null}
														value={values.inicio}
														onChange={handleChange}
														autoComplete="off"
													/>
													<ErrorMessage
														name="inicio">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
												<div className="card-header col-md-12">
													<label className="label">
														Período Final:
													</label>
													<InputMask
														className='form-control bmd-form-group'
														name="fim"
														placeholder="Escolha o período final"
														htmlFor="fim"
														mask="99/99/9999"
														maskChar={null}
														value={values.fim}
														onChange={handleChange}
														autoComplete="off"
													/>
													<ErrorMessage
														name="fim">
														{msg => <span className="span-errors">Campo Obrigatório </span>}
													</ErrorMessage>
												</div>
											</div>
										}
									</div>
								</Modal.Body>
								<Modal.Footer className="footer-modal">
									<div className="row col-md-12">
										<div className="col-md-6 pull-left">
											<button className="btn btn-outline-secondary pull-left" variant="success" onClick={() => { close(); this.setState({ padrao: null }) }}>
												<i className='fas fa-close' /> Cancelar
											</button>
										</div>
										<div className="col-md-6 pull-right">
											<button type='submit' className="btn btn-primary active pull-right" variant="success" onClick={handleSubmit}>
												<i className='fas fa-check' /> {editar ? 'Atualizar' : 'Adicionar'}
											</button>
										</div>
									</div>
								</Modal.Footer>
							</Modal>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{this.modal()}
			</>
		)
	}
}
