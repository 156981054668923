import api from './api';

export default {
	buscarTodos(parametro) {
		return api.post(`/api/Parametros/BuscarTodas`, parametro);
	},
	GetByCulturaId(id) {
		return api.get(`/api/Parametros/GetByCulturaId/${id}`);
	},
	salvar(parametro) {
		return api.post(`/api/Parametros`, parametro);
	},
	deletar(id) {
		return api.delete(`/api/Parametros/${id}`);
	},
	buscarPorId(parametroId) {
		return api.get(`/api/Parametros/BuscarPorId/${parametroId}`);
	},
	editar(parametro) {
		return api.put('/api/Parametros', parametro);
	}
};


