import React, { Component } from 'react';
import { connect } from 'react-redux';
import Content from "../../../components/Layout/Content/content.component";
import ToastHelper from '../../../utils/toastHelper';
import MenuPerfil from '../../../components/Menu/menu.perfil'
import { LoadingStyle } from "./layout.style";

const mapStateToProps = state => ({
	loading: state.loading
});

class LayoutPerfilContainer extends Component {
	constructor(props) {
		super(props)
		const { history } = this.props;
		this.history = history;
	}
	componentDidMount() {
		ToastHelper.create();
	}

	render() {

		const { children, loading } = this.props;
		return (
			<>
				<MenuPerfil teste={''} history={''} ></MenuPerfil>
				<Content>
					{children}
				</Content>
				{loading.loading && (
					<LoadingStyle className="loading">
						<div className="text-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</LoadingStyle>
				)}
			</>
		);
	}
}



export default connect(
	mapStateToProps,
	null,
)(LayoutPerfilContainer);

