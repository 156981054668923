import Modal from '../../../node_modules/react-bootstrap/es/Modal'
import Button from '../../../node_modules/react-bootstrap/es/Button'
import "react-sweet-progress/lib/style.css";
import React, { useState } from 'react'; import ObjectHelper from 'utils/objectHelper';
import ToastHelper from './../../utils/toastHelper';
import './modal.ajustes.scss'
import {
	TextField,
	makeStyles
} from "@material-ui/core";
import {
	ThemeProvider,
	createMuiTheme
} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from "@material-ui/core/FormControl";
import ServicoService from '../../services/servicoService';

const theme = createMuiTheme({
	palette: {
		primary: { 500: "#00635A" }
	}
});

const useStyles = makeStyles(theme => ({
	root: {},
	base: {
		textAlign: "-webkit-center"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: "80%"
	},
	formControlHoras: {
		margin: theme.spacing(1),
		minWidth: "20%"
	},
	margin: {
		marginTop: "15px"
	},
}));

export default function ModalAjusteEmGrupo(props) {
	const classes = useStyles();
	const [localId, setLocalId] = useState();
	const [grupoServicoId, setGrupoServicoId] = useState();
	const [servicoId, setServicoId] = useState();
	const [horas, setHoras] = useState();
	const [quantidade, setQuantidade] = useState();

	const [opcoesServicos, setOpcoesServicos] = useState([]);
	const [erroServico, setErroServico] = useState();


	const closeModal = () => {
		props.setShowModal(false)
		limpaModal()
	}

	const buscarServicosPorGrupo = async (grupoId) => {
		await ServicoService.buscarPorGrupoId(JSON.parse(localStorage.getItem("fazendaId")).value, grupoId).then((response) => {
			if (response.status === 200)
				setOpcoesServicos(ObjectHelper.getValuesParseToSelect(response.data.result))
			else {
				ToastHelper.error(response.statusText);
				setOpcoesServicos([])
			}
		})
			.catch((error) => {
				ToastHelper.error(error.response.data);
			});
	};

	const setGrupoServico = (newValue) => {
		setServicoId('')
		setGrupoServicoId(newValue)
		newValue.value ? buscarServicosPorGrupo(newValue.value) : setOpcoesServicos([])
	}

	const submitModal = async () => {

		let alteracoes = {
			localId: localId,
			grupoServicoId: grupoServicoId ? grupoServicoId.value : null,
			horas: horas ? horas : '',
			servicoId: servicoId ? servicoId.value : null,
			quantidade: quantidade
		}
		let valido = await validaSubmit(alteracoes)
		if (!localId && !grupoServicoId && !horas && (props.tipoApontamento === 'INSUMO' ? !quantidade : true)) await props.setShowModal(false)
		else if (valido) {
			props.salvarAlteracoes(alteracoes)
			props.setShowModal(false)
			limpaModal()
		}
	}

	const limpaModal = () => {
		setLocalId('')
		setServicoId('')
		setGrupoServicoId('')
		setHoras('')
		setQuantidade('')
		setErroServico(false)
		setOpcoesServicos([])
	}

	const validaSubmit = (alteracoes) => {
		let valido = true

		if (alteracoes.grupoServicoId != null) {
			alteracoes.servicoId != null ? valido = true : valido = false
			setErroServico(true)
			return valido
		} else {
			return valido
		}
	}

	return (
		<>
			<Modal show={props.showModal} onHide={() => closeModal()}>
				<Modal.Header closeButton >
					<Modal.Title>{"Editar selecionados."}</Modal.Title>
				</Modal.Header>

				<ThemeProvider theme={theme}>

					<div className="col-md-12 mt-2 text-center">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="localId"
								size="small"
								options={props.locais || []}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) =>
									setLocalId(newValue.value)
								}
								value={props.locais.filter(x => x.value === localId)[0]}
								defaultValue={props.locais.filter(x => x.value === localId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Local" />
								)}
							/>
						</FormControl>
					</div>
					<div className="col-md-12 text-center">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="grupoServicoId"
								size="small"
								options={props.gruposServicos || []}
								getOptionLabel={(option) => option.label}
								onChange={(event, newValue) => {
									setGrupoServico(newValue)
								}}
								value={props.gruposServicos.filter(x => x.value === grupoServicoId)[0]}
								defaultValue={props.gruposServicos.filter(x => x.value === grupoServicoId)[0]}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Grupo de Serviço" />
								)}
							/>
						</FormControl>
					</div>
					<div className="col-md-12 text-center">
						<FormControl className={classes.formControl}>
							<Autocomplete
								id="servicoId"
								size="small"
								options={opcoesServicos || []}
								getOptionLabel={(option) => option.label}
								disabled={opcoesServicos.lenght < 1}
								onChange={(event, newValue) =>
									setServicoId(newValue)
								}
								value={servicoId || null}
								defaultValue={servicoId || null}
								renderInput={(params) => (
									<TextField {...params} variant="standard" label="Serviço" />
								)}
							/>
						</FormControl>
					</div>
					<div className="col-md-12 text-center">
						{erroServico && (
							<span className="span-errors">
								Ao selecionar um grupo de serviço o serviço é obrigatório.
							</span>
						)}
					</div>
					{
						props.tipoApontamento === 'MO' &&
						<div className="col-md-12 text-center">
							<FormControl className={classes.formControlHoras}>
								<TextField
									id="data"
									name="data"
									type="time"
									label="Horas"
									onChange={e => {
										setHoras(e.target.value)
									}}
									value={horas}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</FormControl>
						</div>
					}
					{
						props.tipoApontamento === 'INSUMO' &&
						<div className="col-md-12 text-center">
							<FormControl className={classes.formControlHoras}>
								<TextField
									id="quantidade"
									name="quantidade"
									label="Quantidade"
									type="number"
									onChange={e => {
										setQuantidade(e.target.value)
									}}
									value={quantidade}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</FormControl>
						</div>
					}
				</ThemeProvider>
				<Modal.Footer className="footer-modal mt-3">
					<Button bsStyle="primary" className="btn active mr-4 wd-30  modal-2-btn btn-padrao-rehagro-cancelar" onClick={() => {
						closeModal()
					}}>
						Cancelar
                </Button>
					<Button bsStyle="primary" className="btn active wd-30  modal-2-btn btn-padrao-rehagro" onClick={() =>
						submitModal()
					}>
						Salvar
                </Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
