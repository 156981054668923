import React from 'react'; import ObjectHelper from 'utils/objectHelper'
import Title from "../../components/Title/title.component"
import { CardStyle } from '../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import { RecursosSchema } from './recursos.cadastro.validate';
import UnidadeService from '../../services/unidadeService';
import ToastHelper from "../../utils/toastHelper";
import NumberFormat from 'react-number-format';
import RecursosTipoService from '../../services/recursosTipoService';
import RecursosService from '../../services/recursosService';
import FamiliaMaterialService from '../../services/familiaMaterialService';
import PessoaTipoService from '../../services/categoriaService';
import MaquinaTipoService from '../../services/tipoMaquinaService';

import Select from 'react-select'

export default class RecursosCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editar: false,
			fazendaId: '',
			unidades: [],
			dados: {
				id: undefined,
				codigo: undefined,
				nome: undefined,
				descricao: undefined,
				tipo: undefined,
				classe: undefined,
				unidadeId: undefined,
				custoPadrao: undefined
			},
			tipoList: [],
			classes: [],
			disabled: false
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		const { match: { params, path } } = this.props;
		var isVisualizar = path.search("visualizar");

		isVisualizar > 0 ? this.setState({ disabled: true }) : this.setState({ disabled: false });

		if (params.id) {
			await this.buscar(params.id);
			this.setState({ editar: true })
		}

		await this.getUnidades()
		await this.getRecursosTipo()
	}

	async buscar(id) {
		let { data } = await RecursosService.get(id);
		data.result.tipo = { label: data.result.recursosTipo.nome, value: data.result.recursosTipoId }
		data.result.classe = { label: data.result.recursoClasse, value: data.result.recursoClasseId }
		data.result.unidade = { label: data.result.unidade.sigla, value: data.result.unidadeId }

		if (!this.state.disabled)
			this.changeRecursoTipo(data.result.recursosTipo)

		await this.setState({
			dados: { ...data.result },
			id: id
		});
	}

	getRecursosTipo = async () => {
		let { data } = await RecursosTipoService.get(JSON.parse(localStorage.getItem('fazendaId')).value);
		let tipos = data.result.filter(item => item.codigo !== 'IN' && item.codigo !== 'RE');
		await this.setState({
			tipoList: ObjectHelper.getRecursoTipoParseToSelect(tipos),
		});
	}

	getUnidades = async () => {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			pageSize: 99999
		}

		let data = await UnidadeService.getAll(param);
		await this.setState({ unidades: this.getValuesParseToSelect(data.data.result.items, "id", "sigla") })
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	voltar = () => {
		this.history.goBack();
	}

	onSubmit = async (values) => {
		let metodo = this.state.editar === true ? "editar" : "salvar";
		let acao = this.state.editar === true ? "editado" : "cadastrado";
		values.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

		values.recursoClasse = values.classe.label;
		values.recursoCodigo = values.tipo.label;

		let { data } = await RecursosService[metodo](values);

		if (data.isOk) {
			ToastHelper.success('Recurso ' + acao + ' com sucesso!');
			this.voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
	}

	changeRecursoTipo = async (e) => {
		let param = {
			fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
			page: 1,
			pageSize: 1000,
			order: "Nome"
		}

		let data = null;

		if (e.codigo === "MA") data = await MaquinaTipoService.buscarTodas(param);
		if (e.codigo === "IN" || e.codigo === "RE") data = await FamiliaMaterialService.buscarTodas(param);
		if (e.codigo === "MO") data = await PessoaTipoService.buscarTodas(param);

		if (data) {
			let classes = data.data.result.items;
			if (e.codigo === "RE") {
				classes = classes.filter(item => item.permiteReceita);
			}
			await this.setState({
				classes: this.getValuesParseToSelect(classes)
			})
		} else {
			await this.setState({
				classes: this.getValuesParseToSelect([])
			})
		}
	}

	campos() {
		const { state: { dados } } = this;

		return (
			<Formik
				initialValues={dados}
				enableReinitialize
				validationSchema={RecursosSchema || null}
				onSubmit={(values, options) => {
					this.onSubmit(values, options);
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleChange }) => (
					<form className="form " onSubmit={handleSubmit}>
						<div>
							<div className='row'>
								<div className='col'>
									<Title>{(
										this.state.disabled ? "Visualizar" :
											this.state.editar ? "Editar" :
												"Cadastrar") + ' Recurso'}
									</Title>
								</div>
							</div>
							<CardStyle className="card">
								<div className='card-header row justify-content-start col-md-12'>
									<div className="card-header col-md-4">
										<label className="label">
											Código:*
                                        </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Insira o código do recurso"
											name="codigo"
											onChange={handleChange}
											value={values.codigo}
											disabled={this.state.disabled}
											autoComplete="off"
											htmlFor="codigo"
											type="text" />
										<ErrorMessage
											name="codigo">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Nome:*
                                        </label>
										<Field
											className='form-control bmd-form-group'
											placeholder="Insira o nome do recurso"
											name="nome"
											onChange={handleChange}
											disabled={this.state.disabled}
											value={values.nome}
											autoComplete="off"
											htmlFor="nome"
											type="text" />
										<ErrorMessage
											name="nome">
											{msg => <div className="error error-message error-validate ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4">
										<label className="label">
											Custo Padrão:
                                        </label>
										<NumberFormat
											thousandSeparator={'.'}
											decimalSeparator={','}
											prefix={'R$'}
											className='form-control bmd-form-group'
											placeholder="Insira o custo padrão do recurso"
											name="custoPadrao"
											onChange={handleChange}
											value={values.custoPadrao}
											disabled={this.state.disabled}
											htmlFor="custoPadrao"
										/>
										<ErrorMessage
											name="custoPadrao">
											{msg => <div className="error error-message error-validate  ajust-div-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4 ajust-label-padrao">
										<label className="label">
											Tipo:*
                                        </label>
										<Select id="tipo"
											name="tipo"
											htmlFor="tipo"
											placeholder='Insira o tipo do recurso'
											className={"select-style ajust-label-padrao padding-select " + (this.state.disabled ? "disabled-input" : "")}
											isDisabled={this.state.disabled}
											value={values.tipo}
											onChange={(e) => {
												setFieldValue('recursosTipoId', e.value);
												this.changeRecursoTipo(e);
												setFieldValue('tipo', e);
												setFieldValue('classe', null);
												setFieldValue('recursoClasseId', null);
												setFieldValue('unidade', null);
												setFieldValue('unidadeId', null)
											}}
											options={this.state.tipoList}
											theme={theme => ({
												...theme,
												borderRadius: 0
											})}
										/>
										<ErrorMessage
											name="tipo">
											{msg => <div className="error error-message error-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4 ajust-label-padrao">
										<label className="label">
											Classe:*
                                        </label>
										<Select id="classe"
											name="classe"
											htmlFor="classe"
											placeholder='Insira a classe do recurso'
											onChange={(e) => {
												setFieldValue('classe', e);
												setFieldValue('recursoClasseId', e.value);
											}}
											className={"select-style ajust-label-padrao padding-select " + (this.state.classes.length === 0 ? "disabled-input" : "")}
											isDisabled={this.state.classes.length === 0}
											options={this.state.classes}
											// value={values.classe}
											value={this.state.classes.length === 0 ? undefined : values.classe}
											theme={theme => ({
												...theme,
												borderRadius: 0
											})}
										/>
										<ErrorMessage
											name="classe">
											{msg => <div className="error error-message error-validate">{msg} </div>}
										</ErrorMessage>
									</div>
									<div className="card-header col-md-4 ajust-label-padrao">
										<label className="label">
											Unidade:*
                                        </label>
										<Select id="unidadeId"
											name="unidadeId"
											htmlFor="unidadeId"
											placeholder='Insira a unidade do recurso'
											className={"select-style ajust-label-padrao padding-select " + (this.state.disabled ? "disabled-input" : "")}
											value={this.state.unidades.filter(item => item.value === values.unidadeId)}
											// onChange={(e) => { setFieldValue('unidadeId', e.value) }}
											// value={this.state.unidades.filter(item => item.value === values.unidadeId)}
											onChange={(e) => {
												setFieldValue('unidade', e);
												setFieldValue('unidadeId', e.value)
											}}
											options={this.state.unidades}
											isDisabled={this.state.disabled}
											theme={theme => ({
												...theme,
												borderRadius: 0
											})}
										/>
										<ErrorMessage
											name="unidadeId">
											{msg => <div className="error error-message error-validate">{msg} </div>}
										</ErrorMessage>

									</div>

									<div className="card-header col-md-12">
										<label className="label">
											Descrição:
                                        </label>
										<textarea
											className='form-control bmd-form-group'
											placeholder="Insira a descrição do recurso"
											name="descricao"
											onChange={handleChange}
											value={values.descricao}
											htmlFor="descricao"
											type="textarea"
											disabled={this.state.disabled}
										/>

									</div>
								</div>
							</CardStyle>
							<div className="col-md-12 mt-3">
								<button className="btn btn-outline-secondary pull-left" type="button" onClick={this.voltar}>
									<i className='fas fa-close' /> {this.state.disabled ? "Voltar" : "Cancelar"}
								</button>
								{!this.state.disabled &&
									<button className="btn btn-primary active pull-right" type="submit">
										<i className='fas fa-check' /> Salvar
                                    </button>

								}
							</div>
						</div>
					</form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<>
				{
					this.campos()
				}
			</>
		)
	}
}
