import api from './api';

export default {
	gerarRelatorio(param){
		return api.post("/api/Relatorio/GerarRelatorio", param, { responseType: 'blob' });
	},
	buscarTodos(){
		return api.get(`/api/Relatorio/BuscarTodos/${JSON.parse(localStorage.getItem("fazendaId")).value}`)
	},
	buscar(id){
		return api.get(`/api/Relatorio/${id}`);
	},
	validarRelatorio(param){
		return api.post("/api/Relatorio/ValidarRelatorio", param);
	},
};
