import React from 'react';
import Title from "../../../components/Title/title.component"
import { CardStyle } from '../../../components/CardStyle/cardstyle.component'
import { Formik, Field, ErrorMessage } from 'formik';
import SelectMulti from '../../../components/SelectMulti/selectmulti.component';
import { UsuarioSchema } from './usuario.cadastro.validate';
import ToastHelper from "../../../utils/toastHelper";
import UsuarioService from '../../../services/usuarioService';
import PerfilService from '../../../services/perfilService';
import { UsuarioEditarSchema } from './usuario.editar.validate'
import SubmitFormButtons from '../../../components/submitFormButtons/submitFormButtons';

export default class UsuarioCadastro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editar: false,
			fazendaId: '',
			dados: {
				nome: undefined,
				userName: undefined,
				password: undefined,
				email: "",
				perfilId: undefined,
				confirmacao: undefined,
				usuarioJaExiste: false
			},
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0,
			},
			papeis: [],
			perfis: [],
			usuariopapeis: [],
			pessoas: [],
		};
		this.history = this.props.history;
	}

	async componentDidMount() {
		let { data } = await PerfilService.listar();

		this.setState({
			papeis: data.result
		})

		const { match: { params } } = this.props;
		if (params.id)
			await this.buscar(params.id);
	}

	getValuesParseToSelect(list, valueName, labelName) {
		let options = [];

		valueName = valueName || 'id';
		labelName = labelName || 'nome';
		list = Array.isArray(list) ? list : [];
		list.forEach(item => {
			options.push({ value: item[valueName], label: item[labelName] })
		});
		return options;
	}

	async buscar(usuarioId) {
		let { data } = await UsuarioService.buscarPorId(usuarioId, JSON.parse(localStorage.getItem('fazendaId')).value);
		data.result.perfilId = this.getValuesParseToSelect(data.result.papeis);
		data.result.password = undefined
		data.result.confirmacao = undefined
		data.result.oldPapeis = await this.handleAdicionarPerfil(data.result.perfilId)

		await this.setState({ userName: data.result.userName })

		this.setState({
			dados: { ...data.result },
			editar: true,
		});
	}

	async handleAdicionarPerfil(papeis) {
		var papel = null
		var array = []
		if (papeis.length > 0) {
			papeis.forEach(function (item) {
				papel = {
					perfilId: item.value,
					fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
					label: item.label
				}
				array.push(papel);
			});
			this.setState({ ...this.state, perfis: array })
			return array;
		}
	}

	handleExluirPerfil(item) {
		var items = [...this.state.papeis];
		var indexOf = items.indexOf(item);
		var itemsDeleted = items.splice(indexOf, 0);
		this.setState({
			usuariopapeis: [...itemsDeleted]
		});

		ToastHelper.success("Perfil excluído!");
	}

	voltar = () => {
		this.history.goBack();
	}

	onSubmit = async (values) => {
		if (!this.state.dados.usuarioJaExiste) {

			var metodo = this.state.editar ? "editar" : "salvar";
			let newValues = values
			newValues.papeis = []
			newValues.papeis.push({
				perfilId: this.state.editar ? values.perfilId[0].value : values.perfilId.value,
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value
			})
			newValues.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

			if (metodo === "editar") {
				const { match: { params } } = this.props;
				if (params.id)
					newValues.id = params.id;
			}

			let result = await UsuarioService[metodo](newValues);
			if (!result.data.isOk)
				return ToastHelper.error(result.data.mensagem);

			ToastHelper.success("Usuário cadastrado com sucesso");
		} else {

			let newValues = values
			newValues.papeis = []
			newValues.papeis.push({
				perfilId: this.state.editar ? values.perfilId.value : values.perfilId.value,
				fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value
			})
			newValues.fazendaId = JSON.parse(localStorage.getItem('fazendaId')).value;

			if (metodo === "editar") {
				const { match: { params } } = this.props;
				if (params.id)
					newValues.id = params.id;
			}

			let result = await UsuarioService.novaFazenda(newValues);
			if (!result.data.isOk)
				return ToastHelper.error(result.data.mensagem);

			ToastHelper.success("Usuario vinculado à fazenda com sucesso!");
		}
		this.voltar();
	}

	async handleChangeUserName(event) {
		if (event.target.value.indexOf(' ') >= 0) {
			ToastHelper.warn("O nome de usuário não pode conter espaços!");
		}
		var nome = event.target.value.trim();

		return await this.setState({ userName: nome.toLowerCase() })

	}

	async verificaSeUsuarioExiste(event) {
		var text = event.target.value
		if (event.target.value) {
			let email = event.target.value.toLowerCase()
			let { data } = await UsuarioService.buscarPorEmail(email)

			if (data.isOk) {
				ToastHelper.warn("Usuario já cadastrado no sistema, vamos vinculá-lo a este nova fazenda!");
				await this.setState({
					...this.state,
					dados: {
						nome: data.result.nome,
						userName: data.result.userName,
						passwordHash: data.result.passwordHash,
						email,
						id: data.result.id,
						perfilId: undefined,
						confirmacao: data.result.passwordHash,
						password: data.result.passwordHash,
						usuarioJaExiste: true
					}
				})
			} else {
				await this.setState({
					...this.state,
					dados: {
						nome: "",
						userName: "",
						password: "",
						email: text,
						perfilId: undefined,
						confirmacao: "",
						usuarioJaExiste: false
					}
				})
			}
		}
	}

	render() {
		const { state: { dados } } = this;
		return (
			<>
				<Formik
					initialValues={dados}
					enableReinitialize
					validationSchema={this.state.editar ? UsuarioEditarSchema : UsuarioSchema}
					onSubmit={(values, options) => {
						this.onSubmit(values, options);
					}}
				>
					{({ values, setFieldValue, handleSubmit, handleChange }) => (
						<form className="form" onSubmit={handleSubmit}>
							<div>
								<div className='row'>
									<div className='col'>
										<Title>{(this.state.editar ? "Edição" : "Cadastro") + ' de Usuário'}</Title>
									</div>
								</div>
								<CardStyle className="card">

									<div className='card-header row justify-content-start col-md-12'>
										<div className="card-header col-md-6">
											<label className="label">
												Email:*
                                    </label>
											<Field
												className='form-control'
												placeholder="Email"
												name="email"
												autoComplete="off"
												value={values.email !== "" ? values.email.trim().toLowerCase() : ""}
												htmlFor="email"
												type="email"
												onBlur={(e) => this.verificaSeUsuarioExiste(e)}
											/>
											<ErrorMessage
												name="email">
												{msg => <div className="error error-message error-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Nome:*
                                    </label>
											<Field
												className='form-control'
												placeholder="Nome"
												name="nome"
												onChange={handleChange}
												disabled={this.state.dados.usuarioJaExiste}
												value={values.nome}
												htmlFor="nome"
												autoComplete="off"
												type="text" />
											<ErrorMessage
												name="nome">
												{msg => <div className="error error-message error-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Nome de Usuário:*
                                    </label>
											<Field
												className='form-control'
												placeholder="Nome de Usuário"
												name="userName"
												value={values.userName !== undefined ? values.userName.trim().toLowerCase() : null}
												htmlFor="userName"
												autoComplete="off"
												disabled={this.state.dados.usuarioJaExiste}
												type="text" />
											<ErrorMessage
												name="userName">
												{msg => <div className="error error-message error-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										<div className="card-header col-md-6">
											<label className="label">
												Tipo Perfil:*
                                    </label>
											<SelectMulti
												id="perfilId"
												name="perfilId"
												placeholder="Tipo de Perfil"
												label="nome"
												isClearable={true}
												options={this.state.papeis}
												isMulti={false}
												noOptionsMessage={() => 'Lista vazia'}
												onChange={(item) => {
													if (item) {
														this.handleAdicionarPerfil(item);
														setFieldValue('perfilId', item);
													}
												}}
												value={values.perfilId}
											/>

											<ErrorMessage
												name="perfilId">
												{msg => <div className="error error-message error-validate">{msg} </div>}
											</ErrorMessage>
										</div>
										{
											!this.state.editar && !this.state.dados.usuarioJaExiste &&
											<>
												<div className="card-header col-md-6">
													<label className="label">
														Senha:*
                                            </label>
													<Field
														className='form-control'
														placeholder="Senha"
														name="password"
														value={values.password}
														htmlFor="password"
														autoComplete="new-password"
														type="password" />
													<ErrorMessage
														name="password">
														{msg => <div className="error error-message error-validate">{msg} </div>}
													</ErrorMessage>
												</div>
												<div className="card-header col-md-6">
													<label className="label">
														Confirmação de Senha:*
                                            </label>
													<Field
														className='form-control'
														placeholder="Confirmação de Senha"
														name="confirmacao"
														value={values.confirmacao}
														htmlFor="confirmacao"
														autoComplete="off"
														type="password" />
													<ErrorMessage
														name="confirmacao">
														{msg => <div className="error error-message error-validate">{msg} </div>}
													</ErrorMessage>
												</div>
											</>
										}
									</div>
								</CardStyle>
								{
									!this.state.dados.usuarioJaExiste &&
									<SubmitFormButtons
										flagVisualizar={this.state.visualizar}
										flagEditar={this.state.dados.id !== undefined}
										actionGoBack={() => this.voltar()}
									/>
								}
								{
									this.state.dados.usuarioJaExiste &&
									<div className="col-md-12 mt-3">
										<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => this.voltar()}>
											<i className='fas fa-close' /> Cancelar'
                                </button>

										<button className="btn btn-primary active pull-right" type="submit" hidden={this.state.visualizar}>
											<i className='fas fa-check' /> Confirmar
                                </button>
									</div>
								}
							</div>
						</form>
					)}
				</Formik>
			</>
		)
	}
}
