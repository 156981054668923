import React, { useState, useEffect } from 'react';
import ObjectHelper from 'utils/objectHelper';
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import Title from '../../components/Title/title.component';
import SubmitFormButtons from '../../components/submitFormButtons/submitFormButtons';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import atividadeAgricolaService from '../../services/atividadeAgricolaService'
import fazendaService from '../../services/fazendaService'
import ToastHelper from "../../utils/toastHelper";
import Toggle from 'react-toggle';

export default function FazendaCadastro(props) {

	const { register, handleSubmit, errors, setValue } = useForm({});
	const { match: { params } } = props;
	const [visualizar, setVisualizar] = useState(false)
	const [atividadeAgricola, setAtividadeAgricola] = useState()

	const voltar = () => {
		props.history.goBack();
	}

	const [atividades, setAtividades] = useState([]);
	const [modelo, setmodelo] = useState(false);
	const [fazendasModelo, setFazendasModelo] = useState([]);
	const [fazendaModeloSelecionada, setfazendaModeloSelecionada] = useState(null);

	const buscarModelos = async () => {
		const { data } = await fazendaService.buscarModelos();
		setFazendasModelo(data.result)
	}

	useEffect(() => {
		async function fetchData() {

			const { data } = await atividadeAgricolaService.buscarTudo(JSON.parse(localStorage.getItem('fazendaId')).value)
			if (data.isOk) await setAtividades(data.result)

			if (params.modo === 'visualizar') setVisualizar(true)
			if (params.id === undefined) buscarModelos();
			if (params.id !== undefined) {
				const fazenda = await fazendaService.buscarPorId(params.id)
				setmodelo(fazenda.data.result.result.modelo);
				setValue('nome', fazenda.data.result.result.nomeRazao)
				setValue('hectares', fazenda.data.result.result.hectares)
				setValue('atividadeAgricola', fazenda.data.result.result.atividadeAgricola ? { value: fazenda.data.result.result.atividadeAgricola.id, label: fazenda.data.result.result.atividadeAgricola.nome } : "")
				setAtividadeAgricola(fazenda.data.result.result.atividadeAgricola ? { value: fazenda.data.result.result.atividadeAgricola.id, label: fazenda.data.result.result.atividadeAgricola.nome } : "")
				setValue('cidade', fazenda.data.result.result.cidade)
			}
		}
		fetchData();
	}, []);


	const submitForm = async (param) => {
		let metodo = params.id !== undefined ? "update" : "create";
		let acao = params.id !== undefined ? "editada" : "cadastrada";

		let newParams = param
		if (param.atividadeAgricola) newParams.atividadeAgricolaId = param.atividadeAgricola.value
		newParams.id = params.id !== undefined ? params.id : params.id;
		newParams.modelo = modelo
		if (fazendaModeloSelecionada) newParams.fazendaModeloId = fazendaModeloSelecionada
		const { data } = await fazendaService[metodo](newParams);

		if (data.isOk) {
			ToastHelper.success("Cliente " + acao + " com sucesso!");
			voltar();
		} else
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));

	};

	return (
		<>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className='row'>
					<div className='col'>
						<Title>
							Cadastro de Clientes
						</Title>
					</div>
				</div>
				<CardStyle className='card'>
					<div className='card-header row justify-content-start col-md-12'>
						<div className='card-header col-md-4'>
							<label className='label'>
								Nome do Cliente:*
							</label>
							<input type='text'
								className='form-control bmd-form-group'
								readOnly={visualizar}
								name='nome'
								autoComplete="off"
								ref={register({ required: true })}
							/>
							{errors.nome && <span className='span-errors'> Campo Obrigatório</span>}
						</div>
						<div className='card-header col-md-4'>
							<label className='label'>
								Hectares:
							</label>
							<input
								step="0.01"
								type='number'
								className='form-control bmd-form-group'
								readOnly={visualizar}
								name='hectares'
								autoComplete="off"
								ref={register({ required: false })}
							/>
						</div>

						<div className="card-header col-md-4">
							<label className="label" htmlFor="atividadeAgricolaId">
								Principal Atividade Agricola :
								</label>
							<Select
								isClearable={true}
								placeholder='Selecionar'
								className="select-style ajust-label-padrao disabled-select"
								onChange={item => {
									setValue('atividadeAgricola', item ? item : undefined)
									setAtividadeAgricola(item ? item : undefined)
								}}
								value={atividadeAgricola}
								ref={() => register({ name: "atividadeAgricola" })}
								options={ObjectHelper.getValuesParseToSelect(atividades)}
								theme={theme => ({ ...theme, borderRadius: 0 })}
								isDisabled={visualizar}
							/>
						</div>
						<div className='card-header col-md-4'>
							<label className='label'>
								Cidade:
							</label>
							<input
								type='text'
								className='form-control bmd-form-group'
								readOnly={visualizar}
								name='cidade'
								autoComplete="off"
								ref={register({ required: false })}
							/>
						</div>
						<div className="col-md-4 ">
							<div className="col-md-12 label-horas">
								<label>Modelo</label>
							</div>
							<label className="col-md-12">
								<Toggle
									defaultChecked={modelo}
									checked={modelo}
									value={modelo}
									icons={{
										modelo: <i className="fas fa-check icon-toogle" />,
										unchecked: <i className="fas fa-times icon-toogle" />,
									}}
									onChange={e => setmodelo(!modelo)} />
							</label>
						</div>
						{
							params.id === undefined &&
							<div className="card-header col-md-4">
								<label className="label" htmlFor="atividadeAgricolaId">
									Fazenda Modelo :
								</label>
								<Select
									isClearable={true}
									placeholder='Selecionar'
									className="select-style ajust-label-padrao disabled-select"
									onChange={item => {
										setfazendaModeloSelecionada(item.value)
									}}
									options={fazendasModelo}
									theme={theme => ({ ...theme, borderRadius: 0 })}
									isDisabled={visualizar}
								/>
							</div>
						}
					</div>
				</CardStyle>
				<SubmitFormButtons
					flagVisualizar={visualizar}
					flagEditar={params.id !== undefined}
					actionGoBack={() => props.history.goBack()}
				/>
			</form>
		</>
	)
}
