import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
	getLocais: ['params', 'callback'],
	setLocais: ['locais'],

	getLocaisSafra: ['params', 'callback'],
	setLocaisSafra: ['locais'],

	getServicos: ['params', 'callback'],
	setServicos: ['servicos'],

	getGrupoServicos: ['params', 'callback'],
	setGrupoServicos: ['grupoServicos'],

	getRecursos: ['params', 'callback'],
	setRecursos: ['recursos'],

	getRecursosTipo: ['params', 'callback'],
	setRecursosTipo: ['recursosTipo'],

	createPlanejamento: ['params', 'history', 'callback'],
	postPlanejamento: ['params'],

	updatePlanejamento: ['params', 'history', 'callback'],
	putPlanejamento: ['params'],

	getPlanejamentoById: ['params', 'callback'],
	setPlanejamentoById: ['params'],

	buscarPlanejamentos: ['params', 'callback'],
	readPlanejamentos: ['params'],

	deletePlanejamentoSafra: ['params', 'callback'],

})

const INITIAL_STATE_VALUES = {
	locais: [],
	grupoServicos: [],
	servicos: [],
	recursos: [],
	insumosAnaliseSolo: [],
	recursosTipo: [],
	planejamento: null
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getLocais = (state = INITIAL_STATE_VALUES, action) => ({ ...state, locais: action.data })
const getServicos = (state = INITIAL_STATE_VALUES, action) => ({ ...state, servicos: action.data })
const getGrupoServicos = (state = INITIAL_STATE_VALUES, action) => ({ ...state, grupoServicos: action.data })
const getRecursos = (state = INITIAL_STATE_VALUES, action) => ({ ...state, recursos: action.data })
const getRecursosTipo = (state = INITIAL_STATE, action) => ({ ...state, recursosTipo: action.data })
const createPlanejamento = (state = INITIAL_STATE_VALUES, action) => ({ ...state, planejamento: action.data })
const buscarPlanejamentos = (state = INITIAL_STATE_VALUES, action) => ({ ...state, planejamentos: action.data })
const updatePlanejamento = (state = INITIAL_STATE_VALUES, action) => ({ ...state, planejamento: action.data })
const getPlanejamentoById = (state = INITIAL_STATE, action) => ({ ...state, planejamento: action.data })
const deletePlanejamentoSafra = (state = INITIAL_STATE, action) => ({ ...state, idDeletedItem: action.params })
const getLocaisSafra = (state = INITIAL_STATE, action) => ({ ...state, locais: action.data })

export default createReducer(INITIAL_STATE_VALUES, {
	[Types.SET_LOCAIS]: getLocais,
	[Types.SET_SERVICOS]: getServicos,
	[Types.SET_GRUPO_SERVICOS]: getGrupoServicos,
	[Types.SET_RECURSOS]: getRecursos,
	[Types.POST_PLANEJAMENTO]: createPlanejamento,
	[Types.READ_PLANEJAMENTOS]: buscarPlanejamentos,
	[Types.PUT_PLANEJAMENTO]: updatePlanejamento,
	[Types.SET_PLANEJAMENTO_BY_ID]: getPlanejamentoById,
	[Types.SET_RECURSOS_TIPO]: getRecursosTipo,
	[Types.DELETE_PLANEJAMENTO_SAFRA]: deletePlanejamentoSafra,
	[Types.SET_LOCAIS_SAFRA]: getLocaisSafra,
})
