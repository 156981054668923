import moment from 'moment';

export default class DateHelper {
	static convertStringDate(dataString, formatInput, formateOutput) {
		return moment(dataString, formatInput).format(formateOutput);
	}

	static convertStringToDate(dataString, formateOutput) {
		return typeof dataString === 'string'
			? moment(dataString, formateOutput).toDate()
			: null;
	}

	static isValid(date) {
		if (typeof date !== "string") return false
		var dia = date.split("/")[0];
		var mes = date.split("/")[1];
		var ano = date.split("/")[2];

		if (ano !== undefined && ano.length < 4) return false
		else if (dia !== undefined && parseInt(dia) > 31) return false
		else if (mes !== undefined && parseInt(mes) > 12) return false
		else return true
	}

	static setTimeZone(data) {
		return this.isValid(data)
			? moment(data)
				.tz('America/Sao_Paulo')
				.format()
			: null;
	}

	static formatStringDate(date) {
		var formatDateTime = this.convertStringToDate(date, "DD/MM/YYYY");
		if (this.isValid(formatDateTime)) {
			return moment(formatDateTime).format('DD/MM/YYYY');
		}
		return '--/--/--';
	}

	static formatDate(date) {
		if (date !== 'Invalid date') return moment(date).format('DD/MM/YYYY');
		else return '--/--/--';
	}

	static formatDate3(date) {
		if (this.isValid(date)) {
			return moment(date).format('YYYY-MM-DD');
		}
		return '--/--/--';
	}

	static formatDate2(data) {
		var dia = data.split("/")[0];
		var mes = data.split("/")[1];
		var ano = data.split("/")[2];

		return ("0" + mes).slice(-2) + '/' + ("0" + dia).slice(-2) + '/' + ano;
	}

	static convert(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join("-");
	}

	static convert2(str) {
		var date = this.createDate2(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join("-");
	}

	static createDate(string) {
		var dia = string.split("/")[0];
		var mes = string.split("/")[1];
		var ano = string.split("/")[2];
		var date = Date.parse(`${ano}-${mes}-${dia}T00:00:00-03:00`)
		if (this.isValid(date) !== 'Invalid date')
			return date

	}

	static createDate2(string) {
		if (typeof string !== "string") return false

		if (string.indexOf("-") !== -1) {
			var dia = string.split("-")[0];
			var mes = string.split("-")[1];
			var ano = string.split("-")[2];
		} else if (string.indexOf("/") !== -1) {
			dia = string.split("/")[0];
			mes = string.split("/")[1];
			ano = string.split("/")[2];
		}
		let date = new Date(`${ano}-${mes}-${dia}T00:00:00-03:00`);
		if (this.isValid(date)) {
			return date

		} else
			return false
	}

	static templateCreateDate(string) {

	}

	static formatarData(string) {
		if (typeof string !== "string") return false

		var dia = string.split("/")[0];
		var mes = string.split("/")[1];
		var ano = string.split("/")[2];
		let date = `${ano}-${mes}-${dia}`;
		if (parseInt(dia) > 31 || parseInt(mes) > 12)
			return false
		else
			return date
	}

	static toDate(dateStr) {
		var parts = dateStr.split("/")
		let item1 = parts[0]
		if (item1.length === 4) {
			return dateStr
		} else {
			let data = parts[2] + "-" + (parts[1].length !== 2 ? '0' + parts[1] : parts[1]) + "-" + (parts[0].length !== 2 ? '0' + parts[0] : parts[0])
			return data
		}
	}

}
