import React from "react";
import logo from "../../assets/logo/logo.png";
import profileEmpty from "../../assets/logo/profileEmpty.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  removeToken,  getUserId } from "../../services/auth";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./menu.scss";
import 'react-flags-select/css/react-flags-select.css';
import Traducoes from '../../utils/traducoes'
import { Creators as ModulosCreators } from '../../store/ducks/permissao';
import { validatePermission } from "../../permissoes/permissionHelper";

class MenuPerfil extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			drawer: false,
			userName: "",
			fazendas: [],
			fazendaAtual: null,
			safraId: null
		};
		this.history = this.props.history;
		this.logout = this.logout.bind(this);
		this.onChange = this.onChange.bind(this);
		this.setPermissao = this.setPermissao.bind(this);
		this.checkPermissao = this.checkPermissao.bind(this);
    }
    
	servicoListagem = () => {
		this.history.replace({
			pathname: "/servico"
		})
	}

	checkPermissao(permissao) {
		return validatePermission(permissao, this.props.permissoes);
	}

	setLinguagem() {
		let linguagem = JSON.parse(localStorage.getItem('linguagem'))
		if (linguagem === null) {
			localStorage.setItem('linguagem', JSON.stringify("BR"));
		}
	}

	logout() {
		try {
			removeToken();
			localStorage.clear();
			sessionStorage.clear();
			this.history.push("/login");
		} catch (error) {
			this.setState({
				error: "Houve um problema com o logout"
			});
		}
	}

	async onChange(param) {
		localStorage.removeItem('fazendaId');
		localStorage.removeItem('safraId');
		localStorage.setItem('fazendaId', JSON.stringify(param));
		this.history.push("/");

		this.setPermissao();

		window.location.reload();
	}

	setPermissao() {
		if (localStorage.getItem('fazendaId')) {
			var usuarioId = getUserId();
			this.props.permissaoSetar(usuarioId);
		}
	}

	async onChangeSafra(param) {
		localStorage.setItem('safraId', JSON.stringify(param));
		this.history.push('/');
		this.history.push({
			pathname: `/`
		});
		window.location.reload();

	}

	isActive() {
		if (this.state.drawer === true) {
			this.setState({ drawer: false });
			this.props.teste(false);
		} else {
			this.setState({ drawer: true });
			this.props.teste(true);
		}
	}

	apontamentoMaoDeObra = () => {
		this.history.push({ pathname: "/apontamento" })
	}


	afastamentosListagem = () => {
		this.history.replace({
			pathname: "/afastamentos/listagem"
		})
	}

	onSelectFlag(countryCode) {
		localStorage.setItem('linguagem', JSON.stringify(countryCode));
		window.location.reload()
	}

	render() {
		/*let fazendaAtual = JSON.parse(localStorage.getItem('fazendaId'));
		let safraId = JSON.parse(localStorage.getItem('safraId'));*/
		return (
			<>
                {/*
				<SideNav
					expanded={this.state.drawer}
					onToggle={() => {
						this.isActive();
					}}
				>
					<Toggle>
						{!this.state.drawer ? (
							<i className="fas fa-bars menu-icon"></i>
						) : (
								<div style={{ width: 110 }}>
									<span className="toggle-icon-font">
										<i className="fas fa-arrow-left menu-icon"></i>{" "}
										&nbsp; {'Menu'}
									</span>
								</div>
							)}
					</Toggle>
					{"condição do if aqui"}
				</SideNav>
                */}

				<nav className="navbar navbar-expand-md col-sm-12 navbar-custom">
					<div className="navbar-border">
						<a className="navbar-brand" href="#/">
							<img
								src={logo}
								alt="Logo"
								className="logo"
								width={80}
							/>
						</a>
					</div>
					{/*<ul className="navbar-nav">
						<li className="nav-item dropdown" onClick={this.getFazendas.bind(this)}>
							<DropdownCustom
								onChange={this.onChange}
								array={this.state.fazendas}
								value={fazendaAtual ? fazendaAtual : null}
								label="Selecionar Fazenda"
								onClick={() => this.getFazendas.bind(this)}
								hidden={true}
							></DropdownCustom>
						</li>

						<li className="nav-item dropdown">
							<div onClick={this.getSafras.bind(this)}>
								<DropdownCustom
									onChange={this.onChangeSafra}
									array={this.state.safras}
									value={safraId}
									onClick={this.getSafras.bind(this)}
									label="Selecionar Safra"
									hidden={fazendaAtual === null ? false : true}
								></DropdownCustom>
							</div>
                        </li>
					</ul>*/}

					<ul className="navbar-nav ml-auto">
						{/*<li className="collapsed nav-item dropdown dropdown-menu-right">
							<div style={{
								marginTop: '15px',
								marginRight: '20px'
							}}>
								<ReactFlagsSelect
									alignOptions="left"
									countries={["US", "BR", "ES"]}
									defaultCountry={linguagem ? linguagem : 'BR'}
									showSelectedLabel={false}
									onSelect={this.onSelectFlag} />
							</div>
						</li>*/}
						<li className="collapsed nav-item dropdown dropdown-menu-right">
							<span
								className="nav-link dropdown-toggle"
								id="navbardrop"
								data-toggle="dropdown"
								style={{ textDecoration: "none" }}
								href="#/"
							>
								<a
									className="navbar-brand"
									href="#/"
									style={{ textDecoration: "none" }}
								>
									<img
										src={profileEmpty}
										alt="Logo"
										width={25}
										height={25}
									/>
									<span className="user-info">
										{this.state.userName}
									</span>
								</a>
							</span>
							<div className="dropdown-menu dropdown-menu-right">
								<a
									className="dropdown-item dropdown-custom"
									href="#/"
									style={{ fontSize: 10 }}
								>
									SgAgro
								</a>
								<a
									className="dropdown-item dropdown-custom"
									href="#/login"
									onClick={this.logout}
									style={{ fontSize: 14 }}
								>
									{Traducoes.sair()}
								</a>
							</div>
						</li>
					</ul>

				</nav>
			</>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.loading,
	permissoes: state.permissao.permissoes
});


const mapDispatchToProps = dispatch => bindActionCreators(ModulosCreators, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MenuPerfil);
