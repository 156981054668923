import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Title from "components/Title/title.component";

class RelatoriosMaMoIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			relatorios: [
				{ id: "mo", icone: "", nome: "Apontamentos de Mão de Obra", descricao: "" },
				{ id: "ma", icone: "", nome: "Apontamentos de Máquina", descricao: "" }
			]
		}
	}

	render() {
		return (
			<div className="container-fluid">
				<Title>
					Relatórios dos Apontamentos
				</Title>
				<div className="row">
					{
						this.state.relatorios.map(item =>
							<div className="col-md-3">
								<a href={`#/apontamento/relatorios/${item.id}`} className="title-report-a">
									<div className="card-report">
										<div className="container-report">
											<div className="text-center">
												<i className={"fa-report " + item.icone}></i>
												<div className="title-report">{item.nome}</div>
												<div className="description-report">{item.descricao}</div>
											</div>
										</div>
									</div>
								</a>
							</div>
						)
					}

				</div>


			</div>
		);
	}
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withRouter(RelatoriosMaMoIndex));
