import React, { useState, useEffect } from 'react';
import ObjectHelper from 'utils/objectHelper';
import { PERMISSAO } from '../../permissoes/permissoes';
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import Table from '../../components/Table/table.component';
import Title from '../../components/Title/title.component';
import SimpleSearch from '../../components/SimpleSearch/simplesSearch';
import agendamentoService from 'services/agendamentoService';
import ToastHelper from "../../utils/toastHelper";
import Utils from '../../utils/utils';
import Modal from '../../utils/modalHelper';

export default function AgendamentoListagem(props) {

	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [items, setItems] = useState();
	const [orderColumn, setOrderColumn] = useState()
	const [descending, setDescending] = useState(true)
	const [excluirItem, setExcluirItem] = useState({ id: null, showModal: false })

	const columns = [
		{ id: 0, title: 'Agenda', property: 'agenda' },
		{ id: 1, title: 'Data Início', property: 'dataInicio' },
		{ id: 2, title: 'Data Fim', property: 'dataFim' },
		{ id: 3, title: <i className="fas fa-user-clock icon-in-head-table"> (h)</i>, property: 'horasMo' },
		{ id: 4, title: <i className="fas fa-tractor icon-in-head-table"> (h)</i>, property: 'horasMa' },
		{ id: 5, title: 'Atividades', property: 'atividades' },
		//{ id: 6, title: 'Retrospectiva', property: 'retrospectiva'}
	];

	const paginateParams = {
		page: currentPage,
		pageSize: 10,
		descedescending: false,
		order: 'Agenda',
		filtro: '',
		fazendaId: JSON.parse(localStorage.getItem('fazendaId')).value,
		safraId: ObjectHelper.primeiraSafra()
	}

	const [pagination, setPagination] = useState(paginateParams)

	const actions = [
		{
			id: 2,
			name: 'Editar',
			icon: 'fas fa-edit',
			permissao: PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_ATUALIZAR,
			action: item => editar(item),
		},
		{
			id: 3,
			name: 'Apagar',
			icon: 'fas fa-trash-alt',
			permissao: PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_EXCLUIR,
			action: item => handleModalExcluir(item),
		}
	];



	const handleModalExcluir = (item) => {
		if (item) {
			setExcluirItem({ id: item.id, showModal: !excluirItem.showModal })
		} else {
			setExcluirItem({ id: null, showModal: !excluirItem.showModal })
		}
	}

	const editar = (item) => {
		props.history.push({
			pathname: `/planejamento/agendamento/editar/${item.id}`
		});
	}

	useEffect(() => {
		async function initialize() {
			getAgendamentos(pagination)
		}
		initialize();
	}, []);

	const getAgendamentos = (pag) => {
		async function getAllAgendamentos(pag) {
			const { data } = await agendamentoService.buscarTudo(pag)

			if (data.isOk) {

				await data.result.items.forEach(item => {
					if (item.ativo) {
						item.isAtivo = <i className="far fa-check-circle ativo-check"></i>
					} else {
						item.isAtivo = <i className="far fa-times-circle inativo-check"></i>
					}
				});

				await setItems(data.result.items);
				await setTotal(data.result.total);
				await setPage(data.result.page);
			}
		}
		getAllAgendamentos(pag);
	}

	const handleFilter = async (content) => {
		let newPagination = pagination;
		newPagination.filtro = content.toLowerCase();
		newPagination.page = 1;
		setPagination(newPagination);
		getAgendamentos(newPagination)
	}

	const changePage = (params) => {
		setCurrentPage(params);
		let newPagination = pagination;
		newPagination.page = params;
		setPagination(newPagination);
		getAgendamentos(newPagination)
	}

	const orderTable = (property, descending) => {
		setOrderColumn(Utils.parsePropertToFilter(property));
		setDescending(descending);
		let params = pagination;
		params.order = Utils.parsePropertToFilter(property);
		params.descedescending = descending;
		getAgendamentos(params)
	}

	const apagarAgendamento = async () => {
		const { data } = await agendamentoService.deletar(excluirItem.id);
		if (data.isOk) {
			ToastHelper.success('Agendamento excluído com sucesso!');
		}
		else
			ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");

		await setTimeout(function () {
			getAgendamentos(pagination)
		}, 800)
		setExcluirItem({ showModal: false })
	}

	return (
		<>
			<Title
				acao="Adicionar Agendamento"
				permissaoBotao={PERMISSAO.MODULO_PLANEJAMENTO_SAFRA_CRIAR}
				iconBotao="fas fa-plus"
				onClick={() => props.history.push(`/planejamento/agendamento/cadastro`)}>
				Agendamentos
            </Title>
			<CardStyle className="card">
				<SimpleSearch
					placeHolder="Pesquisar"
					value={pagination.filtro}
					submit={(e) => handleFilter(e)}
				/>
				<br></br>
				<Table
					align='left'
					showPaginate={total > 10}
					totalElements={total}
					totalPages={page}
					columns={columns}
					actions={actions}
					data={items}
					currentPage={currentPage}
					onChangePage={page => changePage(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={columns ? columns.length + 1 : 1}
					orderColumn={orderColumn}
					descending={descending}
					orderFunction={orderTable}
					ordenation={true}
				/>
			</CardStyle>
			<Modal
				show={excluirItem.showModal}
				doubleButtons={true}
				title={"Excluir Agendamento"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => setExcluirItem({ id: excluirItem.id, showModal: false })}
				submit={apagarAgendamento}
				close={() => setExcluirItem({ id: excluirItem.id, showModal: false })}
				content={
					<h6>{"Deseja realmente excluir esse agendamento?"}</h6>
				}
			/>
		</>
	)
}
