import React, { useState, useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt";
import DateHelper from "../../utils/dateHelper";
import Title from "../../components/Title/title.component";
import { useForm } from "react-hook-form";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import { Creators as ModulosCreators } from "./store/ordem_servico.ducks";
import SelectMulti from "../../components/SelectMulti/selectmulti.component";
import Utils from "../../utils/utils";
import TabelaRecursos from "../../components/TabelaRecursos/tabelaRecursos.component";
import OrdemServicoService from "services/ordemServicoService";
import ModalAtualizarRecomendacaoTecnica from '../../utils/modalHelper';
import ModalMensagemRelatorio from '../../utils/modalHelper';
import imagemAtualizarRecomendacoesTecnicas from './atualizar-recomendacoes-tecnicas.png';
import ObjectHelper from "../../utils/objectHelper";

const editorConfiguration = {
	toolbar: [
		"heading",
		"|",
		"bold",
		"italic",
		"link",
		"bulletedList",
		"numberedList",
		"blockQuote"
	]
};

function OrdemServicoCadastro(props) {
	const { handleSubmit, register, setValue, errors } = useForm({});
	const {
		match: { params }
	} = props;

	const [codigo, setCodigo] = useState("");
	const [dataInicio, setDataInicio] = useState();
	const [dataFim, setDataFim] = useState();
	const [locais, setLocais] = useState();
	const [EPIs, setEPIs] = useState();
	const [procedimento, setProcedimento] = useState();
	const [observacoes, setObservacoes] = useState();
	const [insumos, setInsumos] = useState([]);
	const [utilizaPorcentagem, setUtilizaPorcentagem] = useState(null);
	const [modalAtualizarRecomendacaoTecnica, setModalAtualizarRecomendacaoTecnica] = useState(false);
	const [modalMensagemRelatorio, setModalMensagemRelatorio] = useState(false);

	useEffect(() => {
		if (params.id !== undefined) {
			getOrdemServicoById(params.id);
			props.getEpis();
		}
	}, []);

	const getOrdemServicoById = async id => {
		const { data } = await OrdemServicoService.buscarPorId(id);

		let ordemServico = data.result;
		setarUnidadesDeInsumos(ordemServico.insumos)
		setValue("codigo", ordemServico.codigo);
		setValue("servico", ordemServico.servico.nome);
		setValue("areaTotal", ordemServico.areaTotal);

		setUtilizaPorcentagem(ordemServico.servico.utilizaPorcentagem);
		setCodigo(ordemServico.codigo);
		setProcedimento(ordemServico.procedimento);
		setObservacoes(ordemServico.observacoes);
		setInsumos(ordemServico.insumos);
		setLocais(ordemServico.locais);
		setEPIs(ordemServico.epis);

		if (ordemServico.dataInicio) {
			const dataInicio = new Date(
				DateHelper.createDate(ordemServico.dataInicio)
			);
			setValue("dataInicio", dataInicio);
			setDataInicio(dataInicio);
		}

		if (ordemServico.dataInicio) {
			const dataFim = new Date(
				DateHelper.createDate(ordemServico.dataFim)
			);
			setValue("dataFim", dataFim);
			setDataFim(dataFim);
		}
	};

	const setarUnidadesDeInsumos = (recursos) => {

		recursos.map(recurso => {
			if (recurso !== undefined) {
				let units = ObjectHelper.parseValuesUnitsToSelect(
					recurso.unidade
				);
				return recurso.unidades = units;
			} else {
				return recurso.unidades = null;
			}
		})

	}

	const createRelatorio = async (modelItem) => {
		await OrdemServicoService.editar(modelItem);

		const listaDeReceitas = insumos.filter(item => item.recurso.tipo.codigo === "RE");
		const listaDeInsumos = insumos.filter(item => item.recurso.tipo.codigo === "IN");

		if ((listaDeReceitas.length > 0 && listaDeInsumos.length > 0)
			|| (listaDeReceitas.length > 0 && listaDeReceitas.map(item => item.recurso.id).filter((value, index, self) => self.indexOf(value) === index).length > 1)
			|| (listaDeInsumos.length > 0 && listaDeInsumos.map(item => item.recurso.id).filter((value, index, self) => self.indexOf(value) === index).length > 1)) {
			setModalMensagemRelatorio(true);
		} else {
			let response = await OrdemServicoService.relatorio(params.id);
			var data = new Blob([response.data], {
				type:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			});
			const url = window.URL.createObjectURL(data);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `relatorio-${codigo}.xlsx`);
			document.body.appendChild(link);
			link.click();
		}
	};

	const handleGerarRelatorioClick = useCallback(async data => {
		getModelItemFromData(data).then(modelItem => {
			createRelatorio(modelItem);
		});
	});

	const addInsumo = useCallback(async item => {
		setInsumos([...insumos, item]);
	});

	const editInsumo = useCallback(async item => {
		setInsumos([...item]);
	});

	const deleteInsumo = useCallback(async item => {
		setInsumos([...item]);
	});

	const getModelItemFromData = useCallback(async data => {
		const modelItem = {
			Id: params.id,
			dataInicio: data.dataInicio,
			dataFim: data.dataFim,
			areaTotal: data.areaTotal,
			insumos: insumos.map(item => {
				return {
					id: item.id,
					localId: item.local.id,
					produtoId: item.recurso.id,
					unidadeId: item.unidade.id,
					quantidade: item.quantidadeTotal,
					porcentagem: item.porcentagem
				};
			}),
			epis: EPIs.map(item => {
				return { id: item.id };
			}),
			procedimento: procedimento,
			observacoes: observacoes
		};

		return modelItem;
	});

	const salvar = useCallback(async data => {
		getModelItemFromData(data).then(modelItem => {
			props.updateOrdemServico(modelItem, props.history);
			window.scrollTo(0, 0);
		});
		if (params.source === 'recomendacaoTecnica') {
			setModalAtualizarRecomendacaoTecnica(true);
		}
	});

	const cancelar = useCallback(async () => {
		props.history.goBack();
		window.close();
	});

	return (
		<>
			<form onSubmit={handleSubmit(salvar)}>
				<div className="row">
					<div className="col-md-6">
						<Title>Ordem de Serviço</Title>
					</div>
					<div className="col-md-6">
						<button
							type="button"
							className="btn btn-primary active pull-right"
							onClick={handleSubmit(handleGerarRelatorioClick)}
						>
							<i className="fas fa-download" /> Baixar Relatório
						</button>
					</div>
				</div>
				<CardStyle className="card mb-0 pb-0">
					<div className="row col-md-12 flex mt-4">
						<div className="col-md-4">
							<label className="label">Ordem de Serviço:*</label>
							{errors.nome && (
								<span className="span-errors">
									{" "}
									Campo Obrigatório
								</span>
							)}
							<input
								id="codigo"
								name="codigo"
								readOnly={true}
								className="form-control"
								ref={register({ required: true })}
							/>
							{errors.codigo && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="col-md-4">
							<label className="label">Data de início:*</label>
							<DatePicker
								id="dataInicio"
								name="dataInicio"
								selected={dataInicio}
								isClearable={false}
								placeholderText="Informe a data inicial"
								className="form-control bmd-form-group"
								dateFormat="dd/MM/yyyy"
								autoComplete="off"
								readOnly={true}
								ref={register({
									name: "dataInicio",
									required: true
								})}
								onChange={data => {
									setValue("dataInicio", data);
									setDataInicio(data);
								}}
								value={dataInicio}
								locale={pt}
							/>
							{errors.inicio && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="col-md-4">
							<label className="label">Data de fim:*</label>
							<DatePicker
								id="dataFim"
								name="dataFim"
								selected={dataFim}
								isClearable={false}
								placeholderText="Informe a data final"
								className="form-control bmd-form-group"
								dateFormat="dd/MM/yyyy"
								ref={register({
									name: "dataFim",
									required: true
								})}
								autoComplete="off"
								readOnly={true}
								onChange={data => {
									setValue("dataFim", data);
									setDataFim(data);
								}}
								value={dataFim}
								locale={pt}
							/>
							{errors.fim && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
					</div>
					<div className="row col-md-12 flex mt-4">
						<div className="col-md-4">
							<label className="label">Serviço:*</label>
							<input
								id="servico"
								name="servico"
								className="form-control"
								readOnly={true}
								ref={register({ required: true })}
							/>
							{errors.servico && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="col-md-8">
							<label className="label">EPIs:*</label>
							<SelectMulti
								id="EPI"
								name="EPI"
								uniqueKey="value"
								label="label"
								placeholder="Selecione os EPIs"
								className="select-style ajust-label-padrao"
								options={
									props.EPIs
										? Utils.getValuesParseToSelect(
											props.EPIs
										)
										: []
								}
								value={
									EPIs
										? Utils.getValuesParseToSelect(EPIs)
										: []
								}
								isMulti={true}
								theme={theme => ({ ...theme, borderRadius: 0 })}
								onChange={itens => {
									setEPIs(
										(itens ? itens : []).map(item => {
											return {
												id: item.key,
												nome: item.label
											};
										})
									);
								}}
							/>
							{errors.EPIs && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
					</div>
					<div className="row col-md-12 flex mt-4">
						<div className="col-md-10">
							<label className="label">Locais:*</label>
							<SelectMulti
								id="locais"
								name="locais"
								className="select-style ajust-label-padrao disabled"
								disabled={true}
								options={[]}
								value={Utils.getValuesParseToSelect(locais)}
								isMulti={true}
								theme={theme => ({ ...theme, borderRadius: 0 })}
							/>
							{errors.locais && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
						<div className="col-md-2">
							<label className="label">Área Total (ha):*</label>
							<input
								type="number"
								id="areaTotal"
								name="areaTotal"
								readOnly={true}
								className="form-control mt-2"
								autoComplete="off"
								onChange={item => {
									setValue("areaTotal", item);
								}}
								ref={register({ required: true })}
							/>
							{errors.areaTotal && (
								<span className="span-errors">
									Campo obrigatório
								</span>
							)}
						</div>
					</div>
					<div className="row col-md-12 flex mt-4">
						<div className="card-header col-md-12">
							<TabelaRecursos
								titulo="Insumos"
								recursos={insumos}
								locais={locais}
								opcoesRecursosComCodigo={["IN", "RE"]}
								removerClasses={["EPI"]}
								utilizaPorcentagem={utilizaPorcentagem}
								onAdd={addInsumo}
								onEdit={editInsumo}
								onDelete={deleteInsumo}
							/>
						</div>
					</div>
					<div className="row col-md-12 flex mt-4">
						<div className="col-md-12">
							<label className="label">
								Descrição do procedimento:
							</label>
							<CKEditor
								activeClass="editor"
								editor={ClassicEditor}
								config={editorConfiguration}
								data={procedimento}
								onChange={(event, editor) => {
									const data = editor.getData();
									setProcedimento(data);
								}}
							/>
						</div>
					</div>
					<div className="row col-md-12 flex mt-4 mb-5">
						<div className="col-md-12">
							<label className="label">Observações:</label>
							<CKEditor
								activeClass="editor"
								editor={ClassicEditor}
								config={editorConfiguration}
								data={observacoes}
								onChange={(event, editor) => {
									const data = editor.getData();
									setObservacoes(data);
								}}
							/>
						</div>
					</div>
				</CardStyle>
				<ModalAtualizarRecomendacaoTecnica
					show={modalAtualizarRecomendacaoTecnica}
					doubleButtons={false}
					title={"Atualizar Recomendações Técnicas"}
					buttonTitle={"OK"}
					submit={() => {
						setModalAtualizarRecomendacaoTecnica(false);
						window.close();
					}}
					close={() => {
						setModalAtualizarRecomendacaoTecnica(false);
						window.close();
					}}
					content={
						<div>
							<h6>{"É necessário atualizar a tabela de recomendações técnicas para que essas alterações sejam visualizadas"}</h6>
							<img src={imagemAtualizarRecomendacoesTecnicas} alt="Imagem" />
						</div>
					}
				/>
				<ModalMensagemRelatorio
					show={modalMensagemRelatorio}
					doubleButtons={false}
					title={"Informação"}
					buttonTitle={"OK"}
					submit={() => setModalMensagemRelatorio(false)}
					close={() => setModalMensagemRelatorio(false)}
					content={
						<div>
							<h6>{"Não é possível gerar relatórios de ordens de serviço que contenham insumos e receitas ou de ordens de serviço com mais de um tipo de insumo ou receita."}</h6>
						</div>
					}
				/>
				<div className="col-md-12 mt-3">
					<button className="btn btn-primary active pull-right" type="submit">
						<i className='fas fa-check' /> Salvar
                	</button>
					<button className="btn btn-outline-secondary pull-left" type="button" onClick={cancelar}>
						<i className='fas fa-arrow-left' /> Voltar
					</button>

				</div>
			</form>
		</>
	);
}

const mapStateToProps = state => ({
	...state,
	EPIs: state.ordemServico.EPIs
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(ModulosCreators, dispatch);
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrdemServicoCadastro);
