import React, { useState, useEffect } from 'react';
import {
	BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import Select from 'react-select';

export default function HistogramaGrafico(props) {

	const [array, setArray] = useState([])
	const [chart, setChart] = useState("Apontamentos de Mão de Obra")

	useEffect(() => {
		if (props.data !== undefined) {
			if (props.data.length > 0)
				setArray(props.data)
		}
	}, [props.data]);


	return (
		<>

			<div className="card-header">
				{chart}
			</div>
			<div className="col-md-8">
				<Select
					placeholder='Selecione...'
					className="select-style ajust-label-padrao disabled-select"
					onChange={item => { props.setChart(item); setChart(item.label) }}
					options={[
						{
							label: "Apontamentos de Mão de Obra",
							value: "MO"
						},
						{
							label: "Apontamentos de Máquina",
							value: "MA"
						},
					]}
					// value={selectedRecursos}
					theme={theme => ({ ...theme, borderRadius: 0 })}
				/>

			</div>
			<br></br>
			<div className="card">
				<BarChart
					width={(window.innerWidth / 2) - 100}
					height={319}
					data={array || []}
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="servico" />
					<YAxis width={10} yAxisId="left" orientation="left" stroke="#8884d8" />
					<YAxis width={10} yAxisId="right" orientation="right" stroke="#82ca9d" />
					<Tooltip content={<CustomTooltip />} />
					<br></br>
					{
						array.length > 0 &&
						<Legend
							payload={
								[
									{ id: 'realizado', value: `Horas trabalhadas`, type: 'square', color: '#82ca9d' },
									{ id: 'previsto', value: `Horas previstas`, type: 'square', color: '#8884d8' },
									// { id: 'quantidade', value: `Quantidade de apontamentos`, type: 'square', color: '#82ca9d' },
								]
							}
						/>
					}
					<Bar barSize={20} yAxisId="left" dataKey="previsto" fill="#8884d8" />
					<Bar barSize={20} yAxisId="right" dataKey="realizado" fill="#82ca9d" />
				</BarChart>
			</div>
			<br></br>
			<div >
				<a href="/#/apontamento"
					className="btn btn-primary btn-border float-left">
					Ver Apontamentos Mão de Obra
				</a>
				<a href="/#/apontamento/maquina"
					style={{ marginRight: 10 }}
					className="btn btn-primary btn-border float-right">
					Ver Apontamentos Máquina
				</a>
			</div>

		</>
	);
}


const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload) {
		return (
			<div className="custom-tooltip-rechart" >
				<p className="">{`Serviço:  ${payload[0].payload.servico}`}</p>
				<p>{`Quantidade de apontamentos: ${payload[0].payload.quantidade}`}</p>
				<p>{`Total de horas trabalhadas: ${payload[0].payload.realizado}`}</p>
				<p>{`Total de horas previstas: ${payload[0].payload.previsto}`}</p>
			</div>
		);
	}

	return null;
};

