import { all,  fork, put, takeLatest } from "redux-saga/effects";
import uploadService from "../../../../services/uploadService"
import {Types} from "./importacao.ducks";
import executeAction from "../../../../store/sagas/executeAction";


function* uploadTeplate(action) {
	yield executeAction(function* () {

		const { params, callback } = action;
		const { data } = yield  uploadService.upload(params);

		yield put({
			type: Types.UPLOAD_CSV,
			data: data
		});
		callback();
	}, Types.UPLOAD_CSV);
}


function* takeUploadTemplate() {
	yield takeLatest(Types.UPLOAD_CSV, uploadTeplate);
}

export default function* root() {
	yield all([fork(takeUploadTemplate)])
}
