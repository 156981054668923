import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	uploadCsv: ['params', 'history'],

	getServicos: ['params', 'callback'],
	setServicos: ['servicos'],
})

const INITIAL_STATE_VALUES = {
	id: [],
	servicos: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));
const getServicos = (state = INITIAL_STATE, action) => ({ ...state, servicos: action.data })

const uploadCsv = (state = INITIAL_STATE, action) => {
	return state.merge( action.params )
}

export default createReducer(INITIAL_STATE, {
	[Types.UPLOAD_CSV]: uploadCsv,
	[Types.SET_SERVICOS]: getServicos,

})
