import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Title from "components/Title/title.component";
import relatorioService from 'services/relatorioService';

class Relatorios extends Component {
	relatorios = [
		{ link: "#/apontamento/relatorios/mo", icone: "fas fa-user-plus", nome: "Apontamentos de Mão de Obra por Semana", descricao: "Lista todos os apontamento de mão de obra feitos durante a semana" },
		{ link: "#/apontamento/relatorios/ma", icone: "fas fa-tractor", nome: "Apontamentos de Máquina por Semana", descricao: "Lista todos os apontamento de mão de máquina feitos durante a semana" }
	];

	todosRelatorios = [];

	constructor(props) {
		super(props);
		this.state = {
			relatorios: [],
		}
	}

	async buscar() {
		const { data } = await relatorioService.buscarTodos();

		if (data.result && Array.isArray(data.result)) {
			data.result.forEach(element => {
				this.relatorios.push({
					link: `#/relatorios/${element.id}`,
					icone: element.icone,
					nome: element.nome,
					descricao: element.descricao
				});
			});
		}

		this.setState({
			relatorios: this.relatorios
		});

		this.todosRelatorios = [...this.relatorios];
	}

	async componentDidMount() {
		this.buscar();
	}

	setSearch(value) {
		value = value.toLowerCase();
		let relatoriosFiltrados = [...this.relatorios].filter(
			item => item.nome.toLowerCase().includes(value)
					|| item.descricao.toLowerCase().includes(value)
		);

		this.setState({
			relatorios: relatoriosFiltrados
		});
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="card-body">
                    <div className="row">
						<div className="col-md-12">			
							<Title>
								Relatórios
							</Title>
						</div>
					</div>
					<div className="row">
                        <div className="col-md-6">
                            <div className="input-group md-form form-sm form-2 pl-0">
                                <input
                                    className="form-control my-0 py-1"
                                    placeholder={'Pesquisar'}
                                    autoComplete="off"
                                    onChange={(e) => this.setSearch(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <button className="input-group-text red lighten-3" id="basic-text1" >
                                        <i className="fas fa-search icon-color-green"
                                        aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				<div className="card-body">
					<div className="row">
					{
						this.state.relatorios.map(item =>
							<div className=".col-sm-12 col-md-4 col-lg-3" style={{ marginBottom: '20px' }}>
								<a href={item.link} className="card-report title-report-a">
									<div className="container-report">
										<div className="text-center">
											<i className={"fa-report " + item.icone}></i>
											<div className="title-report">{item.nome}</div>
											<div className="description-report">{item.descricao}</div>
										</div>
									</div>
								</a>
							</div>
						)
					}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withRouter(Relatorios));
