import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import UnitService from "../../../services/unidadeService"
import {Types} from "./units.ducks";
import executeAction from "../../../store/sagas/executeAction";
import ToastHelper from "../../../utils/toastHelper";

const parametros = {
	fazendaId: JSON.parse(localStorage.getItem('fazendaId')) !== null ? JSON.parse(localStorage.getItem('fazendaId')).value : null,
	page: 1,
	pageSize: 10,
	filtro:""
}

function* getUnitById(action) {
	yield executeAction(function* () {

		const { params, setUnitsConversions, callback } = action;
		const { data } = yield call( async () => await UnitService.buscarPorId(params));
		if(data.unidadeConversao ? data.unidadeConversao.length > 0 : false) {
			let newUnits = []
			data.unidadeConversao.forEach(unidade => {
				newUnits.push(
					{
						unidadeConvertidaId: unidade.unidadeConvertida.id,
						sigla: unidade.unidadeConvertida.sigla,
						valor: parseFloat(unidade.valor)
					}
				)
			});
			setUnitsConversions(newUnits)
		}
		yield put({
			type: Types.SET_GET_UNIT_BY_ID,
			data: data
		});
		callback();
	}, Types.GET_UNIT_BY_ID);
}

function* asyncListUnits(action) {
	yield executeAction(function* () {
		const {params, callback } = action;
        const { data } = yield call( async () => await UnitService.getAll(params || parametros));
		yield put({ type: Types.READ_UNITS, data: data.result });
		callback();
	}, Types.READ_UNITS);
}

function* asyncCreateUnit(action){
	yield executeAction(function* () {

        const { params, history, callback } = action;
        let newParams = {
            fazendaId: parametros.fazendaId,
            sigla: params.abbreviation,
            descricao: params.description,
            unidadeConversao: params.unitsConversion,
        }
		const { data } = yield call( async () => await UnitService.salvar(newParams));

		if(data.isOk){
			ToastHelper.success('Unidade criada com sucesso!');
			history.goBack()
		} else 
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({ type: Types.POST_UNIT, data: data });
		callback();
	}, Types.CREATE_UNIT);
}

function* updateAsync(action){
	yield executeAction(function* () {

		const { params, history} = action;
        let newParams = {
            fazendaId: parametros.fazendaId,
            sigla: params.abbreviation,
			descricao: params.description,
			unidadeConversao: params.unitsConversion,
            id: params.id
        }
		const { data } = yield call( async () => await UnitService.editar(newParams));
		if(data.isOk){
			ToastHelper.success('Unidade atualizada com sucesso!')
			history.goBack()
		} else 
			ToastHelper.error("Ocorreu um erro. " + (data.exception ? data.exception.Message : data.mensagem));
		yield put({
			type: Types.UPDATE_UNIT,
			data
		});
	}, Types.UPDATE_UNIT);
}

function* deleteUnit(action) {
	yield executeAction(function* () {

		const { params, props, callback } = action;
		const { data } = yield call( async () => await UnitService.deletar(params));
        if(data.isOk) {
            ToastHelper.success('Unidade excluída com sucesso!');
            props.getUnits(parametros)
        }
		else
			ToastHelper.error(data.mensagem ? data.mensagem : "Ocorreu um erro.");
		callback();
	}, Types.DELETE_UNIT);
}

function* updateOptionsUnits(action) {
	yield executeAction(function* () {
		const { optionsUnits, setOptionsUnits, addUnits, callback} = action;
        addUnits.forEach(addUnit => {
			optionsUnits.forEach(unidade => {
                if(unidade.value === addUnit.unidadeConvertidaId) {
					optionsUnits.splice(optionsUnits.indexOf(unidade), 1)
					setOptionsUnits(optionsUnits)
                }
            })
		});
		callback();
		yield put({
			type: Types.UPDATE_OPTIONS_UNITS,
			data: ''
		})
	}, Types.UPDATE_OPTIONS_UNITS);
}

function* takeUpdateUnit() {
	yield takeLatest(Types.UPDATE_UNIT, updateAsync);
}

function* takeGetUnitById() {
	yield takeLatest(Types.GET_UNIT_BY_ID, getUnitById);
}

function* takeReadUnits() {
	yield takeLatest(Types.GET_UNITS, asyncListUnits);
}

function* takeCreateUnit() {
	yield takeLatest(Types.CREATE_UNIT, asyncCreateUnit);
}

function* takeDeleteUnit() {
	yield takeLatest([Types.DELETE_UNIT], deleteUnit);
}

function* takeUpdateOptionsUnits() {
	yield takeLatest([Types.UPDATE_OPTIONS_UNITS], updateOptionsUnits);
}

export default function* root() {
	yield all([fork(takeGetUnitById)])
	yield all([fork(takeReadUnits)])
	yield all([fork(takeCreateUnit)])
	yield all([fork(takeUpdateUnit)])
	yield all([fork(takeDeleteUnit)])
	yield all([fork(takeUpdateOptionsUnits)])
}
