import api from './api';

export default {
	buscarPorId(id) {
		return api.get('/api/ordemservico/' + id);
	},

	editar(item){
		return api.put('/api/ordemservico/', item);
	},

	mesclar(ordensServico){
		return api.post('/api/ordemservico/MesclarOrdensServico/', {
			OrdensServicoIds: ordensServico
		});
	},

	create(planejamentoId){
		return api.post('/api/ordemservico/GerarOrdemServico/', { PlanejamentoId: planejamentoId });
	},
	
	relatorio(id){
		return api.get('/api/ordemservico/relatorio/' + id, { responseType: 'blob' });
	}
};
