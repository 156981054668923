import Modal from 'react-bootstrap/es/Modal';
import React, { useState } from 'react';
import SafraRequest from '../safra.requests'
import ToastHelper from 'utils/toastHelper';

const FAZENDA = JSON.parse(localStorage.getItem('fazendaId'));
export default function ModalDesativar(props) {
	const { show, close, addGrupoSafra } = props;

	const [nome, setNome] = useState("");
	const [errors, setError] = useState(false);

	const salvar = async () => {
		if (nome.length === 0) return setError(true);
		let data = await SafraRequest.salvarGrupoSafra({ nome: nome, fazendaId: FAZENDA.value })
		if (data.isOk)
			return addGrupoSafra()
		else
			return ToastHelper.error(data.mensagem)
	}

	return (
		<>
			<Modal dialogClassName="modal-dialog-md" show={show} onHide={() => { close(); }}>
				<Modal.Body className="modal-body">
					<h3 className='mt-3'>Cadastro de Safra</h3>
					<div className="col-md-12 card-header">
						<label className="label">Nome:*</label>
						<input
							type='text'
							className="form-control"
							autoComplete="off"
							name="nome"
							onChange={e => { setNome(e.target.value); setError(false) }}
						/>
						{errors && <span className="span-errors">Campo Obrigatório.</span>}
					</div>
				</Modal.Body>
				<Modal.Footer className="footer-modal">
					<div className="row col-md-12">
						<div className="col-md-6">
							<button className="btn btn-outline-secondary pull-left" type="button" onClick={() => close()}>
								<i className='fas fa-close' /> Cancelar
                                </button>
						</div>
						<div className="col-md-6">
							<button className="btn btn-primary active pull-right" onClick={salvar}>
								<i className='fas fa-check' /> Salvar
                                </button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

