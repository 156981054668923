import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";

export const {Types, Creators} = createActions({
	create: ['params'],
	getById: ['id', 'callback'],
	setarGetById: ['unidade'],
	update: ['params'],
})


const INITIAL_STATE_VALUES = {
	id: []
};

const INITIAL_STATE = Immutable(Object.assign({}, INITIAL_STATE_VALUES));

const getById = (state = INITIAL_STATE, action) => state.merge( action.data )
const create = (state = INITIAL_STATE, action) => state.merge(action.params)
const update = (state = INITIAL_STATE, action) => state.merge(action.params)


export default createReducer(INITIAL_STATE, {
	[Types.SETAR_GET_BY_ID]: getById,
	[Types.CREATE]: create,
	[Types.UPDATE]: update
})
