import React from "react";
import { CardStyle } from "../../components/CardStyle/cardstyle.component";
import { PERMISSAO } from "../../permissoes/permissoes";
import Title from "../../components/Title/title.component";
import Table from "../../components/Table/table.component";
import ToastHelper from "../../utils/toastHelper";
import Modal from "../../utils/modalHelper";
import RecursosService from "../../services/recursosService";
import Can from "../../permissoes/Can";
import Utils from "../../utils/utils";
import AdvancedSearch from "../../components/AdvancedSearch/advancedSearch";

const NUMERO_ITENS_POR_PAGINA = 10;

export default class RecursosListagem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			showModalExcluir: false,
			idUnidadeExcluir: "",
			showModalAtivaDesativa: false,
			idRecursoAtivaDesativa: "",
			filtro: "",
			orderColumn: "",
			orderTable: "",
			descending: "",
			data: {
				items: [],
				total: 0,
				pageCount: 0,
				page: 0,
				pageSize: 0
			}
		};
		this.history = this.props.history;
		this.handleModalExcluir = this.handleModalExcluir.bind(this);
	}

	paginate = {
		fazendaId: JSON.parse(localStorage.getItem("fazendaId")).value,
		page: 1,
		pageSize: 10,
		filtro: "",
		codigo: "",
		order: "Nome",
		nome: "",
		unidadeId: "",
		descedescending: false,
		recursoClasseId: "",
		recursosTipoId: "",
		custoPadrao: ""
	};

	async componentDidMount() {
		localStorage.removeItem("planejamentoParams");

		await this.buscar(this.paginate);
	}

	async ativaDesativa(item) {
		let dados = await RecursosService.ativaDesativa(item);

		if (dados.status === 200) {
			ToastHelper.success("Status do recurso alterado com sucesso!");
			await this.buscar(this.paginate);
			await this.handleModalAtivaDesativa(null);
		} else {
			ToastHelper.error("Erro ao tentar alterar o status drecurso!");
		}
	}

	async handleModalAtivaDesativa(item) {
		if (item) {
			await this.setState({
				...this.state,
				idRecursoAtivaDesativa: item.id,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			});
		} else {
			await this.setState({
				...this.state,
				showModalAtivaDesativa: !this.state.showModalAtivaDesativa
			});
		}
	}

	setOrderColumn = property => {
		this.setState({ ...this.state, orderColumn: property });
	};

	setDescending = descending => {
		this.setState({ ...this.state, descending: descending });
	};

	TIPO_CADASTRO = "recursos";

	columns = [
		{ id: 0, title: "Codigo", property: "codigo" },
		{ id: 1, title: "Nome", property: "nome" },
		{ id: 2, title: "Tipo", property: "recursosTipo" },
		{ id: 3, title: "Classe", property: "recursoClasse" },
		{ id: 4, title: "Unidade", property: "unidade.sigla" },
		{ id: 5, title: "Custo Padrão", property: "custoPadrao" },
		{ id: 6, title: "Ativo", property: "isAtivo" }
	];

	actions = [
		{
			id: 1,
			name: "Visualizar",
			icon: "fas fa-eye",
			permissao: PERMISSAO.MODULO_RECURSOS_LEITURA,
			action: item => this.visualizar(item)
		},
		{
			id: 2,
			name: "Editar",
			icon: "fas fa-edit",
			permissao: PERMISSAO.MODULO_RECURSOS_ATUALIZAR,
			action: item => this.editar(item)
		},
		{
			id: 3,
			name: "Ativa/Desat.",
			icon: "fas fa-low-vision circ-item-desativar",
			permissao: PERMISSAO.MODULO_RECURSOS_ATUALIZAR,
			action: item => this.handleModalAtivaDesativa(item)
		},
		{
			id: 4,
			name: "Excluir",
			icon: "fas fa-trash-alt",
			permissao: PERMISSAO.MODULO_RECURSOS_EXCLUIR,
			action: item => this.handleModalExcluir(item)
		}
	];

	async visualizar(item) {
		this.history.push(`/planejamento/recursos/visualizar/${item.id}`);
	}

	cadastrar = () => {
		this.history.push(`/planejamento/recursos/cadastro`);
	};

	async editar(item) {
		this.history.push({
			pathname: `/planejamento/recursos/editar/${item.id}`
		});
	}

	async apagar(item) {
		let data = await RecursosService.deletar(item);
		if (data.data.isOk) {
			ToastHelper.success("Recurso excluído com sucesso!");
			await this.buscar(this.paginate);
		} else {
			ToastHelper.error(data.data.mensagem);
		}
		await this.handleModalExcluir();
	}

	orderTable = (property, descending) => {
		let newOrder = Utils.parsePropertToFilter(property);
		this.setState({
			...this.state,
			orderColumn: Utils.parsePropertToFilter(property),
			orderTable: Utils.parsePropertToFilter(property),
			descending: descending
		});
		let params = this.paginate;
		if (property === "data") {
			property = "nome";
		}
		params.order = newOrder;
		params.descedescending = descending;
		this.buscar(params);
	};

	async handlePaginacao(page) {
		await this.setState({
			currentPage: page
		});
		let newPaginate = this.paginate;
		newPaginate.page = page;
		newPaginate.order =
			this.state.orderColumn !== "" ? this.state.orderColumn : "Nome";
		newPaginate.descedescending =
			this.state.descending === "" ? false : this.state.descending;
		await this.buscar(newPaginate);
	}

	async buscar(params) {
		if (params.order === "Data") {
			params.order = "Nome";
		}

		params.recursosTipoCodigo = ["MO", "MA"];

		let result = await RecursosService.buscarTodas(params);

		if (result.data.result) {
			await result.data.result.items.forEach(item => {
				if (item.ativo) {
					item.isAtivo = (
						<i className="far fa-check-circle ativo-check"></i>
					);
				} else {
					item.isAtivo = (
						<i className="far fa-times-circle inativo-check"></i>
					);
				}
			});

			this.setState({
				data: {
					...result.data.result
				}
			});
		}
	}

	table() {
		return (
			<>
				<hr></hr>
				<Table
					align="left"
					showPaginate={
						this.state.data.total > NUMERO_ITENS_POR_PAGINA
					}
					totalElements={this.state.data.total}
					totalPages={this.state.data.page}
					columns={this.columns}
					actions={this.actions}
					data={this.state.data.items}
					currentPage={this.state.currentPage}
					onChangePage={page => this.handlePaginacao(page)}
					emptyMessage="Nenhum item encontrado."
					emptyColSpan={this.columns ? this.columns.length + 1 : 1}
					orderColumn={this.state.orderColumn}
					descending={this.state.descending}
					orderFunction={this.orderTable}
					ordenation={true}
				/>
			</>
		);
	}

	async handleModalExcluir(item) {
		if (item) {
			this.setState({
				...this.state,
				ItemId: item.id,
				showModalExcluir: !this.state.showModalExcluir
			});
		} else {
			await this.setState({
				...this.state,
				showModalExcluir: !this.state.showModalExcluir
			});
		}
	}

	modalExcluir() {
		return (
			<Modal
				show={this.state.showModalExcluir}
				doubleButtons={true}
				title={"Excluir este Recurso"}
				buttonTitle={"Confirmar"}
				button2Title={"Cancelar"}
				buttom2Action={() => this.handleModalExcluir()}
				submit={() => this.apagar(this.state.ItemId)}
				close={() => this.handleModalExcluir()}
				content={<h6>{"Deseja realmente excluir essa unidade?"}</h6>}
			/>
		);
	}

	botaoCadastrar() {
		return (
			<Can
				perform={PERMISSAO.MODULO_RECURSOS_CRIAR}
				yes={() => (
					<button
						className="btn btn-primary btn-style active pull-right"
						onClick={this.cadastrar}
					>
						<i className="fas fa-plus" /> Novo Recurso
					</button>
				)}
			/>
		);
	}

	async onChangeFilter(filtro) {
		await this.setState({ filtro: filtro });
		await this.buscar(filtro);
	}

	campos() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6">
						<Title>Recurso</Title>
					</div>
					<div className="col-md-6 float-right">
						{this.botaoCadastrar()}
					</div>
				</div>
				<CardStyle className="card">
					<div className="card-header col-md-6">
						Recursos cadastrados
					</div>
					<AdvancedSearch
						handleFilter={e => this.onChangeFilter(e)}
						name={true}
						code={true}
						resourceType={true}
						resourceClass={true}
						showOnlyResourceTypes={["MA", "MO"]}
					/>
					<div className="row">
						<div className="col-md-12">{this.table()}</div>
					</div>
				</CardStyle>
				{this.modalExcluir()}
				<Modal
					show={this.state.showModalAtivaDesativa}
					title={"Ativar / Desativar recurso"}
					buttonTitle={"Confirmar"}
					button2Title={"Cancelar"}
					buttom2Action={() => this.handleModalAtivaDesativa(null)}
					submit={() =>
						this.ativaDesativa(this.state.idRecursoAtivaDesativa)
					}
					close={() => this.handleModalAtivaDesativa(null)}
					content={
						<h6>
							{"Deseja realmente alterar o status deste recurso?"}
						</h6>
					}
				/>
			</div>
		);
	}

	render() {
		return <>{this.campos()}</>;
	}
}
