import React, {Component} from 'react';
import '../modais/modal.scss'
import BootstrapTable from '../../../node_modules/react-bootstrap-table-next';
import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Traducoes from '../../utils/traducoes'
const { SearchBar } = Search;

export default class ModalMembros extends Component {
    constructor(props) {
		super(props)
        this.state = {
			membrosEquipe: this.props.membrosEquipe,
			table: {
				columns: [
					{
						dataField: this.props.mostrarHorasApontadas ? 'labelComHoras' : 'label',
						text: Traducoes.nomeFuncionario() + (this.props.mostrarHorasApontadas ? ' (horas apontadas no dia)' : ''),
						style: function callback(cell, row, rowIndex, colIndex) { 
							return row.horasApontadas && row.horasApontadas > 0 ? { fontWeight: 'bold'} : null;
						}
					}
				],
				selectRow: {
					mode: 'checkbox',
					clickToSelect: true,
					style: { backgroundColor: '#c8e6c9'},
					onSelect: this.handleOnSelect,
					onSelectAll: this.handleOnSelectAll,
					selected: this.props.membrosSelecionados
				}
			}
		}
	}

	handleOnSelect  = (row, isSelect) => {
		let membrosEquipe = this.props.membrosEquipe
		membrosEquipe.forEach(membro => {
			if(membro.value === row.value) {

				membro.isSelect = isSelect
			}
		});
		this.atualizar(membrosEquipe);
	}

	async atualizar(membrosEquipe) {
		await this.props.selectAndSubmitModalMembros(membrosEquipe);
		
		await this.setState({
			...this.state,
			membrosEquipe: membrosEquipe,
			table: {
				...this.state.table,
				selectRow: {
					...this.state.table.selectRow,
					selected: this.props.membrosSelecionados
				}
			}
		});
	}
	
	handleOnSelectAll = (isSelect, rows) => {

		let membrosEquipe = this.props.membrosEquipe
		rows.forEach(membroSelecionado => {

			membrosEquipe.forEach(membroDaEquipe => {
				if(membroSelecionado.value === membroDaEquipe.value) {
					membroDaEquipe.isSelect = isSelect
				}
			});
		});

		this.atualizar(membrosEquipe);
	}

    render() {
        return (
			<ToolkitProvider
				keyField="value"
				data={this.props.membrosEquipe}
				columns={ this.state.table.columns }
				search
				>
				{
					props => (
					<div>
						<SearchBar className="wd-100" placeholder={Traducoes.pesquisar()}
							 {...props.searchProps} />
						<hr />
						<BootstrapTable
							{...props.baseProps}
							keyField='value'
							data={this.props.membrosEquipe}
							columns={this.state.table.columns}
							selectRow={this.state.table.selectRow}
							striped 
						/>
					</div>
					)
				}
				</ToolkitProvider>
        )
    }
}