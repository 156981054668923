import { combineReducers } from "redux";
import loading from "./loading";
import permissao from "./permissao";
import unidade from "./unidade";
import planejamentoSafra from "pages/planejamento_safra/store/planejamento_safra.ducks";
import turnos from "pages/turnos/store/turno.ducks";
import geradorPlanejamentoSafra from "pages/gerador_planejamento_safra/store/gerador.ducks";
import advancedSearch from "components/AdvancedSearch/store/advancedSearch.ducks";
import appointmentMachines from "pages/apontamentos/ma/store/ma.ducks";
import appointmentManpower from "pages/apontamentos/mo/store/mo.ducks";
import appointmentEntrys from "pages/apontamentos/insumos/store/entry.appointment.ducks";
import units from "pages/units/store/units.ducks";
import histogramas from "pages/histograma/store/histograma.ducks";
import grupoMaquinas from "pages/grupo_maquinas/store/grupo_maquina.ducks";
import insumoAnaliseSolo from "pages/insumo_analise_solo/store/insumo_analise_solo.ducks";
import painel from "pages/painel/store/painel.ducks";
import painelMO from "pages/painelMO/store/painel.ducks";
import materials from "pages/materials/store/material.duks";
import afastamentos from "pages/afastamento/store/afastamento.ducks";
import importacao from "pages/planejamento_safra/importacao/store/importacao.ducks";
import painelServicos from "pages/painel_servicos/store/painel.servicos.duks";
import grupoLocais from "pages/grupo_locais/store/grupo.locais.ducks";
import agendamento from "pages/agendamento/store/agendamento.ducks";
import recomendacoesTecnicas from "./recomendacoes_tecnicas.ducks";
import ordemServico from "pages/ordem_servico/store/ordem_servico.ducks";
import { planejamentoSafraNovo } from "./planejamentoSafra";
import { apontamentoMo } from "./apontamentoMo";
import { apontamentoMa } from "./apontamentoMa";
import { apontamentoIn } from "./apontamentoIn";
import { ajusteApontamentoMo } from "./ajusteApontamentoMaoDeObra";
import { ajusteApontamentoMa } from "./ajusteApontamentoMaquina";
import { ajusteApontamento } from "./ajusteApontamento";
import { ajusteApontamentoInsumos } from "./ajusteApontamentoInsumos";

const reducers = combineReducers({
	importacao,
	painelMO,
	loading,
	permissao,
	unidade,
	planejamentoSafra,
	turnos,
	geradorPlanejamentoSafra,
	advancedSearch,
	appointmentMachines,
	appointmentManpower,
	appointmentEntrys,
	units,
	histogramas,
	grupoMaquinas,
	painel,
	materials,
	afastamentos,
	insumoAnaliseSolo,
	painelServicos,
	grupoLocais,
	agendamento,
	recomendacoesTecnicas,
	ordemServico,
	planejamentoSafraNovo,
	apontamentoMo,
	apontamentoMa,
	apontamentoIn,
	ajusteApontamentoMa,
	ajusteApontamentoMo,
	ajusteApontamento,
	ajusteApontamentoInsumos
});

export default reducers;
