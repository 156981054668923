import React, {Component} from 'react';
import '../modais/modal.scss'
import BootstrapTable from '../../../node_modules/react-bootstrap-table-next';
import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Traducoes from '../../utils/traducoes'
const { SearchBar } = Search;

export default class ModalMembros extends Component {

    constructor(props) {
		super(props)
        this.state = {
			servicoSelecionado: this.props.servicoSelecionado,
			table: {
				columns: [
					{
						dataField: 'label',
                        text: this.props.nomeColuna,
                    }
				],
				selectRow: {
					mode: 'radio',
					clickToSelect: true,
                    style: { backgroundColor: '#c8e6c9'},
                    onSelect: this.handleOnSelect
                }
            }
		}
	}
    
    handleOnSelect  = (row, isSelect) => {
        row.isSelect = isSelect;
        
        this.setState({
            ...this.state,
            servicoSelecionado: row
        })
        this.atualizar(row);
	}

	async atualizar(servico) {
        await this.props.selectAndSubmitModalServico(servico);
	}

    render() {
        return (

			<ToolkitProvider
				keyField="value"
				data={this.props.listaServicos}
                columns={ this.state.table.columns }
				search
				>
				{
					props => (
					<div>
						<SearchBar className="wd-100" placeholder={Traducoes.pesquisar()}
							 {...props.searchProps} />
						<hr />
						<BootstrapTable
                            {...props.baseProps}
                            options={ this.state.table.options}
							keyField='value'
							data={this.props.listaServicos}
							columns={this.state.table.columns}
							selectRow={this.state.table.selectRow}
							striped 
						/>
					</div>
					)
				}
			</ToolkitProvider>
        )
    }

}