import api from './api';

export default {
	getAll() {
		return api.get(`/api/Fazenda`);
	},
	create(params) {
		return api.post(`/api/Fazenda`, params);
	},
	buscarTudo(params) {
		return api.post(`/api/Fazenda/BuscarTudo`, params);
	},
	buscarModelos() {
		return api.get(`/api/Fazenda/Modelos`);
	},
	buscarPorId(fazendaId) {
		return api.get(`/api/Fazenda/BuscarPorId/${fazendaId}`);
	},
	update(fazenda) {
		return api.put(`/api/Fazenda`, fazenda);
	},
	ativaDesativa(id) {
		return api.get(`/api/Fazenda/AtivarDesativar/${id}`);
	},
};
