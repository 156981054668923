import api from './api';

export default {
	salvar(param){
		return api.post('/api/Histograma', param);
	},
	editar(param){
		return api.put('/api/Histograma', param);
	},
	get(id){
		return api.get(`/api/Histograma/${id}`);
	},
	buscarTudo(param){
		return api.post('/api/Histograma/buscarTudo', param);
	},
	deletar(id){
		return api.delete('/api/Histograma/' + id);
	},


};
