import Modal from '../../../node_modules/react-bootstrap/es/Modal'
import Button from '../../../node_modules/react-bootstrap/es/Button'
import PlanejamentoSafraService from '../../services/planejamentoSafraService'
import "react-sweet-progress/lib/style.css";
import React from 'react';
import ToastHelper from './../../utils/toastHelper';
import './modal.progresso.scss'

export default function ModalDesativar(props) {

	const { show, item, close } = props;

	const alterar = async () => {
		const { data } = await PlanejamentoSafraService.desativar(item.id);
		data.isOk ? ToastHelper.success("Operação realizada com sucesso") : ToastHelper.error(data.mensagem)
		close();
	}

	return (
		<>
			<Modal show={show} onHide={() => { close(); }}>
				<Modal.Header closeButton >
					<Modal.Title>{item.ativo ? "Desativar planejamento" : "Ativar planejamento"}</Modal.Title>
				</Modal.Header>
				<div className="col-md-12 mt-2 text-center">
					<h6>{`Deseja ${item.ativo ? "desativar" : "ativar"} este planejamento?`}</h6>
				</div>

				<Modal.Footer className="footer-modal mt-3">
					<Button bsStyle="primary" className="btn active mr-4 wd-30  modal-2-btn btn-padrao-rehagro-cancelar" onClick={() => {
						close()
					}}>
						Não
                </Button>
					<Button bsStyle="primary" className="btn active wd-30  modal-2-btn btn-padrao-rehagro" onClick={() =>
						alterar()
					}>
						Sim
                </Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
