import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { Types } from "./painel.servicos.duks"
import executeAction from "../../../store/sagas/executeAction";
import PainelService from '../../../services/painelService'

function* getDadosServicesByGrupo(action) {
	yield executeAction(function* () {
		const { params ,callback } = action;
		const { data } = yield call( async () => await PainelService.buscarDadosServicesByGrupo(params));
		yield put({ type: Types.SET_DADOS_SERVICES_BY_GRUPO, data: data });
		callback();
	}, Types.GET_DADOS_SERVICES_BY_GRUPO);
}

function* takeGetDadosServicesByGrupo() {
	yield takeLatest(Types.GET_DADOS_SERVICES_BY_GRUPO, getDadosServicesByGrupo);
}

export default function* root() {
	yield all([fork(takeGetDadosServicesByGrupo)])
}